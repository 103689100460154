import React from 'react';

import InfoInput from '~/components/Inputs/Info';

import { Container, Content } from './styles';

interface ITotalAmountProps {
  total_amount?: number;
}

const TotalAmount: React.FC<ITotalAmountProps> = ({ total_amount }) => {
  return (
    <Container>
      <Content>
        <InfoInput
          name="total_amount"
          labelFor="total_amount"
          labelText="Qtd. Total"
          type="text"
          defaultValue={
            total_amount
              ? new Intl.NumberFormat('pt-BR').format(total_amount)
              : new Intl.NumberFormat('pt-BR').format(0)
          }
          disabled
        />
      </Content>
    </Container>
  );
};

export default TotalAmount;
