import React, { Dispatch, SetStateAction } from 'react';
import { FcHighPriority } from 'react-icons/fc';
import { Dialog } from '@material-ui/core';

import DialogOptionButton from '~/components/Buttons/DialogOption';

import {
  Container,
  IconContainer,
  Title,
  SubTitle,
  ButtonContainer,
} from './styles';

interface IConfirmDeleteProps {
  isOpen: boolean;
  title: string;
  subTitle: string;
  onClose: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const ConfirmDelete: React.FC<IConfirmDeleteProps> = ({
  isOpen,
  title,
  subTitle,
  onClose,
  setIsOpen,
}: IConfirmDeleteProps) => {
  const handleClose = (): void => {
    setIsOpen(false);
  };

  const handleConfirmation = (): void => {
    setIsOpen(false);
    onClose();
  };

  return (
    <Dialog open={isOpen}>
      <Container>
        <IconContainer>
          <FcHighPriority size={60} color="#c9aa71" />
        </IconContainer>

        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>

        <ButtonContainer>
          <DialogOptionButton
            label="Sim, excluir"
            optionType="danger"
            onClick={() => handleConfirmation()}
          />

          <DialogOptionButton
            label="Não"
            marginLeft="1.5rem"
            onClick={() => handleClose()}
          />
        </ButtonContainer>
      </Container>
    </Dialog>
  );
};

export default ConfirmDelete;
