import React from 'react';

import { Container } from './styles';

interface IDefaultTooltipProps {
  title: string | undefined;
  className?: string;
  background?: string;
  borderColor?: string;
  disabled?: boolean;
}

const DefaultTooltip: React.FC<IDefaultTooltipProps> = ({
  title,
  className,
  background,
  borderColor,
  disabled,
  children,
}) => {
  return disabled ? (
    <>{children}</>
  ) : (
    <Container
      className={className}
      background={background}
      borderColor={borderColor}
    >
      {children}
      <span>{title}</span>
    </Container>
  );
};

export default DefaultTooltip;
