import React from 'react';

import {
  FaCog,
  FaMobileAlt,
  FaTrashAlt,
  FaUsers,
  FaWrench,
} from 'react-icons/fa';

export default {
  'Alteração Manual de Solicitação': {
    icon: <FaWrench size={30} />,
    link: '/management/projectediting',
    backgroundColor: '#747474',
  },
  'Exclusão de Solicitação': {
    icon: <FaTrashAlt size={30} />,
    link: '/management/projectdeletion',
    backgroundColor: '#747474',
  },
  Clientes: {
    icon: <FaUsers size={30} />,
    link: '/management/localclients',
    backgroundColor: '#747474',
  },
  'Acessos Aplicativos Mobile': {
    icon: <FaMobileAlt size={30} />,
    link: '/management/mobileaccesses',
    backgroundColor: '#747474',
  },
  'Configurações Gerais': {
    icon: <FaCog size={30} />,
    link: '/management/generalsettings',
    backgroundColor: '#747474',
  },
};
