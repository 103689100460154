import React, { InputHTMLAttributes, useState, useCallback } from 'react';

import ErrorTooltip from '~/components/Tooltips/Error';

import { Label, Container, InputText } from './styles';

interface IDateProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  labelFor?: string;
  labelText?: string;
  upperCaseLabel?: boolean;
  defaultValue?: string;
  error?: string;
}

const Date = React.forwardRef<HTMLInputElement, IDateProps>(
  (
    {
      name,
      labelFor,
      labelText,
      upperCaseLabel,
      defaultValue,
      error,
      ...rest
    }: IDateProps,
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleInputFocus = useCallback(() => {
      setIsFocused(true);
    }, []);

    const handleInputBlur = useCallback(() => {
      setIsFocused(false);
    }, []);

    return (
      <>
        {labelText && (
          <Label isFocused={isFocused}>
            {upperCaseLabel ? labelText.toUpperCase() : labelText}
          </Label>
        )}

        <Container id={labelFor} isFocused={isFocused} isErrored={!!error}>
          <InputText
            name={name}
            defaultValue={defaultValue}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            mask="99/99/9999"
            {...rest}
          />

          {error && <ErrorTooltip title={error} />}
        </Container>
      </>
    );
  },
);

export default Date;
