import React, { ButtonHTMLAttributes } from 'react';
import { BsFiles } from 'react-icons/bs';

import { Container } from './styles';

interface INewAltProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
}

const NewAlt: React.FC<INewAltProps> = ({ label, ...rest }) => {
  return (
    <Container {...rest}>
      {label || 'Novo'} <BsFiles size={16} />
    </Container>
  );
};

export default NewAlt;
