import styled, { css } from 'styled-components';

interface IContentProps {
  maxWidth: string;
}

interface IPreviewMessageProps {
  src: string;
}

export const Container = styled.section`
  ${({ theme }) => css`
    width: 100%;
    background: ${theme.colors.background};
    border-radius: ${theme.radius.default};
    box-shadow: ${theme.shadows.flat};
    padding: 2.4rem;
    margin: 2.4rem 0;
  `}
`;

export const Content = styled.div<IContentProps>`
  ${({ maxWidth }) => css`
    display: flex;
    justify-content: flex-start;

    ${maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
  `}
`;

export const ContentAddRow = styled(Content)`
  padding-top: 1rem;
  justify-content: flex-end;
`;

export const LabelContainer = styled.div`
  margin-bottom: 0.5rem;
`;

export const Label = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.default};
    color: ${theme.colors.labelInput};
  `}
`;

export const Table = styled.table`
  ${({ theme }) => css`
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0 4px;
    background-color: ${theme.colors.lightGrey};
    box-shadow: 1px 1px 6px #ececec;
    border-radius: ${theme.radius.small};

    tr {
      border-radius: ${theme.radius.small};
    }
  `}
`;

export const ItemSequence = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 1rem 0.5rem 1rem 1rem;
    font-size: ${theme.fontSizes.large};
    font-weight: ${theme.weight.strong};
    background-color: ${theme.colors.backgroundContainer};
    border-top-right-radius: ${theme.radius.small};
    border-top-left-radius: ${theme.radius.small};
  `}
`;

export const ItemLabel = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.small};
    font-weight: ${theme.weight.veryStrong};
  `}
`;

export const ItemContent = styled.div`
  display: flex;

  @media (max-width: 1010px) {
    flex-direction: column;
  }
`;

export const ItemWrapper = styled.div`
  width: 100%;
  padding: 0 0.5rem 0 0.5rem;
  margin-top: 1rem;
`;

export const ItemUploadWrapper = styled.fieldset`
  width: 100%;
  border: none;
  margin-top: 1rem;
  padding: 0 0.5rem 0 0.5rem;
`;

export const ItemPreviousFilesWrapper = styled.fieldset`
  ${({ theme }) => css`
    width: 100%;
    padding: 8px;
    border-radius: ${theme.radius.small};
    border: 1px solid #e4e4e4;
  `}
`;

export const FileMainContainer = styled.ul`
  margin-top: 0.5rem;
`;

export const FileImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const FileImage = styled.div<IPreviewMessageProps>`
  ${({ theme, src }) => css`
    width: 55px;
    height: 55px;
    border-radius: ${theme.radius.small};
    background-image: url(${src});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  `}
`;

export const FileInfoName = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.default};
    color: ${theme.colors.darkGrey};
  `}
`;

export const FileInfoFileSize = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.tiny};
  `}
`;

export const FileUrlIcon = styled.div`
  ${({ theme }) => css`
    padding-right: 1rem;

    svg {
      color: ${theme.colors.urlIcon};
    }

    &:hover {
      transform: scale(1.2, 1.2);
    }
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5rem;
`;
