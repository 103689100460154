import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import {
  FaFileUpload,
  FaTrashAlt,
  FaExclamationCircle,
  FaCheckCircle,
  FaLink,
} from 'react-icons/fa';

import AudioIcon from '~/components/Icons/Files/Audio';
import PdfIcon from '~/components/Icons/Files/Pdf';
import PresentationIcon from '~/components/Icons/Files/Presentation';
import SpreadsheetIcon from '~/components/Icons/Files/Spreadsheet';
import TextIcon from '~/components/Icons/Files/Text';
import VideoIcon from '~/components/Icons/Files/Video';
import WordIcon from '~/components/Icons/Files/Word';

import { useApprovalFiles, IApprovalFile } from '~/context/approvalfiles';

import {
  Container,
  FileRow,
  FileContainer,
  FilePreviewWrapper,
  FilePreviewImageWrapper,
  FilePreviewImage,
  FileInfoWrapper,
  FileInfoName,
  FileInfoFileSize,
  FileActions,
  DeleteBtn,
  UrlIcon,
  SuccessIcon,
  ErrorIcon,
} from './styles';

const List = (): JSX.Element => {
  const { uploadedApprovalFiles: approvalFiles, deleteApprovalFile } =
    useApprovalFiles();

  if (!approvalFiles.length) {
    return (
      <span>
        <FaFileUpload
          style={{ marginLeft: '45%', marginTop: 15 }}
          size={35}
          color="#adadad"
        />
      </span>
    );
  }

  function handleApprovalFilePreview(
    uploadedApprovalFile: any,
  ): React.ReactNode {
    if (
      uploadedApprovalFile.type === 'audio/mp3' ||
      uploadedApprovalFile.type === 'audio/mpeg'
    ) {
      return <AudioIcon />;
    }

    if (uploadedApprovalFile.type === 'application/pdf') {
      return <PdfIcon />;
    }

    if (
      uploadedApprovalFile.type ===
        'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
      uploadedApprovalFile.type ===
        'application/vnd.oasis.opendocument.presentation'
    ) {
      return <PresentationIcon />;
    }

    if (
      uploadedApprovalFile.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      uploadedApprovalFile.type ===
        'application/vnd.oasis.opendocument.spreadsheet'
    ) {
      return <SpreadsheetIcon />;
    }

    if (uploadedApprovalFile.type === 'text/plain') {
      return <TextIcon />;
    }

    if (
      uploadedApprovalFile.type === 'image/gif' ||
      uploadedApprovalFile.type === 'video/quicktime' ||
      uploadedApprovalFile.type === 'video/mp4'
    ) {
      return <VideoIcon />;
    }

    if (
      uploadedApprovalFile.type ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      uploadedApprovalFile.type === 'application/vnd.oasis.opendocument.text'
    ) {
      return <WordIcon />;
    }

    return (
      <FilePreviewImageWrapper>
        <FilePreviewImage src={uploadedApprovalFile.preview} />
      </FilePreviewImageWrapper>
    );
  }

  return (
    <Container>
      {approvalFiles.map((uploadedApprovalFile: IApprovalFile) => (
        <FileRow key={uploadedApprovalFile.id}>
          <FileContainer>
            <FilePreviewWrapper>
              {handleApprovalFilePreview(uploadedApprovalFile)}
            </FilePreviewWrapper>

            <FileInfoWrapper>
              <FileInfoName>{uploadedApprovalFile.name}</FileInfoName>

              <FileInfoFileSize>
                {uploadedApprovalFile.readableSize}
              </FileInfoFileSize>
            </FileInfoWrapper>
          </FileContainer>

          <FileActions>
            {!uploadedApprovalFile.uploaded && !uploadedApprovalFile.error && (
              <CircularProgressbar
                styles={{
                  root: { width: 24 },
                  path: { stroke: '#7159c1' },
                }}
                strokeWidth={10}
                text={String(uploadedApprovalFile.progress)}
                value={uploadedApprovalFile.progress || 0}
              />
            )}

            {uploadedApprovalFile.url && (
              <UrlIcon>
                <a
                  href={uploadedApprovalFile.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLink size={20} />
                </a>
              </UrlIcon>
            )}

            {uploadedApprovalFile.uploaded && (
              <SuccessIcon>
                <FaCheckCircle size={24} />
              </SuccessIcon>
            )}
            {uploadedApprovalFile.error && (
              <ErrorIcon>
                <FaExclamationCircle size={24} />
              </ErrorIcon>
            )}
            {!!uploadedApprovalFile.url && (
              <DeleteBtn
                onClick={() => deleteApprovalFile(uploadedApprovalFile.id)}
              >
                <FaTrashAlt size={24} />
              </DeleteBtn>
            )}
          </FileActions>
        </FileRow>
      ))}
    </Container>
  );
};

export default List;
