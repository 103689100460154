import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import Button from '~/components/Button';

export const Container = styled(Button)`
  && {
    ${({ theme }) => css`
      background-color: ${theme.colors.sideBar};
      color: ${theme.colors.label};
      font-size: ${theme.fontSizes.large};
      font-weight: 500;
      width: 100%;
      justify-content: flex-start;
      border-radius: 0;
      padding: 1.6rem 1rem;
      border-bottom: 0.15rem solid
        ${transparentize(0.9, theme.colors.sideBarBorder)};
      margin-bottom: 4px;

      &:hover {
        background-color: ${theme.colors.sideBarLight};
        border-radius: ${theme.radius.small};
        border-bottom: 0.15rem solid
          ${transparentize(0.7, theme.colors.sideBarBorder)};
        transform: translateX(4px);
      }

      div {
        :first-child {
          width: 3.5rem;
          height: 3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 1rem;
        }
      }

      &:hover {
        transform: translateX(6px);
        transition-duration: 0.4s;
      }

      @media (max-width: 670px) {
        display: none;
      }
    `}
  }
`;
