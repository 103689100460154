import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { PropagateLoader } from 'react-spinners';
import { FaProjectDiagram, FaBox } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

import BreadCrumb from '~/components/BreadCrumb';
import ProjectInfo from '~/components/ProjectInfo/Default';
import TableDefaultInput from '~/components/TableInputs/Default';
import TableDateInput from '~/components/TableInputs/Date';
import TableCurrencyInput from '~/components/TableInputs/Currency';
import LockButton from '~/components/Buttons/Lock';
import UnlockButton from '~/components/Buttons/Unlock';
import AddRowButton from '~/components/Buttons/AddRow';
import DeleteRowButton from '~/components/Buttons/DeleteRow';
import TextArea from '~/components/TextArea';
import UploadFiles from '~/components/UploadFiles';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';
import NoticeDialog from '~/components/Dialogs/Notice';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Content,
  ContentAddRow,
  Wrapper,
  LabelContainer,
  Label,
  Table,
  ItemSequence,
  ItemSequenceLabel,
  ItemContent,
  ItemWrapper,
  ItemSubWrapper,
  ItemInfoWrapper,
  ItemButtonsWrapper,
  ButtonContainer,
} from './styles';

interface IProjectLabel {
  control: string;
  packaging: string;
  city: string;
  state: string;
  invoice_series: string;
  invoice_number: string;
  invoice_issue_date: Date;
  invoice_net_price: number;
  document: string;
  item_erp_code: string;
  batch: string;
  sequence: number;
  project_id: string;
  user_inclusion_id: string;
  user_update_id: string;
  invoice_net_price_enabled: boolean;
  automatic_invoice_net_price: boolean;
  validation_general_error: boolean;
  invoice_found: boolean;
  conflict: boolean;
  invoice_validation_error: string | undefined;
  invoice_validation_warning: string | undefined;
  invoice_issue_date_error: string | undefined;
  conflict_error: string | undefined;
}

interface IFormProcedure6 {
  type: string;
  code: string;
  display_id: string;
  user_update_id: string;
  validation_type: string;
  project_procedure_id: string;
  record_description: string;
  record_note: string;
  project_temp_id: string;
  project_labels: IProjectLabel[];
}

interface ILocation {
  id: string;
  type: string;
  code: string;
  erp_rep_code: string;
  item_amount: number;
  status: string;
  project_procedure_id: string;
  local_client: {
    code: string;
    name: string;
    company_name: string;
    document: string;
    address: string;
    city: string;
    state: string;
    representative: boolean;
  };
  display: {
    erp_code: string;
  };
  project_procedure: {
    user_type: string;
  };
}

const Procedure6: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [projectLabels, setProjectLabels] = useState<IProjectLabel[]>([]);
  const [loadProjectLabels, setLoadProjectLabels] = useState(false);
  const [projectLabelsLoaded, setProjectLabelsLoaded] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [projectTempFilled, setProjectTempFilled] = useState(false);
  const [projectTemp, setProjectTemp] = useState('');
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [titleConfirmProcedure, setTitleConfirmProcedure] = useState('');
  const [typeConfirmProcedure, setTypeConfirmProcedure] = useState('');
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isOpenNotice, setIsOpenNotice] = useState(false);
  const [titleNotice, setTitleNotice] = useState('');
  const [subTitleNotice, setSubTitleNotice] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const projectLabelSequence = useRef(0);

  const { handleSubmit, register, setValue, errors } =
    useForm<IFormProcedure6>();

  useEffect(() => {
    if (loadProjectLabels) {
      projectLabelSequence.current += 1;
      const firstProjectLabel: IProjectLabel = {
        control: '',
        packaging: '',
        city: state.local_client.city,
        state: state.local_client.state,
        invoice_series: '',
        invoice_number: '',
        invoice_issue_date: new Date(new Date().setHours(0, 0, 0, 0)),
        invoice_net_price: 0,
        document: state.local_client.document,
        item_erp_code: state.display.erp_code,
        batch: '',
        sequence: projectLabelSequence.current,
        project_id: state.id,
        user_inclusion_id: user.id,
        user_update_id: user.id,
        invoice_net_price_enabled: false,
        automatic_invoice_net_price: false,
        validation_general_error: false,
        invoice_found: false,
        conflict: false,
        invoice_validation_error: undefined,
        invoice_validation_warning: 'Nota fiscal não encontrada',
        invoice_issue_date_error: undefined,
        conflict_error: undefined,
      };

      setProjectLabels([firstProjectLabel]);
      setLoadProjectLabels(false);
      setProjectLabelsLoaded(true);
      setLoading(false);
    }
  }, [state, user, loadProjectLabels]);

  useEffect(() => {
    if (!projectLabelsLoaded) {
      setLoadProjectLabels(true);
    }
  }, [projectLabelsLoaded]);

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto não localizado',
      });

      history.push('/projects/displays');
    } else if (
      user.user_type.type !== state.project_procedure.user_type &&
      user.user_type.type !== 'ADM'
    ) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Tipo de usuário não permitido',
      });

      history.push('/projects/displays');
    } else if (
      state.project_procedure.user_type === 'REP' &&
      state.erp_rep_code !== user.erp_rep_code &&
      user.user_type.type !== 'ADM'
    ) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Usuário não permitido',
      });

      history.push('/projects/displays');
    } else if (state.status.toUpperCase() === 'CANCELADO') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto Cancelado',
      });

      history.push('/projects/displays');
    } else if (state.status.toUpperCase() === 'FINALIZADO') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto Finalizado',
      });

      history.push('/projects/displays');
    } else {
      register('validation_type');
      register('project_temp_id');
      register('type');
      register('code');
      register('record_description');
      register('user_update_id');
      register('project_procedure_id');
      register('project_labels');

      setValue('type', 'DISPLAY');
      setValue('code', state.code);
      setValue('user_update_id', user.id);
      setValue('project_procedure_id', state.project_procedure_id);

      if (!projectTempFilled) {
        setProjectTempFilled(true);
        setProjectTemp(uuidv4());
      }
    }
  }, [addToast, history, state, user, projectTempFilled, register, setValue]);

  useEffect(() => {
    setValue('project_temp_id', projectTemp);
  }, [projectTemp, setValue]);

  const submitForm = useCallback(
    async (data: IFormProcedure6) => {
      try {
        setIsSaving(true);

        await api.put(`/projects/${state.id}`, data);

        addToast({
          type: 'success',
          title: 'Projeto atualizado!',
          description: 'Projeto atualizado com sucesso.',
        });

        history.push('/projects/displays');
      } catch (error: any) {
        setIsSaving(false);

        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [addToast, history, state],
  );

  async function onValidateConfirmProcedure(): Promise<void> {
    const rowsConflict = projectLabels.filter(
      projLabel => projLabel.conflict_error !== undefined,
    );

    const rowsInvalidInvoice = projectLabels.filter(
      projLabel => projLabel.invoice_validation_error !== undefined,
    );

    const rowsInvoiceNotFound = projectLabels.filter(
      projLabel => !projLabel.invoice_found,
    );

    if (projectLabels.length === 0) {
      setTypeConfirmProcedure('danger');
      setTitleConfirmProcedure(
        `Nenhuma etiqueta foi registrada! ` +
          `Confirma mesmo assim o envio do projeto ${state.code} ` +
          `para produção/pedido/faturamento?`,
      );

      setIsOpenConfirmProcedure(true);
    } else if (rowsConflict && rowsConflict.length > 0) {
      setTitleNotice('Existem etiquetas iguais!');
      setSubTitleNotice('Não é possível enviar projeto com etiquetas iguais!');
      setIsOpenNotice(true);
    } else if (rowsInvalidInvoice && rowsInvalidInvoice.length > 0) {
      setTitleNotice('Existem notas fiscais inválidas!');
      setSubTitleNotice(
        'Não é possível enviar projeto com notas fiscais inválidas!',
      );
      setIsOpenNotice(true);
    } else {
      if (rowsInvoiceNotFound && rowsInvoiceNotFound.length > 0) {
        setTypeConfirmProcedure('danger');
        setTitleConfirmProcedure(
          `Existem etiquetas com notas fiscais não encontradas! ` +
            `Confirma mesmo assim o envio do projeto ${state.code} ` +
            `para produção/pedido/faturamento?`,
        );
      } else {
        setTypeConfirmProcedure('');
        setTitleConfirmProcedure(
          `Confirma envio do projeto ${state.code} para produção/pedido/faturamento?`,
        );
      }

      setIsOpenConfirmProcedure(true);
    }
  }

  function onConfirmProcedure(): void {
    setValue('validation_type', 'APROVADO');
    setValue('record_description', `Envio de materiais`);
    setValue('project_labels', projectLabels);

    handleSubmit(submitForm)();
  }

  const onExit = useCallback(() => {
    api.delete(`projectfilesreceived/projecttempid/${projectTemp}`);

    history.push('/projects/displays');
  }, [history, projectTemp]);

  function handleAddRowTableProjectLabels(): void {
    projectLabelSequence.current += 1;
    const newProjectLabel: IProjectLabel = {
      control: '',
      packaging: '',
      city: state.local_client.city,
      state: state.local_client.state,
      invoice_series: '',
      invoice_number: '',
      invoice_issue_date: new Date(new Date().setHours(0, 0, 0, 0)),
      invoice_net_price: 0,
      document: state.local_client.document,
      item_erp_code: state.display.erp_code,
      batch: '',
      sequence: projectLabelSequence.current,
      project_id: state.id,
      user_inclusion_id: user.id,
      user_update_id: user.id,
      invoice_net_price_enabled: false,
      automatic_invoice_net_price: false,
      validation_general_error: false,
      invoice_found: false,
      conflict: false,
      invoice_validation_error: undefined,
      invoice_validation_warning: 'Nota fiscal não encontrada',
      invoice_issue_date_error: undefined,
      conflict_error: undefined,
    };

    setProjectLabels(prevProjectLabels => [
      ...prevProjectLabels,
      newProjectLabel,
    ]);
  }

  function handleDeleteRowTableProjectLabels(): void {
    setProjectLabels(
      projectLabels.filter(
        projLabel => projLabel.sequence !== projectLabelSequence.current,
      ),
    );

    projectLabelSequence.current -= 1;
  }

  useEffect(() => {
    Object.keys(projectLabels).forEach(key => {
      let conf_error: string | undefined;
      const projLabelConflict = projectLabels.filter(
        projectLabel =>
          projectLabel.sequence !== projectLabels[Number(key)].sequence &&
          projectLabel.control === projectLabels[Number(key)].control &&
          projectLabel.packaging === projectLabels[Number(key)].packaging &&
          projectLabel.city === projectLabels[Number(key)].city &&
          projectLabel.state === projectLabels[Number(key)].state &&
          projectLabel.invoice_series ===
            projectLabels[Number(key)].invoice_series &&
          projectLabel.invoice_number ===
            projectLabels[Number(key)].invoice_number &&
          projectLabel.invoice_net_price ===
            projectLabels[Number(key)].invoice_net_price &&
          projectLabel.document === projectLabels[Number(key)].document &&
          projectLabel.item_erp_code ===
            projectLabels[Number(key)].item_erp_code &&
          projectLabel.batch === projectLabels[Number(key)].batch,
      );

      if (projLabelConflict && projLabelConflict.length > 0) {
        conf_error = 'Etiqueta já registrada';
      } else {
        conf_error = undefined;
      }

      if (projectLabels[Number(key)].conflict_error !== conf_error) {
        setProjectLabels(
          projectLabels.map(projLabel => {
            if (projLabel.sequence === projectLabels[Number(key)].sequence) {
              projLabel.conflict_error = conf_error;
            }

            return projLabel;
          }),
        );
      }
    });
  }, [projectLabels]);

  function handleRowControlChange(sequence: number, fieldValue: string): void {
    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.sequence === sequence) {
          projLabel.control = fieldValue;
        }
        return projLabel;
      }),
    );
  }

  function handleRowPackagingChange(
    sequence: number,
    fieldValue: string,
  ): void {
    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.sequence === sequence) {
          projLabel.packaging = fieldValue;
        }
        return projLabel;
      }),
    );
  }

  function handleRowCityChange(sequence: number, fieldValue: string): void {
    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.sequence === sequence) {
          projLabel.city = fieldValue;
        }
        return projLabel;
      }),
    );
  }

  function handleRowStateChange(sequence: number, fieldValue: string): void {
    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.sequence === sequence) {
          projLabel.state = fieldValue;
        }
        return projLabel;
      }),
    );
  }

  async function handleFindInvoice(sequence: number): Promise<void> {
    const projLabelCurrent = projectLabels.find(
      projectLabel => projectLabel.sequence === sequence,
    );

    if (projLabelCurrent) {
      setProjectLabels(
        projectLabels.map(projLabel => {
          if (projLabel.sequence === sequence) {
            projLabel.automatic_invoice_net_price = true;
          }
          return projLabel;
        }),
      );

      if (moment(projLabelCurrent.invoice_issue_date).isValid()) {
        await api
          .get(
            `/invoices/findinvoice?` +
              `series=${projLabelCurrent.invoice_series}&` +
              `number=${projLabelCurrent.invoice_number}&` +
              `issue_date=${projLabelCurrent.invoice_issue_date}&` +
              `document=${state.local_client.document}`,
          )
          .then(response => {
            let foundInvoice = false;

            if (
              response.data.series &&
              response.data.number &&
              response.data.issue_date &&
              response.data.net_price &&
              response.data.document
            ) {
              if (
                response.data.series === projLabelCurrent.invoice_series &&
                response.data.number === projLabelCurrent.invoice_number &&
                moment(response.data.issue_date).format('DD/MM/YYYY') ===
                  moment(projLabelCurrent.invoice_issue_date).format(
                    'DD/MM/YYYY',
                  ) &&
                response.data.document === state.local_client.document
              ) {
                foundInvoice = true;
              }
            }

            if (foundInvoice) {
              setProjectLabels(
                projectLabels.map(projLabel => {
                  if (projLabel.sequence === sequence) {
                    projLabel.invoice_found = true;
                    projLabel.invoice_net_price = response.data.net_price;
                  }
                  return projLabel;
                }),
              );
            } else {
              setProjectLabels(
                projectLabels.map(projLabel => {
                  if (projLabel.sequence === sequence) {
                    projLabel.invoice_found = false;
                  }
                  return projLabel;
                }),
              );
            }
          });
      }
    }
  }

  function handleLabelInvoiceWarnings(): void {
    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.invoice_found) {
          projLabel.invoice_validation_warning = undefined;
        } else {
          projLabel.invoice_validation_warning = 'Nota fiscal não encontrada';
        }

        return projLabel;
      }),
    );
  }

  async function handleInvalidLabelInvoiceGeneral(
    sequence: number,
  ): Promise<void> {
    const projLabelValidate = projectLabels.find(
      projectLabel => projectLabel.sequence === sequence,
    );

    if (projLabelValidate) {
      if (moment(projLabelValidate.invoice_issue_date).isValid()) {
        await api
          .get(
            `/projectlabels/valid?` +
              `invoice_series=${projLabelValidate.invoice_series}&` +
              `invoice_number=${projLabelValidate.invoice_number}&` +
              `invoice_issue_date=${projLabelValidate.invoice_issue_date}`,
          )
          .then(response => {
            let valid = false;

            if (
              response.data.id &&
              response.data.invoice_series &&
              response.data.invoice_number &&
              response.data.invoice_issue_date
            ) {
              if (response.data.id === state.id) {
                valid = true;
              } else {
                setProjectLabels(
                  projectLabels.map(projLabel => {
                    if (projLabel.sequence === sequence) {
                      projLabel.validation_general_error = true;
                    }
                    return projLabel;
                  }),
                );
              }
            } else {
              valid = true;
            }

            if (valid) {
              setProjectLabels(
                projectLabels.map(projLabel => {
                  if (projLabel.sequence === sequence) {
                    projLabel.validation_general_error = false;
                  }
                  return projLabel;
                }),
              );
            }
          });
      }
    }
  }

  function handleInvalidLabelInvoices(): void {
    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.validation_general_error) {
          projLabel.invoice_validation_error =
            'Nota fiscal já utilizada em outro projeto';
        } else {
          projLabel.invoice_validation_error = undefined;
        }

        return projLabel;
      }),
    );
  }

  async function handleLabelInvoiceValidationsComplete(
    sequence: number,
  ): Promise<void> {
    await handleFindInvoice(sequence);
    await handleLabelInvoiceWarnings();
    await handleInvalidLabelInvoiceGeneral(sequence);
    await handleInvalidLabelInvoices();
  }

  async function handleRowInvoiceSeriesChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.sequence === sequence) {
          projLabel.invoice_series = fieldValue;
        }
        return projLabel;
      }),
    );

    await handleLabelInvoiceValidationsComplete(sequence);
  }

  async function handleRowInvoiceNumberChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.sequence === sequence) {
          projLabel.invoice_number = fieldValue;
        }
        return projLabel;
      }),
    );

    await handleLabelInvoiceValidationsComplete(sequence);
  }

  async function handleRowInvoiceIssueDateChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    const issueDate = moment(fieldValue, 'DD/MM/YYYY').toDate();

    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.sequence === sequence) {
          projLabel.invoice_issue_date = issueDate;
        }
        return projLabel;
      }),
    );

    if (
      !moment(fieldValue, 'DD/MM/YYYY').isValid() ||
      issueDate.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)
    ) {
      setProjectLabels(
        projectLabels.map(projLabel => {
          if (projLabel.sequence === sequence) {
            projLabel.invoice_issue_date_error = 'Data inválida';
          }
          return projLabel;
        }),
      );
    } else {
      setProjectLabels(
        projectLabels.map(projLabel => {
          if (projLabel.sequence === sequence) {
            projLabel.invoice_issue_date_error = undefined;
          }
          return projLabel;
        }),
      );
    }

    await handleLabelInvoiceValidationsComplete(sequence);
  }

  function handleRowInvoiceNetPriceChange(
    sequence: number,
    fieldValue: string,
  ): void {
    let netPriceConv = fieldValue;
    let netPriceValue = 0;

    netPriceConv = netPriceConv.replaceAll('R$', '');
    netPriceConv = netPriceConv.replaceAll('.', '');
    netPriceConv = netPriceConv.replaceAll(',', '.');

    if (!Number.isNaN(Number(parseFloat(netPriceConv)))) {
      netPriceValue = parseFloat(netPriceConv);
    }

    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.sequence === sequence) {
          projLabel.invoice_net_price = netPriceValue;
          projLabel.automatic_invoice_net_price = false;
        }
        return projLabel;
      }),
    );
  }

  function handleRowDocumentChange(sequence: number, fieldValue: string): void {
    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.sequence === sequence) {
          projLabel.document = fieldValue;
        }
        return projLabel;
      }),
    );
  }

  function handleRowItemErpCodeChange(
    sequence: number,
    fieldValue: string,
  ): void {
    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.sequence === sequence) {
          projLabel.item_erp_code = fieldValue;
        }
        return projLabel;
      }),
    );
  }

  function handleRowBatchChange(sequence: number, fieldValue: string): void {
    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.sequence === sequence) {
          projLabel.batch = fieldValue;
        }
        return projLabel;
      }),
    );
  }

  function handleRowInvoiceNetPriceEnabledChange(
    sequence: number,
    enabled: boolean,
  ): void {
    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.sequence === sequence) {
          projLabel.invoice_net_price_enabled = enabled;
        }
        return projLabel;
      }),
    );
  }

  function handleDeleteRow(
    projectLabelReceived: IProjectLabel,
  ): React.ReactNode | null {
    if (projectLabelReceived.sequence === projectLabelSequence.current) {
      return (
        <DeleteRowButton
          type="button"
          onClick={() => handleDeleteRowTableProjectLabels()}
        />
      );
    }

    return null;
  }

  function handleProjectLabelsTable(): React.ReactNode {
    if (projectLabels) {
      if (projectLabels.length > 0) {
        return (
          <Table>
            {loading && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <PropagateLoader color="#276c8d" />
              </div>
            )}
            {projectLabels?.map(projectLabel => (
              <tr key={projectLabel.sequence}>
                <ItemSequence>
                  <ItemSequenceLabel>
                    {`${'Sequência'.toUpperCase()}: ${projectLabel.sequence}`}
                  </ItemSequenceLabel>
                </ItemSequence>

                <ItemContent>
                  <ItemWrapper>
                    <TableDefaultInput
                      type="text"
                      labelText="Controle"
                      upperCaseLabel
                      defaultValue={projectLabel.control}
                      maxLength={20}
                      error={projectLabel.conflict_error}
                      onChange={e => {
                        handleRowControlChange(
                          projectLabel.sequence,
                          e.target.value,
                        );
                      }}
                    />
                  </ItemWrapper>

                  <ItemWrapper>
                    <TableDefaultInput
                      type="text"
                      labelText="Embalagem"
                      upperCaseLabel
                      defaultValue={projectLabel.packaging}
                      maxLength={20}
                      onChange={e => {
                        handleRowPackagingChange(
                          projectLabel.sequence,
                          e.target.value,
                        );
                      }}
                    />
                  </ItemWrapper>
                </ItemContent>

                <ItemContent>
                  <ItemWrapper>
                    <TableDefaultInput
                      type="text"
                      labelText="Cliente"
                      upperCaseLabel
                      defaultValue={state.local_client?.name}
                      maxLength={60}
                      disabled
                    />
                  </ItemWrapper>

                  <ItemWrapper>
                    <TableDefaultInput
                      type="text"
                      labelText="Cidade"
                      upperCaseLabel
                      defaultValue={state.local_client?.city}
                      maxLength={50}
                      onChange={e => {
                        handleRowCityChange(
                          projectLabel.sequence,
                          e.target.value,
                        );
                      }}
                      disabled
                    />
                  </ItemWrapper>
                </ItemContent>

                <ItemContent>
                  <ItemWrapper>
                    <TableDefaultInput
                      type="text"
                      labelText="Estado"
                      upperCaseLabel
                      defaultValue={state.local_client?.state}
                      maxLength={30}
                      onChange={e => {
                        handleRowStateChange(
                          projectLabel.sequence,
                          e.target.value,
                        );
                      }}
                      disabled
                    />
                  </ItemWrapper>

                  <ItemWrapper>
                    <TableDefaultInput
                      type="text"
                      labelText="Série NF"
                      upperCaseLabel
                      defaultValue={projectLabel.invoice_series}
                      maxLength={3}
                      onChange={e => {
                        handleRowInvoiceSeriesChange(
                          projectLabel.sequence,
                          e.target.value,
                        );
                      }}
                    />
                  </ItemWrapper>

                  <ItemWrapper>
                    <TableDefaultInput
                      type="text"
                      labelText="Número NF"
                      upperCaseLabel
                      defaultValue={projectLabel.invoice_number}
                      error={projectLabel.invoice_validation_error}
                      warning={projectLabel.invoice_validation_warning}
                      maxLength={7}
                      onChange={e => {
                        handleRowInvoiceNumberChange(
                          projectLabel.sequence,
                          e.target.value,
                        );
                      }}
                    />
                  </ItemWrapper>
                </ItemContent>

                <ItemContent>
                  <ItemWrapper>
                    <TableDateInput
                      type="text"
                      labelText="Data Emissão NF"
                      upperCaseLabel
                      defaultValue={projectLabel.invoice_issue_date.toLocaleDateString()}
                      error={projectLabel.invoice_issue_date_error}
                      onChange={e => {
                        handleRowInvoiceIssueDateChange(
                          projectLabel.sequence,
                          e.target.value,
                        );
                      }}
                    />
                  </ItemWrapper>

                  <ItemWrapper>
                    <ItemSubWrapper>
                      <ItemInfoWrapper>
                        <TableCurrencyInput
                          type="text"
                          labelText="Valor Total NF"
                          upperCaseLabel
                          value={projectLabel.invoice_net_price}
                          automaticValue={
                            projectLabel.automatic_invoice_net_price
                          }
                          onSet={e => {
                            handleRowInvoiceNetPriceChange(
                              projectLabel.sequence,
                              e.target.value,
                            );
                          }}
                          disabled={!projectLabel.invoice_net_price_enabled}
                        />
                      </ItemInfoWrapper>

                      <ItemButtonsWrapper>
                        {projectLabel.invoice_net_price_enabled ? (
                          <UnlockButton
                            iconSize={20}
                            type="button"
                            onClick={() => {
                              handleRowInvoiceNetPriceEnabledChange(
                                projectLabel.sequence,
                                false,
                              );
                            }}
                          />
                        ) : (
                          <LockButton
                            iconSize={20}
                            type="button"
                            onClick={() => {
                              handleRowInvoiceNetPriceEnabledChange(
                                projectLabel.sequence,
                                true,
                              );
                            }}
                          />
                        )}
                      </ItemButtonsWrapper>
                    </ItemSubWrapper>
                  </ItemWrapper>

                  <ItemWrapper>
                    <TableDefaultInput
                      type="text"
                      labelText="CNPJ"
                      upperCaseLabel
                      defaultValue={state.local_client?.document}
                      maxLength={18}
                      onChange={e => {
                        handleRowDocumentChange(
                          projectLabel.sequence,
                          e.target.value,
                        );
                      }}
                      disabled
                    />
                  </ItemWrapper>
                </ItemContent>

                <ItemContent>
                  <ItemWrapper>
                    <TableDefaultInput
                      type="text"
                      labelText="Item"
                      upperCaseLabel
                      defaultValue={projectLabel.item_erp_code}
                      maxLength={14}
                      onChange={e => {
                        handleRowItemErpCodeChange(
                          projectLabel.sequence,
                          e.target.value,
                        );
                      }}
                      disabled
                    />
                  </ItemWrapper>

                  <ItemWrapper>
                    <TableDefaultInput
                      type="text"
                      labelText="Lote"
                      upperCaseLabel
                      defaultValue={projectLabel.batch}
                      maxLength={20}
                      onChange={e => {
                        handleRowBatchChange(
                          projectLabel.sequence,
                          e.target.value,
                        );
                      }}
                    />
                  </ItemWrapper>
                </ItemContent>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '10px 5px 10px 10px',
                  }}
                >
                  {handleDeleteRow(projectLabel)}
                </div>
              </tr>
            ))}
          </Table>
        );
      }
    }

    return <></>;
  }

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/displays"
        firstChild="Displays"
        secondChild="Atualização Solicitação"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaBox}
        secondChildIcon={FaBox}
        mainColored
        firstChildColored
      />

      <ProjectInfo project_id={state.id} />

      <form onSubmit={handleSubmit(submitForm)}>
        <LabelContainer>
          <Label>Etiquetas</Label>
        </LabelContainer>

        <Content>{handleProjectLabelsTable()}</Content>

        <ContentAddRow>
          <AddRowButton
            type="button"
            onClick={() => handleAddRowTableProjectLabels()}
          />
        </ContentAddRow>

        <Content>
          <Wrapper>
            <TextArea
              name="record_note"
              labelFor="record_note"
              labelText="Observação"
              error={errors?.record_note?.message}
              ref={register}
              maxLength={1000}
            />
          </Wrapper>
        </Content>

        <Content>
          <UploadFiles
            type="anexo"
            project_temp_id={projectTemp}
            project_type="DISPLAY"
            sequence={6}
            user_id={user.id}
          />
        </Content>

        <ButtonContainer>
          <SaveButton
            type="button"
            processing={isSaving}
            disabled={isSaving}
            onClick={() => onValidateConfirmProcedure()}
          />

          <ExitButton
            style={{ marginLeft: 10 }}
            type="button"
            disabled={isSaving}
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={onConfirmProcedure}
        title={titleConfirmProcedure}
        subTitle="O projeto será enviado para o próximo procedimento"
        opConfirm="Sim"
        type={typeConfirmProcedure}
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />

      <NoticeDialog
        isOpen={isOpenNotice}
        type="error"
        title={titleNotice}
        subTitle={subTitleNotice}
        setIsOpen={setIsOpenNotice}
      />
    </Container>
  );
};

export default Procedure6;
