import React from 'react';
import { useLocation } from 'react-router-dom';

import DefaultLayout from '../../_layouts/default';

import List from './List';
import New from './New';

const Samples: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <DefaultLayout>
      {pathname === '/items/samples' ? <List /> : <New />}
    </DefaultLayout>
  );
};

export default Samples;
