import styled, { css } from 'styled-components';

interface IDropContainerProps {
  isDragActive?: boolean;
  isDragReject?: boolean;
}

interface IUploadMessageProps {
  type?: string;
}

export const DropContainer = styled.div<IDropContainerProps>`
  ${({ theme, isDragActive, isDragReject }) => css`
    border: 1px dashed #ddd;
    border-radius: 4px;
    cursor: pointer;

    transition: height 0.2s ease;

    ${isDragActive &&
    css`
      border-color: ${theme.colors.vividCyan};
    `}

    ${isDragReject &&
    css`
      border-color: ${theme.colors.danger};
    `}
  `}
`;

export const UploadMessage = styled.p<IUploadMessageProps>`
  ${({ theme, type }) => css`
    display: flex;
    color: ${theme.colors.grey};
    font-size: ${theme.fontSizes.default};
    justify-content: center;
    align-items: center;
    padding: 15px 0;

    ${type === 'success' &&
    css`
      color: ${theme.colors.vividCyan};
    `}

    ${type === 'error' &&
    css`
      color: ${theme.colors.error};
    `}
  `}
`;
