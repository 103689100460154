import styled, { css } from 'styled-components';

import { IDeletePreviousProps } from '.';

export const Container = styled.button<IDeletePreviousProps>`
  ${({ theme, marginTop, marginLeft, isDisabled }) => css`
    background-color: transparent;
    color: ${theme.colors.delete};
    border-radius: 50%;

    &:hover {
      transform: scale(1.2, 1.2);
    }

    ${marginTop &&
    css`
      margin-top: ${marginTop};
    `}

    ${marginLeft &&
    css`
      margin-left: ${marginLeft};
    `}

    ${isDisabled &&
    css`
      &:hover {
        transform: scale(1, 1);
      }
    `}
  `}
`;
