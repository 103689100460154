import React, { createContext, useState, useCallback, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import filesize from 'filesize';

import api from '~/services/api';

export interface IPost {
  approval_file_temp_id: string;
  name: string;
  size: number;
  key: string;
  url: string;
}

export interface IApprovalFile {
  id: string;
  name: string;
  readableSize: string;
  type: string;
  uploaded?: boolean;
  preview: string;
  file: File | null;
  progress?: number;
  error?: boolean;
  url: string;
}

interface IApprovalFileContextData {
  uploadedApprovalFiles: IApprovalFile[];
  deleteApprovalFile(id: string): void;
  handleUpload(
    file: any,
    type: string,
    projectApprovalTempId: string,
    projectType: string,
    sequence: number,
    userId: string,
  ): void;
}

const ApprovalFileContext = createContext<IApprovalFileContextData>(
  {} as IApprovalFileContextData,
);

const ApprovalFileProvider: React.FC = ({ children }) => {
  const [uploadedApprovalFiles, setUploadedApprovalFiles] = useState<
    IApprovalFile[]
  >([]);

  const updateApprovalFile = useCallback((id, data) => {
    setUploadedApprovalFiles(state =>
      state.map(file => (file.id === id ? { ...file, ...data } : file)),
    );
  }, []);

  const processUpload = useCallback(
    (
      uploadedApprovalFile: IApprovalFile,
      type: string,
      projectApprovalTempId: string,
      projectType: string,
      sequence: number,
      userId: string,
    ) => {
      const data = new FormData();
      if (uploadedApprovalFile.file) {
        data.append('type', type);
        data.append('project_approval_temp_id', projectApprovalTempId);
        data.append('project_type', projectType);
        data.append('sequence', String(sequence));
        data.append('approval_file_temp_id', uploadedApprovalFile.id);
        data.append('user_id', userId);
        data.append(
          'file',
          uploadedApprovalFile.file,
          uploadedApprovalFile.name,
        );
      }

      api
        .post('/projectapprovalfilesreceived', data, {
          onUploadProgress: progressEvent => {
            const progress: number = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );

            updateApprovalFile(uploadedApprovalFile.id, { progress });
          },
        })
        .then(response => {
          updateApprovalFile(uploadedApprovalFile.id, {
            uploaded: true,
            id: response.data.id,
            url: response.data.url,
          });
        })
        .catch(err => {
          console.log(err);
          updateApprovalFile(uploadedApprovalFile.id, {
            error: true,
          });
        });
    },
    [updateApprovalFile],
  );

  const handleUpload = useCallback(
    (
      files: File[],
      type: string,
      projectTempId: string,
      projectType: string,
      sequence: number,
      userId: string,
    ) => {
      const newUploadedApprovalFiles: IApprovalFile[] = files.map(
        (file: File) => ({
          file,
          id: uuidv4(),
          name: file.name,
          readableSize: filesize(file.size),
          type: file.type,
          preview: URL.createObjectURL(file),
          progress: 0,
          uploaded: false,
          error: false,
          url: '',
        }),
      );

      setUploadedApprovalFiles(state => state.concat(newUploadedApprovalFiles));
      newUploadedApprovalFiles.forEach(upApprovalFile =>
        processUpload(
          upApprovalFile,
          type,
          projectTempId,
          projectType,
          sequence,
          userId,
        ),
      );
    },
    [processUpload],
  );

  const deleteApprovalFile = useCallback((id: string) => {
    api.delete(`projectapprovalfilesreceived/${id}`);
    setUploadedApprovalFiles(state => state.filter(file => file.id !== id));
  }, []);

  return (
    <ApprovalFileContext.Provider
      value={{ uploadedApprovalFiles, deleteApprovalFile, handleUpload }}
    >
      {children}
    </ApprovalFileContext.Provider>
  );
};

function useApprovalFiles(): IApprovalFileContextData {
  const context = useContext(ApprovalFileContext);

  if (!context) {
    throw new Error(
      'useApprovalFiles deve ser usado dentro de um ApprovalFileProvider',
    );
  }

  return context;
}

export { ApprovalFileProvider, useApprovalFiles };
