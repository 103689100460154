import React, { createContext, useState, useCallback, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import filesize from 'filesize';

import api from '~/services/api';

export interface IPost {
  custom_proj_file_temp_id: string;
  name: string;
  size: number;
  key: string;
  url: string;
}

export interface ICustomProjectFile {
  id: string;
  name: string;
  readableSize: string;
  type: string;
  uploaded?: boolean;
  preview: string;
  file: File | null;
  progress?: number;
  error?: boolean;
  url: string;
}

interface ICustomProjectFileContextData {
  uploadedCustomProjectFiles: ICustomProjectFile[];
  deleteCustomProjectFile(id: string): void;
  handleUpload(
    file: any,
    type: string,
    projectCustomProjectTempId: string,
    projectType: string,
    sequence: number,
    userId: string,
  ): void;
}

const CustomProjectFileContext = createContext<ICustomProjectFileContextData>(
  {} as ICustomProjectFileContextData,
);

const CustomProjectFileProvider: React.FC = ({ children }) => {
  const [uploadedCustomProjectFiles, setUploadedCustomProjectFiles] = useState<
    ICustomProjectFile[]
  >([]);

  const updateCustomProjectFile = useCallback((id, data) => {
    setUploadedCustomProjectFiles(state =>
      state.map(file => (file.id === id ? { ...file, ...data } : file)),
    );
  }, []);

  const processUpload = useCallback(
    (
      uploadedCustomProjectFile: ICustomProjectFile,
      type: string,
      projectCustomProjectTempId: string,
      projectType: string,
      sequence: number,
      userId: string,
    ) => {
      const data = new FormData();
      if (uploadedCustomProjectFile.file) {
        data.append('type', type);
        data.append('project_custom_proj_temp_id', projectCustomProjectTempId);
        data.append('project_type', projectType);
        data.append('sequence', String(sequence));
        data.append('custom_proj_file_temp_id', uploadedCustomProjectFile.id);
        data.append('user_id', userId);
        data.append(
          'file',
          uploadedCustomProjectFile.file,
          uploadedCustomProjectFile.name,
        );
      }

      api
        .post('/projectcustomprojectfilesreceived', data, {
          onUploadProgress: progressEvent => {
            const progress: number = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );

            updateCustomProjectFile(uploadedCustomProjectFile.id, { progress });
          },
        })
        .then(response => {
          updateCustomProjectFile(uploadedCustomProjectFile.id, {
            uploaded: true,
            id: response.data.id,
            url: response.data.url,
          });
        })
        .catch(err => {
          console.log(err);
          updateCustomProjectFile(uploadedCustomProjectFile.id, {
            error: true,
          });
        });
    },
    [updateCustomProjectFile],
  );

  const handleUpload = useCallback(
    (
      files: File[],
      type: string,
      projectTempId: string,
      projectType: string,
      sequence: number,
      userId: string,
    ) => {
      const newUploadedCustomProjectFiles: ICustomProjectFile[] = files.map(
        (file: File) => ({
          file,
          id: uuidv4(),
          name: file.name,
          readableSize: filesize(file.size),
          type: file.type,
          preview: URL.createObjectURL(file),
          progress: 0,
          uploaded: false,
          error: false,
          url: '',
        }),
      );

      setUploadedCustomProjectFiles(state =>
        state.concat(newUploadedCustomProjectFiles),
      );
      newUploadedCustomProjectFiles.forEach(upCustomProjectFile =>
        processUpload(
          upCustomProjectFile,
          type,
          projectTempId,
          projectType,
          sequence,
          userId,
        ),
      );
    },
    [processUpload],
  );

  const deleteCustomProjectFile = useCallback((id: string) => {
    api.delete(`projectcustomprojectfilesreceived/${id}`);
    setUploadedCustomProjectFiles(state =>
      state.filter(file => file.id !== id),
    );
  }, []);

  return (
    <CustomProjectFileContext.Provider
      value={{
        uploadedCustomProjectFiles,
        deleteCustomProjectFile,
        handleUpload,
      }}
    >
      {children}
    </CustomProjectFileContext.Provider>
  );
};

function useCustomProjectFiles(): ICustomProjectFileContextData {
  const context = useContext(CustomProjectFileContext);

  if (!context) {
    throw new Error(
      'useCustomProjectFiles deve ser usado dentro de um CustomProjectFileProvider',
    );
  }

  return context;
}

export { CustomProjectFileProvider, useCustomProjectFiles };
