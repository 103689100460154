import React from 'react';

import DefaultLayout from '../../_layouts/default';

import List from './List';

const All: React.FC = () => {
  function getComponent(): React.ReactNode {
    return <List />;
  }

  return <DefaultLayout>{getComponent()}</DefaultLayout>;
};

export default All;
