import React from 'react';
import { FaExclamation } from 'react-icons/fa';

import { Container } from './styles';

interface IErrorProps {
  title: string | undefined;
}

const Error: React.FC<IErrorProps> = ({ title }) => {
  return (
    <Container title={title}>
      <FaExclamation size={20} />
    </Container>
  );
};

export default Error;
