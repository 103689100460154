import React, { useState, useEffect } from 'react';

import Representative from '~/components/ProjectSections/Representative';
import Client from '~/components/ProjectSections/Client';
import ClientPatterns from '~/components/ProjectSections/ClientPatterns';
import InvoiceClient from '~/components/ProjectSections/InvoiceClient';
import Main from '~/components/ProjectSections/Main';
import ClientContact from '~/components/ProjectSections/ClientContact';
import Records from '~/components/ProjectSections/Records';
import Files from '~/components/ProjectSections/Files';
import RequestFiles from '~/components/ProjectSections/RequestFiles';
import DiscountPrices from '~/components/ProjectSections/DiscountPrices';
import SponsorshipSheets from '~/components/ProjectSections/SponsorshipSheets';
import ApprovalFiles from '~/components/ProjectSections/ApprovalFiles';
import CompletionFiles from '~/components/ProjectSections/CompletionFiles';
import TaxDeductions from '~/components/ProjectSections/TaxDeductions';
import Invoices from '~/components/ProjectSections/Invoices';

import api from '~/services/api';

interface ISponsorshipProps {
  project_id: string;
  all?: boolean;
}

interface IProject {
  id: string;
  type: string;
  code: string;
  price: number;
  gross_discount_price: number;
  net_discount_price: number;
  canceled_discount_price: number;
  granted_discount_price: number;
  total_tax_deductions_price: number;
  total_sponsorship_sheets_price: number;
  erp_client_code: string;
  erp_rep_code: string;
  note: string;
  rebate_campaign: boolean;
  sponsorship_art_approval: boolean;
  sponsorship_completion_files: boolean;
  client_contact_name: string;
  client_contact_phone: string;
  client_contact_email: string;
  erp_invoice_client_code: string;
  project_procedure_id: string;
  sponsorship: {
    code: string;
    name: string;
  };
  project_procedure: {
    id: string;
    sequence: number;
  };
}

const Sponsorship: React.FC<ISponsorshipProps> = ({ project_id, all }) => {
  const [project, setProject] = useState<IProject>();

  useEffect(() => {
    api.get(`/projects/id?id=${project_id}`).then(response => {
      setProject(response.data);
    });
  }, [project_id]);

  function handleRepresentative(): React.ReactNode | null {
    if (project) {
      return (
        <Representative
          erp_rep_code={project.erp_rep_code ? project.erp_rep_code : ''}
        />
      );
    }

    return null;
  }

  function handleClient(): React.ReactNode | null {
    if (project) {
      return (
        <Client
          rep_as_client={false}
          erp_client_code={
            project.erp_client_code ? project.erp_client_code : ''
          }
          erp_rep_code=""
        />
      );
    }

    return null;
  }

  function handleClientPatterns(): React.ReactNode | null {
    if (project) {
      return (
        <ClientPatterns
          erp_client_code={
            project.erp_client_code ? project.erp_client_code : ''
          }
        />
      );
    }

    return null;
  }

  function handleInvoiceClient(): React.ReactNode | null {
    if (
      project &&
      project.erp_invoice_client_code &&
      project.erp_invoice_client_code !== ''
    ) {
      return (
        <InvoiceClient
          erp_invoice_client_code={project.erp_invoice_client_code}
        />
      );
    }

    return null;
  }

  function handleMain(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence > 1 || all) {
        return (
          <Main
            code={project.code ? project.code : ''}
            item={
              project.sponsorship.code && project.sponsorship.name
                ? `(${project.sponsorship.code}) - ${project.sponsorship.name}`
                : ''
            }
            note={project.note ? project.note : ''}
            rebate_campaign={project.rebate_campaign}
            sponsorship_art_approval={project.sponsorship_art_approval}
            sponsorship_completion_files={project.sponsorship_completion_files}
          />
        );
      }
    }

    return null;
  }

  function handleClientContact(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence > 1 || all) {
        return (
          <ClientContact
            client_contact_name={
              project.client_contact_name ? project.client_contact_name : ''
            }
            client_contact_phone={
              project.client_contact_phone ? project.client_contact_phone : ''
            }
            client_contact_email={
              project.client_contact_email ? project.client_contact_email : ''
            }
          />
        );
      }
    }

    return null;
  }

  function handleRecords(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence > 1 || all) {
        return <Records project_id={project.id} />;
      }
    }

    return null;
  }

  function handleFiles(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence > 1 || all) {
        return (
          <Files
            project_id={project.id}
            project_procedure_id={project.project_procedure.id}
          />
        );
      }
    }

    return null;
  }

  function handleRequestFiles(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence > 1 || all) {
        return (
          <RequestFiles
            project_id={project.id}
            project_procedure_id={project.project_procedure.id}
          />
        );
      }
    }

    return null;
  }

  function handleDiscountPrice(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence > 1 || all) {
        return (
          <DiscountPrices
            gross_discount_price={project.gross_discount_price}
            total_sponsorship_sheets_price={
              project.total_sponsorship_sheets_price
            }
            total_tax_deductions_price={project.total_tax_deductions_price}
            net_discount_price={project.net_discount_price}
            canceled_discount_price={project.canceled_discount_price}
            granted_discount_price={project.granted_discount_price}
          />
        );
      }
    }

    return null;
  }

  function handleSponsorshipSheets(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence > 1 || all) {
        return <SponsorshipSheets project_id={project.id} />;
      }
    }

    return null;
  }

  function handleApprovalFiles(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence > 4 || all) {
        return (
          <ApprovalFiles
            project_id={project.id}
            project_procedure_id={project.project_procedure.id}
          />
        );
      }
    }

    return null;
  }

  function handleCompletionFiles(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence > 6 || all) {
        return (
          <CompletionFiles
            project_id={project.id}
            project_procedure_id={project.project_procedure.id}
          />
        );
      }
    }

    return null;
  }

  function handleTaxDeductions(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence > 8 || all) {
        return <TaxDeductions project_id={project.id} />;
      }
    }

    return null;
  }

  function handleInvoices(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence === 10 || all) {
        return <Invoices project_id={project.id} />;
      }
    }

    return null;
  }

  return (
    <>
      {handleRepresentative()}
      {handleClient()}
      {handleClientPatterns()}
      {handleInvoiceClient()}
      {handleMain()}
      {handleClientContact()}
      {handleRecords()}
      {handleFiles()}
      {handleRequestFiles()}
      {handleDiscountPrice()}
      {handleSponsorshipSheets()}
      {handleApprovalFiles()}
      {handleCompletionFiles()}
      {handleTaxDeductions()}
      {handleInvoices()}
    </>
  );
};

export default Sponsorship;
