import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface IContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored?: boolean;
}

export const Container = styled.div<IContainerProps>`
  ${({ theme, isFocused, isFilled, isErrored }) => css`
    display: flex;
    position: relative;
    background: ${theme.colors.lightGrey};
    border-radius: ${theme.radius.small};
    padding: 10px;
    width: 100%;
    margin: 23px 18px 18px 2px;
    border: 1px solid #e4e4e4;
    align-items: center;

    svg {
      color: #cecece;
      margin: 0 6px;
    }

    input {
      :disabled {
        color: ${theme.colors.inputDisabled};
      }
    }

    ${isFocused &&
    css`
      border: 1px solid ${theme.colors.primary};
      color: ${theme.colors.primary};

      > svg {
        color: ${theme.colors.primary};
      }
    `}

    ${isFilled &&
    css`
      color: ${theme.colors.primary};

      > svg {
        color: ${theme.colors.primary};
      }
    `}

    ${isErrored &&
    css`
      border: 1px solid ${theme.colors.error};

      > svg {
        color: ${theme.colors.error};
      }
    `}

    input {
      flex: 1;
      border: 0;
      background: transparent;
      font-size: 1.6rem;
    }

    button {
      background-color: transparent;
    }
  `}
`;

export const Label = styled.label<IContainerProps>`
  ${({ theme, isFocused }) => css`
    width: 0;
    height: 0;
    font-size: ${theme.fontSizes.default};
    white-space: nowrap;
    color: ${theme.colors.labelInput};

    ${isFocused &&
    css`
      color: ${theme.colors.primary};
    `}
  `}
`;

export const Error = styled(Tooltip)`
  ${({ theme }) => css`
    height: 20px;

    svg {
      margin-right: 0;
    }

    span {
      background: ${theme.colors.secondary};
      color: #fff;
    }

    &::before {
      border-color: ${theme.colors.secondary} transparent;
    }
  `}
`;
