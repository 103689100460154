import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { FaProjectDiagram, FaCalendarAlt } from 'react-icons/fa';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import BreadCrumb from '~/components/BreadCrumb';
import ProjectInfo from '~/components/ProjectInfo/Default';
import CompleteIcon from '~/components/TimelineElements/CompleteIcon';
import PendingIcon from '~/components/TimelineElements/PendingIcon';
import Title from '~/components/TimelineElements/Title';
import Topic from '~/components/TimelineElements/Topic';
import Gradient from '~/components/TimelineElements/Gradient';
import DetailTitle from '~/components/TimelineElements/DetailTitle';
import DetailProcedure from '~/components/TimelineElements/DetailProcedure';
import DetailInfo from '~/components/TimelineElements/DetailInfo';
import Code from '~/components/TimelineItems/Code';
import Representative from '~/components/TimelineItems/Representative';
import Client from '~/components/TimelineItems/Client';
import Item from '~/components/TimelineItems/Item';
import ClientContact from '~/components/TimelineItems/ClientContact';
import RequestedEventDate from '~/components/TimelineItems/RequestedEventDate';
import EstEventPartNumber from '~/components/TimelineItems/EstEventPartNumber';
import Observation from '~/components/TimelineItems/Observation';
import Records from '~/components/TimelineItems/Records';
import Files from '~/components/TimelineItems/Files';
import RequestFiles from '~/components/TimelineItems/RequestFiles';
import Validation from '~/components/TimelineItems/Validation';
import RecordNote from '~/components/TimelineItems/RecordNote';
import EventDate from '~/components/TimelineItems/EventDate';
import Gifts from '~/components/TimelineItems/Gifts';
import EventCosts from '~/components/TimelineItems/EventCosts';
import MaterialDeliveryStatus from '~/components/TimelineItems/MaterialDeliveryStatus';
import CompletionFiles from '~/components/TimelineItems/CompletionFiles';
import Completion from '~/components/TimelineItems/Completion';
import ExitButton from '~/components/Buttons/Exit';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import {
  Container,
  TabTitle,
  ProcedureContent,
  MainContent,
  TitleContent,
  TitleTextWrapper,
  InfoContent,
  DetailContent,
  DetailItemWrapper,
  ButtonContainer,
} from './styles';

interface IDetail {
  procedure: string;
  information: string;
  component: string;
}

interface ILocation {
  id: string;
  type: string;
  code: string;
  erp_client_code: string;
  erp_rep_code: string;
  note: string;
  status: string;
  client_contact_name: string;
  client_contact_phone: string;
  client_contact_email: string;
  estimated_event_participants_number: number;
  event_date: Date;
  requested_event_date: Date;
  material_delivery_status: string;
  event: {
    code: string;
    name: string;
  };
  project_procedure: {
    sequence: number;
  };
}

const View: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [tabIndex, setTabIndex] = useState(0);
  const [detail, setDetail] = useState<IDetail>();

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto não localizado',
      });

      history.push('/projects/events');
    } else if (
      user.user_type.type === 'REP' &&
      state.erp_rep_code !== user.erp_rep_code
    ) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Usuário não permitido',
      });

      history.push('/projects/events');
    }
  }, [addToast, history, state, user]);

  function handleFiles(
    procedure: string,
    information: string,
    component: string,
  ): JSX.Element | null {
    if (user.user_type.type !== 'REP') {
      return (
        <Topic
          label="- Anexos"
          onClick={() => {
            setDetail({ procedure, information, component });
          }}
        />
      );
    }

    return null;
  }

  function handleProcedure1(): JSX.Element | null {
    if (
      state?.project_procedure &&
      state.project_procedure.sequence &&
      state.project_procedure.sequence > 1
    ) {
      return (
        <MainContent>
          <TitleContent>
            <CompleteIcon />

            <TitleTextWrapper>
              <Title label="Solicitação" />
            </TitleTextWrapper>
          </TitleContent>

          <InfoContent>
            <Gradient height="500px" />

            <div>
              <Topic
                label="- Código"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Código',
                    component: '01_code',
                  });
                }}
              />

              <Topic
                label="- Representante"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Representante',
                    component: '01_representative',
                  });
                }}
              />

              <Topic
                label="- Cliente"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Cliente',
                    component: '01_client',
                  });
                }}
              />

              <Topic
                label="- Item"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Item',
                    component: '01_item',
                  });
                }}
              />

              <Topic
                label="- Contato"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Contato',
                    component: '01_client_contact',
                  });
                }}
              />

              <Topic
                label="- Data Evento Solicitado"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Data Evento Solicitado',
                    component: '01_requested_event_date',
                  });
                }}
              />

              <Topic
                label="- Número Est. Participantes"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Número Est. Participantes',
                    component: '01_est_event_part_number',
                  });
                }}
              />

              <Topic
                label="- Observação Representante"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Observação Representante',
                    component: '01_representative_obs',
                  });
                }}
              />

              <Topic
                label="- Histórico"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Histórico',
                    component: '01_records',
                  });
                }}
              />

              {handleFiles('Solicitação', 'Anexos', '01_files')}

              <Topic
                label="- Anexos de Requisição"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Anexos de Requisição',
                    component: '01_request_files',
                  });
                }}
              />
            </div>
          </InfoContent>
        </MainContent>
      );
    }

    return null;
  }

  function handleProcedure2(): JSX.Element | null {
    if (
      state?.project_procedure &&
      state.project_procedure.sequence &&
      state.project_procedure.sequence > 2
    ) {
      return (
        <MainContent>
          <TitleContent>
            <CompleteIcon />

            <TitleTextWrapper>
              <Title label="Solicitação de Evento" />
            </TitleTextWrapper>
          </TitleContent>

          <InfoContent>
            <Gradient height="400px" />

            <div>
              <Topic
                label="- Data Evento Oficial"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação de Evento',
                    information: 'Data Evento Oficial',
                    component: '02_event_date',
                  });
                }}
              />

              <Topic
                label="- Brindes"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação de Evento',
                    information: 'Brindes',
                    component: '02_gifts',
                  });
                }}
              />

              <Topic
                label="- Custos Evento"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação de Evento',
                    information: 'Custos Evento',
                    component: '02_event_costs',
                  });
                }}
              />

              <Topic
                label="- Observação"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação de Evento',
                    information: 'Observação',
                    component: '02_obs',
                  });
                }}
              />

              <Topic
                label="- Histórico"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação de Evento',
                    information: 'Histórico',
                    component: '02_records',
                  });
                }}
              />

              {handleFiles('Solicitação de Evento', 'Anexos', '02_files')}
            </div>
          </InfoContent>
        </MainContent>
      );
    }

    return null;
  }

  function handleProcedure3(): JSX.Element | null {
    if (
      state?.project_procedure &&
      state.project_procedure.sequence &&
      (state.project_procedure.sequence > 3 ||
        (state.project_procedure.sequence === 3 &&
          state.status === 'Cancelado'))
    ) {
      return (
        <MainContent>
          <TitleContent>
            <CompleteIcon />

            <TitleTextWrapper>
              <Title label="Aprovação" />
            </TitleTextWrapper>
          </TitleContent>

          <InfoContent>
            <Gradient height="400px" />

            <div>
              <Topic
                label="- Validação"
                onClick={() => {
                  setDetail({
                    procedure: 'Aprovação',
                    information: 'Validação',
                    component: '03_validation',
                  });
                }}
              />

              <Topic
                label="- Observação"
                onClick={() => {
                  setDetail({
                    procedure: 'Aprovação',
                    information: 'Observação',
                    component: '03_obs',
                  });
                }}
              />

              <Topic
                label="- Histórico"
                onClick={() => {
                  setDetail({
                    procedure: 'Aprovação',
                    information: 'Histórico',
                    component: '03_records',
                  });
                }}
              />

              {handleFiles('Aprovação', 'Anexos', '03_files')}
            </div>
          </InfoContent>
        </MainContent>
      );
    }

    return null;
  }

  function handleProcedure4(): JSX.Element | null {
    if (
      state?.project_procedure &&
      state.project_procedure.sequence &&
      (state.project_procedure.sequence > 4 ||
        (state.project_procedure.sequence === 4 &&
          state.status === 'Cancelado'))
    ) {
      return (
        <MainContent>
          <TitleContent>
            <CompleteIcon />

            <TitleTextWrapper>
              <Title label="Aprovação de Custos" />
            </TitleTextWrapper>
          </TitleContent>

          <InfoContent>
            <Gradient height="400px" />

            <div>
              <Topic
                label="- Validação"
                onClick={() => {
                  setDetail({
                    procedure: 'Aprovação de Custos',
                    information: 'Validação',
                    component: '04_validation',
                  });
                }}
              />

              <Topic
                label="- Observação"
                onClick={() => {
                  setDetail({
                    procedure: 'Aprovação de Custos',
                    information: 'Observação',
                    component: '04_obs',
                  });
                }}
              />

              <Topic
                label="- Histórico"
                onClick={() => {
                  setDetail({
                    procedure: 'Aprovação de Custos',
                    information: 'Histórico',
                    component: '04_records',
                  });
                }}
              />

              {handleFiles('Aprovação de Custos', 'Anexos', '04_files')}
            </div>
          </InfoContent>
        </MainContent>
      );
    }

    return null;
  }

  function handleProcedure5(): JSX.Element | null {
    if (
      state?.project_procedure &&
      state.project_procedure.sequence &&
      state.project_procedure.sequence >= 5
    ) {
      return (
        <MainContent>
          <TitleContent>
            <CompleteIcon />

            <TitleTextWrapper>
              <Title label="Alteração Status Entrega" />
            </TitleTextWrapper>
          </TitleContent>

          <InfoContent>
            <Gradient height="400px" />

            <div>
              <Topic
                label="- Status Entrega Material"
                onClick={() => {
                  setDetail({
                    procedure: 'Alteração Status Entrega',
                    information: 'Status Entrega Material',
                    component: '05_material_delivery_status',
                  });
                }}
              />

              <Topic
                label="- Histórico"
                onClick={() => {
                  setDetail({
                    procedure: 'Alteração Status Entrega',
                    information: 'Histórico',
                    component: '05_records',
                  });
                }}
              />

              {handleFiles('Alteração Status Entrega', 'Anexos', '05_files')}
            </div>
          </InfoContent>
        </MainContent>
      );
    }

    return null;
  }

  function handleProcedure6(): JSX.Element | null {
    if (
      state?.project_procedure &&
      state.project_procedure.sequence &&
      state.project_procedure.sequence > 6
    ) {
      return (
        <MainContent>
          <TitleContent>
            <CompleteIcon />

            <TitleTextWrapper>
              <Title label="Anexos de Conclusão" />
            </TitleTextWrapper>
          </TitleContent>

          <InfoContent>
            <Gradient height="400px" />

            <div>
              <Topic
                label="- Anexos de Conclusão"
                onClick={() => {
                  setDetail({
                    procedure: 'Anexos de Conclusão',
                    information: 'Anexos de Conclusão',
                    component: '06_completion_files',
                  });
                }}
              />

              <Topic
                label="- Observação"
                onClick={() => {
                  setDetail({
                    procedure: 'Anexos de Conclusão',
                    information: 'Observação',
                    component: '06_obs',
                  });
                }}
              />

              <Topic
                label="- Histórico"
                onClick={() => {
                  setDetail({
                    procedure: 'Anexos de Conclusão',
                    information: 'Histórico',
                    component: '06_records',
                  });
                }}
              />

              {handleFiles('Anexos de Conclusão', 'Anexos', '06_files')}
            </div>
          </InfoContent>
        </MainContent>
      );
    }

    return null;
  }

  function handleProcedure7(): JSX.Element | null {
    if (
      state?.project_procedure &&
      state.project_procedure.sequence &&
      state.project_procedure.sequence === 7 &&
      state.status !== 'Pendente'
    ) {
      return (
        <MainContent>
          <TitleContent>
            <CompleteIcon />

            <TitleTextWrapper>
              <Title label="Validação Conclusão" />
            </TitleTextWrapper>
          </TitleContent>

          <InfoContent>
            <Gradient height="400px" />

            <div>
              <Topic
                label="- Observação"
                onClick={() => {
                  setDetail({
                    procedure: 'Validação Conclusão',
                    information: 'Observação',
                    component: '07_obs',
                  });
                }}
              />

              <Topic
                label="- Histórico"
                onClick={() => {
                  setDetail({
                    procedure: 'Validação Conclusão',
                    information: 'Histórico',
                    component: '07_records',
                  });
                }}
              />

              {handleFiles('Validação Conclusão', 'Anexos', '07_files')}

              <Topic
                label="- Finalização"
                onClick={() => {
                  setDetail({
                    procedure: 'Validação Conclusão',
                    information: 'Finalização',
                    component: '07_completion',
                  });
                }}
              />
            </div>
          </InfoContent>
        </MainContent>
      );
    }

    return null;
  }

  function handleCurrentProcedure(): JSX.Element | null {
    let title = '';

    if (
      state?.project_procedure &&
      state.project_procedure.sequence === 2 &&
      state.status === 'Pendente'
    ) {
      title = 'Solicitação de Evento';
    }

    if (
      state?.project_procedure &&
      state.project_procedure.sequence === 3 &&
      state.status === 'Pendente'
    ) {
      title = 'Aprovação';
    }

    if (
      state?.project_procedure &&
      state.project_procedure.sequence === 4 &&
      state.status === 'Pendente'
    ) {
      title = 'Aprovação de Custos';
    }

    if (
      state?.project_procedure &&
      state.project_procedure.sequence === 6 &&
      state.status === 'Pendente'
    ) {
      title = 'Anexos de Conclusão';
    }

    if (
      state?.project_procedure &&
      state.project_procedure.sequence === 7 &&
      state.status === 'Pendente'
    ) {
      title = 'Validação Conclusão';
    }

    if (title !== '') {
      return (
        <TitleContent>
          <PendingIcon />

          <TitleTextWrapper>
            <Title label={title} highlighted />
          </TitleTextWrapper>
        </TitleContent>
      );
    }

    return null;
  }

  function handleDetailTitle(): JSX.Element | null {
    if (detail?.procedure || detail?.information) {
      return <DetailTitle />;
    }

    return null;
  }

  function handleDetailProcedure(): JSX.Element | null {
    if (detail?.procedure) {
      return <DetailProcedure label={detail.procedure} />;
    }

    return null;
  }

  function handleDetailInformation(): JSX.Element | null {
    if (detail?.information) {
      return <DetailInfo label={detail.information} />;
    }

    return null;
  }

  function handleDetailComponent(): JSX.Element | null {
    if (detail?.component === '01_code') {
      return <Code code={state?.code ? state.code : ''} />;
    }

    if (detail?.component === '01_representative') {
      return (
        <Representative
          erp_rep_code={state?.erp_rep_code ? state.erp_rep_code : ''}
        />
      );
    }

    if (detail?.component === '01_client') {
      return (
        <Client
          rep_as_client={false}
          erp_client_code={state?.erp_client_code ? state.erp_client_code : ''}
          erp_rep_code=""
        />
      );
    }

    if (detail?.component === '01_item') {
      return (
        <Item
          code={state?.event.code ? state.event.code : ''}
          name={state?.event.name ? state.event.name : ''}
        />
      );
    }

    if (detail?.component === '01_client_contact') {
      return (
        <ClientContact
          client_contact_name={
            state?.client_contact_name ? state.client_contact_name : ''
          }
          client_contact_phone={
            state?.client_contact_phone ? state.client_contact_phone : ''
          }
          client_contact_email={
            state?.client_contact_email ? state.client_contact_email : ''
          }
        />
      );
    }

    if (detail?.component === '01_requested_event_date') {
      return (
        <RequestedEventDate
          requested_event_date={state?.requested_event_date}
        />
      );
    }

    if (detail?.component === '01_est_event_part_number') {
      return (
        <EstEventPartNumber
          estimated_event_participants_number={
            state?.estimated_event_participants_number
          }
        />
      );
    }

    if (detail?.component === '01_representative_obs') {
      return <Observation note={state?.note ? state.note : ''} />;
    }

    if (detail?.component === '01_records') {
      return <Records project_id={state?.id} type="EVENTO" sequence={1} />;
    }

    if (detail?.component === '01_files') {
      return <Files project_id={state?.id} type="EVENTO" sequence={1} />;
    }

    if (detail?.component === '01_request_files') {
      return <RequestFiles project_id={state.id} />;
    }

    if (detail?.component === '02_event_date') {
      return <EventDate event_date={state?.event_date} />;
    }

    if (detail?.component === '02_gifts') {
      return <Gifts project_id={state.id} />;
    }

    if (detail?.component === '02_event_costs') {
      return <EventCosts project_id={state.id} />;
    }

    if (detail?.component === '02_obs') {
      return <RecordNote type="EVENTO" sequence={2} project_id={state.id} />;
    }

    if (detail?.component === '02_records') {
      return <Records project_id={state?.id} type="EVENTO" sequence={2} />;
    }

    if (detail?.component === '02_files') {
      return <Files project_id={state?.id} type="EVENTO" sequence={2} />;
    }

    if (detail?.component === '03_validation') {
      return <Validation type="EVENTO" sequence={3} project_id={state.id} />;
    }

    if (detail?.component === '03_obs') {
      return <RecordNote type="EVENTO" sequence={3} project_id={state.id} />;
    }

    if (detail?.component === '03_records') {
      return <Records project_id={state?.id} type="EVENTO" sequence={3} />;
    }

    if (detail?.component === '03_files') {
      return <Files project_id={state?.id} type="EVENTO" sequence={3} />;
    }

    if (detail?.component === '04_validation') {
      return <Validation type="EVENTO" sequence={4} project_id={state.id} />;
    }

    if (detail?.component === '04_obs') {
      return <RecordNote type="EVENTO" sequence={4} project_id={state.id} />;
    }

    if (detail?.component === '04_records') {
      return <Records project_id={state?.id} type="EVENTO" sequence={4} />;
    }

    if (detail?.component === '04_files') {
      return <Files project_id={state?.id} type="EVENTO" sequence={4} />;
    }

    if (detail?.component === '05_material_delivery_status') {
      return (
        <MaterialDeliveryStatus
          material_delivery_status={state.material_delivery_status}
        />
      );
    }

    if (detail?.component === '05_records') {
      return <Records project_id={state?.id} type="EVENTO" sequence={5} />;
    }

    if (detail?.component === '05_files') {
      return <Files project_id={state?.id} type="EVENTO" sequence={5} />;
    }

    if (detail?.component === '06_completion_files') {
      return <CompletionFiles project_id={state.id} />;
    }

    if (detail?.component === '06_obs') {
      return <RecordNote type="EVENTO" sequence={6} project_id={state.id} />;
    }

    if (detail?.component === '06_records') {
      return <Records project_id={state?.id} type="EVENTO" sequence={6} />;
    }

    if (detail?.component === '06_files') {
      return <Files project_id={state?.id} type="EVENTO" sequence={6} />;
    }

    if (detail?.component === '07_obs') {
      return <RecordNote type="EVENTO" sequence={7} project_id={state.id} />;
    }

    if (detail?.component === '07_records') {
      return <Records project_id={state?.id} type="EVENTO" sequence={7} />;
    }

    if (detail?.component === '07_files') {
      return <Files project_id={state?.id} type="EVENTO" sequence={7} />;
    }

    if (detail?.component === '07_completion') {
      return <Completion type="EVENTO" sequence={7} project_id={state.id} />;
    }

    return null;
  }

  function handleProcedure1Detail(): JSX.Element | null {
    if (detail?.procedure === 'Solicitação') {
      return (
        <DetailContent>
          {handleDetailTitle()}

          {handleDetailProcedure()}

          {handleDetailInformation()}

          <DetailItemWrapper>{handleDetailComponent()}</DetailItemWrapper>
        </DetailContent>
      );
    }

    return null;
  }

  function handleProcedure2Detail(): JSX.Element | null {
    if (detail?.procedure === 'Solicitação de Evento') {
      return (
        <DetailContent>
          {handleDetailTitle()}

          {handleDetailProcedure()}

          {handleDetailInformation()}

          <DetailItemWrapper>{handleDetailComponent()}</DetailItemWrapper>
        </DetailContent>
      );
    }

    return null;
  }

  function handleProcedure3Detail(): JSX.Element | null {
    if (detail?.procedure === 'Aprovação') {
      return (
        <DetailContent>
          {handleDetailTitle()}

          {handleDetailProcedure()}

          {handleDetailInformation()}

          <DetailItemWrapper>{handleDetailComponent()}</DetailItemWrapper>
        </DetailContent>
      );
    }

    return null;
  }

  function handleProcedure4Detail(): JSX.Element | null {
    if (detail?.procedure === 'Aprovação de Custos') {
      return (
        <DetailContent>
          {handleDetailTitle()}

          {handleDetailProcedure()}

          {handleDetailInformation()}

          <DetailItemWrapper>{handleDetailComponent()}</DetailItemWrapper>
        </DetailContent>
      );
    }

    return null;
  }

  function handleProcedure5Detail(): JSX.Element | null {
    if (detail?.procedure === 'Alteração Status Entrega') {
      return (
        <DetailContent>
          {handleDetailTitle()}

          {handleDetailProcedure()}

          {handleDetailInformation()}

          <DetailItemWrapper>{handleDetailComponent()}</DetailItemWrapper>
        </DetailContent>
      );
    }

    return null;
  }

  function handleProcedure6Detail(): JSX.Element | null {
    if (detail?.procedure === 'Anexos de Conclusão') {
      return (
        <DetailContent>
          {handleDetailTitle()}

          {handleDetailProcedure()}

          {handleDetailInformation()}

          <DetailItemWrapper>{handleDetailComponent()}</DetailItemWrapper>
        </DetailContent>
      );
    }

    return null;
  }

  function handleProcedure7Detail(): JSX.Element | null {
    if (detail?.procedure === 'Validação Conclusão') {
      return (
        <DetailContent>
          {handleDetailTitle()}

          {handleDetailProcedure()}

          {handleDetailInformation()}

          <DetailItemWrapper>{handleDetailComponent()}</DetailItemWrapper>
        </DetailContent>
      );
    }

    return null;
  }

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/events"
        firstChild="Eventos"
        secondChild="Visualização Solicitação"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaCalendarAlt}
        secondChildIcon={FaCalendarAlt}
        mainColored
        firstChildColored
      />

      <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <TabList>
          <Tab>
            <TabTitle>Linha do Tempo</TabTitle>
          </Tab>
          <Tab>
            <TabTitle>Geral</TabTitle>
          </Tab>
        </TabList>

        <TabPanel>
          <ProcedureContent>
            {handleProcedure1()}
            {handleProcedure1Detail()}
          </ProcedureContent>

          <ProcedureContent>
            {handleProcedure2()}
            {handleProcedure2Detail()}
          </ProcedureContent>

          <ProcedureContent>
            {handleProcedure3()}
            {handleProcedure3Detail()}
          </ProcedureContent>

          <ProcedureContent>
            {handleProcedure4()}
            {handleProcedure4Detail()}
          </ProcedureContent>

          <ProcedureContent>
            {handleProcedure5()}
            {handleProcedure5Detail()}
          </ProcedureContent>

          <ProcedureContent>
            {handleProcedure6()}
            {handleProcedure6Detail()}
          </ProcedureContent>

          <ProcedureContent>
            {handleProcedure7()}
            {handleProcedure7Detail()}
          </ProcedureContent>

          {handleCurrentProcedure()}
        </TabPanel>

        <TabPanel>
          <ProjectInfo project_id={state.id} all />
        </TabPanel>
      </Tabs>
      <ButtonContainer>
        <Link
          to={{
            pathname: '/projects/events',
          }}
        >
          <ExitButton type="button" />
        </Link>
      </ButtonContainer>
    </Container>
  );
};

export default View;
