import React, { useState, useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { FaAngleDown } from 'react-icons/fa';

import { useAuth } from '~/hooks/auth';

import api from '~/services/api';

import InfoInput from '~/components/Inputs/Info';

import { MainContainer, Label, Container, Content, Wrapper } from './styles';

interface IClientPatternsProps {
  erp_client_code: string;
}

interface IPattern {
  client_code: string;
  evaluation: string;
  amount_p: number;
  initial_date: Date;
  end_date: Date;
  initial_date_pd: Date;
  end_date_pd: Date;
}

const ClientPatterns: React.FC<IClientPatternsProps> = ({
  erp_client_code,
}) => {
  const { user } = useAuth();
  const [pattern, setPattern] = useState<IPattern>();

  useEffect(() => {
    if (erp_client_code !== '') {
      api
        .get(`/patterns/clientcode?erp_client_code=${erp_client_code}`)
        .then(response => {
          setPattern(response.data);
        });
    }
  }, [erp_client_code]);

  if (user.user_type.type === 'REP') {
    return null;
  }

  return (
    <MainContainer>
      <Accordion>
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Label>Padrões Cliente</Label>
        </AccordionSummary>

        <AccordionDetails>
          <Container>
            <Content>
              <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
                <InfoInput
                  name="evaluation"
                  labelFor="evaluation"
                  labelText="Avaliação"
                  type="text"
                  defaultValue={pattern?.evaluation ? pattern.evaluation : ''}
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '50%' }}>
                <InfoInput
                  name="amount"
                  labelFor="amount"
                  labelText="Quantidade"
                  type="text"
                  defaultValue={pattern?.amount_p ? pattern.amount_p : ''}
                  disabled
                />
              </Wrapper>
            </Content>

            <Content>
              <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
                <InfoInput
                  name="initial_date"
                  labelFor="initial_date"
                  labelText="Data Início Vol."
                  type="text"
                  value={
                    pattern?.initial_date !== undefined
                      ? new Date(pattern.initial_date).toLocaleDateString()
                      : 'Indefinido'
                  }
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '50%' }}>
                <InfoInput
                  name="end_date"
                  labelFor="end_date"
                  labelText="Data Fim Vol."
                  type="text"
                  value={
                    pattern?.end_date !== undefined
                      ? new Date(pattern.end_date).toLocaleDateString()
                      : 'Indefinido'
                  }
                  disabled
                />
              </Wrapper>
            </Content>

            <Content>
              <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
                <InfoInput
                  name="initial_date_pd"
                  labelFor="initial_date_pd"
                  labelText="Data Início Padrões"
                  type="text"
                  value={
                    pattern?.initial_date_pd !== undefined
                      ? new Date(pattern.initial_date_pd).toLocaleDateString()
                      : 'Indefinido'
                  }
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '50%' }}>
                <InfoInput
                  name="end_date_pd"
                  labelFor="end_date_pd"
                  labelText="Data Fim Padrões"
                  type="text"
                  value={
                    pattern?.end_date_pd !== undefined
                      ? new Date(pattern.end_date_pd).toLocaleDateString()
                      : 'Indefinido'
                  }
                  disabled
                />
              </Wrapper>
            </Content>
          </Container>
        </AccordionDetails>
      </Accordion>
    </MainContainer>
  );
};

export default ClientPatterns;
