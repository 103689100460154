import React, { useState, useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { FaAngleDown } from 'react-icons/fa';

import api from '~/services/api';

import InfoInput from '~/components/Inputs/Info';

import {
  MainContainer,
  Label,
  Container,
  Content,
  ContentSplit,
  Wrapper,
  HalfWrapper,
  ThreeTenthsWrapper,
  SevenTenthsWrapper,
} from './styles';

interface IClientProps {
  erp_client_code: string;
  trade_name: string;
  store_relationship_category_id: string;
}

interface IClient {
  code: string;
  name: string;
  company_name: string;
  document: string;
  address: string;
  city: string;
  state: string;
  sales_channel_code: string;
}

interface IStoreRelationship {
  id: string;
  code: string;
  erp_client_code: string;
  erp_rep_code: string;
  company_name: string;
  trade_name: string;
  address: string;
  city: string;
  state: string;
  client_contact_name: string;
  client_contact_phone: string;
  client_contact_email: string;
  note: string;
  store_relationship_category_id: string;
  created_at: string;
}

interface IClientGroup {
  description: string;
}

interface IStoreRelationshipCategories {
  id: string;
  name: string;
  code: string;
}

interface ISalesChannel {
  code: string;
  description: string;
}

const Client: React.FC<IClientProps> = ({
  erp_client_code,
  trade_name,
  store_relationship_category_id,
}) => {
  const [client, setClient] = useState<IClient>();
  const [storeRelationship, setStoreRelationship] =
    useState<IStoreRelationship>();
  const [clientGroup, setClientGroup] = useState<IClientGroup>();
  const [salesChannel, setSalesChannel] = useState<ISalesChannel>();
  const [storeRelationshipCategories, setStoreRelationshipCategories] =
    useState<IStoreRelationshipCategories[]>();
  const [storeRelationshipCategory, setStoreRelationshipCategory] =
    useState('');

  useEffect(() => {
    if (erp_client_code !== '') {
      api.get(`/clients/code?code=${erp_client_code}`).then(response => {
        setClient(response.data);
      });

      api
        .get(`/storerelationships/client?erp_client_code=${erp_client_code}`)
        .then(response => {
          setStoreRelationship(response.data);
        });

      api
        .get(`/clientgroups/clientcode?erp_client_code=${erp_client_code}`)
        .then(response => {
          setClientGroup(response.data);
        });
    }
  }, [erp_client_code]);

  useEffect(() => {
    api
      .get('/configurations/storerelationshipcategories/valid')
      .then(response => {
        setStoreRelationshipCategories(response.data);
      });
  }, []);

  useEffect(() => {
    if (storeRelationshipCategories) {
      if (storeRelationshipCategories.length > 0) {
        const storeRelCategory = storeRelationshipCategories.find(
          stRelCategory => stRelCategory.id === store_relationship_category_id,
        );

        if (storeRelCategory) {
          setStoreRelationshipCategory(
            `(${storeRelCategory.code}) - ${storeRelCategory.name}`,
          );
        }
      }
    }
  }, [storeRelationshipCategories, store_relationship_category_id]);

  useEffect(() => {
    if (client) {
      if (client.sales_channel_code) {
        api
          .get(`/saleschannels/code?code=${client.sales_channel_code}`)
          .then(response => {
            setSalesChannel(response.data);
          });
      }
    }
  }, [client]);

  return (
    <MainContainer>
      <Accordion>
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Label>Cliente</Label>
        </AccordionSummary>

        <AccordionDetails>
          <Container>
            <ContentSplit maxWidth="680px">
              <ThreeTenthsWrapper marginRight="1rem">
                <InfoInput
                  name="code"
                  labelFor="code"
                  labelText="Código"
                  type="text"
                  defaultValue={client?.code ? client.code : ''}
                  disabled
                />
              </ThreeTenthsWrapper>

              <SevenTenthsWrapper>
                <InfoInput
                  name="company_name"
                  labelFor="company_name"
                  labelText="Razão Social"
                  type="text"
                  defaultValue={
                    storeRelationship?.company_name &&
                    storeRelationship?.company_name !== ''
                      ? storeRelationship.company_name
                      : client?.company_name
                  }
                  disabled
                />
              </SevenTenthsWrapper>
            </ContentSplit>

            <ContentSplit maxWidth="680px">
              <HalfWrapper marginRight="1rem">
                <InfoInput
                  name="trade_name"
                  labelFor="trade_name"
                  labelText="Nome Fantasia"
                  type="text"
                  defaultValue={trade_name.toUpperCase()}
                  disabled
                />
              </HalfWrapper>

              <HalfWrapper>
                <InfoInput
                  name="name"
                  labelFor="name"
                  labelText="Nome Abreviado"
                  type="text"
                  defaultValue={client?.name ? client.name : ''}
                  disabled
                />
              </HalfWrapper>
            </ContentSplit>

            <ContentSplit maxWidth="680px">
              <ThreeTenthsWrapper marginRight="1rem">
                <InfoInput
                  name="document"
                  labelFor="document"
                  labelText="CNPJ"
                  type="text"
                  defaultValue={client?.document ? client.document : ''}
                  disabled
                />
              </ThreeTenthsWrapper>

              <SevenTenthsWrapper>
                <InfoInput
                  name="address"
                  labelFor="address"
                  labelText="Endereço"
                  type="text"
                  defaultValue={
                    storeRelationship?.address &&
                    storeRelationship?.address !== ''
                      ? storeRelationship.address
                      : client?.address
                  }
                  disabled
                />
              </SevenTenthsWrapper>
            </ContentSplit>

            <ContentSplit maxWidth="680px">
              <HalfWrapper marginRight="1rem">
                <InfoInput
                  name="city"
                  labelFor="city"
                  labelText="Cidade"
                  type="text"
                  defaultValue={
                    storeRelationship?.city && storeRelationship?.city !== ''
                      ? storeRelationship.city
                      : client?.city
                  }
                  disabled
                />
              </HalfWrapper>

              <HalfWrapper>
                <InfoInput
                  name="state"
                  labelFor="state"
                  labelText="Estado"
                  type="text"
                  defaultValue={
                    storeRelationship?.state && storeRelationship?.state !== ''
                      ? storeRelationship.state
                      : client?.state
                  }
                  disabled
                />
              </HalfWrapper>
            </ContentSplit>

            <ContentSplit maxWidth="680px">
              <HalfWrapper marginRight="1rem">
                <InfoInput
                  name="sales_channel"
                  labelFor="sales_channel"
                  labelText="Canal de Venda"
                  type="text"
                  defaultValue={
                    salesChannel?.description ? salesChannel.description : ''
                  }
                  disabled
                />
              </HalfWrapper>

              <HalfWrapper>
                <InfoInput
                  name="group"
                  labelFor="group"
                  labelText="Grupo"
                  type="text"
                  defaultValue={
                    clientGroup?.description ? clientGroup.description : ''
                  }
                  disabled
                />
              </HalfWrapper>
            </ContentSplit>

            <Content maxWidth="680px">
              <Wrapper>
                <InfoInput
                  name="category"
                  labelFor="category"
                  labelText="Categoria"
                  type="text"
                  defaultValue={storeRelationshipCategory}
                  disabled
                />
              </Wrapper>
            </Content>
          </Container>
        </AccordionDetails>
      </Accordion>
    </MainContainer>
  );
};

export default Client;
