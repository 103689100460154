import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight, FaCogs } from 'react-icons/fa';

import { useAuth } from '~/hooks/auth';

import BreadCrumb from '~/components/BreadCrumb';

import {
  AnimatedContainer,
  Configurations,
  ConfigurationCardWrapper,
  ConfigurationCard,
  ConfigurationIcon,
  ConfigurationName,
  GoIcon,
} from './styles';
import { CONFIGURATIONS_NAVIGATION_ANIMATION } from './animations';

import { CONFIGURATIONS_NAVIGATION } from '~/constants';

const MainContent: React.FC = () => {
  const { user } = useAuth();

  function getValidItem(key: any, value: any): React.ReactNode {
    if (key === 'Operadores Logísticos') {
      if (user.user_type.type !== 'ADM' && user.user_type.type !== 'IMK') {
        return <></>;
      }
    }
    if (key === 'Tipos de Abatimentos Fiscais') {
      if (
        user.user_type.type !== 'ADM' &&
        user.user_type.type !== 'MK1' &&
        user.user_type.type !== 'MK2'
      ) {
        return <></>;
      }
    }
    if (key === 'Tipos de Custos de Eventos') {
      if (
        user.user_type.type !== 'ADM' &&
        user.user_type.type !== 'MK1' &&
        user.user_type.type !== 'MK2'
      ) {
        return <></>;
      }
    }
    if (key === 'Categorias de Registros de Lojas') {
      if (
        user.user_type.type !== 'ADM' &&
        user.user_type.type !== 'MK1' &&
        user.user_type.type !== 'MK2' &&
        user.user_type.type !== 'MAX'
      ) {
        return <></>;
      }
    }
    if (key === 'Tipos de Tópico de Registros de Lojas') {
      if (
        user.user_type.type !== 'ADM' &&
        user.user_type.type !== 'MK1' &&
        user.user_type.type !== 'MK2' &&
        user.user_type.type !== 'MAX'
      ) {
        return <></>;
      }
    }

    return (
      <ConfigurationCardWrapper key={`wrapper-${key}`}>
        <Link to={value.link}>
          <ConfigurationCard
            key={`card-${key}`}
            backgroundColor={value.backgroundColor}
          >
            <ConfigurationIcon>{value.icon}</ConfigurationIcon>

            <ConfigurationName>{key}</ConfigurationName>

            <GoIcon>
              <FaArrowRight size={20} />
            </GoIcon>
          </ConfigurationCard>
        </Link>
      </ConfigurationCardWrapper>
    );
  }

  return (
    <AnimatedContainer variants={CONFIGURATIONS_NAVIGATION_ANIMATION}>
      <BreadCrumb
        main="Configurações"
        path="/configurations/main"
        mainIcon={FaCogs}
      />
      <Configurations>
        {Object.entries(CONFIGURATIONS_NAVIGATION).map(([key, value]) =>
          getValidItem(key, value),
        )}
      </Configurations>
    </AnimatedContainer>
  );
};

export default MainContent;
