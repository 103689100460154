export default function getCurrencyFormatValue(
  receivedValue: string | number,
): number {
  let receivedValueConv = receivedValue.toString();

  receivedValueConv = receivedValueConv.replaceAll('R$', '');
  receivedValueConv = receivedValueConv.replaceAll('.', '');
  receivedValueConv = receivedValueConv.replaceAll(',', '.');

  return parseFloat(receivedValueConv);
}
