import React, { ButtonHTMLAttributes } from 'react';

import { FaLock } from 'react-icons/fa';

import { Container } from './styles';

export interface ILockProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  iconSize?: number;
  marginTop?: string;
}

const Lock: React.FC<ILockProps> = ({ iconSize, ...rest }) => {
  return (
    <Container {...rest}>
      <FaLock size={iconSize !== undefined ? iconSize : 26} />
    </Container>
  );
};

export default Lock;
