import React from 'react';
import { useLocation } from 'react-router-dom';

import DefaultLayout from '../../_layouts/default';

import List from './List';
import New from './New';

const ThirdPartyLogisticsProviders: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <DefaultLayout>
      {pathname === '/configurations/thirdpartylogisticsproviders' ? (
        <List />
      ) : (
        <New />
      )}
    </DefaultLayout>
  );
};

export default ThirdPartyLogisticsProviders;
