import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

import { useTheme } from 'styled-components';

import { Container } from './styles';

interface IDistributedColumnsGeneral1Props {
  displayProjectsAmount: number;
  materialProjectsAmount: number;
  sponsorshipProjectsAmount: number;
  adversityProjectsAmount: number;
  folderProjectsAmount: number;
  sampleProjectsAmount: number;
  eventProjectsAmount: number;
}

interface IChartItemDetail {
  category: string;
  amount: number;
  color: string;
}

interface IChartItem {
  amount: number;
  chartItem: IChartItemDetail;
}

const DistributedColumnsGeneral1: React.FC<IDistributedColumnsGeneral1Props> =
  ({
    displayProjectsAmount,
    materialProjectsAmount,
    sponsorshipProjectsAmount,
    adversityProjectsAmount,
    folderProjectsAmount,
    sampleProjectsAmount,
    eventProjectsAmount,
  }: IDistributedColumnsGeneral1Props) => {
    const { colors } = useTheme();
    const [chartItems, setChartItems] = useState<IChartItem[]>([]);

    useEffect(() => {
      const displayProjectsItem: IChartItemDetail = {
        category: 'Displays',
        amount: displayProjectsAmount,
        color: colors.chartDisplay,
      };

      const materialProjectsItem: IChartItemDetail = {
        category: 'Materiais',
        amount: materialProjectsAmount,
        color: colors.chartMaterial,
      };

      const sponsorshipProjectsItem: IChartItemDetail = {
        category: 'Pagamentos',
        amount: sponsorshipProjectsAmount,
        color: colors.chartSponsorship,
      };

      const adversityProjectsItem: IChartItemDetail = {
        category: 'Problemas',
        amount: adversityProjectsAmount,
        color: colors.chartAdversity,
      };

      const folderProjectsItem: IChartItemDetail = {
        category: 'Pastas',
        amount: folderProjectsAmount,
        color: colors.chartFolder,
      };

      const sampleProjectsItem: IChartItemDetail = {
        category: 'Amostras',
        amount: sampleProjectsAmount,
        color: colors.chartSample,
      };

      const eventProjectsItem: IChartItemDetail = {
        category: 'Eventos',
        amount: eventProjectsAmount,
        color: colors.chartEvent,
      };

      const chartItemsTemp: IChartItem[] = [
        {
          amount: displayProjectsAmount,
          chartItem: displayProjectsItem,
        },
        {
          amount: materialProjectsAmount,
          chartItem: materialProjectsItem,
        },
        {
          amount: sponsorshipProjectsAmount,
          chartItem: sponsorshipProjectsItem,
        },
        {
          amount: adversityProjectsAmount,
          chartItem: adversityProjectsItem,
        },
        {
          amount: folderProjectsAmount,
          chartItem: folderProjectsItem,
        },
        {
          amount: sampleProjectsAmount,
          chartItem: sampleProjectsItem,
        },
        {
          amount: eventProjectsAmount,
          chartItem: eventProjectsItem,
        },
      ];

      chartItemsTemp.sort((a, b) => b.amount - a.amount);

      setChartItems(chartItemsTemp);
    }, [
      displayProjectsAmount,
      materialProjectsAmount,
      sponsorshipProjectsAmount,
      adversityProjectsAmount,
      folderProjectsAmount,
      sampleProjectsAmount,
      eventProjectsAmount,
      colors.chartDisplay,
      colors.chartMaterial,
      colors.chartSponsorship,
      colors.chartAdversity,
      colors.chartFolder,
      colors.chartSample,
      colors.chartEvent,
    ]);

    function getColor(chartItemIndex: number): string {
      if (chartItems) {
        if (chartItems[chartItemIndex]) {
          if (chartItems[chartItemIndex].chartItem) {
            if (chartItems[chartItemIndex].chartItem.color) {
              return chartItems[chartItemIndex].chartItem.color;
            }
          }
        }
      }

      return '#fff';
    }

    function getAmount(chartItemIndex: number): number {
      if (chartItems) {
        if (chartItems[chartItemIndex]) {
          if (chartItems[chartItemIndex].chartItem) {
            if (chartItems[chartItemIndex].chartItem.amount) {
              return chartItems[chartItemIndex].chartItem.amount;
            }
          }
        }
      }

      return 0;
    }

    function getCategory(chartItemIndex: number): string {
      if (chartItems) {
        if (chartItems[chartItemIndex]) {
          if (chartItems[chartItemIndex].chartItem) {
            if (chartItems[chartItemIndex].chartItem.category) {
              return chartItems[chartItemIndex].chartItem.category;
            }
          }
        }
      }

      return '';
    }

    const optionColors = [
      getColor(0),
      getColor(1),
      getColor(2),
      getColor(3),
      getColor(4),
      getColor(5),
      getColor(6),
    ];

    const series = [
      {
        name: 'Quantidade',
        data: [
          getAmount(0),
          getAmount(1),
          getAmount(2),
          getAmount(3),
          getAmount(4),
          getAmount(5),
          getAmount(6),
        ],
      },
    ];

    const options = {
      chart: {
        offsetX: 0,
        offsetY: 0,
        redrawOnWindowResize: true,
        toolbar: {
          show: false,
        },
      },
      colors: optionColors,
      plotOptions: {
        bar: {
          columnWidth: '50%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [
          getCategory(0),
          getCategory(1),
          getCategory(2),
          getCategory(3),
          getCategory(4),
          getCategory(5),
          getCategory(6),
        ],
        labels: {
          style: {
            colors: optionColors,
            fontSize: '12px',
          },
        },
      },
    };

    return (
      <Container>
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </Container>
    );
  };

export default DistributedColumnsGeneral1;
