import React, { useState, useEffect } from 'react';
import { PropagateLoader } from 'react-spinners';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { FaAngleDown, FaFileAlt } from 'react-icons/fa';

import Pagination from '~/components/Pagination';
import ProjectRecordNoteDetailModal from '~/components/Modals/Projects/RecordNoteDetail';

import DefaultInput from '~/components/Inputs/Default';

import api from '~/services/api';

import {
  Container,
  Label,
  Wrapper,
  Table,
  Th,
  Td,
  DetailBtn,
  ButtonsContent,
} from './styles';

interface IRecordNotesProps {
  project_id: string;
  default_expanded?: boolean;
}

interface IProjectRecord {
  id: string;
  description: string;
  note: string;
  project_id: string;
  user_id: string;
  user: {
    name: string;
  };
  project_procedure: {
    description: string;
  };
}

const AMOUNT_PER_PAGE = 5;

const RecordNotes: React.FC<IRecordNotesProps> = ({
  project_id,
  default_expanded,
}) => {
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState('');
  const [projectRecords, setProjectRecords] = useState<IProjectRecord[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [descriptionDetail, setDescriptionDetail] = useState('');
  const [noteDetail, setNoteDetail] = useState('');
  const [userDetail, setUserDetail] = useState('');
  const [
    projectProcedureDescriptionDetail,
    setProjectProcedureDescriptionDetail,
  ] = useState('');

  useEffect(() => {
    const loadProjectRecords = async () => {
      await api
        .get(
          `/projectrecords/visiblenotes?` +
            `project_id=${project_id}&` +
            `page=${page}&` +
            `filter=${filter}&` +
            `amount=${AMOUNT_PER_PAGE}`,
        )
        .then(response => {
          setProjectRecords(response.data[0]);
          setCount(response.data[1]);
          setLoading(false);
        });
    };

    loadProjectRecords();
  }, [project_id, page, filter]);

  function getNote(receivedNote: string): string {
    if (receivedNote.length > 50) {
      return `${receivedNote.substring(0, 50)}...`;
    }

    return receivedNote;
  }

  function handleTable(): React.ReactNode {
    if (projectRecords) {
      if (projectRecords.length > 0) {
        return (
          <Table>
            <thead>
              <tr>
                <Th width="30%" textAlign="left">
                  Usuário
                </Th>
                <Th width="60%" textAlign="left">
                  Observação
                </Th>
                <Th width="10%" textAlign="center" />
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <Td colSpan={4} padding="1rem" textAlign="center">
                    <PropagateLoader color="#276c8d" />
                  </Td>
                </tr>
              )}
              {projectRecords?.map(projectRecord => (
                <tr key={projectRecord.id}>
                  <Td width="30%" textAlign="left" heading="USUÁRIO">
                    {projectRecord.user.name}
                  </Td>
                  <Td width="60%" textAlign="left" heading="OBSERVAÇÃO">
                    {getNote(projectRecord.note)}
                  </Td>
                  <Td
                    width="10%"
                    textAlign="center"
                    display="flex"
                    heading={' '}
                  >
                    <DetailBtn
                      onClick={() => {
                        setIsOpenDetail(true);
                        setDescriptionDetail(projectRecord.description);
                        setNoteDetail(projectRecord.note);
                        setUserDetail(projectRecord.user.name);
                        setProjectProcedureDescriptionDetail(
                          projectRecord.project_procedure.description,
                        );
                      }}
                    >
                      <FaFileAlt size={22} />
                    </DetailBtn>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        );
      }
    }

    return (
      <Table>
        <thead>
          <tr>
            <Th width="100%" textAlign="center" />
          </tr>
        </thead>
        <tbody>
          <tr>
            <Td width="100%" textAlign="center">
              Nenhuma informação disponível
            </Td>
          </tr>
        </tbody>
      </Table>
    );
  }

  function clearProjectRecordDetail(): void {
    setDescriptionDetail('');
    setNoteDetail('');
    setUserDetail('');
    setProjectProcedureDescriptionDetail('');
  }

  if (!project_id) {
    return null;
  }

  return (
    <Container>
      <Accordion defaultExpanded={default_expanded}>
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Label>Observações</Label>
        </AccordionSummary>

        <AccordionDetails>
          <Wrapper>
            <div>
              <DefaultInput
                name="search"
                labelText="Pesquisar"
                type="text"
                onChange={event => {
                  setFilter(event.target.value);
                  setPage(0);
                }}
              />
            </div>

            {handleTable()}

            <ButtonsContent>
              <Pagination
                page={page}
                count={count}
                amountPerPage={AMOUNT_PER_PAGE}
                setPage={setPage}
              />
            </ButtonsContent>
          </Wrapper>
        </AccordionDetails>
      </Accordion>

      <ProjectRecordNoteDetailModal
        isOpen={isOpenDetail}
        description={descriptionDetail}
        note={noteDetail}
        user={userDetail}
        project_procedure_description={projectProcedureDescriptionDetail}
        setIsOpen={setIsOpenDetail}
        onClose={() => {
          clearProjectRecordDetail();
        }}
      />
    </Container>
  );
};

export default RecordNotes;
