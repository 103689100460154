import React, { Dispatch, SetStateAction } from 'react';
import { FcHighPriority } from 'react-icons/fc';
import { Dialog } from '@material-ui/core';

import DialogOptionButton from '~/components/Buttons/DialogOption';

import {
  Container,
  IconContainer,
  Title,
  SubTitle,
  ButtonContainer,
} from './styles';

interface IConfirmExitProps {
  isOpen: boolean;
  onExit: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title?: string;
  opConfirm?: string;
}

const ConfirmExit: React.FC<IConfirmExitProps> = ({
  isOpen,
  onExit,
  setIsOpen,
  title,
  opConfirm,
}: IConfirmExitProps) => {
  const handleConfirmation = (): void => {
    setIsOpen(false);
    onExit();
  };

  const handleClose = (): void => {
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen}>
      <Container>
        <IconContainer>
          <FcHighPriority size={60} color="#c9aa71" />
        </IconContainer>

        <Title>{title !== undefined ? title : 'Deseja realmente sair?'}</Title>
        <SubTitle>Todas as alterações serão perdidas!</SubTitle>

        <ButtonContainer>
          <DialogOptionButton
            label={opConfirm !== undefined ? opConfirm : 'Sim, sair'}
            optionType="danger"
            onClick={() => handleConfirmation()}
          />

          <DialogOptionButton
            label="Não"
            marginLeft="1.5rem"
            onClick={() => handleClose()}
          />
        </ButtonContainer>
      </Container>
    </Dialog>
  );
};

export default ConfirmExit;
