import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import {
  FaFileUpload,
  FaTrashAlt,
  FaExclamationCircle,
  FaCheckCircle,
  FaLink,
} from 'react-icons/fa';

import AudioIcon from '~/components/Icons/Files/Audio';
import PdfIcon from '~/components/Icons/Files/Pdf';
import PresentationIcon from '~/components/Icons/Files/Presentation';
import SpreadsheetIcon from '~/components/Icons/Files/Spreadsheet';
import TextIcon from '~/components/Icons/Files/Text';
import VideoIcon from '~/components/Icons/Files/Video';
import WordIcon from '~/components/Icons/Files/Word';

import { useRequestFiles, IRequestFile } from '~/context/requestfiles';

import {
  Container,
  FileRow,
  FileContainer,
  FilePreviewWrapper,
  FilePreviewImageWrapper,
  FilePreviewImage,
  FileInfoWrapper,
  FileInfoName,
  FileInfoFileSize,
  FileActions,
  DeleteBtn,
  UrlIcon,
  SuccessIcon,
  ErrorIcon,
} from './styles';

const List = (): JSX.Element => {
  const { uploadedRequestFiles: requestFiles, deleteRequestFile } =
    useRequestFiles();

  if (!requestFiles.length) {
    return (
      <span>
        <FaFileUpload
          style={{ marginLeft: '45%', marginTop: 15 }}
          size={35}
          color="#adadad"
        />
      </span>
    );
  }

  function handleRequestFilePreview(uploadedRequestFile: any): React.ReactNode {
    if (
      uploadedRequestFile.type === 'audio/mp3' ||
      uploadedRequestFile.type === 'audio/mpeg'
    ) {
      return <AudioIcon />;
    }

    if (uploadedRequestFile.type === 'application/pdf') {
      return <PdfIcon />;
    }

    if (
      uploadedRequestFile.type ===
        'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
      uploadedRequestFile.type ===
        'application/vnd.oasis.opendocument.presentation'
    ) {
      return <PresentationIcon />;
    }

    if (
      uploadedRequestFile.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      uploadedRequestFile.type ===
        'application/vnd.oasis.opendocument.spreadsheet'
    ) {
      return <SpreadsheetIcon />;
    }

    if (uploadedRequestFile.type === 'text/plain') {
      return <TextIcon />;
    }

    if (
      uploadedRequestFile.type === 'image/gif' ||
      uploadedRequestFile.type === 'video/quicktime' ||
      uploadedRequestFile.type === 'video/mp4'
    ) {
      return <VideoIcon />;
    }

    if (
      uploadedRequestFile.type ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      uploadedRequestFile.type === 'application/vnd.oasis.opendocument.text'
    ) {
      return <WordIcon />;
    }

    return (
      <FilePreviewImageWrapper>
        <FilePreviewImage src={uploadedRequestFile.preview} />
      </FilePreviewImageWrapper>
    );
  }

  return (
    <Container>
      {requestFiles.map((uploadedRequestFile: IRequestFile) => (
        <FileRow key={uploadedRequestFile.id}>
          <FileContainer>
            <FilePreviewWrapper>
              {handleRequestFilePreview(uploadedRequestFile)}
            </FilePreviewWrapper>

            <FileInfoWrapper>
              <FileInfoName>{uploadedRequestFile.name}</FileInfoName>

              <FileInfoFileSize>
                {uploadedRequestFile.readableSize}
              </FileInfoFileSize>
            </FileInfoWrapper>
          </FileContainer>

          <FileActions>
            {!uploadedRequestFile.uploaded && !uploadedRequestFile.error && (
              <CircularProgressbar
                styles={{
                  root: { width: 24 },
                  path: { stroke: '#7159c1' },
                }}
                strokeWidth={10}
                text={String(uploadedRequestFile.progress)}
                value={uploadedRequestFile.progress || 0}
              />
            )}

            {uploadedRequestFile.url && (
              <UrlIcon>
                <a
                  href={uploadedRequestFile.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLink size={20} />
                </a>
              </UrlIcon>
            )}

            {uploadedRequestFile.uploaded && (
              <SuccessIcon>
                <FaCheckCircle size={24} />
              </SuccessIcon>
            )}
            {uploadedRequestFile.error && (
              <ErrorIcon>
                <FaExclamationCircle size={24} />
              </ErrorIcon>
            )}
            {!!uploadedRequestFile.url && (
              <DeleteBtn
                onClick={() => deleteRequestFile(uploadedRequestFile.id)}
              >
                <FaTrashAlt size={24} />
              </DeleteBtn>
            )}
          </FileActions>
        </FileRow>
      ))}
    </Container>
  );
};

export default List;
