import React, { createContext, useState, useCallback, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import filesize from 'filesize';

import api from '~/services/api';

export interface IPost {
  completion_file_temp_id: string;
  name: string;
  size: number;
  key: string;
  url: string;
}

export interface ICompletionFile {
  id: string;
  name: string;
  readableSize: string;
  type: string;
  uploaded?: boolean;
  preview: string;
  file: File | null;
  progress?: number;
  error?: boolean;
  url: string;
}

interface ICompletionFileContextData {
  uploadedCompletionFiles: ICompletionFile[];
  deleteCompletionFile(id: string): void;
  handleUpload(
    file: any,
    type: string,
    projectCompletionTempId: string,
    projectType: string,
    sequence: number,
    userId: string,
  ): void;
}

const CompletionFileContext = createContext<ICompletionFileContextData>(
  {} as ICompletionFileContextData,
);

const CompletionFileProvider: React.FC = ({ children }) => {
  const [uploadedCompletionFiles, setUploadedCompletionFiles] = useState<
    ICompletionFile[]
  >([]);

  const updateCompletionFile = useCallback((id, data) => {
    setUploadedCompletionFiles(state =>
      state.map(file => (file.id === id ? { ...file, ...data } : file)),
    );
  }, []);

  const processUpload = useCallback(
    (
      uploadedCompletionFile: ICompletionFile,
      type: string,
      projectCompletionTempId: string,
      projectType: string,
      sequence: number,
      userId: string,
    ) => {
      const data = new FormData();
      if (uploadedCompletionFile.file) {
        data.append('type', type);
        data.append('project_completion_temp_id', projectCompletionTempId);
        data.append('project_type', projectType);
        data.append('sequence', String(sequence));
        data.append('completion_file_temp_id', uploadedCompletionFile.id);
        data.append('user_id', userId);
        data.append(
          'file',
          uploadedCompletionFile.file,
          uploadedCompletionFile.name,
        );
      }

      api
        .post('/projectcompletionfilesreceived', data, {
          onUploadProgress: progressEvent => {
            const progress: number = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );

            updateCompletionFile(uploadedCompletionFile.id, { progress });
          },
        })
        .then(response => {
          updateCompletionFile(uploadedCompletionFile.id, {
            uploaded: true,
            id: response.data.id,
            url: response.data.url,
          });
        })
        .catch(err => {
          console.log(err);
          updateCompletionFile(uploadedCompletionFile.id, {
            error: true,
          });
        });
    },
    [updateCompletionFile],
  );

  const handleUpload = useCallback(
    (
      files: File[],
      type: string,
      projectTempId: string,
      projectType: string,
      sequence: number,
      userId: string,
    ) => {
      const newUploadedCompletionFiles: ICompletionFile[] = files.map(
        (file: File) => ({
          file,
          id: uuidv4(),
          name: file.name,
          readableSize: filesize(file.size),
          type: file.type,
          preview: URL.createObjectURL(file),
          progress: 0,
          uploaded: false,
          error: false,
          url: '',
        }),
      );

      setUploadedCompletionFiles(state =>
        state.concat(newUploadedCompletionFiles),
      );
      newUploadedCompletionFiles.forEach(upCompletionFile =>
        processUpload(
          upCompletionFile,
          type,
          projectTempId,
          projectType,
          sequence,
          userId,
        ),
      );
    },
    [processUpload],
  );

  const deleteCompletionFile = useCallback((id: string) => {
    api.delete(`projectcompletionfilesreceived/${id}`);
    setUploadedCompletionFiles(state => state.filter(file => file.id !== id));
  }, []);

  return (
    <CompletionFileContext.Provider
      value={{ uploadedCompletionFiles, deleteCompletionFile, handleUpload }}
    >
      {children}
    </CompletionFileContext.Provider>
  );
};

function useCompletionFiles(): ICompletionFileContextData {
  const context = useContext(CompletionFileContext);

  if (!context) {
    throw new Error(
      'useCompletionFiles deve ser usado dentro de um CompletionFileProvider',
    );
  }

  return context;
}

export { CompletionFileProvider, useCompletionFiles };
