import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  margin-top: 12rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  z-index: 2;
  padding: 0 1.2rem 0 0;
`;

export const AnimatedWrapper = styled(motion.div)`
  width: 100%;
  display: flex;

  @media (max-width: 670px) {
    flex-direction: column;
  }
`;

export const NavigationContainer = styled.main`
  display: flex;
  flex-direction: column;
  margin-left: 2.4rem;
  width: 100%;

  @media (max-width: 670px) {
    margin: 2.4rem 0 0;
  }
`;
