import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import { Guararapes } from '~/assets/images';

export const AnimatedContainer = styled(motion.header)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 14rem;
    position: absolute;
    background: linear-gradient(
      90deg,
      ${theme.colors.header} 0%,
      ${theme.colors.headerLight} 100%
    );
    box-shadow: ${theme.shadows.strong};
  `}
`;

export const Wrapper = styled.div`
  max-width: 180rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 4rem;
  height: 12rem;
`;

export const AnimatedLeftNav = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: 700;
`;

export const AnimatedRightNav = styled(motion.div)`
  justify-content: flex-end;
  align-items: center;
  flex: 1;

  @media (min-width: 720px) {
    display: flex;
  }
`;

export const LogoLink = styled(Link)`
  margin: 0 2.4rem 0.8rem 6rem;

  @media (max-width: 670px) {
    max-width: 18rem;
    margin: 0;
  }
`;

export const Logo = styled(Guararapes)`
  max-width: 28rem;
  height: auto;

  @media (max-width: 670px) {
    max-width: 18rem;
  }
`;

export const AccessWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 720px) {
    margin-bottom: 0.5rem;
  }
`;

export const AccessBtn = styled.button`
  background-color: transparent;

  &:hover {
    transform: scale(1.2, 1.2);
  }
`;
