import React, { ButtonHTMLAttributes } from 'react';
import { FiFilePlus } from 'react-icons/fi';

import { Container } from './styles';

export interface INewProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
}

const New: React.FC<INewProps> = ({ label, ...rest }) => {
  return (
    <Container {...rest}>
      {label || 'Novo'} <FiFilePlus size={18} />
    </Container>
  );
};

export default New;
