import React, { useCallback, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SyncLoader } from 'react-spinners';
import { FaLock, FaCheckCircle } from 'react-icons/fa';
import * as Yup from 'yup';

import Button from '~/components/Button';
import Input from '~/components/Input';

import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import logoImg from '~/assets/images/logo.png';

import {
  Container,
  Wrapper,
  Content,
  SuccessContent,
  AnimationContainer,
  InstructionsWrapper,
  SuccessInfoWrapper,
  SuccessIconWrapper,
  ButtonWrapper,
  Background,
} from './styles';

interface IFormReset {
  token: string;
  password: string;
}

interface ILocation {
  token: string;
}

const schema = Yup.object().shape({
  password: Yup.string().required('Campo obrigatório'),
});

const Reset: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation<ILocation>();
  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const { handleSubmit, register, setValue, errors } = useForm<IFormReset>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    let emptyToken = false;
    if (state) {
      if (state.token) {
        if (state.token !== '') {
          register('token');
          setValue('token', state.token);
        } else {
          emptyToken = true;
        }
      } else {
        emptyToken = true;
      }
    } else {
      emptyToken = true;
    }

    if (emptyToken) {
      history.push('/');
    }
  }, [history, state, register, setValue]);

  const submitForm = useCallback(
    async (data: IFormReset) => {
      try {
        await api.post(`/password/reset`, data);

        setLoading(false);
        setSuccess(true);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro no envio',
          description: error.response.data.message,
        });
        setLoading(false);
      }
    },
    [addToast],
  );

  useEffect(() => {
    if (errors.password?.message !== undefined) {
      setLoading(false);
    }
  }, [errors]);

  return (
    <Container>
      <Wrapper>
        <AnimationContainer>
          <img src={logoImg} alt="Guararapes Logo" />

          {success ? (
            <SuccessContent>
              <SuccessIconWrapper>
                <FaCheckCircle size={100} />
              </SuccessIconWrapper>
              <SuccessInfoWrapper>
                Senha redefinida com sucesso
              </SuccessInfoWrapper>
            </SuccessContent>
          ) : (
            <Content>
              <form onSubmit={handleSubmit(submitForm)}>
                <InstructionsWrapper>
                  Informe a nova senha para acesso ao sistema.
                </InstructionsWrapper>
                <Input
                  name="password"
                  labelText="Senha"
                  icon={FaLock}
                  isPassword
                  ref={register}
                  error={errors.password?.message}
                />
                <ButtonWrapper>
                  <Button
                    id="btn-send"
                    type="submit"
                    onClick={() => {
                      setLoading(!loading);
                    }}
                  >
                    {loading ? (
                      <SyncLoader size={8} color="#FFF" margin={1} />
                    ) : (
                      'Salvar'
                    )}
                  </Button>
                </ButtonWrapper>
              </form>
            </Content>
          )}
        </AnimationContainer>
        <Background />
      </Wrapper>
    </Container>
  );
};

export default Reset;
