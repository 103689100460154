import React, { Dispatch, SetStateAction } from 'react';

import InfoInput from '~/components/Inputs/Info';
import CloseButton from '~/components/Buttons/Close';

import {
  StyledModal,
  MainContainer,
  Title,
  Container,
  Content,
  Wrapper,
  ButtonContainer,
} from './styles';

interface IInvoiceDetailProps {
  isOpen: boolean;
  onClose: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  project_code: string;
  issue_date: Date;
  created_at: Date;
  series: string;
  number: string;
  gross_price: number;
  discount_price: number;
  title_series: string;
  title_number: string;
  title_installment: string;
  user_inclusion: string;
}

const InvoiceDetail: React.FC<IInvoiceDetailProps> = ({
  isOpen,
  onClose,
  setIsOpen,
  project_code,
  issue_date,
  created_at,
  series,
  number,
  gross_price,
  discount_price,
  title_series,
  title_number,
  title_installment,
  user_inclusion,
}: IInvoiceDetailProps) => {
  const handleClose = (): void => {
    setIsOpen(false);
    onClose();
  };

  return (
    <StyledModal
      isOpen={isOpen}
      style={{
        overlay: {
          zIndex: 999,
          overflowY: 'auto',
          position: 'fixed',
          background: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <MainContainer>
        <Title>Detalhes Notas Fiscais</Title>
        <Container>
          <Content>
            <Wrapper style={{ marginRight: '1rem' }}>
              <InfoInput
                name="project_code"
                labelFor="project_code"
                labelText="Cód. Projeto"
                type="text"
                defaultValue={project_code}
                disabled
              />
            </Wrapper>

            <Wrapper>
              <InfoInput
                name="user_inclusion"
                labelFor="user_inclusion"
                labelText="Usuário"
                type="text"
                defaultValue={user_inclusion}
                disabled
              />
            </Wrapper>
          </Content>

          <Content>
            <Wrapper style={{ marginRight: '1rem' }}>
              <InfoInput
                name="issue_date"
                labelFor="issue_date"
                labelText="Data Emissão"
                type="text"
                defaultValue={new Date(issue_date).toLocaleDateString()}
                disabled
              />
            </Wrapper>

            <Wrapper>
              <InfoInput
                name="created_at"
                labelFor="created_at"
                labelText="Data Inclusão"
                type="text"
                defaultValue={new Date(created_at).toLocaleDateString()}
                disabled
              />
            </Wrapper>
          </Content>

          <Content>
            <Wrapper style={{ marginRight: '1rem' }}>
              <InfoInput
                name="series"
                labelFor="series"
                labelText="Série NF"
                type="text"
                defaultValue={series}
                disabled
              />
            </Wrapper>

            <Wrapper>
              <InfoInput
                name="number"
                labelFor="number"
                labelText="Número NF"
                type="text"
                defaultValue={number}
                disabled
              />
            </Wrapper>
          </Content>

          <Content>
            <Wrapper style={{ marginRight: '1rem' }}>
              <InfoInput
                name="gross_price"
                labelFor="gross_price"
                labelText="Valor Total NF"
                type="text"
                defaultValue={
                  gross_price
                    ? new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(gross_price)
                    : new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(0)
                }
                disabled
              />
            </Wrapper>

            <Wrapper>
              <InfoInput
                name="discount_price"
                labelFor="discount_price"
                labelText="Valor Total Desconto"
                type="text"
                defaultValue={
                  discount_price
                    ? new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(discount_price)
                    : new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(0)
                }
                disabled
              />
            </Wrapper>
          </Content>

          <Content>
            <Wrapper style={{ marginRight: '1rem' }}>
              <InfoInput
                name="title_series"
                labelFor="title_series"
                labelText="Série Duplicata"
                type="text"
                defaultValue={title_series}
                disabled
              />
            </Wrapper>

            <Wrapper>
              <InfoInput
                name="title_number"
                labelFor="title_number"
                labelText="Número Duplicata"
                type="text"
                defaultValue={title_number}
                disabled
              />
            </Wrapper>
          </Content>

          <Content>
            <Wrapper>
              <InfoInput
                name="title_installment"
                labelFor="title_installment"
                labelText="Parcela Duplicata"
                type="text"
                defaultValue={title_installment}
                disabled
              />
            </Wrapper>
          </Content>

          <ButtonContainer>
            <CloseButton onClick={() => handleClose()} />
          </ButtonContainer>
        </Container>
      </MainContainer>
    </StyledModal>
  );
};

export default InvoiceDetail;
