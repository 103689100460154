import React, { ButtonHTMLAttributes } from 'react';

import { FaRedoAlt } from 'react-icons/fa';

import { Container } from './styles';

export interface IRefreshAltProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  marginTop?: string;
  marginLeft?: string;
}

const RefreshAlt: React.FC<IRefreshAltProps> = ({ ...rest }) => {
  return (
    <Container {...rest}>
      <FaRedoAlt size={26} />
    </Container>
  );
};

export default RefreshAlt;
