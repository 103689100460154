import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { motion } from 'framer-motion';

import { Wrapper as HeaderWrapper } from '../Header/styles';

export const AnimatedContainer = styled(motion.footer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 3.2rem;
  transform-origin: bottom;
`;

export const Wrapper = styled(HeaderWrapper)`
  height: auto;
  padding: 1.6rem;
  align-items: center;
`;

export const Span = styled.span`
  ${({ theme }) => css`
    display: block;
    font-size: ${theme.fontSizes.meddium};
    color: ${theme.colors.white};

    :first-of-type {
      margin-bottom: 0.8rem;
    }
  `}
`;

export const FooterSection = styled(AnimatedContainer)<{ background?: string }>`
  ${({ theme, background }) => css`
    background: ${background || transparentize(0.12, theme.colors.footer)};
    margin-top: 0;
    color: ${theme.colors.label};

    :last-of-type {
      ${Wrapper} {
        padding: 2.4rem 1.6rem;
      }
    }
  `}
`;
