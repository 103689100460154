import React from 'react';
import { useLocation } from 'react-router-dom';

import DefaultLayout from '../../_layouts/default';

import List from './List';
import View from './View';
import Procedure1Representative from './Procedures/Procedure1/ChooseRepresentative';
import Procedure1Client from './Procedures/Procedure1/ChooseClient';
import Procedure1New from './Procedures/Procedure1/New';
import Procedure2 from './Procedures/Procedure2';
import Procedure3 from './Procedures/Procedure3';
import Procedure4 from './Procedures/Procedure4';
import Procedure5 from './Procedures/Procedure5';
import Procedure6 from './Procedures/Procedure6';
import Procedure7 from './Procedures/Procedure7';
import EditingChooseProcess from './Editing/ChooseProcess';
import EditingGifts from './Editing/Gifts';
import EditingCancel from './Editing/Cancel';
import EditingClientContact from './Editing/ClientContact';
import EditingEventCosts from './Editing/EventCosts';
import EditingEventDate from './Editing/EventDate';

const Events: React.FC = () => {
  const { pathname } = useLocation();

  function getComponent(): React.ReactNode {
    if (pathname === '/projects/events') {
      return <List />;
    }
    if (pathname === '/projects/events/view') {
      return <View />;
    }
    if (pathname === '/projects/events/procedure1/representative') {
      return <Procedure1Representative />;
    }
    if (pathname === '/projects/events/procedure1/client') {
      return <Procedure1Client />;
    }
    if (pathname === '/projects/events/procedure1/new') {
      return <Procedure1New />;
    }
    if (pathname === '/projects/events/procedure2') {
      return <Procedure2 />;
    }
    if (pathname === '/projects/events/procedure3') {
      return <Procedure3 />;
    }
    if (pathname === '/projects/events/procedure4') {
      return <Procedure4 />;
    }
    if (pathname === '/projects/events/procedure5') {
      return <Procedure5 />;
    }
    if (pathname === '/projects/events/procedure6') {
      return <Procedure6 />;
    }
    if (pathname === '/projects/events/procedure7') {
      return <Procedure7 />;
    }
    if (pathname === '/projects/events/editing/chooseprocess') {
      return <EditingChooseProcess />;
    }
    if (pathname === '/projects/events/editing/gifts') {
      return <EditingGifts />;
    }
    if (pathname === '/projects/events/editing/cancel') {
      return <EditingCancel />;
    }
    if (pathname === '/projects/events/editing/clientcontact') {
      return <EditingClientContact />;
    }
    if (pathname === '/projects/events/editing/eventcosts') {
      return <EditingEventCosts />;
    }
    if (pathname === '/projects/events/editing/eventdate') {
      return <EditingEventDate />;
    }

    return <List />;
  }

  return <DefaultLayout>{getComponent()}</DefaultLayout>;
};

export default Events;
