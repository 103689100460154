import React, { useState, useEffect } from 'react';

import Representative from '~/components/ProjectSections/Representative';
import Client from '~/components/ProjectSections/Client';
import ClientPatterns from '~/components/ProjectSections/ClientPatterns';
import Main from '~/components/ProjectSections/Main';
import ClientContact from '~/components/ProjectSections/ClientContact';
import Records from '~/components/ProjectSections/Records';
import Files from '~/components/ProjectSections/Files';
import RequestFiles from '~/components/ProjectSections/RequestFiles';
import Prices from '~/components/ProjectSections/Prices';
import CompletionFiles from '~/components/ProjectSections/CompletionFiles';

import api from '~/services/api';

interface IMaterialProps {
  project_id: string;
  all?: boolean;
}

interface IProject {
  id: string;
  type: string;
  code: string;
  price: number;
  shipping_price: number;
  total_price: number;
  rep_as_client: boolean;
  erp_client_code: string;
  erp_rep_code: string;
  note: string;
  client_contact_name: string;
  client_contact_phone: string;
  client_contact_email: string;
  project_procedure_id: string;
  material: {
    code: string;
    name: string;
  };
  project_procedure: {
    id: string;
    sequence: number;
  };
}

const Material: React.FC<IMaterialProps> = ({ project_id, all }) => {
  const [project, setProject] = useState<IProject>();

  useEffect(() => {
    api.get(`/projects/id?id=${project_id}`).then(response => {
      setProject(response.data);
    });
  }, [project_id]);

  function handleRepresentative(): React.ReactNode | null {
    if (project) {
      return (
        <Representative
          erp_rep_code={project.erp_rep_code ? project.erp_rep_code : ''}
        />
      );
    }

    return null;
  }

  function handleClient(): React.ReactNode | null {
    if (project) {
      return (
        <Client
          rep_as_client={project.rep_as_client}
          erp_client_code={
            project.erp_client_code ? project.erp_client_code : ''
          }
          erp_rep_code={project.erp_rep_code ? project.erp_rep_code : ''}
        />
      );
    }

    return null;
  }

  function handleClientPatterns(): React.ReactNode | null {
    if (project) {
      return (
        <ClientPatterns
          erp_client_code={
            project.erp_client_code ? project.erp_client_code : ''
          }
        />
      );
    }

    return null;
  }

  function handleMain(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence > 1 || all) {
        return (
          <Main
            code={project.code ? project.code : ''}
            item={
              project.material.code && project.material.name
                ? `(${project.material.code}) - ${project.material.name}`
                : ''
            }
            note={project.note ? project.note : ''}
          />
        );
      }
    }

    return null;
  }

  function handleClientContact(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence > 1 || all) {
        return (
          <ClientContact
            client_contact_name={
              project.client_contact_name ? project.client_contact_name : ''
            }
            client_contact_phone={
              project.client_contact_phone ? project.client_contact_phone : ''
            }
            client_contact_email={
              project.client_contact_email ? project.client_contact_email : ''
            }
          />
        );
      }
    }

    return null;
  }

  function handleRecords(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence > 1 || all) {
        return <Records project_id={project.id} />;
      }
    }

    return null;
  }

  function handleFiles(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence > 1 || all) {
        return (
          <Files
            project_id={project.id}
            project_procedure_id={project.project_procedure.id}
          />
        );
      }
    }

    return null;
  }

  function handleRequestFiles(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence > 1 || all) {
        return (
          <RequestFiles
            project_id={project.id}
            project_procedure_id={project.project_procedure.id}
          />
        );
      }
    }

    return null;
  }

  function handlePrices(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence > 3 || all) {
        return (
          <Prices
            price={project.price}
            shipping_price={project.shipping_price}
            total_price={project.total_price}
          />
        );
      }
    }

    return null;
  }

  function handleCompletionFiles(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence > 4 || all) {
        return (
          <CompletionFiles
            project_id={project.id}
            project_procedure_id={project.project_procedure.id}
          />
        );
      }
    }

    return null;
  }

  return (
    <>
      {handleRepresentative()}
      {handleClient()}
      {handleClientPatterns()}
      {handleMain()}
      {handleClientContact()}
      {handleRecords()}
      {handleFiles()}
      {handleRequestFiles()}
      {handlePrices()}
      {handleCompletionFiles()}
    </>
  );
};

export default Material;
