import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { FaAngleDown } from 'react-icons/fa';

import InfoInput from '~/components/Inputs/Info';
import { MainContainer, Label, Container, Content, Wrapper } from './styles';

interface IEventInfoProps {
  event_date?: Date;
  estimated_event_participants_number?: number;
  total_event_costs_price?: number;
}

const EventInfo: React.FC<IEventInfoProps> = ({
  event_date,
  estimated_event_participants_number,
  total_event_costs_price,
}) => {
  return (
    <MainContainer>
      <Accordion>
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Label>Informações do Evento</Label>
        </AccordionSummary>

        <AccordionDetails>
          <Container>
            <Content>
              <Wrapper style={{ width: '33%', marginRight: '1rem' }}>
                <InfoInput
                  name="event_date"
                  labelFor="event_date"
                  labelText="Data Evento"
                  type="text"
                  value={
                    event_date !== undefined
                      ? new Date(event_date).toLocaleDateString()
                      : 'Indefinido'
                  }
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '33%', marginRight: '1rem' }}>
                <InfoInput
                  name="estimated_event_participants_number"
                  labelFor="estimated_event_participants_number"
                  labelText="Número Est. Participantes"
                  type="text"
                  defaultValue={
                    estimated_event_participants_number
                      ? new Intl.NumberFormat('pt-BR').format(
                          estimated_event_participants_number,
                        )
                      : new Intl.NumberFormat('pt-BR').format(0)
                  }
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '33%' }}>
                <InfoInput
                  name="total_event_costs_price"
                  labelFor="total_event_costs_price"
                  labelText="Valor Custos"
                  type="text"
                  defaultValue={
                    total_event_costs_price
                      ? new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(total_event_costs_price)
                      : new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(0)
                  }
                  disabled
                />
              </Wrapper>
            </Content>
          </Container>
        </AccordionDetails>
      </Accordion>
    </MainContainer>
  );
};

export default EventInfo;
