import styled, { css } from 'styled-components';

export const Container = styled.section`
  ${({ theme }) => css`
    background: ${theme.colors.background};
    border-radius: ${theme.radius.default};
    box-shadow: ${theme.shadows.flat};
    padding: 2.4rem;
    width: 100%;
    margin: 2.4rem 0;

    input {
      width: 100%;
    }

    @media (max-width: 940px) {
      > div:first-child {
        flex-direction: column;
      }
    }

    @media (max-width: 400px) {
      > div + div {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
      }
    }
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5rem;

  @media (max-width: 1010px) {
    float: right;
    flex-direction: column;
  }
`;
