import React, { useState, useEffect, useCallback } from 'react';
import { FaProjectDiagram, FaCalendarAlt } from 'react-icons/fa';
import { PropagateLoader } from 'react-spinners';
import { Link, useLocation } from 'react-router-dom';

import BreadCrumb from '~/components/BreadCrumb';
import ConfirmDeleteDialog from '~/components/Dialogs/ConfirmDelete';
import DateInput from '~/components/Inputs/Date';
import DefaultInput from '~/components/Inputs/Default';
import DefaultTooltip from '~/components/Tooltips/Default';
import DeleteButton from '~/components/Buttons/Delete';
import EditButton from '~/components/Buttons/Edit';
import FixButton from '~/components/Buttons/Fix';
import NewButton from '~/components/Buttons/New';
import Pagination from '~/components/Pagination';
import ReportButton from '~/components/Buttons/Report';
import ReportDialog from '~/components/Dialogs/Report';
import ViewButton from '~/components/Buttons/View';

import { getFormattedCnpj } from '~/utils';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  ContentSplit,
  FilterContent,
  FilterDatesContent,
  FilterDateWrapper,
  ReportContent,
  Table,
  Thead,
  Tbody,
  Th,
  TbodyTr,
  Td,
  LinkView,
  ButtonsContent,
} from './styles';

interface IEventProject {
  id: string;
  code: string;
  event_date: Date;
  status: string;
  material_delivery_status: string;
  event: {
    name: string;
  };
  local_client: {
    company_name: string;
    document: string;
    city: string;
    state: string;
  };
  user_inclusion: {
    id: string;
  };
  project_procedure: {
    sequence: number;
    user_type: string;
    event_validator: boolean;
  };
}

const AMOUNT_PER_PAGE = 10;

const List: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<{ user_type_pending: boolean } | undefined>();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState('');
  const [lastRefresh, setLastRefresh] = useState(new Date());
  const [initialEventDate, setInitialEventDate] = useState<Date>();
  const [endEventDate, setEndEventDate] = useState<Date>();
  const [eventProjects, setEventProjects] = useState<IEventProject[]>();
  const [eventProjectsIds, setEventProjectsIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isOpenReport, setIsOpenReport] = useState(false);
  const [projectIdDelete, setProjectIdDelete] = useState('');
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isNewReqEnabled, setIsNewReqEnabled] = useState(false);
  const [titleDelete, setTitleDelete] = useState('');
  const [subTitleDelete, setSubTitleDelete] = useState('');

  useEffect(() => {
    const ids: string[] = [];
    if (eventProjects) {
      eventProjects.map(eventProject => ids.push(eventProject.id));

      setEventProjectsIds(ids);
    }
  }, [eventProjects]);

  useEffect(() => {
    setPage(0);
  }, [state]);

  useEffect(() => {
    api.get('/generalsettings').then(response => {
      if (response.data) {
        setIsNewReqEnabled(response.data.new_event_req_enabled);
      }
    });
  }, []);

  useEffect(() => {
    const loadEventProjects = async () => {
      await api
        .get(
          `/projects?type=EVENTO` +
            `&page=${page}` +
            `&filter=${filter}` +
            `&amount=${AMOUNT_PER_PAGE}` +
            `&user_type=${user.user_type.type}` +
            `&erp_rep_code=${
              user.user_type.type === 'REP' ? user.erp_rep_code : ''
            }` +
            `&initial_event_date=${initialEventDate}` +
            `&end_event_date=${endEventDate}` +
            `${
              state !== undefined && state.user_type_pending
                ? `&search_type=pending_by_user_type`
                : ''
            }`,
        )
        .then(response => {
          setEventProjects(response.data[0]);
          setCount(response.data[1]);
          setLoading(false);
        });
    };

    loadEventProjects();
  }, [page, filter, user, initialEventDate, endEventDate, state, lastRefresh]);

  function handleEditDisabled(eventProjectReceived: IEventProject): boolean {
    if (eventProjectReceived.status.toUpperCase() === 'CANCELADO') {
      return true;
    }
    if (eventProjectReceived.status.toUpperCase() === 'FINALIZADO') {
      return true;
    }
    if (
      user.user_type.type === eventProjectReceived.project_procedure.user_type
    ) {
      return false;
    }
    if (
      eventProjectReceived.project_procedure.user_type === 'MK2' &&
      user.user_type.type === 'MK1'
    ) {
      return false;
    }
    if (user.user_type.type === 'ADM') {
      return false;
    }
    if (
      eventProjectReceived.project_procedure.sequence === 6 &&
      eventProjectReceived.user_inclusion.id === user.id &&
      (user.user_type.type === 'MK1' || user.user_type.type === 'MK2')
    ) {
      return false;
    }
    if (
      eventProjectReceived.project_procedure.event_validator &&
      user.event_validator
    ) {
      return false;
    }

    return true;
  }

  function handleEditLink(
    eventProjectReceived: IEventProject,
  ): React.ReactNode | null {
    if (handleEditDisabled(eventProjectReceived)) {
      return null;
    }

    return (
      <Link
        to={{
          pathname: `/projects/events/procedure${eventProjectReceived.project_procedure.sequence}`,
          state: eventProjectReceived,
        }}
      >
        <EditButton />
      </Link>
    );
  }

  function handleViewLink(
    eventProjectReceived: IEventProject,
  ): React.ReactNode {
    return (
      <LinkView
        marginLeft={handleEditDisabled(eventProjectReceived)}
        to={{
          pathname: `/projects/events/view`,
          state: eventProjectReceived,
        }}
      >
        <ViewButton />
      </LinkView>
    );
  }

  function handleManualEditDisabled(
    eventProjectReceived: IEventProject,
  ): boolean {
    if (eventProjectReceived.status.toUpperCase() === 'CANCELADO') {
      return true;
    }
    if (eventProjectReceived.status.toUpperCase() === 'FINALIZADO') {
      return true;
    }
    if (
      user.user_type.type === 'ADM' ||
      user.user_type.type === 'MK1' ||
      user.user_type.type === 'MK2' ||
      user.user_type.type === 'MAX'
    ) {
      return false;
    }

    return true;
  }

  function handleManualEditLink(
    eventProjectReceived: IEventProject,
  ): React.ReactNode | null {
    if (handleManualEditDisabled(eventProjectReceived)) {
      return null;
    }

    return (
      <Link
        style={{ marginLeft: '0.5rem' }}
        to={{
          pathname: `/projects/events/editing/chooseprocess`,
          state: eventProjectReceived,
        }}
      >
        <FixButton />
      </Link>
    );
  }

  function handleDeleteButton(
    eventProjectReceived: IEventProject,
  ): React.ReactNode | null {
    if (
      eventProjectReceived.project_procedure.sequence === 2 &&
      eventProjectReceived.status === 'Pendente' &&
      (user.user_type.type === 'REP' || user.user_type.type === 'ADM')
    ) {
      return (
        <DeleteButton
          style={{ marginLeft: '0.5rem' }}
          onClick={() => {
            setProjectIdDelete(eventProjectReceived.id);
            setIsOpenDelete(true);
            setTitleDelete(
              `Deseja realmente excluir a solicitação ${eventProjectReceived.code} ` +
                `do cliente ${eventProjectReceived.local_client.company_name}, ` +
                `com data de evento em ${new Date(
                  eventProjectReceived.event_date,
                ).toLocaleDateString()}?`,
            );
            setSubTitleDelete('Esta operação não poderá ser desfeita!');
          }}
        />
      );
    }

    return null;
  }

  const onDelete = useCallback(
    async (id: string) => {
      try {
        await api.delete(`/projects/${id}`).then(() => {
          setPage(0);
          setLastRefresh(new Date());

          addToast({
            type: 'success',
            title: 'Solicitação excluída!',
            description: 'Solicitação excluída com sucesso.',
          });
        });
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
      setProjectIdDelete('');
      setTitleDelete('');
      setSubTitleDelete('');
    },
    [addToast],
  );

  function handleTable(): React.ReactNode {
    if (eventProjects) {
      if (eventProjects.length > 0) {
        return (
          <Table>
            <Thead>
              <tr>
                <Th width="10%" textAlign="left">
                  Data do Evento
                </Th>
                <Th width="10%" textAlign="left">
                  Código
                </Th>
                <Th width="20%" textAlign="left">
                  Cliente
                </Th>
                <Th width="15%" textAlign="left">
                  Cidade
                </Th>
                <Th width="5%" textAlign="left">
                  UF
                </Th>
                <Th width="10%" textAlign="left">
                  Tipo de Evento
                </Th>
                <Th width="10%" textAlign="left">
                  Situação
                </Th>
                <Th width="10%" textAlign="left">
                  Status Entrega
                </Th>
                <Th width="10%" textAlign="center" />
              </tr>
            </Thead>
            <Tbody>
              {loading && (
                <tr>
                  <Td colSpan={4} padding="1rem" textAlign="center">
                    <PropagateLoader color="#276c8d" />
                  </Td>
                </tr>
              )}
              {eventProjects?.map(eventProject => (
                <TbodyTr key={eventProject.id}>
                  <Td width="10%" textAlign="left" heading="DATA DO EVENTO">
                    {new Date(eventProject.event_date).toLocaleDateString()}
                  </Td>
                  <Td width="10%" textAlign="left" heading="CÓDIGO">
                    {eventProject.code}
                  </Td>
                  <Td width="20%" textAlign="left" heading="CLIENTE">
                    {`${
                      eventProject.local_client.company_name
                    } (${getFormattedCnpj(
                      eventProject.local_client.document,
                    )})`}
                  </Td>
                  <Td width="15%" textAlign="left" heading="CIDADE">
                    {eventProject.local_client.city}
                  </Td>
                  <Td width="5%" textAlign="left" heading="UF">
                    {eventProject.local_client.state}
                  </Td>
                  <Td width="10%" textAlign="left" heading="TIPO DE EVENTO">
                    {eventProject.event.name}
                  </Td>
                  <Td width="10%" textAlign="left" heading="SITUAÇÃO">
                    {eventProject.status}
                  </Td>
                  <Td width="10%" textAlign="left" heading="STATUS ENTREGA">
                    {eventProject.material_delivery_status}
                  </Td>
                  <Td
                    width="10%"
                    textAlign="center"
                    display="flex"
                    heading={' '}
                  >
                    {handleEditLink(eventProject)}

                    {handleViewLink(eventProject)}

                    {handleManualEditLink(eventProject)}

                    {handleDeleteButton(eventProject)}
                  </Td>
                </TbodyTr>
              ))}
            </Tbody>
          </Table>
        );
      }
    }

    return (
      <Table>
        <Thead>
          <tr>
            <Th width="100%" textAlign="center" />
          </tr>
        </Thead>
        <Tbody>
          <TbodyTr>
            <Td width="100%" textAlign="center">
              Nenhuma informação disponível
            </Td>
          </TbodyTr>
        </Tbody>
      </Table>
    );
  }

  function handleNewRequest(): React.ReactNode | null {
    if (
      user.user_type.type === 'REP' ||
      user.user_type.type === 'ADM' ||
      user.user_type.type === 'MK1' ||
      user.user_type.type === 'MK2' ||
      user.user_type.type === 'GCM' ||
      user.user_type.type === 'MAX'
    ) {
      if (isNewReqEnabled) {
        return (
          <Link
            to={
              user.user_type.type === 'REP'
                ? '/projects/events/procedure1/client'
                : '/projects/events/procedure1/representative'
            }
          >
            <NewButton />
          </Link>
        );
      }

      return (
        <DefaultTooltip title="Função indisponível">
          <NewButton disabled />
        </DefaultTooltip>
      );
    }

    return null;
  }

  return (
    <Container style={{ minHeight: 600 }}>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChild="Eventos"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaCalendarAlt}
        mainColored
      />

      <FilterContent>
        <DefaultInput
          name="search"
          labelText="Pesquisar"
          type="text"
          onChange={event => {
            setFilter(event.target.value);
            setPage(0);
          }}
        />
      </FilterContent>

      <ContentSplit>
        <FilterDatesContent>
          <FilterDateWrapper>
            <DateInput
              name="initial_event_date"
              labelFor="initial_event_date"
              labelText="Data de Evento Inicial"
              type="date"
              min="2021-01-01"
              onSet={e => {
                setInitialEventDate(e);
                setPage(0);
              }}
            />
          </FilterDateWrapper>

          <FilterDateWrapper style={{ marginLeft: '1rem' }}>
            <DateInput
              name="end_event_date"
              labelFor="end_event_date"
              labelText="Data de Evento Final"
              type="date"
              min="2021-01-01"
              onSet={e => {
                setEndEventDate(e);
                setPage(0);
              }}
            />
          </FilterDateWrapper>
        </FilterDatesContent>

        <ReportContent>
          <ReportButton onClick={() => setIsOpenReport(true)} />
        </ReportContent>
      </ContentSplit>

      {handleTable()}

      <ButtonsContent>
        <Pagination
          page={page}
          count={count}
          amountPerPage={AMOUNT_PER_PAGE}
          setPage={setPage}
        />

        {handleNewRequest()}
      </ButtonsContent>

      <ReportDialog
        isOpen={isOpenReport}
        setIsOpen={setIsOpenReport}
        projectType="EVENTO"
        ids={eventProjectsIds}
      />

      <ConfirmDeleteDialog
        isOpen={isOpenDelete}
        title={titleDelete}
        subTitle={subTitleDelete}
        setIsOpen={setIsOpenDelete}
        onClose={() => {
          onDelete(projectIdDelete);
        }}
      />
    </Container>
  );
};

export default List;
