import React, { InputHTMLAttributes, useState, useCallback } from 'react';

import ErrorTooltip from '~/components/Tooltips/Error';

import { Label, Container, Text } from './styles';

interface ITableTextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  name?: string;
  labelFor?: string;
  labelText: string;
  upperCaseLabel?: boolean;
  customHeight?: string;
  error?: string;
}

const TableTextArea = React.forwardRef<
  HTMLTextAreaElement,
  ITableTextAreaProps
>(
  (
    {
      name,
      labelText,
      labelFor,
      upperCaseLabel,
      customHeight,
      error,
      ...rest
    }: ITableTextAreaProps,
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleInputFocus = useCallback(() => {
      setIsFocused(!isFocused);
    }, [isFocused]);

    const handleInputBlur = useCallback(() => {
      setIsFocused(false);
    }, []);

    return (
      <>
        {labelText && (
          <Label isFocused={isFocused}>
            {upperCaseLabel ? labelText.toUpperCase() : labelText}
          </Label>
        )}

        <Container id={labelFor} isFocused={isFocused} isErrored={!!error}>
          <Text
            name={name}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            cols={5}
            rows={5}
            ref={ref}
            customHeight={customHeight}
            {...rest}
          />

          {error && <ErrorTooltip title={error} />}
        </Container>
      </>
    );
  },
);

export default TableTextArea;
