import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { FaTools, FaCog } from 'react-icons/fa';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

import DefaultLayout from '../../_layouts/default';

import BreadCrumb from '~/components/BreadCrumb';
import Toggle from '~/components/Toggle';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  TabTitle,
  Content,
  ToggleWrapper,
  ButtonContainer,
} from './styles';

interface IFormGeneralSettings {
  new_display_req_enabled: boolean;
  new_material_req_enabled: boolean;
  new_sponsorship_req_enabled: boolean;
  new_problem_rep_enabled: boolean;
  new_folder_req_enabled: boolean;
  new_sample_req_enabled: boolean;
  new_event_req_enabled: boolean;
}

interface IGeneralSettings {
  new_display_req_enabled: boolean;
  new_material_req_enabled: boolean;
  new_sponsorship_req_enabled: boolean;
  new_problem_rep_enabled: boolean;
  new_folder_req_enabled: boolean;
  new_sample_req_enabled: boolean;
  new_event_req_enabled: boolean;
}

const GeneralSettings: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const [generalSettings, setGeneralSettings] = useState<IGeneralSettings>();
  const [tabIndex, setTabIndex] = useState(0);
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { handleSubmit, register, control } = useForm<IFormGeneralSettings>();

  useEffect(() => {
    if (
      user.user_type.type !== 'ADM' &&
      !user.enable_new_display_req &&
      !user.enable_new_material_req &&
      !user.enable_new_sponsorship_req &&
      !user.enable_new_problem_rep &&
      !user.enable_new_folder_req &&
      !user.enable_new_sample_req &&
      !user.enable_new_event_req
    ) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Usuário sem permissão',
      });

      history.push('/management/main');
    } else {
      api.get('/generalsettings').then(response => {
        if (response.data) {
          setGeneralSettings(response.data);
        } else {
          setGeneralSettings({
            new_display_req_enabled: true,
            new_material_req_enabled: true,
            new_sponsorship_req_enabled: true,
            new_problem_rep_enabled: true,
            new_folder_req_enabled: true,
            new_sample_req_enabled: true,
            new_event_req_enabled: true,
          });
        }
      });
    }
  }, [addToast, history, register, user]);

  const submitForm = useCallback(
    async (data: IFormGeneralSettings) => {
      try {
        setIsSaving(true);

        await api.post('/generalsettings', data);

        addToast({
          type: 'success',
          title: 'Configurações atualizadas!',
          description: 'Configurações atualizadas com sucesso.',
        });

        history.push('/management/main');
      } catch (error: any) {
        setIsSaving(false);

        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [addToast, history],
  );

  const onExit = useCallback(() => {
    history.push('/management/main');
  }, [history]);

  return (
    <DefaultLayout>
      <Container>
        <BreadCrumb
          main="Administração"
          path="/management/main"
          firstChild="Configurações Gerais"
          mainIcon={FaTools}
          firstChildIcon={FaCog}
          mainColored
        />

        <form onSubmit={handleSubmit(submitForm)}>
          <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
            <TabList>
              <Tab>
                <TabTitle>Solicitações</TabTitle>
              </Tab>
            </TabList>

            <TabPanel>
              {generalSettings && (
                <Content>
                  {(user.enable_new_display_req ||
                    user.user_type.type === 'ADM') && (
                    <ToggleWrapper>
                      <Controller
                        render={props => (
                          <Toggle
                            onChange={e => props.onChange(e)}
                            checked={props.value}
                            label="Nova Sol. Display Habilitada"
                          />
                        )}
                        name="new_display_req_enabled"
                        defaultValue={generalSettings.new_display_req_enabled}
                        control={control}
                      />
                    </ToggleWrapper>
                  )}

                  {(user.enable_new_material_req ||
                    user.user_type.type === 'ADM') && (
                    <ToggleWrapper>
                      <Controller
                        render={props => (
                          <Toggle
                            onChange={e => props.onChange(e)}
                            checked={props.value}
                            label="Nova Sol. Material Habilitada"
                          />
                        )}
                        name="new_material_req_enabled"
                        defaultValue={generalSettings.new_material_req_enabled}
                        control={control}
                      />
                    </ToggleWrapper>
                  )}

                  {(user.enable_new_sponsorship_req ||
                    user.user_type.type === 'ADM') && (
                    <ToggleWrapper>
                      <Controller
                        render={props => (
                          <Toggle
                            onChange={e => props.onChange(e)}
                            checked={props.value}
                            label="Nova Sol. Pagamento Habilitada"
                          />
                        )}
                        name="new_sponsorship_req_enabled"
                        defaultValue={
                          generalSettings.new_sponsorship_req_enabled
                        }
                        control={control}
                      />
                    </ToggleWrapper>
                  )}

                  {(user.enable_new_problem_rep ||
                    user.user_type.type === 'ADM') && (
                    <ToggleWrapper>
                      <Controller
                        render={props => (
                          <Toggle
                            onChange={e => props.onChange(e)}
                            checked={props.value}
                            label="Novo Rep. Problema Habilitado"
                          />
                        )}
                        name="new_problem_rep_enabled"
                        defaultValue={generalSettings.new_problem_rep_enabled}
                        control={control}
                      />
                    </ToggleWrapper>
                  )}

                  {(user.enable_new_folder_req ||
                    user.user_type.type === 'ADM') && (
                    <ToggleWrapper>
                      <Controller
                        render={props => (
                          <Toggle
                            onChange={e => props.onChange(e)}
                            checked={props.value}
                            label="Nova Sol. Pasta Habilitada"
                          />
                        )}
                        name="new_folder_req_enabled"
                        defaultValue={generalSettings.new_folder_req_enabled}
                        control={control}
                      />
                    </ToggleWrapper>
                  )}

                  {(user.enable_new_sample_req ||
                    user.user_type.type === 'ADM') && (
                    <ToggleWrapper>
                      <Controller
                        render={props => (
                          <Toggle
                            onChange={e => props.onChange(e)}
                            checked={props.value}
                            label="Nova Sol. Amostra Habilitada"
                          />
                        )}
                        name="new_sample_req_enabled"
                        defaultValue={generalSettings.new_sample_req_enabled}
                        control={control}
                      />
                    </ToggleWrapper>
                  )}

                  {(user.enable_new_event_req ||
                    user.user_type.type === 'ADM') && (
                    <ToggleWrapper>
                      <Controller
                        render={props => (
                          <Toggle
                            onChange={e => props.onChange(e)}
                            checked={props.value}
                            label="Nova Sol. Evento Habilitada"
                          />
                        )}
                        name="new_event_req_enabled"
                        defaultValue={generalSettings.new_event_req_enabled}
                        control={control}
                      />
                    </ToggleWrapper>
                  )}
                </Content>
              )}
            </TabPanel>
          </Tabs>

          <ButtonContainer>
            <SaveButton
              type="button"
              processing={isSaving}
              disabled={isSaving}
              onClick={() => setIsOpenConfirmProcedure(true)}
            />

            <ExitButton
              style={{ marginLeft: 10 }}
              type="button"
              disabled={isSaving}
              onClick={() => setIsOpenExit(true)}
            />
          </ButtonContainer>
        </form>

        <ConfirmProcedureDialog
          isOpen={isOpenConfirmProcedure}
          setIsOpen={setIsOpenConfirmProcedure}
          onConfirm={() => handleSubmit(submitForm)()}
          title="Confirma gravação das configurações?"
          subTitle=""
          opConfirm="Sim"
          type=""
        />

        <ConfirmExitDialog
          isOpen={isOpenExit}
          setIsOpen={setIsOpenExit}
          onExit={onExit}
        />
      </Container>
    </DefaultLayout>
  );
};

export default GeneralSettings;
