import React from 'react';

import InfoInput from '~/components/Inputs/Info';

import { Container, Content } from './styles';

interface IRequestedEventDateProps {
  requested_event_date: Date;
}

const RequestedEventDate: React.FC<IRequestedEventDateProps> = ({
  requested_event_date,
}) => {
  return (
    <Container>
      <Content>
        <InfoInput
          name="requested_event_date"
          labelFor="requested_event_date"
          labelText="Data Evento Solicitado"
          type="text"
          defaultValue={new Date(requested_event_date).toLocaleDateString()}
          disabled
        />
      </Content>
    </Container>
  );
};

export default RequestedEventDate;
