import styled, { css } from 'styled-components';
import { shade } from 'polished';

import { IReportProps } from '.';

export const Container = styled.button<IReportProps>`
  ${({ theme }) => css`
    width: 22rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.report};
    color: #fff;
    border-radius: ${theme.radius.default};
    font-size: ${theme.fontSizes.button};
    font-weight: 400;

    :hover {
      background: ${shade(0.2, theme.colors.report)};
      transition: 0.2s;
    }
  `}
`;

export const IconWrapper = styled.div`
  margin: 0.5rem 0 0 1rem;
`;
