import React, { useCallback, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SyncLoader } from 'react-spinners';
import { FaEnvelope, FaLock } from 'react-icons/fa';
import * as Yup from 'yup';
import validator from 'validator';
import CryptoJS from 'crypto-js';

import Button from '~/components/Button';
import Input from '~/components/Input';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import logoImg from '~/assets/images/logo.png';

import { Container, Content, AnimationContainer } from './styles';

interface IFormAuth {
  email: string;
  password: string;
}

interface ILocation {
  type: string;
  project_id: string;
  project_type: string;
  erp_client_code: string;
}

interface IProject {
  type: string;
  project_procedure: {
    sequence: number;
  };
}

interface INewProjectData {
  type: string;
  erp_client_code?: string;
  erp_client_name?: string;
  erp_client_company_name?: string;
  erp_client_document?: string;
  erp_client_address?: string;
  erp_client_city?: string;
  erp_client_state?: string;
  erp_rep_code: string;
  rep_as_client: boolean;
}

const schema = Yup.object().shape({
  email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
  password: Yup.string().required('Campo obrigatório'),
});

const SignIn: React.FC = () => {
  const { user, signIn } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();
  const { state } = useLocation<ILocation>();
  const [project, setProject] = useState<IProject>();
  const [newProjectData, setNewProjectData] = useState<INewProjectData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [valInput, setValInput] = useState('');

  const { handleSubmit, register, getValues, errors } = useForm<IFormAuth>({
    resolver: yupResolver(schema),
  });

  const submitForm = useCallback(async () => {
    if (state && state.type === 'projectprocedure' && project) {
      let externalPath = '';

      if (project.type === 'DISPLAY') {
        externalPath = `/projects/displays/procedure${project.project_procedure.sequence}`;
      } else if (project.type === 'MATERIAL') {
        externalPath = `/projects/materials/procedure${project.project_procedure.sequence}`;
      } else if (project.type === 'PATROCINIO') {
        externalPath = `/projects/sponsorships/procedure${project.project_procedure.sequence}`;
      } else if (project.type === 'ADVERSIDADE') {
        externalPath = `/projects/adversities/procedure${project.project_procedure.sequence}`;
      } else if (project.type === 'AMOSTRA') {
        externalPath = `/projects/samples/procedure${project.project_procedure.sequence}`;
      } else if (project.type === 'EVENTO') {
        externalPath = `/projects/events/procedure${project.project_procedure.sequence}`;
      }

      history.push({
        pathname: externalPath,
        state: project,
      });
    } else if (state && state.type === 'newproject' && newProjectData && user) {
      let newProjectPath = '';

      if (!newProjectData.erp_client_code) {
        addToast({
          type: 'error',
          title: 'Operação inválida!',
          description: 'Cliente não localizado',
        });

        history.push('/dashboard');
      } else if (
        user.user_type.type === 'REP' &&
        user.erp_rep_code !== newProjectData.erp_rep_code
      ) {
        addToast({
          type: 'error',
          title: 'Operação inválida!',
          description: 'Usuário sem permissão',
        });

        history.push('/dashboard');
      } else {
        if (newProjectData.type === 'DISPLAY') {
          newProjectPath = '/projects/displays/procedure1/new';
        } else if (newProjectData.type === 'MATERIAL') {
          newProjectPath = '/projects/materials/procedure1/new';
        } else if (newProjectData.type === 'PATROCINIO') {
          newProjectPath = '/projects/sponsorships/procedure1/new';
        } else if (newProjectData.type === 'ADVERSIDADE') {
          newProjectPath = '/projects/adversities/procedure1/new';
        } else if (newProjectData.type === 'PASTA') {
          newProjectPath = '/projects/folders/procedure1/new';
        } else if (newProjectData.type === 'AMOSTRA') {
          newProjectPath = '/projects/samples/procedure1/new';
        } else if (newProjectData.type === 'EVENTO') {
          newProjectPath = '/projects/events/procedure1/new';
        }

        history.push({
          pathname: newProjectPath,
          state: newProjectData,
        });
      }
    } else {
      history.push('/dashboard');
    }
  }, [history, state, project, newProjectData, user, addToast]);

  useEffect(() => {
    if (project) {
      handleSubmit(submitForm)();
    }
  }, [project, handleSubmit, submitForm]);

  useEffect(() => {
    if (newProjectData) {
      handleSubmit(submitForm)();
    }
  }, [newProjectData, handleSubmit, submitForm]);

  async function validateSignIn(): Promise<void> {
    try {
      const emailValue = getValues('email');
      let passwordValue = '';

      if (process.env.REACT_APP_PASSWORD_CRYPTR_KEY) {
        passwordValue = CryptoJS.AES.encrypt(
          getValues('password'),
          process.env.REACT_APP_PASSWORD_CRYPTR_KEY,
        ).toString();
      }

      await signIn({ email: emailValue, password: passwordValue });

      if (state && state.type === 'projectprocedure') {
        if (state.project_id) {
          api.get(`/projects/id?id=${state.project_id}`).then(response => {
            setProject(response.data);
          });
        }
      } else if (state && state.type === 'newproject') {
        if (state.project_type && state.erp_client_code) {
          api
            .get(`/clients/code?code=${state.erp_client_code}`)
            .then(response => {
              setNewProjectData({
                type: state.project_type,
                erp_client_code: response.data.code,
                erp_client_name: response.data.name,
                erp_client_company_name: response.data.company_name,
                erp_client_document: response.data.document,
                erp_client_address: response.data.address,
                erp_client_city: response.data.city,
                erp_client_state: response.data.state,
                erp_rep_code: response.data.rep_code,
                rep_as_client: false,
              });
            });
        }
      } else {
        handleSubmit(submitForm)();
      }
    } catch (error: any) {
      addToast({
        type: 'error',
        title: 'Erro na autenticação',
        description: error.response.data.message,
      });
      setLoading(false);
    }
  }

  function validateForm(): void {
    const emailValue = getValues('email');
    const passwordValue = getValues('password');
    if (
      emailValue === '' ||
      passwordValue === '' ||
      !validator.isEmail(emailValue)
    ) {
      setLoading(false);
      handleSubmit(submitForm)();
    } else {
      validateSignIn();
    }
  }

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="Guararapes Logo" />
          <form onSubmit={handleSubmit(submitForm)}>
            <Input
              name="email"
              type="text"
              labelText="E-mail"
              icon={FaEnvelope}
              ref={register}
              error={errors.email?.message}
              validateInput
              onChange={event => setValInput(event.target.value)}
              valueInput={valInput}
            />

            <Input
              name="password"
              labelText="Senha"
              icon={FaLock}
              isPassword
              ref={register}
              error={errors.password?.message}
              validateInput
              onChange={event => setValInput(event.target.value)}
              valueInput={valInput}
            />

            <Button
              type="button"
              onClick={() => {
                setLoading(!loading);
                validateForm();
              }}
            >
              {loading ? (
                <SyncLoader size={8} color="#FFF" margin={4} />
              ) : (
                'Entrar'
              )}
            </Button>
            <a
              href="/resetpassword/request"
              rel="noopener noreferrer"
              target="_blank"
            >
              Esqueceu sua senha?
            </a>
          </form>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignIn;
