import React from 'react';
import { ReactSwitchProps } from 'react-switch';

import { Container, Label, ToggleButton } from './styles';

interface IToggleProps extends ReactSwitchProps {
  label?: string;
}

const Toggle = React.forwardRef<HTMLInputElement, IToggleProps>(
  ({ label, ...rest }: IToggleProps) => {
    return (
      <Container>
        <Label>{label !== undefined ? label : 'Ativo?'}</Label>
        <ToggleButton offColor="#ff0000" {...rest} />
      </Container>
    );
  },
);

export default Toggle;
