import React, { useState, useEffect } from 'react';
import { PropagateLoader } from 'react-spinners';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { FaAngleDown, FaFileAlt } from 'react-icons/fa';

import Paginator from '~/components/Paginator';
import PrevButton from '~/components/Paginator/PrevButton';
import NextButton from '~/components/Paginator/NextButton';
import CenterButton from '~/components/Paginator/CenterButton';
import ProjectLabelDetailModal from '~/components/Modals/Projects/LabelDetail';

import DefaultInput from '~/components/Inputs/Default';

import api from '~/services/api';

import {
  Container,
  Label,
  Wrapper,
  Table,
  Th,
  Td,
  DetailBtn,
  ButtonsContent,
} from './styles';

interface ILabelsProps {
  project_id: string;
}

interface IProjectLabel {
  id: string;
  control: string;
  packaging: string;
  erp_client_code: string;
  city: string;
  state: string;
  invoice_series: string;
  invoice_number: string;
  invoice_issue_date: Date;
  invoice_net_price: number;
  document: string;
  item_erp_code: string;
  batch: string;
  sequence: number;
  project_id: string;
  user_inclusion_id: string;
  user_update_id: string;
  project: {
    code: string;
    name: string;
  };
  user_inclusion: {
    name: string;
  };
  user_update: {
    name: string;
  };
  created_at: string;
}

const Labels: React.FC<ILabelsProps> = ({ project_id }) => {
  const [page, setPage] = useState(0);
  const [pageFilter, setPageFilter] = useState(0);
  const [filter, setFilter] = useState('');
  const [filterHelper, setFilterHelper] = useState('');
  const [refresh, setRefresh] = useState(true);
  const [projectLabels, setProjectLabels] = useState<IProjectLabel[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [sequenceDetail, setSequenceDetail] = useState(0);
  const [projectCodeDetail, setProjectCodeDetail] = useState('');
  const [controlDetail, setControlDetail] = useState('');
  const [packagingDetail, setPackagingDetail] = useState('');
  const [erpClientCodeDetail, setErpClientCodeDetail] = useState('');
  const [cityDetail, setCityDetail] = useState('');
  const [stateDetail, setStateDetail] = useState('');
  const [invoiceSeriesDetail, setInvoiceSeriesDetail] = useState('');
  const [invoiceNumberDetail, setInvoiceNumberDetail] = useState('');
  const [invoiceIssueDateDetail, setInvoiceIssueDateDetail] = useState(
    new Date(),
  );
  const [invoiceNetPriceDetail, setInvoiceNetPriceDetail] = useState(0);
  const [documentDetail, setDocumentDetail] = useState('');
  const [itemErpCodeDetail, setItemErpCodeDetail] = useState('');
  const [batchDetail, setBatchDetail] = useState('');
  const [userDetail, setUserDetail] = useState('');

  useEffect(() => {
    if (refresh && project_id) {
      setRefresh(false);
      let pageSearch = 0;
      let foundData = false;

      if (filter !== filterHelper) {
        setPage(0);
        setPageFilter(0);
      }

      setFilterHelper(filter);

      if (filter !== '') {
        pageSearch = pageFilter;
      } else {
        pageSearch = page;
      }

      api
        .get(
          `/projectlabels/project?project_id=${project_id}&page=${pageSearch}&filter=${filter}`,
        )
        .then(firstResponse => {
          if (firstResponse.data.length > 0) {
            foundData = true;
            setProjectLabels(firstResponse.data);
            setLoading(false);
          }

          if (!foundData) {
            if (pageSearch - 1 >= 0) {
              pageSearch -= 1;
              if (filter !== '') {
                setPageFilter(pageFilter - 1);
              } else {
                setPage(page - 1);
              }
            }

            api
              .get(
                `/projectlabels/project?project_id=${project_id}&page=${pageSearch}&filter=${filter}`,
              )
              .then(secondResponse => {
                setProjectLabels(secondResponse.data);
                setLoading(false);

                api
                  .get(
                    `/projectlabels/project?project_id=${project_id}&page=${
                      pageSearch + 1
                    }
                    &filter=${filter}`,
                  )
                  .then(thirdResponse => {
                    setNextDisabled(false);
                    if (thirdResponse.data.length === 0) {
                      setNextDisabled(true);
                    }
                  });
              });
          } else {
            api
              .get(
                `/projectlabels/project?project_id=${project_id}&page=${
                  pageSearch + 1
                }
                &filter=${filter}`,
              )
              .then(fourthResponse => {
                setNextDisabled(false);
                if (fourthResponse.data.length === 0) {
                  setNextDisabled(true);
                }
              });
          }
        });
    }
  }, [page, pageFilter, filter, filterHelper, refresh, project_id]);

  function handlePage(type: string): void {
    if (type === 'backward') {
      if (filter !== '') {
        setPageFilter(pageFilter - 1);
      } else {
        setPage(page - 1);
      }
    } else if (type === 'forward') {
      if (filter !== '') {
        setPageFilter(pageFilter + 1);
      } else {
        setPage(page + 1);
      }
    } else if (filter !== '') {
      setPageFilter(0);
    } else {
      setPage(0);
    }
    setRefresh(true);
  }

  function handlePrevButtonDisabled(): boolean {
    let validationPage = 0;

    if (filter !== '') {
      validationPage = pageFilter;
    } else {
      validationPage = page;
    }

    if (validationPage === 0) {
      return true;
    }

    return false;
  }

  function handleFilterChange(value: string): void {
    setFilter(value);
    setRefresh(true);
  }

  function handleTable(): React.ReactNode {
    if (projectLabels) {
      if (projectLabels.length > 0) {
        return (
          <Table>
            <thead>
              <tr>
                <Th width="20%" textAlign="left">
                  Nota Fiscal
                </Th>
                <Th width="20%" textAlign="left">
                  Embalagem
                </Th>
                <Th width="25%" textAlign="left">
                  Item
                </Th>
                <Th width="25%" textAlign="left">
                  Lote
                </Th>
                <Th width="10%" textAlign="center" />
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <Td colSpan={4} padding="1rem" textAlign="center">
                    <PropagateLoader color="#276c8d" />
                  </Td>
                </tr>
              )}
              {projectLabels?.map(projectLabel => (
                <tr key={projectLabel.id}>
                  <Td width="20%" textAlign="left" heading="NOTA FISCAL">
                    {projectLabel.invoice_number}
                  </Td>
                  <Td width="20%" textAlign="left" heading="EMBALAGEM">
                    {projectLabel.packaging.substring(0, 50)}
                  </Td>
                  <Td width="25%" textAlign="left" heading="ITEM">
                    {projectLabel.item_erp_code.substring(0, 50)}
                  </Td>
                  <Td width="25%" textAlign="left" heading="LOTE">
                    {projectLabel.batch}
                  </Td>
                  <Td
                    width="10%"
                    textAlign="center"
                    display="flex"
                    heading={' '}
                  >
                    <DetailBtn
                      onClick={() => {
                        setIsOpenDetail(true);
                        setSequenceDetail(projectLabel.sequence);
                        setProjectCodeDetail(projectLabel.project.code);
                        setControlDetail(projectLabel.control);
                        setPackagingDetail(projectLabel.packaging);
                        setErpClientCodeDetail(projectLabel.erp_client_code);
                        setCityDetail(projectLabel.city);
                        setStateDetail(projectLabel.state);
                        setInvoiceSeriesDetail(projectLabel.invoice_series);
                        setInvoiceNumberDetail(projectLabel.invoice_number);
                        setInvoiceIssueDateDetail(
                          projectLabel.invoice_issue_date,
                        );
                        setInvoiceNetPriceDetail(
                          projectLabel.invoice_net_price,
                        );
                        setDocumentDetail(projectLabel.document);
                        setItemErpCodeDetail(projectLabel.item_erp_code);
                        setBatchDetail(projectLabel.batch);
                        setUserDetail(projectLabel.user_inclusion.name);
                      }}
                    >
                      <FaFileAlt size={22} />
                    </DetailBtn>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        );
      }
    }

    return (
      <Table>
        <thead>
          <tr>
            <Th width="100%" textAlign="center" />
          </tr>
        </thead>
        <tbody>
          <tr>
            <Td width="100%" textAlign="center">
              Nenhuma informação disponível
            </Td>
          </tr>
        </tbody>
      </Table>
    );
  }

  function clearProjectLabelDetail(): void {
    setSequenceDetail(0);
    setProjectCodeDetail('');
    setControlDetail('');
    setPackagingDetail('');
    setErpClientCodeDetail('');
    setCityDetail('');
    setStateDetail('');
    setInvoiceSeriesDetail('');
    setInvoiceNumberDetail('');
    setInvoiceIssueDateDetail(new Date());
    setInvoiceNetPriceDetail(0);
    setDocumentDetail('');
    setItemErpCodeDetail('');
    setBatchDetail('');
    setUserDetail('');
  }

  if (!project_id) {
    return null;
  }

  return (
    <Container>
      <Accordion>
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Label>Etiquetas</Label>
        </AccordionSummary>

        <AccordionDetails>
          <Wrapper>
            <div>
              <DefaultInput
                name="search"
                labelText="Pesquisar"
                type="text"
                onChange={event => handleFilterChange(event.target.value)}
              />
            </div>

            {handleTable()}

            <ButtonsContent>
              <Paginator>
                <PrevButton
                  disabled={handlePrevButtonDisabled()}
                  onClick={() => handlePage('backward')}
                />
                <CenterButton onClick={() => handlePage('reset')} />
                <NextButton
                  disabled={nextDisabled}
                  onClick={() => handlePage('forward')}
                />
              </Paginator>
            </ButtonsContent>
          </Wrapper>
        </AccordionDetails>
      </Accordion>

      <ProjectLabelDetailModal
        isOpen={isOpenDetail}
        sequence={sequenceDetail}
        project_code={projectCodeDetail}
        control={controlDetail}
        packaging={packagingDetail}
        erp_client_code={erpClientCodeDetail}
        city={cityDetail}
        state={stateDetail}
        invoice_series={invoiceSeriesDetail}
        invoice_number={invoiceNumberDetail}
        invoice_issue_date={invoiceIssueDateDetail}
        invoice_net_price={invoiceNetPriceDetail}
        document={documentDetail}
        item_erp_code={itemErpCodeDetail}
        batch={batchDetail}
        user={userDetail}
        setIsOpen={setIsOpenDetail}
        onClose={() => {
          clearProjectLabelDetail();
        }}
      />
    </Container>
  );
};

export default Labels;
