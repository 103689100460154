import styled, { css } from 'styled-components';

import { ISelectProps } from '.';

export const Container = styled.button<ISelectProps>`
  ${({ theme, marginTop, marginLeft }) => css`
    background-color: ${theme.colors.green};
    color: #fff;
    padding: 8px;
    border-radius: 50%;
    box-shadow: 1px 1px 1px ${theme.colors.lightGrey};

    &:hover {
      transform: scale(1.2, 1.2);
    }

    ${marginTop &&
    css`
      margin-top: ${marginTop};
    `}

    ${marginLeft &&
    css`
      margin-left: ${marginLeft};
    `}
  `}
`;
