import React, { memo } from 'react';

import { Container } from './styles';

interface IGradientProps {
  height?: string;
}

const Gradient: React.FC<IGradientProps> = ({ height }) => {
  return <Container height={height !== undefined ? height : '30rem'} />;
};

export default memo(Gradient);
