import React from 'react';

import InfoInput from '~/components/Inputs/Info';

import { Container, Content } from './styles';

interface INetDiscountPriceProps {
  net_discount_price?: number;
}

const NetDiscountPrice: React.FC<INetDiscountPriceProps> = ({
  net_discount_price,
}) => {
  return (
    <Container>
      <Content>
        <InfoInput
          name="net_discount_price"
          labelFor="net_discount_price"
          labelText="Valor Desconto Líquido"
          type="text"
          defaultValue={
            net_discount_price
              ? new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(net_discount_price)
              : new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(0)
          }
          disabled
        />
      </Content>
    </Container>
  );
};

export default NetDiscountPrice;
