import styled, { css } from 'styled-components';
import { shade, tint } from 'polished';

import { INewProps } from '.';

export const Container = styled.button<INewProps>`
  ${({ theme, disabled }) => css`
    width: 220px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
    background-color: ${theme.colors.secondary};
    color: #fff;
    border-radius: ${theme.radius.default};
    font-size: ${theme.fontSizes.button};
    font-weight: 400;

    ${disabled
      ? css`
          background-color: ${tint(0.3, theme.colors.secondary)};
          cursor: not-allowed;
        `
      : css`
          :hover {
            background: ${shade(0.2, theme.colors.secondary)};
            transition: 0.2s;
          }
        `}
  `}
`;
