import React, { ButtonHTMLAttributes } from 'react';
import { FaAngleLeft } from 'react-icons/fa';

import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const PrevButton: React.FC<ButtonProps> = ({
  id,
  disabled,
  ...rest
}) => {
  return (
    <Container
      id={id}
      disabled={disabled}
      isDisabled={disabled}
      {...rest}
    >
      <FaAngleLeft size={16} />
    </Container>
  );
};

export default PrevButton;
