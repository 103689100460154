import React from 'react';

import InfoInput from '~/components/Inputs/Info';

import { Container, Content } from './styles';

interface ITotalSponsorshipSheetsPriceProps {
  total_sponsorship_sheets_price?: number;
}

const TotalSponsorshipSheetsPrice: React.FC<ITotalSponsorshipSheetsPriceProps> =
  ({ total_sponsorship_sheets_price }) => {
    return (
      <Container>
        <Content>
          <InfoInput
            name="total_sponsorship_sheets_price"
            labelFor="total_sponsorship_sheets_price"
            labelText="Valor Desconto Líquido"
            type="text"
            defaultValue={
              total_sponsorship_sheets_price
                ? new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(total_sponsorship_sheets_price)
                : new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(0)
            }
            disabled
          />
        </Content>
      </Container>
    );
  };

export default TotalSponsorshipSheetsPrice;
