import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FaTools, FaMobileAlt } from 'react-icons/fa';

import DefaultLayout from '../../_layouts/default';

import BreadCrumb from '~/components/BreadCrumb';
import DefaultInput from '~/components/Inputs/Default';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  ContentSplit,
  OneFifthWrapper,
  TwoFifthsWrapper,
  ButtonContainer,
} from './styles';

interface IFormMobileAccesses {
  android_url: string;
  ios_url: string;
  version: string;
}

interface IMobileAccesses {
  id: string;
  android_url: string;
  ios_url: string;
  version: string;
}

const MobileAccesses: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const [mobileAccesses, setMobileAccesses] = useState<IMobileAccesses>();
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { handleSubmit, register, setValue } = useForm<IFormMobileAccesses>();

  useEffect(() => {
    if (user.user_type.type !== 'ADM') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Tipo de usuário não permitido',
      });

      history.push('/management/main');
    } else {
      api.get('/mobileaccesses').then(response => {
        setMobileAccesses(response.data);
      });
    }
  }, [addToast, history, user]);

  useEffect(() => {
    if (mobileAccesses) {
      setValue('android_url', mobileAccesses.android_url);
      setValue('ios_url', mobileAccesses.ios_url);
      setValue('version', mobileAccesses.version);
    }
  }, [mobileAccesses, setValue]);

  const submitForm = useCallback(
    async (data: IFormMobileAccesses) => {
      try {
        setIsSaving(true);

        await api.post('/mobileaccesses', data);

        addToast({
          type: 'success',
          title: 'Acessos atualizados!',
          description: 'Acessos atualizados com sucesso.',
        });

        history.push('/management/main');
      } catch (error: any) {
        setIsSaving(false);

        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [addToast, history],
  );

  function onConfirmProcedure(): void {
    handleSubmit(submitForm)();
  }

  const onExit = useCallback(() => {
    history.push('/management/main');
  }, [history]);

  return (
    <DefaultLayout>
      <Container>
        <BreadCrumb
          main="Administração"
          path="/management/main"
          firstChild="Acessos Aplicativos Mobile"
          mainIcon={FaTools}
          firstChildIcon={FaMobileAlt}
          mainColored
        />

        <form onSubmit={handleSubmit(submitForm)}>
          <ContentSplit maxWidth="930px">
            <TwoFifthsWrapper>
              <DefaultInput
                name="android_url"
                labelFor="android_url"
                labelText="URL Android"
                type="text"
                ref={register}
                maxLength={100}
              />
            </TwoFifthsWrapper>

            <TwoFifthsWrapper>
              <DefaultInput
                name="ios_url"
                labelFor="ios_url"
                labelText="URL IOS"
                type="text"
                ref={register}
                maxLength={100}
              />
            </TwoFifthsWrapper>

            <OneFifthWrapper>
              <DefaultInput
                name="version"
                labelFor="version"
                labelText="Versão"
                type="text"
                ref={register}
                maxLength={7}
              />
            </OneFifthWrapper>
          </ContentSplit>

          <ButtonContainer>
            <SaveButton
              type="button"
              processing={isSaving}
              disabled={isSaving}
              onClick={() => setIsOpenConfirmProcedure(true)}
            />

            <ExitButton
              style={{ marginLeft: 10 }}
              type="button"
              disabled={isSaving}
              onClick={() => setIsOpenExit(true)}
            />
          </ButtonContainer>
        </form>

        <ConfirmProcedureDialog
          isOpen={isOpenConfirmProcedure}
          setIsOpen={setIsOpenConfirmProcedure}
          onConfirm={onConfirmProcedure}
          title="Confirma gravação dos acessos?"
          subTitle=""
          opConfirm="Sim"
          type=""
        />

        <ConfirmExitDialog
          isOpen={isOpenExit}
          setIsOpen={setIsOpenExit}
          onExit={onExit}
        />
      </Container>
    </DefaultLayout>
  );
};

export default MobileAccesses;
