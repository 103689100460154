import React from 'react';

import {
  FaBox,
  FaDollyFlatbed,
  FaMoneyBillWave,
  FaListAlt,
  FaFolder,
  FaBoxOpen,
  FaCalendarAlt,
} from 'react-icons/fa';

export default {
  Displays: {
    icon: <FaBox size={30} />,
    link: '/items/displays',
    backgroundColor: '#747474',
  },
  Materiais: {
    icon: <FaDollyFlatbed size={30} />,
    link: '/items/materials',
    backgroundColor: '#747474',
  },
  Pagamentos: {
    icon: <FaMoneyBillWave size={30} />,
    link: '/items/sponsorships',
    backgroundColor: '#747474',
  },
  Problemas: {
    icon: <FaListAlt size={30} />,
    link: '/items/adversities',
    backgroundColor: '#747474',
  },
  Pastas: {
    icon: <FaFolder size={30} />,
    link: '/items/folders',
    backgroundColor: '#747474',
  },
  Amostras: {
    icon: <FaBoxOpen size={30} />,
    link: '/items/samples',
    backgroundColor: '#747474',
  },
  Eventos: {
    icon: <FaCalendarAlt size={30} />,
    link: '/items/events',
    backgroundColor: '#747474',
  },
};
