import styled, { css } from 'styled-components';

import { ITopicProps } from '.';

export const Container = styled.button<ITopicProps>`
  ${({ theme }) => css`
    margin: 20px 0 0 5px;
    color: ${theme.colors.grey};
    background: transparent;
    font-size: ${theme.fontSizes.default};
    font-weight: bold;
    justify-content: center;

    &:hover {
      transform: scale(1.1, 1.1);
    }
  `}
`;
