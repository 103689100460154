import React from 'react';

import TextArea from '~/components/TextArea';

import { Container, Content } from './styles';

interface IObservationProps {
  note?: string;
}

const Observation: React.FC<IObservationProps> = ({ note }) => {
  return (
    <Container>
      <Content>
        <TextArea
          name="note"
          labelFor="note"
          labelText="Observação Geral"
          defaultValue={note}
          maxLength={1000}
          disabled
        />
      </Content>
    </Container>
  );
};

export default Observation;
