import React, { useState, useEffect } from 'react';

import Specific01 from '~/components/ProjectSections/Specific01';
import RecordNotes from '~/components/ProjectSections/RecordNotes';

import api from '~/services/api';

interface ISponsorship09Props {
  project_id: string;
}

interface IProject {
  id: string;
  type: string;
  code: string;
  gross_discount_price: number;
  net_discount_price: number;
  granted_discount_price: number;
  total_tax_deductions_price: number;
  total_sponsorship_sheets_price: number;
  erp_client_code: string;
  note: string;
  rebate_campaign: boolean;
  sponsorship_art_approval: boolean;
  sponsorship_completion_files: boolean;
  sponsorship: {
    code: string;
    name: string;
  };
}

const Sponsorship09: React.FC<ISponsorship09Props> = ({ project_id }) => {
  const [project, setProject] = useState<IProject>();

  useEffect(() => {
    api.get(`/projects/id?id=${project_id}`).then(response => {
      setProject(response.data);
    });
  }, [project_id]);

  function handleSpecific01(): React.ReactNode | null {
    if (project) {
      return (
        <Specific01
          project_id={project.id}
          type={project.type}
          code={project.code}
          erp_client_code={
            project.erp_client_code ? project.erp_client_code : ''
          }
          item={
            project.sponsorship.code && project.sponsorship.name
              ? `(${project.sponsorship.code}) - ${project.sponsorship.name}`
              : ''
          }
          gross_discount_price={project.gross_discount_price}
          net_discount_price={project.net_discount_price}
          note={project.note}
          default_expanded
        />
      );
    }

    return null;
  }

  function handleRecordNotes(): React.ReactNode | null {
    if (project) {
      return <RecordNotes project_id={project.id} default_expanded />;
    }

    return null;
  }

  return (
    <>
      {handleSpecific01()}
      {handleRecordNotes()}
    </>
  );
};

export default Sponsorship09;
