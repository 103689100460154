import styled, { css } from 'styled-components';

interface IContainerProps {
  isDisabled?: boolean;
}

export const Container = styled.button<IContainerProps>`
  ${({ theme, isDisabled }) => css`
    background-color: #fff;
    color: ${theme.colors.grey};
    margin: 0 0.6rem;
    padding: 8px 8px;
    border-radius: 100%;
    box-shadow: 0 0 4px #bbbbbb;

    ${!isDisabled &&
    css`
      &:hover {
        transform: scale(1.2, 1.2);
      }
    `}

    ${isDisabled &&
    css`
      background-color: ${theme.colors.secondLightGrey};

      svg {
        color: ${theme.colors.grey};
      }
    `}
  `}
`;
