import React from 'react';

import PendingProjects from './PendingProjects';
import Statistics from './Statistics';

import { AnimatedContainer } from './styles';
import { MAIN_CONTENT_ANIMATION } from './animations';

const MainContent = (): JSX.Element => {
  return (
    <AnimatedContainer variants={MAIN_CONTENT_ANIMATION}>
      <PendingProjects />
      <Statistics />
    </AnimatedContainer>
  );
};

export default MainContent;
