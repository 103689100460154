import React, { InputHTMLAttributes, useState, useCallback } from 'react';

import ErrorTooltip from '~/components/Tooltips/Error';

import WarningTooltip from '~/components/Tooltips/Warning';

import { Label, Container, InputText } from './styles';

interface ICurrencyProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  labelFor?: string;
  labelText?: string;
  upperCaseLabel?: boolean;
  error?: string;
  warning?: string;
  value?: number;
  defaultValue?: number;
  automaticValue?: boolean;
  disabled?: boolean;
  onSet?: (e: any) => void;
}

const Currency = React.forwardRef<HTMLInputElement, ICurrencyProps>(
  (
    {
      name,
      labelFor,
      labelText,
      upperCaseLabel,
      error,
      warning,
      value,
      defaultValue,
      automaticValue,
      disabled,
      onSet,
    }: ICurrencyProps,
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleInputFocus = useCallback(() => {
      setIsFocused(!isFocused);
    }, [isFocused]);

    const handleInputBlur = useCallback(() => {
      setIsFocused(false);
    }, []);

    const handleChange = (e: any): void => {
      const valueSplit = e.target.value.split(',', 2);

      if (valueSplit[1] && valueSplit[1].length > 2) {
        const decimalExcess = valueSplit[1].length - 2;

        e.target.value = e.target.value.substring(
          0,
          e.target.value.length - decimalExcess,
        );
      }

      if (onSet) {
        onSet(e);
      }
    };

    return (
      <>
        {labelText && (
          <Label isFocused={isFocused}>
            {upperCaseLabel ? labelText.toUpperCase() : labelText}
          </Label>
        )}

        <Container id={labelFor} isFocused={isFocused} isErrored={!!error}>
          <InputText
            name={name}
            type="text"
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            value={automaticValue ? value : undefined}
            defaultValue={defaultValue}
            ref={ref}
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            allowNegativeValue={false}
            decimalsLimit={2}
            maxLength={9}
            onChange={e => {
              handleChange(e);
            }}
            disabled={disabled}
          />

          {error && <ErrorTooltip title={error} />}

          {warning && <WarningTooltip title={warning} />}
        </Container>
      </>
    );
  },
);

export default Currency;
