import React, { useState, useEffect, useCallback } from 'react';
import { FaProjectDiagram, FaMoneyBillWave } from 'react-icons/fa';
import { PropagateLoader } from 'react-spinners';
import { Link, useLocation } from 'react-router-dom';

import BreadCrumb from '~/components/BreadCrumb';
import ConfirmDeleteDialog from '~/components/Dialogs/ConfirmDelete';
import DefaultInput from '~/components/Inputs/Default';
import DefaultTooltip from '~/components/Tooltips/Default';
import DeleteButton from '~/components/Buttons/Delete';
import EditButton from '~/components/Buttons/Edit';
import NewButton from '~/components/Buttons/New';
import Pagination from '~/components/Pagination';
import ReportButton from '~/components/Buttons/Report';
import ReportDialog from '~/components/Dialogs/Report';
import ViewButton from '~/components/Buttons/View';

import { getFormattedCnpj } from '~/utils';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  ContentSplit,
  FilterContent,
  ReportContent,
  Table,
  Thead,
  Tbody,
  Th,
  TbodyTr,
  Td,
  LinkView,
  ButtonsContent,
} from './styles';

interface ISponsorshipProject {
  id: string;
  code: string;
  status: string;
  gross_discount_price: number;
  sponsorship: {
    name: string;
  };
  local_client: {
    company_name: string;
    document: string;
    city: string;
    state: string;
  };
  user_inclusion: {
    id: string;
  };
  project_procedure: {
    sequence: number;
    process: string;
    user_type: string;
  };
  created_at: string;
}

const AMOUNT_PER_PAGE = 10;

const List: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<{ user_type_pending: boolean } | undefined>();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState('');
  const [lastRefresh, setLastRefresh] = useState(new Date());
  const [sponsorshipProjects, setSponsorshipProjects] =
    useState<ISponsorshipProject[]>();
  const [sponsorshipProjectsIds, setSponsorshipProjectsIds] = useState<
    string[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isOpenReport, setIsOpenReport] = useState(false);
  const [projectIdDelete, setProjectIdDelete] = useState('');
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isNewReqEnabled, setIsNewReqEnabled] = useState(false);
  const [titleDelete, setTitleDelete] = useState('');
  const [subTitleDelete, setSubTitleDelete] = useState('');

  useEffect(() => {
    const ids: string[] = [];
    if (sponsorshipProjects) {
      sponsorshipProjects.map(sponsorshipProject =>
        ids.push(sponsorshipProject.id),
      );

      setSponsorshipProjectsIds(ids);
    }
  }, [sponsorshipProjects]);

  useEffect(() => {
    setPage(0);
  }, [state]);

  useEffect(() => {
    api.get('/generalsettings').then(response => {
      if (response.data) {
        setIsNewReqEnabled(response.data.new_sponsorship_req_enabled);
      }
    });
  }, []);

  useEffect(() => {
    const loadSponsorshipProjects = async () => {
      await api
        .get(
          `/projects?type=PATROCINIO` +
            `&page=${page}` +
            `&filter=${filter}` +
            `&amount=${AMOUNT_PER_PAGE}` +
            `&user_type=${user.user_type.type}` +
            `&erp_rep_code=${
              user.user_type.type === 'REP' ? user.erp_rep_code : ''
            }` +
            `${
              state !== undefined && state.user_type_pending
                ? '&search_type=pending_by_user_type'
                : ''
            }`,
        )
        .then(response => {
          setSponsorshipProjects(response.data[0]);
          setCount(response.data[1]);
          setLoading(false);
        });
    };

    loadSponsorshipProjects();
  }, [page, filter, user, state, lastRefresh]);

  function handleEditDisabled(
    sponsorshipProjectReceived: ISponsorshipProject,
  ): boolean {
    if (sponsorshipProjectReceived.status.toUpperCase() === 'CANCELADO') {
      return true;
    }
    if (
      user.user_type.type ===
      sponsorshipProjectReceived.project_procedure.user_type
    ) {
      return false;
    }
    if (
      sponsorshipProjectReceived.project_procedure.user_type === 'MK2' &&
      user.user_type.type === 'MK1'
    ) {
      return false;
    }
    if (user.user_type.type === 'ADM') {
      return false;
    }
    if (
      sponsorshipProjectReceived.project_procedure.sequence === 4 &&
      (user.user_type.type === 'MK1' || user.user_type.type === 'MK2')
    ) {
      return false;
    }
    if (
      sponsorshipProjectReceived.project_procedure.sequence === 6 &&
      sponsorshipProjectReceived.user_inclusion.id === user.id &&
      (user.user_type.type === 'MK1' || user.user_type.type === 'MK2')
    ) {
      return false;
    }
    if (
      sponsorshipProjectReceived.project_procedure.process ===
        'APROVACAO_GERAL' &&
      user.spons_gen_approval_perm &&
      (!user.spons_gen_approval_min_price ||
        user.spons_gen_approval_min_price <=
          sponsorshipProjectReceived.gross_discount_price) &&
      (!user.spons_gen_approval_max_price ||
        user.spons_gen_approval_max_price >=
          sponsorshipProjectReceived.gross_discount_price)
    ) {
      return false;
    }

    return true;
  }

  function handleEditLink(
    sponsorshipProjectReceived: ISponsorshipProject,
  ): React.ReactNode | null {
    if (handleEditDisabled(sponsorshipProjectReceived)) {
      return null;
    }

    return (
      <Link
        to={{
          pathname: `/projects/sponsorships/procedure${sponsorshipProjectReceived.project_procedure.sequence}`,
          state: sponsorshipProjectReceived,
        }}
      >
        <EditButton />
      </Link>
    );
  }

  function handleViewLink(
    sponsorshipProjectReceived: ISponsorshipProject,
  ): React.ReactNode {
    return (
      <LinkView
        marginLeft={handleEditDisabled(sponsorshipProjectReceived)}
        to={{
          pathname: `/projects/sponsorships/view`,
          state: sponsorshipProjectReceived,
        }}
      >
        <ViewButton />
      </LinkView>
    );
  }

  function handleDeleteButton(
    sponsorshipProjectReceived: ISponsorshipProject,
  ): React.ReactNode | null {
    if (
      sponsorshipProjectReceived.project_procedure.sequence === 2 &&
      sponsorshipProjectReceived.status === 'Pendente' &&
      (user.user_type.type === 'REP' || user.user_type.type === 'ADM')
    ) {
      return (
        <DeleteButton
          style={{ marginLeft: '0.5rem' }}
          onClick={() => {
            setProjectIdDelete(sponsorshipProjectReceived.id);
            setIsOpenDelete(true);
            setTitleDelete(
              `Deseja realmente excluir a solicitação ${sponsorshipProjectReceived.code} ` +
                `do cliente ${sponsorshipProjectReceived.local_client.company_name}, ` +
                `aberta no dia ${new Date(
                  sponsorshipProjectReceived.created_at,
                ).toLocaleDateString()}?`,
            );
            setSubTitleDelete('Esta operação não poderá ser desfeita!');
          }}
        />
      );
    }

    return null;
  }

  const onDelete = useCallback(
    async (id: string) => {
      try {
        await api.delete(`/projects/${id}`).then(() => {
          setPage(0);
          setLastRefresh(new Date());

          addToast({
            type: 'success',
            title: 'Solicitação excluída!',
            description: 'Solicitação excluída com sucesso.',
          });
        });
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
      setProjectIdDelete('');
      setTitleDelete('');
      setSubTitleDelete('');
    },
    [addToast],
  );

  function handleTable(): React.ReactNode {
    if (sponsorshipProjects) {
      if (sponsorshipProjects.length > 0) {
        return (
          <Table>
            <Thead>
              <tr>
                <Th width="10%" textAlign="left">
                  Data Abertura
                </Th>
                <Th width="10%" textAlign="left">
                  Código
                </Th>
                <Th width="20%" textAlign="left">
                  Cliente
                </Th>
                <Th width="15%" textAlign="left">
                  Cidade
                </Th>
                <Th width="5%" textAlign="left">
                  UF
                </Th>
                <Th width="20%" textAlign="left">
                  Item
                </Th>
                <Th width="10%" textAlign="left">
                  Situação
                </Th>
              </tr>
            </Thead>
            <Tbody>
              {loading && (
                <tr>
                  <Td colSpan={4} padding="1rem" textAlign="center">
                    <PropagateLoader color="#276c8d" />
                  </Td>
                </tr>
              )}
              {sponsorshipProjects?.map(sponsorshipProject => (
                <TbodyTr key={sponsorshipProject.id}>
                  <Td width="10%" textAlign="left" heading="DATA ABERTURA">
                    {new Date(
                      sponsorshipProject.created_at,
                    ).toLocaleDateString()}
                  </Td>
                  <Td width="10%" textAlign="left" heading="CÓDIGO">
                    {sponsorshipProject.code}
                  </Td>
                  <Td width="20%" textAlign="left" heading="CLIENTE">
                    {`${
                      sponsorshipProject.local_client.company_name
                    } (${getFormattedCnpj(
                      sponsorshipProject.local_client.document,
                    )})`}
                  </Td>
                  <Td width="15%" textAlign="left" heading="CIDADE">
                    {sponsorshipProject.local_client.city}
                  </Td>
                  <Td width="5%" textAlign="left" heading="UF">
                    {sponsorshipProject.local_client.state}
                  </Td>
                  <Td width="20%" textAlign="left" heading="ITEM">
                    {sponsorshipProject.sponsorship.name}
                  </Td>
                  <Td width="10%" textAlign="left" heading="SITUAÇÃO">
                    {sponsorshipProject.status}
                  </Td>
                  <Td
                    width="10%"
                    textAlign="center"
                    display="flex"
                    heading={' '}
                  >
                    {handleEditLink(sponsorshipProject)}

                    {handleViewLink(sponsorshipProject)}

                    {handleDeleteButton(sponsorshipProject)}
                  </Td>
                </TbodyTr>
              ))}
            </Tbody>
          </Table>
        );
      }
    }

    return (
      <Table>
        <Thead>
          <tr>
            <Th width="100%" textAlign="center" />
          </tr>
        </Thead>
        <Tbody>
          <TbodyTr>
            <Td width="100%" textAlign="center">
              Nenhuma informação disponível
            </Td>
          </TbodyTr>
        </Tbody>
      </Table>
    );
  }

  function handleNewRequest(): React.ReactNode | null {
    if (
      user.user_type.type === 'REP' ||
      user.user_type.type === 'MK1' ||
      user.user_type.type === 'MK2' ||
      user.user_type.type === 'MAX' ||
      user.user_type.type === 'GCM' ||
      user.user_type.type === 'ADM'
    ) {
      if (isNewReqEnabled) {
        return (
          <Link
            to={
              user.user_type.type === 'REP'
                ? '/projects/sponsorships/procedure1/client'
                : '/projects/sponsorships/procedure1/representative'
            }
          >
            <NewButton />
          </Link>
        );
      }

      return (
        <DefaultTooltip title="Função indisponível">
          <NewButton disabled />
        </DefaultTooltip>
      );
    }

    return null;
  }

  return (
    <Container style={{ minHeight: 600 }}>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChild="Pagamentos"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaMoneyBillWave}
        mainColored
      />

      <ContentSplit>
        <FilterContent>
          <DefaultInput
            name="search"
            labelText="Pesquisar"
            type="text"
            onChange={event => {
              setFilter(event.target.value);
              setPage(0);
            }}
          />
        </FilterContent>

        <ReportContent>
          <ReportButton onClick={() => setIsOpenReport(true)} />
        </ReportContent>
      </ContentSplit>

      {handleTable()}

      <ButtonsContent>
        <Pagination
          page={page}
          count={count}
          amountPerPage={AMOUNT_PER_PAGE}
          setPage={setPage}
        />

        {handleNewRequest()}
      </ButtonsContent>

      <ReportDialog
        isOpen={isOpenReport}
        setIsOpen={setIsOpenReport}
        projectType="PATROCINIO"
        ids={sponsorshipProjectsIds}
      />

      <ConfirmDeleteDialog
        isOpen={isOpenDelete}
        title={titleDelete}
        subTitle={subTitleDelete}
        setIsOpen={setIsOpenDelete}
        onClose={() => {
          onDelete(projectIdDelete);
        }}
      />
    </Container>
  );
};

export default List;
