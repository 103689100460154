import React, { InputHTMLAttributes, useState, useCallback } from 'react';
import { IconBaseProps } from 'react-icons';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import { useTheme } from 'styled-components';

import ErrorTooltip from '~/components/Tooltips/Error';

import { Container, Label, InputText, PasswordButton } from './styles';

interface IDefaultProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  labelFor?: string;
  labelText: string;
  isPassword?: boolean;
  icon?: React.ComponentType<IconBaseProps>;
  error?: string;
  validateInput?: boolean;
  valueInput?: string;
}

const Default = React.forwardRef<HTMLInputElement, IDefaultProps>(
  (
    {
      name,
      labelText,
      labelFor,
      icon: Icon,
      error,
      isPassword,
      validateInput,
      valueInput,
      ...rest
    }: IDefaultProps,
    ref,
  ) => {
    const { colors } = useTheme();
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);
    const [clickPassword, setClickPassword] = useState<boolean>(false);

    const handlePassword = useCallback(() => {
      setClickPassword(!clickPassword);
    }, [clickPassword]);

    const handleInputFocus = useCallback(() => {
      setIsFocused(!isFocused);
    }, [isFocused]);

    const handleInputBlur = useCallback(
      (inputValidate, inputValue) => () => {
        setIsFocused(false);

        if (inputValidate) {
          setIsFilled(inputValue !== '');
        }
      },
      [],
    );

    return (
      <>
        <Label isFocused={isFocused} isFilled={isFilled}>
          {labelText}
        </Label>

        <Container
          id={labelFor}
          isFocused={isFocused}
          isFilled={isFilled}
          isErrored={!!error}
        >
          {Icon && <Icon size={18} />}
          <InputText
            name={name}
            type={clickPassword ? 'type' : 'password'}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur(validateInput, valueInput)}
            ref={ref}
            {...rest}
          />

          {isPassword && (
            <PasswordButton type="button" onClick={handlePassword}>
              {clickPassword ? (
                <FaEyeSlash size={18} color={colors.primary} />
              ) : (
                <FaEye size={18} />
              )}
            </PasswordButton>
          )}

          {error && <ErrorTooltip title={error} />}
        </Container>
      </>
    );
  },
);

export default Default;
