import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FaFileUpload } from 'react-icons/fa';
import filesize from 'filesize';

import { useTheme } from 'styled-components';

import AudioIcon from '~/components/Icons/Files/Audio';
import PdfIcon from '~/components/Icons/Files/Pdf';
import PresentationIcon from '~/components/Icons/Files/Presentation';
import SpreadsheetIcon from '~/components/Icons/Files/Spreadsheet';
import TextIcon from '~/components/Icons/Files/Text';
import VideoIcon from '~/components/Icons/Files/Video';
import WordIcon from '~/components/Icons/Files/Word';
import DeletePrevButton from '~/components/Buttons/DeletePrevious';

import {
  Container,
  Content,
  DropContainer,
  UploadMessage,
  FilePreviewImage,
} from './styles';

interface IFileUploaderProps {
  acceptedFileTypes: string[];
  onFileSelect: (file: File | null) => void;
}

const FileUploader: React.FC<IFileUploaderProps> = ({
  acceptedFileTypes,
  onFileSelect,
}) => {
  const { colors, fontSizes } = useTheme();
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState('');

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: any[]) => {
      if (fileRejections.length) {
        setError('Formato de arquivo inválido.');
        setFile(null);
        onFileSelect(null);
        return;
      }

      const selectedFile = acceptedFiles[0];
      setFile(selectedFile);
      setError('');
      onFileSelect(selectedFile);
    },
    [onFileSelect],
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
      accept: acceptedFileTypes,
      maxFiles: 1,
    });

  const renderDragMessage = useCallback(() => {
    if (!isDragActive) {
      return <UploadMessage>Arraste o arquivo aqui...</UploadMessage>;
    }

    if (isDragReject) {
      return (
        <UploadMessage type="error">
          Tipo de arquivo não suportado
        </UploadMessage>
      );
    }

    return <UploadMessage type="success">Solte o arquivo aqui</UploadMessage>;
  }, [isDragActive, isDragReject]);

  function handleFilePreview(uploadedFile: any): React.ReactNode {
    if (
      uploadedFile.type === 'audio/mp3' ||
      uploadedFile.type === 'audio/mpeg'
    ) {
      return <AudioIcon />;
    }

    if (uploadedFile.type === 'application/pdf') {
      return <PdfIcon />;
    }

    if (
      uploadedFile.type ===
        'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
      uploadedFile.type === 'application/vnd.oasis.opendocument.presentation'
    ) {
      return <PresentationIcon />;
    }

    if (
      uploadedFile.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      uploadedFile.type === 'application/vnd.ms-excel' ||
      uploadedFile.type === 'application/vnd.oasis.opendocument.spreadsheet'
    ) {
      return <SpreadsheetIcon />;
    }

    if (uploadedFile.type === 'text/plain') {
      return <TextIcon />;
    }

    if (
      uploadedFile.type === 'image/gif' ||
      uploadedFile.type === 'video/quicktime' ||
      uploadedFile.type === 'video/mp4'
    ) {
      return <VideoIcon />;
    }

    if (
      uploadedFile.type ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      uploadedFile.type === 'application/vnd.oasis.opendocument.text'
    ) {
      return <WordIcon />;
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <FilePreviewImage src={uploadedFile.preview} />
      </div>
    );
  }

  const clearFile = () => {
    setFile(null);
    setError('');
    onFileSelect(null);
  };

  return (
    <Container>
      <Content>
        <DropContainer {...getRootProps()}>
          <input {...getInputProps()} />
          {renderDragMessage()}
        </DropContainer>

        {!file && (
          <span>
            <FaFileUpload
              style={{ marginLeft: '45%', marginTop: 15 }}
              size={35}
              color={colors.grey}
            />
          </span>
        )}

        {error !== '' && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 10,
              fontSize: fontSizes.large,
              fontStyle: 'italic',
              color: colors.warning,
            }}
          >
            {error}
          </div>
        )}

        {file && (
          <div style={{ marginTop: '2rem' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '8rem' }}>{handleFilePreview(file)}</div>

                <div>
                  <div
                    style={{
                      fontSize: fontSizes.default,
                      color: colors.darkGrey,
                    }}
                  >
                    {file.name}
                  </div>

                  <div style={{ fontSize: fontSizes.tiny }}>
                    {filesize(file.size)}
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <DeletePrevButton iconSize={24} onClick={clearFile} />
              </div>
            </div>
          </div>
        )}
      </Content>
    </Container>
  );
};

export default FileUploader;
