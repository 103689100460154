import React from 'react';

import InfoInput from '~/components/Inputs/Info';

import { Container, Content } from './styles';

interface IUnitPriceProps {
  unit_price?: number;
}

const UnitPrice: React.FC<IUnitPriceProps> = ({ unit_price }) => {
  return (
    <Container>
      <Content>
        <InfoInput
          name="unit_price"
          labelFor="unit_price"
          labelText="Valor Unitário"
          type="text"
          defaultValue={
            unit_price
              ? new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(unit_price)
              : new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(0)
          }
          disabled
        />
      </Content>
    </Container>
  );
};

export default UnitPrice;
