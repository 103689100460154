import styled, { css, keyframes } from 'styled-components';

import signInBackgroundImg from '../../../assets/images/signin-background.png';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background: ${theme.colors.darkGrey};
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  height: 80vh;
  width: 100%;
  justify-content: center;
  align-content: center;
  max-width: 60rem;
  max-height: 60rem;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    background: ${theme.colors.background};
    animation: ${appearFromLeft} 1s;
    border-top-right-radius: ${theme.radius.small};
    border-bottom-right-radius: ${theme.radius.small};

    @media (max-width: 620px) {
      width: 70%;
      border-radius: ${theme.radius.small};
    }
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 8rem;
  justify-content: center;

  label {
    display: flex;
    margin: 0 6px;
  }
`;

export const SuccessContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 3rem;
  padding-left: 3rem;
  margin-top: 8rem;
  align-items: center;
  justify-content: center;
`;

export const InstructionsWrapper = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.small};
    text-align: justify;
    text-justify: inter-word;
    margin-bottom: 4rem;
  `}
`;

export const SuccessIconWrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.success};
  `}
`;

export const SuccessInfoWrapper = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.small};
    text-align: justify;
    text-justify: inter-word;
    margin-top: 4rem;
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  #btn-send {
    width: 15rem;
  }
`;

export const Background = styled.div`
  ${({ theme }) => css`
    background: url(${signInBackgroundImg}) no-repeat center;
    background-size: cover;
    width: 50%;
    border-top-left-radius: ${theme.radius.small};
    border-bottom-left-radius: ${theme.radius.small};

    @media (max-width: 620px) {
      display: none;
    }
  `}
`;
