import React from 'react';

import InfoInput from '~/components/Inputs/Info';

import { useAuth } from '~/hooks/auth';

import { Container, Content, Wrapper } from './styles';

interface ITplpProps {
  tplp_name?: string;
  tplp_email?: string;
}

const Tplp: React.FC<ITplpProps> = ({ tplp_name, tplp_email }) => {
  const { user } = useAuth();

  if (user.user_type.type === 'REP') {
    return null;
  }

  return (
    <Container>
      <Content>
        <Wrapper>
          <InfoInput
            name="tplp_name"
            labelFor="tplp_name"
            labelText="Nome"
            type="text"
            defaultValue={tplp_name !== undefined ? tplp_name : ''}
            disabled
          />
        </Wrapper>

        <Wrapper>
          <InfoInput
            name="tplp_email"
            labelFor="tplp_email"
            labelText="E-mail"
            type="text"
            defaultValue={tplp_email !== undefined ? tplp_email : ''}
            disabled
          />
        </Wrapper>
      </Content>
    </Container>
  );
};

export default Tplp;
