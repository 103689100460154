import React from 'react';
import { useLocation } from 'react-router-dom';

import DefaultLayout from '../../_layouts/default';

import List from './List';
import Record from './Record';
import DataLoad from './DataLoad';

const LocalClients: React.FC = () => {
  const { pathname } = useLocation();

  function getComponent(): React.ReactNode {
    if (pathname.indexOf('/management/localclients/edit') !== -1) {
      return <Record />;
    }

    if (pathname === '/management/localclients/dataload') {
      return <DataLoad />;
    }

    return <List />;
  }

  return <DefaultLayout>{getComponent()}</DefaultLayout>;
};

export default LocalClients;
