import React, { ButtonHTMLAttributes } from 'react';

import { FaEye } from 'react-icons/fa';

import { Container } from './styles';

export interface IViewProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  marginLeft?: string;
}

const View: React.FC<IViewProps> = ({ ...rest }) => {
  return (
    <Container {...rest}>
      <FaEye size={16} />
    </Container>
  );
};

export default View;
