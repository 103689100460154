import React from 'react';

import Upload from './Upload';
import List from './List';

import { Container, Content } from './styles';

import { CompletionFileProvider } from '~/context/completionfiles';

interface IUploadCompletionFilesProps {
  type: string;
  project_completion_temp_id: string;
  project_type: string;
  sequence: number;
  user_id: string;
}

const UploadCompletionFiles: React.FC<IUploadCompletionFilesProps> = ({
  type,
  project_completion_temp_id,
  project_type,
  sequence,
  user_id,
}) => {
  return (
    <CompletionFileProvider>
      <Container>
        <Content>
          <Upload
            type={type}
            project_completion_temp_id={project_completion_temp_id}
            project_type={project_type}
            sequence={sequence}
            user_id={user_id}
          />
          <List />
        </Content>
      </Container>
    </CompletionFileProvider>
  );
};

export default UploadCompletionFiles;
