import React, { Dispatch, SetStateAction } from 'react';
import { FaAngleLeft, FaEllipsisH, FaAngleRight } from 'react-icons/fa';

import { Container, PrevNextWrapper, CenterWrapper } from './styles';

interface IPaginationProps {
  page: number;
  count: number;
  amountPerPage: number;
  setPage: Dispatch<SetStateAction<number>>;
}

const Pagination: React.FC<IPaginationProps> = ({
  page,
  count,
  amountPerPage,
  setPage,
}: IPaginationProps) => {
  const disabledPrevious = page === 0;
  const disabledStart = page === 0;
  const disabledNext =
    count <= amountPerPage || count / amountPerPage <= page + 1;

  return (
    <Container>
      <PrevNextWrapper
        disabled={disabledPrevious}
        onClick={() => setPage(page - 1)}
      >
        <FaAngleLeft size={16} />
      </PrevNextWrapper>

      <CenterWrapper disabled={disabledStart} onClick={() => setPage(0)}>
        <FaEllipsisH size={16} />
      </CenterWrapper>

      <PrevNextWrapper
        disabled={disabledNext}
        onClick={() => setPage(page + 1)}
      >
        <FaAngleRight size={16} />
      </PrevNextWrapper>
    </Container>
  );
};

export default Pagination;
