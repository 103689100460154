import React, { Dispatch, SetStateAction } from 'react';
import { FcHighPriority } from 'react-icons/fc';
import { Dialog } from '@material-ui/core';

import DialogOptionButton from '~/components/Buttons/DialogOption';

import {
  Container,
  IconContainer,
  Title,
  SubTitle,
  ButtonContainer,
} from './styles';

interface IConfirmWarningProps {
  isOpen: boolean;
  onConfirm: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title?: string;
  subTitle?: string;
  opConfirm?: string;
}

const ConfirmWarning: React.FC<IConfirmWarningProps> = ({
  isOpen,
  onConfirm,
  setIsOpen,
  title,
  subTitle,
  opConfirm,
}: IConfirmWarningProps) => {
  const handleConfirmation = (): void => {
    setIsOpen(false);
    onConfirm();
  };

  const handleClose = (): void => {
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen}>
      <Container>
        <IconContainer>
          <FcHighPriority size={60} color="#c9aa71" />
        </IconContainer>

        <Title>{title !== undefined ? title : 'Confirma a operação?'}</Title>

        {!!subTitle && <SubTitle>{subTitle}</SubTitle>}

        <ButtonContainer>
          <DialogOptionButton
            label={opConfirm !== undefined ? opConfirm : 'Sim, confirmar'}
            optionType="danger"
            onClick={() => handleConfirmation()}
          />

          <DialogOptionButton
            label="Não"
            marginLeft="1.5rem"
            onClick={() => handleClose()}
          />
        </ButtonContainer>
      </Container>
    </Dialog>
  );
};

export default ConfirmWarning;
