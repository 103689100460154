import React, { createContext, useState, useCallback, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import filesize from 'filesize';

import api from '~/services/api';

export interface IPost {
  request_file_temp_id: string;
  name: string;
  size: number;
  key: string;
  url: string;
}

export interface IRequestFile {
  id: string;
  name: string;
  readableSize: string;
  type: string;
  uploaded?: boolean;
  preview: string;
  file: File | null;
  progress?: number;
  error?: boolean;
  url: string;
}

interface IRequestFileContextData {
  uploadedRequestFiles: IRequestFile[];
  deleteRequestFile(id: string): void;
  handleUpload(
    file: any,
    type: string,
    projectRequestTempId: string,
    projectType: string,
    sequence: number,
    userId: string,
  ): void;
}

const RequestFileContext = createContext<IRequestFileContextData>(
  {} as IRequestFileContextData,
);

const RequestFileProvider: React.FC = ({ children }) => {
  const [uploadedRequestFiles, setUploadedRequestFiles] = useState<
    IRequestFile[]
  >([]);

  const updateRequestFile = useCallback((id, data) => {
    setUploadedRequestFiles(state =>
      state.map(file => (file.id === id ? { ...file, ...data } : file)),
    );
  }, []);

  const processUpload = useCallback(
    (
      uploadedRequestFile: IRequestFile,
      type: string,
      projectRequestTempId: string,
      projectType: string,
      sequence: number,
      userId: string,
    ) => {
      const data = new FormData();
      if (uploadedRequestFile.file) {
        data.append('type', type);
        data.append('project_request_temp_id', projectRequestTempId);
        data.append('project_type', projectType);
        data.append('sequence', String(sequence));
        data.append('request_file_temp_id', uploadedRequestFile.id);
        data.append('user_id', userId);
        data.append('file', uploadedRequestFile.file, uploadedRequestFile.name);
      }

      api
        .post('/projectrequestfilesreceived', data, {
          onUploadProgress: progressEvent => {
            const progress: number = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );

            updateRequestFile(uploadedRequestFile.id, { progress });
          },
        })
        .then(response => {
          updateRequestFile(uploadedRequestFile.id, {
            uploaded: true,
            id: response.data.id,
            url: response.data.url,
          });
        })
        .catch(err => {
          console.log(err);
          updateRequestFile(uploadedRequestFile.id, {
            error: true,
          });
        });
    },
    [updateRequestFile],
  );

  const handleUpload = useCallback(
    (
      files: File[],
      type: string,
      projectTempId: string,
      projectType: string,
      sequence: number,
      userId: string,
    ) => {
      const newUploadedRequestFiles: IRequestFile[] = files.map(
        (file: File) => ({
          file,
          id: uuidv4(),
          name: file.name,
          readableSize: filesize(file.size),
          type: file.type,
          preview: URL.createObjectURL(file),
          progress: 0,
          uploaded: false,
          error: false,
          url: '',
        }),
      );

      setUploadedRequestFiles(state => state.concat(newUploadedRequestFiles));
      newUploadedRequestFiles.forEach(upRequestFile =>
        processUpload(
          upRequestFile,
          type,
          projectTempId,
          projectType,
          sequence,
          userId,
        ),
      );
    },
    [processUpload],
  );

  const deleteRequestFile = useCallback((id: string) => {
    api.delete(`projectrequestfilesreceived/${id}`);
    setUploadedRequestFiles(state => state.filter(file => file.id !== id));
  }, []);

  return (
    <RequestFileContext.Provider
      value={{ uploadedRequestFiles, deleteRequestFile, handleUpload }}
    >
      {children}
    </RequestFileContext.Provider>
  );
};

function useRequestFiles(): IRequestFileContextData {
  const context = useContext(RequestFileContext);

  if (!context) {
    throw new Error(
      'useRequestFiles deve ser usado dentro de um RequestFileProvider',
    );
  }

  return context;
}

export { RequestFileProvider, useRequestFiles };
