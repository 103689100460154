import React, { Dispatch, SetStateAction } from 'react';

import InfoInput from '~/components/Inputs/Info';
import TextArea from '~/components/TextArea';
import CloseButton from '~/components/Buttons/Close';

import {
  StyledModal,
  MainContainer,
  Title,
  Container,
  Content,
  ContentSplit,
  Wrapper,
  HalfWrapper,
  ButtonContainer,
} from './styles';

interface IRecordDetailProps {
  isOpen: boolean;
  onClose: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  description?: string;
  status?: string;
  note?: string;
  reason?: string;
  project_code?: string;
  user?: string;
  project_procedure_description?: string;
}

const RecordDetail: React.FC<IRecordDetailProps> = ({
  isOpen,
  onClose,
  setIsOpen,
  description,
  status,
  note,
  reason,
  project_code,
  user,
  project_procedure_description,
}: IRecordDetailProps) => {
  const handleClose = (): void => {
    setIsOpen(false);
    onClose();
  };

  return (
    <StyledModal
      isOpen={isOpen}
      style={{
        overlay: {
          zIndex: 999,
          overflowY: 'auto',
          position: 'fixed',
          background: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <MainContainer>
        <Title>Detalhes Registro Histórico</Title>
        <Container>
          <ContentSplit maxWidth="680px">
            <HalfWrapper marginRight="1rem">
              <InfoInput
                name="project_code"
                labelFor="project_code"
                labelText="Cód. Projeto"
                type="text"
                defaultValue={project_code}
                disabled
              />
            </HalfWrapper>

            {!!status && (
              <HalfWrapper>
                <InfoInput
                  name="status"
                  labelFor="status"
                  labelText="Status"
                  type="text"
                  defaultValue={status}
                  disabled
                />
              </HalfWrapper>
            )}
          </ContentSplit>

          {!!description && (
            <Content maxWidth="680px">
              <Wrapper>
                <InfoInput
                  name="description"
                  labelFor="description"
                  labelText="Descrição"
                  type="text"
                  defaultValue={description}
                  disabled
                />
              </Wrapper>
            </Content>
          )}

          {!!note && (
            <Content maxWidth="680px">
              <Wrapper>
                <TextArea
                  name="note"
                  labelFor="note"
                  labelText="Observação"
                  defaultValue={note}
                  maxLength={1000}
                  disabled
                />
              </Wrapper>
            </Content>
          )}

          {!!reason && (
            <Content maxWidth="680px">
              <Wrapper>
                <TextArea
                  name="reason"
                  labelFor="reason"
                  labelText="Motivo"
                  defaultValue={reason}
                  maxLength={1000}
                  disabled
                />
              </Wrapper>
            </Content>
          )}

          <ContentSplit maxWidth="680px">
            {!!user && (
              <HalfWrapper marginRight="1rem">
                <InfoInput
                  name="user"
                  labelFor="user"
                  labelText="Usuário"
                  type="text"
                  defaultValue={user}
                  disabled
                />
              </HalfWrapper>
            )}

            {!!project_procedure_description && (
              <HalfWrapper>
                <InfoInput
                  name="project_procedure_name"
                  labelFor="project_procedure_name"
                  labelText="Procedimento"
                  type="text"
                  defaultValue={project_procedure_description}
                  disabled
                />
              </HalfWrapper>
            )}
          </ContentSplit>

          <ButtonContainer>
            <CloseButton onClick={() => handleClose()} />
          </ButtonContainer>
        </Container>
      </MainContainer>
    </StyledModal>
  );
};

export default RecordDetail;
