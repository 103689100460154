import React from 'react';
import { useLocation } from 'react-router-dom';

import DefaultLayout from '../../_layouts/default';

import ChooseProject from './ChooseProject';
import Deletion from './Deletion';

const ProjectDeletion: React.FC = () => {
  const { pathname } = useLocation();

  function getComponent(): React.ReactNode {
    if (pathname === '/management/projectdeletion/deletion') {
      return <Deletion />;
    }

    return <ChooseProject />;
  }

  return <DefaultLayout>{getComponent()}</DefaultLayout>;
};

export default ProjectDeletion;
