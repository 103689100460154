import React, { useState, useEffect } from 'react';

import Representative from '~/components/StoreRelationshipSections/Representative';
import Client from '~/components/StoreRelationshipSections/Client';
import ClientPatterns from '~/components/StoreRelationshipSections/ClientPatterns';
import Main from '~/components/StoreRelationshipSections/Main';
import ClientContact from '~/components/StoreRelationshipSections/ClientContact';
import Projects from '~/components/StoreRelationshipSections/Projects';
import Records from '~/components/StoreRelationshipSections/Records';
import Topics from '~/components/StoreRelationshipSections/Topics';
import Displays from '~/components/StoreRelationshipSections/Displays';

import api from '~/services/api';

interface IStoreRelationshipInfoProps {
  erp_rep_code?: string;
  erp_client_code?: string;
  store_relationship_id?: string;
}

interface IStoreRelationship {
  id: string;
  code: string;
  erp_client_code: string;
  erp_rep_code: string;
  trade_name: string;
  client_contact_name: string;
  client_contact_phone: string;
  client_contact_email: string;
  note: string;
  store_relationship_category_id: string;
}

const StoreRelationshipInfo: React.FC<IStoreRelationshipInfoProps> = ({
  erp_rep_code,
  erp_client_code,
  store_relationship_id,
}) => {
  const [storeRelationship, setStoreRelationship] =
    useState<IStoreRelationship>();

  useEffect(() => {
    if (store_relationship_id) {
      api
        .get(`/storerelationships/iddetail?id=${store_relationship_id}`)
        .then(response => {
          setStoreRelationship(response.data);
        });
    }
  }, [store_relationship_id]);

  function handleRepresentative(): React.ReactNode {
    return (
      <Representative
        erp_rep_code={erp_rep_code !== undefined ? erp_rep_code : ''}
      />
    );
  }

  function handleClient(): React.ReactNode | null {
    return (
      <Client
        erp_client_code={erp_client_code !== undefined ? erp_client_code : ''}
        trade_name={
          storeRelationship?.trade_name ? storeRelationship.trade_name : ''
        }
        store_relationship_category_id={
          storeRelationship?.store_relationship_category_id
            ? storeRelationship.store_relationship_category_id
            : ''
        }
      />
    );
  }

  function handleClientPatterns(): React.ReactNode | null {
    return (
      <ClientPatterns
        erp_client_code={erp_client_code !== undefined ? erp_client_code : ''}
      />
    );
  }

  function handleMain(): React.ReactNode | null {
    return (
      <Main
        code={storeRelationship?.code ? storeRelationship.code : ''}
        note={storeRelationship?.note ? storeRelationship.note : ''}
      />
    );
  }

  function handleClientContact(): React.ReactNode | null {
    return (
      <ClientContact
        client_contact_name={
          storeRelationship?.client_contact_name
            ? storeRelationship.client_contact_name
            : ''
        }
        client_contact_phone={
          storeRelationship?.client_contact_phone
            ? storeRelationship.client_contact_phone
            : ''
        }
        client_contact_email={
          storeRelationship?.client_contact_email
            ? storeRelationship.client_contact_email
            : ''
        }
      />
    );
  }

  function handleProjects(): React.ReactNode | null {
    return (
      <Projects
        erp_client_code={erp_client_code !== undefined ? erp_client_code : ''}
      />
    );
  }

  function handleRecords(): React.ReactNode | null {
    return (
      <Records
        store_relationship_id={
          storeRelationship?.id ? storeRelationship.id : ''
        }
      />
    );
  }

  function handleTopics(): React.ReactNode | null {
    return (
      <Topics
        store_relationship_id={
          storeRelationship?.id ? storeRelationship?.id : ''
        }
      />
    );
  }

  function handleDisplays(): React.ReactNode | null {
    return (
      <Displays
        store_relationship_id={
          storeRelationship?.id ? storeRelationship?.id : ''
        }
      />
    );
  }

  return (
    <>
      {handleRepresentative()}
      {handleClient()}
      {handleClientPatterns()}
      {handleMain()}
      {handleClientContact()}
      {handleProjects()}
      {handleRecords()}
      {handleTopics()}
      {handleDisplays()}
    </>
  );
};

export default StoreRelationshipInfo;
