import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 50rem;
`;

export const Label = styled.label`
  ${({ theme }) => css`
    width: 0;
    height: 0;
    font-size: ${theme.fontSizes.default};
    white-space: nowrap;
    color: ${theme.colors.labelInput};
  `}
`;
