import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;

    svg {
      color: ${theme.colors.audioFileIcon};
    }
  `}
`;
