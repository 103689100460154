import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

export interface ITopicProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
}

const Topic: React.FC<ITopicProps> = ({ label, ...rest }) => {
  return <Container {...rest}>{label}</Container>;
};

export default Topic;
