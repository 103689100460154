import React, { InputHTMLAttributes, useState, useCallback } from 'react';

import ErrorTooltip from '~/components/Tooltips/Error';

import WarningTooltip from '~/components/Tooltips/Warning';

import { Label, Container, InputText } from './styles';

interface IPercentageProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  labelFor?: string;
  labelText?: string;
  upperCaseLabel?: boolean;
  error?: string;
  warning?: string;
  value?: number;
  defaultValue?: number;
  automaticValue?: boolean;
  disabled?: boolean;
  onSet?: (e: any) => void;
}

const Percentage = React.forwardRef<HTMLInputElement, IPercentageProps>(
  (
    {
      name,
      labelFor,
      labelText,
      upperCaseLabel,
      error,
      warning,
      value,
      defaultValue,
      automaticValue,
      disabled,
      onSet,
    }: IPercentageProps,
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleInputFocus = useCallback(() => {
      setIsFocused(!isFocused);
    }, [isFocused]);

    const handleInputBlur = useCallback(() => {
      setIsFocused(false);
    }, []);

    const handleChange = (e: any): void => {
      if (onSet) {
        onSet(e);
      }
    };

    return (
      <>
        {labelText && (
          <Label isFocused={isFocused}>
            {upperCaseLabel ? labelText.toUpperCase() : labelText}
          </Label>
        )}

        <Container id={labelFor} isFocused={isFocused} isErrored={!!error}>
          <InputText
            name={name}
            type="text"
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            value={automaticValue ? value : undefined}
            defaultValue={defaultValue}
            ref={ref}
            intlConfig={{ locale: 'pt-BR' }}
            allowNegativeValue={false}
            decimalsLimit={2}
            prefix="%"
            maxLength={6}
            onChange={e => {
              handleChange(e);
            }}
            disabled={disabled}
          />

          {error && <ErrorTooltip title={error} />}

          {warning && <WarningTooltip title={warning} />}
        </Container>
      </>
    );
  },
);

export default Percentage;
