import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

interface IThProps {
  width: string;
  textAlign: string;
}

interface ITdProps {
  width?: string;
  textAlign?: string;
  padding?: string;
  display?: string;
  heading?: string;
}

export const Container = styled(motion.section)`
  ${({ theme }) => css`
    width: 100%;
    min-height: 600px;
    margin: 2.4rem 0;
    padding: 2.4rem;
    background: ${theme.colors.backgroundContainer};
    border-radius: ${theme.radius.small};
    box-shadow: ${theme.shadows.flat};
  `}
`;

export const FilterContent = styled.div`
  width: 50%;

  @media (max-width: 1200px) {
    width: 100%;
    max-width: 680px;
  }
`;

export const Table = styled.table`
  ${({ theme }) => css`
    margin: 3rem 0 0 0;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0 4px;
    background-color: ${theme.colors.lightGrey};
    box-shadow: 1px 1px 6px #ececec;
    border-radius: ${theme.radius.small};
  `}
`;

export const Thead = styled.thead`
  @media (max-width: 995px) {
    display: none;
  }
`;

export const Tbody = styled.tbody`
  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundContainer};
  `}
`;

export const Th = styled.th<IThProps>`
  ${({ width, textAlign }) => css`
    padding: 1.8rem 0 0 1rem;
    font-size: 14px;
    text-transform: uppercase;

    &:first-child {
      padding-left: 2rem;
    }

    ${width &&
    css`
      width: ${width};
    `}

    ${textAlign &&
    css`
      text-align: ${textAlign};
    `}
  `}
`;

export const TbodyTr = styled.tr`
  ${({ theme }) => css`
    transition: all 0.25s ease;
    background-color: #fff;

    &:hover {
      transform: translateY(-1px);
      background-color: ${theme.colors.hoverTable};
      border-radius: ${theme.radius.small};
    }

    @media (max-width: 995px) {
      display: block;
      margin-bottom: 2rem;
      border-radius: ${theme.radius.small};
    }
  `}
`;

export const Td = styled.td<ITdProps>`
  ${({ theme, width, textAlign, padding, display, heading }) => css`
    border: 0;
    font-size: ${theme.fontSizes.meddium};
    padding: 1rem 0 0.5rem 1rem;
    font-weight: ${theme.weight.default};

    &:first-child {
      padding-left: 2rem;

      &::before {
        border-top-left-radius: ${theme.radius.small};
      }
    }

    &:last-child {
      padding-right: 2rem;

      &::before {
        border-bottom-left-radius: ${theme.radius.small};
      }
    }

    ${width &&
    css`
      width: ${width};
    `}

    ${textAlign &&
    css`
      text-align: ${textAlign};
    `}

    ${padding &&
    css`
      padding: ${padding};
    `}

    ${display &&
    css`
      display: ${display};
    `}

    @media (max-width: 995px) {
      display: block;
      position: relative;
      width: 100%;
      padding-left: 14.2rem;
      text-align: left;

      &:first-child {
        padding-left: 14.2rem;
      }

      ${display &&
      css`
        display: ${display};
      `}

      &::before {
        ${heading &&
        css`
          content: '${heading}';
        `}

        width: 12rem;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        position: absolute;
        background-color: ${theme.colors.lightGrey};
        padding: 0 0.8rem;
        font-size: 14px;
        font-weight: 700;
      }
    }
  `}
`;

export const LinkView = styled(Link)`
  margin-left: 0.5rem;
`;
