import React, { useState, useEffect, useRef } from 'react';

import Select from '~/components/Select';
import DistributedColumnsPrices2 from '~/components/StatisticsCharts/DistributedColumnsPrices2';

import api from '~/services/api';

import {
  Container,
  FilterContent,
  FilterWrapper,
  ChartWrapper,
} from './styles';

interface IRepresentative {
  code: string;
  name: string;
}

interface IClient {
  code: string;
  company_name: string;
}

interface IProject {
  type: string;
  gross_discount_price?: number;
  net_discount_price?: number;
  total_tax_deductions_price?: number;
  rebate_campaign: boolean;
}

const Statistic6: React.FC = () => {
  const [representatives, setRepresentatives] = useState<IRepresentative[]>();
  const [clients, setClients] = useState<IClient[]>();
  const [projects, setProjects] = useState<IProject[]>();
  const stateValue = useRef('ALL');
  const stateLabel = useRef('Todos');
  const previousStateValue = useRef('');
  const representativeValue = useRef('ALL');
  const representativeLabel = useRef('Todos');
  const previousRepresentativeValue = useRef('');
  const clientValue = useRef('ALL');
  const clientLabel = useRef('Todos');
  const previousClientValue = useRef('');
  const statusValue = useRef('Pendente_Finalizado');
  const statusLabel = useRef('Pendente/Finalizado');
  const [refreshStates, setRefreshStates] = useState(true);
  const [refreshRepresentatives, setRefreshRepresentatives] = useState(false);
  const [refreshClients, setRefreshClients] = useState(false);
  const [refreshStatus, setRefreshStatus] = useState(false);
  const [refreshProjects, setRefreshProjects] = useState(false);
  const [
    grossDiscountPriceRebateCampaignSponsorshipProjects,
    setGrossDiscountPriceRebateCampaignSponsorshipProjects,
  ] = useState(0);
  const [
    totalTaxDeductionsPriceRebateCampaignSponsorshipProjects,
    setTotalTaxDeductionsPriceRebateCampaignSponsorshipProjects,
  ] = useState(0);
  const [
    netDiscountPriceRebateCampaignSponsorshipProjects,
    setNetDiscountPriceRebateCampaignSponsorshipProjects,
  ] = useState(0);

  useEffect(() => {
    if (refreshStates) {
      if (stateValue.current !== previousStateValue.current) {
        previousStateValue.current = stateValue.current;
        representativeValue.current = 'ALL';
        representativeLabel.current = 'Todos';
        clientValue.current = 'ALL';
        clientLabel.current = 'Todos';
        statusValue.current = 'Pendente_Finalizado';
        statusLabel.current = 'Pendente/Finalizado';

        setRefreshStates(false);
        setRefreshRepresentatives(true);
      }
    }
  }, [refreshStates]);

  useEffect(() => {
    if (refreshRepresentatives) {
      if (representativeValue.current !== previousRepresentativeValue.current) {
        previousRepresentativeValue.current = representativeValue.current;
        clientValue.current = 'ALL';
        clientLabel.current = 'Todos';
        statusValue.current = 'Pendente_Finalizado';
        statusLabel.current = 'Pendente/Finalizado';
      }
      if (stateValue.current && stateValue.current !== '') {
        api
          .get(`/representatives/state?state=${stateValue.current}`)
          .then(response => {
            setRepresentatives([
              {
                code: 'ALL',
                name: '',
              },
              ...response.data,
            ]);
            setRefreshRepresentatives(false);
            setRefreshClients(true);
          });
      }
    }
  }, [refreshRepresentatives]);

  useEffect(() => {
    if (refreshClients) {
      if (clientValue.current !== previousClientValue.current) {
        previousClientValue.current = clientValue.current;
        statusValue.current = 'Pendente_Finalizado';
        statusLabel.current = 'Pendente/Finalizado';
      }
      if (
        stateValue.current &&
        stateValue.current !== '' &&
        representativeValue.current &&
        representativeValue.current !== ''
      ) {
        api
          .get(
            `/clients/staterepresentative?` +
              `state=${stateValue.current}&` +
              `erp_rep_code=${representativeValue.current}`,
          )
          .then(response => {
            setClients([
              {
                code: 'ALL',
                company_name: '',
              },
              ...response.data,
            ]);
            setRefreshClients(false);
            setRefreshStatus(true);
          });
      }
    }
  }, [refreshClients]);

  useEffect(() => {
    if (refreshStatus) {
      setRefreshStatus(false);
      setRefreshProjects(true);
    }
  }, [refreshStatus]);

  useEffect(() => {
    if (refreshProjects) {
      if (
        stateValue.current &&
        stateValue.current !== '' &&
        representativeValue.current &&
        representativeValue.current !== '' &&
        clientValue.current &&
        clientValue.current !== '' &&
        statusValue.current &&
        statusValue.current !== ''
      ) {
        api
          .get(
            `/projects/projectstatistics?` +
              `state=${stateValue.current}&` +
              `erp_rep_code=${representativeValue.current}&` +
              `erp_client_code=${clientValue.current}&` +
              `status=${statusValue.current}`,
          )
          .then(response => {
            setProjects(response.data);
            setRefreshProjects(false);
          });
      }
    }
  }, [refreshProjects]);

  useEffect(() => {
    let gross_discount_price_rebate_campaign_sponsorship_projects = 0;
    let total_tax_deductions_price_rebate_campaign_sponsorship_projects = 0;
    let net_discount_price_rebate_campaign_sponsorship_projects = 0;

    if (projects) {
      if (projects.length > 0) {
        projects.map(project => {
          if (project.type === 'PATROCINIO') {
            if (project.gross_discount_price && project.rebate_campaign) {
              gross_discount_price_rebate_campaign_sponsorship_projects +=
                project.gross_discount_price;
            }
            if (project.total_tax_deductions_price && project.rebate_campaign) {
              total_tax_deductions_price_rebate_campaign_sponsorship_projects +=
                project.total_tax_deductions_price;
            }
            if (project.net_discount_price && project.rebate_campaign) {
              net_discount_price_rebate_campaign_sponsorship_projects +=
                project.net_discount_price;
            }
          }

          return project;
        });
      }

      setGrossDiscountPriceRebateCampaignSponsorshipProjects(
        gross_discount_price_rebate_campaign_sponsorship_projects,
      );
      setTotalTaxDeductionsPriceRebateCampaignSponsorshipProjects(
        total_tax_deductions_price_rebate_campaign_sponsorship_projects,
      );
      setNetDiscountPriceRebateCampaignSponsorshipProjects(
        net_discount_price_rebate_campaign_sponsorship_projects,
      );
    }
  }, [projects]);

  const stateOptions: { value: string; label: string }[] = [
    { value: 'ALL', label: 'Todos' },
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapá' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceará' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espírito Santo' },
    { value: 'GO', label: 'Goiás' },
    { value: 'MA', label: 'Maranhão' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Pará' },
    { value: 'PB', label: 'Paraíba' },
    { value: 'PR', label: 'Paraná' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piauí' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondônia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'São Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' },
  ];

  const representativeOptions = representatives?.map(representative => ({
    value: representative.code,
    label:
      representative.code === 'ALL'
        ? 'Todos'
        : `(${representative.code}) - ${representative.name}`,
  }));

  const clientOptions = clients?.map(client => ({
    value: client.code,
    label:
      client.code === 'ALL'
        ? 'Todos'
        : `(${client.code}) - ${client.company_name}`,
  }));

  const statusOptions: { value: string; label: string }[] = [
    { value: 'Pendente_Finalizado', label: 'Pendente/Finalizado' },
    { value: 'Pendente', label: 'Pendente' },
    { value: 'Finalizado', label: 'Finalizado' },
    { value: 'Cancelado', label: 'Cancelado' },
    { value: 'Todos', label: 'Todos' },
  ];

  function handleStateChange(
    stateValueReceived: string,
    stateLabelReceived: string,
  ): void {
    stateValue.current = stateValueReceived;
    stateLabel.current = stateLabelReceived;
    setRefreshStates(true);
  }

  function handleRepresentativeChange(
    representativeValueReceived: string,
    representativeLabelReceived: string,
  ): void {
    representativeValue.current = representativeValueReceived;
    representativeLabel.current = representativeLabelReceived;
    setRefreshRepresentatives(true);
  }

  function handleClientChange(
    clientValueReceived: string,
    clientLabelReceived: string,
  ): void {
    clientValue.current = clientValueReceived;
    clientLabel.current = clientLabelReceived;
    setRefreshClients(true);
  }

  function handleStatusChange(
    statusValueReceived: string,
    statusLabelReceived: string,
  ): void {
    statusValue.current = statusValueReceived;
    statusLabel.current = statusLabelReceived;
    setRefreshStatus(true);
  }

  return (
    <Container>
      <FilterContent>
        <FilterWrapper>
          <Select
            label="Estado"
            options={stateOptions}
            value={{
              value: stateValue.current,
              label: stateLabel.current,
            }}
            darkBorder
            onSet={(e: any) => {
              handleStateChange(e.value, e.label);
            }}
          />
        </FilterWrapper>

        <FilterWrapper>
          <Select
            label="Representante"
            options={representativeOptions}
            value={{
              value: representativeValue.current,
              label: representativeLabel.current,
            }}
            darkBorder
            onSet={(e: any) => {
              handleRepresentativeChange(e.value, e.label);
            }}
          />
        </FilterWrapper>

        <FilterWrapper>
          <Select
            label="Cliente"
            options={clientOptions}
            value={{
              value: clientValue.current,
              label: clientLabel.current,
            }}
            darkBorder
            onSet={(e: any) => {
              handleClientChange(e.value, e.label);
            }}
          />
        </FilterWrapper>

        <FilterWrapper>
          <Select
            label="Status"
            options={statusOptions}
            value={{
              value: statusValue.current,
              label: statusLabel.current,
            }}
            darkBorder
            onSet={(e: any) => {
              handleStatusChange(e.value, e.label);
            }}
          />
        </FilterWrapper>
      </FilterContent>

      <ChartWrapper>
        <DistributedColumnsPrices2
          label="Pagamentos - Campanha de Rebate"
          grossDiscountPrice={
            grossDiscountPriceRebateCampaignSponsorshipProjects
          }
          totalTaxDeductionsPrice={
            totalTaxDeductionsPriceRebateCampaignSponsorshipProjects
          }
          netDiscountPrice={netDiscountPriceRebateCampaignSponsorshipProjects}
        />
      </ChartWrapper>
    </Container>
  );
};

export default Statistic6;
