import styled, { css } from 'styled-components';

import { IViewProps } from '.';

export const Container = styled.button<IViewProps>`
  ${({ theme, marginLeft }) => css`
    background-color: ${theme.colors.view};
    color: #fff;
    padding: 8px;
    border-radius: 50%;
    box-shadow: 1px 1px 1px ${theme.colors.lightGrey};

    &:hover {
      transform: scale(1.2, 1.2);
    }

    ${marginLeft &&
    css`
      margin-left: ${marginLeft};
    `}
  `}
`;
