import React from 'react';

import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';

import Routes from './routes';
import * as themes from './styles/themes';
import GlobalStyles from './styles/global';

import AppProvider from './hooks';

import useAppTheme from './hooks/theme';

const App: React.FC = () => {
  const { currentTheme } = useAppTheme();

  return (
    <ThemeProvider theme={themes[currentTheme]}>
      <AppProvider>
        <Router>
          <Routes />
        </Router>
      </AppProvider>

      <GlobalStyles />
    </ThemeProvider>
  );
};

export default App;
