import styled, { css } from 'styled-components';

interface IGradientProps {
  height: string;
}

export const Container = styled.div<IGradientProps>`
  ${({ theme, height }) => css`
    width: 1rem;
    height: ${height};
    margin-left: 20px;
    background: linear-gradient(
      90deg,
      ${theme.colors.darkGrayishOrange} 40%,
      ${theme.colors.primary} 100%
    );
  `}
`;
