import styled, { css, keyframes } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    animation: ${appearFromLeft} 1s;

    form {
      display: flex;
      flex-direction: column;

      margin: 80px 0;
      width: 340px;

      label {
        display: flex;
        margin: 0 6px;
      }

      > div {
        background: ${theme.colors.background};
      }

      > button {
        margin-top: 12px;
        font-weight: 500;
      }
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    place-content: center;

    width: 100%;
    max-width: 520px;

    > img {
      max-width: 68%;
    }

    h1 {
      text-align: center;
      font-size: 18px;
      margin: 10px 0 30px;
    }

    a {
      text-align: right;
      margin-top: 10px;
      display: block;
      font-size: 12px;
      font-weight: 500;
      text-decoration: none;
      color: ${theme.colors.secondary};
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, theme.colors.secondary)};
      }
    }

    > a {
      font-size: 20px;
      text-decoration: none;
      font-weight: 500;
      color: ${theme.colors.secondary};
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, theme.colors.secondary)};
      }
    }

    > p {
      font-size: 14px;
    }
  `}
`;
