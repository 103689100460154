import React, { useState, useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { FaAngleDown } from 'react-icons/fa';

import api from '~/services/api';

import InfoInput from '~/components/Inputs/Info';

import { MainContainer, Label, Container, Content, Wrapper } from './styles';

interface IRepresentativeProps {
  erp_rep_code: string;
}

interface IRepresentative {
  code: string;
  name: string;
}

const Representative: React.FC<IRepresentativeProps> = ({ erp_rep_code }) => {
  const [representative, setRepresentative] = useState<IRepresentative>();

  useEffect(() => {
    if (erp_rep_code !== '') {
      api.get(`/representatives/code?code=${erp_rep_code}`).then(response => {
        setRepresentative(response.data);
      });
    }
  }, [erp_rep_code]);

  return (
    <MainContainer>
      <Accordion>
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Label>Representante</Label>
        </AccordionSummary>

        <AccordionDetails>
          <Container>
            <Content>
              <Wrapper style={{ width: '30%', marginRight: '1rem' }}>
                <InfoInput
                  name="code"
                  labelFor="code"
                  labelText="Código"
                  type="text"
                  defaultValue={representative?.code ? representative.code : ''}
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '70%' }}>
                <InfoInput
                  name="name"
                  labelFor="name"
                  labelText="Nome"
                  type="text"
                  defaultValue={representative?.name ? representative.name : ''}
                  disabled
                />
              </Wrapper>
            </Content>
          </Container>
        </AccordionDetails>
      </Accordion>
    </MainContainer>
  );
};

export default Representative;
