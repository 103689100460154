import React, { useState, useEffect, useRef } from 'react';

import Select from '~/components/Select';
import SimplePieType from '~/components/StatisticsCharts/SimplePieType';

import { useAuth } from '~/hooks/auth';

import api from '~/services/api';

import {
  Container,
  FilterContent,
  FilterWrapper,
  ChartWrapper,
} from './styles';

interface IRepresentative {
  code: string;
  name: string;
}

interface IClient {
  code: string;
  company_name: string;
}

interface IProject {
  type: string;
  status: string;
  rebate_campaign?: boolean;
}

const Statistic5: React.FC = () => {
  const { user } = useAuth();
  const [representatives, setRepresentatives] = useState<IRepresentative[]>();
  const [clients, setClients] = useState<IClient[]>();
  const [projects, setProjects] = useState<IProject[]>();
  const stateValue = useRef('ALL');
  const stateLabel = useRef('Todos');
  const previousStateValue = useRef('');
  const representativeValue = useRef('ALL');
  const representativeLabel = useRef('Todos');
  const previousRepresentativeValue = useRef('');
  const clientValue = useRef('ALL');
  const clientLabel = useRef('Todos');
  const previousClientValue = useRef('');
  const [refreshStates, setRefreshStates] = useState(true);
  const [refreshRepresentatives, setRefreshRepresentatives] = useState(false);
  const [refreshClients, setRefreshClients] = useState(false);
  const [refreshProjects, setRefreshProjects] = useState(false);
  const [
    rebateCampaignSponsorshipPendingProjects,
    setRebateCampaignSponsorshipPendingProjects,
  ] = useState(0);
  const [
    rebateCampaignSponsorshipFinishedProjects,
    setRebateCampaignSponsorshipFinishedProjects,
  ] = useState(0);
  const [
    rebateCampaignSponsorshipCanceledProjects,
    setRebateCampaignSponsorshipCanceledProjects,
  ] = useState(0);

  useEffect(() => {
    if (refreshStates) {
      if (stateValue.current !== previousStateValue.current) {
        previousStateValue.current = stateValue.current;
        representativeValue.current = 'ALL';
        representativeLabel.current = 'Todos';
        clientValue.current = 'ALL';
        clientLabel.current = 'Todos';

        setRefreshStates(false);
        setRefreshRepresentatives(true);
      }
    }
  }, [refreshStates]);

  useEffect(() => {
    if (refreshRepresentatives) {
      if (representativeValue.current !== previousRepresentativeValue.current) {
        previousRepresentativeValue.current = representativeValue.current;
        clientValue.current = 'ALL';
        clientLabel.current = 'Todos';
      }
      if (stateValue.current && stateValue.current !== '') {
        api
          .get(`/representatives/state?state=${stateValue.current}`)
          .then(response => {
            setRepresentatives([
              {
                code: 'ALL',
                name: '',
              },
              ...response.data,
            ]);
            setRefreshRepresentatives(false);
            setRefreshClients(true);
          });
      }
    }
  }, [refreshRepresentatives]);

  useEffect(() => {
    if (refreshClients) {
      if (clientValue.current !== previousClientValue.current) {
        previousClientValue.current = clientValue.current;
      }
      if (
        stateValue.current &&
        stateValue.current !== '' &&
        representativeValue.current &&
        representativeValue.current !== ''
      ) {
        if (user.user_type.type === 'REP') {
          representativeValue.current = user.erp_rep_code;
        }
        api
          .get(
            `/clients/staterepresentative?` +
              `state=${stateValue.current}&` +
              `erp_rep_code=${representativeValue.current}`,
          )
          .then(response => {
            setClients([
              {
                code: 'ALL',
                company_name: '',
              },
              ...response.data,
            ]);
            setRefreshClients(false);
            setRefreshProjects(true);
          });
      }
    }
  }, [refreshClients, user]);

  useEffect(() => {
    if (refreshProjects) {
      if (
        stateValue.current &&
        stateValue.current !== '' &&
        representativeValue.current &&
        representativeValue.current !== '' &&
        clientValue.current &&
        clientValue.current !== ''
      ) {
        api
          .get(
            `/projects/projectstatistics?` +
              `state=${stateValue.current}&` +
              `erp_rep_code=${representativeValue.current}&` +
              `erp_client_code=${clientValue.current}&` +
              `status=Todos`,
          )
          .then(response => {
            setProjects(response.data);
            setRefreshProjects(false);
          });
      }
    }
  }, [refreshProjects]);

  useEffect(() => {
    let rebate_campaign_sponsorship_pending_project_length = 0;
    let rebate_campaign_sponsorship_finished_project_length = 0;
    let rebate_campaign_sponsorship_canceled_project_length = 0;

    if (projects) {
      if (projects.length > 0) {
        rebate_campaign_sponsorship_pending_project_length = projects.filter(
          project =>
            project.status.toUpperCase() === 'PENDENTE' &&
            project.type === 'PATROCINIO' &&
            project.rebate_campaign,
        ).length;

        rebate_campaign_sponsorship_finished_project_length = projects.filter(
          project =>
            project.status.toUpperCase() === 'FINALIZADO' &&
            project.type === 'PATROCINIO' &&
            project.rebate_campaign,
        ).length;

        rebate_campaign_sponsorship_canceled_project_length = projects.filter(
          project =>
            project.status.toUpperCase() === 'CANCELADO' &&
            project.type === 'PATROCINIO' &&
            project.rebate_campaign,
        ).length;
      }

      setRebateCampaignSponsorshipPendingProjects(
        rebate_campaign_sponsorship_pending_project_length,
      );
      setRebateCampaignSponsorshipFinishedProjects(
        rebate_campaign_sponsorship_finished_project_length,
      );
      setRebateCampaignSponsorshipCanceledProjects(
        rebate_campaign_sponsorship_canceled_project_length,
      );
    }
  }, [projects]);

  const stateOptions: { value: string; label: string }[] = [
    { value: 'ALL', label: 'Todos' },
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapá' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceará' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espírito Santo' },
    { value: 'GO', label: 'Goiás' },
    { value: 'MA', label: 'Maranhão' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Pará' },
    { value: 'PB', label: 'Paraíba' },
    { value: 'PR', label: 'Paraná' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piauí' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondônia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'São Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' },
  ];

  const representativeOptions = representatives?.map(representative => ({
    value: representative.code,
    label:
      representative.code === 'ALL'
        ? 'Todos'
        : `(${representative.code}) - ${representative.name}`,
  }));

  const clientOptions = clients?.map(client => ({
    value: client.code,
    label:
      client.code === 'ALL'
        ? 'Todos'
        : `(${client.code}) - ${client.company_name}`,
  }));

  function handleStateChange(
    stateValueReceived: string,
    stateLabelReceived: string,
  ): void {
    stateValue.current = stateValueReceived;
    stateLabel.current = stateLabelReceived;
    setRefreshStates(true);
  }

  function handleRepresentativeChange(
    representativeValueReceived: string,
    representativeLabelReceived: string,
  ): void {
    representativeValue.current = representativeValueReceived;
    representativeLabel.current = representativeLabelReceived;
    setRefreshRepresentatives(true);
  }

  function handleClientChange(
    clientValueReceived: string,
    clientLabelReceived: string,
  ): void {
    clientValue.current = clientValueReceived;
    clientLabel.current = clientLabelReceived;
    setRefreshClients(true);
  }

  function handleFilter(): React.ReactNode {
    if (user.user_type.type !== 'REP') {
      return (
        <FilterContent>
          <FilterWrapper>
            <Select
              label="Estado"
              options={stateOptions}
              value={{
                value: stateValue.current,
                label: stateLabel.current,
              }}
              darkBorder
              onSet={(e: any) => {
                handleStateChange(e.value, e.label);
              }}
            />
          </FilterWrapper>

          <FilterWrapper>
            <Select
              label="Representante"
              options={representativeOptions}
              value={{
                value: representativeValue.current,
                label: representativeLabel.current,
              }}
              darkBorder
              onSet={(e: any) => {
                handleRepresentativeChange(e.value, e.label);
              }}
            />
          </FilterWrapper>

          <FilterWrapper>
            <Select
              label="Cliente"
              options={clientOptions}
              value={{
                value: clientValue.current,
                label: clientLabel.current,
              }}
              darkBorder
              onSet={(e: any) => {
                handleClientChange(e.value, e.label);
              }}
            />
          </FilterWrapper>
        </FilterContent>
      );
    }

    return <></>;
  }

  return (
    <Container>
      {handleFilter()}
      <ChartWrapper>
        <SimplePieType
          label="Pagamentos - Campanha de Rebate"
          pendingAmount={rebateCampaignSponsorshipPendingProjects}
          finishedAmount={rebateCampaignSponsorshipFinishedProjects}
          canceledAmount={rebateCampaignSponsorshipCanceledProjects}
        />
      </ChartWrapper>
    </Container>
  );
};

export default Statistic5;
