import React from 'react';

import Upload from './Upload';
import List from './List';

import { Container, Content } from './styles';

import { ApprovalFileProvider } from '~/context/approvalfiles';

interface IUploadApprovalFilesProps {
  type: string;
  project_approval_temp_id: string;
  project_type: string;
  sequence: number;
  user_id: string;
}

const UploadApprovalFiles: React.FC<IUploadApprovalFilesProps> = ({
  type,
  project_approval_temp_id,
  project_type,
  sequence,
  user_id,
}) => {
  return (
    <ApprovalFileProvider>
      <Container>
        <Content>
          <Upload
            type={type}
            project_approval_temp_id={project_approval_temp_id}
            project_type={project_type}
            sequence={sequence}
            user_id={user_id}
          />
          <List />
        </Content>
      </Container>
    </ApprovalFileProvider>
  );
};

export default UploadApprovalFiles;
