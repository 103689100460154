import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FaTools, FaTrashAlt } from 'react-icons/fa';

import BreadCrumb from '~/components/BreadCrumb';
import Select from '~/components/Select';
import SelectButton from '~/components/Buttons/Select';
import NoticeDialog from '~/components/Dialogs/Notice';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Content,
  OneFifthWrapper,
  TwoFifthsWrapper,
  ButtonContainer,
} from './styles';

interface IProject {
  id: string;
  code: string;
}

const ChooseProject: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const [projects, setProjects] = useState<IProject[]>();
  const [project, setProject] = useState<IProject>();
  const [projectId, setProjectId] = useState<string>();
  const [loadingProjects, setLoadingProjects] = useState<boolean>(true);
  const [isOpenNotice, setIsOpenNotice] = useState(false);

  useEffect(() => {
    if (user.user_type.type !== 'ADM') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Tipo de usuário não permitido',
      });

      history.push('/management/main');
    } else {
      api.get(`/projects/allorderbycode`).then(response => {
        setProjects(response.data);
        setLoadingProjects(false);
      });
    }
  }, [user, addToast, history]);

  useEffect(() => {
    if (projectId) {
      api.get(`/projects/id?id=${projectId}`).then(response => {
        setProject(response.data);
      });
    }
  }, [projectId]);

  const projectOptions = projects?.map(proj => ({
    value: proj.id,
    label: `${proj.code}`,
  }));

  function onConfirmProcedure(): void {
    if (project) {
      history.push({
        pathname: '/management/projectdeletion/deletion',
        state: {
          id: project.id,
        },
      });
    }
  }

  return (
    <Container>
      <BreadCrumb
        main="Administração"
        path="/management/main"
        firstChild="Exclusão de Solicitação"
        mainIcon={FaTools}
        firstChildIcon={FaTrashAlt}
        mainColored
      />

      <Content maxWidth="700px">
        <TwoFifthsWrapper>
          <Select
            label="Projetos"
            options={projectOptions}
            onSet={(e: any) => {
              setProjectId(e.value);
            }}
            loading={loadingProjects}
          />
        </TwoFifthsWrapper>

        <OneFifthWrapper>
          <ButtonContainer>
            <SelectButton
              onClick={() => {
                if (!project) {
                  setIsOpenNotice(true);
                } else {
                  onConfirmProcedure();
                }
              }}
            />
          </ButtonContainer>
        </OneFifthWrapper>
      </Content>

      <NoticeDialog
        isOpen={isOpenNotice}
        type="error"
        title="Projeto não selecionado!"
        subTitle="Necessário selecionar projeto válido!"
        setIsOpen={setIsOpenNotice}
      />
    </Container>
  );
};

export default ChooseProject;
