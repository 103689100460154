import React from 'react';
import ReactApexChart from 'react-apexcharts';

import { useTheme } from 'styled-components';

import { Container } from './styles';

interface ISimpleDonutGeneralProps {
  displayProjectsAmount: number;
  materialProjectsAmount: number;
  sponsorshipProjectsAmount: number;
  adversityProjectsAmount: number;
  folderProjectsAmount: number;
  sampleProjectsAmount: number;
  eventProjectsAmount: number;
}

const SimpleDonutGeneral: React.FC<ISimpleDonutGeneralProps> = ({
  displayProjectsAmount,
  materialProjectsAmount,
  sponsorshipProjectsAmount,
  adversityProjectsAmount,
  folderProjectsAmount,
  sampleProjectsAmount,
  eventProjectsAmount,
}: ISimpleDonutGeneralProps) => {
  const { colors } = useTheme();

  const optionColors = [
    colors.chartDisplay,
    colors.chartMaterial,
    colors.chartSponsorship,
    colors.chartAdversity,
    colors.chartFolder,
    colors.chartSample,
    colors.chartEvent,
  ];

  const series = [
    displayProjectsAmount,
    materialProjectsAmount,
    sponsorshipProjectsAmount,
    adversityProjectsAmount,
    folderProjectsAmount,
    sampleProjectsAmount,
    eventProjectsAmount,
  ];

  const options = {
    labels: [
      'Displays',
      'Materiais',
      'Pagamentos',
      'Problemas',
      'Pastas',
      'Amostras',
      'Eventos',
    ],
    colors: optionColors,
    dataLabels: {
      enabled: true,
      formatter: (value: number) => {
        return `${Intl.NumberFormat('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(value)}%`;
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  return (
    <Container>
      <ReactApexChart options={options} series={series} type="donut" />
    </Container>
  );
};

export default SimpleDonutGeneral;
