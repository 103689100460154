import React from 'react';

import InfoInput from '~/components/Inputs/Info';

import { Container, Content } from './styles';

interface IPendingAmountProps {
  pending_amount?: number;
}

const PendingAmount: React.FC<IPendingAmountProps> = ({ pending_amount }) => {
  return (
    <Container>
      <Content>
        <InfoInput
          name="pending_amount"
          labelFor="pending_amount"
          labelText="Qtd. Pendente"
          type="text"
          defaultValue={
            pending_amount
              ? new Intl.NumberFormat('pt-BR').format(pending_amount)
              : new Intl.NumberFormat('pt-BR').format(0)
          }
          disabled
        />
      </Content>
    </Container>
  );
};

export default PendingAmount;
