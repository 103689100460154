import styled, { css } from 'styled-components';

export const Container = styled.section`
  width: 100%;
`;

export const TabTitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.darkGrey};
    font-size: ${theme.fontSizes.large};
    font-weight: bold;
    justify-content: center;
    margin-left: 0.5rem;
    width: 15rem;
  `}
`;

export const TabFirst = styled.div`
  width: 100%;
`;

export const TabSecond = styled.div`
  width: 100%;
`;

export const TabThird = styled.div`
  width: 100%;
`;

export const StatisticsSection = styled.article`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 1.6rem 2.6rem;
    max-width: 38.4rem;
    width: 100%;
    height: 100%;
    max-height: 18.4rem;
    min-height: 16rem;
    margin-bottom: 2rem;
    color: ${theme.colors.grey};
    align-items: flex-start;
    border-radius: ${theme.radius.default};
  `}
`;

export const Table = styled.table`
  width: 100%;

  tbody {
    tr {
      transition: all 0.25s ease;
      &:hover {
        transform: translateY(-1px);
      }
    }
  }
`;
