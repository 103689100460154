import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FcCheckmark, FcCancel } from 'react-icons/fc';
import { FaUser } from 'react-icons/fa';
import { PropagateLoader } from 'react-spinners';

import BreadCrumb from '~/components/BreadCrumb';
import DefaultInput from '~/components/Inputs/Default';
import EditButton from '~/components/Buttons/Edit';
import DeleteButton from '~/components/Buttons/Delete';
import Pagination from '~/components/Pagination';
import NewButton from '~/components/Buttons/New';
import ConfirmDeleteDialog from '~/components/Dialogs/ConfirmDelete';

import { useToast } from '~/hooks/toast';
import { useAuth } from '~/hooks/auth';

import api from '~/services/api';

import {
  Container,
  FilterContent,
  Table,
  Thead,
  Tbody,
  Th,
  TbodyTr,
  Td,
  ButtonsContent,
} from './styles';

interface IUser {
  id: string;
  name: string;
  email: string;
  active: boolean;
  user_type: {
    name: string;
  };
}

const AMOUNT_PER_PAGE = 5;

const List: React.FC = () => {
  const { addToast } = useToast();
  const { user } = useAuth();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState('');
  const [lastRefresh, setLastRefresh] = useState(new Date());
  const [users, setUsers] = useState<IUser[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [userIdDelete, setUserIdDelete] = useState('');
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [titleDelete, setTitleDelete] = useState('');
  const [subTitleDelete, setSubTitleDelete] = useState('');

  useEffect(() => {
    const loadUsers = async () => {
      await api
        .get(`/users?page=${page}&filter=${filter}&amount=${AMOUNT_PER_PAGE}`)
        .then(response => {
          setUsers(response.data[0]);
          setCount(response.data[1]);
          setLoading(false);
        });
    };

    loadUsers();
  }, [page, filter, lastRefresh]);

  const onDelete = useCallback(
    async (id: string) => {
      try {
        await api.delete(`/users/${id}`).then(() => {
          setPage(0);
          setLastRefresh(new Date());

          addToast({
            type: 'success',
            title: 'Usuário excluído!',
            description: 'Usuário excluído com sucesso.',
          });
        });
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
      setUserIdDelete('');
      setTitleDelete('');
      setSubTitleDelete('');
    },
    [addToast],
  );

  function handleDeleteButton(userReceived: IUser): React.ReactNode | null {
    if (user.id !== userReceived.id) {
      return (
        <DeleteButton
          marginLeft="0.5rem"
          disabled={user.id === userReceived.id}
          onClick={() => {
            setUserIdDelete(userReceived.id);
            setIsOpenDelete(true);
            setTitleDelete(
              `Deseja realmente excluir o usuário ${userReceived.name}?`,
            );
            setSubTitleDelete('Esta operação não poderá ser desfeita!');
          }}
        />
      );
    }

    return null;
  }

  function handleTable(): React.ReactNode {
    if (users) {
      if (users.length > 0) {
        return (
          <Table>
            <Thead>
              <tr>
                <Th width="15%" textAlign="left">
                  Nome
                </Th>
                <Th width="20%" textAlign="left">
                  E-mail
                </Th>
                <Th width="10%" textAlign="left">
                  Tipo
                </Th>
                <Th width="10%" textAlign="center">
                  Situação
                </Th>
                <Th width="10%" textAlign="center" />
              </tr>
            </Thead>
            <Tbody>
              {loading && (
                <tr>
                  <Td colSpan={4} padding="1rem" textAlign="center">
                    <PropagateLoader color="#276c8d" />
                  </Td>
                </tr>
              )}
              {users?.map(userRow => (
                <TbodyTr key={userRow.id}>
                  <Td width="15%" textAlign="left" heading="NOME">
                    {userRow.name.substring(0, 50)}
                  </Td>
                  <Td width="20%" textAlign="left" heading="E-MAIL">
                    {userRow.email.substring(0, 100)}
                  </Td>
                  <Td width="10%" textAlign="left" heading="TIPO">
                    {userRow.user_type.name.substring(0, 30)}
                  </Td>
                  <Td width="10%" textAlign="center" heading="SITUAÇÃO">
                    {userRow.active ? (
                      <FcCheckmark size={22} />
                    ) : (
                      <FcCancel size={22} />
                    )}
                  </Td>
                  <Td
                    width="10%"
                    textAlign="center"
                    display="flex"
                    heading={' '}
                  >
                    <Link
                      to={{
                        pathname: `/users/edit/${userRow.id}`,
                        state: userRow,
                      }}
                    >
                      <EditButton />
                    </Link>

                    {handleDeleteButton(userRow)}
                  </Td>
                </TbodyTr>
              ))}
            </Tbody>
          </Table>
        );
      }
    }

    return (
      <Table>
        <Thead>
          <tr>
            <Th width="100%" textAlign="center" />
          </tr>
        </Thead>
        <Tbody>
          <TbodyTr>
            <Td width="100%" textAlign="center">
              Nenhuma informação disponível
            </Td>
          </TbodyTr>
        </Tbody>
      </Table>
    );
  }

  return (
    <Container style={{ minHeight: 600 }}>
      <BreadCrumb main="Usuários" path="/users" mainIcon={FaUser} />

      <FilterContent>
        <DefaultInput
          name="search"
          labelText="Pesquisar"
          type="text"
          onChange={event => {
            setFilter(event.target.value);
            setPage(0);
          }}
        />
      </FilterContent>

      {handleTable()}

      <ButtonsContent>
        <Pagination
          page={page}
          count={count}
          amountPerPage={AMOUNT_PER_PAGE}
          setPage={setPage}
        />

        <Link to="/users/new">
          <NewButton />
        </Link>
      </ButtonsContent>

      <ConfirmDeleteDialog
        isOpen={isOpenDelete}
        title={titleDelete}
        subTitle={subTitleDelete}
        setIsOpen={setIsOpenDelete}
        onClose={() => {
          onDelete(userIdDelete);
        }}
      />
    </Container>
  );
};

export default List;
