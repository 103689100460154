import styled, { css } from 'styled-components';
import { shade } from 'polished';

import { Container as ButtonContainer } from '~/components/Button/styles';

interface ITaskButtonProps {
  isExtraVal: boolean;
}

interface ITaskButtonInfoProps {
  isExtraVal: boolean;
}

export const Container = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const TaskButton = styled(ButtonContainer)<ITaskButtonProps>`
  ${({ theme, isExtraVal }) => css`
    padding: 2rem 1.6rem;
    justify-content: space-between;
    width: 34rem;
    min-height: 15rem;
    border-radius: ${theme.radius.default};
    background: ${theme.colors.notification};
    margin-bottom: 1rem;

    :hover {
      background: ${shade(0.2, theme.colors.notification)};

      ${isExtraVal &&
      css`
        background: ${shade(0.2, theme.colors.extraValNotification)};
      `}

      color: #fff;
      transition: 0.2s;
    }

    @media (max-width: 940px) {
      max-width: 100%;
      margin-bottom: 2rem;
    }

    ${isExtraVal &&
    css`
      background: ${theme.colors.extraValNotification};
    `}
  `}
`;

export const TaskButtonTitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.label};
    font-size: ${theme.fontSizes.large};
    margin-bottom: 0.5rem;
  `}
`;

export const TaskButtonRows = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-content: center;
    font-size: ${theme.fontSizes.small};
  `}
`;

export const TaskButtonRow = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.small};
  `}
`;

export const TaskButtonInfo = styled.div<ITaskButtonInfoProps>`
  ${({ theme, isExtraVal }) => css`
    color: ${theme.colors.slightlyDesaturatedOrange};

    ${isExtraVal &&
    css`
      color: ${theme.colors.darkModerateOrange};
    `}
  `}
`;
