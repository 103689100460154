import styled, { css } from 'styled-components';

interface IThProps {
  width: string;
  textAlign: string;
}

interface ITdProps {
  width?: string;
  textAlign?: string;
  padding?: string;
  display?: string;
  heading?: string;
}

export const Container = styled.div`
  max-width: 680px;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background: ${theme.colors.backgroundContainer};
    padding: 2rem 2rem 3rem 2rem;

    @media (max-width: 940px) {
      > div:first-child {
        flex-direction: column;
      }
    }

    @media (max-width: 400px) {
      > div + div {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
      }
    }
  `}
`;

export const Table = styled.table`
  ${({ theme }) => css`
    margin: 3rem 0 0 0;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0 4px;
    background-color: ${theme.colors.lightGrey};
    box-shadow: 1px 1px 6px #ececec;
    border-radius: ${theme.radius.small};

    tbody {
      background-color: ${theme.colors.backgroundContainer};
      tr {
        transition: all 0.25s ease;
        background-color: #fff;

        &:hover {
          transform: translateY(-1px);
          background-color: ${theme.colors.hoverTable};
          border-radius: ${theme.radius.small};
        }
      }
    }

    @media (max-width: 1040px) {
      thead {
        display: none;
      }

      tr {
        display: block;
        margin-bottom: 2rem;
        border-radius: ${theme.radius.small};
      }
    }
  `}
`;

export const Th = styled.th<IThProps>`
  ${({ theme, width, textAlign }) => css`
    padding: 1.8rem 0;
    font-size: ${theme.fontSizes.default};
    text-transform: uppercase;
    color: ${theme.colors.grey};

    &:first-child {
      padding-left: 2rem;
    }

    ${width &&
    css`
      width: ${width};
    `}

    ${textAlign &&
    css`
      text-align: ${textAlign};
    `}
  `}
`;

export const Td = styled.th<ITdProps>`
  ${({ theme, width, textAlign, padding, display, heading }) => css`
    border: 0;
    font-size: ${theme.fontSizes.small};
    padding: 1rem 0;
    font-weight: ${theme.weight.default};
    color: ${theme.colors.grey};

    &:first-child {
      padding-left: 2rem;
    }

    &:last-child {
      padding-right: 2rem;
    }

    ${width &&
    css`
      width: ${width};
    `}

    ${textAlign &&
    css`
      text-align: ${textAlign};
    `}

    ${padding &&
    css`
      padding: ${padding};
    `}

    ${display &&
    css`
      display: ${display};
    `}

    @media (max-width: 1040px) {
      display: block;
      position: relative;
      width: 100%;
      padding-left: 15.2rem;
      text-align: left;

      &:first-child {
        padding-left: 15.2rem;
      }

      ${display &&
      css`
        display: ${display};
      `}

      &::before {
        ${heading &&
        css`
          content: '${heading}';
        `}

        position: absolute;
        top: 0;
        left: 0;
        width: 13rem;
        height: 100%;
        display: flex;
        align-items: center;
        background-color: ${theme.colors.lightGrey};
        padding: 0 0.8rem;
      }
    }
  `}
`;

export const ButtonsContent = styled.div`
  display: flex;
  margin-top: 2rem;
`;
