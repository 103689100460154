import React from 'react';

import InfoInput from '~/components/Inputs/Info';

import { Container, Content } from './styles';

interface IGrossDiscountPriceInitialStageProps {
  gross_discount_price?: number;
  total_sponsorship_sheets_price?: number;
}

const GrossDiscountPriceInitialStage: React.FC<IGrossDiscountPriceInitialStageProps> =
  ({ gross_discount_price, total_sponsorship_sheets_price }) => {
    function getGrossDiscountPriceInitialStage(): number {
      if (gross_discount_price && total_sponsorship_sheets_price) {
        return gross_discount_price - total_sponsorship_sheets_price;
      }

      if (gross_discount_price) {
        return gross_discount_price;
      }

      return 0;
    }

    return (
      <Container>
        <Content>
          <InfoInput
            name="gross_discount_price_initial_stage"
            labelFor="gross_discount_price_initial_stage"
            labelText="Valor Total Desconto"
            type="text"
            defaultValue={new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(getGrossDiscountPriceInitialStage())}
            disabled
          />
        </Content>
      </Container>
    );
  };

export default GrossDiscountPriceInitialStage;
