import React, { createContext, useState, useCallback, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import filesize from 'filesize';

import api from '~/services/api';

export interface IPost {
  file_temp_id: string;
  name: string;
  size: number;
  key: string;
  url: string;
}

export interface IStoreRelationshipDisplayFile {
  id: string;
  name: string;
  readableSize: string;
  type: string;
  uploaded?: boolean;
  preview: string;
  file: File | null;
  progress?: number;
  error?: boolean;
  url: string;
}

interface IStoreRelationshipDisplayFileContextData {
  uploadedStoreRelationshipDisplayFiles: IStoreRelationshipDisplayFile[];
  deleteStoreRelationshipDisplayFile(id: string): void;
  handleUpload(
    file: any,
    type: string,
    storeRelationshipDisplayTempId: string,
    userId: string,
  ): void;
}

const StoreRelationshipDisplayFileContext =
  createContext<IStoreRelationshipDisplayFileContextData>(
    {} as IStoreRelationshipDisplayFileContextData,
  );

const StoreRelationshipDisplayFileProvider: React.FC = ({ children }) => {
  const [
    uploadedStoreRelationshipDisplayFiles,
    setUploadedStoreRelationshipDisplayFiles,
  ] = useState<IStoreRelationshipDisplayFile[]>([]);

  const updateStoreRelationshipDisplayFile = useCallback((id, data) => {
    setUploadedStoreRelationshipDisplayFiles(state =>
      state.map(file => (file.id === id ? { ...file, ...data } : file)),
    );
  }, []);

  const processUpload = useCallback(
    (
      uploadedStoreRelationshipDisplayFile: IStoreRelationshipDisplayFile,
      type: string,
      storeRelationshipDisplayTempId: string,
      userId: string,
    ) => {
      const data = new FormData();
      if (uploadedStoreRelationshipDisplayFile.file) {
        data.append('type', type);
        data.append(
          'store_relationship_display_temp_id',
          storeRelationshipDisplayTempId,
        );
        data.append('file_temp_id', uploadedStoreRelationshipDisplayFile.id);
        data.append('user_id', userId);
        data.append(
          'file',
          uploadedStoreRelationshipDisplayFile.file,
          uploadedStoreRelationshipDisplayFile.name,
        );
      }

      api
        .post('/storerelationshipdisplayfilesreceived', data, {
          onUploadProgress: progressEvent => {
            const progress: number = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );

            updateStoreRelationshipDisplayFile(
              uploadedStoreRelationshipDisplayFile.id,
              { progress },
            );
          },
        })
        .then(response => {
          updateStoreRelationshipDisplayFile(
            uploadedStoreRelationshipDisplayFile.id,
            {
              uploaded: true,
              id: response.data.id,
              url: response.data.url,
            },
          );
        })
        .catch(err => {
          console.log(err);
          updateStoreRelationshipDisplayFile(
            uploadedStoreRelationshipDisplayFile.id,
            {
              error: true,
            },
          );
        });
    },
    [updateStoreRelationshipDisplayFile],
  );

  const handleUpload = useCallback(
    (
      files: File[],
      type: string,
      storeRelationshipDisplayTempId: string,
      userId: string,
    ) => {
      const newUploadedStoreRelationshipDisplayFiles: IStoreRelationshipDisplayFile[] =
        files.map((file: File) => ({
          file,
          id: uuidv4(),
          name: file.name,
          readableSize: filesize(file.size),
          type: file.type,
          preview: URL.createObjectURL(file),
          progress: 0,
          uploaded: false,
          error: false,
          url: '',
        }));

      setUploadedStoreRelationshipDisplayFiles(state =>
        state.concat(newUploadedStoreRelationshipDisplayFiles),
      );
      newUploadedStoreRelationshipDisplayFiles.forEach(
        upStoreRelationshipDisplayFile =>
          processUpload(
            upStoreRelationshipDisplayFile,
            type,
            storeRelationshipDisplayTempId,
            userId,
          ),
      );
    },
    [processUpload],
  );

  const deleteStoreRelationshipDisplayFile = useCallback((id: string) => {
    api.delete(`storerelationshipdisplayfilesreceived/${id}`);
    setUploadedStoreRelationshipDisplayFiles(state =>
      state.filter(file => file.id !== id),
    );
  }, []);

  return (
    <StoreRelationshipDisplayFileContext.Provider
      value={{
        uploadedStoreRelationshipDisplayFiles,
        deleteStoreRelationshipDisplayFile,
        handleUpload,
      }}
    >
      {children}
    </StoreRelationshipDisplayFileContext.Provider>
  );
};

function useStoreRelationshipDisplayFiles(): IStoreRelationshipDisplayFileContextData {
  const context = useContext(StoreRelationshipDisplayFileContext);

  if (!context) {
    throw new Error(
      'useStoreRelationshipDisplayFiles deve ser usado ' +
        'dentro de um StoreRelationshipDisplayFileProvider',
    );
  }

  return context;
}

export {
  StoreRelationshipDisplayFileProvider,
  useStoreRelationshipDisplayFiles,
};
