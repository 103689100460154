import React from 'react';

import InfoInput from '~/components/Inputs/Info';

import { Container, Content } from './styles';

interface IPriceProps {
  price?: number;
}

const Price: React.FC<IPriceProps> = ({ price }) => {
  return (
    <Container>
      <Content>
        <InfoInput
          name="price"
          labelFor="price"
          labelText="Valor"
          type="text"
          defaultValue={
            price
              ? new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(price)
              : new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(0)
          }
          disabled
        />
      </Content>
    </Container>
  );
};

export default Price;
