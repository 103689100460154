import React from 'react';

import InfoInput from '~/components/Inputs/Info';

import { Container, Content } from './styles';

interface IShippingPriceProps {
  shipping_price?: number;
}

const ShippingPrice: React.FC<IShippingPriceProps> = ({ shipping_price }) => {
  return (
    <Container>
      <Content>
        <InfoInput
          name="shipping_price"
          labelFor="shipping_price"
          labelText="Valor de Frete"
          type="text"
          defaultValue={
            shipping_price
              ? new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(shipping_price)
              : new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(0)
          }
          disabled
        />
      </Content>
    </Container>
  );
};

export default ShippingPrice;
