/* eslint-disable no-nested-ternary */

import React, { useState, useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { FaAngleDown } from 'react-icons/fa';

import api from '~/services/api';

import InfoInput from '~/components/Inputs/Info';

import { MainContainer, Label, Container, Content, Wrapper } from './styles';

interface IInvoiceClientProps {
  erp_invoice_client_code: string;
  default_expanded?: boolean;
}

interface IClient {
  code: string;
  name: string;
  company_name: string;
  document: string;
  address: string;
  city: string;
  state: string;
  sales_channel_code: string;
}

interface IStoreRelationship {
  company_name: string;
  trade_name: string;
  address: string;
  city: string;
  state: string;
}

interface IClientGroup {
  description: string;
}

interface ISalesChannel {
  code: string;
  description: string;
}

const InvoiceClient: React.FC<IInvoiceClientProps> = ({
  erp_invoice_client_code,
  default_expanded,
}) => {
  const [client, setClient] = useState<IClient>();
  const [storeRelationship, setStoreRelationship] =
    useState<IStoreRelationship>();
  const [clientGroup, setClientGroup] = useState<IClientGroup>();
  const [salesChannel, setSalesChannel] = useState<ISalesChannel>();

  useEffect(() => {
    api.get(`/clients/code?code=${erp_invoice_client_code}`).then(response => {
      setClient(response.data);
    });

    api
      .get(
        `/storerelationships/client?erp_client_code=${erp_invoice_client_code}`,
      )
      .then(response => {
        setStoreRelationship(response.data);
      });

    api
      .get(
        `/clientgroups/clientcode?erp_client_code=${erp_invoice_client_code}`,
      )
      .then(response => {
        setClientGroup(response.data);
      });
  }, [erp_invoice_client_code]);

  useEffect(() => {
    if (client) {
      if (client.sales_channel_code) {
        api
          .get(`/saleschannels/code?code=${client.sales_channel_code}`)
          .then(response => {
            setSalesChannel(response.data);
          });
      }
    }
  }, [client]);

  return (
    <MainContainer>
      <Accordion defaultExpanded={default_expanded}>
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Label>Cliente Faturamento</Label>
        </AccordionSummary>

        <AccordionDetails>
          <Container>
            <Content>
              <Wrapper style={{ width: '30%', marginRight: '1rem' }}>
                <InfoInput
                  name="code"
                  labelFor="code"
                  labelText="Código"
                  type="text"
                  defaultValue={client?.code}
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '70%' }}>
                <InfoInput
                  name="company_name"
                  labelFor="company_name"
                  labelText="Razão Social"
                  type="text"
                  defaultValue={
                    storeRelationship?.company_name &&
                    storeRelationship?.company_name !== ''
                      ? storeRelationship.company_name
                      : client?.company_name
                  }
                  disabled
                />
              </Wrapper>
            </Content>

            <Content>
              <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
                <InfoInput
                  name="trade_name"
                  labelFor="trade_name"
                  labelText="Nome Fantasia"
                  type="text"
                  defaultValue={
                    storeRelationship?.trade_name
                      ? storeRelationship.trade_name.toUpperCase()
                      : ''
                  }
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '50%' }}>
                <InfoInput
                  name="name"
                  labelFor="name"
                  labelText="Nome Abreviado"
                  type="text"
                  defaultValue={client?.name}
                  disabled
                />
              </Wrapper>
            </Content>

            <Content>
              <Wrapper style={{ width: '30%', marginRight: '1rem' }}>
                <InfoInput
                  name="document"
                  labelFor="document"
                  labelText="CNPJ"
                  type="text"
                  defaultValue={client?.document}
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '70%' }}>
                <InfoInput
                  name="address"
                  labelFor="address"
                  labelText="Endereço"
                  type="text"
                  defaultValue={
                    storeRelationship?.address &&
                    storeRelationship?.address !== ''
                      ? storeRelationship.address
                      : client?.address
                  }
                  disabled
                />
              </Wrapper>
            </Content>

            <Content>
              <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
                <InfoInput
                  name="city"
                  labelFor="city"
                  labelText="Cidade"
                  type="text"
                  defaultValue={
                    storeRelationship?.city && storeRelationship?.city !== ''
                      ? storeRelationship.city
                      : client?.city
                  }
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '50%' }}>
                <InfoInput
                  name="state"
                  labelFor="state"
                  labelText="Estado"
                  type="text"
                  defaultValue={
                    storeRelationship?.state && storeRelationship?.state !== ''
                      ? storeRelationship.state
                      : client?.state
                  }
                  disabled
                />
              </Wrapper>
            </Content>

            <Content>
              <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
                <InfoInput
                  name="sales_channel"
                  labelFor="sales_channel"
                  labelText="Canal de Venda"
                  type="text"
                  defaultValue={salesChannel?.description}
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '50%' }}>
                <InfoInput
                  name="group"
                  labelFor="group"
                  labelText="Grupo"
                  type="text"
                  defaultValue={clientGroup?.description}
                  disabled
                />
              </Wrapper>
            </Content>
          </Container>
        </AccordionDetails>
      </Accordion>
    </MainContainer>
  );
};

export default InvoiceClient;
