import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { FaAngleDown } from 'react-icons/fa';

import InfoInput from '~/components/Inputs/Info';

import { MainContainer, Label, Container, Content, Wrapper } from './styles';

interface IClientContactProps {
  client_contact_name: string;
  client_contact_phone: string;
  client_contact_email: string;
}

const ClientContact: React.FC<IClientContactProps> = ({
  client_contact_name,
  client_contact_phone,
  client_contact_email,
}) => {
  return (
    <MainContainer>
      <Accordion>
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Label>Contato da Loja</Label>
        </AccordionSummary>

        <AccordionDetails>
          <Container>
            <Content>
              <Wrapper style={{ width: '60%', marginRight: '1rem' }}>
                <InfoInput
                  name="client_contact_name"
                  labelFor="client_contact_name"
                  labelText="Nome"
                  type="text"
                  defaultValue={client_contact_name}
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '40%' }}>
                <InfoInput
                  name="client_contact_phone"
                  labelFor="client_contact_phone"
                  labelText="Telefone"
                  type="text"
                  defaultValue={client_contact_phone}
                  disabled
                />
              </Wrapper>
            </Content>

            <Content>
              <Wrapper style={{ width: '100%' }}>
                <InfoInput
                  name="client_contact_email"
                  labelFor="client_contact_email"
                  labelText="E-mail"
                  type="text"
                  defaultValue={client_contact_email}
                  disabled
                />
              </Wrapper>
            </Content>
          </Container>
        </AccordionDetails>
      </Accordion>
    </MainContainer>
  );
};

export default ClientContact;
