import React from 'react';

import InfoInput from '~/components/Inputs/Info';

import { Container, Content } from './styles';

interface IItemAmountProps {
  item_amount?: number;
}

const ItemAmount: React.FC<IItemAmountProps> = ({ item_amount }) => {
  return (
    <Container>
      <Content>
        <InfoInput
          name="item_amount"
          labelFor="item_amount"
          labelText="Quantidade"
          type="text"
          defaultValue={
            item_amount
              ? new Intl.NumberFormat('pt-BR', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(item_amount)
              : new Intl.NumberFormat('pt-BR', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(0)
          }
          disabled
        />
      </Content>
    </Container>
  );
};

export default ItemAmount;
