import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import {
  FaFileUpload,
  FaTrashAlt,
  FaExclamationCircle,
  FaCheckCircle,
  FaLink,
} from 'react-icons/fa';

import AudioIcon from '~/components/Icons/Files/Audio';
import PdfIcon from '~/components/Icons/Files/Pdf';
import PresentationIcon from '~/components/Icons/Files/Presentation';
import SpreadsheetIcon from '~/components/Icons/Files/Spreadsheet';
import TextIcon from '~/components/Icons/Files/Text';
import VideoIcon from '~/components/Icons/Files/Video';
import WordIcon from '~/components/Icons/Files/Word';

import {
  useStoreRelationshipDisplayFiles,
  IStoreRelationshipDisplayFile,
} from '~/context/storerelationshipdisplayfiles';

import {
  Container,
  FileRow,
  FileContainer,
  FilePreviewWrapper,
  FilePreviewImageWrapper,
  FilePreviewImage,
  FileInfoWrapper,
  FileInfoName,
  FileInfoFileSize,
  FileActions,
  DeleteBtn,
  UrlIcon,
  SuccessIcon,
  ErrorIcon,
} from './styles';

const List = (): JSX.Element => {
  const {
    uploadedStoreRelationshipDisplayFiles: storeRelationshipDisplayFiles,
    deleteStoreRelationshipDisplayFile,
  } = useStoreRelationshipDisplayFiles();

  if (!storeRelationshipDisplayFiles.length) {
    return (
      <span>
        <FaFileUpload
          style={{ marginLeft: '45%', marginTop: 15 }}
          size={35}
          color="#adadad"
        />
      </span>
    );
  }

  function handleSRDFilePreview(uploadedSRDFile: any): React.ReactNode {
    if (
      uploadedSRDFile.type === 'audio/mp3' ||
      uploadedSRDFile.type === 'audio/mpeg'
    ) {
      return <AudioIcon />;
    }

    if (uploadedSRDFile.type === 'application/pdf') {
      return <PdfIcon />;
    }

    if (
      uploadedSRDFile.type ===
        'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
      uploadedSRDFile.type === 'application/vnd.oasis.opendocument.presentation'
    ) {
      return <PresentationIcon />;
    }

    if (
      uploadedSRDFile.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      uploadedSRDFile.type === 'application/vnd.oasis.opendocument.spreadsheet'
    ) {
      return <SpreadsheetIcon />;
    }

    if (uploadedSRDFile.type === 'text/plain') {
      return <TextIcon />;
    }

    if (
      uploadedSRDFile.type === 'image/gif' ||
      uploadedSRDFile.type === 'video/quicktime' ||
      uploadedSRDFile.type === 'video/mp4'
    ) {
      return <VideoIcon />;
    }

    if (
      uploadedSRDFile.type ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      uploadedSRDFile.type === 'application/vnd.oasis.opendocument.text'
    ) {
      return <WordIcon />;
    }

    return (
      <FilePreviewImageWrapper>
        <FilePreviewImage src={uploadedSRDFile.preview} />
      </FilePreviewImageWrapper>
    );
  }

  return (
    <Container>
      {storeRelationshipDisplayFiles.map(
        (
          uploadedStoreRelationshipDisplayFile: IStoreRelationshipDisplayFile,
        ) => (
          <FileRow key={uploadedStoreRelationshipDisplayFile.id}>
            <FileContainer>
              <FilePreviewWrapper>
                {handleSRDFilePreview(uploadedStoreRelationshipDisplayFile)}
              </FilePreviewWrapper>

              <FileInfoWrapper>
                <FileInfoName>
                  {uploadedStoreRelationshipDisplayFile.name}
                </FileInfoName>
                <FileInfoFileSize>
                  {uploadedStoreRelationshipDisplayFile.readableSize}
                </FileInfoFileSize>
              </FileInfoWrapper>
            </FileContainer>

            <FileActions>
              {!uploadedStoreRelationshipDisplayFile.uploaded &&
                !uploadedStoreRelationshipDisplayFile.error && (
                  <CircularProgressbar
                    styles={{
                      root: { width: 24 },
                      path: { stroke: '#7159c1' },
                    }}
                    strokeWidth={10}
                    text={String(uploadedStoreRelationshipDisplayFile.progress)}
                    value={uploadedStoreRelationshipDisplayFile.progress || 0}
                  />
                )}

              {uploadedStoreRelationshipDisplayFile.url && (
                <UrlIcon>
                  <a
                    href={uploadedStoreRelationshipDisplayFile.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLink size={20} />
                  </a>
                </UrlIcon>
              )}

              {uploadedStoreRelationshipDisplayFile.uploaded && (
                <SuccessIcon>
                  <FaCheckCircle size={24} />
                </SuccessIcon>
              )}
              {uploadedStoreRelationshipDisplayFile.error && (
                <ErrorIcon>
                  <FaExclamationCircle size={24} />
                </ErrorIcon>
              )}
              {!!uploadedStoreRelationshipDisplayFile.url && (
                <DeleteBtn
                  onClick={() => {
                    deleteStoreRelationshipDisplayFile(
                      uploadedStoreRelationshipDisplayFile.id,
                    );
                  }}
                >
                  <FaTrashAlt size={24} />
                </DeleteBtn>
              )}
            </FileActions>
          </FileRow>
        ),
      )}
    </Container>
  );
};

export default List;
