import React from 'react';

import InfoInput from '~/components/Inputs/Info';

import { Container, Content } from './styles';

interface IFirstRequestProps {
  first_request?: string;
}

const FirstRequest: React.FC<IFirstRequestProps> = ({ first_request }) => {
  return (
    <Container>
      <Content>
        <InfoInput
          name="first_request"
          labelFor="first_request"
          labelText="1º Pedido"
          type="text"
          defaultValue={first_request}
          disabled
        />
      </Content>
    </Container>
  );
};

export default FirstRequest;
