import styled, { css } from 'styled-components';
import { shade } from 'polished';

import Button from '~/components/Button';

interface IContentProps {
  maxWidth: string;
}

interface IWrapperProps {
  marginRight?: string;
}

export const Container = styled.section`
  ${({ theme }) => css`
    width: 100%;
    background: ${theme.colors.background};
    border-radius: ${theme.radius.default};
    box-shadow: ${theme.shadows.flat};
    padding: 2.4rem;
    margin: 2.4rem 0;
  `}
`;

export const Content = styled.div<IContentProps>`
  ${({ maxWidth }) => css`
    display: flex;
    justify-content: flex-start;

    ${maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
  `}
`;

export const Wrapper = styled.div<IWrapperProps>`
  ${({ marginRight }) => css`
    width: 100%;
    margin-bottom: 2rem;

    ${marginRight &&
    css`
      margin-right: ${marginRight};
    `}

    @media (max-width: 1010px) {
      margin: 0 0 2rem 0;
    }
  `}
`;

export const HalfWrapper = styled(Wrapper)`
  width: 50%;

  @media (max-width: 1010px) {
    width: 100%;
  }
`;

export const ThreeFourthsWrapper = styled(Wrapper)`
  width: 75%;

  @media (max-width: 1010px) {
    width: 100%;
  }
`;

export const TwoFifthsWrapper = styled(Wrapper)`
  width: 40%;

  @media (max-width: 1010px) {
    width: 100%;
  }
`;

export const ThreeFifthsWrapper = styled(Wrapper)`
  width: 60%;

  @media (max-width: 1010px) {
    width: 100%;
  }
`;

export const InfoWrapper = styled.fieldset`
  ${({ theme }) => css`
    width: 75%;
    border-radius: ${theme.radius.small};
    padding: 10px;
    margin-bottom: 1.8rem;
    border: 1px solid #e4e4e4;
  `}
`;

export const ButtonHalfWrapper = styled.div`
  width: 50%;
  display: flex;
`;

export const ButtonOneFourthWrapper = styled.div`
  width: 25%;
  display: flex;
`;

export const InfoContent = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 1010px) {
    flex-direction: column;
  }
`;

export const LabelContainer = styled.div`
  margin-bottom: 0.5rem;
`;

export const Label = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.default};
    color: ${theme.colors.labelInput};
  `}
`;

export const ExtrasContainer = styled.div`
  width: 75%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const ExtrasButtonWrapper = styled.div`
  width: 29rem;
  padding: 0 2.5rem 0 2.5rem;
  margin-bottom: 2rem;
`;

export const ExtrasButton = styled(Button)`
  ${({ theme }) => css`
    padding: 1.4rem 1.6rem;
    justify-content: space-between;
    width: 100%;
    height: 12rem;
    border-radius: ${theme.radius.default};
    background: ${theme.colors.veryDarkGrey};

    :hover {
      background: ${shade(0.2, theme.colors.optionsHover)};
      transition: 0.2s;
    }
  `}
`;

export const ExtrasButtonIcon = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.iconProjects};
  `}
`;

export const ExtrasButtonType = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.large};
    margin-left: 0.8rem;
  `}
`;

export const ExtrasButtonGoIcon = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    margin-left: 0.8rem;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20;
`;
