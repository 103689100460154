import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaStore } from 'react-icons/fa';
import { PropagateLoader } from 'react-spinners';

import BreadCrumb from '~/components/BreadCrumb';
import DefaultInput from '~/components/Inputs/Default';
import LockButton from '~/components/Buttons/Lock';
import UnlockButton from '~/components/Buttons/Unlock';
import SelectButton from '~/components/Buttons/Select';
import ViewButton from '~/components/Buttons/View';
import Paginator from '~/components/Paginator';
import PrevButton from '~/components/Paginator/PrevButton';
import NextButton from '~/components/Paginator/NextButton';
import CenterButton from '~/components/Paginator/CenterButton';

import { useAuth } from '~/hooks/auth';

import api from '~/services/api';

import {
  Container,
  LabelContainer,
  Label,
  FiltersContent,
  FiltersMainWrapper,
  FiltersButtonWrapper,
  FiltersWrapper,
  Wrapper,
  OneThirdWrapper,
  Table,
  Thead,
  Tbody,
  Th,
  TbodyTr,
  Td,
  ButtonsContent,
} from './styles';

interface IClient {
  code: string;
  name: string;
  company_name: string;
  document: string;
  address: string;
  city: string;
  state: string;
  rep_code: string;
  rep_name: string;
  sales_channel_code: string;
}

const ListClients: React.FC = () => {
  const { user } = useAuth();
  const [page, setPage] = useState(1);
  const [pageFilter, setPageFilter] = useState(1);
  const [filter, setFilter] = useState('');
  const [filterHelper, setFilterHelper] = useState('');
  const [refresh, setRefresh] = useState(true);
  const codeFilter = useRef('');
  const companyNameFilter = useRef('');
  const documentFilter = useRef('');
  const representativeNameFilter = useRef('');
  const cityFilter = useRef('');
  const stateFilter = useRef('');
  const codeFilterHelper = useRef('');
  const companyNameFilterHelper = useRef('');
  const documentFilterHelper = useRef('');
  const representativeNameFilterHelper = useRef('');
  const cityFilterHelper = useRef('');
  const stateFilterHelper = useRef('');
  const [refreshManyFilters, setRefreshManyFilters] = useState(false);
  const [filtersEnabled, setFiltersEnabled] = useState(true);
  const [manyFiltersEnabled, setManyFiltersEnabled] = useState(false);
  const [clients, setClients] = useState<IClient[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [nextDisabled, setNextDisabled] = useState(true);

  useEffect(() => {
    let erpRepCode = '';
    if (user.user_type.type === 'REP') {
      erpRepCode = user.erp_rep_code;
    }
    if (refresh) {
      setRefresh(false);
      let pageSearch = 1;
      let foundData = false;

      if (filter !== filterHelper) {
        setPage(1);
        setPageFilter(1);
      }

      setFilterHelper(filter);

      if (filter !== '') {
        pageSearch = pageFilter;
      } else {
        pageSearch = page;
      }

      api
        .get(
          `/clients?page=${pageSearch}&filter=${filter}` +
            `&user_type=${user.user_type.type}&erp_rep_code=${erpRepCode}`,
        )
        .then(firstResponse => {
          if (firstResponse.data.length > 0) {
            foundData = true;
            setClients(firstResponse.data);
            setLoading(false);
          }

          if (!foundData) {
            if (pageSearch - 1 >= 1) {
              pageSearch -= 1;
              if (filter !== '') {
                setPageFilter(pageFilter - 1);
              } else {
                setPage(page - 1);
              }
            }

            api
              .get(
                `/clients?page=${pageSearch}&filter=${filter}` +
                  `&user_type=${user.user_type.type}&erp_rep_code=${erpRepCode}`,
              )
              .then(secondResponse => {
                setClients(secondResponse.data);
                setLoading(false);

                api
                  .get(
                    `/clients?page=${pageSearch + 1}&filter=${filter}` +
                      `&user_type=${user.user_type.type}&erp_rep_code=${erpRepCode}`,
                  )
                  .then(thirdResponse => {
                    setNextDisabled(false);
                    if (thirdResponse.data.length === 0) {
                      setNextDisabled(true);
                    }
                  });
              });
          } else {
            api
              .get(
                `/clients?page=${pageSearch + 1}&filter=${filter}` +
                  `&user_type=${user.user_type.type}&erp_rep_code=${erpRepCode}`,
              )
              .then(fourthResponse => {
                setNextDisabled(false);
                if (fourthResponse.data.length === 0) {
                  setNextDisabled(true);
                }
              });
          }
        });
    }
  }, [page, pageFilter, filter, filterHelper, refresh, user]);

  useEffect(() => {
    let erpRepCode = '';
    if (user.user_type.type === 'REP') {
      erpRepCode = user.erp_rep_code;
    }
    if (refreshManyFilters) {
      setRefreshManyFilters(false);
      let pageSearch = 1;
      let foundData = false;

      if (
        codeFilter.current !== codeFilterHelper.current ||
        companyNameFilter.current !== companyNameFilterHelper.current ||
        documentFilter.current !== documentFilterHelper.current ||
        representativeNameFilter.current !==
          representativeNameFilterHelper.current ||
        cityFilter.current !== cityFilterHelper.current ||
        stateFilter.current !== stateFilterHelper.current
      ) {
        setPage(1);
        setPageFilter(1);
      }

      codeFilterHelper.current = codeFilter.current;
      companyNameFilterHelper.current = companyNameFilter.current;
      documentFilterHelper.current = documentFilter.current;
      representativeNameFilterHelper.current = representativeNameFilter.current;
      cityFilterHelper.current = cityFilter.current;
      stateFilterHelper.current = stateFilter.current;

      if (
        codeFilter.current !== '' ||
        companyNameFilter.current !== '' ||
        documentFilter.current !== '' ||
        representativeNameFilter.current !== '' ||
        cityFilter.current !== '' ||
        stateFilter.current !== ''
      ) {
        pageSearch = pageFilter;
      } else {
        pageSearch = page;
      }

      api
        .get(
          `/clients/manyfilters?` +
            `page=${pageSearch}&` +
            `user_type=${user.user_type.type}&` +
            `erp_rep_code=${erpRepCode}&` +
            `code=${codeFilter.current}&` +
            `company_name=${companyNameFilter.current}&` +
            `document=${documentFilter.current}&` +
            `representative_name=${representativeNameFilter.current}&` +
            `city=${cityFilter.current}&` +
            `state=${stateFilter.current}`,
        )
        .then(firstResponse => {
          if (firstResponse.data.length > 0) {
            foundData = true;
            setClients(firstResponse.data);
            setLoading(false);
          }

          if (!foundData) {
            if (pageSearch - 1 >= 1) {
              pageSearch -= 1;
              if (
                codeFilter.current !== '' ||
                companyNameFilter.current !== '' ||
                documentFilter.current !== '' ||
                representativeNameFilter.current !== '' ||
                cityFilter.current !== '' ||
                stateFilter.current !== ''
              ) {
                setPageFilter(pageFilter - 1);
              } else {
                setPage(page - 1);
              }
            }

            api
              .get(
                `/clients/manyfilters?` +
                  `page=${pageSearch}&` +
                  `user_type=${user.user_type.type}&` +
                  `erp_rep_code=${erpRepCode}&` +
                  `code=${codeFilter.current}&` +
                  `company_name=${companyNameFilter.current}&` +
                  `document=${documentFilter.current}&` +
                  `representative_name=${representativeNameFilter.current}&` +
                  `city=${cityFilter.current}&` +
                  `state=${stateFilter.current}`,
              )
              .then(secondResponse => {
                setClients(secondResponse.data);
                setLoading(false);

                api
                  .get(
                    `/clients/manyfilters?` +
                      `page=${pageSearch + 1}&` +
                      `user_type=${user.user_type.type}&` +
                      `erp_rep_code=${erpRepCode}&` +
                      `code=${codeFilter.current}&` +
                      `company_name=${companyNameFilter.current}&` +
                      `document=${documentFilter.current}&` +
                      `representative_name=${representativeNameFilter.current}&` +
                      `city=${cityFilter.current}&` +
                      `state=${stateFilter.current}`,
                  )
                  .then(thirdResponse => {
                    setNextDisabled(false);
                    if (thirdResponse.data.length === 0) {
                      setNextDisabled(true);
                    }
                  });
              });
          } else {
            api
              .get(
                `/clients/manyfilters?` +
                  `page=${pageSearch + 1}&` +
                  `user_type=${user.user_type.type}&` +
                  `erp_rep_code=${erpRepCode}&` +
                  `code=${codeFilter.current}&` +
                  `company_name=${companyNameFilter.current}&` +
                  `document=${documentFilter.current}&` +
                  `representative_name=${representativeNameFilter.current}&` +
                  `city=${cityFilter.current}&` +
                  `state=${stateFilter.current}`,
              )
              .then(fourthResponse => {
                setNextDisabled(false);
                if (fourthResponse.data.length === 0) {
                  setNextDisabled(true);
                }
              });
          }
        });
    }
  }, [
    page,
    pageFilter,
    codeFilter,
    companyNameFilter,
    documentFilter,
    representativeNameFilter,
    cityFilter,
    stateFilter,
    codeFilterHelper,
    companyNameFilterHelper,
    documentFilterHelper,
    representativeNameFilterHelper,
    cityFilterHelper,
    stateFilterHelper,
    refreshManyFilters,
    user,
  ]);

  function handlePage(type: string): void {
    if (filtersEnabled) {
      if (type === 'backward') {
        if (filter !== '') {
          setPageFilter(pageFilter - 1);
        } else {
          setPage(page - 1);
        }
      } else if (type === 'forward') {
        if (filter !== '') {
          setPageFilter(pageFilter + 1);
        } else {
          setPage(page + 1);
        }
      } else if (filter !== '') {
        setPageFilter(1);
      } else {
        setPage(1);
      }

      setRefresh(true);
    } else if (manyFiltersEnabled) {
      if (type === 'backward') {
        if (
          codeFilter.current !== '' ||
          companyNameFilter.current !== '' ||
          documentFilter.current !== '' ||
          representativeNameFilter.current !== '' ||
          cityFilter.current !== '' ||
          stateFilter.current !== ''
        ) {
          setPageFilter(pageFilter - 1);
        } else {
          setPage(page - 1);
        }
      } else if (type === 'forward') {
        if (
          codeFilter.current !== '' ||
          companyNameFilter.current !== '' ||
          documentFilter.current !== '' ||
          representativeNameFilter.current !== '' ||
          cityFilter.current !== '' ||
          stateFilter.current !== ''
        ) {
          setPageFilter(pageFilter + 1);
        } else {
          setPage(page + 1);
        }
      } else if (
        codeFilter.current !== '' ||
        companyNameFilter.current !== '' ||
        documentFilter.current !== '' ||
        representativeNameFilter.current !== '' ||
        cityFilter.current !== '' ||
        stateFilter.current !== ''
      ) {
        setPageFilter(1);
      } else {
        setPage(1);
      }

      setRefreshManyFilters(true);
    }
  }

  function handlePrevButtonDisabled(): boolean {
    let validationPage = 1;

    if (filtersEnabled) {
      if (filter !== '') {
        validationPage = pageFilter;
      } else {
        validationPage = page;
      }
    } else if (manyFiltersEnabled) {
      if (
        codeFilter.current !== '' ||
        companyNameFilter.current !== '' ||
        documentFilter.current !== '' ||
        representativeNameFilter.current !== '' ||
        cityFilter.current !== '' ||
        stateFilter.current !== ''
      ) {
        validationPage = pageFilter;
      } else {
        validationPage = page;
      }
    }

    if (validationPage === 1) {
      return true;
    }

    return false;
  }

  function handleFilterChange(value: string): void {
    setFilter(value);
    setRefresh(true);
  }

  function handleCodeFilterChange(value: string): void {
    codeFilter.current = value;
    setRefreshManyFilters(true);
  }

  function handleCompanyNameFilterChange(value: string): void {
    companyNameFilter.current = value;
    setRefreshManyFilters(true);
  }

  function handleDocumentFilterChange(value: string): void {
    documentFilter.current = value;
    setRefreshManyFilters(true);
  }

  function handleRepresentativeNameFilterChange(value: string): void {
    representativeNameFilter.current = value;
    setRefreshManyFilters(true);
  }

  function handleCityFilterChange(value: string): void {
    cityFilter.current = value;
    setRefreshManyFilters(true);
  }

  function handleStateFilterChange(value: string): void {
    stateFilter.current = value;
    setRefreshManyFilters(true);
  }

  function handleActionButtons(clientReceived: IClient): React.ReactNode {
    return (
      <>
        <Link
          to={{
            pathname: `/storerelationships/procedure1`,
            state: clientReceived,
          }}
        >
          <SelectButton />
        </Link>

        <Link
          style={{ marginLeft: '5px' }}
          to={{
            pathname: `/storerelationships/view`,
            state: clientReceived,
          }}
        >
          <ViewButton />
        </Link>
      </>
    );
  }

  function handleTable(): React.ReactNode {
    if (clients) {
      if (clients.length > 0) {
        if (user.user_type.type === 'REP') {
          return (
            <Table>
              <Thead>
                <tr>
                  <Th width="10%" textAlign="left">
                    Código
                  </Th>
                  <Th width="35%" textAlign="left">
                    Razão Social
                  </Th>
                  <Th width="20%" textAlign="left">
                    CNPJ
                  </Th>
                  <Th width="15%" textAlign="left">
                    Cidade
                  </Th>
                  <Th width="15%" textAlign="left">
                    Estado
                  </Th>
                  <Th width="5%" textAlign="center" />
                </tr>
              </Thead>
              <Tbody>
                {loading && (
                  <tr>
                    <Td colSpan={4} padding="1rem" textAlign="center">
                      <PropagateLoader color="#276c8d" />
                    </Td>
                  </tr>
                )}
                {clients?.map(client => (
                  <TbodyTr key={client.code}>
                    <Td width="10%" textAlign="left" heading="CÓDIGO">
                      {client.code}
                    </Td>
                    <Td width="35%" textAlign="left" heading="RAZÃO SOCIAL">
                      {client.company_name.toUpperCase()}
                    </Td>
                    <Td width="20%" textAlign="left" heading="CNPJ">
                      {client.document}
                    </Td>
                    <Td width="15%" textAlign="left" heading="CIDADE">
                      {client.city.toUpperCase()}
                    </Td>
                    <Td width="15%" textAlign="left" heading="ESTADO">
                      {client.state.toUpperCase()}
                    </Td>
                    <Td
                      width="5%"
                      textAlign="center"
                      display="flex"
                      heading={' '}
                    >
                      {handleActionButtons(client)}
                    </Td>
                  </TbodyTr>
                ))}
              </Tbody>
            </Table>
          );
        }

        return (
          <Table>
            <Thead>
              <tr>
                <Th width="10%" textAlign="left">
                  Código
                </Th>
                <Th width="25%" textAlign="left">
                  Razão Social
                </Th>
                <Th width="15%" textAlign="left">
                  CNPJ
                </Th>
                <Th width="20%" textAlign="left">
                  Representante
                </Th>
                <Th width="15%" textAlign="left">
                  Cidade
                </Th>
                <Th width="10%" textAlign="left">
                  Estado
                </Th>
                <Th width="5%" textAlign="center" />
              </tr>
            </Thead>
            <Tbody>
              {loading && (
                <tr>
                  <Td colSpan={4} padding="1rem" textAlign="center">
                    <PropagateLoader color="#276c8d" />
                  </Td>
                </tr>
              )}
              {clients?.map(client => (
                <TbodyTr key={client.code}>
                  <Td width="10%" textAlign="left" heading="CÓDIGO">
                    {client.code}
                  </Td>
                  <Td width="25%" textAlign="left" heading="RAZÃO SOCIAL">
                    {client.company_name.toUpperCase()}
                  </Td>
                  <Td width="15%" textAlign="left" heading="CNPJ">
                    {client.document}
                  </Td>
                  <Td width="20%" textAlign="left" heading="REPRESENTANTE">
                    {client.rep_name.toUpperCase()}
                  </Td>
                  <Td width="15%" textAlign="left" heading="CIDADE">
                    {client.city.toUpperCase()}
                  </Td>
                  <Td width="10%" textAlign="left" heading="ESTADO">
                    {client.state.toUpperCase()}
                  </Td>
                  <Td
                    width="5%"
                    textAlign="center"
                    display="flex"
                    heading={' '}
                  >
                    {handleActionButtons(client)}
                  </Td>
                </TbodyTr>
              ))}
            </Tbody>
          </Table>
        );
      }
    }

    return (
      <Table>
        <Thead>
          <tr>
            <Th width="100%" textAlign="center" />
          </tr>
        </Thead>
        <Tbody>
          <TbodyTr>
            <Td width="100%" textAlign="center">
              Nenhuma informação disponível
            </Td>
          </TbodyTr>
        </Tbody>
      </Table>
    );
  }

  return (
    <Container style={{ minHeight: 600 }}>
      <BreadCrumb main="Lojas" path="/storerelationships" mainIcon={FaStore} />

      <LabelContainer>
        <Label>Pesquisa Geral</Label>
      </LabelContainer>

      <FiltersContent maxWidth="1010px">
        <FiltersMainWrapper disabled={!filtersEnabled}>
          <Wrapper>
            <DefaultInput
              name="search"
              labelText="Pesquisar"
              type="text"
              onChange={event => handleFilterChange(event.target.value)}
            />
          </Wrapper>
        </FiltersMainWrapper>

        <FiltersButtonWrapper>
          {filtersEnabled ? (
            <UnlockButton
              iconSize={26}
              type="button"
              onClick={() => {
                setPage(1);
                setPageFilter(1);
                setFiltersEnabled(false);
                setManyFiltersEnabled(true);
                setRefreshManyFilters(true);
              }}
            />
          ) : (
            <LockButton
              iconSize={26}
              type="button"
              onClick={() => {
                setPage(1);
                setPageFilter(1);
                setFiltersEnabled(true);
                setManyFiltersEnabled(false);
                setRefresh(true);
              }}
            />
          )}
        </FiltersButtonWrapper>
      </FiltersContent>

      <LabelContainer>
        <Label>Pesquisa Específica</Label>
      </LabelContainer>

      <FiltersContent maxWidth="1010px">
        <FiltersMainWrapper disabled={!manyFiltersEnabled}>
          <FiltersWrapper>
            <OneThirdWrapper marginRight="1rem">
              <DefaultInput
                name="code_search"
                labelText="Código"
                type="text"
                onChange={event => handleCodeFilterChange(event.target.value)}
              />
            </OneThirdWrapper>

            <OneThirdWrapper marginRight="1rem">
              <DefaultInput
                name="company_name_search"
                labelText="Razão Social"
                type="text"
                onChange={event => {
                  handleCompanyNameFilterChange(event.target.value);
                }}
              />
            </OneThirdWrapper>

            <OneThirdWrapper>
              <DefaultInput
                name="document_search"
                labelText="CNPJ"
                type="text"
                onChange={event => {
                  handleDocumentFilterChange(event.target.value);
                }}
              />
            </OneThirdWrapper>
          </FiltersWrapper>

          <FiltersWrapper>
            <OneThirdWrapper marginRight="1rem">
              <DefaultInput
                name="representative_name_search"
                labelText="Representante"
                type="text"
                onChange={event => {
                  handleRepresentativeNameFilterChange(event.target.value);
                }}
              />
            </OneThirdWrapper>

            <OneThirdWrapper marginRight="1rem">
              <DefaultInput
                name="city_search"
                labelText="Cidade"
                type="text"
                onChange={event => handleCityFilterChange(event.target.value)}
              />
            </OneThirdWrapper>

            <OneThirdWrapper>
              <DefaultInput
                name="state_search"
                labelText="Estado"
                type="text"
                onChange={event => handleStateFilterChange(event.target.value)}
              />
            </OneThirdWrapper>
          </FiltersWrapper>
        </FiltersMainWrapper>

        <FiltersButtonWrapper>
          {manyFiltersEnabled ? (
            <UnlockButton
              iconSize={26}
              type="button"
              onClick={() => {
                setPage(1);
                setPageFilter(1);
                setManyFiltersEnabled(false);
                setFiltersEnabled(true);
                setRefresh(true);
              }}
            />
          ) : (
            <LockButton
              iconSize={26}
              type="button"
              onClick={() => {
                setPage(1);
                setPageFilter(1);
                setManyFiltersEnabled(true);
                setFiltersEnabled(false);
                setRefreshManyFilters(true);
              }}
            />
          )}
        </FiltersButtonWrapper>
      </FiltersContent>

      {handleTable()}

      <ButtonsContent>
        <Paginator>
          <PrevButton
            disabled={handlePrevButtonDisabled()}
            onClick={() => handlePage('backward')}
          />
          <CenterButton onClick={() => handlePage('reset')} />
          <NextButton
            disabled={nextDisabled}
            onClick={() => handlePage('forward')}
          />
        </Paginator>
      </ButtonsContent>
    </Container>
  );
};

export default ListClients;
