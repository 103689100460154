import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import Modal from 'react-modal';

interface IContentProps {
  maxWidth: string;
}

interface IWrapperProps {
  marginRight?: string;
}

interface IThProps {
  width: string;
  textAlign: string;
}

interface ITdProps {
  width?: string;
  textAlign?: string;
  padding?: string;
  display?: string;
  heading?: string;
}

interface IPreviewMessageProps {
  src: string;
}

export const StyledModal = styled(Modal)`
  ${({ theme }) => css`
    margin-top: 10%;
    margin-left: 50%;
    transform: translate(-50%, -10%);
    background-color: ${theme.colors.background};
    box-shadow: ${theme.shadows.veryStrong};
    border-radius: ${theme.radius.meddium};
    width: 100%;
    max-width: 680px;

    @media (max-width: 1010px) {
      margin-top: 0;
      margin-left: 0;
      transform: translate(0%, 0%);
      margin: auto;
    }
  `}
`;

export const MainContainer = styled.div`
  padding: 1rem 1rem 1rem 1rem;
`;

export const Title = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.large};
  `}
`;

export const Container = styled.section`
  ${({ theme }) => css`
    width: 100%;
    background: ${theme.colors.backgroundContainer};
    border-radius: ${theme.radius.small};
    border: 1px solid #e4e4e4;
    margin-bottom: 2rem;
    padding: 2rem 2rem 2rem 2rem;
    max-width: 680px;
  `}
`;

export const Content = styled.div<IContentProps>`
  ${({ maxWidth }) => css`
    display: flex;
    justify-content: flex-start;

    ${maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
  `}
`;

export const ContentSplit = styled(Content)`
  @media (max-width: 1010px) {
    flex-direction: column;
  }
`;

export const Wrapper = styled.div<IWrapperProps>`
  ${({ marginRight }) => css`
    width: 100%;
    margin-bottom: 2rem;

    ${marginRight &&
    css`
      margin-right: ${marginRight};
    `}

    @media (max-width: 1010px) {
      margin: 0 0 2rem 0;
    }
  `}
`;

export const HalfWrapper = styled(Wrapper)`
  width: 50%;

  @media (max-width: 1010px) {
    width: 100%;
  }
`;

export const FilesWrapper = styled(motion.section)`
  ${({ theme }) => css`
    width: 100%;
    background: ${theme.colors.backgroundContainer};
    border-radius: ${theme.radius.small};
    border: 1px solid #e4e4e4;
    margin-bottom: 2rem;
    padding: 2rem 2rem 2rem 2rem;
    max-width: 680px;
  `}
`;

export const FilterContent = styled.div`
  max-width: 680px;
`;

export const Table = styled.table`
  ${({ theme }) => css`
    margin: 3rem 0 0 0;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0 4px;
    background-color: ${theme.colors.lightGrey};
    box-shadow: 1px 1px 6px #ececec;
    border-radius: ${theme.radius.small};

    tbody {
      background-color: ${theme.colors.backgroundContainer};
      tr {
        transition: all 0.25s ease;
        background-color: #fff;

        &:hover {
          transform: translateY(-1px);
          background-color: ${theme.colors.hoverTable};
          border-radius: ${theme.radius.small};
        }
      }
    }

    @media (max-width: 1040px) {
      thead {
        display: none;
      }

      tr {
        display: block;
        margin-bottom: 2rem;
        border-radius: ${theme.radius.small};
      }
    }
  `}
`;

export const Th = styled.th<IThProps>`
  ${({ theme, width, textAlign }) => css`
    padding: 1.8rem 0;
    font-size: ${theme.fontSizes.default};
    text-transform: uppercase;
    color: ${theme.colors.grey};

    &:first-child {
      padding-left: 2rem;
    }

    ${width &&
    css`
      width: ${width};
    `}

    ${textAlign &&
    css`
      text-align: ${textAlign};
    `}
  `}
`;

export const Td = styled.th<ITdProps>`
  ${({ theme, width, textAlign, padding, display, heading }) => css`
    border: 0;
    font-size: ${theme.fontSizes.small};
    padding: 1rem 0;
    font-weight: ${theme.weight.default};
    color: ${theme.colors.grey};

    &:first-child {
      padding-left: 2rem;
    }

    &:last-child {
      padding-right: 2rem;
    }

    ${width &&
    css`
      width: ${width};
    `}

    ${textAlign &&
    css`
      text-align: ${textAlign};
    `}

    ${padding &&
    css`
      padding: ${padding};
    `}

    ${display &&
    css`
      display: ${display};
    `}

    @media (max-width: 1040px) {
      display: block;
      position: relative;
      width: 100%;
      padding-left: 15.2rem;
      text-align: left;

      &:first-child {
        padding-left: 15.2rem;
      }

      ${display &&
      css`
        display: ${display};
      `}

      &::before {
        ${heading &&
        css`
          content: '${heading}';
        `}

        position: absolute;
        top: 0;
        left: 0;
        width: 13rem;
        height: 100%;
        display: flex;
        align-items: center;
        background-color: ${theme.colors.lightGrey};
        padding: 0 0.8rem;
      }
    }
  `}
`;

export const FileWrapper = styled.div`
  width: 8rem;
`;

export const FileImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const FileImage = styled.div<IPreviewMessageProps>`
  ${({ theme, src }) => css`
    width: 50px;
    height: 50px;
    border-radius: ${theme.radius.small};
    background-image: url(${src});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  `}
`;

export const UrlIcon = styled.div`
  ${({ theme }) => css`
    margin-right: 1rem;
    margin-top: 0.6rem;

    svg {
      color: ${theme.colors.urlIcon};
    }

    &:hover {
      transform: scale(1.2, 1.2);
    }
  `}
`;

export const ButtonsContent = styled.div`
  display: flex;
  margin-top: 2rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;
