import React, { useState, useEffect } from 'react';

import api from '~/services/api';

import TextArea from '~/components/TextArea';

import { Container, Content } from './styles';

interface ICompletionProps {
  sequence: number;
  type: string;
  project_id: string;
}

interface ICompletion {
  sequence: number;
  description: string;
  status: string;
  visible: boolean;
  note: string;
  reason: string;
  project_id: string;
  user_id: string;
  user: {
    name: string;
  };
  project_procedure_id: string;
}

const Completion: React.FC<ICompletionProps> = ({
  sequence,
  type,
  project_id,
}) => {
  const [completion, setCompletion] = useState<ICompletion>();
  const [completionValue, setCompletionValue] = useState('');

  useEffect(() => {
    if (completion) {
      if (completion.status) {
        if (completion.status === 'Finalizado') {
          setCompletionValue(`Finalizado por ${completion.user.name}`);
        }
      }
    }
  }, [completion]);

  useEffect(() => {
    api
      .get(
        `/projectrecords/statusprojectprocedure?` +
          `status=Finalizado&type=${type}&sequence=${sequence}&project_id=${project_id}`,
      )
      .then(response => {
        setCompletion(response.data);
      });
  }, [type, sequence, project_id]);

  return (
    <Container>
      <Content>
        <TextArea
          name="completion"
          labelFor="completion"
          labelText="Finalização"
          value={completionValue}
          maxLength={1000}
          disabled
        />
      </Content>
    </Container>
  );
};

export default Completion;
