import styled, { css } from 'styled-components';
import { shade } from 'polished';

import { IDialogOptionProps } from '.';

export const Container = styled.button<IDialogOptionProps>`
  ${({ theme, optionType, marginLeft }) => css`
    width: 22rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.2rem 6rem;
    border-radius: ${theme.radius.default};
    background: ${theme.colors.secondary};
    color: #fff;
    font-size: ${theme.fontSizes.default};
    font-weight: 400;

    :hover {
      background: ${shade(0.2, theme.colors.secondary)};
      transition: 0.2s;
      transform: scale(1.1, 1.1);
    }

    ${optionType === 'danger' &&
    css`
      background: ${theme.colors.dialogOptionDanger};

      :hover {
        background: ${shade(0.2, theme.colors.dialogOptionDanger)};
      }
    `}

    ${optionType === 'report' &&
    css`
      background: ${theme.colors.dialogOptionReport};

      :hover {
        background: ${shade(0.2, theme.colors.dialogOptionReport)};
      }
    `}

    ${optionType === 'success' &&
    css`
      background: ${theme.colors.dialogOptionSuccess};

      :hover {
        background: ${shade(0.2, theme.colors.dialogOptionSuccess)};
      }
    `}

    ${marginLeft &&
    css`
      margin-left: ${marginLeft};
    `}
  `}
`;
