import React, { useEffect, useState, memo } from 'react';
import { useLocation, Link } from 'react-router-dom';

import api from '~/services/api';

import androidLogo from '~/assets/images/android-logo.svg';
import appleLogo from '~/assets/images/apple-logo.svg';
import iosLogo from '~/assets/images/ios-logo.svg';

import {
  AnimatedContainer,
  Wrapper,
  AnimatedLeftNav,
  AnimatedRightNav,
  LogoLink,
  Logo,
  AccessWrapper,
  AccessBtn,
} from './styles';

import AccountDropdown from './AccountDropdown';

import { CONTAINER_ANIMATION, NAVS_ANIMATION } from './animations';

interface IMobileAccesses {
  android_url: string;
  ios_url: string;
}

const Header: React.FC = () => {
  const { pathname } = useLocation();
  const [mobileAccesses, setMobileAccesses] = useState<IMobileAccesses>();

  useEffect(() => {
    api.get('/mobileaccesses').then(response => {
      setMobileAccesses(response.data);
    });
  }, []);

  return (
    <AnimatedContainer
      variants={CONTAINER_ANIMATION}
      initial={pathname === '/dashboard' ? 'unMounted' : 'mounted'}
      animate="mounted"
    >
      <Wrapper>
        <AnimatedLeftNav variants={NAVS_ANIMATION}>
          <LogoLink
            to={{
              pathname: '/dashboard',
            }}
          >
            <Logo />
          </LogoLink>
        </AnimatedLeftNav>

        <AnimatedRightNav variants={NAVS_ANIMATION}>
          <AccessWrapper>
            <Link
              to={{
                pathname: mobileAccesses?.android_url,
              }}
              target="_blank"
            >
              <AccessBtn style={{ marginRight: '10px' }}>
                <img src={androidLogo} alt="" width="50px" />
              </AccessBtn>
            </Link>

            <Link
              to={{
                pathname: mobileAccesses?.ios_url,
              }}
              target="_blank"
            >
              <AccessBtn>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={appleLogo}
                    alt=""
                    width="27px"
                    style={{ marginBottom: '3px' }}
                  />

                  <img src={iosLogo} alt="" width="20px" />
                </div>
              </AccessBtn>
            </Link>
          </AccessWrapper>

          <AccountDropdown />
        </AnimatedRightNav>
      </Wrapper>
    </AnimatedContainer>
  );
};

export default memo(Header);
