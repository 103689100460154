import React from 'react';

import InfoInput from '~/components/Inputs/Info';

import { Container, Content } from './styles';

interface IEstEventPartNumberProps {
  estimated_event_participants_number?: number;
}

const EstEventPartNumber: React.FC<IEstEventPartNumberProps> = ({
  estimated_event_participants_number,
}) => {
  return (
    <Container>
      <Content>
        <InfoInput
          name="estimated_event_participants_number"
          labelFor="estimated_event_participants_number"
          labelText="Número Est. Participantes"
          type="text"
          defaultValue={
            estimated_event_participants_number
              ? new Intl.NumberFormat('pt-BR').format(
                  estimated_event_participants_number,
                )
              : new Intl.NumberFormat('pt-BR').format(0)
          }
          disabled
        />
      </Content>
    </Container>
  );
};

export default EstEventPartNumber;
