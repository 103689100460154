import React, { ButtonHTMLAttributes } from 'react';
import { MdCancel } from 'react-icons/md';

import { Container } from './styles';

interface IRejectProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
}

const Reject: React.FC<IRejectProps> = ({ label, ...rest }) => {
  return (
    <Container {...rest}>
      {label || 'Recusar'} <MdCancel size={18} />
    </Container>
  );
};

export default Reject;
