import styled, { css } from 'styled-components';

export const Container = styled.section`
  ${({ theme }) => css`
    background: ${theme.colors.background};
    border-radius: ${theme.radius.default};
    box-shadow: ${theme.shadows.flat};
    padding: 2.4rem;
    width: 100%;
    margin: 2.4rem 0;
  `}
`;

export const Content = styled.div`
  max-width: 900px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 1rem;
`;

export const Wrapper = styled.div`
  @media (max-width: 900px) {
    width: 100% !important;
  }
`;

export const ValidationLabel = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.grey};
    font-size: 18px;
    font-style: italic;
  `}
`;
