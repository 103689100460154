import styled, { css } from 'styled-components';

interface IDividerProps {
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
}

export const Container = styled.div<IDividerProps>`
  ${({ theme, mt, mr, mb, ml }) => css`
    position: relative;
    margin: ${mt ? `${mt}px` : '0'} ${mr ? `${mr}px` : '0'}
      ${mb ? `${mb}px` : '0'} ${ml ? `${ml}px` : '0'};
    border-top: 2px solid ${theme.colors.secondLightGrey};

    span {
      position: absolute;
      top: -13px;
      left: 50px;
      padding: 0 10px;
      background: ${theme.colors.white};
      color: ${theme.colors.grey};
      font-size: ${theme.fontSizes.large};
      font-weight: 500;
    }
  `}
`;
