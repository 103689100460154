import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const AnimatedContainer = styled(motion.section)`
  ${({ theme }) => css`
    background: ${theme.colors.backgroundContainer};
    border-radius: ${theme.radius.default};
    box-shadow: ${theme.shadows.flat};
    padding: 2.4rem;
    width: 100%;
    margin-top: 1rem;

    > div {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  `}
`;
