import styled, { css } from 'styled-components';

import { IUnlockProps } from '.';

export const Container = styled.button<IUnlockProps>`
  ${({ theme, marginTop }) => css`
    background-color: transparent;
    color: ${theme.colors.lockUnlock};
    padding: 8px 0 0 8px;
    border-radius: 50%;
    box-shadow: 1px 1px 1px ${theme.colors.lightGrey};

    &:hover {
      transform: scale(1.2, 1.2);
    }

    ${marginTop &&
    css`
      margin-top: ${marginTop};
    `}
  `}
`;
