import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

export interface IDialogOptionProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  optionType?: string;
  marginLeft?: string;
}

const DialogOption: React.FC<IDialogOptionProps> = ({ label, ...rest }) => {
  return (
    <Container type="button" {...rest}>
      {label}
    </Container>
  );
};

export default DialogOption;
