import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { FaAngleDown } from 'react-icons/fa';

import ViewButton from '~/components/Buttons/View';
import Paginator from '~/components/Paginator';
import PrevButton from '~/components/Paginator/PrevButton';
import NextButton from '~/components/Paginator/NextButton';
import CenterButton from '~/components/Paginator/CenterButton';

import Input from '~/components/Input';

import api from '~/services/api';

import {
  Container,
  Label,
  Wrapper,
  Table,
  Th,
  Td,
  ButtonsContent,
} from './styles';

interface IProjectsProps {
  erp_client_code: string;
}

interface IProject {
  id: string;
  type: string;
  code: string;
  status: string;
  display: {
    name: string;
  };
  material: {
    name: string;
  };
  sponsorship: {
    name: string;
  };
  adversity: {
    name: string;
  };
  folder: {
    name: string;
  };
  sample: {
    name: string;
  };
  event: {
    name: string;
  };
  created_at: string;
}

const Projects: React.FC<IProjectsProps> = ({ erp_client_code }) => {
  const [page, setPage] = useState(0);
  const [pageFilter, setPageFilter] = useState(0);
  const [filter, setFilter] = useState('');
  const [filterHelper, setFilterHelper] = useState('');
  const [refresh, setRefresh] = useState(true);
  const [projects, setProjects] = useState<IProject[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [nextDisabled, setNextDisabled] = useState(true);

  useEffect(() => {
    if (refresh && erp_client_code) {
      setRefresh(false);
      let pageSearch = 0;
      let foundData = false;

      if (filter !== filterHelper) {
        setPage(0);
        setPageFilter(0);
      }

      setFilterHelper(filter);

      if (filter !== '') {
        pageSearch = pageFilter;
      } else {
        pageSearch = page;
      }

      api
        .get(
          `/projects/clientcode?erp_client_code=${erp_client_code}&page=${pageSearch}&filter=${filter}`,
        )
        .then(firstResponse => {
          if (firstResponse.data.length > 0) {
            foundData = true;
            setProjects(firstResponse.data);
            setLoading(false);
          }

          if (!foundData) {
            if (pageSearch - 1 >= 0) {
              pageSearch -= 1;
              if (filter !== '') {
                setPageFilter(pageFilter - 1);
              } else {
                setPage(page - 1);
              }
            }

            api
              .get(
                `/projects/clientcode?erp_client_code=${erp_client_code}&page=${pageSearch}&filter=${filter}`,
              )
              .then(secondResponse => {
                setProjects(secondResponse.data);
                setLoading(false);

                api
                  .get(
                    `/projects/clientcode?erp_client_code=${erp_client_code}&page=${
                      pageSearch + 1
                    }
                    &filter=${filter}`,
                  )
                  .then(thirdResponse => {
                    setNextDisabled(false);
                    if (thirdResponse.data.length === 0) {
                      setNextDisabled(true);
                    }
                  });
              });
          } else {
            api
              .get(
                `/projects/clientcode?erp_client_code=${erp_client_code}&page=${
                  pageSearch + 1
                }
                &filter=${filter}`,
              )
              .then(fourthResponse => {
                setNextDisabled(false);
                if (fourthResponse.data.length === 0) {
                  setNextDisabled(true);
                }
              });
          }
        });
    }
  }, [page, pageFilter, filter, filterHelper, refresh, erp_client_code]);

  function handlePage(type: string): void {
    if (type === 'backward') {
      if (filter !== '') {
        setPageFilter(pageFilter - 1);
      } else {
        setPage(page - 1);
      }
    } else if (type === 'forward') {
      if (filter !== '') {
        setPageFilter(pageFilter + 1);
      } else {
        setPage(page + 1);
      }
    } else if (filter !== '') {
      setPageFilter(0);
    } else {
      setPage(0);
    }
    setRefresh(true);
  }

  function handlePrevButtonDisabled(): boolean {
    let validationPage = 0;

    if (filter !== '') {
      validationPage = pageFilter;
    } else {
      validationPage = page;
    }

    if (validationPage === 0) {
      return true;
    }

    return false;
  }

  function handleFilterChange(value: string): void {
    setFilter(value);
    setRefresh(true);
  }

  function getProjectType(projectReceived: IProject): string {
    if (projectReceived.type === 'DISPLAY') {
      return 'Display';
    }
    if (projectReceived.type === 'MATERIAL') {
      return 'Material';
    }
    if (projectReceived.type === 'PATROCINIO') {
      return 'Pagamento';
    }
    if (projectReceived.type === 'ADVERSIDADE') {
      return 'Problema';
    }
    if (projectReceived.type === 'PASTA') {
      return 'Pasta';
    }
    if (projectReceived.type === 'AMOSTRA') {
      return 'Amostra';
    }
    if (projectReceived.type === 'EVENTO') {
      return 'Evento';
    }

    return '';
  }

  function getProjectItemName(projectReceived: IProject): string {
    if (projectReceived.type === 'DISPLAY') {
      return projectReceived.display.name;
    }
    if (projectReceived.type === 'MATERIAL') {
      return projectReceived.material.name;
    }
    if (projectReceived.type === 'PATROCINIO') {
      return projectReceived.sponsorship.name;
    }
    if (projectReceived.type === 'ADVERSIDADE') {
      return projectReceived.adversity.name;
    }
    if (projectReceived.type === 'PASTA') {
      return projectReceived.folder.name;
    }
    if (projectReceived.type === 'AMOSTRA') {
      return projectReceived.sample.name;
    }
    if (projectReceived.type === 'EVENTO') {
      return projectReceived.event.name;
    }

    return '';
  }

  function getViewPath(projectReceived: IProject): string {
    if (projectReceived.type === 'DISPLAY') {
      return `/projects/displays/view`;
    }
    if (projectReceived.type === 'MATERIAL') {
      return `/projects/materials/view`;
    }
    if (projectReceived.type === 'PATROCINIO') {
      return `/projects/sponsorships/view`;
    }
    if (projectReceived.type === 'ADVERSIDADE') {
      return `/projects/adversities/view`;
    }
    if (projectReceived.type === 'PASTA') {
      return `/projects/folders/view`;
    }
    if (projectReceived.type === 'AMOSTRA') {
      return `/projects/samples/view`;
    }
    if (projectReceived.type === 'EVENTO') {
      return `/projects/events/view`;
    }

    return '';
  }

  function handleTable(): React.ReactNode {
    if (projects) {
      if (projects.length > 0) {
        return (
          <Table>
            <thead>
              <tr>
                <Th width="20%" textAlign="left">
                  Data Abertura
                </Th>
                <Th width="20%" textAlign="left">
                  Tipo
                </Th>
                <Th width="15%" textAlign="left">
                  Código
                </Th>
                <Th width="20%" textAlign="left">
                  Item
                </Th>
                <Th width="15%" textAlign="center">
                  Situação
                </Th>
                <Th width="10%" textAlign="center" />
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <Td colSpan={4} padding="1rem" textAlign="center">
                    <PropagateLoader color="#276c8d" />
                  </Td>
                </tr>
              )}
              {projects?.map(project => (
                <tr key={project.id}>
                  <Td width="20%" textAlign="left" heading="DATA ABERTURA">
                    {new Date(project.created_at).toLocaleDateString()}
                  </Td>
                  <Td width="20%" textAlign="left" heading="TIPO">
                    {getProjectType(project)}
                  </Td>
                  <Td width="15%" textAlign="left" heading="CÓDIGO">
                    {project.code}
                  </Td>
                  <Td width="20%" textAlign="left" heading="ITEM">
                    {getProjectItemName(project)}
                  </Td>
                  <Td width="15%" textAlign="center" heading="SITUAÇÃO">
                    {project.status}
                  </Td>
                  <Td
                    width="10%"
                    textAlign="center"
                    display="flex"
                    heading={' '}
                  >
                    <Link
                      to={{
                        pathname: getViewPath(project),
                        state: project,
                      }}
                    >
                      <ViewButton />
                    </Link>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        );
      }
    }

    return (
      <Table>
        <thead>
          <tr>
            <Th width="100%" textAlign="center" />
          </tr>
        </thead>
        <tbody>
          <tr>
            <Td width="100%" textAlign="center">
              Nenhuma informação disponível
            </Td>
          </tr>
        </tbody>
      </Table>
    );
  }

  if (!erp_client_code) {
    return null;
  }

  return (
    <Container>
      <Accordion>
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Label>Solicitações</Label>
        </AccordionSummary>

        <AccordionDetails>
          <Wrapper>
            <form onSubmit={() => {}}>
              <div
                style={{
                  display: 'flex',
                  maxWidth: 680,
                }}
              >
                <Input
                  name="search"
                  labelText="Pesquisar"
                  type="text"
                  onChange={event => handleFilterChange(event.target.value)}
                />
              </div>
            </form>

            {handleTable()}

            <ButtonsContent>
              <Paginator>
                <PrevButton
                  disabled={handlePrevButtonDisabled()}
                  onClick={() => handlePage('backward')}
                />
                <CenterButton onClick={() => handlePage('reset')} />
                <NextButton
                  disabled={nextDisabled}
                  onClick={() => handlePage('forward')}
                />
              </Paginator>
            </ButtonsContent>
          </Wrapper>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default Projects;
