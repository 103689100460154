import React from 'react';

import {
  FaHome,
  FaProjectDiagram,
  FaBoxes,
  FaStore,
  FaUser,
  FaCogs,
  FaTools,
} from 'react-icons/fa';

export default {
  Início: {
    icon: <FaHome />,
    link: '/dashboard',
  },
  Solicitações: {
    icon: <FaProjectDiagram />,
    link: '/projects/main',
  },
  Itens: {
    icon: <FaBoxes />,
    link: '/items/main',
  },
  Lojas: {
    icon: <FaStore />,
    link: '/storerelationships',
  },
  Usuários: {
    icon: <FaUser />,
    link: '/users',
  },
  Configurações: {
    icon: <FaCogs />,
    link: '/configurations/main',
  },
  Administração: {
    icon: <FaTools />,
    link: '/management/main',
  },
} as const;
