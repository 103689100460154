import React, { Dispatch, SetStateAction } from 'react';
import { FcCancel } from 'react-icons/fc';
import { Dialog } from '@material-ui/core';

import DialogOptionButton from '~/components/Buttons/DialogOption';

import {
  Container,
  IconContainer,
  Title,
  SubTitle,
  ButtonContainer,
} from './styles';

interface INoticeProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  type: string;
  title: string;
  subTitle: string;
}

const Notice: React.FC<INoticeProps> = ({
  isOpen,
  setIsOpen,
  type,
  title,
  subTitle,
}: INoticeProps) => {
  const handleClose = (): void => {
    setIsOpen(false);
  };

  function getIcon(): JSX.Element {
    if (type === 'error') {
      return <FcCancel size={60} color="#c9aa71" />;
    }

    return <></>;
  }

  return (
    <Dialog open={isOpen}>
      <Container>
        <IconContainer>{getIcon()}</IconContainer>

        <Title>{title}</Title>

        <SubTitle>{subTitle}</SubTitle>

        <ButtonContainer>
          <DialogOptionButton label="Fechar" onClick={() => handleClose()} />
        </ButtonContainer>
      </Container>
    </Dialog>
  );
};

export default Notice;
