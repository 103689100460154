import light from './light';

const dark: typeof light = {
  ...light,
  colors: {
    ...light.colors,
    grey: '#2e2e2e',
    lightGrey: '#616161',
    darkGrey: '#f3f3f3',
    background: '#272727',
    backgroundContainer: '#363636',
    sideBar: '#312721',
    sideBarLight: '#5f4b40',
    labelCard: '#dfdfdf',
  },
};

export default dark;
