import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { shade } from 'polished';

import Button from '~/components/Button';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2.4rem;
  position: relative;
  cursor: pointer;
`;

export const AnimatedDropdown = styled(motion.nav)`
  ${({ theme }) => css`
    position: absolute;
    z-index: 999;
    top: 128%;
    right: 0;
    min-width: 14.6rem;
    border-radius: ${theme.radius.small};
    background: ${theme.colors.lightGrey};
    box-shadow: 0 0.6rem 0.6rem rgba(0, 0, 0, 0.12);

    ::before {
      content: '';
      display: block;
      position: absolute;
      top: -33%;
      right: 18%;
      border-color: ${`transparent transparent ${theme.colors.lightGrey} transparent `};
      border-style: solid;
      border-width: 0.8rem;
    }
  `}
`;

export const NavButton = styled(Button)`
  ${({ theme }) => css`
    width: 100%;
    padding: 1.2rem 1.6rem;
    justify-content: space-between;
    font-size: ${theme.fontSizes.default};
    background: ${theme.colors.lightGrey};
    color: ${theme.colors.grey};

    :hover {
      background: ${shade(0.2, theme.colors.darkGrey)};
      transition: 0.2s;
    }

    :first-child {
      border-radius: ${theme.radius.small};
    }
    :last-child {
      border-radius: ${theme.radius.small};
    }
  `}
`;

export const UserAccount = styled.div`
  ${({ theme }) => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem 1rem;
    border-radius: ${theme.radius.default};
    background: ${theme.colors.lightGrey};
    box-shadow: 0 0.6rem 0.6rem rgba(0, 0, 0, 0.12);

    &:hover {
      background: ${shade(0.2, theme.colors.darkGrey)};
      transition: 0.2s;
    }
  `}
`;

export const UserAccountName = styled.div`
  ${({ theme }) => css`
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    font-size: ${theme.fontSizes.large};
    color: ${theme.colors.darkGrey};
  `}
`;
