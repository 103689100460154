import React from 'react';

import InfoInput from '~/components/Inputs/Info';

import { Container, Content } from './styles';

interface IEventDateProps {
  event_date: Date;
}

const EventDate: React.FC<IEventDateProps> = ({ event_date }) => {
  return (
    <Container>
      <Content>
        <InfoInput
          name="event_date"
          labelFor="event_date"
          labelText="Data Evento Oficial"
          type="text"
          defaultValue={new Date(event_date).toLocaleDateString()}
          disabled
        />
      </Content>
    </Container>
  );
};

export default EventDate;
