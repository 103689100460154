import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { FaProjectDiagram, FaBoxOpen } from 'react-icons/fa';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import BreadCrumb from '~/components/BreadCrumb';
import ProjectInfo from '~/components/ProjectInfo/Default';
import CompleteIcon from '~/components/TimelineElements/CompleteIcon';
import PendingIcon from '~/components/TimelineElements/PendingIcon';
import Title from '~/components/TimelineElements/Title';
import Topic from '~/components/TimelineElements/Topic';
import Gradient from '~/components/TimelineElements/Gradient';
import DetailTitle from '~/components/TimelineElements/DetailTitle';
import DetailProcedure from '~/components/TimelineElements/DetailProcedure';
import DetailInfo from '~/components/TimelineElements/DetailInfo';
import Code from '~/components/TimelineItems/Code';
import Representative from '~/components/TimelineItems/Representative';
import Client from '~/components/TimelineItems/Client';
import Item from '~/components/TimelineItems/Item';
import ClientContact from '~/components/TimelineItems/ClientContact';
import TotalAmount from '~/components/TimelineItems/TotalAmount';
import SentAmount from '~/components/TimelineItems/SentAmount';
import PendingAmount from '~/components/TimelineItems/PendingAmount';
import Observation from '~/components/TimelineItems/Observation';
import Records from '~/components/TimelineItems/Records';
import Files from '~/components/TimelineItems/Files';
import RequestFiles from '~/components/TimelineItems/RequestFiles';
import TotalPrice from '~/components/TimelineItems/TotalPrice';
import Validation from '~/components/TimelineItems/Validation';
import RecordNote from '~/components/TimelineItems/RecordNote';
import SentInvoices from '~/components/TimelineItems/SentInvoices';
import Labels from '~/components/TimelineItems/Labels';
import RecordNotes from '~/components/TimelineItems/RecordNotes';
import Completion from '~/components/TimelineItems/Completion';
import ExitButton from '~/components/Buttons/Exit';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import {
  Container,
  TabTitle,
  ProcedureContent,
  MainContent,
  TitleContent,
  TitleTextWrapper,
  InfoContent,
  DetailContent,
  DetailItemWrapper,
  ButtonContainer,
} from './styles';

interface IDetail {
  procedure: string;
  information: string;
  component: string;
}

interface ILocation {
  id: string;
  type: string;
  code: string;
  price: number;
  total_price: number;
  item_total_amount: number;
  item_sent_amount: number;
  item_pending_amount: number;
  rep_as_client: boolean;
  erp_client_code: string;
  erp_rep_code: string;
  note: string;
  status: string;
  client_contact_name: string;
  client_contact_phone: string;
  client_contact_email: string;
  sample: {
    code: string;
    name: string;
  };
  project_procedure: {
    sequence: number;
  };
}

const View: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [tabIndex, setTabIndex] = useState(0);
  const [detail, setDetail] = useState<IDetail>();

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto não localizado',
      });

      history.push('/projects/samples');
    } else if (
      user.user_type.type === 'REP' &&
      state.erp_rep_code !== user.erp_rep_code
    ) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Usuário não permitido',
      });

      history.push('/projects/samples');
    }
  }, [addToast, history, state, user]);

  function handleFiles(
    procedure: string,
    information: string,
    component: string,
  ): JSX.Element | null {
    if (user.user_type.type !== 'REP') {
      return (
        <Topic
          label="- Anexos"
          onClick={() => {
            setDetail({ procedure, information, component });
          }}
        />
      );
    }

    return null;
  }

  function handleCompletion(
    procedure: string,
    information: string,
    component: string,
  ): JSX.Element | null {
    if (state.status === 'Finalizado') {
      return (
        <Topic
          label="- Finalização"
          onClick={() => {
            setDetail({ procedure, information, component });
          }}
        />
      );
    }

    return null;
  }

  function handleProcedure1(): JSX.Element | null {
    if (
      state?.project_procedure &&
      state.project_procedure.sequence &&
      state.project_procedure.sequence > 1
    ) {
      return (
        <MainContent>
          <TitleContent>
            <CompleteIcon />

            <TitleTextWrapper>
              <Title label="Solicitação" />
            </TitleTextWrapper>
          </TitleContent>

          <InfoContent>
            <Gradient height="550px" />

            <div>
              <Topic
                label="- Código"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Código',
                    component: '01_code',
                  });
                }}
              />

              <Topic
                label="- Representante"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Representante',
                    component: '01_representative',
                  });
                }}
              />

              <Topic
                label="- Cliente"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Cliente',
                    component: '01_client',
                  });
                }}
              />

              <Topic
                label="- Item"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Item',
                    component: '01_item',
                  });
                }}
              />

              <Topic
                label="- Contato"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Contato',
                    component: '01_client_contact',
                  });
                }}
              />

              <Topic
                label="- Qtd. Total"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Qtd. Total',
                    component: '01_total_amount',
                  });
                }}
              />

              <Topic
                label="- Qtd. Enviada"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Qtd. Enviada',
                    component: '01_sent_amount',
                  });
                }}
              />

              <Topic
                label="- Qtd. Pendente"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Qtd. Pendente',
                    component: '01_pending_amount',
                  });
                }}
              />

              <Topic
                label="- Observação"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Observação',
                    component: '01_obs',
                  });
                }}
              />

              <Topic
                label="- Histórico"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Histórico',
                    component: '01_records',
                  });
                }}
              />

              {handleFiles('Solicitação', 'Anexos', '01_files')}

              <Topic
                label="- Anexos de Requisição"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Anexos de Requisição',
                    component: '01_request_files',
                  });
                }}
              />
            </div>
          </InfoContent>
        </MainContent>
      );
    }

    return null;
  }

  function handleProcedure2(): JSX.Element | null {
    if (
      state?.project_procedure &&
      state.project_procedure.sequence &&
      (state.project_procedure.sequence > 2 ||
        (state.project_procedure.sequence === 2 &&
          state.status === 'Cancelado'))
    ) {
      return (
        <MainContent>
          <TitleContent>
            <CompleteIcon />

            <TitleTextWrapper>
              <Title label="Aprovação" />
            </TitleTextWrapper>
          </TitleContent>

          <InfoContent>
            <Gradient height="400px" />

            <div>
              <Topic
                label="- Valor Total"
                onClick={() => {
                  setDetail({
                    procedure: 'Aprovação',
                    information: 'Valor Total',
                    component: '02_total_price',
                  });
                }}
              />

              <Topic
                label="- Validação"
                onClick={() => {
                  setDetail({
                    procedure: 'Aprovação',
                    information: 'Validação',
                    component: '02_validation',
                  });
                }}
              />

              <Topic
                label="- Observação"
                onClick={() => {
                  setDetail({
                    procedure: 'Aprovação',
                    information: 'Observação',
                    component: '02_obs',
                  });
                }}
              />

              <Topic
                label="- Histórico"
                onClick={() => {
                  setDetail({
                    procedure: 'Aprovação',
                    information: 'Histórico',
                    component: '02_records',
                  });
                }}
              />

              {handleFiles('Aprovação', 'Anexos', '02_files')}
            </div>
          </InfoContent>
        </MainContent>
      );
    }

    return null;
  }

  function handleProcedure3(): JSX.Element | null {
    if (
      state?.project_procedure &&
      state.project_procedure.sequence &&
      state.project_procedure.sequence === 3 &&
      state.item_sent_amount > 0
    ) {
      return (
        <MainContent>
          <TitleContent>
            <CompleteIcon />

            <TitleTextWrapper>
              <Title label="Produção/Pedido/Faturamento" />
            </TitleTextWrapper>
          </TitleContent>

          <InfoContent>
            <Gradient height="400px" />

            <div>
              <Topic
                label="- Notas Fiscais Enviadas"
                onClick={() => {
                  setDetail({
                    procedure: 'Produção/Pedido/Faturamento',
                    information: 'Notas Fiscais Enviadas',
                    component: '03_sent_invoices',
                  });
                }}
              />

              <Topic
                label="- Etiquetas"
                onClick={() => {
                  setDetail({
                    procedure: 'Produção/Pedido/Faturamento',
                    information: 'Etiquetas',
                    component: '03_labels',
                  });
                }}
              />

              <Topic
                label="- Qtd. Total"
                onClick={() => {
                  setDetail({
                    procedure: 'Produção/Pedido/Faturamento',
                    information: 'Qtd. Total',
                    component: '03_total_amount',
                  });
                }}
              />

              <Topic
                label="- Atualização Qtd. Enviada"
                onClick={() => {
                  setDetail({
                    procedure: 'Produção/Pedido/Faturamento',
                    information: 'Qtd. Enviada',
                    component: '03_sent_amount',
                  });
                }}
              />

              <Topic
                label="- Atualização Qtd. Pendente"
                onClick={() => {
                  setDetail({
                    procedure: 'Produção/Pedido/Faturamento',
                    information: 'Qtd. Pendente',
                    component: '03_pending_amount',
                  });
                }}
              />

              <Topic
                label="- Observações"
                onClick={() => {
                  setDetail({
                    procedure: 'Produção/Pedido/Faturamento',
                    information: 'Observações',
                    component: '03_notes',
                  });
                }}
              />

              <Topic
                label="- Histórico"
                onClick={() => {
                  setDetail({
                    procedure: 'Produção/Pedido/Faturamento',
                    information: 'Histórico',
                    component: '03_records',
                  });
                }}
              />

              {handleFiles('Produção/Pedido/Faturamento', 'Anexos', '03_files')}

              {handleCompletion(
                'Produção/Pedido/Faturamento',
                'Finalização',
                '03_completion',
              )}
            </div>
          </InfoContent>
        </MainContent>
      );
    }

    return null;
  }

  function handleCurrentProcedure(): JSX.Element | null {
    let title = '';

    if (
      state?.project_procedure &&
      state.project_procedure.sequence === 2 &&
      state.status === 'Pendente'
    ) {
      title = 'Aprovação';
    }

    if (
      state?.project_procedure &&
      state.project_procedure.sequence === 3 &&
      state.item_sent_amount <= 0
    ) {
      title = 'Produção/Pedido/Faturamento';
    }

    if (title !== '') {
      return (
        <TitleContent>
          <PendingIcon />

          <TitleTextWrapper>
            <Title label={title} highlighted />
          </TitleTextWrapper>
        </TitleContent>
      );
    }

    return null;
  }

  function handleDetailTitle(): JSX.Element | null {
    if (detail?.procedure || detail?.information) {
      return <DetailTitle />;
    }

    return null;
  }

  function handleDetailProcedure(): JSX.Element | null {
    if (detail?.procedure) {
      return <DetailProcedure label={detail.procedure} />;
    }

    return null;
  }

  function handleDetailInformation(): JSX.Element | null {
    if (detail?.information) {
      return <DetailInfo label={detail.information} />;
    }

    return null;
  }

  function handleDetailComponent(): JSX.Element | null {
    if (detail?.component === '01_code') {
      return <Code code={state?.code ? state.code : ''} />;
    }

    if (detail?.component === '01_representative') {
      return (
        <Representative
          erp_rep_code={state?.erp_rep_code ? state.erp_rep_code : ''}
        />
      );
    }

    if (detail?.component === '01_client') {
      return (
        <Client
          rep_as_client={state.rep_as_client}
          erp_client_code={state?.erp_client_code ? state.erp_client_code : ''}
          erp_rep_code={state?.erp_rep_code ? state.erp_rep_code : ''}
        />
      );
    }

    if (detail?.component === '01_item') {
      return (
        <Item
          code={state?.sample.code ? state.sample.code : ''}
          name={state?.sample.name ? state.sample.name : ''}
        />
      );
    }

    if (detail?.component === '01_client_contact') {
      return (
        <ClientContact
          client_contact_name={
            state?.client_contact_name ? state.client_contact_name : ''
          }
          client_contact_phone={
            state?.client_contact_phone ? state.client_contact_phone : ''
          }
          client_contact_email={
            state?.client_contact_email ? state.client_contact_email : ''
          }
        />
      );
    }

    if (detail?.component === '01_total_amount') {
      return (
        <TotalAmount
          total_amount={state?.item_total_amount ? state.item_total_amount : 0}
        />
      );
    }

    if (detail?.component === '01_sent_amount') {
      return <SentAmount sent_amount={0} />;
    }

    if (detail?.component === '01_pending_amount') {
      return (
        <PendingAmount
          pending_amount={
            state?.item_total_amount ? state.item_total_amount : 0
          }
        />
      );
    }

    if (detail?.component === '01_obs') {
      return <Observation note={state?.note ? state.note : ''} />;
    }

    if (detail?.component === '01_records') {
      return <Records project_id={state?.id} type="AMOSTRA" sequence={1} />;
    }

    if (detail?.component === '01_files') {
      return <Files project_id={state?.id} type="AMOSTRA" sequence={1} />;
    }

    if (detail?.component === '01_request_files') {
      return <RequestFiles project_id={state.id} />;
    }

    if (detail?.component === '02_total_price') {
      return <TotalPrice total_price={state?.price ? state.price : 0} />;
    }

    if (detail?.component === '02_validation') {
      return <Validation type="AMOSTRA" sequence={2} project_id={state.id} />;
    }

    if (detail?.component === '02_obs') {
      return <RecordNote type="AMOSTRA" sequence={2} project_id={state.id} />;
    }

    if (detail?.component === '02_records') {
      return <Records project_id={state?.id} type="AMOSTRA" sequence={2} />;
    }

    if (detail?.component === '02_files') {
      return <Files project_id={state?.id} type="AMOSTRA" sequence={2} />;
    }

    if (detail?.component === '03_sent_invoices') {
      return <SentInvoices project_id={state?.id} />;
    }

    if (detail?.component === '03_labels') {
      return <Labels project_id={state.id} />;
    }

    if (detail?.component === '03_total_amount') {
      return (
        <TotalAmount
          total_amount={state?.item_total_amount ? state.item_total_amount : 0}
        />
      );
    }

    if (detail?.component === '03_sent_amount') {
      return (
        <SentAmount
          sent_amount={state?.item_sent_amount ? state.item_sent_amount : 0}
        />
      );
    }

    if (detail?.component === '03_pending_amount') {
      return (
        <PendingAmount
          pending_amount={
            state?.item_pending_amount ? state.item_pending_amount : 0
          }
        />
      );
    }

    if (detail?.component === '03_notes') {
      return <RecordNotes type="AMOSTRA" sequence={3} project_id={state.id} />;
    }

    if (detail?.component === '03_records') {
      return <Records project_id={state?.id} type="AMOSTRA" sequence={3} />;
    }

    if (detail?.component === '03_files') {
      return <Files project_id={state?.id} type="AMOSTRA" sequence={3} />;
    }

    if (detail?.component === '03_completion') {
      return <Completion type="AMOSTRA" sequence={3} project_id={state.id} />;
    }

    return null;
  }

  function handleProcedure1Detail(): JSX.Element | null {
    if (detail?.procedure === 'Solicitação') {
      return (
        <DetailContent>
          {handleDetailTitle()}

          {handleDetailProcedure()}

          {handleDetailInformation()}

          <DetailItemWrapper>{handleDetailComponent()}</DetailItemWrapper>
        </DetailContent>
      );
    }

    return null;
  }

  function handleProcedure2Detail(): JSX.Element | null {
    if (detail?.procedure === 'Aprovação') {
      return (
        <DetailContent>
          {handleDetailTitle()}

          {handleDetailProcedure()}

          {handleDetailInformation()}

          <DetailItemWrapper>{handleDetailComponent()}</DetailItemWrapper>
        </DetailContent>
      );
    }

    return null;
  }

  function handleProcedure3Detail(): JSX.Element | null {
    if (detail?.procedure === 'Produção/Pedido/Faturamento') {
      return (
        <DetailContent>
          {handleDetailTitle()}

          {handleDetailProcedure()}

          {handleDetailInformation()}

          <DetailItemWrapper>{handleDetailComponent()}</DetailItemWrapper>
        </DetailContent>
      );
    }

    return null;
  }

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/samples"
        firstChild="Amostras"
        secondChild="Visualização Solicitação"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaBoxOpen}
        secondChildIcon={FaBoxOpen}
        mainColored
        firstChildColored
      />

      <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <TabList>
          <Tab>
            <TabTitle>Linha do Tempo</TabTitle>
          </Tab>
          <Tab>
            <TabTitle>Geral</TabTitle>
          </Tab>
        </TabList>

        <TabPanel>
          <ProcedureContent>
            {handleProcedure1()}
            {handleProcedure1Detail()}
          </ProcedureContent>

          <ProcedureContent>
            {handleProcedure2()}
            {handleProcedure2Detail()}
          </ProcedureContent>

          <ProcedureContent>
            {handleProcedure3()}
            {handleProcedure3Detail()}
          </ProcedureContent>

          {handleCurrentProcedure()}
        </TabPanel>

        <TabPanel>
          <ProjectInfo project_id={state.id} all />
        </TabPanel>
      </Tabs>
      <ButtonContainer>
        <Link
          to={{
            pathname: '/projects/samples',
          }}
        >
          <ExitButton type="button" />
        </Link>
      </ButtonContainer>
    </Container>
  );
};

export default View;
