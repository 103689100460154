import React from 'react';

import { Container } from './styles';

interface ITitleProps {
  label: string;
  highlighted?: boolean;
}

const Title: React.FC<ITitleProps> = ({ label, highlighted }) => {
  return <Container highlighted={highlighted}>{label}</Container>;
};

export default Title;
