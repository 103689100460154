import styled, { css } from 'styled-components';
import Modal from 'react-modal';

interface IContentProps {
  maxWidth: string;
}

interface IWrapperProps {
  marginRight?: string;
}

export const StyledModal = styled(Modal)`
  ${({ theme }) => css`
    margin-top: 10%;
    margin-left: 50%;
    transform: translate(-50%, -10%);
    background-color: ${theme.colors.background};
    box-shadow: ${theme.shadows.veryStrong};
    border-radius: ${theme.radius.meddium};
    width: 100%;
    max-width: 680px;

    @media (max-width: 1010px) {
      margin-top: 0;
      margin-left: 0;
      transform: translate(0%, 0%);
      margin: auto;
    }
  `}
`;

export const MainContainer = styled.div`
  padding: 1rem 1rem 1rem 1rem;
`;

export const Title = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.large};
  `}
`;

export const Container = styled.section`
  ${({ theme }) => css`
    width: 100%;
    background: ${theme.colors.backgroundContainer};
    border-radius: ${theme.radius.small};
    border: 1px solid #e4e4e4;
    margin-bottom: 2rem;
    padding: 2rem 2rem 2rem 2rem;
    max-width: 680px;
  `}
`;

export const Content = styled.div<IContentProps>`
  ${({ maxWidth }) => css`
    display: flex;
    justify-content: flex-start;

    ${maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
  `}
`;

export const ContentSplit = styled(Content)`
  @media (max-width: 1010px) {
    flex-direction: column;
  }
`;

export const Wrapper = styled.div<IWrapperProps>`
  ${({ marginRight }) => css`
    width: 100%;
    margin-bottom: 2rem;

    ${marginRight &&
    css`
      margin-right: ${marginRight};
    `}

    @media (max-width: 1010px) {
      margin: 0 0 2rem 0;
    }
  `}
`;

export const HalfWrapper = styled(Wrapper)`
  width: 50%;

  @media (max-width: 1010px) {
    width: 100%;
  }
`;

export const OneThirdWrapper = styled(Wrapper)`
  width: 33%;

  @media (max-width: 1010px) {
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;
