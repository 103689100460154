import React from 'react';

import InfoInput from '~/components/Inputs/Info';

import { Container, Content } from './styles';

interface ITotalPriceProps {
  total_price?: number;
}

const TotalPrice: React.FC<ITotalPriceProps> = ({ total_price }) => {
  return (
    <Container>
      <Content>
        <InfoInput
          name="total_price"
          labelFor="total_price"
          labelText="Valor Total"
          type="text"
          defaultValue={
            total_price
              ? new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(total_price)
              : new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(0)
          }
          disabled
        />
      </Content>
    </Container>
  );
};

export default TotalPrice;
