import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { FaAngleDown } from 'react-icons/fa';

import InfoInput from '~/components/Inputs/Info';

import { MainContainer, Label, Container, Content, Wrapper } from './styles';

interface IAmountProps {
  total_amount?: number;
  sent_amount?: number;
  pending_amount?: number;
}

const Amount: React.FC<IAmountProps> = ({
  total_amount,
  sent_amount,
  pending_amount,
}) => {
  return (
    <MainContainer>
      <Accordion>
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Label>Quantidades</Label>
        </AccordionSummary>

        <AccordionDetails>
          <Container>
            <Content>
              <Wrapper style={{ width: '33%', marginRight: '1rem' }}>
                <InfoInput
                  name="total_amount"
                  labelFor="total_amount"
                  labelText="Qtd. Total"
                  type="text"
                  defaultValue={
                    total_amount
                      ? new Intl.NumberFormat('pt-BR').format(total_amount)
                      : new Intl.NumberFormat('pt-BR').format(0)
                  }
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '33%', marginRight: '1rem' }}>
                <InfoInput
                  name="sent_amount"
                  labelFor="sent_amount"
                  labelText="Qtd. Enviada"
                  type="text"
                  defaultValue={
                    sent_amount
                      ? new Intl.NumberFormat('pt-BR').format(sent_amount)
                      : new Intl.NumberFormat('pt-BR').format(0)
                  }
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '33%' }}>
                <InfoInput
                  name="pending_amount"
                  labelFor="pending_amount"
                  labelText="Qtd. Pendente"
                  type="text"
                  defaultValue={
                    pending_amount
                      ? new Intl.NumberFormat('pt-BR').format(pending_amount)
                      : new Intl.NumberFormat('pt-BR').format(0)
                  }
                  disabled
                />
              </Wrapper>
            </Content>
          </Container>
        </AccordionDetails>
      </Accordion>
    </MainContainer>
  );
};

export default Amount;
