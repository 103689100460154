import styled, { css } from 'styled-components';

interface IContentProps {
  maxWidth: string;
}

export const Container = styled.section`
  ${({ theme }) => css`
    width: 100%;
    min-height: 300px;
    margin: 2.4rem 0;
    padding: 2.4rem;
    background: ${theme.colors.background};
    border-radius: ${theme.radius.default};
    box-shadow: ${theme.shadows.flat};
  `}
`;

export const Content = styled.div<IContentProps>`
  ${({ maxWidth }) => css`
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    min-height: 20rem;

    ${maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
  `}
`;

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 1rem 2rem 0;

  @media (max-width: 900px) {
    margin: 0 0 2rem 0;
  }
`;

export const TwoFifthsWrapper = styled(Wrapper)`
  width: 40%;
`;

export const ThreeFifthsWrapper = styled(Wrapper)`
  width: 60%;
`;
