import styled, { css } from 'styled-components';
import { shade } from 'polished';

import { IButtonProps } from '.';

export const Container = styled.button<IButtonProps>`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.colors.secondary};
    padding: 1.2rem 6rem;
    border-radius: ${theme.radius.default};
    color: #fff;
    font-size: ${theme.fontSizes.large};
    font-weight: 400;

    :hover {
      background: ${shade(0.2, theme.colors.secondary)};
      color: #fff;
      transition: 0.2s;
    }

    :disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  `}
`;
