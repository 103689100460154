import styled, { css } from 'styled-components';

interface IContainerProps {
  isFocused: boolean;
  isErrored?: boolean;
}

interface ILabelProps {
  isFocused: boolean;
  isErrored?: boolean;
}

export const Label = styled.label<ILabelProps>`
  ${({ theme, isFocused }) => css`
    font-size: ${theme.fontSizes.label};
    color: ${theme.colors.labelInput};

    ${isFocused &&
    css`
      color: ${theme.colors.primary};
    `}
  `}
`;

export const Container = styled.div<IContainerProps>`
  ${({ theme, isFocused, isErrored }) => css`
    width: 100%;
    display: flex;
    position: relative;
    background: ${theme.colors.lightGrey};
    border-radius: ${theme.radius.small};
    padding: 10px;
    border: 1px solid ${theme.colors.inputBorder};
    align-items: center;

    ${isFocused &&
    css`
      border: 1px solid ${theme.colors.primary};
      color: ${theme.colors.primary};
    `}

    ${isErrored &&
    css`
      border: 1px solid ${theme.colors.error};
    `}

    input {
      flex: 1;
      border: 0;
      background: transparent;
      font-size: 1.6rem;
    }
  `}
`;
