import React from 'react';

import Representative from '~/components/ProjectSections/Representative';
import Client from '~/components/ProjectSections/Client';
import ClientPatterns from '~/components/ProjectSections/ClientPatterns';

interface INewProps {
  rep_as_client: boolean;
  erp_rep_code?: string;
  erp_client_code?: string;
}

const New: React.FC<INewProps> = ({
  rep_as_client,
  erp_rep_code,
  erp_client_code,
}) => {
  function handleRepresentative(): React.ReactNode {
    return (
      <Representative
        erp_rep_code={erp_rep_code !== undefined ? erp_rep_code : ''}
      />
    );
  }

  function handleClient(): React.ReactNode {
    return (
      <Client
        rep_as_client={rep_as_client}
        erp_client_code={erp_client_code !== undefined ? erp_client_code : ''}
        erp_rep_code={erp_rep_code !== undefined ? erp_rep_code : ''}
      />
    );
  }

  function handleClientPatterns(): React.ReactNode | null {
    if (erp_client_code && erp_client_code !== '') {
      return <ClientPatterns erp_client_code={erp_client_code} />;
    }

    return null;
  }

  return (
    <>
      {handleRepresentative()}
      {handleClient()}
      {handleClientPatterns()}
    </>
  );
};

export default New;
