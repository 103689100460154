/* eslint-disable no-nested-ternary */

import React, { useState, useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { FaAngleDown } from 'react-icons/fa';

import api from '~/services/api';

import InfoInput from '~/components/Inputs/Info';

import { MainContainer, Label, Container, Content, Wrapper } from './styles';

interface IClientProps {
  rep_as_client: boolean;
  erp_client_code: string;
  erp_rep_code: string;
  default_expanded?: boolean;
}

interface IClient {
  code: string;
  name: string;
  company_name: string;
  document: string;
  address: string;
  city: string;
  state: string;
  sales_channel_code: string;
}

interface IStoreRelationship {
  company_name: string;
  trade_name: string;
  address: string;
  city: string;
  state: string;
}

interface IClientGroup {
  description: string;
}

interface ISalesChannel {
  code: string;
  description: string;
}

interface IRepresentative {
  code: string;
  name: string;
  abbreviated_name: string;
  document: string;
  address: string;
  city: string;
  state: string;
}

const Client: React.FC<IClientProps> = ({
  rep_as_client = false,
  erp_client_code,
  erp_rep_code,
  default_expanded,
}) => {
  const [client, setClient] = useState<IClient>();
  const [storeRelationship, setStoreRelationship] =
    useState<IStoreRelationship>();
  const [clientGroup, setClientGroup] = useState<IClientGroup>();
  const [salesChannel, setSalesChannel] = useState<ISalesChannel>();
  const [representative, setRepresentative] = useState<IRepresentative>();

  useEffect(() => {
    if (rep_as_client && erp_rep_code !== '') {
      api.get(`/representatives/code?code=${erp_rep_code}`).then(response => {
        setRepresentative(response.data);
      });
    } else if (!rep_as_client && erp_client_code !== '') {
      api.get(`/clients/code?code=${erp_client_code}`).then(response => {
        setClient(response.data);
      });

      api
        .get(`/storerelationships/client?erp_client_code=${erp_client_code}`)
        .then(response => {
          setStoreRelationship(response.data);
        });

      api
        .get(`/clientgroups/clientcode?erp_client_code=${erp_client_code}`)
        .then(response => {
          setClientGroup(response.data);
        });
    }
  }, [rep_as_client, erp_client_code, erp_rep_code]);

  useEffect(() => {
    if (client) {
      if (client.sales_channel_code) {
        api
          .get(`/saleschannels/code?code=${client.sales_channel_code}`)
          .then(response => {
            setSalesChannel(response.data);
          });
      }
    }
  }, [client]);

  return (
    <MainContainer>
      <Accordion defaultExpanded={default_expanded}>
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Label>Cliente</Label>
        </AccordionSummary>

        <AccordionDetails>
          <Container>
            <Content>
              <Wrapper style={{ width: '30%', marginRight: '1rem' }}>
                <InfoInput
                  name="code"
                  labelFor="code"
                  labelText="Código"
                  type="text"
                  defaultValue={
                    rep_as_client ? representative?.code : client?.code
                  }
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '70%' }}>
                <InfoInput
                  name="company_name"
                  labelFor="company_name"
                  labelText="Razão Social"
                  type="text"
                  defaultValue={
                    rep_as_client
                      ? representative?.name
                      : storeRelationship?.company_name &&
                        storeRelationship?.company_name !== ''
                      ? storeRelationship.company_name
                      : client?.company_name
                  }
                  disabled
                />
              </Wrapper>
            </Content>

            <Content>
              <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
                <InfoInput
                  name="trade_name"
                  labelFor="trade_name"
                  labelText="Nome Fantasia"
                  type="text"
                  defaultValue={
                    storeRelationship?.trade_name
                      ? storeRelationship.trade_name.toUpperCase()
                      : ''
                  }
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '50%' }}>
                <InfoInput
                  name="name"
                  labelFor="name"
                  labelText="Nome Abreviado"
                  type="text"
                  defaultValue={
                    rep_as_client
                      ? representative?.abbreviated_name
                      : client?.name
                  }
                  disabled
                />
              </Wrapper>
            </Content>

            <Content>
              <Wrapper style={{ width: '30%', marginRight: '1rem' }}>
                <InfoInput
                  name="document"
                  labelFor="document"
                  labelText="CNPJ"
                  type="text"
                  defaultValue={
                    rep_as_client ? representative?.document : client?.document
                  }
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '70%' }}>
                <InfoInput
                  name="address"
                  labelFor="address"
                  labelText="Endereço"
                  type="text"
                  defaultValue={
                    rep_as_client
                      ? representative?.address
                      : storeRelationship?.address &&
                        storeRelationship?.address !== ''
                      ? storeRelationship.address
                      : client?.address
                  }
                  disabled
                />
              </Wrapper>
            </Content>

            <Content>
              <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
                <InfoInput
                  name="city"
                  labelFor="city"
                  labelText="Cidade"
                  type="text"
                  defaultValue={
                    rep_as_client
                      ? representative?.city
                      : storeRelationship?.city &&
                        storeRelationship?.city !== ''
                      ? storeRelationship.city
                      : client?.city
                  }
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '50%' }}>
                <InfoInput
                  name="state"
                  labelFor="state"
                  labelText="Estado"
                  type="text"
                  defaultValue={
                    rep_as_client
                      ? representative?.state
                      : storeRelationship?.state &&
                        storeRelationship?.state !== ''
                      ? storeRelationship.state
                      : client?.state
                  }
                  disabled
                />
              </Wrapper>
            </Content>

            <Content>
              <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
                <InfoInput
                  name="sales_channel"
                  labelFor="sales_channel"
                  labelText="Canal de Venda"
                  type="text"
                  defaultValue={rep_as_client ? '' : salesChannel?.description}
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '50%' }}>
                <InfoInput
                  name="group"
                  labelFor="group"
                  labelText="Grupo"
                  type="text"
                  defaultValue={rep_as_client ? '' : clientGroup?.description}
                  disabled
                />
              </Wrapper>
            </Content>

            <Content>
              <Wrapper style={{ width: '100%' }}>
                <InfoInput
                  name="representative"
                  labelFor="representative"
                  labelText="É Representante?"
                  type="text"
                  defaultValue={rep_as_client ? 'Sim' : 'Não'}
                  disabled
                />
              </Wrapper>
            </Content>
          </Container>
        </AccordionDetails>
      </Accordion>
    </MainContainer>
  );
};

export default Client;
