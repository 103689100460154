import React from 'react';
import { FaRegFileAudio } from 'react-icons/fa';

import { Container } from './styles';

interface IAudioProps {
  iconSize?: number;
}

const Audio: React.FC<IAudioProps> = ({ iconSize }) => {
  return (
    <Container>
      <FaRegFileAudio size={iconSize !== undefined ? iconSize : 60} />
    </Container>
  );
};

export default Audio;
