import React, { ButtonHTMLAttributes } from 'react';
import { FaSave } from 'react-icons/fa';
import { ClipLoader } from 'react-spinners';

import { Container } from './styles';

interface ISaveProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  processing?: boolean;
}

const Save: React.FC<ISaveProps> = ({ label, processing = false, ...rest }) => {
  return (
    <Container {...rest}>
      {label || 'Salvar'}

      {processing ? (
        <ClipLoader color="#fff" size={20} />
      ) : (
        <FaSave size={16} />
      )}
    </Container>
  );
};

export default Save;
