import React, { Dispatch, SetStateAction } from 'react';

import InfoInput from '~/components/Inputs/Info';
import CloseButton from '~/components/Buttons/Close';

import {
  StyledModal,
  MainContainer,
  Title,
  Container,
  ContentSplit,
  HalfWrapper,
  ButtonContainer,
} from './styles';

interface ISentInvoiceDetailProps {
  isOpen: boolean;
  onClose: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  project_code: string;
  number: string;
  series: string;
  issue_date: Date;
  created_at: Date;
  net_price: number;
  amount: number;
  user_inclusion: string;
}

const SentInvoiceDetail: React.FC<ISentInvoiceDetailProps> = ({
  isOpen,
  onClose,
  setIsOpen,
  project_code,
  number,
  series,
  issue_date,
  created_at,
  net_price,
  amount,
  user_inclusion,
}: ISentInvoiceDetailProps) => {
  const handleClose = (): void => {
    setIsOpen(false);
    onClose();
  };

  return (
    <StyledModal
      isOpen={isOpen}
      style={{
        overlay: {
          zIndex: 999,
          overflowY: 'auto',
          position: 'fixed',
          background: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <MainContainer>
        <Title>Detalhes Notas Fiscais Enviadas</Title>
        <Container>
          <ContentSplit maxWidth="680px">
            <HalfWrapper marginRight="1rem">
              <InfoInput
                name="project_code"
                labelFor="project_code"
                labelText="Cód. Projeto"
                type="text"
                defaultValue={project_code}
                disabled
              />
            </HalfWrapper>

            <HalfWrapper>
              <InfoInput
                name="user_inclusion"
                labelFor="user_inclusion"
                labelText="Usuário"
                type="text"
                defaultValue={user_inclusion}
                disabled
              />
            </HalfWrapper>
          </ContentSplit>

          <ContentSplit maxWidth="680px">
            <HalfWrapper marginRight="1rem">
              <InfoInput
                name="number"
                labelFor="number"
                labelText="Número"
                type="text"
                defaultValue={number}
                disabled
              />
            </HalfWrapper>

            <HalfWrapper>
              <InfoInput
                name="series"
                labelFor="series"
                labelText="Série"
                type="text"
                defaultValue={series}
                disabled
              />
            </HalfWrapper>
          </ContentSplit>

          <ContentSplit maxWidth="680px">
            <HalfWrapper marginRight="1rem">
              <InfoInput
                name="issue_date"
                labelFor="issue_date"
                labelText="Data Emissão"
                type="text"
                defaultValue={new Date(issue_date).toLocaleDateString()}
                disabled
              />
            </HalfWrapper>

            <HalfWrapper>
              <InfoInput
                name="created_at"
                labelFor="created_at"
                labelText="Data Inclusão"
                type="text"
                defaultValue={new Date(created_at).toLocaleDateString()}
                disabled
              />
            </HalfWrapper>
          </ContentSplit>

          <ContentSplit maxWidth="680px">
            <HalfWrapper marginRight="1rem">
              <InfoInput
                name="net_price"
                labelFor="net_price"
                labelText="Valor Nota Fiscal"
                type="text"
                defaultValue={
                  net_price
                    ? new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(net_price)
                    : new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(0)
                }
                disabled
              />
            </HalfWrapper>

            <HalfWrapper>
              <InfoInput
                name="amount"
                labelFor="amount"
                labelText="Quantidade"
                type="text"
                defaultValue={
                  amount
                    ? new Intl.NumberFormat('pt-BR').format(amount)
                    : new Intl.NumberFormat('pt-BR').format(0)
                }
                disabled
              />
            </HalfWrapper>
          </ContentSplit>

          <ButtonContainer>
            <CloseButton onClick={() => handleClose()} />
          </ButtonContainer>
        </Container>
      </MainContainer>
    </StyledModal>
  );
};

export default SentInvoiceDetail;
