import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FaProjectDiagram, FaMoneyBillWave } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

import BreadCrumb from '~/components/BreadCrumb';
import ProjectInfo from '~/components/ProjectInfo/Default';
import UploadApprovalFiles from '~/components/UploadApprovalFiles';
import TextArea from '~/components/TextArea';
import UploadFiles from '~/components/UploadFiles';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';
import NoticeDialog from '~/components/Dialogs/Notice';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import { Container, Content, Wrapper, ButtonContainer } from './styles';

interface IFormProcedure4 {
  type: string;
  code: string;
  sponsorship_id: string;
  user_update_id: string;
  validation_type: string;
  project_procedure_id: string;
  record_description: string;
  record_note: string;
  project_temp_id: string;
  project_approval_temp_id: string;
}

interface ILocation {
  id: string;
  type: string;
  code: string;
  erp_rep_code: string;
  status: string;
  project_procedure_id: string;
  project_procedure: {
    user_type: string;
  };
}

const Procedure4: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [projectTempFilled, setProjectTempFilled] = useState(false);
  const [projectTemp, setProjectTemp] = useState('');
  const [projectApprovalTempFilled, setProjectApprovalTempFilled] =
    useState(false);
  const [projectApprovalTemp, setProjectApprovalTemp] = useState('');
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [titleConfirmProcedure, setTitleConfirmProcedure] = useState('');
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isOpenNotice, setIsOpenNotice] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { handleSubmit, register, setValue, errors } =
    useForm<IFormProcedure4>();

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto não localizado',
      });

      history.push('/projects/sponsorships');
    } else if (
      user.user_type.type !== state.project_procedure.user_type &&
      user.user_type.type !== 'ADM' &&
      user.user_type.type !== 'MK1' &&
      user.user_type.type !== 'MK2'
    ) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Tipo de usuário não permitido',
      });

      history.push('/projects/sponsorships');
    } else if (
      state.project_procedure.user_type === 'REP' &&
      state.erp_rep_code !== user.erp_rep_code &&
      user.user_type.type !== 'ADM' &&
      user.user_type.type !== 'MK1' &&
      user.user_type.type !== 'MK2'
    ) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Usuário não permitido',
      });

      history.push('/projects/sponsorships');
    } else if (state.status.toUpperCase() === 'CANCELADO') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto Cancelado',
      });

      history.push('/projects/sponsorships');
    } else if (state.status.toUpperCase() === 'FINALIZADO') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto Finalizado',
      });

      history.push('/projects/sponsorships');
    } else {
      register('validation_type');
      register('project_temp_id');
      register('project_approval_temp_id');
      register('type');
      register('code');
      register('record_description');
      register('user_update_id');
      register('project_procedure_id');

      setValue('type', 'PATROCINIO');
      setValue('code', state.code);
      setValue('user_update_id', user.id);
      setValue('project_procedure_id', state.project_procedure_id);

      if (!projectTempFilled) {
        setProjectTempFilled(true);
        setProjectTemp(uuidv4());
      }

      if (!projectApprovalTempFilled) {
        setProjectApprovalTempFilled(true);
        setProjectApprovalTemp(uuidv4());
      }
    }
  }, [
    addToast,
    history,
    state,
    user,
    projectTempFilled,
    projectApprovalTempFilled,
    register,
    setValue,
  ]);

  useEffect(() => {
    setValue('project_temp_id', projectTemp);
    setValue('project_approval_temp_id', projectApprovalTemp);
  }, [projectTemp, projectApprovalTemp, setValue]);

  const submitForm = useCallback(
    async (data: IFormProcedure4) => {
      try {
        setIsSaving(true);

        await api.put(`/projects/${state.id}`, data);

        addToast({
          type: 'success',
          title: 'Projeto atualizado!',
          description: 'Projeto atualizado com sucesso.',
        });

        history.push('/projects/sponsorships');
      } catch (error: any) {
        setIsSaving(false);

        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [addToast, history, state],
  );

  async function onValidateConfirmProcedure(): Promise<void> {
    api
      .get(
        `/projectapprovalfilesreceived/projectapprovaltemp?project_approval_temp_id=${projectApprovalTemp}`,
      )
      .then(response => {
        if (response.data.length > 0) {
          setIsOpenConfirmProcedure(true);
          setTitleConfirmProcedure(`Confirma envio do projeto ${state.code}?`);
        } else {
          setIsOpenNotice(true);
        }
      });
  }

  function onConfirmProcedure(): void {
    setValue('validation_type', 'APROVADO');
    setValue('record_description', `Envio de arquivos para aprovação de arte`);

    handleSubmit(submitForm)();
  }

  const onExit = useCallback(() => {
    api.delete(`projectfilesreceived/projecttempid/${projectTemp}`);

    api.delete(
      `projectapprovalfilesreceived/projectapprovaltempid/${projectApprovalTemp}`,
    );

    history.push('/projects/sponsorships');
  }, [history, projectTemp, projectApprovalTemp]);

  function handleFiles(): React.ReactNode {
    if (user.user_type.type !== 'REP') {
      return (
        <Content>
          <UploadFiles
            type="anexo"
            project_temp_id={projectTemp}
            project_type="PATROCINIO"
            sequence={4}
            user_id={user.id}
          />
        </Content>
      );
    }

    return <></>;
  }

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/sponsorships"
        firstChild="Pagamentos"
        secondChild="Atualização Solicitação"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaMoneyBillWave}
        secondChildIcon={FaMoneyBillWave}
        mainColored
        firstChildColored
      />

      <ProjectInfo project_id={state.id} />

      <form onSubmit={handleSubmit(submitForm)}>
        <Content>
          <UploadApprovalFiles
            type="aprovacao"
            project_approval_temp_id={projectApprovalTemp}
            project_type="PATROCINIO"
            sequence={4}
            user_id={user.id}
          />
        </Content>

        <Content>
          <Wrapper>
            <TextArea
              name="record_note"
              labelFor="record_note"
              labelText="Observação"
              error={errors?.record_note?.message}
              ref={register}
              maxLength={1000}
            />
          </Wrapper>
        </Content>

        {handleFiles()}

        <ButtonContainer>
          <SaveButton
            type="button"
            processing={isSaving}
            disabled={isSaving}
            onClick={() => onValidateConfirmProcedure()}
          />

          <ExitButton
            style={{ marginLeft: 10 }}
            type="button"
            disabled={isSaving}
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={onConfirmProcedure}
        title={titleConfirmProcedure}
        subTitle="O projeto será enviado para o próximo procedimento"
        opConfirm="Sim"
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />

      <NoticeDialog
        isOpen={isOpenNotice}
        type="error"
        title="Necessário anexar arquivos para aprovação da arte!"
        subTitle="Não é possível enviar projeto sem anexar arquivos"
        setIsOpen={setIsOpenNotice}
      />
    </Container>
  );
};

export default Procedure4;
