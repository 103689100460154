import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';

export type IAccordionProps = {
  icon: React.ReactNode;
  sectionName: string;
  link: string;
};

const Accordion: React.FC<IAccordionProps> = ({
  icon,
  sectionName,
  link,
}: IAccordionProps) => {
  return (
    <Link to={link}>
      <Container>
        <div>{icon}</div>
        {sectionName}
      </Container>
    </Link>
  );
};

export default Accordion;
