import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';

import api from '~/services/api';

import InfoInput from '~/components/Inputs/Info';
import CloseButton from '~/components/Buttons/Close';

import {
  StyledModal,
  MainContainer,
  Title,
  Container,
  Content,
  Wrapper,
  ButtonContainer,
} from './styles';

interface ILabelDetailProps {
  isOpen: boolean;
  onClose: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  sequence: number;
  project_code: string;
  control: string;
  packaging: string;
  erp_client_code?: string;
  city: string;
  state: string;
  invoice_series: string;
  invoice_number: string;
  invoice_issue_date: Date;
  invoice_net_price: number;
  document: string;
  item_erp_code: string;
  batch: string;
  user: string;
}

interface IClient {
  code: string;
  name: string;
  company_name: string;
  document: string;
  address: string;
  city: string;
  state: string;
}

const LabelDetail: React.FC<ILabelDetailProps> = ({
  isOpen,
  onClose,
  setIsOpen,
  sequence,
  project_code,
  control,
  packaging,
  erp_client_code,
  city,
  state,
  invoice_series,
  invoice_number,
  invoice_issue_date,
  invoice_net_price,
  document,
  item_erp_code,
  batch,
  user,
}: ILabelDetailProps) => {
  const [client, setClient] = useState<IClient>();

  useEffect(() => {
    if (erp_client_code && erp_client_code !== '') {
      api.get(`/clients/code?code=${erp_client_code}`).then(response => {
        setClient(response.data);
      });
    }
  }, [erp_client_code]);

  const handleClose = (): void => {
    setIsOpen(false);
    onClose();
  };

  return (
    <StyledModal
      isOpen={isOpen}
      style={{
        overlay: {
          zIndex: 999,
          overflowY: 'auto',
          position: 'fixed',
          background: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <MainContainer>
        <Title>Detalhes Etiquetas</Title>
        <Container>
          <Content>
            <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
              <InfoInput
                name="project_code"
                labelFor="project_code"
                labelText="Cód. Projeto"
                type="text"
                defaultValue={project_code}
                disabled
              />
            </Wrapper>

            <Wrapper style={{ width: '50%' }}>
              <InfoInput
                name="sequence"
                labelFor="sequence"
                labelText="Sequência"
                type="text"
                defaultValue={sequence}
                disabled
              />
            </Wrapper>
          </Content>

          <Content>
            <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
              <InfoInput
                name="control"
                labelFor="control"
                labelText="Controle"
                type="text"
                defaultValue={control}
                disabled
              />
            </Wrapper>

            <Wrapper style={{ width: '50%' }}>
              <InfoInput
                name="packaging"
                labelFor="packaging"
                labelText="Embalagem"
                type="text"
                defaultValue={packaging}
                disabled
              />
            </Wrapper>
          </Content>

          <Content>
            <Wrapper style={{ width: '100%' }}>
              <InfoInput
                name="client_name"
                labelFor="client_name"
                labelText="Cliente"
                type="text"
                defaultValue={client?.name ? client.name : ''}
                disabled
              />
            </Wrapper>
          </Content>

          <Content>
            <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
              <InfoInput
                name="client_city"
                labelFor="client_city"
                labelText="Cidade"
                type="text"
                defaultValue={city}
                disabled
              />
            </Wrapper>

            <Wrapper style={{ width: '50%' }}>
              <InfoInput
                name="client_state"
                labelFor="client_state"
                labelText="Estado"
                type="text"
                defaultValue={state}
                disabled
              />
            </Wrapper>
          </Content>

          <Content>
            <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
              <InfoInput
                name="invoice_series"
                labelFor="invoice_series"
                labelText="Série NF"
                type="text"
                defaultValue={invoice_series}
                disabled
              />
            </Wrapper>

            <Wrapper style={{ width: '50%' }}>
              <InfoInput
                name="invoice_number"
                labelFor="invoice_number"
                labelText="Número NF"
                type="text"
                defaultValue={invoice_number}
                disabled
              />
            </Wrapper>
          </Content>

          <Content>
            <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
              <InfoInput
                name="invoice_issue_date"
                labelFor="invoice_issue_date"
                labelText="Data Emissão NF"
                type="text"
                defaultValue={new Date(invoice_issue_date).toLocaleDateString()}
                disabled
              />
            </Wrapper>

            <Wrapper style={{ width: '50%' }}>
              <InfoInput
                name="invoice_net_price"
                labelFor="invoice_net_price"
                labelText="Valor Total NF"
                type="text"
                defaultValue={
                  invoice_net_price
                    ? new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(invoice_net_price)
                    : new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(0)
                }
                disabled
              />
            </Wrapper>
          </Content>

          <Content>
            <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
              <InfoInput
                name="document"
                labelFor="document"
                labelText="CNPJ"
                type="text"
                defaultValue={document}
                disabled
              />
            </Wrapper>

            <Wrapper style={{ width: '50%' }}>
              <InfoInput
                name="item_erp_code"
                labelFor="item_erp_code"
                labelText="Item"
                type="text"
                defaultValue={item_erp_code}
                disabled
              />
            </Wrapper>
          </Content>

          <Content>
            <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
              <InfoInput
                name="batch"
                labelFor="batch"
                labelText="Lote"
                type="text"
                defaultValue={batch}
                disabled
              />
            </Wrapper>

            <Wrapper style={{ width: '50%' }}>
              <InfoInput
                name="user"
                labelFor="user"
                labelText="Usuário"
                type="text"
                defaultValue={user}
                disabled
              />
            </Wrapper>
          </Content>

          <ButtonContainer>
            <CloseButton onClick={() => handleClose()} />
          </ButtonContainer>
        </Container>
      </MainContainer>
    </StyledModal>
  );
};

export default LabelDetail;
