import React, { useState, useEffect } from 'react';
import { PropagateLoader } from 'react-spinners';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { FaAngleDown, FaFileAlt } from 'react-icons/fa';

import Pagination from '~/components/Pagination';
import ProjectRecordDetailModal from '~/components/Modals/Projects/RecordDetail';

import DefaultInput from '~/components/Inputs/Default';

import api from '~/services/api';

import {
  Container,
  Label,
  Wrapper,
  Table,
  Th,
  Td,
  DetailBtn,
  ButtonsContent,
} from './styles';

interface IRecordsProps {
  project_id: string;
}

interface IProjectRecord {
  id: string;
  description: string;
  status: string;
  note: string;
  reason: string;
  project_id: string;
  project: {
    code: string;
  };
  user: {
    name: string;
  };
  project_procedure: {
    description: string;
  };
  created_at: string;
}

const AMOUNT_PER_PAGE = 5;

const Records: React.FC<IRecordsProps> = ({ project_id }) => {
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState('');
  const [projectRecords, setProjectRecords] = useState<IProjectRecord[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [descriptionDetail, setDescriptionDetail] = useState('');
  const [statusDetail, setStatusDetail] = useState('');
  const [noteDetail, setNoteDetail] = useState('');
  const [reasonDetail, setReasonDetail] = useState('');
  const [projectCodeDetail, setProjectCodeDetail] = useState('');
  const [userDetail, setUserDetail] = useState('');
  const [projProcDescriptionDetail, setProjProcDescriptionDetail] =
    useState('');

  useEffect(() => {
    const loadProjectRecords = async () => {
      await api
        .get(
          `/projectrecords/projectvisible?` +
            `project_id=${project_id}&` +
            `page=${page}&` +
            `filter=${filter}&` +
            `amount=${AMOUNT_PER_PAGE}`,
        )
        .then(response => {
          setProjectRecords(response.data[0]);
          setCount(response.data[1]);
          setLoading(false);
        });
    };

    loadProjectRecords();
  }, [project_id, page, filter]);

  function handleTable(): React.ReactNode {
    if (projectRecords) {
      if (projectRecords.length > 0) {
        return (
          <Table>
            <thead>
              <tr>
                <Th width="15%" textAlign="left">
                  Código
                </Th>
                <Th width="20%" textAlign="left">
                  Data
                </Th>
                <Th width="60%" textAlign="left">
                  Descrição
                </Th>
                <Th width="10%" textAlign="center" />
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <Td colSpan={4} padding="1rem" textAlign="center">
                    <PropagateLoader color="#276c8d" />
                  </Td>
                </tr>
              )}
              {projectRecords?.map(projectRecord => (
                <tr key={projectRecord.id}>
                  <Td width="15%" textAlign="left" heading="CÓDIGO">
                    {projectRecord.project.code}
                  </Td>
                  <Td width="20%" textAlign="left" heading="DATA">
                    {new Date(projectRecord.created_at).toLocaleDateString()}
                  </Td>
                  <Td width="60%" textAlign="left" heading="DESCRIÇÃO">
                    {projectRecord.description.substring(0, 100)}
                  </Td>
                  <Td
                    width="10%"
                    textAlign="center"
                    display="flex"
                    heading={' '}
                  >
                    <DetailBtn
                      onClick={() => {
                        setIsOpenDetail(true);
                        setDescriptionDetail(projectRecord.description);
                        setStatusDetail(projectRecord.status);
                        setNoteDetail(projectRecord.note);
                        setReasonDetail(projectRecord.reason);
                        setProjectCodeDetail(projectRecord.project.code);
                        setUserDetail(projectRecord.user.name);
                        setProjProcDescriptionDetail(
                          projectRecord.project_procedure.description,
                        );
                      }}
                    >
                      <FaFileAlt size={22} />
                    </DetailBtn>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        );
      }
    }

    return (
      <Table>
        <thead>
          <tr>
            <Th width="100%" textAlign="center" />
          </tr>
        </thead>
        <tbody>
          <tr>
            <Td width="100%" textAlign="center">
              Nenhuma informação disponível
            </Td>
          </tr>
        </tbody>
      </Table>
    );
  }

  function clearProjectRecordDetail(): void {
    setDescriptionDetail('');
    setStatusDetail('');
    setNoteDetail('');
    setReasonDetail('');
    setProjectCodeDetail('');
    setUserDetail('');
    setProjProcDescriptionDetail('');
  }

  if (!project_id) {
    return null;
  }

  return (
    <Container>
      <Accordion>
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Label>Histórico</Label>
        </AccordionSummary>

        <AccordionDetails>
          <Wrapper>
            <div>
              <DefaultInput
                name="search"
                labelText="Pesquisar"
                type="text"
                onChange={event => {
                  setFilter(event.target.value);
                  setPage(0);
                }}
              />
            </div>

            {handleTable()}

            <ButtonsContent>
              <Pagination
                page={page}
                count={count}
                amountPerPage={AMOUNT_PER_PAGE}
                setPage={setPage}
              />
            </ButtonsContent>
          </Wrapper>
        </AccordionDetails>
      </Accordion>

      <ProjectRecordDetailModal
        isOpen={isOpenDetail}
        description={descriptionDetail}
        status={statusDetail}
        note={noteDetail}
        reason={reasonDetail}
        project_code={projectCodeDetail}
        user={userDetail}
        project_procedure_description={projProcDescriptionDetail}
        setIsOpen={setIsOpenDetail}
        onClose={() => {
          clearProjectRecordDetail();
        }}
      />
    </Container>
  );
};

export default Records;
