import styled, { css } from 'styled-components';
import Modal from 'react-modal';
import { shade } from 'polished';
import Button from '~/components/Button';

export const StyledModal = styled(Modal)`
  ${({ theme }) => css`
    width: 100%;
    max-width: 680px;
    margin-top: 20%;
    margin-left: 50%;
    transform: translate(-50%, -20%);
    background-color: ${theme.colors.background};
    box-shadow: ${theme.shadows.veryStrong};
    border-radius: ${theme.radius.meddium};
  `}
`;

export const Container = styled.div`
  padding: 1rem 1rem 1rem 1rem;
`;

export const IconContainer = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.xLarge};
    text-align: center;
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.xLarge};
    text-align: center;
    color: ${theme.colors.darkGrey};
  `}
`;

export const SubTitle = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.meddium};
    text-align: center;
    margin-top: 1rem;
  `}
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 0 1rem 1rem 1rem;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: center;
  margin-top: 1rem;
`;

export const OptionButton = styled(Button)`
  ${({ theme }) => css`
    margin: 1rem;
    font-size: ${theme.fontSizes.default};
    color: ${theme.colors.white};
  `}
`;

export const OptionConfirm = styled(OptionButton)`
  ${({ theme }) => css`
    background: ${theme.colors.danger};
    width: 22rem;

    :hover {
      transform: scale(1.1, 1.1);
      background: ${shade(0.2, theme.colors.danger)};
    }
  `}
`;

export const OptionCancel = styled(OptionButton)`
  ${({ theme }) => css`
    background: ${theme.colors.secondary};
    width: 22rem;

    :hover {
      transform: scale(1.1, 1.1);
      background: ${shade(0.2, theme.colors.secondary)};
    }
  `}
`;
