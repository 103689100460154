import React, { Dispatch, SetStateAction } from 'react';

import InfoInput from '~/components/Inputs/Info';
import TextArea from '~/components/TextArea';
import CloseButton from '~/components/Buttons/Close';

import {
  StyledModal,
  MainContainer,
  Title,
  Container,
  Content,
  Wrapper,
  ButtonContainer,
} from './styles';

interface IEventCostDetailProps {
  isOpen: boolean;
  onClose: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  project_code: string;
  created_at: Date;
  user_inclusion: string;
  event_cost_type: string;
  price: number;
  note: string;
}

const EventCostDetail: React.FC<IEventCostDetailProps> = ({
  isOpen,
  onClose,
  setIsOpen,
  project_code,
  user_inclusion,
  created_at,
  event_cost_type,
  price,
  note,
}: IEventCostDetailProps) => {
  const handleClose = (): void => {
    setIsOpen(false);
    onClose();
  };

  return (
    <StyledModal
      isOpen={isOpen}
      style={{
        overlay: {
          zIndex: 999,
          overflowY: 'auto',
          position: 'fixed',
          background: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <MainContainer>
        <Title>Detalhes Custos</Title>
        <Container>
          <Content>
            <Wrapper style={{ width: '33%', marginRight: '1rem' }}>
              <InfoInput
                name="project_code"
                labelFor="project_code"
                labelText="Cód. Projeto"
                type="text"
                defaultValue={project_code}
                disabled
              />
            </Wrapper>

            <Wrapper style={{ width: '33%', marginRight: '1rem' }}>
              <InfoInput
                name="user_inclusion"
                labelFor="user_inclusion"
                labelText="Usuário"
                type="text"
                defaultValue={user_inclusion}
                disabled
              />
            </Wrapper>

            <Wrapper style={{ width: '33%' }}>
              <InfoInput
                name="created_at"
                labelFor="created_at"
                labelText="Data Inclusão"
                type="text"
                defaultValue={new Date(created_at).toLocaleDateString()}
                disabled
              />
            </Wrapper>
          </Content>

          <Content>
            <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
              <InfoInput
                name="event_cost_type"
                labelFor="event_cost_type"
                labelText="Tipo"
                type="text"
                defaultValue={event_cost_type}
                disabled
              />
            </Wrapper>

            <Wrapper style={{ width: '50%' }}>
              <InfoInput
                name="price"
                labelFor="price"
                labelText="Valor"
                type="text"
                defaultValue={new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(price)}
                disabled
              />
            </Wrapper>
          </Content>

          <Content>
            <Wrapper style={{ width: '100%' }}>
              <TextArea
                name="note"
                labelFor="note"
                labelText="Observação"
                defaultValue={note}
                maxLength={2000}
                disabled
              />
            </Wrapper>
          </Content>

          <ButtonContainer>
            <CloseButton onClick={() => handleClose()} />
          </ButtonContainer>
        </Container>
      </MainContainer>
    </StyledModal>
  );
};

export default EventCostDetail;
