import React, { useState, useEffect } from 'react';
import { PropagateLoader } from 'react-spinners';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { FaAngleDown, FaFileAlt } from 'react-icons/fa';

import Paginator from '~/components/Paginator';
import PrevButton from '~/components/Paginator/PrevButton';
import NextButton from '~/components/Paginator/NextButton';
import CenterButton from '~/components/Paginator/CenterButton';
import StoreRelationshipRecordDetailModal from '~/components/Modals/StoreRelationships/RecordDetail';

import Input from '~/components/Input';

import api from '~/services/api';

import {
  Container,
  Label,
  Wrapper,
  Table,
  Th,
  Td,
  DetailBtn,
  ButtonsContent,
} from './styles';

interface IRecordsProps {
  store_relationship_id: string;
}

interface IStoreRelationshipRecord {
  id: string;
  description: string;
  status: string;
  note: string;
  reason: string;
  store_relationship: {
    code: string;
  };
  user: {
    name: string;
  };
  created_at: string;
}

const Records: React.FC<IRecordsProps> = ({ store_relationship_id }) => {
  const [page, setPage] = useState(0);
  const [pageFilter, setPageFilter] = useState(0);
  const [filter, setFilter] = useState('');
  const [filterHelper, setFilterHelper] = useState('');
  const [refresh, setRefresh] = useState(true);
  const [storeRelationshipRecords, setStoreRelationshipRecords] =
    useState<IStoreRelationshipRecord[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [descriptionDetail, setDescriptionDetail] = useState('');
  const [statusDetail, setStatusDetail] = useState('');
  const [noteDetail, setNoteDetail] = useState('');
  const [reasonDetail, setReasonDetail] = useState('');
  const [storeRelationshipCodeDetail, setStoreRelationshipCodeDetail] =
    useState('');
  const [userDetail, setUserDetail] = useState('');

  useEffect(() => {
    if (refresh && store_relationship_id) {
      setRefresh(false);
      let pageSearch = 0;
      let foundData = false;

      if (filter !== filterHelper) {
        setPage(0);
        setPageFilter(0);
      }

      setFilterHelper(filter);

      if (filter !== '') {
        pageSearch = pageFilter;
      } else {
        pageSearch = page;
      }

      api
        .get(
          `/storerelationshiprecords/storerelationshipvisible` +
            `?store_relationship_id=${store_relationship_id}` +
            `&page=${pageSearch}` +
            `&filter=${filter}`,
        )
        .then(firstResponse => {
          if (firstResponse.data.length > 0) {
            foundData = true;
            setStoreRelationshipRecords(firstResponse.data);
            setLoading(false);
          }

          if (!foundData) {
            if (pageSearch - 1 >= 0) {
              pageSearch -= 1;
              if (filter !== '') {
                setPageFilter(pageFilter - 1);
              } else {
                setPage(page - 1);
              }
            }

            api
              .get(
                `/storerelationshiprecords/storerelationshipvisible` +
                  `?store_relationship_id=${store_relationship_id}` +
                  `&page=${pageSearch}` +
                  `&filter=${filter}`,
              )
              .then(secondResponse => {
                setStoreRelationshipRecords(secondResponse.data);
                setLoading(false);

                api
                  .get(
                    `/storerelationshiprecords/storerelationshipvisible` +
                      `?store_relationship_id=${store_relationship_id}` +
                      `&page=${pageSearch + 1}
                    &filter=${filter}`,
                  )
                  .then(thirdResponse => {
                    setNextDisabled(false);
                    if (thirdResponse.data.length === 0) {
                      setNextDisabled(true);
                    }
                  });
              });
          } else {
            api
              .get(
                `/storerelationshiprecords/storerelationshipvisible` +
                  `?store_relationship_id=${store_relationship_id}` +
                  `&page=${pageSearch + 1}` +
                  `&filter=${filter}`,
              )
              .then(fourthResponse => {
                setNextDisabled(false);
                if (fourthResponse.data.length === 0) {
                  setNextDisabled(true);
                }
              });
          }
        });
    }
  }, [page, pageFilter, filter, filterHelper, refresh, store_relationship_id]);

  function handlePage(type: string): void {
    if (type === 'backward') {
      if (filter !== '') {
        setPageFilter(pageFilter - 1);
      } else {
        setPage(page - 1);
      }
    } else if (type === 'forward') {
      if (filter !== '') {
        setPageFilter(pageFilter + 1);
      } else {
        setPage(page + 1);
      }
    } else if (filter !== '') {
      setPageFilter(0);
    } else {
      setPage(0);
    }
    setRefresh(true);
  }

  function handlePrevButtonDisabled(): boolean {
    let validationPage = 0;

    if (filter !== '') {
      validationPage = pageFilter;
    } else {
      validationPage = page;
    }

    if (validationPage === 0) {
      return true;
    }

    return false;
  }

  function handleFilterChange(value: string): void {
    setFilter(value);
    setRefresh(true);
  }

  function handleTable(): React.ReactNode {
    if (storeRelationshipRecords) {
      if (storeRelationshipRecords.length > 0) {
        return (
          <Table>
            <thead>
              <tr>
                <Th width="15%" textAlign="left">
                  Código
                </Th>
                <Th width="20%" textAlign="left">
                  Data
                </Th>
                <Th width="60%" textAlign="left">
                  Descrição
                </Th>
                <Th width="10%" textAlign="center" />
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <Td colSpan={4} padding="1rem" textAlign="center">
                    <PropagateLoader color="#276c8d" />
                  </Td>
                </tr>
              )}
              {storeRelationshipRecords?.map(storeRelationshipRecord => (
                <tr key={storeRelationshipRecord.id}>
                  <Td width="15%" textAlign="left" heading="CÓDIGO">
                    {storeRelationshipRecord.store_relationship.code}
                  </Td>
                  <Td width="20%" textAlign="left" heading="DATA">
                    {new Date(
                      storeRelationshipRecord.created_at,
                    ).toLocaleDateString()}
                  </Td>
                  <Td width="60%" textAlign="left" heading="DESCRIÇÃO">
                    {storeRelationshipRecord.description.substring(0, 100)}
                  </Td>
                  <Td
                    width="10%"
                    textAlign="center"
                    display="flex"
                    heading={' '}
                  >
                    <DetailBtn
                      onClick={() => {
                        setIsOpenDetail(true);
                        setDescriptionDetail(
                          storeRelationshipRecord.description,
                        );
                        setStatusDetail(storeRelationshipRecord.status);
                        setNoteDetail(storeRelationshipRecord.note);
                        setReasonDetail(storeRelationshipRecord.reason);
                        setStoreRelationshipCodeDetail(
                          storeRelationshipRecord.store_relationship.code,
                        );
                        setUserDetail(storeRelationshipRecord.user.name);
                      }}
                    >
                      <FaFileAlt size={22} />
                    </DetailBtn>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        );
      }
    }

    return (
      <Table>
        <thead>
          <tr>
            <Th width="100%" textAlign="center" />
          </tr>
        </thead>
        <tbody>
          <tr>
            <Td width="100%" textAlign="center">
              Nenhuma informação disponível
            </Td>
          </tr>
        </tbody>
      </Table>
    );
  }

  function clearStoreRelationshipRecordDetail(): void {
    setDescriptionDetail('');
    setStatusDetail('');
    setNoteDetail('');
    setReasonDetail('');
    setStoreRelationshipCodeDetail('');
    setUserDetail('');
  }

  if (!store_relationship_id) {
    return null;
  }

  return (
    <Container>
      <Accordion>
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Label>Histórico</Label>
        </AccordionSummary>

        <AccordionDetails>
          <Wrapper>
            <form onSubmit={() => {}}>
              <div
                style={{
                  display: 'flex',
                  maxWidth: 680,
                }}
              >
                <Input
                  name="search"
                  labelText="Pesquisar"
                  type="text"
                  onChange={event => handleFilterChange(event.target.value)}
                />
              </div>
            </form>

            {handleTable()}

            <ButtonsContent>
              <Paginator>
                <PrevButton
                  disabled={handlePrevButtonDisabled()}
                  onClick={() => handlePage('backward')}
                />
                <CenterButton onClick={() => handlePage('reset')} />
                <NextButton
                  disabled={nextDisabled}
                  onClick={() => handlePage('forward')}
                />
              </Paginator>
            </ButtonsContent>
          </Wrapper>
        </AccordionDetails>
      </Accordion>

      <StoreRelationshipRecordDetailModal
        isOpen={isOpenDetail}
        description={descriptionDetail}
        status={statusDetail}
        note={noteDetail}
        reason={reasonDetail}
        store_relationship_code={storeRelationshipCodeDetail}
        user={userDetail}
        setIsOpen={setIsOpenDetail}
        onClose={() => {
          clearStoreRelationshipRecordDetail();
        }}
      />
    </Container>
  );
};

export default Records;
