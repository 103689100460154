import React from 'react';

import InfoInput from '~/components/Inputs/Info';

import { Container, Content } from './styles';

interface ICodeProps {
  code: string;
}

const Code: React.FC<ICodeProps> = ({ code }) => {
  return (
    <Container>
      <Content>
        <InfoInput
          name="code"
          labelFor="code"
          labelText="Código"
          type="text"
          defaultValue={code}
          disabled
        />
      </Content>
    </Container>
  );
};

export default Code;
