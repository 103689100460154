import React from 'react';
import { FaRegFileExcel } from 'react-icons/fa';

import { Container } from './styles';

interface ISpreadsheetProps {
  iconSize?: number;
}

const Spreadsheet: React.FC<ISpreadsheetProps> = ({ iconSize }) => {
  return (
    <Container>
      <FaRegFileExcel size={iconSize !== undefined ? iconSize : 60} />
    </Container>
  );
};

export default Spreadsheet;
