import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FaTools, FaWrench } from 'react-icons/fa';

import BreadCrumb from '~/components/BreadCrumb';
import Select from '~/components/Select';
import SelectButton from '~/components/Buttons/Select';
import NoticeDialog from '~/components/Dialogs/Notice';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  ContentSplit,
  OneFifthWrapper,
  TwoFifthsWrapper,
  ButtonContainer,
} from './styles';

interface IProject {
  id: string;
  type: string;
  code: string;
  erp_client_code: string;
  erp_rep_code: string;
  net_discount_price: number;
  granted_discount_price: number;
  canceled_discount_price: number;
  status: string;
}

const ChooseProcess: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const [projects, setProjects] = useState<IProject[]>();
  const [project, setProject] = useState<IProject>();
  const [projectId, setProjectId] = useState<string>();
  const [type, setType] = useState('none');
  const [loadingProjects, setLoadingProjects] = useState<boolean>(true);
  const [isOpenNotice, setIsOpenNotice] = useState(false);
  const [titleNotice, setTitleNotice] = useState('');
  const [subTitleNotice, setSubTitleNotice] = useState('');

  useEffect(() => {
    if (user.user_type.type !== 'ADM' && !user.admin_functions) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Tipo de usuário não permitido',
      });

      history.push('/management/main');
    } else {
      api.get(`/projects/allorderbycode`).then(response => {
        setProjects(response.data);
        setLoadingProjects(false);
      });
    }
  }, [user, addToast, history]);

  useEffect(() => {
    if (projectId) {
      api.get(`/projects/id?id=${projectId}`).then(response => {
        setProject(response.data);
      });
    }
  }, [projectId]);

  const projectOptions = projects?.map(proj => ({
    value: proj.id,
    label: `${proj.code}`,
  }));

  const typeOptions: { value: string; label: string }[] =
    user.user_type.type === 'ADM'
      ? [
          { value: 'cancel', label: 'Cancelamento' },
          { value: 'discount_cancellation', label: 'Cancelamento de Desconto' },
          { value: 'erp_rep_client', label: 'Representante/Cliente' },
          { value: 'status', label: 'Status' },
        ]
      : [{ value: 'cancel', label: 'Cancelamento' }];

  function onConfirmProcedure(): void {
    if (type === 'cancel' && project) {
      history.push({
        pathname: '/management/projectediting/cancel',
        state: {
          id: project.id,
          code: project.code,
          status: project.status,
        },
      });
    }

    if (type === 'discount_cancellation' && project) {
      history.push({
        pathname: '/management/projectediting/discountcancellation',
        state: {
          id: project.id,
          type: project.type,
          code: project.code,
          pending_discount_price:
            project.net_discount_price -
            project.granted_discount_price -
            project.canceled_discount_price,
        },
      });
    }

    if (type === 'erp_rep_client' && project) {
      history.push({
        pathname: '/management/projectediting/erprepclient',
        state: {
          id: project.id,
          code: project.code,
          erp_rep_code: project.erp_rep_code,
          erp_client_code: project.erp_client_code,
        },
      });
    }

    if (type === 'status' && project) {
      history.push({
        pathname: '/management/projectediting/status',
        state: {
          id: project.id,
          code: project.code,
          status: project.status,
        },
      });
    }
  }

  async function onValidateConfirmProcedure(): Promise<void> {
    if (!project) {
      setTitleNotice('Projeto não selecionado!');
      setSubTitleNotice('Necessário selecionar projeto válido!');
      setIsOpenNotice(true);
    } else if (type === 'none') {
      setTitleNotice('Tipo não selecionado!');
      setSubTitleNotice('Necessário selecionar tipo válido!');
      setIsOpenNotice(true);
    } else {
      onConfirmProcedure();
    }
  }

  return (
    <Container>
      <BreadCrumb
        main="Administração"
        path="/management/main"
        firstChild="Alteração Manual de Solicitação"
        mainIcon={FaTools}
        firstChildIcon={FaWrench}
        mainColored
      />

      <ContentSplit maxWidth="700px">
        <TwoFifthsWrapper>
          <Select
            label="Projetos"
            options={projectOptions}
            onSet={(e: any) => {
              setProjectId(e.value);
            }}
            loading={loadingProjects}
          />
        </TwoFifthsWrapper>

        <TwoFifthsWrapper>
          <Select
            label="Tipo"
            options={typeOptions}
            onSet={(e: any) => {
              setType(e.value);
            }}
          />
        </TwoFifthsWrapper>

        <OneFifthWrapper>
          <ButtonContainer>
            <SelectButton onClick={() => onValidateConfirmProcedure()} />
          </ButtonContainer>
        </OneFifthWrapper>
      </ContentSplit>

      <NoticeDialog
        isOpen={isOpenNotice}
        type="error"
        title={titleNotice}
        subTitle={subTitleNotice}
        setIsOpen={setIsOpenNotice}
      />
    </Container>
  );
};

export default ChooseProcess;
