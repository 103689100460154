import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useCompletionFiles } from '~/context/completionfiles';

import { DropContainer, UploadMessage } from './styles';

interface IUploadProps {
  type: string;
  project_completion_temp_id: string;
  project_type: string;
  sequence: number;
  user_id: string;
}

function Upload({
  type,
  project_completion_temp_id,
  project_type,
  sequence,
  user_id,
}: IUploadProps): JSX.Element {
  const { handleUpload } = useCompletionFiles();

  const onDrop = useCallback(
    completionFiles => {
      handleUpload(
        completionFiles,
        type,
        project_completion_temp_id,
        project_type,
        sequence,
        user_id,
      );
    },
    [
      handleUpload,
      type,
      project_completion_temp_id,
      project_type,
      sequence,
      user_id,
    ],
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      accept: [
        'image/jpeg',
        'image/pjpeg',
        'image/png',
        'image/gif',
        'application/pdf',
        'audio/mp3',
        'audio/mpeg',
        'video/quicktime',
        'video/mp4',
        'text/plain',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.oasis.opendocument.spreadsheet',
        'application/vnd.oasis.opendocument.presentation',
        'application/vnd.oasis.opendocument.text',
      ],
      onDrop,
    });

  const renderDragMessage = useCallback(() => {
    if (!isDragActive) {
      return (
        <UploadMessage>Arraste os arquivos de conclusão aqui...</UploadMessage>
      );
    }

    if (isDragReject) {
      return (
        <UploadMessage type="error">
          Tipo de arquivo não suportado
        </UploadMessage>
      );
    }

    return (
      <UploadMessage type="success">
        Solte os arquivos de conclusão aqui
      </UploadMessage>
    );
  }, [isDragActive, isDragReject]);

  return (
    <DropContainer {...getRootProps()}>
      <input {...getInputProps()} />
      {renderDragMessage()}
    </DropContainer>
  );
}

export default Upload;
