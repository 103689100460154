import styled, { css } from 'styled-components';

export const Container = styled.section`
  ${({ theme }) => css`
    width: 100%;
    background: ${theme.colors.background};
    border-radius: ${theme.radius.default};
    box-shadow: ${theme.shadows.flat};
    padding: 2.4rem;
    margin: 2.4rem 0;
  `}
`;

export const Content = styled.div`
  max-width: 680px;
  display: flex;
  justify-content: flex-start;
`;

export const ContentSplit = styled(Content)`
  @media (max-width: 1010px) {
    flex-direction: column;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 2rem;

  @media (max-width: 1010px) {
    margin: 0 0 2rem 0;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20;

  @media (max-width: 1010px) {
    float: right;
    flex-direction: column;

    > button {
      margin: 0 0 1rem 0;
    }
  }
`;
