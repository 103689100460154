import styled, { css } from 'styled-components';

export const Container = styled.button`
  ${({ theme }) => css`
    background-color: #fff;
    color: ${theme.colors.grey};
    padding: 8px 16px;
    border-radius: 8%;
    box-shadow: 0 0 3px #bbbbbb;

    &:hover {
      transform: scale(1.2, 1.2);
    }
  `}
`;
