import React from 'react';
import { FaRegFilePowerpoint } from 'react-icons/fa';

import { Container } from './styles';

interface IPresentationProps {
  iconSize?: number;
}

const Presentation: React.FC<IPresentationProps> = ({ iconSize }) => {
  return (
    <Container>
      <FaRegFilePowerpoint size={iconSize !== undefined ? iconSize : 60} />
    </Container>
  );
};

export default Presentation;
