import React from 'react';

import TextArea from '~/components/TextArea';

import { Container, Content } from './styles';

interface INoteCustomProjectProps {
  note_custom_project?: string;
}

const NoteCustomProject: React.FC<INoteCustomProjectProps> = ({
  note_custom_project,
}) => {
  return (
    <Container>
      <Content>
        <TextArea
          name="note_custom_project"
          labelFor="note_custom_project"
          labelText="Observação Projeto Personalizado"
          defaultValue={note_custom_project}
          maxLength={1000}
          disabled
        />
      </Content>
    </Container>
  );
};

export default NoteCustomProject;
