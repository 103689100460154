import React, { InputHTMLAttributes, useState, useCallback } from 'react';

import ErrorTooltip from '~/components/Tooltips/Error';

import { Label, Container, InputText } from './styles';

interface IWholeNumberProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  labelFor?: string;
  labelText?: string;
  upperCaseLabel?: boolean;
  error?: string;
  defaultValue?: number;
  disabled?: boolean;
  maxLength?: number;
  onSet?: (e: any) => void;
}

const WholeNumber = React.forwardRef<HTMLInputElement, IWholeNumberProps>(
  (
    {
      name,
      labelFor,
      labelText,
      upperCaseLabel,
      error,
      defaultValue,
      disabled,
      maxLength,
      onSet,
    }: IWholeNumberProps,
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleInputFocus = useCallback(() => {
      setIsFocused(!isFocused);
    }, [isFocused]);

    const handleInputBlur = useCallback(() => {
      setIsFocused(false);
    }, []);

    const handleChange = (e: any): void => {
      if (onSet) {
        onSet(e);
      }
    };

    return (
      <>
        {labelText && (
          <Label isFocused={isFocused}>
            {upperCaseLabel ? labelText.toUpperCase() : labelText}
          </Label>
        )}

        <Container id={labelFor} isFocused={isFocused} isErrored={!!error}>
          <InputText
            name={name}
            type="text"
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            defaultValue={defaultValue}
            ref={ref}
            intlConfig={{ locale: 'pt-BR' }}
            allowDecimals={false}
            allowNegativeValue={false}
            decimalsLimit={0}
            maxLength={maxLength !== undefined ? maxLength : 6}
            onChange={e => {
              handleChange(e);
            }}
            disabled={disabled}
          />

          {error && <ErrorTooltip title={error} />}
        </Container>
      </>
    );
  },
);

export default WholeNumber;
