import React, { ButtonHTMLAttributes } from 'react';

import { FaFileExcel } from 'react-icons/fa';

import { Container, IconWrapper } from './styles';

export interface IReportProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
}

const Report: React.FC<IReportProps> = ({ label, ...rest }) => {
  return (
    <Container {...rest}>
      {label !== undefined ? label : 'Relatório'}

      <IconWrapper>
        <FaFileExcel size={16} />
      </IconWrapper>
    </Container>
  );
};

export default Report;
