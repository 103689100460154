import React, { useState, useEffect } from 'react';
import { PropagateLoader } from 'react-spinners';

import { FaFileAlt } from 'react-icons/fa';

import Paginator from '~/components/Paginator';
import PrevButton from '~/components/Paginator/PrevButton';
import NextButton from '~/components/Paginator/NextButton';
import CenterButton from '~/components/Paginator/CenterButton';
import ProjectSponsorshipSheetInitialStageDetailModal from '~/components/Modals/Projects/SponsorshipSheetInitialStageDetail';

import DefaultInput from '~/components/Inputs/Default';

import api from '~/services/api';

import {
  Container,
  Content,
  Table,
  Th,
  Td,
  DetailBtn,
  ButtonsContent,
} from './styles';

interface ISponsorshipSheetsInitialStageProps {
  project_id: string;
}

interface IProjectSponsorshipSheet {
  id: string;
  sequence: number;
  amount: number;
  sheet_price: number;
  total_price: number;
  sheet_pattern_id: string;
  sheet_thickness_id: string;
  project_id: string;
  user_inclusion_id: string;
  user_update_id: string;
  sheet_pattern: {
    name: string;
  };
  sheet_thickness: {
    description: string;
  };
  project: {
    code: string;
    name: string;
  };
  user_inclusion: {
    name: string;
  };
  user_update: {
    name: string;
  };
  created_at: string;
}

const SponsorshipSheetsInitialStage: React.FC<ISponsorshipSheetsInitialStageProps> =
  ({ project_id }) => {
    const [page, setPage] = useState(0);
    const [pageFilter, setPageFilter] = useState(0);
    const [filter, setFilter] = useState('');
    const [filterHelper, setFilterHelper] = useState('');
    const [refresh, setRefresh] = useState(true);
    const [projectSponsorshipSheets, setProjectSponsorshipSheets] =
      useState<IProjectSponsorshipSheet[]>();
    const [loading, setLoading] = useState<boolean>(true);
    const [nextDisabled, setNextDisabled] = useState(true);
    const [isOpenDetail, setIsOpenDetail] = useState(false);
    const [projectCodeDetail, setProjectCodeDetail] = useState('');
    const [createdAtDetail, setCreatedAtDetail] = useState(new Date());
    const [sheetPatternDetail, setSheetPatternDetail] = useState('');
    const [sheetThicknessDetail, setSheetThicknessDetail] = useState('');
    const [amountDetail, setAmountDetail] = useState(0);
    const [userInclusionDetail, setUserInclusionDetail] = useState('');

    useEffect(() => {
      if (refresh && project_id) {
        setRefresh(false);
        let pageSearch = 0;
        let foundData = false;

        if (filter !== filterHelper) {
          setPage(0);
          setPageFilter(0);
        }

        setFilterHelper(filter);

        if (filter !== '') {
          pageSearch = pageFilter;
        } else {
          pageSearch = page;
        }

        api
          .get(
            `/projectsponsorshipsheets/projectinitialstage?project_id=${project_id}&page=${pageSearch}&filter=${filter}`,
          )
          .then(firstResponse => {
            if (firstResponse.data.length > 0) {
              foundData = true;
              setProjectSponsorshipSheets(firstResponse.data);
              setLoading(false);
            }

            if (!foundData) {
              if (pageSearch - 1 >= 0) {
                pageSearch -= 1;
                if (filter !== '') {
                  setPageFilter(pageFilter - 1);
                } else {
                  setPage(page - 1);
                }
              }

              api
                .get(
                  `/projectsponsorshipsheets/projectinitialstage?project_id=${project_id}&page=${pageSearch}&filter=${filter}`,
                )
                .then(secondResponse => {
                  setProjectSponsorshipSheets(secondResponse.data);
                  setLoading(false);

                  api
                    .get(
                      `/projectsponsorshipsheets/projectinitialstage?project_id=${project_id}&page=${
                        pageSearch + 1
                      }
                      &filter=${filter}`,
                    )
                    .then(thirdResponse => {
                      setNextDisabled(false);
                      if (thirdResponse.data.length === 0) {
                        setNextDisabled(true);
                      }
                    });
                });
            } else {
              api
                .get(
                  `/projectsponsorshipsheets/projectinitialstage?project_id=${project_id}&page=${
                    pageSearch + 1
                  }
                  &filter=${filter}`,
                )
                .then(fourthResponse => {
                  setNextDisabled(false);
                  if (fourthResponse.data.length === 0) {
                    setNextDisabled(true);
                  }
                });
            }
          });
      }
    }, [page, pageFilter, filter, filterHelper, refresh, project_id]);

    function handlePage(type: string): void {
      if (type === 'backward') {
        if (filter !== '') {
          setPageFilter(pageFilter - 1);
        } else {
          setPage(page - 1);
        }
      } else if (type === 'forward') {
        if (filter !== '') {
          setPageFilter(pageFilter + 1);
        } else {
          setPage(page + 1);
        }
      } else if (filter !== '') {
        setPageFilter(0);
      } else {
        setPage(0);
      }
      setRefresh(true);
    }

    function handlePrevButtonDisabled(): boolean {
      let validationPage = 0;

      if (filter !== '') {
        validationPage = pageFilter;
      } else {
        validationPage = page;
      }

      if (validationPage === 0) {
        return true;
      }

      return false;
    }

    function handleFilterChange(value: string): void {
      setFilter(value);
      setRefresh(true);
    }

    function handleTable(): React.ReactNode {
      if (projectSponsorshipSheets) {
        if (projectSponsorshipSheets.length > 0) {
          return (
            <Table>
              <thead>
                <tr>
                  <Th width="30%" textAlign="left">
                    Padrão
                  </Th>
                  <Th width="30%" textAlign="right">
                    Espessura
                  </Th>
                  <Th width="30%" textAlign="right">
                    Quantidade
                  </Th>
                  <Th width="10%" textAlign="center" />
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr>
                    <Td colSpan={4} padding="1rem" textAlign="center">
                      <PropagateLoader color="#276c8d" />
                    </Td>
                  </tr>
                )}
                {projectSponsorshipSheets?.map(projectSponsorshipSheet => (
                  <tr key={projectSponsorshipSheet.id}>
                    <Td width="30%" textAlign="left" heading="PADRÃO">
                      {projectSponsorshipSheet.sheet_pattern.name}
                    </Td>
                    <Td width="30%" textAlign="right" heading="ESPESSURA">
                      {projectSponsorshipSheet.sheet_thickness.description}
                    </Td>
                    <Td width="30%" textAlign="right" heading="QUANTIDADE">
                      {projectSponsorshipSheet.amount}
                    </Td>
                    <Td
                      width="10%"
                      textAlign="center"
                      display="flex"
                      heading={' '}
                    >
                      <DetailBtn
                        onClick={() => {
                          setIsOpenDetail(true);
                          setProjectCodeDetail(
                            projectSponsorshipSheet.project.code,
                          );
                          setCreatedAtDetail(
                            new Date(projectSponsorshipSheet.created_at),
                          );
                          setSheetPatternDetail(
                            projectSponsorshipSheet.sheet_pattern.name,
                          );
                          setSheetThicknessDetail(
                            projectSponsorshipSheet.sheet_thickness.description,
                          );
                          setAmountDetail(projectSponsorshipSheet.amount);
                          setUserInclusionDetail(
                            projectSponsorshipSheet.user_inclusion.name,
                          );
                        }}
                      >
                        <FaFileAlt size={22} />
                      </DetailBtn>
                    </Td>
                  </tr>
                ))}
              </tbody>
            </Table>
          );
        }
      }

      return (
        <Table>
          <thead>
            <tr>
              <Th width="100%" textAlign="center" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <Td width="100%" textAlign="center">
                Nenhuma informação disponível
              </Td>
            </tr>
          </tbody>
        </Table>
      );
    }

    function clearProjectSponsorshipSheetDetail(): void {
      setProjectCodeDetail('');
      setCreatedAtDetail(new Date());
      setSheetPatternDetail('');
      setSheetThicknessDetail('');
      setAmountDetail(0);
      setUserInclusionDetail('');
    }

    if (!project_id) {
      return null;
    }

    return (
      <Container>
        <Content>
          <div>
            <DefaultInput
              name="search"
              labelText="Pesquisar"
              type="text"
              onChange={event => handleFilterChange(event.target.value)}
            />
          </div>

          {handleTable()}

          <ButtonsContent>
            <Paginator>
              <PrevButton
                disabled={handlePrevButtonDisabled()}
                onClick={() => handlePage('backward')}
              />
              <CenterButton onClick={() => handlePage('reset')} />
              <NextButton
                disabled={nextDisabled}
                onClick={() => handlePage('forward')}
              />
            </Paginator>
          </ButtonsContent>
        </Content>

        <ProjectSponsorshipSheetInitialStageDetailModal
          isOpen={isOpenDetail}
          project_code={projectCodeDetail}
          created_at={createdAtDetail}
          sheet_pattern={sheetPatternDetail}
          sheet_thickness={sheetThicknessDetail}
          amount={amountDetail}
          user_inclusion={userInclusionDetail}
          setIsOpen={setIsOpenDetail}
          onClose={() => {
            clearProjectSponsorshipSheetDetail();
          }}
        />
      </Container>
    );
  };

export default SponsorshipSheetsInitialStage;
