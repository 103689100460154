/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FaCheck, FaTimes, FaTools, FaUsers } from 'react-icons/fa';

import { useTheme } from 'styled-components';

import BreadCrumb from '~/components/BreadCrumb';
import DefaultInput from '~/components/Inputs/Default';
import ValidateButton from '~/components/Buttons/Validate';
import EditAltButton from '~/components/Buttons/EditAlt';
import DefaultTooltip from '~/components/Tooltips/Default';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';

import { getFormattedCnpj } from '~/utils';

import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import { Container, Content, Wrapper, ValidationLabel } from './styles';

interface IAccount {
  code: string | undefined;
  validation_desc: string;
  processing: boolean;
  validation: 'success' | 'error' | 'none' | 'empty';
  enabled: boolean;
}

interface ILocation {
  id: string;
  code?: string;
  name?: string;
  company_name?: string;
  document?: string;
  address?: string;
  city?: string;
  state?: string;
  local_representative?: {
    code: string;
    name: string;
  };
  spons_account?: {
    code: string;
    description: string;
  };
  rebate_spons_account?: {
    code: string;
    description: string;
  };
}

const Record: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const { colors } = useTheme();
  const [sponsAccount, setSponsAccount] = useState<IAccount>({
    code: state.spons_account?.code,
    validation_desc:
      state.spons_account?.description || 'Nenhuma conta informada',
    processing: false,
    validation: state.spons_account != null ? 'success' : 'empty',
    enabled: !state.spons_account,
  });
  const [rebateSponsAccount, setRebateSponsAccount] = useState<IAccount>({
    code: state.rebate_spons_account?.code,
    validation_desc:
      state.rebate_spons_account?.description || 'Nenhuma conta informada',
    processing: false,
    validation: state.rebate_spons_account != null ? 'success' : 'empty',
    enabled: !state.rebate_spons_account,
  });
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const validateSponsAccount = useCallback(
    async (code: string | undefined) => {
      try {
        setSponsAccount({ ...sponsAccount, processing: true, enabled: false });

        await api.get(`/accounts/validate/${code}`).then(response => {
          setSponsAccount({
            ...sponsAccount,
            validation_desc: response.data.description,
            processing: false,
            validation: 'success',
            enabled: false,
          });
        });
      } catch (error: any) {
        setSponsAccount({
          ...sponsAccount,
          validation_desc: 'Conta inválida',
          processing: false,
          validation: 'error',
          enabled: true,
        });
      }
    },
    [sponsAccount],
  );

  const validateRebateSponsAccount = useCallback(
    async (code: string | undefined) => {
      try {
        setRebateSponsAccount({
          ...rebateSponsAccount,
          processing: true,
          enabled: false,
        });

        await api.get(`/accounts/validate/${code}`).then(response => {
          setRebateSponsAccount({
            ...rebateSponsAccount,
            validation_desc: response.data.description,
            processing: false,
            validation: 'success',
            enabled: false,
          });
        });
      } catch (error: any) {
        setRebateSponsAccount({
          ...rebateSponsAccount,
          validation_desc: 'Conta inválida',
          processing: false,
          validation: 'error',
          enabled: true,
        });
      }
    },
    [rebateSponsAccount],
  );

  function foundInvalidAccounts(): boolean {
    return (
      sponsAccount.processing ||
      sponsAccount.validation === 'error' ||
      sponsAccount.validation === 'none' ||
      rebateSponsAccount.processing ||
      rebateSponsAccount.validation === 'error' ||
      rebateSponsAccount.validation === 'none'
    );
  }

  const submitForm = useCallback(async () => {
    try {
      setIsSaving(true);

      await api.put('/localclients/accounts', {
        id: state.id,
        spons_account: sponsAccount.code,
        rebate_spons_account: rebateSponsAccount.code,
      });

      addToast({
        type: 'success',
        title: 'Cadastro atualizado!',
        description: 'Cliente atualizado com sucesso.',
      });

      history.push('/management/localclients');
    } catch (error: any) {
      setIsSaving(false);

      addToast({
        type: 'error',
        title: 'Ocorreu um erro!',
        description: error.response.data.message,
      });
    }
  }, [addToast, history, rebateSponsAccount, sponsAccount, state]);

  return (
    <Container>
      <BreadCrumb
        main="Administração"
        path="/management/main"
        firstChildPath="/management/localclients"
        firstChild="Clientes"
        secondChild="Edição de Cliente"
        mainIcon={FaTools}
        firstChildIcon={FaUsers}
        secondChildIcon={FaUsers}
        mainColored
        firstChildColored
      />

      <Content>
        <Wrapper style={{ width: 150 }}>
          <DefaultInput
            name="code"
            labelFor="code"
            labelText="Código"
            type="text"
            value={state?.code}
            disabled
          />
        </Wrapper>

        <Wrapper style={{ width: 450 }}>
          <DefaultInput
            name="name"
            labelFor="name"
            labelText="Nome"
            type="text"
            value={state?.company_name}
            disabled
          />
        </Wrapper>

        <Wrapper style={{ width: 200 }}>
          <DefaultInput
            name="document"
            labelFor="document"
            labelText="CNPJ"
            type="text"
            value={getFormattedCnpj(state?.document || '')}
            disabled
          />
        </Wrapper>

        <Wrapper style={{ width: 350 }}>
          <DefaultInput
            name="address"
            labelFor="address"
            labelText="Endereço"
            type="text"
            value={state?.address}
            disabled
          />
        </Wrapper>

        <Wrapper style={{ width: 250 }}>
          <DefaultInput
            name="city"
            labelFor="city"
            labelText="Cidade"
            type="text"
            value={state?.city}
            disabled
          />
        </Wrapper>

        <Wrapper style={{ width: 150 }}>
          <DefaultInput
            name="state"
            labelFor="state"
            labelText="Estado"
            type="text"
            value={state?.state}
            disabled
          />
        </Wrapper>

        <Wrapper style={{ width: 150 }}>
          <DefaultInput
            name="rep_code"
            labelFor="rep_code"
            labelText="Código Rep."
            type="text"
            value={state?.local_representative?.code}
            disabled
          />
        </Wrapper>

        <Wrapper style={{ width: 450 }}>
          <DefaultInput
            name="rep_name"
            labelFor="rep_name"
            labelText="Nome Rep."
            type="text"
            value={state?.local_representative?.name}
            disabled
          />
        </Wrapper>
      </Content>

      <form>
        <div style={{ maxWidth: 900, marginTop: 30 }}>
          <div
            style={{
              display: 'flex',
              columnGap: '1rem',
              alignItems: 'end',
            }}
          >
            <div>
              <DefaultInput
                name="spons_account"
                labelFor="spons_account"
                labelText="Cód. Cta. Ctbl. Pagamentos"
                type="text"
                defaultValue={state?.spons_account?.code}
                disabled={!sponsAccount.enabled}
                maxLength={8}
                onChange={e => {
                  setSponsAccount({
                    ...sponsAccount,
                    code: e.target.value,
                    validation:
                      !e.target.value || e.target.value === ''
                        ? 'empty'
                        : 'none',
                    validation_desc:
                      !e.target.value || e.target.value === ''
                        ? 'Nenhuma conta informada'
                        : sponsAccount.validation_desc,
                  });
                }}
              />
            </div>

            <ValidateButton
              processing={sponsAccount.processing}
              disabled={
                sponsAccount.validation === 'success' ||
                sponsAccount.validation === 'empty' ||
                sponsAccount.processing
              }
              onClick={() => validateSponsAccount(sponsAccount.code)}
            />

            <EditAltButton
              onClick={() => {
                setSponsAccount({ ...sponsAccount, enabled: true });
              }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              marginTop: 5,
              columnGap: '0.5rem',
              alignItems: 'center',
            }}
          >
            <ValidationLabel>{sponsAccount.validation_desc}</ValidationLabel>

            {sponsAccount.validation === 'success' && (
              <FaCheck size={16} color={colors.validate} />
            )}

            {sponsAccount.validation === 'error' && (
              <FaTimes size={16} color={colors.danger} />
            )}
          </div>
        </div>

        <div style={{ maxWidth: 900, marginTop: 30 }}>
          <div
            style={{
              display: 'flex',
              columnGap: '1rem',
              alignItems: 'end',
            }}
          >
            <div>
              <DefaultInput
                name="rebate_spons_account"
                labelFor="rebate_spons_account"
                labelText="Cód. Cta. Ctbl. Pag. Rebate"
                type="text"
                defaultValue={state?.rebate_spons_account?.code}
                disabled={!rebateSponsAccount.enabled}
                maxLength={8}
                onChange={e => {
                  setRebateSponsAccount({
                    ...rebateSponsAccount,
                    code: e.target.value,
                    validation:
                      !e.target.value || e.target.value === ''
                        ? 'empty'
                        : 'none',
                    validation_desc:
                      !e.target.value || e.target.value === ''
                        ? 'Nenhuma conta informada'
                        : rebateSponsAccount.validation_desc,
                  });
                }}
              />
            </div>

            <ValidateButton
              processing={rebateSponsAccount.processing}
              disabled={
                rebateSponsAccount.validation === 'success' ||
                rebateSponsAccount.validation === 'empty' ||
                rebateSponsAccount.processing
              }
              onClick={() => {
                validateRebateSponsAccount(rebateSponsAccount.code);
              }}
            />

            <EditAltButton
              onClick={() => {
                setRebateSponsAccount({ ...rebateSponsAccount, enabled: true });
              }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              marginTop: 5,
              columnGap: '0.5rem',
              alignItems: 'center',
            }}
          >
            <ValidationLabel>
              {rebateSponsAccount.validation_desc}
            </ValidationLabel>

            {rebateSponsAccount.validation === 'success' && (
              <FaCheck size={16} color={colors.validate} />
            )}

            {rebateSponsAccount.validation === 'error' && (
              <FaTimes size={16} color={colors.danger} />
            )}
          </div>
        </div>

        <div
          style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}
        >
          <DefaultTooltip
            title="Existem contas inválidas ou não validadas"
            background={colors.save}
            borderColor={colors.save}
            disabled={!foundInvalidAccounts()}
          >
            <SaveButton
              type="button"
              processing={isSaving}
              disabled={isSaving || foundInvalidAccounts()}
              onClick={() => {
                setIsOpenConfirmProcedure(true);
              }}
            />
          </DefaultTooltip>

          <ExitButton
            style={{ marginLeft: 10 }}
            type="button"
            disabled={isSaving}
            onClick={() => setIsOpenExit(true)}
          />
        </div>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={() => submitForm()}
        title="Confirma edição do cliente?"
        subTitle=""
        opConfirm="Sim"
        type=""
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={() => history.push('/management/localclients')}
      />
    </Container>
  );
};

export default Record;
