import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { FaAngleDown } from 'react-icons/fa';

import InfoInput from '~/components/Inputs/Info';

import { MainContainer, Label, Container, Content, Wrapper } from './styles';

interface IPricesProps {
  item_amount?: number;
  unit_price?: number;
  price?: number;
  shipping_price?: number;
  total_price?: number;
}

const Prices: React.FC<IPricesProps> = ({
  item_amount,
  unit_price,
  price,
  shipping_price,
  total_price,
}) => {
  function handleItemAmount(): React.ReactNode {
    if (item_amount !== undefined) {
      return (
        <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
          <InfoInput
            name="item_amount"
            labelFor="item_amount"
            labelText="Quantidade"
            type="text"
            defaultValue={
              item_amount
                ? new Intl.NumberFormat('pt-BR', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(item_amount)
                : new Intl.NumberFormat('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(0)
            }
            disabled
          />
        </Wrapper>
      );
    }

    return <></>;
  }

  function handleUnitPrice(): React.ReactNode {
    if (unit_price !== undefined) {
      return (
        <Wrapper style={{ width: '50%' }}>
          <InfoInput
            name="unit_price"
            labelFor="unit_price"
            labelText="Valor Unitário"
            type="text"
            defaultValue={
              unit_price
                ? new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(unit_price)
                : new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(0)
            }
            disabled
          />
        </Wrapper>
      );
    }

    return <></>;
  }

  function handleFirstExtraFields(): React.ReactNode {
    if (item_amount !== undefined || unit_price !== undefined) {
      return (
        <Content>
          {handleItemAmount()}
          {handleUnitPrice()}
        </Content>
      );
    }

    return <></>;
  }

  return (
    <MainContainer>
      <Accordion>
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Label>Valores</Label>
        </AccordionSummary>

        <AccordionDetails>
          <Container>
            {handleFirstExtraFields()}

            <Content>
              <Wrapper style={{ width: '33%', marginRight: '1rem' }}>
                <InfoInput
                  name="price"
                  labelFor="price"
                  labelText="Valor"
                  type="text"
                  defaultValue={
                    price
                      ? new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(price)
                      : new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(0)
                  }
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '33%', marginRight: '1rem' }}>
                <InfoInput
                  name="shipping_price"
                  labelFor="shipping_price"
                  labelText="Valor Frete"
                  type="text"
                  defaultValue={
                    shipping_price
                      ? new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(shipping_price)
                      : new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(0)
                  }
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '33%' }}>
                <InfoInput
                  name="total_price"
                  labelFor="total_price"
                  labelText="Valor Total"
                  type="text"
                  defaultValue={
                    total_price
                      ? new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(total_price)
                      : new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(0)
                  }
                  disabled
                />
              </Wrapper>
            </Content>
          </Container>
        </AccordionDetails>
      </Accordion>
    </MainContainer>
  );
};

export default Prices;
