export default function getFormattedCnpj(cnpj: string): string {
  let formattedCnpj = cnpj;

  formattedCnpj = formattedCnpj.replace(/\D/g, '');
  formattedCnpj = formattedCnpj.replace(/(\d{2})(\d)/, '$1.$2');
  formattedCnpj = formattedCnpj.replace(/(\d{3})(\d)/, '$1.$2');
  formattedCnpj = formattedCnpj.replace(/(\d{3})(\d)/, '$1/$2');
  formattedCnpj = formattedCnpj.replace(/(\d{4})(\d)/, '$1-$2');
  formattedCnpj = formattedCnpj.replace(/(-\d{2})\d+?$/, '$1');

  return formattedCnpj;
}
