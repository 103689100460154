import styled, { css } from 'styled-components';

interface IContainerProps {
  isFocused: boolean;
}

export const Label = styled.label<IContainerProps>`
  ${({ theme, isFocused }) => css`
    font-size: ${theme.fontSizes.default};
    color: ${theme.colors.labelInput};

    ${isFocused &&
    css`
      color: ${theme.colors.primary};
    `}
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    font-size: ${theme.fontSizes.large};
  `}
`;

export const WindowedSelectWrapper = styled.div`
  width: 100%;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
`;
