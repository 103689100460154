import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import {
  FaFileUpload,
  FaTrashAlt,
  FaExclamationCircle,
  FaCheckCircle,
  FaLink,
} from 'react-icons/fa';

import AudioIcon from '~/components/Icons/Files/Audio';
import PdfIcon from '~/components/Icons/Files/Pdf';
import PresentationIcon from '~/components/Icons/Files/Presentation';
import SpreadsheetIcon from '~/components/Icons/Files/Spreadsheet';
import TextIcon from '~/components/Icons/Files/Text';
import VideoIcon from '~/components/Icons/Files/Video';
import WordIcon from '~/components/Icons/Files/Word';

import {
  useCustomProjectFiles,
  ICustomProjectFile,
} from '~/context/customprojectfiles';

import {
  Container,
  FileRow,
  FileContainer,
  FilePreviewWrapper,
  FilePreviewImageWrapper,
  FilePreviewImage,
  FileInfoWrapper,
  FileInfoName,
  FileInfoFileSize,
  FileActions,
  DeleteBtn,
  UrlIcon,
  SuccessIcon,
  ErrorIcon,
} from './styles';

const List = (): JSX.Element => {
  const {
    uploadedCustomProjectFiles: customProjectFiles,
    deleteCustomProjectFile,
  } = useCustomProjectFiles();

  if (!customProjectFiles.length) {
    return (
      <span>
        <FaFileUpload
          style={{ marginLeft: '45%', marginTop: 15 }}
          size={35}
          color="#adadad"
        />
      </span>
    );
  }

  function handleCustomProjectFilePreview(
    uploadedCustomProjectFile: any,
  ): React.ReactNode {
    if (
      uploadedCustomProjectFile.type === 'audio/mp3' ||
      uploadedCustomProjectFile.type === 'audio/mpeg'
    ) {
      return <AudioIcon />;
    }

    if (uploadedCustomProjectFile.type === 'application/pdf') {
      return <PdfIcon />;
    }

    if (
      uploadedCustomProjectFile.type ===
        'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
      uploadedCustomProjectFile.type ===
        'application/vnd.oasis.opendocument.presentation'
    ) {
      return <PresentationIcon />;
    }

    if (
      uploadedCustomProjectFile.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      uploadedCustomProjectFile.type ===
        'application/vnd.oasis.opendocument.spreadsheet'
    ) {
      return <SpreadsheetIcon />;
    }

    if (uploadedCustomProjectFile.type === 'text/plain') {
      return <TextIcon />;
    }

    if (
      uploadedCustomProjectFile.type === 'image/gif' ||
      uploadedCustomProjectFile.type === 'video/quicktime' ||
      uploadedCustomProjectFile.type === 'video/mp4'
    ) {
      return <VideoIcon />;
    }

    if (
      uploadedCustomProjectFile.type ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      uploadedCustomProjectFile.type ===
        'application/vnd.oasis.opendocument.text'
    ) {
      return <WordIcon />;
    }

    return (
      <FilePreviewImageWrapper>
        <FilePreviewImage src={uploadedCustomProjectFile.preview} />
      </FilePreviewImageWrapper>
    );
  }

  return (
    <Container>
      {customProjectFiles.map(
        (uploadedCustomProjectFile: ICustomProjectFile) => (
          <FileRow key={uploadedCustomProjectFile.id}>
            <FileContainer>
              <FilePreviewWrapper>
                {handleCustomProjectFilePreview(uploadedCustomProjectFile)}
              </FilePreviewWrapper>

              <FileInfoWrapper>
                <FileInfoName>{uploadedCustomProjectFile.name}</FileInfoName>

                <FileInfoFileSize>
                  {uploadedCustomProjectFile.readableSize}
                </FileInfoFileSize>
              </FileInfoWrapper>
            </FileContainer>

            <FileActions>
              {!uploadedCustomProjectFile.uploaded &&
                !uploadedCustomProjectFile.error && (
                  <CircularProgressbar
                    styles={{
                      root: { width: 24 },
                      path: { stroke: '#7159c1' },
                    }}
                    strokeWidth={10}
                    text={String(uploadedCustomProjectFile.progress)}
                    value={uploadedCustomProjectFile.progress || 0}
                  />
                )}

              {uploadedCustomProjectFile.url && (
                <UrlIcon>
                  <a
                    href={uploadedCustomProjectFile.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLink size={20} />
                  </a>
                </UrlIcon>
              )}

              {uploadedCustomProjectFile.uploaded && (
                <SuccessIcon>
                  <FaCheckCircle size={24} />
                </SuccessIcon>
              )}
              {uploadedCustomProjectFile.error && (
                <ErrorIcon>
                  <FaExclamationCircle size={24} />
                </ErrorIcon>
              )}
              {!!uploadedCustomProjectFile.url && (
                <DeleteBtn
                  onClick={() => {
                    deleteCustomProjectFile(uploadedCustomProjectFile.id);
                  }}
                >
                  <FaTrashAlt size={24} />
                </DeleteBtn>
              )}
            </FileActions>
          </FileRow>
        ),
      )}
    </Container>
  );
};

export default List;
