import React, { ButtonHTMLAttributes } from 'react';
import { MdExitToApp } from 'react-icons/md';

import { Container } from './styles';

interface IExitProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
}

const Exit: React.FC<IExitProps> = ({ label, ...rest }) => {
  return (
    <Container {...rest}>
      {label || 'Sair'} <MdExitToApp size={20} />
    </Container>
  );
};

export default Exit;
