import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import {
  FaFileUpload,
  FaTrashAlt,
  FaExclamationCircle,
  FaCheckCircle,
  FaLink,
} from 'react-icons/fa';

import AudioIcon from '~/components/Icons/Files/Audio';
import PdfIcon from '~/components/Icons/Files/Pdf';
import PresentationIcon from '~/components/Icons/Files/Presentation';
import SpreadsheetIcon from '~/components/Icons/Files/Spreadsheet';
import TextIcon from '~/components/Icons/Files/Text';
import VideoIcon from '~/components/Icons/Files/Video';
import WordIcon from '~/components/Icons/Files/Word';

import { useFiles, IFile } from '~/context/files';

import {
  Container,
  FileRow,
  FileContainer,
  FilePreviewWrapper,
  FilePreviewImageWrapper,
  FilePreviewImage,
  FileInfoWrapper,
  FileInfoName,
  FileInfoFileSize,
  FileActions,
  DeleteBtn,
  UrlIcon,
  SuccessIcon,
  ErrorIcon,
} from './styles';

const List = (): JSX.Element => {
  const { uploadedFiles: files, deleteFile } = useFiles();

  if (!files.length) {
    return (
      <span>
        <FaFileUpload
          style={{ marginLeft: '45%', marginTop: 15 }}
          size={35}
          color="#adadad"
        />
      </span>
    );
  }

  function handleFilePreview(uploadedFile: any): React.ReactNode {
    if (
      uploadedFile.type === 'audio/mp3' ||
      uploadedFile.type === 'audio/mpeg'
    ) {
      return <AudioIcon />;
    }

    if (uploadedFile.type === 'application/pdf') {
      return <PdfIcon />;
    }

    if (
      uploadedFile.type ===
        'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
      uploadedFile.type === 'application/vnd.oasis.opendocument.presentation'
    ) {
      return <PresentationIcon />;
    }

    if (
      uploadedFile.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      uploadedFile.type === 'application/vnd.oasis.opendocument.spreadsheet'
    ) {
      return <SpreadsheetIcon />;
    }

    if (uploadedFile.type === 'text/plain') {
      return <TextIcon />;
    }

    if (
      uploadedFile.type === 'image/gif' ||
      uploadedFile.type === 'video/quicktime' ||
      uploadedFile.type === 'video/mp4'
    ) {
      return <VideoIcon />;
    }

    if (
      uploadedFile.type ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      uploadedFile.type === 'application/vnd.oasis.opendocument.text'
    ) {
      return <WordIcon />;
    }

    return (
      <FilePreviewImageWrapper>
        <FilePreviewImage src={uploadedFile.preview} />
      </FilePreviewImageWrapper>
    );
  }

  return (
    <Container>
      {files.map((uploadedFile: IFile) => (
        <FileRow key={uploadedFile.id}>
          <FileContainer>
            <FilePreviewWrapper>
              {handleFilePreview(uploadedFile)}
            </FilePreviewWrapper>

            <FileInfoWrapper>
              <FileInfoName>{uploadedFile.name}</FileInfoName>
              <FileInfoFileSize>{uploadedFile.readableSize}</FileInfoFileSize>
            </FileInfoWrapper>
          </FileContainer>

          <FileActions>
            {!uploadedFile.uploaded && !uploadedFile.error && (
              <CircularProgressbar
                styles={{
                  root: { width: 24 },
                  path: { stroke: '#7159c1' },
                }}
                strokeWidth={10}
                text={String(uploadedFile.progress)}
                value={uploadedFile.progress || 0}
              />
            )}

            {uploadedFile.url && (
              <UrlIcon>
                <a
                  href={uploadedFile.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLink size={20} />
                </a>
              </UrlIcon>
            )}

            {uploadedFile.uploaded && (
              <SuccessIcon>
                <FaCheckCircle size={24} />
              </SuccessIcon>
            )}
            {uploadedFile.error && (
              <ErrorIcon>
                <FaExclamationCircle size={24} />
              </ErrorIcon>
            )}
            {!!uploadedFile.url && (
              <DeleteBtn onClick={() => deleteFile(uploadedFile.id)}>
                <FaTrashAlt size={24} />
              </DeleteBtn>
            )}
          </FileActions>
        </FileRow>
      ))}
    </Container>
  );
};

export default List;
