import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

export type IDeleteAltProps = ButtonHTMLAttributes<HTMLButtonElement>;

const DeleteAlt: React.FC<IDeleteAltProps> = ({ ...rest }) => {
  return (
    <Container type="button" {...rest}>
      Excluir
    </Container>
  );
};

export default DeleteAlt;
