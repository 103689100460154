import React, { ButtonHTMLAttributes } from 'react';
import { FaCheck } from 'react-icons/fa';
import { ClipLoader } from 'react-spinners';

import { Container } from './styles';

interface IValidateProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  processing?: boolean;
}

const Validate: React.FC<IValidateProps> = ({
  label,
  processing = false,
  ...rest
}) => {
  return (
    <Container type="button" {...rest}>
      {label || 'Validar'}

      {processing ? (
        <ClipLoader color="#fff" size={20} />
      ) : (
        <FaCheck size={16} />
      )}
    </Container>
  );
};

export default Validate;
