import React, { useState, useCallback } from 'react';
import { OptionTypeBase, Props as SelectProps } from 'react-select';
import WindowedSelect from 'react-windowed-select';

import { useTheme } from 'styled-components';

import ErrorTooltip from '~/components/Tooltips/Error';

import {
  Label,
  Container,
  WindowedSelectWrapper,
  TooltipWrapper,
} from './styles';

interface ITableSelectProps extends SelectProps<OptionTypeBase> {
  name: string;
  label: string;
  upperCaseLabel?: boolean;
  disabled?: boolean;
  options:
    | {
        value: string;
        label: string;
      }[]
    | undefined;
  onSet?: (e: any) => void;
}

const TableSelect = React.forwardRef<HTMLSelectElement, ITableSelectProps>(
  (
    {
      name,
      label,
      upperCaseLabel,
      disabled,
      options,
      onSet,
      loading,
      error,
      ...rest
    },
    ref,
  ) => {
    const { colors } = useTheme();
    const [focused, setFocused] = useState(false);

    function getOptionBackgroundColor(
      isSelected: boolean,
      isFocused: boolean,
    ): string {
      if (isSelected) {
        return colors.darkGrey;
      }
      if (isFocused) {
        return colors.grey;
      }

      return colors.lightGrey;
    }

    const customStyles = {
      zIndex: 999,
      option: (provided: any, state: any) => ({
        ...provided,
        borderBottom: `1px dotted ${colors.grey}`,
        color: state.isSelected ? colors.lightGrey : '#000000',
        padding: 6,
        fontSize: 14,
        backgroundColor: getOptionBackgroundColor(
          state.isSelected,
          state.isFocused,
        ),
        '&:hover': {
          background: state.isSelected ? colors.darkGrey : colors.grey,
        },
      }),
      control: (provided: any) => ({
        ...provided,
        background: colors.lightGrey,
        borderColor: '#e4e4e4',
        padding: 1,
        margin: '0',
        height: 33,
        minHeight: 33,
        fontSize: 14,
      }),
      valueContainer: (provided: any) => ({
        ...provided,
        height: 28,
        minHeight: 28,
        background: colors.lightGrey,
      }),
      input: (provided: any) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorsContainer: (provided: any) => ({
        ...provided,
        height: 28,
        minHeight: 28,
        background: colors.lightGrey,
      }),
      menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
    };

    const handleFocus = useCallback(() => {
      setFocused(!focused);
    }, [focused]);

    const handleBlur = useCallback(() => {
      setFocused(false);
    }, []);

    const handleChange = (e: any): void => {
      if (onSet) {
        onSet(e);
      }
    };

    return (
      <>
        {label && (
          <Label htmlFor={name} isFocused={focused}>
            {upperCaseLabel ? label.toUpperCase() : label}
          </Label>
        )}

        <Container>
          <WindowedSelectWrapper>
            <WindowedSelect
              name={name}
              placeholder="Selecione ..."
              options={options}
              isLoading={loading}
              inputRef={ref}
              isDisabled={disabled}
              styles={customStyles}
              menuPortalTarget={document.body}
              noOptionsMessage={() => {
                return 'Nenhuma informação disponível';
              }}
              theme={(theme: any) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: colors.primary,
                },
              })}
              onChange={(e: any) => {
                handleChange(e);
              }}
              onFocus={handleFocus}
              onBlur={handleBlur}
              {...rest}
            />
          </WindowedSelectWrapper>

          <TooltipWrapper>
            {error && <ErrorTooltip title={error} />}
          </TooltipWrapper>
        </Container>
      </>
    );
  },
);

export default TableSelect;
