import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { FaProjectDiagram, FaListAlt } from 'react-icons/fa';
import { PropagateLoader } from 'react-spinners';

import BreadCrumb from '~/components/BreadCrumb';
import DefaultInput from '~/components/Inputs/Default';
import SelectButton from '~/components/Buttons/Select';
import Paginator from '~/components/Paginator';
import PrevButton from '~/components/Paginator/PrevButton';
import NextButton from '~/components/Paginator/NextButton';
import CenterButton from '~/components/Paginator/CenterButton';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  FilterContent,
  Table,
  Thead,
  Tbody,
  Th,
  TbodyTr,
  Td,
  ButtonsContent,
} from './styles';

interface IRepresentative {
  code: string;
  name: string;
}

const ListRepresentatives: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const [page, setPage] = useState(1);
  const [pageFilter, setPageFilter] = useState(1);
  const [filter, setFilter] = useState('');
  const [filterHelper, setFilterHelper] = useState('');
  const [refresh, setRefresh] = useState(true);
  const [representatives, setRepresentatives] = useState<IRepresentative[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [nextDisabled, setNextDisabled] = useState(true);

  useEffect(() => {
    const checkEnabledFunctionality = async () => {
      await api.get('/generalsettings').then(response => {
        if (response.data && !response.data.new_problem_rep_enabled) {
          history.push('/');
        }
      });
    };

    checkEnabledFunctionality();
  }, [history]);

  useEffect(() => {
    if (
      user.user_type.type !== 'MK1' &&
      user.user_type.type !== 'MK2' &&
      user.user_type.type !== 'MAX' &&
      user.user_type.type !== 'GCM' &&
      user.user_type.type !== 'ADM'
    ) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Tipo de usuário não permitido',
      });

      history.push('/projects/adversities');
    }
  }, [addToast, history, user.user_type.type]);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      let pageSearch = 1;
      let foundData = false;

      if (filter !== filterHelper) {
        setPage(1);
        setPageFilter(1);
      }

      setFilterHelper(filter);

      if (filter !== '') {
        pageSearch = pageFilter;
      } else {
        pageSearch = page;
      }

      api
        .get(`/representatives?page=${pageSearch}&filter=${filter}`)
        .then(firstResponse => {
          if (firstResponse.data.length > 0) {
            foundData = true;
            setRepresentatives(firstResponse.data);
            setLoading(false);
          }

          if (!foundData) {
            if (pageSearch - 1 >= 1) {
              pageSearch -= 1;
              if (filter !== '') {
                setPageFilter(pageFilter - 1);
              } else {
                setPage(page - 1);
              }
            }

            api
              .get(`/representatives?page=${pageSearch}&filter=${filter}`)
              .then(secondResponse => {
                setRepresentatives(secondResponse.data);
                setLoading(false);

                api
                  .get(
                    `/representatives?page=${pageSearch + 1}&filter=${filter}`,
                  )
                  .then(thirdResponse => {
                    setNextDisabled(false);
                    if (thirdResponse.data.length === 0) {
                      setNextDisabled(true);
                    }
                  });
              });
          } else {
            api
              .get(`/representatives?page=${pageSearch + 1}&filter=${filter}`)
              .then(fourthResponse => {
                setNextDisabled(false);
                if (fourthResponse.data.length === 0) {
                  setNextDisabled(true);
                }
              });
          }
        });
    }
  }, [page, pageFilter, filter, filterHelper, refresh]);

  function handlePage(type: string): void {
    if (type === 'backward') {
      if (filter !== '') {
        setPageFilter(pageFilter - 1);
      } else {
        setPage(page - 1);
      }
    } else if (type === 'forward') {
      if (filter !== '') {
        setPageFilter(pageFilter + 1);
      } else {
        setPage(page + 1);
      }
    } else if (filter !== '') {
      setPageFilter(1);
    } else {
      setPage(1);
    }
    setRefresh(true);
  }

  function handlePrevButtonDisabled(): boolean {
    let validationPage = 1;

    if (filter !== '') {
      validationPage = pageFilter;
    } else {
      validationPage = page;
    }

    if (validationPage === 1) {
      return true;
    }

    return false;
  }

  function handleFilterChange(value: string): void {
    setFilter(value);
    setRefresh(true);
  }

  function handleTable(): React.ReactNode {
    if (representatives) {
      if (representatives.length > 0) {
        return (
          <Table>
            <Thead>
              <tr>
                <Th width="20%" textAlign="left">
                  Código
                </Th>
                <Th width="60%" textAlign="left">
                  Representante
                </Th>
                <Th width="20%" textAlign="center" />
              </tr>
            </Thead>
            <Tbody>
              {loading && (
                <tr>
                  <Td colSpan={4} padding="1rem" textAlign="center">
                    <PropagateLoader color="#276c8d" />
                  </Td>
                </tr>
              )}
              {representatives?.map(representative => (
                <TbodyTr key={representative.code}>
                  <Td width="20%" textAlign="left" heading="CÓDIGO">
                    {representative.code}
                  </Td>
                  <Td width="60%" textAlign="left" heading="REPRESENTANTE">
                    {representative.name}
                  </Td>
                  <Td
                    width="20%"
                    textAlign="center"
                    display="flex"
                    heading={' '}
                  >
                    <Link
                      to={{
                        pathname: `/projects/adversities/procedure1/client`,
                        state: representative,
                      }}
                    >
                      <SelectButton />
                    </Link>
                  </Td>
                </TbodyTr>
              ))}
            </Tbody>
          </Table>
        );
      }
    }

    return (
      <Table>
        <Thead>
          <tr>
            <Th width="100%" textAlign="center" />
          </tr>
        </Thead>
        <Tbody>
          <TbodyTr>
            <Td width="100%" textAlign="center">
              Nenhuma informação disponível
            </Td>
          </TbodyTr>
        </Tbody>
      </Table>
    );
  }

  return (
    <Container style={{ minHeight: 600 }}>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/adversities"
        firstChild="Problemas"
        secondChild="Novo Reporte"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaListAlt}
        secondChildIcon={FaListAlt}
        mainColored
        firstChildColored
      />

      <FilterContent>
        <DefaultInput
          name="search"
          labelText="Pesquisar"
          type="text"
          onChange={event => handleFilterChange(event.target.value)}
        />
      </FilterContent>

      {handleTable()}

      <ButtonsContent>
        <Paginator>
          <PrevButton
            disabled={handlePrevButtonDisabled()}
            onClick={() => handlePage('backward')}
          />
          <CenterButton onClick={() => handlePage('reset')} />
          <NextButton
            disabled={nextDisabled}
            onClick={() => handlePage('forward')}
          />
        </Paginator>
      </ButtonsContent>
    </Container>
  );
};

export default ListRepresentatives;
