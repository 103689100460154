import React, { Dispatch, SetStateAction } from 'react';

import InfoInput from '~/components/Inputs/Info';
import CloseButton from '~/components/Buttons/Close';

import {
  StyledModal,
  MainContainer,
  Title,
  Container,
  ContentSplit,
  HalfWrapper,
  ButtonContainer,
} from './styles';

interface ISponsorshipSheetInitialStageDetailProps {
  isOpen: boolean;
  onClose: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  project_code: string;
  created_at: Date;
  sheet_pattern: string;
  sheet_thickness: string;
  amount: number;
  user_inclusion: string;
}

const SponsorshipSheetInitialStageDetail: React.FC<ISponsorshipSheetInitialStageDetailProps> =
  ({
    isOpen,
    onClose,
    setIsOpen,
    project_code,
    created_at,
    sheet_pattern,
    sheet_thickness,
    amount,
    user_inclusion,
  }: ISponsorshipSheetInitialStageDetailProps) => {
    const handleClose = (): void => {
      setIsOpen(false);
      onClose();
    };

    return (
      <StyledModal
        isOpen={isOpen}
        style={{
          overlay: {
            zIndex: 999,
            overflowY: 'auto',
            position: 'fixed',
            background: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <MainContainer>
          <Title>Detalhes Chapas de MDF</Title>
          <Container>
            <ContentSplit maxWidth="680px">
              <HalfWrapper marginRight="1rem">
                <InfoInput
                  name="project_code"
                  labelFor="project_code"
                  labelText="Cód. Projeto"
                  type="text"
                  defaultValue={project_code}
                  disabled
                />
              </HalfWrapper>

              <HalfWrapper>
                <InfoInput
                  name="user_inclusion"
                  labelFor="user_inclusion"
                  labelText="Usuário"
                  type="text"
                  defaultValue={user_inclusion}
                  disabled
                />
              </HalfWrapper>
            </ContentSplit>

            <ContentSplit maxWidth="680px">
              <HalfWrapper marginRight="1rem">
                <InfoInput
                  name="created_at"
                  labelFor="created_at"
                  labelText="Data Inclusão"
                  type="text"
                  defaultValue={new Date(created_at).toLocaleDateString()}
                  disabled
                />
              </HalfWrapper>

              <HalfWrapper>
                <InfoInput
                  name="sheet_pattern"
                  labelFor="sheet_pattern"
                  labelText="Padrão"
                  type="text"
                  defaultValue={sheet_pattern}
                  disabled
                />
              </HalfWrapper>
            </ContentSplit>

            <ContentSplit maxWidth="680px">
              <HalfWrapper marginRight="1rem">
                <InfoInput
                  name="sheet_thickness"
                  labelFor="sheet_thickness"
                  labelText="Espessura"
                  type="text"
                  defaultValue={sheet_thickness}
                  disabled
                />
              </HalfWrapper>

              <HalfWrapper>
                <InfoInput
                  name="amount"
                  labelFor="amount"
                  labelText="Quantidade"
                  type="text"
                  defaultValue={new Intl.NumberFormat('pt-BR', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(amount)}
                  disabled
                />
              </HalfWrapper>
            </ContentSplit>

            <ButtonContainer>
              <CloseButton onClick={() => handleClose()} />
            </ButtonContainer>
          </Container>
        </MainContainer>
      </StyledModal>
    );
  };

export default SponsorshipSheetInitialStageDetail;
