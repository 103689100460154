import React, { useState, useEffect } from 'react';

import Display from './Types/Display';
import Material from './Types/Material';
import Sponsorship from './Types/Sponsorship';
import Adversity from './Types/Adversity';
import Folder from './Types/Folder';
import Sample from './Types/Sample';
import Event from './Types/Event';

import api from '~/services/api';

interface IDefaultProps {
  project_id: string;
  all?: boolean;
}

interface IProject {
  id: string;
  type: string;
}

const Default: React.FC<IDefaultProps> = ({ project_id, all }) => {
  const [project, setProject] = useState<IProject>();

  useEffect(() => {
    api.get(`/projects/id?id=${project_id}`).then(response => {
      setProject(response.data);
    });
  }, [project_id]);

  function handleProjectType(): React.ReactNode | null {
    if (project) {
      if (project.type === 'DISPLAY') {
        return <Display project_id={project.id} all={all} />;
      }

      if (project.type === 'MATERIAL') {
        return <Material project_id={project.id} all={all} />;
      }

      if (project.type === 'PATROCINIO') {
        return <Sponsorship project_id={project.id} all={all} />;
      }

      if (project.type === 'ADVERSIDADE') {
        return <Adversity project_id={project.id} all={all} />;
      }

      if (project.type === 'PASTA') {
        return <Folder project_id={project.id} all={all} />;
      }

      if (project.type === 'AMOSTRA') {
        return <Sample project_id={project.id} all={all} />;
      }

      if (project.type === 'EVENTO') {
        return <Event project_id={project.id} all={all} />;
      }
    }

    return null;
  }

  return <>{handleProjectType()}</>;
};

export default Default;
