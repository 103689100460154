import React, { useState, useEffect } from 'react';
import { PropagateLoader } from 'react-spinners';

import { FaLink } from 'react-icons/fa';

import Paginator from '~/components/Paginator';
import PrevButton from '~/components/Paginator/PrevButton';
import NextButton from '~/components/Paginator/NextButton';
import CenterButton from '~/components/Paginator/CenterButton';

import DefaultInput from '~/components/Inputs/Default';

import api from '~/services/api';

import {
  Container,
  Content,
  Table,
  Th,
  Td,
  FilePreview,
  UrlIcon,
  ButtonsContent,
} from './styles';

interface IApprovalFilesProps {
  project_id: string;
}

interface IProjectApprovalFile {
  id: string;
  name: string;
  size: number;
  key: string;
  url: string;
  created_at: string;
}

const ApprovalFiles: React.FC<IApprovalFilesProps> = ({ project_id }) => {
  const [page, setPage] = useState(0);
  const [pageFilter, setPageFilter] = useState(0);
  const [filter, setFilter] = useState('');
  const [filterHelper, setFilterHelper] = useState('');
  const [refresh, setRefresh] = useState(true);
  const [projectApprovalFiles, setProjectApprovalFiles] =
    useState<IProjectApprovalFile[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [nextDisabled, setNextDisabled] = useState(true);

  useEffect(() => {
    if (refresh && project_id) {
      setRefresh(false);
      let pageSearch = 0;
      let foundData = false;

      if (filter !== filterHelper) {
        setPage(0);
        setPageFilter(0);
      }

      setFilterHelper(filter);

      if (filter !== '') {
        pageSearch = pageFilter;
      } else {
        pageSearch = page;
      }

      api
        .get(
          `/projectapprovalfiles/project?project_id=${project_id}&page=${pageSearch}&filter=${filter}`,
        )
        .then(firstResponse => {
          if (firstResponse.data.length > 0) {
            foundData = true;
            setProjectApprovalFiles(firstResponse.data);
            setLoading(false);
          }

          if (!foundData) {
            if (pageSearch - 1 >= 0) {
              pageSearch -= 1;
              if (filter !== '') {
                setPageFilter(pageFilter - 1);
              } else {
                setPage(page - 1);
              }
            }

            api
              .get(
                `/projectapprovalfiles/project?project_id=${project_id}&page=${pageSearch}&filter=${filter}`,
              )
              .then(secondResponse => {
                setProjectApprovalFiles(secondResponse.data);
                setLoading(false);

                api
                  .get(
                    `/projectapprovalfiles/project?project_id=${project_id}&page=${
                      pageSearch + 1
                    }
                      &filter=${filter}`,
                  )
                  .then(thirdResponse => {
                    setNextDisabled(false);
                    if (thirdResponse.data.length === 0) {
                      setNextDisabled(true);
                    }
                  });
              });
          } else {
            api
              .get(
                `/projectapprovalfiles/project?project_id=${project_id}&page=${
                  pageSearch + 1
                }
                  &filter=${filter}`,
              )
              .then(fourthResponse => {
                setNextDisabled(false);
                if (fourthResponse.data.length === 0) {
                  setNextDisabled(true);
                }
              });
          }
        });
    }
  }, [page, pageFilter, filter, filterHelper, refresh, project_id]);

  function handlePage(type: string): void {
    if (type === 'backward') {
      if (filter !== '') {
        setPageFilter(pageFilter - 1);
      } else {
        setPage(page - 1);
      }
    } else if (type === 'forward') {
      if (filter !== '') {
        setPageFilter(pageFilter + 1);
      } else {
        setPage(page + 1);
      }
    } else if (filter !== '') {
      setPageFilter(0);
    } else {
      setPage(0);
    }
    setRefresh(true);
  }

  function handlePrevButtonDisabled(): boolean {
    let validationPage = 0;

    if (filter !== '') {
      validationPage = pageFilter;
    } else {
      validationPage = page;
    }

    if (validationPage === 0) {
      return true;
    }

    return false;
  }

  function handleFilterChange(value: string): void {
    setFilter(value);
    setRefresh(true);
  }

  function handleTable(): React.ReactNode {
    if (projectApprovalFiles) {
      if (projectApprovalFiles.length > 0) {
        return (
          <Table>
            <thead>
              <tr>
                <Th width="30%" textAlign="left" />
                <Th width="20%" textAlign="left">
                  Data
                </Th>
                <Th width="40%" textAlign="left">
                  Nome
                </Th>
                <Th width="10%" textAlign="center" />
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <Td colSpan={4} padding="1rem" textAlign="center">
                    <PropagateLoader color="#276c8d" />
                  </Td>
                </tr>
              )}
              {projectApprovalFiles?.map(projectApprovalFile => (
                <tr key={projectApprovalFile.id}>
                  <Td width="15%" textAlign="left" heading={' '}>
                    <FilePreview src={projectApprovalFile.url} />
                  </Td>
                  <Td width="20%" textAlign="left" heading="DATA">
                    {new Date(
                      projectApprovalFile.created_at,
                    ).toLocaleDateString()}
                  </Td>
                  <Td width="60%" textAlign="left" heading="NOME">
                    {projectApprovalFile.name.substring(0, 100)}
                  </Td>
                  <Td
                    width="10%"
                    textAlign="center"
                    display="flex"
                    heading={' '}
                  >
                    <UrlIcon>
                      <a
                        href={projectApprovalFile.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaLink size={22} />
                      </a>
                    </UrlIcon>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        );
      }
    }

    return (
      <Table>
        <thead>
          <tr>
            <Th width="100%" textAlign="center" />
          </tr>
        </thead>
        <tbody>
          <tr>
            <Td width="100%" textAlign="center">
              Nenhuma informação disponível
            </Td>
          </tr>
        </tbody>
      </Table>
    );
  }

  if (!project_id) {
    return null;
  }

  return (
    <Container>
      <Content>
        <div>
          <DefaultInput
            name="search"
            labelText="Pesquisar"
            type="text"
            onChange={event => handleFilterChange(event.target.value)}
          />
        </div>

        {handleTable()}

        <ButtonsContent>
          <Paginator>
            <PrevButton
              disabled={handlePrevButtonDisabled()}
              onClick={() => handlePage('backward')}
            />
            <CenterButton onClick={() => handlePage('reset')} />
            <NextButton
              disabled={nextDisabled}
              onClick={() => handlePage('forward')}
            />
          </Paginator>
        </ButtonsContent>
      </Content>
    </Container>
  );
};

export default ApprovalFiles;
