import styled, { css } from 'styled-components';

interface IOptionProps {
  isActive: boolean;
  marginTop?: string;
  marginLeft?: string;
}

export const Container = styled.button<IOptionProps>`
  ${({ theme, isActive, marginTop, marginLeft }) => css`
    width: 22rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.2rem 6rem;
    background-color: ${theme.colors.veryDarkGrey};
    color: #fff;
    border-radius: ${theme.radius.default};
    font-size: 1.4;
    font-weight: 400;

    :hover {
      background: ${theme.colors.primary};
      transition: 0.2s;
    }

    ${isActive &&
    css`
      background-color: ${theme.colors.primary};
    `}

    ${marginTop &&
    css`
      margin-top: ${marginTop};
    `}

    ${marginLeft &&
    css`
      margin-left: ${marginLeft};
    `}
  `}
`;
