import React from 'react';

import MainContent from './MainContent';
import DefaultLayout from '../_layouts/default';

const Management = (): JSX.Element => {
  return (
    <DefaultLayout>
      <MainContent />
    </DefaultLayout>
  );
};

export default Management;
