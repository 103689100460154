import React from 'react';

import {
  FaBox,
  FaDollyFlatbed,
  FaMoneyBillWave,
  FaListAlt,
  FaFolder,
  FaBoxOpen,
  FaCalendarAlt,
} from 'react-icons/fa';

export default {
  Displays: {
    icon: <FaBox size={32} />,
    link: '/projects/displays',
    backgroundColor: '#747474',
  },
  Materiais: {
    icon: <FaDollyFlatbed size={32} />,
    link: '/projects/materials',
    backgroundColor: '#747474',
  },
  Pagamentos: {
    icon: <FaMoneyBillWave size={32} />,
    link: '/projects/sponsorships',
    backgroundColor: '#747474',
  },
  'Reportar um Problema': {
    icon: <FaListAlt size={32} />,
    link: '/projects/adversities',
    backgroundColor: '#747474',
  },
  Pastas: {
    icon: <FaFolder size={32} />,
    link: '/projects/folders',
    backgroundColor: '#747474',
  },
  Amostras: {
    icon: <FaBoxOpen size={32} />,
    link: '/projects/samples',
    backgroundColor: '#747474',
  },
  Eventos: {
    icon: <FaCalendarAlt size={32} />,
    link: '/projects/events',
    backgroundColor: '#747474',
  },
};
