import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.grey};
    font-size: ${theme.fontSizes.large};
    font-weight: ${theme.weight.strong};
    justify-content: center;
    margin-left: 0.5rem;

    > span {
      color: ${theme.colors.grey};
      font-size: ${theme.fontSizes.large};
      font-weight: bold;
      justify-content: center;
      margin: 0 5px 0 5px;
    }
  `}
`;
