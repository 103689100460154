import React from 'react';
import { FaRegFileWord } from 'react-icons/fa';

import { Container } from './styles';

interface IWordProps {
  iconSize?: number;
}

const Word: React.FC<IWordProps> = ({ iconSize }) => {
  return (
    <Container>
      <FaRegFileWord size={iconSize !== undefined ? iconSize : 60} />
    </Container>
  );
};

export default Word;
