import React from 'react';

import Upload from './Upload';
import List from './List';

import { Container, Content } from './styles';

import { StoreRelationshipTopicFileProvider } from '~/context/storerelationshiptopicfiles';

interface IUploadStoreRelationshipTopicFilesProps {
  type: string;
  store_relationship_topic_temp_id: string;
  user_id: string;
  isDisabled?: boolean;
}

const UploadStoreRelationshipTopicFiles: React.FC<IUploadStoreRelationshipTopicFilesProps> =
  ({ type, store_relationship_topic_temp_id, user_id, isDisabled }) => {
    return (
      <StoreRelationshipTopicFileProvider>
        <Container>
          <Content>
            <Upload
              type={type}
              store_relationship_topic_temp_id={
                store_relationship_topic_temp_id
              }
              user_id={user_id}
              isDisabled={isDisabled}
            />
            <List />
          </Content>
        </Container>
      </StoreRelationshipTopicFileProvider>
    );
  };

export default UploadStoreRelationshipTopicFiles;
