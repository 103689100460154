import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight, FaTools } from 'react-icons/fa';

import { useAuth } from '~/hooks/auth';

import BreadCrumb from '~/components/BreadCrumb';

import {
  AnimatedContainer,
  Management,
  ManagementCardWrapper,
  ManagementCard,
  ManagementIcon,
  ManagementName,
  GoIcon,
} from './styles';
import { MANAGEMENT_NAVIGATION_ANIMATION } from './animations';

import { MANAGEMENT_NAVIGATION } from '~/constants';

const MainContent: React.FC = () => {
  const { user } = useAuth();

  function getValidItem(key: any, value: any): React.ReactNode | null {
    if (key === 'Alteração Manual de Solicitação') {
      if (user.user_type.type !== 'ADM' && !user.admin_functions) {
        return null;
      }
    }

    if (key === 'Exclusão de Solicitação') {
      if (user.user_type.type !== 'ADM') {
        return null;
      }
    }

    if (key === 'Clientes') {
      if (user.user_type.type !== 'ADM' && !user.edit_local_client_perm) {
        return null;
      }
    }

    if (key === 'Acessos Aplicativos Mobile') {
      if (user.user_type.type !== 'ADM') {
        return null;
      }
    }

    if (key === 'Configurações Gerais') {
      if (
        user.user_type.type !== 'ADM' &&
        !user.enable_new_display_req &&
        !user.enable_new_material_req &&
        !user.enable_new_sponsorship_req &&
        !user.enable_new_problem_rep &&
        !user.enable_new_folder_req &&
        !user.enable_new_sample_req &&
        !user.enable_new_event_req
      ) {
        return null;
      }
    }

    return (
      <ManagementCardWrapper key={`wrapper-${key}`}>
        <Link to={value.link}>
          <ManagementCard
            key={`card-${key}`}
            backgroundColor={value.backgroundColor}
          >
            <ManagementIcon>{value.icon}</ManagementIcon>

            <ManagementName>{key}</ManagementName>

            <GoIcon>
              <FaArrowRight size={20} />
            </GoIcon>
          </ManagementCard>
        </Link>
      </ManagementCardWrapper>
    );
  }

  return (
    <AnimatedContainer variants={MANAGEMENT_NAVIGATION_ANIMATION}>
      <BreadCrumb
        main="Administração"
        path="/management/main"
        mainIcon={FaTools}
      />
      <Management>
        {Object.entries(MANAGEMENT_NAVIGATION).map(([key, value]) =>
          getValidItem(key, value),
        )}
      </Management>
    </AnimatedContainer>
  );
};

export default MainContent;
