import React, { useState, useCallback } from 'react';
import { OptionTypeBase, Props as SelectProps } from 'react-select';
import WindowedSelect from 'react-windowed-select';

import { useTheme } from 'styled-components';

import ErrorTooltip from '~/components/Tooltips/Error';

import {
  Label,
  Container,
  WindowedSelectWrapper,
  TooltipWrapper,
} from './styles';

interface ISelectProps extends SelectProps<OptionTypeBase> {
  name: string;
  label: string;
  disabled?: boolean;
  options:
    | {
        value: string;
        label: string;
      }[]
    | undefined;
  darkBorder: boolean;
  onSet?: (e: any) => void;
}

const Select = React.forwardRef<HTMLSelectElement, ISelectProps>(
  (
    {
      name,
      label,
      disabled,
      options,
      darkBorder,
      onSet,
      loading,
      error,
      ...rest
    },
    ref,
  ) => {
    const { colors } = useTheme();
    const [isFocusedLabel, setIsFocusedLabel] = useState(false);

    function getOptionBackgroundColor(
      isSelected: boolean,
      isFocused: boolean,
    ): string {
      if (isSelected) {
        return colors.darkGrey;
      }
      if (isFocused) {
        return colors.grey;
      }

      return colors.lightGrey;
    }

    function getBorderColor(
      error_border: boolean,
      dark_border: boolean,
    ): string {
      if (error_border) {
        return colors.error;
      }
      if (dark_border) {
        return colors.grey;
      }

      return colors.veryLightGrey;
    }

    const customStyles = {
      zIndex: 999,
      option: (provided: any, state: any) => ({
        ...provided,
        borderBottom: `1px dotted ${colors.grey}`,
        color: state.isSelected ? colors.lightGrey : '#000000',
        padding: 6,
        fontSize: 16,
        backgroundColor: getOptionBackgroundColor(
          state.isSelected,
          state.isFocused,
        ),
        '&:hover': {
          background: state.isSelected ? colors.darkGrey : colors.grey,
        },
      }),
      control: (provided: any) => ({
        ...provided,
        background: colors.lightGrey,
        borderColor: getBorderColor(error, darkBorder),
        padding: 1,
        margin: '0',
        height: 50,
        minHeight: 50,
        fontSize: 16,
        '&:hover': {
          borderColor: darkBorder ? colors.darkGrey : colors.grey,
        },
      }),
      valueContainer: (provided: any) => ({
        ...provided,
        height: 45,
        minHeight: 45,
        background: colors.lightGrey,
      }),
      input: (provided: any) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorsContainer: (provided: any) => ({
        ...provided,
        height: 45,
        minHeight: 45,
        background: colors.lightGrey,
      }),
      menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
    };

    const handleChange = (e: any): void => {
      if (onSet) {
        onSet(e);
      }
    };

    const handleInputFocus = useCallback(() => {
      setIsFocusedLabel(!isFocusedLabel);
    }, [isFocusedLabel]);

    const handleInputBlur = useCallback(() => {
      setIsFocusedLabel(false);
    }, []);

    return (
      <>
        <Label htmlFor={name} isFocused={isFocusedLabel}>
          {label}
        </Label>

        <Container>
          <WindowedSelectWrapper>
            <WindowedSelect
              name={name}
              placeholder="Selecione ..."
              options={options}
              isLoading={loading}
              inputRef={ref}
              isDisabled={disabled}
              styles={customStyles}
              menuPortalTarget={document.body}
              noOptionsMessage={() => {
                return 'Nenhuma informação disponível';
              }}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              theme={(theme: any) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: colors.primary,
                },
              })}
              onChange={(e: any) => {
                handleChange(e);
              }}
              {...rest}
            />
          </WindowedSelectWrapper>

          <TooltipWrapper>
            {error && <ErrorTooltip title={error} />}
          </TooltipWrapper>
        </Container>
      </>
    );
  },
);

export default Select;
