import React from 'react';
import { Link } from 'react-router-dom';
import { FiBarChart2 } from 'react-icons/fi';

import { useAuth } from '~/hooks/auth';

import Accordion from './Accordion';
import AccountProjects from './AccountProjects';

import { DEFAULT_TRANSITION, SIDE_BAR_NAVIGATION } from '~/constants';

import { AnimatedContainer, AnimatedUserProjects, Wrapper } from './styles';

const animation = {
  unMounted: { opacity: 0, y: -50 },
  mounted: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.5, ...DEFAULT_TRANSITION },
  },
};

const SideBar: React.FC = () => {
  const { user } = useAuth();

  function getValidSideBarItem(key: any, value: any): React.ReactNode | null {
    if (key === 'Solicitações') {
      if (user.user_type.type === 'REP') {
        return (
          <Accordion
            key={key}
            icon={value.icon}
            link={value.link}
            sectionName="Nova Solicitação"
          />
        );
      }
    }
    if (key === 'Itens') {
      if (
        user.user_type.type !== 'ADM' &&
        user.user_type.type !== 'MK1' &&
        user.user_type.type !== 'MK2' &&
        user.user_type.type !== 'IMK'
      ) {
        return null;
      }
    }
    if (key === 'Lojas') {
      if (
        user.user_type.type !== 'ADM' &&
        user.user_type.type !== 'MK1' &&
        user.user_type.type !== 'MK2' &&
        user.user_type.type !== 'MAX' &&
        user.user_type.type !== 'REP' &&
        user.user_type.type !== 'GMK' &&
        user.user_type.type !== 'DGR' &&
        user.user_type.type !== 'DCM' &&
        user.user_type.type !== 'IMK' &&
        user.user_type.type !== 'GCM'
      ) {
        return null;
      }
    }
    if (key === 'Usuários') {
      if (user.user_type.type !== 'ADM') {
        return <></>;
      }
    }
    if (key === 'Configurações') {
      if (
        user.user_type.type !== 'ADM' &&
        user.user_type.type !== 'MK1' &&
        user.user_type.type !== 'MK2' &&
        user.user_type.type !== 'MAX' &&
        user.user_type.type !== 'IMK'
      ) {
        return null;
      }
    }
    if (key === 'Administração') {
      if (user.user_type.type !== 'ADM' && !user.admin_functions) {
        return null;
      }
    }

    return (
      <Accordion
        key={key}
        icon={value.icon}
        link={value.link}
        sectionName={key}
      />
    );
  }

  return (
    <Wrapper>
      <AnimatedContainer variants={animation}>
        <AccountProjects />
        <Link to="/projects/all/usertypepending">
          <AnimatedUserProjects
            whileHover={{ y: -4, transition: DEFAULT_TRANSITION }}
            whileTap={{ y: 2, transition: DEFAULT_TRANSITION }}
          >
            Todos Pendentes Comigo
            <FiBarChart2 size="1.6rem" />
          </AnimatedUserProjects>
        </Link>

        {Object.entries(SIDE_BAR_NAVIGATION).map(([key, value]) =>
          getValidSideBarItem(key, value),
        )}
      </AnimatedContainer>
    </Wrapper>
  );
};

export default SideBar;
