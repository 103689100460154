import React, { useState, useEffect } from 'react';

import api from '~/services/api';

import InfoInput from '~/components/Inputs/Info';

import { Container, Content, Wrapper } from './styles';

interface IRepresentativeProps {
  erp_rep_code: string;
}

interface IRepresentative {
  code: string;
  name: string;
}

const Representative: React.FC<IRepresentativeProps> = ({ erp_rep_code }) => {
  const [representative, setRepresentative] = useState<IRepresentative>();

  useEffect(() => {
    if (erp_rep_code !== '') {
      api.get(`/representatives/code?code=${erp_rep_code}`).then(response => {
        setRepresentative(response.data);
      });
    }
  }, [erp_rep_code]);

  return (
    <Container>
      <Content>
        <Wrapper>
          <InfoInput
            name="code"
            labelFor="code"
            labelText="Código"
            type="text"
            defaultValue={representative?.code ? representative.code : ''}
            disabled
          />
        </Wrapper>

        <Wrapper>
          <InfoInput
            name="name"
            labelFor="name"
            labelText="Nome"
            type="text"
            defaultValue={representative?.name ? representative.name : ''}
            disabled
          />
        </Wrapper>
      </Content>
    </Container>
  );
};

export default Representative;
