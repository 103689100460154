import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { motion } from 'framer-motion';

export const Wrapper = styled.div`
  max-width: 28rem;
  height: 100%;
  display: flex;
  flex: 1 100%;
  flex-direction: column;

  @media (max-width: 670px) {
    max-width: 100%;
  }
`;

export const AnimatedContainer = styled(motion.section)`
  ${({ theme }) => css`
    background: linear-gradient(
      180deg,
      ${theme.colors.sideBar} 65%,
      ${theme.colors.sideBarLight} 100%
    );
    width: 100%;
    height: 100%;
    border-radius: ${theme.radius.small};
    box-shadow: ${theme.shadows.default};
    padding: 2.4rem;
  `}
`;

export const AnimatedUserProjects = styled(motion.button)`
  && {
    ${({ theme }) => css`
      display: flex;
      justify-content: space-between;
      color: ${theme.colors.white};
      font-size: ${theme.fontSizes.small};
      width: 100%;
      margin: 0.8rem 0;
      padding: 0.8rem 2.4rem;
      border-radius: ${theme.radius.small};
      transition: box-shadow 300ms ease-out;
      background: ${theme.colors.sideBarButton};

      &:hover {
        box-shadow: 0 0.4rem 0.4rem
          ${transparentize(0.72, theme.colors.sideBarButton)};
      }
    `}
  }
`;
