import styled, { css } from 'styled-components';

interface IContentProps {
  maxWidth: string;
}

interface IWrapperProps {
  marginRight?: string;
}

export const Container = styled.section`
  ${({ theme }) => css`
    background: ${theme.colors.background};
    border-radius: ${theme.radius.default};
    box-shadow: ${theme.shadows.flat};
    padding: 2.4rem;
    width: 100%;
    margin: 2.4rem 0;
  `}
`;

export const Content = styled.div<IContentProps>`
  ${({ maxWidth }) => css`
    display: flex;
    justify-content: flex-start;

    ${maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
  `}
`;

export const ContentSplit = styled(Content)`
  @media (max-width: 1010px) {
    flex-direction: column;
  }
`;

export const Wrapper = styled.div<IWrapperProps>`
  ${({ marginRight }) => css`
    width: 100%;
    margin-bottom: 2rem;

    ${marginRight &&
    css`
      margin-right: ${marginRight};
    `}

    @media (max-width: 1010px) {
      margin: 0 0 2rem 0;
    }
  `}
`;

export const HalfWrapper = styled(Wrapper)`
  width: 50%;

  @media (max-width: 1010px) {
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20;
`;
