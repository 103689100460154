import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { FaAngleDown } from 'react-icons/fa';

import InfoInput from '~/components/Inputs/Info';

import { useAuth } from '~/hooks/auth';

import { MainContainer, Label, Container, Content, Wrapper } from './styles';

interface ITplpProps {
  tplp_name?: string;
  tplp_email?: string;
}

const Tplp: React.FC<ITplpProps> = ({ tplp_name, tplp_email }) => {
  const { user } = useAuth();

  if (user.user_type.type === 'REP') {
    return null;
  }

  return (
    <MainContainer>
      <Accordion>
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Label>Operador Logístico</Label>
        </AccordionSummary>

        <AccordionDetails>
          <Container>
            <Content>
              <Wrapper>
                <InfoInput
                  name="tplp_name"
                  labelFor="tplp_name"
                  labelText="Nome"
                  type="text"
                  defaultValue={tplp_name !== undefined ? tplp_name : ''}
                  disabled
                />
              </Wrapper>
            </Content>

            <Content>
              <Wrapper>
                <InfoInput
                  name="tplp_email"
                  labelFor="tplp_email"
                  labelText="E-mail"
                  type="text"
                  defaultValue={tplp_email !== undefined ? tplp_email : ''}
                  disabled
                />
              </Wrapper>
            </Content>
          </Container>
        </AccordionDetails>
      </Accordion>
    </MainContainer>
  );
};

export default Tplp;
