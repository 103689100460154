import React, { useState, useEffect } from 'react';
import { PropagateLoader } from 'react-spinners';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { FaAngleDown, FaFileAlt } from 'react-icons/fa';

import Paginator from '~/components/Paginator';
import PrevButton from '~/components/Paginator/PrevButton';
import NextButton from '~/components/Paginator/NextButton';
import CenterButton from '~/components/Paginator/CenterButton';
import ProjectSentInvoiceDetailModal from '~/components/Modals/Projects/SentInvoiceDetail';

import DefaultInput from '~/components/Inputs/Default';

import api from '~/services/api';

import {
  Container,
  Label,
  Wrapper,
  Table,
  Th,
  Td,
  DetailBtn,
  ButtonsContent,
} from './styles';

interface ISentInvoicesProps {
  project_id: string;
}

interface IProjectSentInvoice {
  id: string;
  number: string;
  series: string;
  issue_date: Date;
  net_price: number;
  amount: number;
  sequence: number;
  project_id: string;
  user_inclusion_id: string;
  user_update_id: string;
  project: {
    code: string;
    name: string;
  };
  user_inclusion: {
    name: string;
  };
  user_update: {
    name: string;
  };
  created_at: string;
}

const SentInvoices: React.FC<ISentInvoicesProps> = ({ project_id }) => {
  const [page, setPage] = useState(0);
  const [pageFilter, setPageFilter] = useState(0);
  const [filter, setFilter] = useState('');
  const [filterHelper, setFilterHelper] = useState('');
  const [refresh, setRefresh] = useState(true);
  const [projectSentInvoices, setProjectSentInvoices] =
    useState<IProjectSentInvoice[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [projectCodeDetail, setProjectCodeDetail] = useState('');
  const [numberDetail, setNumberDetail] = useState('');
  const [seriesDetail, setSeriesDetail] = useState('');
  const [issueDateDetail, setIssueDateDetail] = useState(new Date());
  const [createdAtDetail, setCreatedAtDetail] = useState(new Date());
  const [netPriceDetail, setNetPriceDetail] = useState(0);
  const [amountDetail, setAmountDetail] = useState(0);
  const [userInclusionDetail, setUserInclusionDetail] = useState('');

  useEffect(() => {
    if (refresh && project_id) {
      setRefresh(false);
      let pageSearch = 0;
      let foundData = false;

      if (filter !== filterHelper) {
        setPage(0);
        setPageFilter(0);
      }

      setFilterHelper(filter);

      if (filter !== '') {
        pageSearch = pageFilter;
      } else {
        pageSearch = page;
      }

      api
        .get(
          `/projectsentinvoices/project?project_id=${project_id}&page=${pageSearch}&filter=${filter}`,
        )
        .then(firstResponse => {
          if (firstResponse.data.length > 0) {
            foundData = true;
            setProjectSentInvoices(firstResponse.data);
            setLoading(false);
          }

          if (!foundData) {
            if (pageSearch - 1 >= 0) {
              pageSearch -= 1;
              if (filter !== '') {
                setPageFilter(pageFilter - 1);
              } else {
                setPage(page - 1);
              }
            }

            api
              .get(
                `/projectsentinvoices/project?project_id=${project_id}&page=${pageSearch}&filter=${filter}`,
              )
              .then(secondResponse => {
                setProjectSentInvoices(secondResponse.data);
                setLoading(false);

                api
                  .get(
                    `/projectsentinvoices/project?project_id=${project_id}&page=${
                      pageSearch + 1
                    }
                    &filter=${filter}`,
                  )
                  .then(thirdResponse => {
                    setNextDisabled(false);
                    if (thirdResponse.data.length === 0) {
                      setNextDisabled(true);
                    }
                  });
              });
          } else {
            api
              .get(
                `/projectsentinvoices/project?project_id=${project_id}&page=${
                  pageSearch + 1
                }
                &filter=${filter}`,
              )
              .then(fourthResponse => {
                setNextDisabled(false);
                if (fourthResponse.data.length === 0) {
                  setNextDisabled(true);
                }
              });
          }
        });
    }
  }, [page, pageFilter, filter, filterHelper, refresh, project_id]);

  function handlePage(type: string): void {
    if (type === 'backward') {
      if (filter !== '') {
        setPageFilter(pageFilter - 1);
      } else {
        setPage(page - 1);
      }
    } else if (type === 'forward') {
      if (filter !== '') {
        setPageFilter(pageFilter + 1);
      } else {
        setPage(page + 1);
      }
    } else if (filter !== '') {
      setPageFilter(0);
    } else {
      setPage(0);
    }
    setRefresh(true);
  }

  function handlePrevButtonDisabled(): boolean {
    let validationPage = 0;

    if (filter !== '') {
      validationPage = pageFilter;
    } else {
      validationPage = page;
    }

    if (validationPage === 0) {
      return true;
    }

    return false;
  }

  function handleFilterChange(value: string): void {
    setFilter(value);
    setRefresh(true);
  }

  function handleTable(): React.ReactNode {
    if (projectSentInvoices) {
      if (projectSentInvoices.length > 0) {
        return (
          <Table>
            <thead>
              <tr>
                <Th width="20%" textAlign="left">
                  Data Emissão
                </Th>
                <Th width="15%" textAlign="left">
                  Número
                </Th>
                <Th width="15%" textAlign="left">
                  Série
                </Th>
                <Th width="20%" textAlign="left">
                  Valor NF
                </Th>
                <Th width="20%" textAlign="left">
                  Quantidade
                </Th>
                <Th width="10%" textAlign="center" />
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <Td colSpan={4} padding="1rem" textAlign="center">
                    <PropagateLoader color="#276c8d" />
                  </Td>
                </tr>
              )}
              {projectSentInvoices?.map(projectSentInvoice => (
                <tr key={projectSentInvoice.id}>
                  <Td width="20%" textAlign="left" heading="DATA EMISSÃO">
                    {new Date(
                      projectSentInvoice.issue_date,
                    ).toLocaleDateString()}
                  </Td>
                  <Td width="15%" textAlign="left" heading="NÚMERO">
                    {projectSentInvoice.number}
                  </Td>
                  <Td width="15%" textAlign="left" heading="SÉRIE">
                    {projectSentInvoice.series}
                  </Td>
                  <Td width="20%" textAlign="left" heading="VALOR NF">
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(projectSentInvoice.net_price)}
                  </Td>
                  <Td width="20%" textAlign="left" heading="QUANTIDADE">
                    {new Intl.NumberFormat('pt-BR', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(projectSentInvoice.amount)}
                  </Td>
                  <Td
                    width="10%"
                    textAlign="center"
                    display="flex"
                    heading={' '}
                  >
                    <DetailBtn
                      onClick={() => {
                        setIsOpenDetail(true);
                        setProjectCodeDetail(projectSentInvoice.project.code);
                        setNumberDetail(projectSentInvoice.number);
                        setSeriesDetail(projectSentInvoice.series);
                        setIssueDateDetail(projectSentInvoice.issue_date);
                        setCreatedAtDetail(
                          new Date(projectSentInvoice.created_at),
                        );
                        setNetPriceDetail(projectSentInvoice.net_price);
                        setAmountDetail(projectSentInvoice.amount);
                        setUserInclusionDetail(
                          projectSentInvoice.user_inclusion.name,
                        );
                      }}
                    >
                      <FaFileAlt size={22} />
                    </DetailBtn>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        );
      }
    }

    return (
      <Table>
        <thead>
          <tr>
            <Th width="100%" textAlign="center" />
          </tr>
        </thead>
        <tbody>
          <tr>
            <Td width="100%" textAlign="center">
              Nenhuma informação disponível
            </Td>
          </tr>
        </tbody>
      </Table>
    );
  }

  function clearProjectSentInvoiceDetail(): void {
    setProjectCodeDetail('');
    setNumberDetail('');
    setSeriesDetail('');
    setIssueDateDetail(new Date());
    setCreatedAtDetail(new Date());
    setNetPriceDetail(0);
    setAmountDetail(0);
    setUserInclusionDetail('');
  }

  if (!project_id) {
    return null;
  }

  return (
    <Container>
      <Accordion>
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Label>Notas Fiscais Enviadas</Label>
        </AccordionSummary>

        <AccordionDetails>
          <Wrapper>
            <div>
              <DefaultInput
                name="search"
                labelText="Pesquisar"
                type="text"
                onChange={event => handleFilterChange(event.target.value)}
              />
            </div>

            {handleTable()}

            <ButtonsContent>
              <Paginator>
                <PrevButton
                  disabled={handlePrevButtonDisabled()}
                  onClick={() => handlePage('backward')}
                />
                <CenterButton onClick={() => handlePage('reset')} />
                <NextButton
                  disabled={nextDisabled}
                  onClick={() => handlePage('forward')}
                />
              </Paginator>
            </ButtonsContent>
          </Wrapper>
        </AccordionDetails>
      </Accordion>

      <ProjectSentInvoiceDetailModal
        isOpen={isOpenDetail}
        project_code={projectCodeDetail}
        number={numberDetail}
        series={seriesDetail}
        issue_date={issueDateDetail}
        created_at={createdAtDetail}
        net_price={netPriceDetail}
        amount={amountDetail}
        user_inclusion={userInclusionDetail}
        setIsOpen={setIsOpenDetail}
        onClose={() => {
          clearProjectSentInvoiceDetail();
        }}
      />
    </Container>
  );
};

export default SentInvoices;
