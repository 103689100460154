import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useStoreRelationshipDisplayFiles } from '~/context/storerelationshipdisplayfiles';

import { DropContainer, UploadMessage } from './styles';

interface IUploadProps {
  type: string;
  store_relationship_display_temp_id: string;
  user_id: string;
  isDisabled?: boolean;
}

function Upload({
  type,
  store_relationship_display_temp_id,
  user_id,
  isDisabled,
}: IUploadProps): JSX.Element {
  const { handleUpload } = useStoreRelationshipDisplayFiles();

  const onDrop = useCallback(
    storeRelationshipDisplayFiles => {
      handleUpload(
        storeRelationshipDisplayFiles,
        type,
        store_relationship_display_temp_id,
        user_id,
      );
    },
    [handleUpload, type, store_relationship_display_temp_id, user_id],
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      accept: [
        'image/jpeg',
        'image/pjpeg',
        'image/png',
        'image/gif',
        'application/pdf',
        'audio/mp3',
        'audio/mpeg',
        'video/quicktime',
        'video/mp4',
        'text/plain',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.oasis.opendocument.spreadsheet',
        'application/vnd.oasis.opendocument.presentation',
        'application/vnd.oasis.opendocument.text',
      ],
      onDrop,
      disabled: isDisabled,
    });

  const renderDragMessage = useCallback(() => {
    if (!isDragActive) {
      return <UploadMessage>Arraste os arquivos aqui...</UploadMessage>;
    }

    if (isDragReject) {
      return (
        <UploadMessage type="error">
          Tipo de arquivo não suportado
        </UploadMessage>
      );
    }

    return <UploadMessage type="success">Solte os arquivos aqui</UploadMessage>;
  }, [isDragActive, isDragReject]);

  return (
    <DropContainer {...getRootProps()} isDisabled={isDisabled}>
      <input {...getInputProps()} />
      {renderDragMessage()}
    </DropContainer>
  );
}

export default Upload;
