import styled, { css } from 'styled-components';

export const Container = styled.section`
  ${({ theme }) => css`
    background: ${theme.colors.background};
    border-radius: ${theme.radius.default};
    box-shadow: ${theme.shadows.flat};
    padding: 2.4rem;
    width: 100%;
    margin: 2.4rem 0;
  `}
`;

export const Content = styled.div`
  max-width: 1000px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const Wrapper = styled.div`
  margin: 0rem 0.5rem 2rem 0.5rem;

  @media (max-width: 900px) {
    width: 100% !important;
    margin: 0 0 2rem 0;
  }
`;

export const ToggleWrapper = styled.div`
  width: 220px;
  margin: 1rem 0 2rem 0;
  padding: 0 1rem;

  @media (max-width: 900px) {
    width: 100% !important;
  }
`;

export const InputWithTTWrapper = styled.div`
  width: 220px;
  display: flex;
  margin: 1rem 0 2rem 0;
  padding: 0 1rem;

  @media (max-width: 900px) {
    width: 100% !important;
  }
`;

export const InputWrapper = styled.div`
  @media (max-width: 900px) {
    width: 100% !important;
  }
`;

export const TooltipWrapper = styled.div`
  ${({ theme }) => css`
    width: 30px;
    padding: 32px 5px 0 5px;
    color: ${theme.colors.darkGrayishBlue};
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 30px 20px 20px 20px;
`;
