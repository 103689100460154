import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { FaAngleDown } from 'react-icons/fa';

import InfoInput from '~/components/Inputs/Info';

import { MainContainer, Label, Container, Content, Wrapper } from './styles';

interface IMaterialDeliveryStatusProps {
  material_delivery_status?: string;
}

const MaterialDeliveryStatus: React.FC<IMaterialDeliveryStatusProps> = ({
  material_delivery_status,
}) => {
  return (
    <MainContainer>
      <Accordion>
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Label>Entrega Material</Label>
        </AccordionSummary>

        <AccordionDetails>
          <Container>
            <Content>
              <Wrapper>
                <InfoInput
                  name="material_delivery_status"
                  labelFor="material_delivery_status"
                  labelText="Status"
                  type="text"
                  defaultValue={
                    material_delivery_status !== undefined
                      ? material_delivery_status
                      : ''
                  }
                  disabled
                />
              </Wrapper>
            </Content>
          </Container>
        </AccordionDetails>
      </Accordion>
    </MainContainer>
  );
};

export default MaterialDeliveryStatus;
