import React, { useState, useEffect } from 'react';

import api from '~/services/api';

import TextArea from '~/components/TextArea';

import { Container, Content } from './styles';

interface IRecordNoteProps {
  sequence: number;
  type: string;
  project_id: string;
}

interface IRecordNoteApproved {
  sequence: number;
  description: string;
  status: string;
  visible: boolean;
  note: string;
  reason: string;
  project_id: string;
  user_id: string;
  user: {
    name: string;
  };
  project_procedure_id: string;
}

interface IRecordNoteCanceled {
  sequence: number;
  description: string;
  status: string;
  visible: boolean;
  note: string;
  reason: string;
  project_id: string;
  user_id: string;
  user: {
    name: string;
  };
  project_procedure_id: string;
}

interface IRecordNoteCompletion {
  sequence: number;
  description: string;
  status: string;
  visible: boolean;
  note: string;
  reason: string;
  project_id: string;
  user_id: string;
  user: {
    name: string;
  };
  project_procedure_id: string;
}

interface IRecordNoteEmpty {
  sequence: number;
  description: string;
  status: string;
  visible: boolean;
  note: string;
  reason: string;
  project_id: string;
  user_id: string;
  user: {
    name: string;
  };
  project_procedure_id: string;
}

const RecordNote: React.FC<IRecordNoteProps> = ({
  sequence,
  type,
  project_id,
}) => {
  const [recordNoteApproved, setRecordNoteApproved] =
    useState<IRecordNoteApproved>();
  const [recordNoteCanceled, setRecordNoteCanceled] =
    useState<IRecordNoteCanceled>();
  const [recordNoteCompletion, setRecordNoteCompletion] =
    useState<IRecordNoteCompletion>();
  const [recordNoteEmpty, setRecordNoteEmpty] = useState<IRecordNoteEmpty>();
  const [recordNote, setRecordNote] = useState('');

  useEffect(() => {
    if (recordNoteApproved) {
      if (recordNoteApproved.status) {
        if (recordNoteApproved.status === 'Aprovado') {
          setRecordNote(recordNoteApproved.note);
        }
      }
    }
  }, [recordNoteApproved]);

  useEffect(() => {
    if (recordNoteCanceled) {
      if (recordNoteCanceled.status) {
        if (recordNoteCanceled.status === 'Cancelado') {
          setRecordNote(recordNoteCanceled.note);
        }
      }
    }
  }, [recordNoteCanceled]);

  useEffect(() => {
    if (recordNoteCompletion) {
      if (recordNoteCompletion.status) {
        if (recordNoteCompletion.status === 'Finalizado') {
          setRecordNote(recordNoteCompletion.note);
        }
      }
    }
  }, [recordNoteCompletion]);

  useEffect(() => {
    if (recordNoteEmpty) {
      if (recordNoteEmpty.status) {
        if (recordNoteEmpty.status === 'Cancelado') {
          setRecordNote(recordNoteEmpty.note);
        }
      }
    }
  }, [recordNoteEmpty]);

  useEffect(() => {
    api
      .get(
        `/projectrecords/statusprojectprocedure?` +
          `status=Aprovado&type=${type}&sequence=${sequence}&project_id=${project_id}`,
      )
      .then(response => {
        setRecordNoteApproved(response.data);
      });

    api
      .get(
        `/projectrecords/statusprojectprocedure?` +
          `status=Cancelado&type=${type}&sequence=${sequence}&project_id=${project_id}`,
      )
      .then(response => {
        setRecordNoteCanceled(response.data);
      });

    api
      .get(
        `/projectrecords/statusprojectprocedure?` +
          `status=Finalizado&type=${type}&sequence=${sequence}&project_id=${project_id}`,
      )
      .then(response => {
        setRecordNoteCompletion(response.data);
      });

    api
      .get(
        `/projectrecords/statusprojectprocedure?` +
          `status=&type=${type}&sequence=${sequence}&project_id=${project_id}`,
      )
      .then(response => {
        setRecordNoteEmpty(response.data);
      });
  }, [type, sequence, project_id]);

  return (
    <Container>
      <Content>
        <TextArea
          name="record_note"
          labelFor="record_note"
          labelText="Observação"
          value={recordNote}
          maxLength={1000}
          disabled
        />
      </Content>
    </Container>
  );
};

export default RecordNote;
