import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.grey};
    font-size: ${theme.fontSizes.xLarge};
    font-weight: bold;
    justify-content: center;
    text-align: center;
    margin-left: 0.5rem;
  `}
`;
