import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { PropagateLoader } from 'react-spinners';
import { FaProjectDiagram, FaBoxOpen } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

import BreadCrumb from '~/components/BreadCrumb';
import ProjectInfo from '~/components/ProjectInfo/Default';
import TableDefaultInput from '~/components/TableInputs/Default';
import TableDateInput from '~/components/TableInputs/Date';
import TableCurrencyInput from '~/components/TableInputs/Currency';
import TableWholeNumberInput from '~/components/TableInputs/WholeNumber';
import LockButton from '~/components/Buttons/Lock';
import UnlockButton from '~/components/Buttons/Unlock';
import AddRowButton from '~/components/Buttons/AddRow';
import DeleteRowButton from '~/components/Buttons/DeleteRow';
import DeletePreviousButton from '~/components/Buttons/DeletePrevious';
import TextArea from '~/components/TextArea';
import UploadFiles from '~/components/UploadFiles';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';
import ConfirmWarningDialog from '~/components/Dialogs/ConfirmWarning';
import DeleteModal from '~/components/Modals/Delete';
import NoticeDialog from '~/components/Dialogs/Notice';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Content,
  ContentAddRow,
  Wrapper,
  LabelContainer,
  Label,
  Table,
  ItemSequence,
  ItemSequenceLabel,
  ItemContent,
  ItemWrapper,
  ItemSubWrapper,
  ItemInfoWrapper,
  ItemButtonsWrapper,
  ButtonContainer,
} from './styles';

interface IPreviousProjectSentInvoice {
  id: string;
  number: string;
  series: string;
  issue_date: Date;
  net_price: number;
  amount: number;
  sequence: number;
  project_id: string;
  user_inclusion_id: string;
  user_update_id: string;
  project: {
    code: string;
    name: string;
  };
  user_inclusion: {
    name: string;
  };
  user_update: {
    name: string;
  };
  created_at: string;
}

interface IProjectSentInvoice {
  id: string;
  number: string;
  series: string;
  issue_date: Date;
  net_price: number;
  amount: number;
  sequence: number;
  project_id: string;
  user_inclusion_id: string;
  user_update_id: string;
  project_record_reason: string;
  validation_error: string | undefined;
  validation_general_error: boolean;
  validation_internal_error: boolean;
  issue_date_error: string | undefined;
  previous: boolean;
  fields_enabled: boolean;
  deleted: boolean;
  previous_number: string;
  previous_series: string;
  previous_issue_date: Date | undefined;
  created_at: Date;
}

interface IProjectLabel {
  control: string;
  packaging: string;
  city: string;
  state: string;
  invoice_series: string;
  invoice_number: string;
  invoice_issue_date: Date;
  invoice_net_price: number;
  document: string;
  item_erp_code: string;
  batch: string;
  sequence: number;
  project_id: string;
  user_inclusion_id: string;
  user_update_id: string;
  invoice_net_price_enabled: boolean;
  automatic_invoice_net_price: boolean;
  validation_general_error: boolean;
  invoice_found: boolean;
  conflict: boolean;
  invoice_validation_error: string | undefined;
  invoice_validation_warning: string | undefined;
  invoice_issue_date_error: string | undefined;
  conflict_error: string | undefined;
}

interface IFormProcedure3 {
  type: string;
  code: string;
  item_total_amount: number;
  item_total_amount_temp: number;
  sample_id: string;
  user_update_id: string;
  validation_type: string;
  project_procedure_id: string;
  record_description: string;
  record_note: string;
  project_temp_id: string;
  project_sent_invoices: IProjectSentInvoice[];
  project_labels: IProjectLabel[];
}

interface ILocation {
  id: string;
  type: string;
  code: string;
  price: number;
  item_total_amount: number;
  erp_rep_code: string;
  status: string;
  project_procedure_id: string;
  local_client: {
    code: string;
    name: string;
    company_name: string;
    document: string;
    address: string;
    city: string;
    state: string;
    representative: boolean;
  };
  sample: {
    erp_code: string;
  };
  project_procedure: {
    user_type: string;
  };
}

const Procedure3: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [previousProjectSentInvoices, setPreviousProjectSentInvoices] =
    useState<IPreviousProjectSentInvoice[]>();
  const [projectSentInvoices, setProjectSentInvoices] = useState<
    IProjectSentInvoice[]
  >([]);
  const [loadProjectSentInvoices, setloadProjectSentInvoices] = useState(false);
  const [projectSentInvoicesLoaded, setProjectSentInvoicesLoaded] =
    useState(false);
  const [loadingProjectSentInvoices, setLoadingProjectSentInvoices] =
    useState<boolean>(true);
  const [projectLabels, setProjectLabels] = useState<IProjectLabel[]>([]);
  const [loadProjectLabels, setloadProjectLabels] = useState(false);
  const [projectLabelsLoaded, setProjectLabelsLoaded] = useState(false);
  const [loadingProjectLabels, setLoadingProjectLabels] =
    useState<boolean>(true);
  const [projectTempFilled, setProjectTempFilled] = useState(false);
  const [projectTemp, setProjectTemp] = useState('');
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [titleConfirmProcedure, setTitleConfirmProcedure] = useState('');
  const [typeConfirmProcedure, setTypeConfirmProcedure] = useState('');
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [isOpenDeleteProjectSentInvoice, setIsOpenDeleteProjectSentInvoice] =
    useState(false);
  const [titleDeleteProjectSentInvoice, setTitleDeleteProjectSentInvoice] =
    useState('');
  const [
    subTitleDeleteProjectSentInvoice,
    setSubTitleDeleteProjectSentInvoice,
  ] = useState('');
  const [reasonDeleteProjectSentInvoice, setReasonDeleteProjectSentInvoice] =
    useState('');
  const [isOpenNotice, setIsOpenNotice] = useState(false);
  const [titleNotice, setTitleNotice] = useState('');
  const [subTitleNotice, setSubTitleNotice] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const projectSentInvoiceSequence = useRef(1);
  const projectLabelSequence = useRef(0);
  const [prevProjectLabelSequenceLoaded, setPrevProjectLabelSequenceLoaded] =
    useState(false);
  const projectTotalAmount = useRef(0);
  const projectSentInvoicesTotalAmount = useRef(0);
  const [sentInvoiceEnableSequence, setSentInvoiceEnableSequence] =
    useState('');
  const [confirmSentInvoiceEnabled, setConfirmSentInvoiceEnabled] =
    useState(false);
  const [sentInvoiceDeleteSequence, setSentInvoiceDeleteSequence] =
    useState('');
  const [confirmSentInvoiceDelete, setConfirmSentInvoiceDelete] =
    useState(false);

  const { handleSubmit, register, setValue, errors } =
    useForm<IFormProcedure3>();

  useEffect(() => {
    if (loadProjectSentInvoices) {
      if (previousProjectSentInvoices) {
        if (previousProjectSentInvoices.length > 0) {
          Object.keys(previousProjectSentInvoices).forEach(key => {
            projectSentInvoiceSequence.current =
              previousProjectSentInvoices[Number(key)].sequence + 1;
            const previousProjectSentInvoice: IProjectSentInvoice = {
              id: previousProjectSentInvoices[Number(key)].id,
              number: previousProjectSentInvoices[Number(key)].number,
              series: previousProjectSentInvoices[Number(key)].series,
              issue_date: new Date(
                previousProjectSentInvoices[Number(key)].issue_date,
              ),
              net_price: previousProjectSentInvoices[Number(key)].net_price,
              amount: previousProjectSentInvoices[Number(key)].amount,
              sequence: previousProjectSentInvoices[Number(key)].sequence,
              project_id: previousProjectSentInvoices[Number(key)].project_id,
              user_inclusion_id:
                previousProjectSentInvoices[Number(key)].user_inclusion_id,
              user_update_id: user.id,
              project_record_reason: '',
              validation_error: undefined,
              validation_general_error: false,
              validation_internal_error: false,
              issue_date_error: undefined,
              previous: true,
              fields_enabled: false,
              deleted: false,
              previous_number: previousProjectSentInvoices[Number(key)].number,
              previous_series: previousProjectSentInvoices[Number(key)].series,
              previous_issue_date: new Date(
                previousProjectSentInvoices[Number(key)].issue_date,
              ),
              created_at: new Date(
                previousProjectSentInvoices[Number(key)].created_at,
              ),
            };

            setProjectSentInvoices(prevProjectSentInvoices => [
              ...prevProjectSentInvoices,
              previousProjectSentInvoice,
            ]);
          });
        }
      }

      const firstProjectSentInvoice: IProjectSentInvoice = {
        id: '',
        number: '',
        series: '',
        issue_date: new Date(),
        net_price: 0,
        amount: 0,
        sequence: projectSentInvoiceSequence.current,
        project_id: state.id,
        user_inclusion_id: user.id,
        user_update_id: user.id,
        project_record_reason: '',
        validation_error: undefined,
        validation_general_error: false,
        validation_internal_error: false,
        issue_date_error: undefined,
        previous: false,
        fields_enabled: true,
        deleted: false,
        previous_number: '',
        previous_series: '',
        previous_issue_date: undefined,
        created_at: new Date(),
      };

      setProjectSentInvoices(prevProjectSentInvoices => [
        ...prevProjectSentInvoices,
        firstProjectSentInvoice,
      ]);

      setloadProjectSentInvoices(false);
      setProjectSentInvoicesLoaded(true);
      setLoadingProjectSentInvoices(false);
    }
  }, [state, user, loadProjectSentInvoices, previousProjectSentInvoices]);

  useEffect(() => {
    if (state === undefined) {
      if (!projectSentInvoicesLoaded) {
        setloadProjectSentInvoices(true);
      }
    } else if (previousProjectSentInvoices) {
      if (!projectSentInvoicesLoaded) {
        setloadProjectSentInvoices(true);
      }
    }
  }, [projectSentInvoicesLoaded, previousProjectSentInvoices, state]);

  useEffect(() => {
    if (loadProjectLabels) {
      projectLabelSequence.current += 1;
      const firstProjectLabel: IProjectLabel = {
        control: '',
        packaging: '',
        city: state.local_client.city,
        state: state.local_client.state,
        invoice_series: '',
        invoice_number: '',
        invoice_issue_date: new Date(new Date().setHours(0, 0, 0, 0)),
        invoice_net_price: 0,
        document: state.local_client.document,
        item_erp_code: state.sample.erp_code,
        batch: '',
        sequence: projectLabelSequence.current,
        project_id: state.id,
        user_inclusion_id: user.id,
        user_update_id: user.id,
        invoice_net_price_enabled: false,
        automatic_invoice_net_price: false,
        validation_general_error: false,
        invoice_found: false,
        conflict: false,
        invoice_validation_error: undefined,
        invoice_validation_warning: 'Nota fiscal não encontrada',
        invoice_issue_date_error: undefined,
        conflict_error: undefined,
      };

      setProjectLabels([firstProjectLabel]);
      setloadProjectLabels(false);
      setProjectLabelsLoaded(true);
      setLoadingProjectLabels(false);
    }
  }, [state, user, loadProjectLabels]);

  useEffect(() => {
    if (prevProjectLabelSequenceLoaded && !projectLabelsLoaded) {
      setloadProjectLabels(true);
    }
  }, [projectLabelsLoaded, prevProjectLabelSequenceLoaded]);

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto não localizado',
      });

      history.push('/projects/samples');
    } else if (
      user.user_type.type !== state.project_procedure.user_type &&
      user.user_type.type !== 'ADM' &&
      !(
        state.project_procedure.user_type === 'MK3' &&
        (user.user_type.type === 'MK1' || user.user_type.type === 'MK2')
      )
    ) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Tipo de usuário não permitido',
      });

      history.push('/projects/samples');
    } else if (
      state.project_procedure.user_type === 'REP' &&
      state.erp_rep_code !== user.erp_rep_code &&
      user.user_type.type !== 'ADM'
    ) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Usuário não permitido',
      });

      history.push('/projects/samples');
    } else if (state.status.toUpperCase() === 'CANCELADO') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto Cancelado',
      });

      history.push('/projects/samples');
    } else {
      register('validation_type');
      register('project_temp_id');
      register('type');
      register('code');
      register('record_description');
      register('user_update_id');
      register('project_procedure_id');
      register('project_sent_invoices');
      register('project_labels');

      setValue('type', 'AMOSTRA');
      setValue('code', state.code);
      setValue('user_update_id', user.id);
      setValue('project_procedure_id', state.project_procedure_id);

      if (state !== undefined) {
        api
          .get(`/projectsentinvoices/projectall?project_id=${state.id}`)
          .then(response => {
            setPreviousProjectSentInvoices(response.data);
          });
      }

      if (state !== undefined) {
        api
          .get(`/projectlabels/maxsequence?project_id=${state.id}`)
          .then(response => {
            if (response.data.sequence) {
              projectLabelSequence.current = response.data.sequence;
            }
            setPrevProjectLabelSequenceLoaded(true);
          });
      }

      if (!projectTempFilled) {
        setProjectTempFilled(true);
        setProjectTemp(uuidv4());
      }
    }
  }, [addToast, history, state, user, projectTempFilled, register, setValue]);

  useEffect(() => {
    setValue('project_temp_id', projectTemp);
  }, [projectTemp, setValue]);

  const submitForm = useCallback(
    async (data: IFormProcedure3) => {
      try {
        setIsSaving(true);

        await api.put(`/projects/${state.id}`, data);

        addToast({
          type: 'success',
          title: 'Projeto atualizado!',
          description: 'Projeto atualizado com sucesso.',
        });

        history.push('/projects/samples');
      } catch (error: any) {
        setIsSaving(false);

        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [addToast, history, state],
  );

  async function onValidateConfirmProcedure(): Promise<void> {
    projectTotalAmount.current = Number(
      state.item_total_amount.toString().replaceAll('.', ''),
    );

    const rowsEmptyNumber = projectSentInvoices.filter(
      projSentInvoice => projSentInvoice.number === '',
    );

    const rowsInvalidSentInvoice = projectSentInvoices.filter(
      projSentInvoice => projSentInvoice.validation_error !== undefined,
    );

    const rowsInvalidIssueDate = projectSentInvoices.filter(
      projSentInvoice => projSentInvoice.issue_date_error !== undefined,
    );

    const rowsInvalidNetPrice = projectSentInvoices.filter(
      projSentInvoice => !(projSentInvoice.net_price > 0),
    );

    const rowsInvalidAmount = projectSentInvoices.filter(
      projSentInvoice => !(projSentInvoice.amount > 0),
    );

    projectSentInvoicesTotalAmount.current = 0;
    if (projectSentInvoices) {
      if (projectSentInvoices.length > 0) {
        Object.keys(projectSentInvoices).forEach(key => {
          if (!projectSentInvoices[Number(key)].deleted) {
            projectSentInvoicesTotalAmount.current +=
              projectSentInvoices[Number(key)].amount;
          }
        });
      }
    }

    const rowsConflict = projectLabels.filter(
      projLabel => projLabel.conflict_error !== undefined,
    );

    const rowsInvalidInvoice = projectLabels.filter(
      projLabel => projLabel.invoice_validation_error !== undefined,
    );

    const rowsInvoiceNotFound = projectLabels.filter(
      projLabel => !projLabel.invoice_found,
    );

    if (projectTotalAmount.current <= 0) {
      setTitleNotice('Quantidades inválidas!');
      setSubTitleNotice('Quantidade total deve ser maior do que "0" (zero)!');
      setIsOpenNotice(true);
    } else if (
      projectTotalAmount.current < projectSentInvoicesTotalAmount.current
    ) {
      setTitleNotice('Quantidades inválidas!');
      setSubTitleNotice('Quantidade total menor do que a quantidade enviada!');
      setIsOpenNotice(true);
    } else if (projectSentInvoices.length <= 0) {
      setTitleNotice('Notas fiscais inválidas!');
      setSubTitleNotice('Necessário informar notas fiscais!');
      setIsOpenNotice(true);
    } else if (rowsEmptyNumber && rowsEmptyNumber.length > 0) {
      setTitleNotice('Existem notas fiscais sem número!');
      setSubTitleNotice(
        'Não é possível enviar projeto com notas fiscais sem número!',
      );
      setIsOpenNotice(true);
    } else if (rowsInvalidSentInvoice && rowsInvalidSentInvoice.length > 0) {
      setTitleNotice('Existem notas fiscais inválidas!');
      setSubTitleNotice(
        'Não é possível enviar projeto com notas fiscais inválidas!',
      );
      setIsOpenNotice(true);
    } else if (rowsInvalidIssueDate && rowsInvalidIssueDate.length > 0) {
      setTitleNotice('Existem datas de emissão inválidas em notas fiscais!');
      setSubTitleNotice(
        'Não é possível enviar projeto com notas fiscais possuindo datas de emissão inválidas!',
      );
      setIsOpenNotice(true);
    } else if (rowsInvalidNetPrice && rowsInvalidNetPrice.length > 0) {
      setTitleNotice('Valores inválidos em notas fiscais!');
      setSubTitleNotice(
        'Não é possível enviar projeto com notas fiscais sem valor informado!',
      );
      setIsOpenNotice(true);
    } else if (rowsInvalidAmount && rowsInvalidAmount.length > 0) {
      setTitleNotice('Quantidades inválidas em notas fiscais!');
      setSubTitleNotice(
        'Não é possível enviar projeto com notas fiscais sem quantidade informada!',
      );
      setIsOpenNotice(true);
    } else if (rowsConflict && rowsConflict.length > 0) {
      setTitleNotice('Existem etiquetas iguais!');
      setSubTitleNotice('Não é possível enviar projeto com etiquetas iguais!');
      setIsOpenNotice(true);
    } else if (rowsInvalidInvoice && rowsInvalidInvoice.length > 0) {
      setTitleNotice('Existem notas fiscais inválidas em Etiquetas!');
      setSubTitleNotice(
        'Não é possível enviar projeto com notas fiscais inválidas em Etiquetas!',
      );
      setIsOpenNotice(true);
    } else {
      if (rowsInvoiceNotFound && rowsInvoiceNotFound.length > 0) {
        setTypeConfirmProcedure('danger');
        setTitleConfirmProcedure(
          `Existem etiquetas com notas fiscais não encontradas! ` +
            `Confirma mesmo assim a gravação do projeto ${state.code}?`,
        );
      } else {
        setTypeConfirmProcedure('');
        setTitleConfirmProcedure(`Confirma gravação do projeto ${state.code}?`);
      }

      setIsOpenConfirmProcedure(true);
    }
  }

  function onConfirmProcedure(): void {
    setValue('validation_type', 'APROVADO');
    if (projectTotalAmount.current <= projectSentInvoicesTotalAmount.current) {
      setValue(
        'record_description',
        `Finalização do projeto, com quantidade total ` +
          `enviada de ${projectSentInvoicesTotalAmount.current}`,
      );
    } else {
      setValue(
        'record_description',
        `Atualização do projeto, com quantidade total ` +
          `enviada de ${projectSentInvoicesTotalAmount.current}`,
      );
    }
    setValue('project_sent_invoices', projectSentInvoices);
    setValue('project_labels', projectLabels);

    handleSubmit(submitForm)();
  }

  const onExit = useCallback(() => {
    api.delete(`projectfilesreceived/projecttempid/${projectTemp}`);

    history.push('/projects/samples');
  }, [history, projectTemp]);

  function onConfirmWarning(): void {
    setConfirmSentInvoiceEnabled(true);
  }

  function onDeleteProjectSentInvoice(): void {
    setConfirmSentInvoiceDelete(true);
  }

  function handleAddRowTableProjectSentInvoices(): void {
    projectSentInvoiceSequence.current += 1;

    const newProjectSentInvoice: IProjectSentInvoice = {
      id: '',
      number: '',
      series: '',
      issue_date: new Date(),
      net_price: 0,
      amount: 0,
      sequence: projectSentInvoiceSequence.current,
      project_id: state.id,
      user_inclusion_id: user.id,
      user_update_id: user.id,
      project_record_reason: '',
      validation_error: undefined,
      validation_general_error: false,
      validation_internal_error: false,
      issue_date_error: undefined,
      previous: false,
      fields_enabled: true,
      deleted: false,
      previous_number: '',
      previous_series: '',
      previous_issue_date: undefined,
      created_at: new Date(),
    };

    setProjectSentInvoices(prevProjectSentInvoices => [
      ...prevProjectSentInvoices,
      newProjectSentInvoice,
    ]);
  }

  function handleDeleteRowTableProjectSentInvoices(): void {
    setProjectSentInvoices(
      projectSentInvoices.filter(
        projSentInvoice =>
          projSentInvoice.sequence !== projectSentInvoiceSequence.current,
      ),
    );

    projectSentInvoiceSequence.current -= 1;
  }

  async function handleInvalidInvoiceGeneral(sequence: number): Promise<void> {
    const projSentInvoiceValidate = projectSentInvoices.find(
      projectSentInvoice => projectSentInvoice.sequence === sequence,
    );

    if (projSentInvoiceValidate) {
      if (moment(projSentInvoiceValidate.issue_date).isValid()) {
        await api
          .get(
            `/projectsentinvoices/valid?` +
              `series=${projSentInvoiceValidate.series}&` +
              `number=${projSentInvoiceValidate.number}&` +
              `issue_date=${projSentInvoiceValidate.issue_date}`,
          )
          .then(response => {
            let valid = false;

            if (
              response.data.series &&
              response.data.number &&
              response.data.issue_date
            ) {
              if (
                response.data.series ===
                  projSentInvoiceValidate.previous_series &&
                response.data.number ===
                  projSentInvoiceValidate.previous_number &&
                moment(response.data.issue_date).format('DD/MM/YYYY') ===
                  moment(projSentInvoiceValidate.previous_issue_date).format(
                    'DD/MM/YYYY',
                  )
              ) {
                valid = true;
              } else {
                setProjectSentInvoices(
                  projectSentInvoices.map(projSentInvoice => {
                    if (projSentInvoice.sequence === sequence) {
                      projSentInvoice.validation_general_error = true;
                    }
                    return projSentInvoice;
                  }),
                );
              }
            } else {
              valid = true;
            }

            if (valid) {
              setProjectSentInvoices(
                projectSentInvoices.map(projSentInvoice => {
                  if (projSentInvoice.sequence === sequence) {
                    projSentInvoice.validation_general_error = false;
                  }
                  return projSentInvoice;
                }),
              );
            }
          });
      }
    }
  }

  async function handleInvalidInvoiceInternal(sequence: number): Promise<void> {
    const projSentInvoiceValidate = projectSentInvoices.find(
      projectSentInvoice => projectSentInvoice.sequence === sequence,
    );

    if (projSentInvoiceValidate) {
      const projSentInvoiceInvalid = projectSentInvoices.filter(
        projectSentInvoice =>
          !projectSentInvoice.deleted &&
          projectSentInvoice.series === projSentInvoiceValidate.series &&
          projectSentInvoice.number === projSentInvoiceValidate.number &&
          moment(projectSentInvoice.issue_date).format('DD/MM/YYYY') ===
            moment(projSentInvoiceValidate.issue_date).format('DD/MM/YYYY'),
      );

      if (projSentInvoiceInvalid) {
        if (projSentInvoiceInvalid.length > 0) {
          Object.keys(projSentInvoiceInvalid).forEach(key => {
            setProjectSentInvoices(
              projectSentInvoices.map(projSentInvoice => {
                if (
                  projSentInvoice.sequence ===
                  projSentInvoiceInvalid[Number(key)].sequence
                ) {
                  projSentInvoice.validation_internal_error = true;
                }
                return projSentInvoice;
              }),
            );
          });
        }
      }
    }
  }

  useEffect(() => {
    let checkInvoiceErrors = false;

    const projSentInvoiceGeneralError = projectSentInvoices.filter(
      projectSentInvoice => projectSentInvoice.validation_general_error,
    );

    if (projSentInvoiceGeneralError) {
      if (projSentInvoiceGeneralError.length > 0) {
        Object.keys(projSentInvoiceGeneralError).forEach(key => {
          const projSentInvoicePrevious = projectSentInvoices.find(
            projectSentInvoice =>
              projectSentInvoice.sequence !==
                projSentInvoiceGeneralError[Number(key)].sequence &&
              projectSentInvoice.previous_series ===
                projSentInvoiceGeneralError[Number(key)].series &&
              projectSentInvoice.previous_number ===
                projSentInvoiceGeneralError[Number(key)].number &&
              moment(projectSentInvoice.previous_issue_date).format(
                'DD/MM/YYYY',
              ) ===
                moment(
                  projSentInvoiceGeneralError[Number(key)].issue_date,
                ).format('DD/MM/YYYY'),
          );

          if (projSentInvoicePrevious) {
            const projSentInvoiceGeneralConflict = projectSentInvoices.find(
              projectSentInvoice =>
                !projectSentInvoice.deleted &&
                projectSentInvoice.sequence !==
                  projSentInvoiceGeneralError[Number(key)].sequence &&
                projectSentInvoice.series ===
                  projSentInvoiceGeneralError[Number(key)].series &&
                projectSentInvoice.number ===
                  projSentInvoiceGeneralError[Number(key)].number &&
                moment(projectSentInvoice.issue_date).format('DD/MM/YYYY') ===
                  moment(
                    projSentInvoiceGeneralError[Number(key)].issue_date,
                  ).format('DD/MM/YYYY'),
            );

            if (!projSentInvoiceGeneralConflict) {
              setProjectSentInvoices(
                projectSentInvoices.map(projSentInvoice => {
                  if (
                    projSentInvoice.sequence ===
                    projSentInvoiceGeneralError[Number(key)].sequence
                  ) {
                    projSentInvoice.validation_general_error = false;
                    checkInvoiceErrors = true;
                  }
                  return projSentInvoice;
                }),
              );
            }
          }
        });
      }
    }

    const projSentInvoiceInternalError = projectSentInvoices.filter(
      projectSentInvoice => projectSentInvoice.validation_internal_error,
    );

    if (projSentInvoiceInternalError) {
      if (projSentInvoiceInternalError.length > 0) {
        Object.keys(projSentInvoiceInternalError).forEach(key => {
          let conflict = false;

          const projSentInvoiceInternalConflict = projectSentInvoices.filter(
            projectSentInvoice =>
              !projectSentInvoice.deleted &&
              projectSentInvoice.sequence !==
                projSentInvoiceInternalError[Number(key)].sequence &&
              projectSentInvoice.series ===
                projSentInvoiceInternalError[Number(key)].series &&
              projectSentInvoice.number ===
                projSentInvoiceInternalError[Number(key)].number &&
              moment(projectSentInvoice.issue_date).format('DD/MM/YYYY') ===
                moment(
                  projSentInvoiceInternalError[Number(key)].issue_date,
                ).format('DD/MM/YYYY'),
          );

          if (projSentInvoiceInternalConflict) {
            if (projSentInvoiceInternalConflict.length > 0) {
              conflict = true;
            }
          }

          if (!conflict) {
            setProjectSentInvoices(
              projectSentInvoices.map(projSentInvoice => {
                if (
                  projSentInvoice.sequence ===
                  projSentInvoiceInternalError[Number(key)].sequence
                ) {
                  projSentInvoice.validation_internal_error = false;
                  checkInvoiceErrors = true;
                }
                return projSentInvoice;
              }),
            );
          }
        });
      }
    }

    if (checkInvoiceErrors) {
      setProjectSentInvoices(
        projectSentInvoices.map(projSentInvoice => {
          if (projSentInvoice.validation_internal_error) {
            projSentInvoice.validation_error = 'Nota fiscal já utilizada';
          } else if (projSentInvoice.validation_general_error) {
            projSentInvoice.validation_error =
              'Nota fiscal já utilizada em outro projeto';
          } else {
            projSentInvoice.validation_error = undefined;
          }

          return projSentInvoice;
        }),
      );
    }
  }, [projectSentInvoices]);

  function handleInvalidInvoices(): void {
    setProjectSentInvoices(
      projectSentInvoices.map(projSentInvoice => {
        if (projSentInvoice.validation_internal_error) {
          projSentInvoice.validation_error = 'Nota fiscal já utilizada';
        } else if (projSentInvoice.validation_general_error) {
          projSentInvoice.validation_error =
            'Nota fiscal já utilizada em outro projeto';
        } else {
          projSentInvoice.validation_error = undefined;
        }

        return projSentInvoice;
      }),
    );
  }

  async function handleInvoiceValidationsComplete(
    sequence: number,
  ): Promise<void> {
    await handleInvalidInvoiceGeneral(sequence);
    await handleInvalidInvoiceInternal(sequence);
    await handleInvalidInvoices();
  }

  async function handleRowSentInvoicesNumberChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    setProjectSentInvoices(
      projectSentInvoices.map(projSentInvoice => {
        if (projSentInvoice.sequence === sequence) {
          projSentInvoice.number = fieldValue;
        }
        return projSentInvoice;
      }),
    );

    await handleInvoiceValidationsComplete(sequence);
  }

  async function handleRowSentInvoicesSeriesChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    setProjectSentInvoices(
      projectSentInvoices.map(projSentInvoice => {
        if (projSentInvoice.sequence === sequence) {
          projSentInvoice.series = fieldValue;
        }
        return projSentInvoice;
      }),
    );

    await handleInvoiceValidationsComplete(sequence);
  }

  async function handleRowSentInvoicesIssueDateChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    const issueDate = moment(fieldValue, 'DD/MM/YYYY').toDate();

    setProjectSentInvoices(
      projectSentInvoices.map(projSentInvoice => {
        if (projSentInvoice.sequence === sequence) {
          projSentInvoice.issue_date = issueDate;
        }
        return projSentInvoice;
      }),
    );

    if (
      !moment(fieldValue, 'DD/MM/YYYY').isValid() ||
      issueDate.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)
    ) {
      setProjectSentInvoices(
        projectSentInvoices.map(projSentInvoice => {
          if (projSentInvoice.sequence === sequence) {
            projSentInvoice.issue_date_error = 'Data inválida';
          }
          return projSentInvoice;
        }),
      );
    } else {
      setProjectSentInvoices(
        projectSentInvoices.map(projSentInvoice => {
          if (projSentInvoice.sequence === sequence) {
            projSentInvoice.issue_date_error = undefined;
          }
          return projSentInvoice;
        }),
      );
    }

    await handleInvoiceValidationsComplete(sequence);
  }

  function handleRowSentInvoicesNetPriceChange(
    sequence: number,
    fieldValue: string,
  ): void {
    let netPriceConv = fieldValue;
    let netPriceValue = 0;

    netPriceConv = netPriceConv.replaceAll('R$', '');
    netPriceConv = netPriceConv.replaceAll('.', '');
    netPriceConv = netPriceConv.replaceAll(',', '.');

    if (!Number.isNaN(Number(parseFloat(netPriceConv)))) {
      netPriceValue = parseFloat(netPriceConv);
    }

    setProjectSentInvoices(
      projectSentInvoices.map(projSentInvoice => {
        if (projSentInvoice.sequence === sequence) {
          projSentInvoice.net_price = netPriceValue;
        }
        return projSentInvoice;
      }),
    );
  }

  function handleRowSentInvoicesAmountChange(
    sequence: number,
    fieldValue: string,
  ): void {
    let amountConv = fieldValue;
    let amountValue = 0;

    amountConv = amountConv.replaceAll('.', '');
    amountConv = amountConv.replaceAll(',', '.');

    if (!Number.isNaN(Number(parseFloat(amountConv)))) {
      amountValue = parseFloat(amountConv);
    }

    setProjectSentInvoices(
      projectSentInvoices.map(projSentInvoice => {
        if (projSentInvoice.sequence === sequence) {
          projSentInvoice.amount = amountValue;
        }
        return projSentInvoice;
      }),
    );
  }

  function handleRowFieldsEnabledFalseChange(sequence: number): void {
    setProjectSentInvoices(
      projectSentInvoices.map(projSentInvoice => {
        if (projSentInvoice.sequence === sequence) {
          projSentInvoice.fields_enabled = false;
        }
        return projSentInvoice;
      }),
    );
  }

  useEffect(() => {
    if (confirmSentInvoiceEnabled) {
      if (sentInvoiceEnableSequence) {
        if (sentInvoiceEnableSequence !== '') {
          setProjectSentInvoices(
            projectSentInvoices.map(projSentInvoice => {
              if (
                projSentInvoice.sequence === Number(sentInvoiceEnableSequence)
              ) {
                projSentInvoice.fields_enabled = true;
              }
              return projSentInvoice;
            }),
          );
          setSentInvoiceEnableSequence('');
          setConfirmSentInvoiceEnabled(false);
        }
      }
    }
  }, [
    confirmSentInvoiceEnabled,
    sentInvoiceEnableSequence,
    projectSentInvoices,
  ]);

  useEffect(() => {
    if (confirmSentInvoiceDelete) {
      if (sentInvoiceDeleteSequence) {
        if (sentInvoiceDeleteSequence !== '') {
          setProjectSentInvoices(
            projectSentInvoices.map(projSentInvoice => {
              if (
                projSentInvoice.sequence === Number(sentInvoiceDeleteSequence)
              ) {
                projSentInvoice.deleted = true;
                projSentInvoice.project_record_reason =
                  reasonDeleteProjectSentInvoice;
                projSentInvoice.validation_error = undefined;
                projSentInvoice.validation_general_error = false;
                projSentInvoice.validation_internal_error = false;
              }
              return projSentInvoice;
            }),
          );

          setSentInvoiceDeleteSequence('');
          setConfirmSentInvoiceDelete(false);
          setReasonDeleteProjectSentInvoice('');
        }
      }
    }
  }, [
    confirmSentInvoiceDelete,
    sentInvoiceDeleteSequence,
    projectSentInvoices,
    reasonDeleteProjectSentInvoice,
  ]);

  function handleAddRowTableProjectLabels(): void {
    projectLabelSequence.current += 1;
    const newProjectLabel: IProjectLabel = {
      control: '',
      packaging: '',
      city: state.local_client.city,
      state: state.local_client.state,
      invoice_series: '',
      invoice_number: '',
      invoice_issue_date: new Date(new Date().setHours(0, 0, 0, 0)),
      invoice_net_price: 0,
      document: state.local_client.document,
      item_erp_code: state.sample.erp_code,
      batch: '',
      sequence: projectLabelSequence.current,
      project_id: state.id,
      user_inclusion_id: user.id,
      user_update_id: user.id,
      invoice_net_price_enabled: false,
      automatic_invoice_net_price: false,
      validation_general_error: false,
      invoice_found: false,
      conflict: false,
      invoice_validation_error: undefined,
      invoice_validation_warning: 'Nota fiscal não encontrada',
      invoice_issue_date_error: undefined,
      conflict_error: undefined,
    };

    setProjectLabels(prevProjectLabels => [
      ...prevProjectLabels,
      newProjectLabel,
    ]);
  }

  function handleDeleteRowTableProjectLabels(): void {
    setProjectLabels(
      projectLabels.filter(
        projLabel => projLabel.sequence !== projectLabelSequence.current,
      ),
    );

    projectLabelSequence.current -= 1;
  }

  useEffect(() => {
    Object.keys(projectLabels).forEach(key => {
      let conf_error: string | undefined;
      const projLabelConflict = projectLabels.filter(
        projectLabel =>
          projectLabel.sequence !== projectLabels[Number(key)].sequence &&
          projectLabel.control === projectLabels[Number(key)].control &&
          projectLabel.packaging === projectLabels[Number(key)].packaging &&
          projectLabel.city === projectLabels[Number(key)].city &&
          projectLabel.state === projectLabels[Number(key)].state &&
          projectLabel.invoice_series ===
            projectLabels[Number(key)].invoice_series &&
          projectLabel.invoice_number ===
            projectLabels[Number(key)].invoice_number &&
          projectLabel.invoice_net_price ===
            projectLabels[Number(key)].invoice_net_price &&
          projectLabel.document === projectLabels[Number(key)].document &&
          projectLabel.item_erp_code ===
            projectLabels[Number(key)].item_erp_code &&
          projectLabel.batch === projectLabels[Number(key)].batch,
      );

      if (projLabelConflict && projLabelConflict.length > 0) {
        conf_error = 'Etiqueta já registrada';
      } else {
        conf_error = undefined;
      }

      if (projectLabels[Number(key)].conflict_error !== conf_error) {
        setProjectLabels(
          projectLabels.map(projLabel => {
            if (projLabel.sequence === projectLabels[Number(key)].sequence) {
              projLabel.conflict_error = conf_error;
            }

            return projLabel;
          }),
        );
      }
    });
  }, [projectLabels]);

  function handleRowLabelsControlChange(
    sequence: number,
    fieldValue: string,
  ): void {
    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.sequence === sequence) {
          projLabel.control = fieldValue;
        }
        return projLabel;
      }),
    );
  }

  function handleRowLabelsPackagingChange(
    sequence: number,
    fieldValue: string,
  ): void {
    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.sequence === sequence) {
          projLabel.packaging = fieldValue;
        }
        return projLabel;
      }),
    );
  }

  function handleRowLabelsCityChange(
    sequence: number,
    fieldValue: string,
  ): void {
    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.sequence === sequence) {
          projLabel.city = fieldValue;
        }
        return projLabel;
      }),
    );
  }

  function handleRowLabelsStateChange(
    sequence: number,
    fieldValue: string,
  ): void {
    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.sequence === sequence) {
          projLabel.state = fieldValue;
        }
        return projLabel;
      }),
    );
  }

  async function handleLabelFindInvoice(sequence: number): Promise<void> {
    const projLabelCurrent = projectLabels.find(
      projectLabel => projectLabel.sequence === sequence,
    );

    if (projLabelCurrent) {
      setProjectLabels(
        projectLabels.map(projLabel => {
          if (projLabel.sequence === sequence) {
            projLabel.automatic_invoice_net_price = true;
          }
          return projLabel;
        }),
      );

      if (moment(projLabelCurrent.invoice_issue_date).isValid()) {
        await api
          .get(
            `/invoices/findinvoice?` +
              `series=${projLabelCurrent.invoice_series}&` +
              `number=${projLabelCurrent.invoice_number}&` +
              `issue_date=${projLabelCurrent.invoice_issue_date}&` +
              `document=${state.local_client.document}`,
          )
          .then(response => {
            let foundInvoice = false;

            if (
              response.data.series &&
              response.data.number &&
              response.data.issue_date &&
              response.data.net_price &&
              response.data.document
            ) {
              if (
                response.data.series === projLabelCurrent.invoice_series &&
                response.data.number === projLabelCurrent.invoice_number &&
                moment(response.data.issue_date).format('DD/MM/YYYY') ===
                  moment(projLabelCurrent.invoice_issue_date).format(
                    'DD/MM/YYYY',
                  ) &&
                response.data.document === state.local_client.document
              ) {
                foundInvoice = true;
              }
            }

            if (foundInvoice) {
              setProjectLabels(
                projectLabels.map(projLabel => {
                  if (projLabel.sequence === sequence) {
                    projLabel.invoice_found = true;
                    projLabel.invoice_net_price = response.data.net_price;
                  }
                  return projLabel;
                }),
              );
            } else {
              setProjectLabels(
                projectLabels.map(projLabel => {
                  if (projLabel.sequence === sequence) {
                    projLabel.invoice_found = false;
                  }
                  return projLabel;
                }),
              );
            }
          });
      }
    }
  }

  function handleLabelInvoiceWarnings(): void {
    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.invoice_found) {
          projLabel.invoice_validation_warning = undefined;
        } else {
          projLabel.invoice_validation_warning = 'Nota fiscal não encontrada';
        }

        return projLabel;
      }),
    );
  }

  async function handleInvalidLabelInvoiceGeneral(
    sequence: number,
  ): Promise<void> {
    const projLabelValidate = projectLabels.find(
      projectLabel => projectLabel.sequence === sequence,
    );

    if (projLabelValidate) {
      if (moment(projLabelValidate.invoice_issue_date).isValid()) {
        await api
          .get(
            `/projectlabels/valid?` +
              `invoice_series=${projLabelValidate.invoice_series}&` +
              `invoice_number=${projLabelValidate.invoice_number}&` +
              `invoice_issue_date=${projLabelValidate.invoice_issue_date}`,
          )
          .then(response => {
            let valid = false;

            if (
              response.data.id &&
              response.data.invoice_series &&
              response.data.invoice_number &&
              response.data.invoice_issue_date
            ) {
              if (response.data.id === state.id) {
                valid = true;
              } else {
                setProjectLabels(
                  projectLabels.map(projLabel => {
                    if (projLabel.sequence === sequence) {
                      projLabel.validation_general_error = true;
                    }
                    return projLabel;
                  }),
                );
              }
            } else {
              valid = true;
            }

            if (valid) {
              setProjectLabels(
                projectLabels.map(projLabel => {
                  if (projLabel.sequence === sequence) {
                    projLabel.validation_general_error = false;
                  }
                  return projLabel;
                }),
              );
            }
          });
      }
    }
  }

  function handleInvalidLabelInvoices(): void {
    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.validation_general_error) {
          projLabel.invoice_validation_error =
            'Nota fiscal já utilizada em outro projeto';
        } else {
          projLabel.invoice_validation_error = undefined;
        }

        return projLabel;
      }),
    );
  }

  async function handleLabelInvoiceValidationsComplete(
    sequence: number,
  ): Promise<void> {
    await handleLabelFindInvoice(sequence);
    await handleLabelInvoiceWarnings();
    await handleInvalidLabelInvoiceGeneral(sequence);
    await handleInvalidLabelInvoices();
  }

  async function handleRowLabelsInvoiceSeriesChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.sequence === sequence) {
          projLabel.invoice_series = fieldValue;
        }
        return projLabel;
      }),
    );

    await handleLabelInvoiceValidationsComplete(sequence);
  }

  async function handleRowLabelsInvoiceNumberChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.sequence === sequence) {
          projLabel.invoice_number = fieldValue;
        }
        return projLabel;
      }),
    );

    await handleLabelInvoiceValidationsComplete(sequence);
  }

  async function handleRowLabelsInvoiceIssueDateChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    const issueDate = moment(fieldValue, 'DD/MM/YYYY').toDate();

    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.sequence === sequence) {
          projLabel.invoice_issue_date = issueDate;
        }
        return projLabel;
      }),
    );

    if (
      !moment(fieldValue, 'DD/MM/YYYY').isValid() ||
      issueDate.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)
    ) {
      setProjectLabels(
        projectLabels.map(projLabel => {
          if (projLabel.sequence === sequence) {
            projLabel.invoice_issue_date_error = 'Data inválida';
          }
          return projLabel;
        }),
      );
    } else {
      setProjectLabels(
        projectLabels.map(projLabel => {
          if (projLabel.sequence === sequence) {
            projLabel.invoice_issue_date_error = undefined;
          }
          return projLabel;
        }),
      );
    }

    await handleLabelInvoiceValidationsComplete(sequence);
  }

  function handleRowLabelsInvoiceNetPriceChange(
    sequence: number,
    fieldValue: string,
  ): void {
    let netPriceConv = fieldValue;
    let netPriceValue = 0;

    netPriceConv = netPriceConv.replaceAll('R$', '');
    netPriceConv = netPriceConv.replaceAll('.', '');
    netPriceConv = netPriceConv.replaceAll(',', '.');

    if (!Number.isNaN(Number(parseFloat(netPriceConv)))) {
      netPriceValue = parseFloat(netPriceConv);
    }

    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.sequence === sequence) {
          projLabel.invoice_net_price = netPriceValue;
          projLabel.automatic_invoice_net_price = false;
        }
        return projLabel;
      }),
    );
  }

  function handleRowLabelsDocumentChange(
    sequence: number,
    fieldValue: string,
  ): void {
    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.sequence === sequence) {
          projLabel.document = fieldValue;
        }
        return projLabel;
      }),
    );
  }

  function handleRowLabelsItemErpCodeChange(
    sequence: number,
    fieldValue: string,
  ): void {
    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.sequence === sequence) {
          projLabel.item_erp_code = fieldValue;
        }
        return projLabel;
      }),
    );
  }

  function handleRowLabelsBatchChange(
    sequence: number,
    fieldValue: string,
  ): void {
    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.sequence === sequence) {
          projLabel.batch = fieldValue;
        }
        return projLabel;
      }),
    );
  }

  function handleRowLabelsInvoiceNetPriceEnabledChange(
    sequence: number,
    enabled: boolean,
  ): void {
    setProjectLabels(
      projectLabels.map(projLabel => {
        if (projLabel.sequence === sequence) {
          projLabel.invoice_net_price_enabled = enabled;
        }
        return projLabel;
      }),
    );
  }

  function handleProjectSentInvoicesTableRowEnableFieldsButtons(
    rowProjectSentInvoice: IProjectSentInvoice,
  ): React.ReactNode {
    if (rowProjectSentInvoice.fields_enabled) {
      return (
        <UnlockButton
          iconSize={20}
          type="button"
          onClick={() => {
            handleRowFieldsEnabledFalseChange(rowProjectSentInvoice.sequence);
          }}
        />
      );
    }

    return (
      <LockButton
        iconSize={20}
        type="button"
        onClick={() => {
          setSentInvoiceEnableSequence(
            rowProjectSentInvoice.sequence.toString(),
          );
          setIsOpenWarning(true);
        }}
      />
    );
  }

  function handleDeleteProjectSentInvoiceRow(
    projectSentInvoiceReceived: IProjectSentInvoice,
  ): React.ReactNode | null {
    if (
      projectSentInvoiceReceived.sequence === projectSentInvoiceSequence.current
    ) {
      return (
        <DeleteRowButton
          type="button"
          onClick={() => {
            handleDeleteRowTableProjectSentInvoices();
          }}
        />
      );
    }

    return null;
  }

  function handleProjectSentInvoicesTableRowButtons(
    rowProjectSentInvoice: IProjectSentInvoice,
  ): React.ReactNode {
    if (rowProjectSentInvoice.previous) {
      return (
        <>
          {handleProjectSentInvoicesTableRowEnableFieldsButtons(
            rowProjectSentInvoice,
          )}
          <DeletePreviousButton
            marginTop="0.8rem"
            marginLeft="1rem"
            type="button"
            onClick={() => {
              setSentInvoiceDeleteSequence(
                rowProjectSentInvoice.sequence.toString(),
              );
              setTitleDeleteProjectSentInvoice(
                `Deseja realmente excluir a NF ${rowProjectSentInvoice.number} ` +
                  `previamente registrada?`,
              );
              setSubTitleDeleteProjectSentInvoice(
                'A exclusão apenas será efetivada ao salvar o procedimento!',
              );
              setIsOpenDeleteProjectSentInvoice(true);
            }}
          />
        </>
      );
    }

    return handleDeleteProjectSentInvoiceRow(rowProjectSentInvoice);
  }

  function handleProjectSentInvoicesTableRow(
    rowProjectSentInvoice: IProjectSentInvoice,
  ): React.ReactNode | null {
    if (!rowProjectSentInvoice.deleted) {
      return (
        <tr key={rowProjectSentInvoice.sequence}>
          <ItemSequence>
            <ItemSequenceLabel>
              {`${'Sequência'.toUpperCase()}: ${
                rowProjectSentInvoice.sequence
              }`}
            </ItemSequenceLabel>
          </ItemSequence>

          <ItemContent>
            <ItemWrapper>
              <TableDefaultInput
                type="text"
                labelText="Número"
                upperCaseLabel
                defaultValue={rowProjectSentInvoice.number}
                error={rowProjectSentInvoice.validation_error}
                maxLength={7}
                disabled={
                  rowProjectSentInvoice.previous &&
                  !rowProjectSentInvoice.fields_enabled
                }
                onChange={e => {
                  handleRowSentInvoicesNumberChange(
                    rowProjectSentInvoice.sequence,
                    e.target.value,
                  );
                }}
              />
            </ItemWrapper>

            <ItemWrapper>
              <TableDefaultInput
                type="text"
                labelText="Série"
                upperCaseLabel
                defaultValue={rowProjectSentInvoice.series}
                maxLength={3}
                disabled={
                  rowProjectSentInvoice.previous &&
                  !rowProjectSentInvoice.fields_enabled
                }
                onChange={e => {
                  handleRowSentInvoicesSeriesChange(
                    rowProjectSentInvoice.sequence,
                    e.target.value,
                  );
                }}
              />
            </ItemWrapper>

            <ItemWrapper>
              <TableDateInput
                type="text"
                labelText="Data Emissão"
                upperCaseLabel
                defaultValue={rowProjectSentInvoice.issue_date.toLocaleDateString()}
                error={rowProjectSentInvoice.issue_date_error}
                disabled={
                  rowProjectSentInvoice.previous &&
                  !rowProjectSentInvoice.fields_enabled
                }
                onChange={e => {
                  handleRowSentInvoicesIssueDateChange(
                    rowProjectSentInvoice.sequence,
                    e.target.value,
                  );
                }}
              />
            </ItemWrapper>
          </ItemContent>

          <ItemContent>
            <ItemWrapper>
              <TableDateInput
                type="text"
                labelText="Data Inclusão"
                upperCaseLabel
                defaultValue={rowProjectSentInvoice.created_at.toLocaleDateString()}
                disabled
              />
            </ItemWrapper>

            <ItemWrapper>
              <TableCurrencyInput
                type="text"
                labelText="Valor NF"
                upperCaseLabel
                defaultValue={rowProjectSentInvoice.net_price}
                disabled={
                  rowProjectSentInvoice.previous &&
                  !rowProjectSentInvoice.fields_enabled
                }
                onSet={e => {
                  handleRowSentInvoicesNetPriceChange(
                    rowProjectSentInvoice.sequence,
                    e.target.value,
                  );
                }}
              />
            </ItemWrapper>

            <ItemWrapper>
              <TableWholeNumberInput
                type="text"
                labelText="Quantidade"
                upperCaseLabel
                defaultValue={rowProjectSentInvoice.amount}
                disabled={
                  rowProjectSentInvoice.previous &&
                  !rowProjectSentInvoice.fields_enabled
                }
                onSet={e => {
                  handleRowSentInvoicesAmountChange(
                    rowProjectSentInvoice.sequence,
                    e.target.value,
                  );
                }}
              />
            </ItemWrapper>
          </ItemContent>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '10px 5px 10px 10px',
            }}
          >
            {handleProjectSentInvoicesTableRowButtons(rowProjectSentInvoice)}
          </div>
        </tr>
      );
    }

    return null;
  }

  function handleProjectSentInvoicesTable(): React.ReactNode {
    if (projectSentInvoices) {
      if (projectSentInvoices.length > 0) {
        return (
          <Table>
            {loadingProjectSentInvoices && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <PropagateLoader color="#276c8d" />
              </div>
            )}
            {projectSentInvoices?.map(projectSentInvoice =>
              handleProjectSentInvoicesTableRow(projectSentInvoice),
            )}
          </Table>
        );
      }
    }

    return <></>;
  }

  function handleDeleteProjectLabelRow(
    projectLabelReceived: IProjectLabel,
  ): React.ReactNode | null {
    if (projectLabelReceived.sequence === projectLabelSequence.current) {
      return (
        <DeleteRowButton
          type="button"
          onClick={() => handleDeleteRowTableProjectLabels()}
        />
      );
    }

    return null;
  }

  function handleProjectLabelsTable(): React.ReactNode {
    if (projectLabels) {
      if (projectLabels.length > 0) {
        return (
          <Table>
            {loadingProjectLabels && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <PropagateLoader color="#276c8d" />
              </div>
            )}
            {projectLabels?.map(projectLabel => (
              <tr key={projectLabel.sequence}>
                <ItemSequence>
                  <ItemSequenceLabel>
                    {`${'Sequência'.toUpperCase()}: ${projectLabel.sequence}`}
                  </ItemSequenceLabel>
                </ItemSequence>

                <ItemContent>
                  <ItemWrapper>
                    <TableDefaultInput
                      type="text"
                      labelText="Controle"
                      upperCaseLabel
                      defaultValue={projectLabel.control}
                      maxLength={20}
                      error={projectLabel.conflict_error}
                      onChange={e => {
                        handleRowLabelsControlChange(
                          projectLabel.sequence,
                          e.target.value,
                        );
                      }}
                    />
                  </ItemWrapper>

                  <ItemWrapper>
                    <TableDefaultInput
                      type="text"
                      labelText="Embalagem"
                      upperCaseLabel
                      defaultValue={projectLabel.packaging}
                      maxLength={20}
                      onChange={e => {
                        handleRowLabelsPackagingChange(
                          projectLabel.sequence,
                          e.target.value,
                        );
                      }}
                    />
                  </ItemWrapper>
                </ItemContent>

                <ItemContent>
                  <ItemWrapper>
                    <TableDefaultInput
                      type="text"
                      labelText="Cliente"
                      upperCaseLabel
                      defaultValue={state.local_client?.name}
                      maxLength={60}
                      disabled
                    />
                  </ItemWrapper>

                  <ItemWrapper>
                    <TableDefaultInput
                      type="text"
                      labelText="Cidade"
                      upperCaseLabel
                      defaultValue={state.local_client?.city}
                      maxLength={50}
                      onChange={e => {
                        handleRowLabelsCityChange(
                          projectLabel.sequence,
                          e.target.value,
                        );
                      }}
                      disabled
                    />
                  </ItemWrapper>
                </ItemContent>

                <ItemContent>
                  <ItemWrapper>
                    <TableDefaultInput
                      type="text"
                      labelText="Estado"
                      upperCaseLabel
                      defaultValue={state.local_client?.state}
                      maxLength={30}
                      onChange={e => {
                        handleRowLabelsStateChange(
                          projectLabel.sequence,
                          e.target.value,
                        );
                      }}
                      disabled
                    />
                  </ItemWrapper>

                  <ItemWrapper>
                    <TableDefaultInput
                      type="text"
                      labelText="Série NF"
                      upperCaseLabel
                      defaultValue={projectLabel.invoice_series}
                      maxLength={3}
                      onChange={e => {
                        handleRowLabelsInvoiceSeriesChange(
                          projectLabel.sequence,
                          e.target.value,
                        );
                      }}
                    />
                  </ItemWrapper>

                  <ItemWrapper>
                    <TableDefaultInput
                      type="text"
                      labelText="Número NF"
                      upperCaseLabel
                      defaultValue={projectLabel.invoice_number}
                      error={projectLabel.invoice_validation_error}
                      warning={projectLabel.invoice_validation_warning}
                      maxLength={7}
                      onChange={e => {
                        handleRowLabelsInvoiceNumberChange(
                          projectLabel.sequence,
                          e.target.value,
                        );
                      }}
                    />
                  </ItemWrapper>
                </ItemContent>

                <ItemContent>
                  <ItemWrapper>
                    <TableDateInput
                      type="text"
                      labelText="Data Emissão NF"
                      upperCaseLabel
                      defaultValue={projectLabel.invoice_issue_date.toLocaleDateString()}
                      error={projectLabel.invoice_issue_date_error}
                      onChange={e => {
                        handleRowLabelsInvoiceIssueDateChange(
                          projectLabel.sequence,
                          e.target.value,
                        );
                      }}
                    />
                  </ItemWrapper>

                  <ItemWrapper>
                    <ItemSubWrapper>
                      <ItemInfoWrapper>
                        <TableCurrencyInput
                          type="text"
                          labelText="Valor Total NF"
                          upperCaseLabel
                          value={projectLabel.invoice_net_price}
                          automaticValue={
                            projectLabel.automatic_invoice_net_price
                          }
                          onSet={e => {
                            handleRowLabelsInvoiceNetPriceChange(
                              projectLabel.sequence,
                              e.target.value,
                            );
                          }}
                          disabled={!projectLabel.invoice_net_price_enabled}
                        />
                      </ItemInfoWrapper>

                      <ItemButtonsWrapper>
                        {projectLabel.invoice_net_price_enabled ? (
                          <UnlockButton
                            iconSize={20}
                            type="button"
                            onClick={() => {
                              handleRowLabelsInvoiceNetPriceEnabledChange(
                                projectLabel.sequence,
                                false,
                              );
                            }}
                          />
                        ) : (
                          <LockButton
                            iconSize={20}
                            type="button"
                            onClick={() => {
                              handleRowLabelsInvoiceNetPriceEnabledChange(
                                projectLabel.sequence,
                                true,
                              );
                            }}
                          />
                        )}
                      </ItemButtonsWrapper>
                    </ItemSubWrapper>
                  </ItemWrapper>

                  <ItemWrapper>
                    <TableDefaultInput
                      type="text"
                      labelText="CNPJ"
                      upperCaseLabel
                      defaultValue={state.local_client?.document}
                      maxLength={18}
                      onChange={e => {
                        handleRowLabelsDocumentChange(
                          projectLabel.sequence,
                          e.target.value,
                        );
                      }}
                      disabled
                    />
                  </ItemWrapper>
                </ItemContent>

                <ItemContent>
                  <ItemWrapper>
                    <TableDefaultInput
                      type="text"
                      labelText="Item"
                      upperCaseLabel
                      defaultValue={projectLabel.item_erp_code}
                      maxLength={14}
                      onChange={e => {
                        handleRowLabelsItemErpCodeChange(
                          projectLabel.sequence,
                          e.target.value,
                        );
                      }}
                      disabled
                    />
                  </ItemWrapper>

                  <ItemWrapper>
                    <TableDefaultInput
                      type="text"
                      labelText="Lote"
                      upperCaseLabel
                      defaultValue={projectLabel.batch}
                      maxLength={20}
                      onChange={e => {
                        handleRowLabelsBatchChange(
                          projectLabel.sequence,
                          e.target.value,
                        );
                      }}
                    />
                  </ItemWrapper>
                </ItemContent>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '10px 5px 10px 10px',
                  }}
                >
                  {handleDeleteProjectLabelRow(projectLabel)}
                </div>
              </tr>
            ))}
          </Table>
        );
      }
    }

    return <></>;
  }

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/samples"
        firstChild="Amostras"
        secondChild="Atualização Solicitação"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaBoxOpen}
        secondChildIcon={FaBoxOpen}
        mainColored
        firstChildColored
      />

      <ProjectInfo project_id={state.id} />

      <form onSubmit={handleSubmit(submitForm)}>
        <LabelContainer>
          <Label>Notas Fiscais</Label>
        </LabelContainer>

        <Content>{handleProjectSentInvoicesTable()}</Content>

        <ContentAddRow>
          <AddRowButton
            type="button"
            onClick={() => handleAddRowTableProjectSentInvoices()}
          />
        </ContentAddRow>

        <LabelContainer>
          <Label>Etiquetas</Label>
        </LabelContainer>

        <Content>{handleProjectLabelsTable()}</Content>

        <ContentAddRow>
          <AddRowButton
            type="button"
            onClick={() => handleAddRowTableProjectLabels()}
          />
        </ContentAddRow>

        <Content>
          <Wrapper>
            <TextArea
              name="record_note"
              labelFor="record_note"
              labelText="Observação"
              error={errors?.record_note?.message}
              ref={register}
              maxLength={1000}
            />
          </Wrapper>
        </Content>

        <Content>
          <UploadFiles
            type="anexo"
            project_temp_id={projectTemp}
            project_type="AMOSTRA"
            sequence={3}
            user_id={user.id}
          />
        </Content>

        <ButtonContainer>
          <SaveButton
            type="button"
            processing={isSaving}
            disabled={isSaving}
            onClick={() => onValidateConfirmProcedure()}
          />

          <ExitButton
            style={{ marginLeft: 10 }}
            type="button"
            disabled={isSaving}
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={onConfirmProcedure}
        title={titleConfirmProcedure}
        subTitle="O projeto será enviado para o próximo procedimento"
        opConfirm="Sim"
        type={typeConfirmProcedure}
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />

      <ConfirmWarningDialog
        isOpen={isOpenWarning}
        setIsOpen={setIsOpenWarning}
        onConfirm={onConfirmWarning}
        title="Deseja realmente alterar a NF previamente registrada?"
        opConfirm="Sim, alterar"
      />

      <DeleteModal
        isOpen={isOpenDeleteProjectSentInvoice}
        onDelete={onDeleteProjectSentInvoice}
        title={titleDeleteProjectSentInvoice}
        subTitle={subTitleDeleteProjectSentInvoice}
        setIsOpen={setIsOpenDeleteProjectSentInvoice}
        setReason={setReasonDeleteProjectSentInvoice}
      />

      <NoticeDialog
        isOpen={isOpenNotice}
        type="error"
        title={titleNotice}
        subTitle={subTitleNotice}
        setIsOpen={setIsOpenNotice}
      />
    </Container>
  );
};

export default Procedure3;
