import React, { InputHTMLAttributes, useState, useCallback } from 'react';
import moment from 'moment';

import ErrorTooltip from '~/components/Tooltips/Error';

import { Label, Container, InputText } from './styles';

interface IDateProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  labelFor?: string;
  labelText: string;
  error?: string;
  onSet?: (e: any) => void;
}

const Date = React.forwardRef<HTMLInputElement, IDateProps>(
  ({ name, labelText, labelFor, error, onSet, ...rest }: IDateProps, ref) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleInputFocus = useCallback(() => {
      setIsFocused(!isFocused);
    }, [isFocused]);

    const handleInputBlur = useCallback(() => {
      setIsFocused(false);
    }, []);

    const handleChange = (e: any): void => {
      if (onSet) {
        onSet(e.target.value);
      }
    };

    return (
      <>
        <Label isFocused={isFocused}>{labelText}</Label>

        <Container id={labelFor} isFocused={isFocused} isErrored={!!error}>
          <InputText
            name={name}
            type="type"
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            min={moment().format('YYYY-MM-DD')}
            max={moment().add(1825, 'days').format('YYYY-MM-DD')}
            ref={ref}
            onChange={e => {
              handleChange(e);
            }}
            {...rest}
          />

          {error && <ErrorTooltip title={error} />}
        </Container>
      </>
    );
  },
);

export default Date;
