import React from 'react';

import { Container } from './styles';

interface IDividerProps {
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
  title?: string;
}

const Divider: React.FC<IDividerProps> = ({
  mt,
  mr,
  mb,
  ml,
  title,
}: IDividerProps) => {
  return (
    <Container mt={mt} mr={mr} mb={mb} ml={ml}>
      {title && <span>{title}</span>}
    </Container>
  );
};

export default Divider;
