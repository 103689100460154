import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${theme.radius.small};
    border: 1px solid #e4e4e4;
    margin-bottom: 2rem;
    padding: 2rem 2rem 2rem 2rem;
    width: 100%;
    max-width: 680px;
    height: 100%;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 580px;
    border-radius: ${theme.radius.small};
  `}
`;
