import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { PropagateLoader } from 'react-spinners';
import { FaProjectDiagram, FaCalendarAlt } from 'react-icons/fa';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import BreadCrumb from '~/components/BreadCrumb';
import ProjectInfo from '~/components/ProjectInfo/Default';
import DateInput from '~/components/Inputs/Date';
import TableSelect from '~/components/TableSelect';
import TableWholeNumberInput from '~/components/TableInputs/WholeNumber';
import TableCurrencyInput from '~/components/TableInputs/Currency';
import TableTextArea from '~/components/TableTextArea';
import AddRowButton from '~/components/Buttons/AddRow';
import DeleteRowButton from '~/components/Buttons/DeleteRow';
import TextArea from '~/components/TextArea';
import UploadFiles from '~/components/UploadFiles';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';
import NoticeDialog from '~/components/Dialogs/Notice';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Content,
  ContentAddRow,
  Wrapper,
  LabelContainer,
  Label,
  Table,
  ItemSequence,
  ItemSequenceLabel,
  ItemContent,
  ItemWrapper,
  ButtonContainer,
} from './styles';

interface IProjectGift {
  sequence: number;
  amount: number;
  gift_type: {
    value: string;
  };
  user_inclusion_id: string;
  user_update_id: string;
  gift_type_error: string;
}

interface IProjectEventCost {
  sequence: number;
  price: number;
  note: string;
  event_cost_type: {
    value: string;
  };
  project_id: string;
  user_inclusion_id: string;
  user_update_id: string;
}

interface IFormProcedure2 {
  type: string;
  code: string;
  event_date: Date;
  user_update_id: string;
  validation_type: string;
  project_procedure_id: string;
  record_description: string;
  record_note: string;
  project_temp_id: string;
  project_event_costs: IProjectEventCost[];
  project_gifts: IProjectGift[];
}

interface ILocation {
  id: string;
  type: string;
  code: string;
  status: string;
  event_date: Date;
  project_procedure_id: string;
  project_procedure: {
    user_type: string;
    event_responsable: boolean;
  };
}

const schema = Yup.object().shape({
  event_date: Yup.date().typeError('Campo obrigatório'),
});

const Procedure2: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [giftTypes, setGiftTypes] = useState<
    {
      id: string;
      name: string;
      erp_code: string;
    }[]
  >();
  const [loadingGiftTypes, setLoadingGiftTypes] = useState<boolean>(true);
  const [eventCostTypes, setEventCostTypes] = useState<
    {
      id: string;
      name: string;
    }[]
  >();
  const [loadingEventCostTypes, setLoadingEventCostTypes] =
    useState<boolean>(true);
  const [projectGifts, setProjectGifts] = useState<IProjectGift[]>([]);
  const [loadProjectGifts, setLoadProjectGifts] = useState(false);
  const [projectGiftsLoaded, setProjectGiftsLoaded] = useState(false);
  const [loadingProjectGifts, setLoadingProjectGifts] = useState<boolean>(true);
  const [projectEventCosts, setProjectEventCosts] = useState<
    IProjectEventCost[]
  >([]);
  const [loadProjectEventCosts, setLoadProjectEventCosts] = useState(false);
  const [projectEventCostsLoaded, setProjectEventCostsLoaded] = useState(false);
  const [loadingProjectEventCosts, setLoadingProjectEventCosts] =
    useState<boolean>(true);
  const [projectTempFilled, setProjectTempFilled] = useState(false);
  const [projectTemp, setProjectTemp] = useState('');
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [titleConfirmProcedure, setTitleConfirmProcedure] = useState('');
  const [typeConfirmProcedure, setTypeConfirmProcedure] = useState('');
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isOpenNotice, setIsOpenNotice] = useState(false);
  const [titleNotice, setTitleNotice] = useState('');
  const [subTitleNotice, setSubTitleNotice] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const projectGiftSequence = useRef(0);
  const projectEventCostSequence = useRef(0);

  const { handleSubmit, register, getValues, setValue, errors } =
    useForm<IFormProcedure2>({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (loadProjectGifts) {
      projectGiftSequence.current += 1;
      const firstProjectGift: IProjectGift = {
        sequence: projectGiftSequence.current,
        amount: 0,
        gift_type: { value: '' },
        user_inclusion_id: user.id,
        user_update_id: user.id,
        gift_type_error: '',
      };

      setProjectGifts([firstProjectGift]);
      setLoadProjectGifts(false);
      setProjectGiftsLoaded(true);
      setLoadingProjectGifts(false);
    }
  }, [state, user, loadProjectGifts]);

  useEffect(() => {
    if (!projectGiftsLoaded) {
      setLoadProjectGifts(true);
    }
  }, [projectGiftsLoaded]);

  useEffect(() => {
    if (loadProjectEventCosts) {
      projectEventCostSequence.current += 1;
      const firstProjectEventCost: IProjectEventCost = {
        sequence: projectEventCostSequence.current,
        price: 0,
        note: '',
        event_cost_type: { value: '' },
        project_id: state.id,
        user_inclusion_id: user.id,
        user_update_id: user.id,
      };

      setProjectEventCosts([firstProjectEventCost]);
      setLoadProjectEventCosts(false);
      setProjectEventCostsLoaded(true);
      setLoadingProjectEventCosts(false);
    }
  }, [state, user, loadProjectEventCosts]);

  useEffect(() => {
    if (!projectEventCostsLoaded) {
      setLoadProjectEventCosts(true);
    }
  }, [projectEventCostsLoaded]);

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto não localizado',
      });

      history.push('/projects/events');
    } else if (
      !(
        user.user_type.type === state.project_procedure.user_type ||
        user.user_type.type === 'ADM' ||
        (state.project_procedure.user_type === 'MK2' &&
          user.user_type.type === 'MK1')
      )
    ) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Usuário não permitido',
      });

      history.push('/projects/events');
    } else if (state.status.toUpperCase() === 'CANCELADO') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto Cancelado',
      });

      history.push('/projects/events');
    } else if (state.status.toUpperCase() === 'FINALIZADO') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto Finalizado',
      });

      history.push('/projects/events');
    } else {
      register('validation_type');
      register('project_temp_id');
      register('type');
      register('code');
      register('record_description');
      register('user_update_id');
      register('project_procedure_id');
      register('project_gifts');
      register('project_event_costs');

      setValue('type', 'EVENTO');
      setValue('code', state.code);
      setValue('user_update_id', user.id);
      setValue('project_procedure_id', state.project_procedure_id);

      api.get('/configurations/gifttypes/valid').then(response => {
        setGiftTypes(response.data);
        setLoadingGiftTypes(false);
      });

      api.get('/configurations/eventcosttypes/valid').then(response => {
        setEventCostTypes(response.data);
        setLoadingEventCostTypes(false);
      });

      if (!projectTempFilled) {
        setProjectTempFilled(true);
        setProjectTemp(uuidv4());
      }
    }
  }, [addToast, history, state, user, projectTempFilled, register, setValue]);

  const giftTypeOptions = giftTypes?.map(giftType => ({
    value: giftType.id,
    label: `${giftType.name} ${
      giftType.erp_code !== null ? ` (${giftType.erp_code})` : ''
    }`,
  }));

  const eventCostTypeOptions = eventCostTypes?.map(eventCostType => ({
    value: eventCostType.id,
    label: eventCostType.name,
  }));

  useEffect(() => {
    setValue('project_temp_id', projectTemp);
  }, [projectTemp, setValue]);

  const submitForm = useCallback(
    async (data: IFormProcedure2) => {
      try {
        setIsSaving(true);

        await api.put(`/projects/${state.id}`, data);

        addToast({
          type: 'success',
          title: 'Projeto atualizado!',
          description: 'Projeto atualizado com sucesso.',
        });

        history.push('/projects/events');
      } catch (error: any) {
        setIsSaving(false);

        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [addToast, history, state],
  );

  async function onValidateConfirmProcedure(): Promise<void> {
    const giftsRowsInvalidType = projectGifts.filter(
      projGift => projGift.gift_type.value === '',
    );

    const giftsRowsInvalidAmount = projectGifts.filter(
      projGift => projGift.amount <= 0,
    );

    const giftsRowsGiftTypeError = projectGifts.filter(
      projGift => projGift.gift_type_error,
    );

    const eventCostsRowsInvalidType = projectEventCosts.filter(
      projEventCost => projEventCost.event_cost_type.value === '',
    );

    const eventCostsRowsInvalidPrice = projectEventCosts.filter(
      projEventCost => projEventCost.price <= 0,
    );

    const eventCostsRowsInvalidNote = projectEventCosts.filter(
      projEventCost => projEventCost.note === '',
    );

    if (giftsRowsInvalidType && giftsRowsInvalidType.length > 0) {
      setTitleNotice('Tipos inválidos em brindes!');
      setSubTitleNotice(
        'Não é possível enviar projeto com registro de brindes sem tipo selecionado!',
      );
      setIsOpenNotice(true);
    } else if (giftsRowsInvalidAmount && giftsRowsInvalidAmount.length > 0) {
      setTitleNotice('Quantidades inválidas em brindes!');
      setSubTitleNotice(
        'Não é possível enviar projeto com registro de brindes sem quantidade informada!',
      );
      setIsOpenNotice(true);
    } else if (giftsRowsGiftTypeError && giftsRowsGiftTypeError.length > 0) {
      setTitleNotice('Tipos inválidos em brindes!');
      setSubTitleNotice(
        'Não é possível atualizar solicitação com erros em registros de brindes!',
      );
      setIsOpenNotice(true);
    } else if (projectEventCosts.length <= 0) {
      setTitleNotice('Custos inválidos!');
      setSubTitleNotice('Necessário informar custos!');
      setIsOpenNotice(true);
    } else if (
      eventCostsRowsInvalidType &&
      eventCostsRowsInvalidType.length > 0
    ) {
      setTitleNotice('Tipos inválidos em custos!');
      setSubTitleNotice(
        'Não é possível enviar projeto com registro de custos sem tipo selecionado!',
      );
      setIsOpenNotice(true);
    } else if (
      eventCostsRowsInvalidPrice &&
      eventCostsRowsInvalidPrice.length > 0
    ) {
      setTitleNotice('Valores inválidos em custos!');
      setSubTitleNotice(
        'Não é possível enviar projeto com registro de custos sem valor!',
      );
      setIsOpenNotice(true);
    } else if (
      eventCostsRowsInvalidNote &&
      eventCostsRowsInvalidNote.length > 0
    ) {
      setTitleNotice('Existem custos sem observação!');
      setSubTitleNotice(
        'Não é possível enviar projeto com custos sem observação!',
      );
      setIsOpenNotice(true);
    } else if (projectGifts.length <= 0) {
      setTypeConfirmProcedure('danger');
      setTitleConfirmProcedure(
        `Nenhum brinde foi registrado! ` +
          `Confirma mesmo assim o envio para aprovação de custos, ` +
          `do projeto ${state.code}, com data de evento em ` +
          `${moment(getValues('event_date')).format('DD/MM/yyyy')}?`,
      );
      setIsOpenConfirmProcedure(true);
    } else {
      setTypeConfirmProcedure('');
      setTitleConfirmProcedure(
        `Confirma envio para aprovação de custos, ` +
          `do projeto ${state.code}, com data de evento ` +
          `em ${moment(getValues('event_date')).format('DD/MM/yyyy')}?`,
      );
      setIsOpenConfirmProcedure(true);
    }
  }

  function onConfirmProcedure(): void {
    setValue('validation_type', 'APROVADO');
    setValue('record_description', 'Registro de custos');
    setValue('project_gifts', projectGifts);
    setValue('project_event_costs', projectEventCosts);

    handleSubmit(submitForm)();
  }

  const onExit = useCallback(() => {
    api.delete(`projectfilesreceived/projecttempid/${projectTemp}`);

    history.push('/projects/events');
  }, [history, projectTemp]);

  function handleAddRowTableProjectGifts(): void {
    projectGiftSequence.current += 1;

    const newProjectGift: IProjectGift = {
      sequence: projectGiftSequence.current,
      amount: 0,
      gift_type: { value: '' },
      user_inclusion_id: user.id,
      user_update_id: user.id,
      gift_type_error: '',
    };

    setProjectGifts(prevProjectGifts => [...prevProjectGifts, newProjectGift]);
  }

  function handleDeleteRowTableProjectGifts(): void {
    setProjectGifts(
      projectGifts.filter(
        projGift => projGift.sequence !== projectGiftSequence.current,
      ),
    );

    projectGiftSequence.current -= 1;
  }

  useEffect(() => {
    Object.keys(projectGifts).forEach(key => {
      let conflict = false;

      const projGiftConflict = projectGifts.filter(
        projectGift =>
          projectGift.sequence !== projectGifts[Number(key)].sequence &&
          projectGift.gift_type.value ===
            projectGifts[Number(key)].gift_type.value,
      );

      if (projGiftConflict && projGiftConflict.length > 0) {
        conflict = true;
      }

      if (conflict && projectGifts[Number(key)].gift_type_error === '') {
        setProjectGifts(
          projectGifts.map(projGift => {
            if (projGift.sequence === projectGifts[Number(key)].sequence) {
              projGift.gift_type_error = 'Tipo de brinde já utilizado';
            }

            return projGift;
          }),
        );
      } else if (
        !conflict &&
        projectGifts[Number(key)].gift_type_error !== ''
      ) {
        setProjectGifts(
          projectGifts.map(projGift => {
            if (projGift.sequence === projectGifts[Number(key)].sequence) {
              projGift.gift_type_error = '';
            }

            return projGift;
          }),
        );
      }
    });
  }, [projectGifts]);

  async function handleRowGiftsTypeChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    setProjectGifts(
      projectGifts.map(projGift => {
        if (projGift.sequence === sequence) {
          projGift.gift_type.value = fieldValue;
        }
        return projGift;
      }),
    );
  }

  function handleRowGiftsAmountChange(
    sequence: number,
    fieldValue: number,
  ): void {
    let amountConv = fieldValue.toString();
    let amountValue = 0;

    amountConv = amountConv.replaceAll('.', '');
    amountConv = amountConv.replaceAll(',', '.');

    if (!Number.isNaN(Number(parseFloat(amountConv)))) {
      amountValue = parseFloat(amountConv);
    }

    setProjectGifts(
      projectGifts.map(projGift => {
        if (projGift.sequence === sequence) {
          projGift.amount = amountValue;
        }
        return projGift;
      }),
    );
  }

  function handleAddRowTableProjectEventCosts(): void {
    projectEventCostSequence.current += 1;

    const newProjectEventCost: IProjectEventCost = {
      sequence: projectEventCostSequence.current,
      price: 0,
      note: '',
      event_cost_type: { value: '' },
      project_id: state.id,
      user_inclusion_id: user.id,
      user_update_id: user.id,
    };

    setProjectEventCosts(prevProjectEventCosts => [
      ...prevProjectEventCosts,
      newProjectEventCost,
    ]);
  }

  function handleDeleteRowTableProjectEventCosts(): void {
    setProjectEventCosts(
      projectEventCosts.filter(
        projEventCost =>
          projEventCost.sequence !== projectEventCostSequence.current,
      ),
    );

    projectEventCostSequence.current -= 1;
  }

  async function handleRowEventCostsTypeChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    setProjectEventCosts(
      projectEventCosts.map(projEventCost => {
        if (projEventCost.sequence === sequence) {
          projEventCost.event_cost_type.value = fieldValue;
        }
        return projEventCost;
      }),
    );
  }

  function handleRowEventCostsPriceChange(
    sequence: number,
    fieldValue: string,
  ): void {
    let priceConv = fieldValue;
    let priceValue = 0;

    priceConv = priceConv.replaceAll('R$', '');
    priceConv = priceConv.replaceAll('.', '');
    priceConv = priceConv.replaceAll(',', '.');

    if (!Number.isNaN(Number(parseFloat(priceConv)))) {
      priceValue = parseFloat(priceConv);
    }

    setProjectEventCosts(
      projectEventCosts.map(projEventCost => {
        if (projEventCost.sequence === sequence) {
          projEventCost.price = priceValue;
        }
        return projEventCost;
      }),
    );
  }

  async function handleRowEventCostsNoteChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    setProjectEventCosts(
      projectEventCosts.map(projEventCost => {
        if (projEventCost.sequence === sequence) {
          projEventCost.note = fieldValue;
        }
        return projEventCost;
      }),
    );
  }

  function handleDeleteProjectGiftRow(
    projectGiftReceived: IProjectGift,
  ): React.ReactNode | null {
    if (projectGiftReceived.sequence === projectGiftSequence.current) {
      return (
        <DeleteRowButton
          type="button"
          onClick={() => {
            handleDeleteRowTableProjectGifts();
          }}
        />
      );
    }

    return null;
  }

  function handleProjectGiftsTable(): React.ReactNode {
    if (projectGifts) {
      if (projectGifts.length > 0) {
        return (
          <Table>
            {loadingProjectGifts && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <PropagateLoader color="#276c8d" />
              </div>
            )}
            {projectGifts?.map(projectGift => (
              <tr key={projectGift.sequence}>
                <ItemSequence>
                  <ItemSequenceLabel>
                    {`${'Sequência'.toUpperCase()}: ${projectGift.sequence}`}
                  </ItemSequenceLabel>
                </ItemSequence>

                <ItemContent>
                  <ItemWrapper>
                    <TableSelect
                      label="Tipo"
                      upperCaseLabel
                      options={giftTypeOptions}
                      loading={loadingGiftTypes}
                      error={projectGift.gift_type_error}
                      onSet={(e: any) => {
                        handleRowGiftsTypeChange(projectGift.sequence, e.value);
                      }}
                    />
                  </ItemWrapper>

                  <ItemWrapper>
                    <TableWholeNumberInput
                      type="text"
                      labelText="Quantidade"
                      upperCaseLabel
                      defaultValue={projectGift.amount}
                      onSet={e => {
                        handleRowGiftsAmountChange(
                          projectGift.sequence,
                          e.target.value,
                        );
                      }}
                    />
                  </ItemWrapper>
                </ItemContent>

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '10px 5px 10px 10px',
                  }}
                >
                  {handleDeleteProjectGiftRow(projectGift)}
                </div>
              </tr>
            ))}
          </Table>
        );
      }
    }

    return <></>;
  }

  function handleDeleteProjectEventCostRow(
    projectEventCostReceived: IProjectEventCost,
  ): React.ReactNode | null {
    if (
      projectEventCostReceived.sequence === projectEventCostSequence.current
    ) {
      return (
        <DeleteRowButton
          type="button"
          onClick={() => {
            handleDeleteRowTableProjectEventCosts();
          }}
        />
      );
    }

    return null;
  }

  function handleProjectEventCostsTable(): React.ReactNode {
    if (projectEventCosts) {
      if (projectEventCosts.length > 0) {
        return (
          <Table>
            {loadingProjectEventCosts && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <PropagateLoader color="#276c8d" />
              </div>
            )}
            {projectEventCosts?.map(projectEventCost => (
              <tr key={projectEventCost.sequence}>
                <ItemSequence>
                  <ItemSequenceLabel>
                    {`${'Sequência'.toUpperCase()}: ${
                      projectEventCost.sequence
                    }`}
                  </ItemSequenceLabel>
                </ItemSequence>

                <ItemContent>
                  <ItemWrapper>
                    <TableSelect
                      label="Tipo"
                      upperCaseLabel
                      options={eventCostTypeOptions}
                      loading={loadingEventCostTypes}
                      onSet={(e: any) => {
                        handleRowEventCostsTypeChange(
                          projectEventCost.sequence,
                          e.value,
                        );
                      }}
                    />
                  </ItemWrapper>

                  <ItemWrapper>
                    <TableCurrencyInput
                      type="text"
                      labelText="Valor"
                      upperCaseLabel
                      defaultValue={projectEventCost.price}
                      onSet={e => {
                        handleRowEventCostsPriceChange(
                          projectEventCost.sequence,
                          e.target.value,
                        );
                      }}
                    />
                  </ItemWrapper>
                </ItemContent>

                <ItemContent>
                  <ItemWrapper>
                    <TableTextArea
                      labelText="Observação"
                      upperCaseLabel
                      maxLength={2000}
                      onChange={e => {
                        handleRowEventCostsNoteChange(
                          projectEventCost.sequence,
                          e.target.value,
                        );
                      }}
                    />
                  </ItemWrapper>
                </ItemContent>

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '10px 5px 10px 10px',
                  }}
                >
                  {handleDeleteProjectEventCostRow(projectEventCost)}
                </div>
              </tr>
            ))}
          </Table>
        );
      }
    }

    return <></>;
  }

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/events"
        firstChild="Eventos"
        secondChild="Atualização Solicitação"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaCalendarAlt}
        secondChildIcon={FaCalendarAlt}
        mainColored
        firstChildColored
      />

      <ProjectInfo project_id={state.id} />

      <form onSubmit={handleSubmit(submitForm)}>
        <Content>
          <Wrapper style={{ width: '50%' }}>
            <DateInput
              name="event_date"
              labelFor="event_date"
              labelText="Data Evento"
              type="date"
              defaultValue={moment(state?.event_date).format('YYYY-MM-DD')}
              error={errors?.event_date?.message}
              ref={register}
            />
          </Wrapper>
        </Content>

        <LabelContainer>
          <Label>Brindes</Label>
        </LabelContainer>

        <Content>{handleProjectGiftsTable()}</Content>

        <ContentAddRow>
          <AddRowButton
            type="button"
            onClick={() => handleAddRowTableProjectGifts()}
          />
        </ContentAddRow>

        <LabelContainer>
          <Label>Custos</Label>
        </LabelContainer>

        <Content>{handleProjectEventCostsTable()}</Content>

        <ContentAddRow>
          <AddRowButton
            type="button"
            onClick={() => handleAddRowTableProjectEventCosts()}
          />
        </ContentAddRow>

        <Content>
          <Wrapper style={{ width: '100%' }}>
            <TextArea
              name="record_note"
              labelFor="record_note"
              labelText="Observação"
              error={errors?.record_note?.message}
              ref={register}
              maxLength={1000}
            />
          </Wrapper>
        </Content>

        <Content>
          <Wrapper style={{ width: '100%' }}>
            <UploadFiles
              type="anexo"
              project_temp_id={projectTemp}
              project_type="EVENTO"
              sequence={2}
              user_id={user.id}
            />
          </Wrapper>
        </Content>

        <ButtonContainer>
          <SaveButton
            type="button"
            processing={isSaving}
            disabled={isSaving}
            onClick={() => onValidateConfirmProcedure()}
          />

          <ExitButton
            style={{ marginLeft: 10 }}
            type="button"
            disabled={isSaving}
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={onConfirmProcedure}
        title={titleConfirmProcedure}
        subTitle="O projeto será enviado para o próximo procedimento"
        opConfirm="Sim"
        type={typeConfirmProcedure}
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />

      <NoticeDialog
        isOpen={isOpenNotice}
        type="error"
        title={titleNotice}
        subTitle={subTitleNotice}
        setIsOpen={setIsOpenNotice}
      />
    </Container>
  );
};

export default Procedure2;
