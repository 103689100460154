import React from 'react';
import ReactApexChart from 'react-apexcharts';

import { useTheme } from 'styled-components';

import { Container, Label } from './styles';

interface ISimplePieTypeProps {
  label: string;
  pendingAmount: number;
  finishedAmount: number;
  canceledAmount: number;
}

const SimplePieType: React.FC<ISimplePieTypeProps> = ({
  label,
  pendingAmount,
  finishedAmount,
  canceledAmount,
}: ISimplePieTypeProps) => {
  const { colors } = useTheme();

  const optionColors = [
    colors.chartPending,
    colors.chartFinished,
    colors.chartCanceled,
  ];

  const series = [pendingAmount, finishedAmount, canceledAmount];

  const options = {
    chart: {
      width: 380,
    },
    labels: ['Pendentes', 'Finalizados', 'Cancelados'],
    colors: optionColors,
    dataLabels: {
      enabled: true,
      formatter: (value: number) => {
        return `${Intl.NumberFormat('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(value)}%`;
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  return (
    <Container>
      <Label>{label}</Label>

      <ReactApexChart
        options={options}
        series={series}
        type="pie"
        width={380}
      />
    </Container>
  );
};

export default SimplePieType;
