import styled, { css } from 'styled-components';
import Modal from 'react-modal';

export const StyledModal = styled(Modal)`
  ${({ theme }) => css`
    margin-top: 10%;
    margin-left: 50%;
    transform: translate(-50%, -10%);
    background-color: ${theme.colors.background};
    box-shadow: ${theme.shadows.veryStrong};
    border-radius: ${theme.radius.meddium};
    width: 100%;
    max-width: 680px;

    @media (max-width: 1010px) {
      margin-top: 0;
      margin-left: 0;
      transform: translate(0%, 0%);
      margin: auto;
    }
  `}
`;

export const MainContainer = styled.div`
  padding: 1rem 1rem 1rem 1rem;
`;

export const Title = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.large};
  `}
`;

export const Container = styled.section`
  ${({ theme }) => css`
    width: 100%;
    background: ${theme.colors.backgroundContainer};
    border-radius: ${theme.radius.small};
    border: 1px solid #e4e4e4;
    margin-bottom: 2rem;
    padding: 2rem 2rem 2rem 2rem;
    max-width: 680px;
  `}
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;

  @media (max-width: 1010px) {
    flex-direction: column;
  }
`;

export const Wrapper = styled.div`
  width: 50%;
  margin-bottom: 2rem;

  @media (max-width: 1010px) {
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;
