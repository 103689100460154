import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { PropagateLoader } from 'react-spinners';
import * as mime from 'mime';

import { FaLink } from 'react-icons/fa';

import InfoInput from '~/components/Inputs/Info';
import TextArea from '~/components/TextArea';
import AudioIcon from '~/components/Icons/Files/Audio';
import PdfIcon from '~/components/Icons/Files/Pdf';
import PresentationIcon from '~/components/Icons/Files/Presentation';
import SpreadsheetIcon from '~/components/Icons/Files/Spreadsheet';
import TextIcon from '~/components/Icons/Files/Text';
import VideoIcon from '~/components/Icons/Files/Video';
import WordIcon from '~/components/Icons/Files/Word';
import Paginator from '~/components/Paginator';
import PrevButton from '~/components/Paginator/PrevButton';
import NextButton from '~/components/Paginator/NextButton';
import CenterButton from '~/components/Paginator/CenterButton';
import CloseButton from '~/components/Buttons/Close';

import api from '~/services/api';

import {
  StyledModal,
  MainContainer,
  Title,
  Container,
  Content,
  ContentSplit,
  Wrapper,
  HalfWrapper,
  FilesWrapper,
  FilterContent,
  Table,
  Th,
  Td,
  FileWrapper,
  FileImageWrapper,
  FileImage,
  UrlIcon,
  ButtonsContent,
  ButtonContainer,
} from './styles';

interface ITopicDetailProps {
  isOpen: boolean;
  onClose: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  store_relationship_code: string;
  store_relationship_topic_id: string;
  sequence: string;
  type: string;
  description: string;
  created_at: Date;
  updated_at: Date;
  user_inclusion: string;
  user_update: string;
}

interface IStoreRelationshipTopicFile {
  id: string;
  name: string;
  size: number;
  key: string;
  url: string;
  store_relationship_topic_id: string;
  user_id: string;
  store_relationship_topic_file_received_id: string;
  user: {
    id: string;
    name: string;
    type: string;
  };
  created_at: string;
}

const TopicDetail: React.FC<ITopicDetailProps> = ({
  isOpen,
  onClose,
  setIsOpen,
  store_relationship_code,
  store_relationship_topic_id,
  sequence,
  type,
  description,
  created_at,
  updated_at,
  user_inclusion,
  user_update,
}: ITopicDetailProps) => {
  const [page, setPage] = useState(0);
  const [pageFilter, setPageFilter] = useState(0);
  const [filter, setFilter] = useState('');
  const [filterHelper, setFilterHelper] = useState('');
  const [refresh, setRefresh] = useState(true);
  const [storeRelationshipTopicFiles, setStoreRelationshipTopicFiles] =
    useState<IStoreRelationshipTopicFile[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [nextDisabled, setNextDisabled] = useState(true);

  const handleClose = (): void => {
    setIsOpen(false);
    onClose();
    setPage(0);
    setPageFilter(0);
    setFilter('');
    setFilterHelper('');
    setRefresh(true);
    setStoreRelationshipTopicFiles([]);
    setLoading(true);
    setNextDisabled(true);
  };

  useEffect(() => {
    if (refresh && store_relationship_topic_id) {
      setRefresh(false);
      let pageSearch = 0;
      let foundData = false;

      if (filter !== filterHelper) {
        setPage(0);
        setPageFilter(0);
      }

      setFilterHelper(filter);

      if (filter !== '') {
        pageSearch = pageFilter;
      } else {
        pageSearch = page;
      }

      api
        .get(
          `/storerelationshiptopicfiles/storerelationshiptopic` +
            `?store_relationship_topic_id=${store_relationship_topic_id}` +
            `&page=${pageSearch}` +
            `&filter=${filter}`,
        )
        .then(firstResponse => {
          if (firstResponse.data.length > 0) {
            foundData = true;
            setStoreRelationshipTopicFiles(firstResponse.data);
            setLoading(false);
          }

          if (!foundData) {
            if (pageSearch - 1 >= 0) {
              pageSearch -= 1;
              if (filter !== '') {
                setPageFilter(pageFilter - 1);
              } else {
                setPage(page - 1);
              }
            }

            api
              .get(
                `/storerelationshiptopicfiles/storerelationshiptopic` +
                  `?store_relationship_topic_id=${store_relationship_topic_id}` +
                  `&page=${pageSearch}` +
                  `&filter=${filter}`,
              )
              .then(secondResponse => {
                setStoreRelationshipTopicFiles(secondResponse.data);
                setLoading(false);

                api
                  .get(
                    `/storerelationshiptopicfiles/storerelationshiptopic` +
                      `?store_relationship_topic_id=${store_relationship_topic_id}` +
                      `&page=${pageSearch + 1}` +
                      `&filter=${filter}`,
                  )
                  .then(thirdResponse => {
                    setNextDisabled(false);
                    if (thirdResponse.data.length === 0) {
                      setNextDisabled(true);
                    }
                  });
              });
          } else {
            api
              .get(
                `/storerelationshiptopicfiles/storerelationshiptopic` +
                  `?store_relationship_topic_id=${store_relationship_topic_id}` +
                  `&page=${pageSearch + 1}` +
                  `&filter=${filter}`,
              )
              .then(fourthResponse => {
                setNextDisabled(false);
                if (fourthResponse.data.length === 0) {
                  setNextDisabled(true);
                }
              });
          }
        });
    }
  }, [
    page,
    pageFilter,
    filter,
    filterHelper,
    refresh,
    store_relationship_topic_id,
  ]);

  function handlePage(action_type: string): void {
    if (action_type === 'backward') {
      if (filter !== '') {
        setPageFilter(pageFilter - 1);
      } else {
        setPage(page - 1);
      }
    } else if (action_type === 'forward') {
      if (filter !== '') {
        setPageFilter(pageFilter + 1);
      } else {
        setPage(page + 1);
      }
    } else if (filter !== '') {
      setPageFilter(0);
    } else {
      setPage(0);
    }
    setRefresh(true);
  }

  function handlePrevButtonDisabled(): boolean {
    let validationPage = 0;

    if (filter !== '') {
      validationPage = pageFilter;
    } else {
      validationPage = page;
    }

    if (validationPage === 0) {
      return true;
    }

    return false;
  }

  function handleFilterChange(value: string): void {
    setFilter(value);
    setRefresh(true);
  }

  function handleTopicFileImageIcon(topicFileUrl: string): React.ReactNode {
    const topicFileMimeType = mime.getType(topicFileUrl);

    if (
      topicFileMimeType === 'audio/mp3' ||
      topicFileMimeType === 'audio/mpeg'
    ) {
      return <AudioIcon iconSize={50} />;
    }

    if (topicFileMimeType === 'application/pdf') {
      return <PdfIcon iconSize={50} />;
    }

    if (
      topicFileMimeType ===
        'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
      topicFileMimeType === 'application/vnd.oasis.opendocument.presentation'
    ) {
      return <PresentationIcon iconSize={50} />;
    }

    if (
      topicFileMimeType ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      topicFileMimeType === 'application/vnd.oasis.opendocument.spreadsheet'
    ) {
      return <SpreadsheetIcon iconSize={50} />;
    }

    if (topicFileMimeType === 'text/plain') {
      return <TextIcon iconSize={50} />;
    }

    if (
      topicFileMimeType === 'image/gif' ||
      topicFileMimeType === 'video/quicktime' ||
      topicFileMimeType === 'video/mp4'
    ) {
      return <VideoIcon iconSize={40} />;
    }

    if (
      topicFileMimeType ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      topicFileMimeType === 'application/vnd.oasis.opendocument.text'
    ) {
      return <WordIcon iconSize={50} />;
    }

    return (
      <FileImageWrapper>
        <FileImage src={topicFileUrl} />
      </FileImageWrapper>
    );
  }

  function handleTable(): React.ReactNode {
    if (storeRelationshipTopicFiles) {
      if (storeRelationshipTopicFiles.length > 0) {
        return (
          <Table>
            <thead>
              <tr>
                <Th width="30%" textAlign="left" />
                <Th width="20%" textAlign="left">
                  Data
                </Th>
                <Th width="40%" textAlign="left">
                  Nome
                </Th>
                <Th width="10%" textAlign="center" />
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <Td colSpan={4} padding="1rem" textAlign="center">
                    <PropagateLoader color="#276c8d" />
                  </Td>
                </tr>
              )}
              {storeRelationshipTopicFiles?.map(storeRelationshipTopicFile => (
                <tr key={storeRelationshipTopicFile.id}>
                  <Td width="15%" textAlign="left" heading={' '}>
                    <FileWrapper>
                      {handleTopicFileImageIcon(storeRelationshipTopicFile.url)}
                    </FileWrapper>
                  </Td>
                  <Td width="20%" textAlign="left" heading="DATA">
                    {new Date(
                      storeRelationshipTopicFile.created_at,
                    ).toLocaleDateString()}
                  </Td>
                  <Td width="60%" textAlign="left" heading="NOME">
                    {storeRelationshipTopicFile.name}
                  </Td>
                  <Td
                    width="10%"
                    textAlign="center"
                    display="flex"
                    heading={' '}
                  >
                    <UrlIcon>
                      <a
                        href={storeRelationshipTopicFile.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaLink size={22} />
                      </a>
                    </UrlIcon>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        );
      }
    }

    return (
      <Table>
        <thead>
          <tr>
            <Th width="100%" textAlign="center" />
          </tr>
        </thead>
        <tbody>
          <tr>
            <Td width="100%" textAlign="center">
              Nenhuma informação disponível
            </Td>
          </tr>
        </tbody>
      </Table>
    );
  }

  return (
    <StyledModal
      isOpen={isOpen}
      style={{
        overlay: {
          zIndex: 999,
          overflowY: 'auto',
          position: 'fixed',
          background: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <MainContainer>
        <Title>Detalhes Tópico</Title>
        <Container>
          <ContentSplit maxWidth="680px">
            <HalfWrapper marginRight="1rem">
              <InfoInput
                name="store_relationship_code"
                labelFor="store_relationship_code"
                labelText="Cód. Registro"
                type="text"
                defaultValue={store_relationship_code}
                disabled
              />
            </HalfWrapper>

            <HalfWrapper>
              <InfoInput
                name="sequence"
                labelFor="sequence"
                labelText="Sequência"
                type="text"
                defaultValue={sequence}
                disabled
              />
            </HalfWrapper>
          </ContentSplit>

          <Content maxWidth="680px">
            <Wrapper>
              <InfoInput
                name="type"
                labelFor="type"
                labelText="Tipo"
                type="text"
                defaultValue={type}
                disabled
              />
            </Wrapper>
          </Content>

          <Content maxWidth="680px">
            <Wrapper>
              <TextArea
                name="description"
                labelFor="description"
                labelText="Descrição"
                customHeight="5rem"
                defaultValue={description}
                maxLength={250}
                disabled
              />
            </Wrapper>
          </Content>

          <FilesWrapper>
            <FilterContent>
              <InfoInput
                name="search"
                labelText="Pesquisar"
                type="text"
                onChange={event => handleFilterChange(event.target.value)}
              />
            </FilterContent>

            {handleTable()}

            <ButtonsContent>
              <Paginator>
                <PrevButton
                  disabled={handlePrevButtonDisabled()}
                  onClick={() => handlePage('backward')}
                />
                <CenterButton onClick={() => handlePage('reset')} />
                <NextButton
                  disabled={nextDisabled}
                  onClick={() => handlePage('forward')}
                />
              </Paginator>
            </ButtonsContent>
          </FilesWrapper>

          <ContentSplit maxWidth="680px">
            <HalfWrapper marginRight="1rem">
              <InfoInput
                name="created_at"
                labelFor="created_at"
                labelText="Data Inclusão"
                type="text"
                defaultValue={new Date(created_at).toLocaleDateString()}
                disabled
              />
            </HalfWrapper>

            <HalfWrapper>
              <InfoInput
                name="updated_at"
                labelFor="updated_at"
                labelText="Data Atualização"
                type="text"
                defaultValue={new Date(updated_at).toLocaleDateString()}
                disabled
              />
            </HalfWrapper>
          </ContentSplit>

          <ContentSplit maxWidth="680px">
            <HalfWrapper marginRight="1rem">
              <InfoInput
                name="user_inclusion"
                labelFor="user_inclusion"
                labelText="Usuário Inclusão"
                type="text"
                defaultValue={user_inclusion}
                disabled
              />
            </HalfWrapper>

            <HalfWrapper>
              <InfoInput
                name="user_update"
                labelFor="user_update"
                labelText="Usuário Atualização"
                type="text"
                defaultValue={user_update}
                disabled
              />
            </HalfWrapper>
          </ContentSplit>

          <ButtonContainer>
            <CloseButton onClick={() => handleClose()} />
          </ButtonContainer>
        </Container>
      </MainContainer>
    </StyledModal>
  );
};

export default TopicDetail;
