import styled, { css } from 'styled-components';
import { shade } from 'polished';

import Button from '~/components/Button';

export const Container = styled.section`
  ${({ theme }) => css`
    width: 100%;
    background: ${theme.colors.background};
    border-radius: ${theme.radius.default};
    box-shadow: ${theme.shadows.flat};
    padding: 2.4rem;
    margin: 2.4rem 0;
  `}
`;

export const Content = styled.div`
  max-width: 680px;
  display: flex;
  justify-content: flex-start;
`;

export const ContentSplit = styled(Content)`
  @media (max-width: 1010px) {
    flex-direction: column;
  }
`;

export const ContentAddRow = styled(Content)`
  justify-content: flex-end;
  padding-top: 1rem;
`;

export const Wrapper = styled.div`
  margin-bottom: 2rem;

  @media (max-width: 1010px) {
    width: 100% !important;
    margin: 0 0 2rem 0;
  }
`;

export const CenterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

export const DownloadButton = styled(Button)`
  ${({ theme }) => css`
    background-color: ${theme.colors.download};
    width: 18rem;
    height: 8rem;
    font-size: ${theme.fontSizes.small};
    padding: 1rem 1rem 1rem 1rem;

    :hover {
      background: ${shade(0.2, theme.colors.downloadHover)};
      color: #fff;
      transition: 0.2s;
    }
  `}
`;

export const DownloadLink = styled.a`
  ${({ theme }) => css`
    text-decoration: none;
    color: #fff;
    font-size: ${theme.fontSizes.small};
    font-weight: ${theme.weight.default};
  `}
`;

export const LabelContainer = styled.div`
  margin-bottom: 0.5rem;
`;

export const Label = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.default};
    color: ${theme.colors.labelInput};
  `}
`;

export const BorderContent = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 680px;
    border-radius: ${theme.radius.small};
    padding: 10px;
    margin-bottom: 1.8rem;
    border: 1px solid #e4e4e4;
  `}
`;

export const FlexWrapper = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 1010px) {
    flex-direction: column;
  }
`;

export const AllPatternsButton = styled(Button)`
  ${({ theme }) => css`
    background-color: ${theme.colors.save};
    width: 18rem;

    :hover {
      background: ${shade(0.2, theme.colors.saveHover)};
      color: #fff;
      transition: 0.2s;
    }
  `}
`;

export const Table = styled.table`
  ${({ theme }) => css`
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0 4px;
    background-color: ${theme.colors.lightGrey};
    box-shadow: 1px 1px 6px #ececec;
    border-radius: ${theme.radius.small};

    tr {
      border-radius: ${theme.radius.small};
    }
  `}
`;

export const ItemSequence = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 1rem 0.5rem 1rem 1rem;
    background-color: ${theme.colors.backgroundContainer};
    border-top-right-radius: ${theme.radius.small};
    border-top-left-radius: ${theme.radius.small};
  `}
`;

export const ItemContent = styled.div`
  display: flex;

  @media (max-width: 1010px) {
    flex-direction: column;
  }
`;

export const ItemWrapper = styled.div`
  width: 100%;
  padding: 0 0.5rem 0 0.5rem;
  margin-top: 1rem;
`;

export const NoticeLabel = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.grey};
    color: ${theme.colors.white};
    border-radius: ${theme.radius.default};
    width: 20rem;
    padding: 15px 0px 15px 15px;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20;
`;
