import React from 'react';

import Upload from './Upload';
import List from './List';

import { Container, Content } from './styles';

import { StoreRelationshipDisplayFileProvider } from '~/context/storerelationshipdisplayfiles';

interface IUploadStoreRelationshipDisplayFilesProps {
  type: string;
  store_relationship_display_temp_id: string;
  user_id: string;
  isDisabled?: boolean;
}

const UploadStoreRelationshipDisplayFiles: React.FC<IUploadStoreRelationshipDisplayFilesProps> =
  ({ type, store_relationship_display_temp_id, user_id, isDisabled }) => {
    return (
      <StoreRelationshipDisplayFileProvider>
        <Container>
          <Content>
            <Upload
              type={type}
              store_relationship_display_temp_id={
                store_relationship_display_temp_id
              }
              user_id={user_id}
              isDisabled={isDisabled}
            />
            <List />
          </Content>
        </Container>
      </StoreRelationshipDisplayFileProvider>
    );
  };

export default UploadStoreRelationshipDisplayFiles;
