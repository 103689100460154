import React from 'react';

import InfoInput from '~/components/Inputs/Info';

import { Container, Content, Wrapper } from './styles';

interface IItemProps {
  client_contact_name: string;
  client_contact_phone: string;
  client_contact_email: string;
}

const Item: React.FC<IItemProps> = ({
  client_contact_name,
  client_contact_phone,
  client_contact_email,
}) => {
  return (
    <Container>
      <Content>
        <Wrapper>
          <InfoInput
            name="client_contact_name"
            labelFor="client_contact_name"
            labelText="Nome"
            type="text"
            defaultValue={client_contact_name}
            disabled
          />
        </Wrapper>

        <Wrapper>
          <InfoInput
            name="client_contact_phone"
            labelFor="client_contact_phone"
            labelText="Telefone"
            type="text"
            defaultValue={client_contact_phone}
            disabled
          />
        </Wrapper>

        <Wrapper>
          <InfoInput
            name="client_contact_email"
            labelFor="client_contact_email"
            labelText="E-mail"
            type="text"
            defaultValue={client_contact_email}
            disabled
          />
        </Wrapper>
      </Content>
    </Container>
  );
};

export default Item;
