import React from 'react';

import InfoInput from '~/components/Inputs/Info';

import { Container, Content } from './styles';

interface IGrossDiscountPriceProps {
  gross_discount_price?: number;
}

const GrossDiscountPrice: React.FC<IGrossDiscountPriceProps> = ({
  gross_discount_price,
}) => {
  return (
    <Container>
      <Content>
        <InfoInput
          name="gross_discount_price"
          labelFor="gross_discount_price"
          labelText="Valor Total Desconto"
          type="text"
          defaultValue={
            gross_discount_price
              ? new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(gross_discount_price)
              : new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(0)
          }
          disabled
        />
      </Content>
    </Container>
  );
};

export default GrossDiscountPrice;
