import styled, { css } from 'styled-components';
import { shade } from 'polished';

import { ICloseProps } from '.';

export const Container = styled.button<ICloseProps>`
  ${({ theme, marginLeft }) => css`
    width: 18rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.2rem 6rem;
    background-color: ${theme.colors.secondary};
    color: #fff;
    border-radius: ${theme.radius.default};
    font-size: ${theme.fontSizes.button};
    font-weight: 400;

    :hover {
      background: ${shade(0.2, theme.colors.secondary)};
      transition: 0.2s;
    }

    ${marginLeft &&
    css`
      margin-left: ${marginLeft};
    `}
  `}
`;
