import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FcCheckmark, FcCancel } from 'react-icons/fc';
import { FaCogs, FaLaptopHouse } from 'react-icons/fa';
import { PropagateLoader } from 'react-spinners';

import BreadCrumb from '~/components/BreadCrumb';
import DefaultInput from '~/components/Inputs/Default';
import EditButton from '~/components/Buttons/Edit';
import DeleteButton from '~/components/Buttons/Delete';
import Pagination from '~/components/Pagination';
import NewButton from '~/components/Buttons/New';
import ConfirmDeleteDialog from '~/components/Dialogs/ConfirmDelete';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  FilterContent,
  Table,
  Thead,
  Tbody,
  Th,
  TbodyTr,
  Td,
  ButtonsContent,
} from './styles';

interface IStoreRelationshipTopicType {
  id: string;
  name: string;
  active: boolean;
}

const AMOUNT_PER_PAGE = 5;

const List: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState('');
  const [lastRefresh, setLastRefresh] = useState(new Date());
  const [storeRelationshipTopicTypes, setStoreRelationshipTopicTypes] =
    useState<IStoreRelationshipTopicType[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [
    storeRelationshipTopicTypeIdDelete,
    setStoreRelationshipTopicTypeIdDelete,
  ] = useState('');
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [titleDelete, setTitleDelete] = useState('');
  const [subTitleDelete, setSubTitleDelete] = useState('');

  useEffect(() => {
    if (
      user.user_type.type !== 'ADM' &&
      user.user_type.type !== 'MK1' &&
      user.user_type.type !== 'MK2' &&
      user.user_type.type !== 'MAX'
    ) {
      history.push('/configurations/main');
    }
  }, [history, user]);

  useEffect(() => {
    const loadStoreRelationshipTopicTypes = async () => {
      await api
        .get(
          `/configurations/storerelationshiptopictypes?page=${page}&filter=${filter}&amount=${AMOUNT_PER_PAGE}`,
        )
        .then(response => {
          setStoreRelationshipTopicTypes(response.data[0]);
          setCount(response.data[1]);
          setLoading(false);
        });
    };

    loadStoreRelationshipTopicTypes();
  }, [page, filter, lastRefresh]);

  const onDelete = useCallback(
    async (id: string) => {
      try {
        await api
          .delete(`/configurations/storerelationshiptopictypes/${id}`)
          .then(() => {
            setPage(0);
            setLastRefresh(new Date());

            addToast({
              type: 'success',
              title: 'Tipo de tópico de registro de loja excluído!',
              description:
                'Tipo de tópico de registro de loja excluído com sucesso.',
            });
          });
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
      setStoreRelationshipTopicTypeIdDelete('');
      setTitleDelete('');
      setSubTitleDelete('');
    },
    [addToast],
  );

  function handleTable(): React.ReactNode {
    if (storeRelationshipTopicTypes) {
      if (storeRelationshipTopicTypes.length > 0) {
        return (
          <Table>
            <Thead>
              <tr>
                <Th width="60%" textAlign="left">
                  Nome
                </Th>
                <Th width="20%" textAlign="center">
                  Situação
                </Th>
                <Th width="20%" textAlign="center" />
              </tr>
            </Thead>
            <Tbody>
              {loading && (
                <tr>
                  <Td colSpan={4} padding="1rem" textAlign="center">
                    <PropagateLoader color="#276c8d" />
                  </Td>
                </tr>
              )}
              {storeRelationshipTopicTypes?.map(storeRelationshipTopicType => (
                <TbodyTr key={storeRelationshipTopicType.id}>
                  <Td width="60%" textAlign="left" heading="NOME">
                    {storeRelationshipTopicType.name.substring(0, 100)}
                  </Td>
                  <Td width="20%" textAlign="center" heading="SITUAÇÃO">
                    {storeRelationshipTopicType.active ? (
                      <FcCheckmark size={22} />
                    ) : (
                      <FcCancel size={22} />
                    )}
                  </Td>
                  <Td
                    width="20%"
                    textAlign="center"
                    display="flex"
                    heading={' '}
                  >
                    <Link
                      to={{
                        pathname: `/configurations/storerelationshiptopictypes/edit/${storeRelationshipTopicType.id}`,
                        state: storeRelationshipTopicType,
                      }}
                    >
                      <EditButton />
                    </Link>

                    <DeleteButton
                      marginLeft="0.5rem"
                      onClick={() => {
                        setStoreRelationshipTopicTypeIdDelete(
                          storeRelationshipTopicType.id,
                        );
                        setIsOpenDelete(true);
                        setTitleDelete(
                          `Deseja realmente excluir o tipo de tópico de registro ` +
                            `de loja ${storeRelationshipTopicType.name}?`,
                        );
                        setSubTitleDelete(
                          'Esta operação não poderá ser desfeita!',
                        );
                      }}
                    />
                  </Td>
                </TbodyTr>
              ))}
            </Tbody>
          </Table>
        );
      }
    }

    return (
      <Table>
        <Thead>
          <tr>
            <Th width="100%" textAlign="center" />
          </tr>
        </Thead>
        <Tbody>
          <TbodyTr>
            <Td width="100%" textAlign="center">
              Nenhuma informação disponível
            </Td>
          </TbodyTr>
        </Tbody>
      </Table>
    );
  }

  return (
    <Container style={{ minHeight: 600 }}>
      <BreadCrumb
        main="Configurações"
        path="/configurations/main"
        firstChild="Tipos de Tópico de Registros de Lojas"
        mainIcon={FaCogs}
        firstChildIcon={FaLaptopHouse}
        mainColored
      />

      <FilterContent>
        <DefaultInput
          name="search"
          labelText="Pesquisar"
          type="text"
          onChange={event => {
            setFilter(event.target.value);
            setPage(0);
          }}
        />
      </FilterContent>

      {handleTable()}

      <ButtonsContent>
        <Pagination
          page={page}
          count={count}
          amountPerPage={AMOUNT_PER_PAGE}
          setPage={setPage}
        />

        <Link to="/configurations/storerelationshiptopictypes/new">
          <NewButton />
        </Link>
      </ButtonsContent>

      <ConfirmDeleteDialog
        isOpen={isOpenDelete}
        title={titleDelete}
        subTitle={subTitleDelete}
        setIsOpen={setIsOpenDelete}
        onClose={() => {
          onDelete(storeRelationshipTopicTypeIdDelete);
        }}
      />
    </Container>
  );
};

export default List;
