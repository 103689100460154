import React from 'react';
import { FaExclamation } from 'react-icons/fa';

import { Container } from './styles';

interface IWarningProps {
  title: string | undefined;
}

const Warning: React.FC<IWarningProps> = ({ title }) => {
  return (
    <Container title={title}>
      <FaExclamation size={14} />
    </Container>
  );
};

export default Warning;
