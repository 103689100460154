import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FaDatabase, FaInfoCircle, FaTools, FaUsers } from 'react-icons/fa';

import { useTheme } from 'styled-components';

import BreadCrumb from '~/components/BreadCrumb';
import FileUploader from '~/components/FileUploader';
import DefaultTooltip from '~/components/Tooltips/Default';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';

import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import { Container } from './styles';

const DataLoad: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { colors } = useTheme();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleFileSelect = (file: File | null) => {
    setSelectedFile(file);
  };

  const submitForm = useCallback(async () => {
    try {
      if (selectedFile) {
        setIsSaving(true);

        const data = new FormData();

        data.append('file', selectedFile);

        await api.post('localclients/dataload', data);

        addToast({
          type: 'success',
          title: 'Carga realizada!',
          description: 'Processo executado com sucesso.',
        });

        history.push('/management/localclients');
      }
    } catch (error: any) {
      setIsSaving(false);

      addToast({
        type: 'error',
        title: 'Ocorreu um erro!',
        description: error.response.data.message,
      });
    }
  }, [addToast, history, selectedFile]);

  return (
    <Container>
      <BreadCrumb
        main="Administração"
        path="/management/main"
        firstChildPath="/management/localclients"
        firstChild="Clientes"
        secondChild="Carga de Dados"
        mainIcon={FaTools}
        firstChildIcon={FaUsers}
        secondChildIcon={FaDatabase}
        mainColored
        firstChildColored
      />

      <div
        style={{
          display: 'flex',
          marginBottom: 10,
          columnGap: 3,
          fontSize: 14,
          fontStyle: 'italic',
        }}
      >
        <FaInfoCircle size={16} />

        <p>Carga de dados para atualização de contas contábeis dos clientes</p>
      </div>

      <div style={{ width: '100%', maxWidth: 680 }}>
        <FileUploader
          acceptedFileTypes={[
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
          ]}
          onFileSelect={handleFileSelect}
        />
      </div>

      <div
        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}
      >
        <DefaultTooltip
          title="Necessário selecionar arquivo"
          background={colors.save}
          borderColor={colors.save}
          disabled={selectedFile !== null}
        >
          <SaveButton
            type="button"
            processing={isSaving}
            disabled={isSaving || selectedFile === null}
            onClick={() => {
              setIsOpenConfirmProcedure(true);
            }}
          />
        </DefaultTooltip>

        <ExitButton
          style={{ marginLeft: 10 }}
          type="button"
          disabled={isSaving}
          onClick={() => setIsOpenExit(true)}
        />
      </div>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={() => submitForm()}
        title="Confirma edição do cliente?"
        subTitle=""
        opConfirm="Sim"
        type=""
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={() => history.push('/management/localclients')}
      />
    </Container>
  );
};

export default DataLoad;
