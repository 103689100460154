import styled from 'styled-components';

export const Container = styled.section`
  max-width: 1080px;
  margin-bottom: 1rem;
`;

export const FilterContent = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 5rem;
  @media (max-width: 1070px) {
    flex-direction: column;
  }
`;

export const FilterWrapper = styled.div`
  width: 100%;
  margin-right: 1rem;
`;

export const ChartWrapper = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 1070px) {
    flex-direction: column;
  }
`;

export const ChartRightContent = styled.div`
  margin-left: 15rem;
  @media (max-width: 1070px) {
    margin-left: 0;
  }
`;
