import React from 'react';
import ReactApexChart from 'react-apexcharts';

import { useTheme } from 'styled-components';

import { Container, Label } from './styles';

interface IDistributedColumnsPrices1Props {
  label?: string;
  price: number;
  shippingPrice: number;
  totalPrice: number;
}

const DistributedColumnsPrices1: React.FC<IDistributedColumnsPrices1Props> = ({
  label,
  price,
  shippingPrice,
  totalPrice,
}: IDistributedColumnsPrices1Props) => {
  const { colors } = useTheme();

  const optionColors = [
    colors.chartPrice,
    colors.chartShippingPrice,
    colors.chartTotalPrice,
  ];

  const series = [
    {
      name: '',
      data: [price, shippingPrice, totalPrice],
    },
  ];

  const options = {
    chart: {
      offsetX: 0,
      offsetY: 0,
      redrawOnWindowResize: true,
      toolbar: {
        show: false,
      },
    },
    colors: optionColors,
    plotOptions: {
      bar: {
        columnWidth: '50%',
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: ['Valor', 'Valor Frete', 'Valor Total'],
      labels: {
        style: {
          colors: optionColors,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        formatter: (value: number) => {
          return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(value);
        },
      },
    },
  };

  return (
    <Container>
      {label && <Label>{label}</Label>}

      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </Container>
  );
};

export default DistributedColumnsPrices1;
