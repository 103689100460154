import React from 'react';
import { useLocation } from 'react-router-dom';

import { AnimatedContainer, FooterSection, Wrapper, Span } from './styles';
import { CONTAINER_ANIMATION } from './animations';

const Footer: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <AnimatedContainer
      variants={CONTAINER_ANIMATION}
      initial={pathname === '/dashboard' ? 'unMounted' : 'mounted'}
      animate="mounted"
    >
      <FooterSection as="section">
        <Wrapper>
          <Span>{`Copyright ${new Date().getFullYear()} © Guararapes Painéis S/A.`}</Span>
        </Wrapper>
      </FooterSection>
    </AnimatedContainer>
  );
};

export default Footer;
