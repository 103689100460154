import React, { ButtonHTMLAttributes } from 'react';

import { FaArrowAltCircleRight } from 'react-icons/fa';

import { Container } from './styles';

export interface ISelectProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  marginTop?: string;
  marginLeft?: string;
}

const Select: React.FC<ISelectProps> = ({ ...rest }) => {
  return (
    <Container {...rest}>
      <FaArrowAltCircleRight size={16} />
    </Container>
  );
};

export default Select;
