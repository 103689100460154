import React, { Dispatch, SetStateAction } from 'react';
import { FcLowPriority, FcHighPriority } from 'react-icons/fc';
import { Dialog } from '@material-ui/core';

import DialogOptionButton from '~/components/Buttons/DialogOption';

import {
  Container,
  IconContainer,
  Title,
  SubTitle,
  ButtonContainer,
} from './styles';

interface IConfirmProcedureProps {
  isOpen: boolean;
  title: string;
  subTitle: string;
  onConfirm: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  opConfirm?: string;
  type?: string;
}

const ConfirmProcedure: React.FC<IConfirmProcedureProps> = ({
  isOpen,
  title,
  subTitle,
  onConfirm,
  setIsOpen,
  opConfirm,
  type,
}: IConfirmProcedureProps) => {
  const handleClose = (): void => {
    setIsOpen(false);
  };

  const handleConfirmation = (): void => {
    setIsOpen(false);
    onConfirm();
  };

  function getIcon(): JSX.Element {
    if (type) {
      if (type === 'danger') {
        return <FcHighPriority size={60} />;
      }
    }

    return <FcLowPriority size={60} />;
  }

  return (
    <Dialog open={isOpen}>
      <Container>
        <IconContainer>{getIcon()}</IconContainer>

        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>

        <ButtonContainer>
          <DialogOptionButton
            label={opConfirm !== undefined ? opConfirm : 'Sim'}
            optionType="success"
            onClick={() => handleConfirmation()}
          />

          <DialogOptionButton
            label="Não"
            marginLeft="1.5rem"
            onClick={() => handleClose()}
          />
        </ButtonContainer>
      </Container>
    </Dialog>
  );
};

export default ConfirmProcedure;
