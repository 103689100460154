import styled, { css } from 'styled-components';

interface IContainerProps {
  highlighted?: boolean;
}

export const Container = styled.div<IContainerProps>`
  ${({ theme, highlighted }) => css`
    color: ${theme.colors.grey};
    font-size: ${theme.fontSizes.xLarge};
    font-weight: bold;
    justify-content: center;
    margin-left: 0.5rem;

    ${highlighted &&
    css`
      color: ${theme.colors.darkGrey};
    `}
  `}
`;
