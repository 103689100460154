import styled, { css } from 'styled-components';

interface IDropContainerProps {
  isDragActive?: boolean;
  isDragReject?: boolean;
}

interface IUploadMessageProps {
  type?: string;
}

interface IFilePreviewImageProps {
  src: string;
}

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${theme.radius.small};
    border: 1px solid #e4e4e4;
    margin-bottom: 2rem;
    padding: 2rem 2rem 2rem 2rem;
    width: 100%;
    max-width: 680px;
    height: 100%;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 580px;
    border-radius: ${theme.radius.small};
  `}
`;

export const DropContainer = styled.div<IDropContainerProps>`
  ${({ theme, isDragActive, isDragReject }) => css`
    border: 1px dashed #ddd;
    border-radius: 4px;
    cursor: pointer;

    transition: height 0.2s ease;

    ${isDragActive &&
    css`
      border-color: ${theme.colors.vividCyan};
    `}

    ${isDragReject &&
    css`
      border-color: ${theme.colors.danger};
    `}
  `}
`;

export const UploadMessage = styled.p<IUploadMessageProps>`
  ${({ theme, type }) => css`
    display: flex;
    color: ${theme.colors.grey};
    font-size: ${theme.fontSizes.default};
    justify-content: center;
    align-items: center;
    padding: 15px 0;

    ${type === 'success' &&
    css`
      color: ${theme.colors.vividCyan};
    `}

    ${type === 'error' &&
    css`
      color: ${theme.colors.error};
    `}
  `}
`;

export const FilePreviewImage = styled.div<IFilePreviewImageProps>`
  ${({ theme, src }) => css`
    width: 55px;
    height: 55px;
    border-radius: ${theme.radius.small};
    background-image: url(${src});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  `}
`;
