import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { FaProjectDiagram, FaMoneyBillWave } from 'react-icons/fa';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import BreadCrumb from '~/components/BreadCrumb';
import ProjectInfo from '~/components/ProjectInfo/Default';
import CompleteIcon from '~/components/TimelineElements/CompleteIcon';
import PendingIcon from '~/components/TimelineElements/PendingIcon';
import Title from '~/components/TimelineElements/Title';
import Topic from '~/components/TimelineElements/Topic';
import Gradient from '~/components/TimelineElements/Gradient';
import DetailTitle from '~/components/TimelineElements/DetailTitle';
import DetailProcedure from '~/components/TimelineElements/DetailProcedure';
import DetailInfo from '~/components/TimelineElements/DetailInfo';
import Code from '~/components/TimelineItems/Code';
import Representative from '~/components/TimelineItems/Representative';
import Client from '~/components/TimelineItems/Client';
import Item from '~/components/TimelineItems/Item';
import RebateCampaign from '~/components/TimelineItems/RebateCampaign';
import ClientContact from '~/components/TimelineItems/ClientContact';
import GrossDiscountPriceInitialStage from '~/components/TimelineItems/GrossDiscountPriceInitialStage';
import NetDiscountPrice from '~/components/TimelineItems/NetDiscountPrice';
import SponsorshipSheetsInitialStage from '~/components/TimelineItems/SponsorshipSheetsInitialStage';
import Observation from '~/components/TimelineItems/Observation';
import Records from '~/components/TimelineItems/Records';
import Files from '~/components/TimelineItems/Files';
import RequestFiles from '~/components/TimelineItems/RequestFiles';
import Validation from '~/components/TimelineItems/Validation';
import RecordNote from '~/components/TimelineItems/RecordNote';
import ApprovalFiles from '~/components/TimelineItems/ApprovalFiles';
import CompletionFiles from '~/components/TimelineItems/CompletionFiles';
import SponsorshipSheets from '~/components/TimelineItems/SponsorshipSheets';
import TotalSponsorshipSheetsPrice from '~/components/TimelineItems/TotalSponsorshipSheetsPrice';
import GrossDiscountPrice from '~/components/TimelineItems/GrossDiscountPrice';
import TaxDeductions from '~/components/TimelineItems/TaxDeductions';
import Invoices from '~/components/TimelineItems/Invoices';
import ExitButton from '~/components/Buttons/Exit';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import {
  Container,
  TabTitle,
  ProcedureContent,
  MainContent,
  TitleContent,
  TitleTextWrapper,
  InfoContent,
  DetailContent,
  DetailItemWrapper,
  ButtonContainer,
} from './styles';

interface IDetail {
  procedure: string;
  information: string;
  component: string;
}

interface ILocation {
  id: string;
  type: string;
  code: string;
  price: number;
  gross_discount_price: number;
  net_discount_price: number;
  total_sponsorship_sheets_price: number;
  erp_client_code: string;
  erp_rep_code: string;
  note: string;
  status: string;
  rebate_campaign: boolean;
  spons_final_approval_dismissal: boolean;
  sponsorship_art_approval: boolean;
  sponsorship_completion_files: boolean;
  client_contact_name: string;
  client_contact_phone: string;
  client_contact_email: string;
  sponsorship: {
    code: string;
    name: string;
  };
  project_procedure: {
    sequence: number;
  };
}

const View: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [tabIndex, setTabIndex] = useState(0);
  const [detail, setDetail] = useState<IDetail>();

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto não localizado',
      });

      history.push('/projects/sponsorships');
    } else if (
      user.user_type.type === 'REP' &&
      state.erp_rep_code !== user.erp_rep_code
    ) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Usuário não permitido',
      });

      history.push('/projects/sponsorships');
    }
  }, [addToast, history, state, user]);

  function handleFiles(
    procedure: string,
    information: string,
    component: string,
  ): JSX.Element | null {
    if (user.user_type.type !== 'REP') {
      return (
        <Topic
          label="- Anexos"
          onClick={() => {
            setDetail({ procedure, information, component });
          }}
        />
      );
    }

    return null;
  }

  function handleProcedure1(): JSX.Element | null {
    if (
      state?.project_procedure &&
      state.project_procedure.sequence &&
      state.project_procedure.sequence > 1
    ) {
      return (
        <MainContent>
          <TitleContent>
            <CompleteIcon />

            <TitleTextWrapper>
              <Title label="Solicitação" />
            </TitleTextWrapper>
          </TitleContent>

          <InfoContent>
            <Gradient height="570px" />

            <div>
              <Topic
                label="- Código"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Código',
                    component: '01_code',
                  });
                }}
              />

              <Topic
                label="- Representante"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Representante',
                    component: '01_representative',
                  });
                }}
              />

              <Topic
                label="- Cliente"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Cliente',
                    component: '01_client',
                  });
                }}
              />

              <Topic
                label="- Item"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Item',
                    component: '01_item',
                  });
                }}
              />

              <Topic
                label="- Campanha de Rebate"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Campanha de Rebate',
                    component: '01_rebate_campaign',
                  });
                }}
              />

              <Topic
                label="- Contato"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Contato',
                    component: '01_client_contact',
                  });
                }}
              />

              <Topic
                label="- Valor Total Desconto"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Valor Total Desconto',
                    component: '01_gross_discount',
                  });
                }}
              />

              <Topic
                label="- Atualização Desconto Líquido"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Valor Desconto Líquido',
                    component: '01_net_discount',
                  });
                }}
              />

              <Topic
                label="- Chapas de MDF"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Chapas de MDF',
                    component: '01_sheets',
                  });
                }}
              />

              <Topic
                label="- Observação Representante"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Observação Representante',
                    component: '01_representative_obs',
                  });
                }}
              />

              <Topic
                label="- Histórico"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Histórico',
                    component: '01_records',
                  });
                }}
              />

              {handleFiles('Solicitação', 'Anexos', '01_files')}

              <Topic
                label="- Anexos de Requisição"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação',
                    information: 'Anexos de Requisição',
                    component: '01_request_files',
                  });
                }}
              />
            </div>
          </InfoContent>
        </MainContent>
      );
    }

    return null;
  }

  function handleProcedure2(): JSX.Element | null {
    if (
      state?.project_procedure &&
      state.project_procedure.sequence &&
      (state.project_procedure.sequence > 2 ||
        (state.project_procedure.sequence === 2 &&
          state.status === 'Cancelado'))
    ) {
      return (
        <MainContent>
          <TitleContent>
            <CompleteIcon />

            <TitleTextWrapper>
              <Title label="Aprovação Marketing" />
            </TitleTextWrapper>
          </TitleContent>

          <InfoContent>
            <Gradient height="400px" />

            <div>
              <Topic
                label="- Validação"
                onClick={() => {
                  setDetail({
                    procedure: 'Aprovação Marketing',
                    information: 'Validação',
                    component: '02_validation',
                  });
                }}
              />

              <Topic
                label="- Observação"
                onClick={() => {
                  setDetail({
                    procedure: 'Aprovação Marketing',
                    information: 'Observação',
                    component: '02_obs',
                  });
                }}
              />

              <Topic
                label="- Histórico"
                onClick={() => {
                  setDetail({
                    procedure: 'Aprovação Marketing',
                    information: 'Histórico',
                    component: '02_records',
                  });
                }}
              />

              {handleFiles('Aprovação Marketing', 'Anexos', '02_files')}
            </div>
          </InfoContent>
        </MainContent>
      );
    }

    return null;
  }

  function handleProcedure3(): JSX.Element | null {
    if (
      state?.project_procedure &&
      state.project_procedure.sequence &&
      (state.project_procedure.sequence > 3 ||
        (state.project_procedure.sequence === 3 &&
          state.status === 'Cancelado'))
    ) {
      return (
        <MainContent>
          <TitleContent>
            <CompleteIcon />

            <TitleTextWrapper>
              <Title label="Aprovação Gestão Marketing" />
            </TitleTextWrapper>
          </TitleContent>

          <InfoContent>
            <Gradient height="400px" />

            <div>
              <Topic
                label="- Validação"
                onClick={() => {
                  setDetail({
                    procedure: 'Aprovação Gestão Marketing',
                    information: 'Validação',
                    component: '03_validation',
                  });
                }}
              />

              <Topic
                label="- Observação"
                onClick={() => {
                  setDetail({
                    procedure: 'Aprovação Gestão Marketing',
                    information: 'Observação',
                    component: '03_obs',
                  });
                }}
              />

              <Topic
                label="- Histórico"
                onClick={() => {
                  setDetail({
                    procedure: 'Aprovação Gestão Marketing',
                    information: 'Histórico',
                    component: '03_records',
                  });
                }}
              />

              {handleFiles('Aprovação Gestão Marketing', 'Anexos', '03_files')}
            </div>
          </InfoContent>
        </MainContent>
      );
    }

    return null;
  }

  function handleProcedure4(): JSX.Element | null {
    if (
      state?.project_procedure &&
      state.project_procedure.sequence &&
      state.project_procedure.sequence > 4 &&
      state.sponsorship_art_approval
    ) {
      return (
        <MainContent>
          <TitleContent>
            <CompleteIcon />

            <TitleTextWrapper>
              <Title label="Solicitação de Arte" />
            </TitleTextWrapper>
          </TitleContent>

          <InfoContent>
            <Gradient height="400px" />

            <div>
              <Topic
                label="- Anexos de Aprovação"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação de Arte',
                    information: 'Anexos de Aprovação',
                    component: '04_approval_files',
                  });
                }}
              />

              <Topic
                label="- Observação"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação de Arte',
                    information: 'Observação',
                    component: '04_obs',
                  });
                }}
              />

              <Topic
                label="- Histórico"
                onClick={() => {
                  setDetail({
                    procedure: 'Solicitação de Arte',
                    information: 'Histórico',
                    component: '04_records',
                  });
                }}
              />

              {handleFiles('Solicitação de Arte', 'Anexos', '04_files')}
            </div>
          </InfoContent>
        </MainContent>
      );
    }

    return null;
  }

  function handleProcedure5(): JSX.Element | null {
    if (
      state?.project_procedure &&
      state.project_procedure.sequence &&
      (state.project_procedure.sequence > 5 ||
        (state.project_procedure.sequence === 5 &&
          state.status === 'Cancelado')) &&
      state.sponsorship_art_approval
    ) {
      return (
        <MainContent>
          <TitleContent>
            <CompleteIcon />

            <TitleTextWrapper>
              <Title label="Aprovação de Arte" />
            </TitleTextWrapper>
          </TitleContent>

          <InfoContent>
            <Gradient height="400px" />

            <div>
              <Topic
                label="- Validação"
                onClick={() => {
                  setDetail({
                    procedure: 'Aprovação de Arte',
                    information: 'Validação',
                    component: '05_validation',
                  });
                }}
              />

              <Topic
                label="- Observação"
                onClick={() => {
                  setDetail({
                    procedure: 'Aprovação de Arte',
                    information: 'Observação',
                    component: '05_obs',
                  });
                }}
              />

              <Topic
                label="- Histórico"
                onClick={() => {
                  setDetail({
                    procedure: 'Aprovação de Arte',
                    information: 'Histórico',
                    component: '05_records',
                  });
                }}
              />

              {handleFiles('Aprovação de Arte', 'Anexos', '05_files')}
            </div>
          </InfoContent>
        </MainContent>
      );
    }

    return null;
  }

  function handleProcedure6(): JSX.Element | null {
    if (
      state?.project_procedure &&
      state.project_procedure.sequence &&
      state.project_procedure.sequence > 6 &&
      (state.sponsorship_art_approval || state.sponsorship_completion_files)
    ) {
      return (
        <MainContent>
          <TitleContent>
            <CompleteIcon />

            <TitleTextWrapper>
              <Title label="Produção de Material" />
            </TitleTextWrapper>
          </TitleContent>

          <InfoContent>
            <Gradient height="400px" />

            <div>
              <Topic
                label="- Anexos de Conclusão"
                onClick={() => {
                  setDetail({
                    procedure: 'Produção de Material',
                    information: 'Anexos de Conclusão',
                    component: '06_completion_files',
                  });
                }}
              />

              <Topic
                label="- Observação"
                onClick={() => {
                  setDetail({
                    procedure: 'Produção de Material',
                    information: 'Observação',
                    component: '06_obs',
                  });
                }}
              />

              <Topic
                label="- Histórico"
                onClick={() => {
                  setDetail({
                    procedure: 'Produção de Material',
                    information: 'Histórico',
                    component: '06_records',
                  });
                }}
              />

              {handleFiles('Produção de Material', 'Anexos', '06_files')}
            </div>
          </InfoContent>
        </MainContent>
      );
    }

    return null;
  }

  function handleProcedure7(): JSX.Element | null {
    if (
      state?.project_procedure &&
      state.project_procedure.sequence &&
      (state.project_procedure.sequence > 7 ||
        (state.project_procedure.sequence === 7 &&
          state.status === 'Cancelado')) &&
      !state.spons_final_approval_dismissal
    ) {
      return (
        <MainContent>
          <TitleContent>
            <CompleteIcon />

            <TitleTextWrapper>
              <Title label="Aprovação Final" />
            </TitleTextWrapper>
          </TitleContent>

          <InfoContent>
            <Gradient height="400px" />

            <div>
              <Topic
                label="- Validação"
                onClick={() => {
                  setDetail({
                    procedure: 'Aprovação Final',
                    information: 'Validação',
                    component: '07_validation',
                  });
                }}
              />

              <Topic
                label="- Observação"
                onClick={() => {
                  setDetail({
                    procedure: 'Aprovação Final',
                    information: 'Observação',
                    component: '07_obs',
                  });
                }}
              />

              <Topic
                label="- Histórico"
                onClick={() => {
                  setDetail({
                    procedure: 'Aprovação Final',
                    information: 'Histórico',
                    component: '07_records',
                  });
                }}
              />

              {handleFiles('Aprovação Final', 'Anexos', '07_files')}
            </div>
          </InfoContent>
        </MainContent>
      );
    }

    return null;
  }

  function handleProcedure8(): JSX.Element | null {
    if (
      state?.project_procedure &&
      state.project_procedure.sequence &&
      state.project_procedure.sequence > 8
    ) {
      return (
        <MainContent>
          <TitleContent>
            <CompleteIcon />

            <TitleTextWrapper>
              <Title label="Cálculo de Abatimento Fiscal" />
            </TitleTextWrapper>
          </TitleContent>

          <InfoContent>
            <Gradient height="400px" />

            <div>
              <Topic
                label="- Atualização Chapas de MDF"
                onClick={() => {
                  setDetail({
                    procedure: 'Cálculo de Abatimento Fiscal',
                    information: 'Chapas de MDF',
                    component: '08_sheets',
                  });
                }}
              />

              <Topic
                label="- Valor Total Chapas"
                onClick={() => {
                  setDetail({
                    procedure: 'Cálculo de Abatimento Fiscal',
                    information: 'Valor Total Chapas',
                    component: '08_total_sponsorship_sheets_price',
                  });
                }}
              />

              <Topic
                label="- Atualização Valor Total Desconto"
                onClick={() => {
                  setDetail({
                    procedure: 'Cálculo de Abatimento Fiscal',
                    information: 'Valor Total Desconto',
                    component: '08_gross_discount',
                  });
                }}
              />

              <Topic
                label="- Abatimentos Fiscais"
                onClick={() => {
                  setDetail({
                    procedure: 'Cálculo de Abatimento Fiscal',
                    information: 'Abatimentos Fiscais',
                    component: '08_tax_deductions',
                  });
                }}
              />

              <Topic
                label="- Atualização Valor Líquido"
                onClick={() => {
                  setDetail({
                    procedure: 'Cálculo de Abatimento Fiscal',
                    information: 'Valor Líquido',
                    component: '08_net_discount',
                  });
                }}
              />

              <Topic
                label="- Observação"
                onClick={() => {
                  setDetail({
                    procedure: 'Cálculo de Abatimento Fiscal',
                    information: 'Observação',
                    component: '08_obs',
                  });
                }}
              />

              <Topic
                label="- Histórico"
                onClick={() => {
                  setDetail({
                    procedure: 'Cálculo de Abatimento Fiscal',
                    information: 'Histórico',
                    component: '08_records',
                  });
                }}
              />

              {handleFiles(
                'Cálculo de Abatimento Fiscal',
                'Anexos',
                '08_files',
              )}
            </div>
          </InfoContent>
        </MainContent>
      );
    }

    return null;
  }

  function handleProcedure9(): JSX.Element | null {
    if (
      state?.project_procedure &&
      state.project_procedure.sequence &&
      (state.project_procedure.sequence > 9 ||
        (state.project_procedure.sequence === 9 &&
          state.status === 'Cancelado'))
    ) {
      return (
        <MainContent>
          <TitleContent>
            <CompleteIcon />

            <TitleTextWrapper>
              <Title label="Aprovação Geral" />
            </TitleTextWrapper>
          </TitleContent>

          <InfoContent>
            <Gradient height="400px" />

            <div>
              <Topic
                label="- Validação"
                onClick={() => {
                  setDetail({
                    procedure: 'Aprovação Geral',
                    information: 'Validação',
                    component: '09_validation',
                  });
                }}
              />

              <Topic
                label="- Observação"
                onClick={() => {
                  setDetail({
                    procedure: 'Aprovação Geral',
                    information: 'Observação',
                    component: '09_obs',
                  });
                }}
              />

              <Topic
                label="- Histórico"
                onClick={() => {
                  setDetail({
                    procedure: 'Aprovação Geral',
                    information: 'Histórico',
                    component: '09_records',
                  });
                }}
              />

              <Topic
                label="- Histórico Validação Rejeição"
                onClick={() => {
                  setDetail({
                    procedure: 'Aprovação Geral',
                    information: 'Histórico Validação Rejeição',
                    component: '09_extra_val_records',
                  });
                }}
              />

              {handleFiles('Aprovação Geral', 'Anexos', '09_files')}
            </div>
          </InfoContent>
        </MainContent>
      );
    }

    return null;
  }

  function handleProcedure10(): JSX.Element | null {
    if (
      state?.project_procedure &&
      state.project_procedure.sequence &&
      state.project_procedure.sequence === 10
    ) {
      return (
        <MainContent>
          <TitleContent>
            <CompleteIcon />

            <TitleTextWrapper>
              <Title label="Concessão de Desconto" />
            </TitleTextWrapper>
          </TitleContent>

          <InfoContent>
            <Gradient height="400px" />

            <div>
              <Topic
                label="- Notas Fiscais"
                onClick={() => {
                  setDetail({
                    procedure: 'Concessão de Desconto',
                    information: 'Notas Fiscais',
                    component: '10_invoices',
                  });
                }}
              />

              <Topic
                label="- Observação"
                onClick={() => {
                  setDetail({
                    procedure: 'Concessão de Desconto',
                    information: 'Observação',
                    component: '10_obs',
                  });
                }}
              />

              <Topic
                label="- Histórico"
                onClick={() => {
                  setDetail({
                    procedure: 'Concessão de Desconto',
                    information: 'Histórico',
                    component: '10_records',
                  });
                }}
              />

              {handleFiles('Concessão de Desconto', 'Anexos', '10_files')}
            </div>
          </InfoContent>
        </MainContent>
      );
    }

    return null;
  }

  function handleCurrentProcedure(): JSX.Element | null {
    let title = '';

    if (
      state?.project_procedure &&
      state.project_procedure.sequence === 2 &&
      state.status === 'Pendente'
    ) {
      title = 'Aprovação Marketing';
    }

    if (
      state?.project_procedure &&
      state.project_procedure.sequence === 3 &&
      state.status === 'Pendente'
    ) {
      title = 'Aprovação Gestão Marketing';
    }

    if (
      state?.project_procedure &&
      state.project_procedure.sequence === 4 &&
      state.status === 'Pendente' &&
      state.sponsorship_art_approval
    ) {
      title = 'Solicitação de Arte';
    }

    if (
      state?.project_procedure &&
      state.project_procedure.sequence === 5 &&
      state.status === 'Pendente' &&
      state.sponsorship_art_approval
    ) {
      title = 'Aprovação de Arte';
    }

    if (
      state?.project_procedure &&
      state.project_procedure.sequence === 6 &&
      state.status === 'Pendente' &&
      (state.sponsorship_art_approval || state.sponsorship_completion_files)
    ) {
      title = 'Produção de Material';
    }

    if (
      state?.project_procedure &&
      state.project_procedure.sequence === 7 &&
      !state.spons_final_approval_dismissal &&
      state.status === 'Pendente'
    ) {
      title = 'Aprovação Final';
    }

    if (
      state?.project_procedure &&
      state.project_procedure.sequence === 8 &&
      state.status === 'Pendente'
    ) {
      title = 'Cálculo de Abatimento Fiscal';
    }

    if (
      state?.project_procedure &&
      state.project_procedure.sequence === 9 &&
      state.status === 'Pendente'
    ) {
      title = 'Aprovação Geral';
    }

    if (
      state?.project_procedure &&
      state.project_procedure.sequence === 109 &&
      state.status === 'Pendente'
    ) {
      title = 'Validação Rejeição Geral';
    }

    if (title !== '') {
      return (
        <TitleContent>
          <PendingIcon />

          <TitleTextWrapper>
            <Title label={title} highlighted />
          </TitleTextWrapper>
        </TitleContent>
      );
    }

    return null;
  }

  function handleDetailTitle(): JSX.Element | null {
    if (detail?.procedure || detail?.information) {
      return <DetailTitle />;
    }

    return null;
  }

  function handleDetailProcedure(): JSX.Element | null {
    if (detail?.procedure) {
      return <DetailProcedure label={detail.procedure} />;
    }

    return null;
  }

  function handleDetailInformation(): JSX.Element | null {
    if (detail?.information) {
      return <DetailInfo label={detail.information} />;
    }

    return null;
  }

  function handleProcedure1Detail(): JSX.Element | null {
    if (detail?.procedure === 'Solicitação') {
      return (
        <DetailContent>
          {handleDetailTitle()}

          {handleDetailProcedure()}

          {handleDetailInformation()}

          <DetailItemWrapper>{handleDetailComponent()}</DetailItemWrapper>
        </DetailContent>
      );
    }

    return null;
  }

  function handleProcedure2Detail(): JSX.Element | null {
    if (detail?.procedure === 'Aprovação Marketing') {
      return (
        <DetailContent>
          {handleDetailTitle()}

          {handleDetailProcedure()}

          {handleDetailInformation()}

          <DetailItemWrapper>{handleDetailComponent()}</DetailItemWrapper>
        </DetailContent>
      );
    }

    return null;
  }

  function handleProcedure3Detail(): JSX.Element | null {
    if (detail?.procedure === 'Aprovação Gestão Marketing') {
      return (
        <DetailContent>
          {handleDetailTitle()}

          {handleDetailProcedure()}

          {handleDetailInformation()}

          <DetailItemWrapper>{handleDetailComponent()}</DetailItemWrapper>
        </DetailContent>
      );
    }

    return null;
  }

  function handleProcedure4Detail(): JSX.Element | null {
    if (detail?.procedure === 'Solicitação de Arte') {
      return (
        <DetailContent>
          {handleDetailTitle()}

          {handleDetailProcedure()}

          {handleDetailInformation()}

          <DetailItemWrapper>{handleDetailComponent()}</DetailItemWrapper>
        </DetailContent>
      );
    }

    return null;
  }

  function handleProcedure5Detail(): JSX.Element | null {
    if (detail?.procedure === 'Aprovação de Arte') {
      return (
        <DetailContent>
          {handleDetailTitle()}

          {handleDetailProcedure()}

          {handleDetailInformation()}

          <DetailItemWrapper>{handleDetailComponent()}</DetailItemWrapper>
        </DetailContent>
      );
    }

    return null;
  }

  function handleProcedure6Detail(): JSX.Element | null {
    if (detail?.procedure === 'Produção de Material') {
      return (
        <DetailContent>
          {handleDetailTitle()}

          {handleDetailProcedure()}

          {handleDetailInformation()}

          <DetailItemWrapper>{handleDetailComponent()}</DetailItemWrapper>
        </DetailContent>
      );
    }

    return null;
  }

  function handleProcedure7Detail(): JSX.Element | null {
    if (detail?.procedure === 'Aprovação Final') {
      return (
        <DetailContent>
          {handleDetailTitle()}

          {handleDetailProcedure()}

          {handleDetailInformation()}

          <DetailItemWrapper>{handleDetailComponent()}</DetailItemWrapper>
        </DetailContent>
      );
    }

    return null;
  }

  function handleProcedure8Detail(): JSX.Element | null {
    if (detail?.procedure === 'Cálculo de Abatimento Fiscal') {
      return (
        <DetailContent>
          {handleDetailTitle()}

          {handleDetailProcedure()}

          {handleDetailInformation()}

          <DetailItemWrapper>{handleDetailComponent()}</DetailItemWrapper>
        </DetailContent>
      );
    }

    return null;
  }

  function handleProcedure9Detail(): JSX.Element | null {
    if (detail?.procedure === 'Aprovação Geral') {
      return (
        <DetailContent>
          {handleDetailTitle()}

          {handleDetailProcedure()}

          {handleDetailInformation()}

          <DetailItemWrapper>{handleDetailComponent()}</DetailItemWrapper>
        </DetailContent>
      );
    }

    return null;
  }

  function handleProcedure10Detail(): JSX.Element | null {
    if (detail?.procedure === 'Concessão de Desconto') {
      return (
        <DetailContent>
          {handleDetailTitle()}

          {handleDetailProcedure()}

          {handleDetailInformation()}

          <DetailItemWrapper>{handleDetailComponent()}</DetailItemWrapper>
        </DetailContent>
      );
    }

    return null;
  }

  function handleDetailComponent(): JSX.Element | null {
    if (detail?.component === '01_code') {
      return <Code code={state?.code ? state.code : ''} />;
    }

    if (detail?.component === '01_representative') {
      return (
        <Representative
          erp_rep_code={state?.erp_rep_code ? state.erp_rep_code : ''}
        />
      );
    }

    if (detail?.component === '01_client') {
      return (
        <Client
          rep_as_client={false}
          erp_client_code={state?.erp_client_code ? state.erp_client_code : ''}
          erp_rep_code=""
        />
      );
    }

    if (detail?.component === '01_item') {
      return (
        <Item
          code={state?.sponsorship.code ? state.sponsorship.code : ''}
          name={state?.sponsorship.name ? state.sponsorship.name : ''}
        />
      );
    }

    if (detail?.component === '01_rebate_campaign') {
      return <RebateCampaign rebate_campaign={state.rebate_campaign} />;
    }

    if (detail?.component === '01_client_contact') {
      return (
        <ClientContact
          client_contact_name={
            state?.client_contact_name ? state.client_contact_name : ''
          }
          client_contact_phone={
            state?.client_contact_phone ? state.client_contact_phone : ''
          }
          client_contact_email={
            state?.client_contact_email ? state.client_contact_email : ''
          }
        />
      );
    }

    if (detail?.component === '01_gross_discount') {
      return (
        <GrossDiscountPriceInitialStage
          gross_discount_price={
            state?.gross_discount_price ? state.gross_discount_price : 0
          }
          total_sponsorship_sheets_price={
            state?.total_sponsorship_sheets_price
              ? state.total_sponsorship_sheets_price
              : 0
          }
        />
      );
    }

    if (detail?.component === '01_net_discount') {
      return (
        <NetDiscountPrice
          net_discount_price={
            state !== undefined
              ? state.gross_discount_price -
                state.total_sponsorship_sheets_price
              : 0
          }
        />
      );
    }

    if (detail?.component === '01_sheets') {
      return <SponsorshipSheetsInitialStage project_id={state.id} />;
    }

    if (detail?.component === '01_representative_obs') {
      return <Observation note={state?.note ? state.note : ''} />;
    }

    if (detail?.component === '01_records') {
      return <Records project_id={state?.id} type="PATROCINIO" sequence={1} />;
    }

    if (detail?.component === '01_files') {
      return <Files project_id={state?.id} type="PATROCINIO" sequence={1} />;
    }

    if (detail?.component === '01_request_files') {
      return <RequestFiles project_id={state.id} />;
    }

    if (detail?.component === '02_validation') {
      return (
        <Validation type="PATROCINIO" sequence={2} project_id={state.id} />
      );
    }

    if (detail?.component === '02_obs') {
      return (
        <RecordNote type="PATROCINIO" sequence={2} project_id={state.id} />
      );
    }

    if (detail?.component === '02_records') {
      return <Records project_id={state?.id} type="PATROCINIO" sequence={2} />;
    }

    if (detail?.component === '02_files') {
      return <Files project_id={state?.id} type="PATROCINIO" sequence={2} />;
    }

    if (detail?.component === '03_validation') {
      return (
        <Validation type="PATROCINIO" sequence={3} project_id={state.id} />
      );
    }

    if (detail?.component === '03_obs') {
      return (
        <RecordNote type="PATROCINIO" sequence={3} project_id={state.id} />
      );
    }

    if (detail?.component === '03_records') {
      return <Records project_id={state?.id} type="PATROCINIO" sequence={3} />;
    }

    if (detail?.component === '03_files') {
      return <Files project_id={state?.id} type="PATROCINIO" sequence={3} />;
    }

    if (detail?.component === '04_approval_files') {
      return <ApprovalFiles project_id={state.id} />;
    }

    if (detail?.component === '04_obs') {
      return (
        <RecordNote type="PATROCINIO" sequence={4} project_id={state.id} />
      );
    }

    if (detail?.component === '04_records') {
      return <Records project_id={state?.id} type="PATROCINIO" sequence={4} />;
    }

    if (detail?.component === '04_files') {
      return <Files project_id={state?.id} type="PATROCINIO" sequence={4} />;
    }

    if (detail?.component === '05_validation') {
      return (
        <Validation type="PATROCINIO" sequence={5} project_id={state.id} />
      );
    }

    if (detail?.component === '05_obs') {
      return (
        <RecordNote type="PATROCINIO" sequence={5} project_id={state.id} />
      );
    }

    if (detail?.component === '05_records') {
      return <Records project_id={state?.id} type="PATROCINIO" sequence={5} />;
    }

    if (detail?.component === '05_files') {
      return <Files project_id={state?.id} type="PATROCINIO" sequence={5} />;
    }

    if (detail?.component === '06_completion_files') {
      return <CompletionFiles project_id={state.id} />;
    }

    if (detail?.component === '06_obs') {
      return (
        <RecordNote type="PATROCINIO" sequence={6} project_id={state.id} />
      );
    }

    if (detail?.component === '06_records') {
      return <Records project_id={state?.id} type="PATROCINIO" sequence={6} />;
    }

    if (detail?.component === '06_files') {
      return <Files project_id={state?.id} type="PATROCINIO" sequence={6} />;
    }

    if (detail?.component === '07_validation') {
      return (
        <Validation type="PATROCINIO" sequence={7} project_id={state.id} />
      );
    }

    if (detail?.component === '07_obs') {
      return (
        <RecordNote type="PATROCINIO" sequence={7} project_id={state.id} />
      );
    }

    if (detail?.component === '07_records') {
      return <Records project_id={state?.id} type="PATROCINIO" sequence={7} />;
    }

    if (detail?.component === '07_files') {
      return <Files project_id={state?.id} type="PATROCINIO" sequence={7} />;
    }

    if (detail?.component === '08_sheets') {
      return <SponsorshipSheets project_id={state.id} />;
    }

    if (detail?.component === '08_total_sponsorship_sheets_price') {
      return (
        <TotalSponsorshipSheetsPrice
          total_sponsorship_sheets_price={
            state?.total_sponsorship_sheets_price
              ? state.total_sponsorship_sheets_price
              : 0
          }
        />
      );
    }

    if (detail?.component === '08_gross_discount') {
      return (
        <GrossDiscountPrice
          gross_discount_price={
            state?.gross_discount_price ? state.gross_discount_price : 0
          }
        />
      );
    }

    if (detail?.component === '08_tax_deductions') {
      return <TaxDeductions project_id={state.id} />;
    }

    if (detail?.component === '08_net_discount') {
      return (
        <NetDiscountPrice
          net_discount_price={
            state?.net_discount_price ? state.net_discount_price : 0
          }
        />
      );
    }

    if (detail?.component === '08_obs') {
      return (
        <RecordNote type="PATROCINIO" sequence={8} project_id={state.id} />
      );
    }

    if (detail?.component === '08_records') {
      return <Records project_id={state?.id} type="PATROCINIO" sequence={8} />;
    }

    if (detail?.component === '08_files') {
      return <Files project_id={state?.id} type="PATROCINIO" sequence={8} />;
    }

    if (detail?.component === '09_validation') {
      return (
        <Validation type="PATROCINIO" sequence={9} project_id={state.id} />
      );
    }

    if (detail?.component === '09_obs') {
      return (
        <RecordNote type="PATROCINIO" sequence={9} project_id={state.id} />
      );
    }

    if (detail?.component === '09_records') {
      return <Records project_id={state?.id} type="PATROCINIO" sequence={9} />;
    }

    if (detail?.component === '09_extra_val_records') {
      return (
        <Records project_id={state?.id} type="PATROCINIO" sequence={109} />
      );
    }

    if (detail?.component === '09_files') {
      return <Files project_id={state?.id} type="PATROCINIO" sequence={9} />;
    }

    if (detail?.component === '10_invoices') {
      return <Invoices project_id={state.id} />;
    }

    if (detail?.component === '10_obs') {
      return (
        <RecordNote type="PATROCINIO" sequence={10} project_id={state.id} />
      );
    }

    if (detail?.component === '10_records') {
      return <Records project_id={state?.id} type="PATROCINIO" sequence={10} />;
    }

    if (detail?.component === '10_files') {
      return <Files project_id={state?.id} type="PATROCINIO" sequence={10} />;
    }

    return null;
  }

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/sponsorships"
        firstChild="Pagamentos"
        secondChild="Visualização Solicitação"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaMoneyBillWave}
        secondChildIcon={FaMoneyBillWave}
        mainColored
        firstChildColored
      />

      <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <TabList>
          <Tab>
            <TabTitle>Linha do Tempo</TabTitle>
          </Tab>
          <Tab>
            <TabTitle>Geral</TabTitle>
          </Tab>
        </TabList>

        <TabPanel>
          <ProcedureContent>
            {handleProcedure1()}
            {handleProcedure1Detail()}
          </ProcedureContent>

          <ProcedureContent>
            {handleProcedure2()}
            {handleProcedure2Detail()}
          </ProcedureContent>

          <ProcedureContent>
            {handleProcedure3()}
            {handleProcedure3Detail()}
          </ProcedureContent>

          <ProcedureContent>
            {handleProcedure4()}
            {handleProcedure4Detail()}
          </ProcedureContent>

          <ProcedureContent>
            {handleProcedure5()}
            {handleProcedure5Detail()}
          </ProcedureContent>

          <ProcedureContent>
            {handleProcedure6()}
            {handleProcedure6Detail()}
          </ProcedureContent>

          <ProcedureContent>
            {handleProcedure7()}
            {handleProcedure7Detail()}
          </ProcedureContent>

          <ProcedureContent>
            {handleProcedure8()}
            {handleProcedure8Detail()}
          </ProcedureContent>

          <ProcedureContent>
            {handleProcedure9()}
            {handleProcedure9Detail()}
          </ProcedureContent>

          <ProcedureContent>
            {handleProcedure10()}
            {handleProcedure10Detail()}
          </ProcedureContent>

          {handleCurrentProcedure()}
        </TabPanel>

        <TabPanel>
          <ProjectInfo project_id={state.id} all />
        </TabPanel>
      </Tabs>
      <ButtonContainer>
        <Link
          to={{
            pathname: '/projects/sponsorships',
          }}
        >
          <ExitButton type="button" />
        </Link>
      </ButtonContainer>
    </Container>
  );
};

export default View;
