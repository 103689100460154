import styled, { css } from 'styled-components';

interface IPreviewMessageProps {
  src: string;
}

export const Container = styled.ul`
  margin-top: 2rem;
`;

export const FileRow = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + li {
    margin-top: 1.5rem;
  }
`;

export const FileContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FilePreviewWrapper = styled.div`
  width: 8rem;
`;

export const FilePreviewImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const FilePreviewImage = styled.div<IPreviewMessageProps>`
  ${({ theme, src }) => css`
    width: 55px;
    height: 55px;
    border-radius: ${theme.radius.small};
    background-image: url(${src});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  `}
`;

export const FileInfoWrapper = styled.div`
  margin-right: 1rem;
`;

export const FileInfoName = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.default};
    color: ${theme.colors.darkGrey};
  `}
`;

export const FileInfoFileSize = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.tiny};
  `}
`;

export const FileActions = styled.div`
  display: flex;
  align-items: center;
`;

export const DeleteBtn = styled.button`
  ${({ theme }) => css`
    background-color: transparent;
    color: ${theme.colors.danger};
    margin-left: 1rem;
    border-radius: 50%;

    &:hover {
      transform: scale(1.2, 1.2);
    }
  `}
`;

export const UrlIcon = styled.div`
  ${({ theme }) => css`
    margin-right: 1rem;

    svg {
      color: ${theme.colors.urlIcon};
    }

    &:hover {
      transform: scale(1.2, 1.2);
    }
  `}
`;

export const SuccessIcon = styled.div`
  ${({ theme }) => css`
    svg {
      color: ${theme.colors.successUploadIcon};
    }
  `}
`;

export const ErrorIcon = styled.div`
  ${({ theme }) => css`
    svg {
      color: ${theme.colors.errorUploadIcon};
    }
  `}
`;
