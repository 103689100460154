import React from 'react';
import { FaRegFilePdf } from 'react-icons/fa';

import { Container } from './styles';

interface IPdfProps {
  iconSize?: number;
}

const Pdf: React.FC<IPdfProps> = ({ iconSize }) => {
  return (
    <Container>
      <FaRegFilePdf size={iconSize !== undefined ? iconSize : 60} />
    </Container>
  );
};

export default Pdf;
