import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

interface IOptionProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  isActive: boolean;
  marginTop?: string;
  marginLeft?: string;
}

const Option: React.FC<IOptionProps> = ({
  label,
  isActive,
  marginTop,
  marginLeft,
  ...rest
}) => {
  return (
    <Container
      isActive={isActive}
      marginTop={marginTop}
      marginLeft={marginLeft}
      {...rest}
    >
      {label}
    </Container>
  );
};

export default Option;
