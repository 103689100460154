import styled, { css } from 'styled-components';

export const Container = styled.div`
  max-width: 680px;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background: ${theme.colors.backgroundContainer};
    padding: 2rem 2rem 3rem 2rem;
  `}
`;
