import React, { useState, useRef } from 'react';

import { AnimatePresence } from 'framer-motion';
import { FaUserCircle, FaAngleDown, FaSignOutAlt } from 'react-icons/fa';

import {
  Container,
  AnimatedDropdown,
  NavButton,
  UserAccount,
  UserAccountName,
} from './styles';
import { DROP_DOWN_ANIMATION } from './animations';

import { useAuth } from '~/hooks/auth';

const AccountDropdown: React.FC = () => {
  const { user, signOut } = useAuth();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  function handleDropdown(): void {
    if (isDropdownVisible) {
      setIsDropdownVisible(false);
    } else {
      setIsDropdownVisible(true);
    }
  }

  const handleSignOut = (): void => {
    signOut();
  };

  return (
    <Container onClick={handleDropdown}>
      <UserAccount>
        <FaUserCircle color="#797979" size={26} />
        <UserAccountName>{user.name}</UserAccountName>
        <FaAngleDown color="#797979" size={14} />
      </UserAccount>

      <AnimatePresence>
        {isDropdownVisible && (
          <AnimatedDropdown
            variants={DROP_DOWN_ANIMATION}
            initial="hidden"
            animate="visible"
            exit="hidden"
            ref={dropdownRef}
          >
            <ul>
              <NavButton onClick={handleSignOut}>
                <FaSignOutAlt />
                Sair da Conta
              </NavButton>
            </ul>
          </AnimatedDropdown>
        )}
      </AnimatePresence>
    </Container>
  );
};

export default AccountDropdown;
