import React, { ButtonHTMLAttributes } from 'react';

import { FaPlus } from 'react-icons/fa';

import { Container } from './styles';

export type IAddRowProps = ButtonHTMLAttributes<HTMLButtonElement>;

const AddRow: React.FC<IAddRowProps> = ({ ...rest }) => {
  return (
    <Container {...rest}>
      <FaPlus size={16} />
    </Container>
  );
};

export default AddRow;
