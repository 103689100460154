import React, { InputHTMLAttributes, useState, useCallback } from 'react';

import ErrorTooltip from '~/components/Tooltips/Error';

import { Label, CompLabel, Container, Text } from './styles';

interface ITextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  labelFor?: string;
  labelText: string;
  compLabelText?: string;
  customHeight?: string;
  error?: string;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, ITextAreaProps>(
  (
    {
      name,
      labelText,
      compLabelText,
      labelFor,
      customHeight,
      error,
      ...rest
    }: ITextAreaProps,
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleInputFocus = useCallback(() => {
      setIsFocused(!isFocused);
    }, [isFocused]);

    const handleInputBlur = useCallback(() => {
      setIsFocused(false);
    }, []);

    function handleLabel(): React.ReactNode {
      if (compLabelText) {
        if (compLabelText !== '') {
          return (
            <Label isFocused={isFocused}>
              {labelText}
              <CompLabel isFocused={isFocused}>
                {` (${compLabelText})`}
              </CompLabel>
            </Label>
          );
        }
      }

      return <Label isFocused={isFocused}>{labelText}</Label>;
    }

    return (
      <>
        {handleLabel()}

        <Container id={labelFor} isFocused={isFocused} isErrored={!!error}>
          <Text
            name={name}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            cols={5}
            rows={5}
            ref={ref}
            customHeight={customHeight}
            {...rest}
          />

          {error && <ErrorTooltip title={error} />}
        </Container>
      </>
    );
  },
);

export default TextArea;
