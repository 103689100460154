import styled, { css } from 'styled-components';

export const Container = styled.section`
  ${({ theme }) => css`
    width: 100%;
    background: ${theme.colors.background};
    border-radius: ${theme.radius.default};
    box-shadow: ${theme.shadows.flat};
    padding: 2.4rem;
    margin: 2.4rem 0;
  `}
`;

export const Content = styled.div`
  max-width: 680px;
  display: flex;
  justify-content: flex-start;
`;

export const Wrapper = styled.div`
  margin-bottom: 2rem;

  @media (max-width: 1010px) {
    width: 100% !important;
    margin: 0 0 2rem 0;
  }
`;

export const Label = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.default};
    color: ${theme.colors.labelInput};
  `}
`;

export const BorderContent = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 680px;
    border-radius: ${theme.radius.small};
    padding: 10px;
    margin-bottom: 1.8rem;
    border: 1px solid #e4e4e4;
  `}
`;

export const FlexWrapper = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 1010px) {
    flex-direction: column;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20;
`;
