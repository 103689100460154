import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBell, FaArrowRight } from 'react-icons/fa';

import { useAuth } from '~/hooks/auth';

import api from '~/services/api';

import {
  Container,
  TaskButton,
  TaskButtonRows,
  TaskButtonTitle,
  TaskButtonRow,
  TaskButtonInfo,
} from './styles';

interface IProject {
  type: string;
  code?: string;
  display?: {
    name?: string;
  };
  material?: {
    name?: string;
  };
  sponsorship?: {
    name?: string;
  };
  adversity?: {
    name?: string;
  };
  folder?: {
    name?: string;
  };
  sample?: {
    name?: string;
  };
  event?: {
    name?: string;
  };
  local_client: {
    company_name: string;
  };
  project_procedure: {
    sequence: number;
    description: string;
    extra_validation: boolean;
  };
}

const PendingProjects: React.FC = () => {
  const { user } = useAuth();
  const [projects, setProjects] = useState<IProject[]>([]);

  useEffect(() => {
    api.get(`/projects/usertypependingprojects`).then(response => {
      setProjects(response.data);
    });
  }, [user]);

  function getItem(projectReceived: IProject): string | undefined {
    if (projectReceived.type === 'DISPLAY') {
      return projectReceived.display?.name;
    }
    if (projectReceived.type === 'MATERIAL') {
      return projectReceived.material?.name;
    }
    if (projectReceived.type === 'PATROCINIO') {
      return projectReceived.sponsorship?.name;
    }
    if (projectReceived.type === 'ADVERSIDADE') {
      return projectReceived.adversity?.name;
    }
    if (projectReceived.type === 'PASTA') {
      return projectReceived.folder?.name;
    }
    if (projectReceived.type === 'AMOSTRA') {
      return projectReceived.sample?.name;
    }
    if (projectReceived.type === 'EVENTO') {
      return projectReceived.event?.name;
    }

    return undefined;
  }

  function getPath(recProject: IProject): string {
    let sec_path = '';

    if (recProject.type === 'DISPLAY') sec_path = 'displays';
    if (recProject.type === 'MATERIAL') sec_path = 'materials';
    if (recProject.type === 'PATROCINIO') sec_path = 'sponsorships';
    if (recProject.type === 'ADVERSIDADE') sec_path = 'adversities';
    if (recProject.type === 'PASTA') sec_path = 'folders';
    if (recProject.type === 'AMOSTRA') sec_path = 'samples';
    if (recProject.type === 'EVENTO') sec_path = 'events';

    if (recProject.project_procedure.sequence && sec_path !== '') {
      return (
        `/projects/${sec_path}/procedure` +
        `${recProject.project_procedure.sequence}`
      );
    }

    return '/dashboard';
  }

  function getPendingProjects(): React.ReactNode {
    return projects?.map(project => (
      <Link
        to={{
          pathname: getPath(project),
          state: project,
        }}
      >
        <TaskButton isExtraVal={project.project_procedure.extra_validation}>
          <FaBell size={32} />
          <div>
            <TaskButtonTitle>
              Aviso de {project.project_procedure.description}
            </TaskButtonTitle>

            <TaskButtonRows>
              <TaskButtonRow>
                <TaskButtonInfo
                  isExtraVal={project.project_procedure.extra_validation}
                >
                  Tipo: {project.type}
                </TaskButtonInfo>
              </TaskButtonRow>

              <TaskButtonRow>
                <TaskButtonInfo
                  isExtraVal={project.project_procedure.extra_validation}
                >
                  Código: {project.code}
                </TaskButtonInfo>
              </TaskButtonRow>

              <TaskButtonRow>
                <TaskButtonInfo
                  isExtraVal={project.project_procedure.extra_validation}
                >
                  Cliente: {project.local_client.company_name}
                </TaskButtonInfo>
              </TaskButtonRow>

              <TaskButtonRow>
                <TaskButtonInfo
                  isExtraVal={project.project_procedure.extra_validation}
                >
                  Item: {getItem(project)}
                </TaskButtonInfo>
              </TaskButtonRow>
            </TaskButtonRows>
          </div>
          <FaArrowRight size={20} />
        </TaskButton>
      </Link>
    ));
  }

  return <Container>{getPendingProjects()}</Container>;
};

export default PendingProjects;
