import React, { useState, useEffect, useCallback } from 'react';
import { FaProjectDiagram, FaFolder } from 'react-icons/fa';
import { PropagateLoader } from 'react-spinners';
import { Link, useLocation } from 'react-router-dom';

import BreadCrumb from '~/components/BreadCrumb';
import ConfirmDeleteDialog from '~/components/Dialogs/ConfirmDelete';
import DefaultInput from '~/components/Inputs/Default';
import DefaultTooltip from '~/components/Tooltips/Default';
import DeleteButton from '~/components/Buttons/Delete';
import EditButton from '~/components/Buttons/Edit';
import NewAltButton from '~/components/Buttons/NewAlt';
import NewButton from '~/components/Buttons/New';
import OptionButton from '~/components/Buttons/Option';
import Pagination from '~/components/Pagination';
import ReportButton from '~/components/Buttons/Report';
import ReportDialog from '~/components/Dialogs/Report';
import ViewButton from '~/components/Buttons/View';

import { getFormattedCnpj } from '~/utils';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  ContentSplit,
  FilterContent,
  FilterOptionsContent,
  ReportContent,
  Table,
  Thead,
  Tbody,
  Th,
  TbodyTr,
  Td,
  LinkView,
  ButtonsContent,
} from './styles';

interface IFolderProject {
  id: string;
  code: string;
  status: string;
  batch: string;
  folder: {
    name: string;
  };
  local_client: {
    company_name: string;
    document: string;
    city: string;
    state: string;
  };
  project_procedure: {
    sequence: number;
    user_type: string;
  };
  created_at: string;
}

const AMOUNT_PER_PAGE = 10;

const List: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<{ user_type_pending: boolean } | undefined>();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState('');
  const [option, setOption] = useState('all');
  const [lastRefresh, setLastRefresh] = useState(new Date());
  const [folderProjects, setFolderProjects] = useState<IFolderProject[]>();
  const [folderProjectsIds, setFolderProjectsIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isOpenReport, setIsOpenReport] = useState(false);
  const [projectIdDelete, setProjectIdDelete] = useState('');
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isNewReqEnabled, setIsNewReqEnabled] = useState(false);
  const [titleDelete, setTitleDelete] = useState('');
  const [subTitleDelete, setSubTitleDelete] = useState('');

  useEffect(() => {
    const ids: string[] = [];
    if (folderProjects) {
      folderProjects.map(folderProject => ids.push(folderProject.id));

      setFolderProjectsIds(ids);
    }
  }, [folderProjects]);

  useEffect(() => {
    setPage(0);
  }, [state]);

  useEffect(() => {
    api.get('/generalsettings').then(response => {
      if (response.data) {
        setIsNewReqEnabled(response.data.new_folder_req_enabled);
      }
    });
  }, []);

  useEffect(() => {
    const loadFolderProjects = async () => {
      await api
        .get(
          `/projects?type=PASTA` +
            `&page=${page}` +
            `&filter=${filter}` +
            `&amount=${AMOUNT_PER_PAGE}` +
            `&user_type=${user.user_type.type}` +
            `&erp_rep_code=${
              user.user_type.type === 'REP' ? user.erp_rep_code : ''
            }` +
            `&option=${option}` +
            `${
              state !== undefined && state.user_type_pending
                ? '&search_type=pending_by_user_type'
                : ''
            }`,
        )
        .then(response => {
          setFolderProjects(response.data[0]);
          setCount(response.data[1]);
          setLoading(false);
        });
    };

    loadFolderProjects();
  }, [page, filter, user, option, state, lastRefresh]);

  function getBatch(folderProjectReceived: IFolderProject): string {
    if (folderProjectReceived.batch) {
      return folderProjectReceived.batch;
    }

    return 'Sem lote';
  }

  function handleEditDisabled(folderProjectReceived: IFolderProject): boolean {
    if (folderProjectReceived.status.toUpperCase() === 'CANCELADO') {
      return true;
    }
    if (
      user.user_type.type === folderProjectReceived.project_procedure.user_type
    ) {
      return false;
    }
    if (user.user_type.type === 'ADM') {
      return false;
    }
    if (user.user_type.type === 'MK1' || user.user_type.type === 'MK2') {
      return false;
    }

    return true;
  }

  function getEditData(folderProjectReceived: IFolderProject): any {
    const editDataObject: any = folderProjectReceived;
    editDataObject.origin = 'project';

    return editDataObject;
  }

  function handleEditLink(
    folderProjectReceived: IFolderProject,
  ): React.ReactNode | null {
    if (handleEditDisabled(folderProjectReceived)) {
      return null;
    }

    return (
      <Link
        to={{
          pathname: `/projects/folders/procedure${folderProjectReceived.project_procedure.sequence}`,
          state: getEditData(folderProjectReceived),
        }}
      >
        <EditButton />
      </Link>
    );
  }

  function handleViewLink(
    folderProjectReceived: IFolderProject,
  ): React.ReactNode {
    return (
      <LinkView
        marginLeft={handleEditDisabled(folderProjectReceived)}
        to={{
          pathname: `/projects/folders/view`,
          state: folderProjectReceived,
        }}
      >
        <ViewButton />
      </LinkView>
    );
  }

  function handleDeleteButton(
    folderProjectReceived: IFolderProject,
  ): React.ReactNode | null {
    if (
      folderProjectReceived.project_procedure.sequence === 2 &&
      folderProjectReceived.status === 'Pendente' &&
      (user.user_type.type === 'REP' || user.user_type.type === 'ADM')
    ) {
      return (
        <DeleteButton
          style={{ marginLeft: '0.5rem' }}
          onClick={() => {
            setProjectIdDelete(folderProjectReceived.id);
            setIsOpenDelete(true);
            setTitleDelete(
              `Deseja realmente excluir a solicitação ${folderProjectReceived.code} ` +
                `do cliente ${folderProjectReceived.local_client.company_name}, ` +
                `aberta no dia ${new Date(
                  folderProjectReceived.created_at,
                ).toLocaleDateString()}?`,
            );
            setSubTitleDelete('Esta operação não poderá ser desfeita!');
          }}
        />
      );
    }

    return null;
  }

  const onDelete = useCallback(
    async (id: string) => {
      try {
        await api.delete(`/projects/${id}`).then(() => {
          setPage(0);
          setLastRefresh(new Date());

          addToast({
            type: 'success',
            title: 'Solicitação excluída!',
            description: 'Solicitação excluída com sucesso.',
          });
        });
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
      setProjectIdDelete('');
      setTitleDelete('');
      setSubTitleDelete('');
    },
    [addToast],
  );

  function handleTable(): React.ReactNode {
    if (folderProjects) {
      if (folderProjects.length > 0) {
        return (
          <Table>
            <Thead>
              <tr>
                <Th width="10%" textAlign="left">
                  Data Abertura
                </Th>
                <Th width="10%" textAlign="left">
                  Código
                </Th>
                <Th width="20%" textAlign="left">
                  Cliente
                </Th>
                <Th width="10%" textAlign="left">
                  Cidade
                </Th>
                <Th width="5%" textAlign="left">
                  UF
                </Th>
                <Th width="15%" textAlign="left">
                  Item
                </Th>
                <Th width="10%" textAlign="left">
                  Lote
                </Th>
                <Th width="10%" textAlign="left">
                  Situação
                </Th>
                <Th width="10%" textAlign="center" />
              </tr>
            </Thead>
            <Tbody>
              {loading && (
                <tr>
                  <Td colSpan={4} padding="1rem" textAlign="center">
                    <PropagateLoader color="#276c8d" />
                  </Td>
                </tr>
              )}
              {folderProjects?.map(folderProject => (
                <TbodyTr key={folderProject.id}>
                  <Td width="10%" textAlign="left" heading="DATA ABERTURA">
                    {new Date(folderProject.created_at).toLocaleDateString()}
                  </Td>
                  <Td width="10%" textAlign="left" heading="CÓDIGO">
                    {folderProject.code}
                  </Td>
                  <Td width="20%" textAlign="left" heading="CLIENTE">
                    {`${
                      folderProject.local_client.company_name
                    } (${getFormattedCnpj(
                      folderProject.local_client.document,
                    )})`}
                  </Td>
                  <Td width="10%" textAlign="left" heading="CIDADE">
                    {folderProject.local_client.city}
                  </Td>
                  <Td width="5%" textAlign="left" heading="UF">
                    {folderProject.local_client.state}
                  </Td>
                  <Td width="15%" textAlign="left" heading="ITEM">
                    {folderProject.folder.name}
                  </Td>
                  <Td width="10%" textAlign="left" heading="LOTE">
                    {getBatch(folderProject)}
                  </Td>
                  <Td width="10%" textAlign="left" heading="SITUAÇÃO">
                    {folderProject.status}
                  </Td>
                  <Td
                    width="10%"
                    textAlign="center"
                    display="flex"
                    heading={' '}
                  >
                    {handleEditLink(folderProject)}

                    {handleViewLink(folderProject)}

                    {handleDeleteButton(folderProject)}
                  </Td>
                </TbodyTr>
              ))}
            </Tbody>
          </Table>
        );
      }
    }

    return (
      <Table>
        <Thead>
          <tr>
            <Th width="100%" textAlign="center" />
          </tr>
        </Thead>
        <Tbody>
          <TbodyTr>
            <Td width="100%" textAlign="center">
              Nenhuma informação disponível
            </Td>
          </TbodyTr>
        </Tbody>
      </Table>
    );
  }

  function getNewLinkPath(): any {
    if (user.user_type.type === 'REP') {
      return {
        pathname: `/projects/folders/procedure1/client`,
        state: {
          code: user.erp_rep_code,
        },
      };
    }

    return '/projects/folders/procedure1/representative';
  }

  function handleNewRequest(): React.ReactNode | null {
    if (
      user.user_type.type === 'ADM' ||
      user.user_type.type === 'MK1' ||
      user.user_type.type === 'MK2' ||
      user.user_type.type === 'MAX' ||
      user.user_type.type === 'REP'
    ) {
      if (isNewReqEnabled) {
        return (
          <Link to={getNewLinkPath()}>
            <NewButton />
          </Link>
        );
      }

      return (
        <DefaultTooltip title="Função indisponível">
          <NewButton disabled />
        </DefaultTooltip>
      );
    }

    return null;
  }

  function handleNewBatches(): React.ReactNode | null {
    if (
      user.user_type.type === 'ADM' ||
      user.user_type.type === 'MK1' ||
      user.user_type.type === 'MK2' ||
      user.user_type.type === 'MAX'
    ) {
      if (isNewReqEnabled) {
        return (
          <div style={{ marginLeft: '1rem' }}>
            <Link to="/projects/folders/folderbatches">
              <NewAltButton label="Novos Lotes" />
            </Link>
          </div>
        );
      }

      return (
        <DefaultTooltip title="Função indisponível">
          <NewAltButton
            label="Novos Lotes"
            disabled
            style={{ marginLeft: '1rem' }}
          />
        </DefaultTooltip>
      );
    }

    return null;
  }

  return (
    <Container style={{ minHeight: 600 }}>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChild="Pastas"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaFolder}
        mainColored
      />

      <FilterContent>
        <DefaultInput
          name="search"
          labelText="Pesquisar"
          type="text"
          onChange={event => {
            setFilter(event.target.value);
            setPage(0);
          }}
        />
      </FilterContent>

      <ContentSplit>
        <FilterOptionsContent>
          <OptionButton
            label="Todos"
            isActive={option === 'all'}
            marginTop="2.5rem"
            onClick={() => {
              setOption('all');
              setPage(0);
            }}
          />

          <OptionButton
            label="Lotes"
            isActive={option === 'batches'}
            marginTop="2.5rem"
            marginLeft="1rem"
            onClick={() => {
              setOption('batches');
              setPage(0);
            }}
          />

          <OptionButton
            label="Sem Lote"
            isActive={option === 'no_batch'}
            marginTop="2.5rem"
            marginLeft="1rem"
            onClick={() => {
              setOption('no_batch');
              setPage(0);
            }}
          />
        </FilterOptionsContent>

        <ReportContent>
          <ReportButton onClick={() => setIsOpenReport(true)} />
        </ReportContent>
      </ContentSplit>

      {handleTable()}

      <ButtonsContent>
        <Pagination
          page={page}
          count={count}
          amountPerPage={AMOUNT_PER_PAGE}
          setPage={setPage}
        />

        {handleNewRequest()}

        {handleNewBatches()}
      </ButtonsContent>

      <ReportDialog
        isOpen={isOpenReport}
        setIsOpen={setIsOpenReport}
        projectType="PASTA"
        ids={folderProjectsIds}
      />

      <ConfirmDeleteDialog
        isOpen={isOpenDelete}
        title={titleDelete}
        subTitle={subTitleDelete}
        setIsOpen={setIsOpenDelete}
        onClose={() => {
          onDelete(projectIdDelete);
        }}
      />
    </Container>
  );
};

export default List;
