import React from 'react';
import { useLocation } from 'react-router-dom';

import DefaultLayout from '../../_layouts/default';

import List from './List';
import View from './View';
import Procedure1Representative from './Procedures/Procedure1/ChooseRepresentative';
import Procedure1Client from './Procedures/Procedure1/ChooseClient';
import Procedure1New from './Procedures/Procedure1/New';
import Procedure2 from './Procedures/Procedure2';
import Procedure3 from './Procedures/Procedure3';
import Procedure4 from './Procedures/Procedure4';
import Procedure5 from './Procedures/Procedure5';
import Procedure6 from './Procedures/Procedure6';
import Procedure7 from './Procedures/Procedure7';
import Procedure8 from './Procedures/Procedure8';

const Displays: React.FC = () => {
  const { pathname } = useLocation();

  function getComponent(): React.ReactNode {
    if (pathname === '/projects/displays') {
      return <List />;
    }
    if (pathname === '/projects/displays/view') {
      return <View />;
    }
    if (pathname === '/projects/displays/procedure1/representative') {
      return <Procedure1Representative />;
    }
    if (pathname === '/projects/displays/procedure1/client') {
      return <Procedure1Client />;
    }
    if (pathname === '/projects/displays/procedure1/new') {
      return <Procedure1New />;
    }
    if (pathname === '/projects/displays/procedure2') {
      return <Procedure2 />;
    }
    if (pathname === '/projects/displays/procedure3') {
      return <Procedure3 />;
    }
    if (pathname === '/projects/displays/procedure4') {
      return <Procedure4 />;
    }
    if (pathname === '/projects/displays/procedure5') {
      return <Procedure5 />;
    }
    if (pathname === '/projects/displays/procedure6') {
      return <Procedure6 />;
    }
    if (pathname === '/projects/displays/procedure7') {
      return <Procedure7 />;
    }
    if (pathname === '/projects/displays/procedure8') {
      return <Procedure8 />;
    }

    return <List />;
  }

  return <DefaultLayout>{getComponent()}</DefaultLayout>;
};

export default Displays;
