import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

export interface ICloseProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  marginLeft?: string;
}

const Close: React.FC<ICloseProps> = ({ label, ...rest }) => {
  return (
    <Container {...rest}>{label !== undefined ? label : 'Fechar'}</Container>
  );
};

export default Close;
