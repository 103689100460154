import React, { useState, useEffect } from 'react';
import {
  Route as ReactDOMRoute,
  Redirect,
  RouteProps,
  useHistory,
} from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

interface IRouteProps extends RouteProps {
  path: string;
  isPrivate?: boolean;
  component?: React.ComponentType;
  search?: string;
}

interface ITokenPayload {
  exp: number;
}

interface IProject {
  type: string;
  project_procedure: {
    sequence: number;
  };
}

interface INewProjectData {
  type: string;
  erp_client_code?: string;
  erp_client_name?: string;
  erp_client_company_name?: string;
  erp_client_document?: string;
  erp_client_address?: string;
  erp_client_city?: string;
  erp_client_state?: string;
  erp_rep_code: string;
  rep_as_client: boolean;
}

const Route: React.FC<IRouteProps> = ({
  path,
  isPrivate = false,
  component: Component,
  search,
  ...rest
}) => {
  const { user, signOut } = useAuth();
  const { addToast } = useToast();
  const [project, setProject] = useState<IProject>();
  const [newProjectData, setNewProjectData] = useState<INewProjectData>();
  const history = useHistory();
  let isUserLoggedIn = false;
  let isPasswordRequest = false;
  let isPasswordReset = false;
  let isExternalAccess = false;
  let isNewProject = false;
  let passwordResetToken = '';
  let projectId = '';

  function checkIsValidUser(): boolean {
    if (path.indexOf('/users') !== -1) {
      if (user.user_type.type !== 'ADM') {
        return false;
      }
    }

    if (path.indexOf('/items') !== -1) {
      if (
        user.user_type.type !== 'ADM' &&
        user.user_type.type !== 'MK1' &&
        user.user_type.type !== 'MK2' &&
        user.user_type.type !== 'IMK'
      ) {
        return false;
      }
    }

    if (path.indexOf('/configurations') !== -1) {
      if (
        user.user_type.type !== 'ADM' &&
        user.user_type.type !== 'MK1' &&
        user.user_type.type !== 'MK2' &&
        user.user_type.type !== 'MAX' &&
        user.user_type.type !== 'IMK'
      ) {
        return false;
      }
    }

    if (
      path.indexOf('/management/localclients') !== -1 &&
      user.user_type.type !== 'ADM' &&
      !user.edit_local_client_perm
    ) {
      return false;
    }

    if (path.indexOf('/management') !== -1) {
      if (user.user_type.type !== 'ADM' && !user.admin_functions) {
        return false;
      }
    }

    return true;
  }

  function checkComponent(): React.ReactNode {
    if (Component) {
      return <Component />;
    }

    return (
      <Redirect
        to={{
          pathname: '/dashboard',
        }}
      />
    );
  }

  function checkIsPasswordRequest(): boolean {
    if (path.indexOf('/resetpassword/request') !== -1) {
      return true;
    }

    return false;
  }

  function checkIsPasswordReset(): boolean {
    if (path.indexOf('/passwordreset') !== -1) {
      return true;
    }

    return false;
  }

  function checkIsExternalAccess(): boolean {
    if (path.indexOf('/externalaccess') !== -1) {
      return true;
    }

    return false;
  }

  function checkIsNewProject(): boolean {
    if (path.indexOf('/newproject') !== -1) {
      return true;
    }

    return false;
  }

  function getPasswordResetInfo(): void {
    const splitPath = path.split('passwordreset', 2);

    if (splitPath) {
      if (splitPath.length > 1) {
        const splitRoute = splitPath[1].split('/', 2);
        if (splitRoute) {
          if (splitRoute.length > 1) {
            passwordResetToken = splitRoute[1];
          }
        }
      }
    }
  }

  function getExternalInfo(): void {
    const splitPath = path.split('externalaccess', 2);

    if (splitPath) {
      if (splitPath.length > 1) {
        const splitRoute = splitPath[1].split('/', 3);
        if (splitRoute) {
          if (splitRoute.length > 2) {
            projectId = splitRoute[2];
          }
        }
      }
    }
  }

  function getProject(): void {
    api.get(`/projects/id?id=${projectId}`).then(response => {
      setProject(response.data);
    });
  }

  useEffect(() => {
    let externalPath = '';

    if (project) {
      if (project.type === 'DISPLAY') {
        externalPath = `/projects/displays/procedure${project.project_procedure.sequence}`;
      } else if (project.type === 'MATERIAL') {
        externalPath = `/projects/materials/procedure${project.project_procedure.sequence}`;
      } else if (project.type === 'PATROCINIO') {
        externalPath = `/projects/sponsorships/procedure${project.project_procedure.sequence}`;
      } else if (project.type === 'ADVERSIDADE') {
        externalPath = `/projects/adversities/procedure${project.project_procedure.sequence}`;
      } else if (project.type === 'PASTA') {
        externalPath = `/projects/folders/procedure${project.project_procedure.sequence}`;
      } else if (project.type === 'AMOSTRA') {
        externalPath = `/projects/samples/procedure${project.project_procedure.sequence}`;
      } else if (project.type === 'EVENTO') {
        externalPath = `/projects/events/procedure${project.project_procedure.sequence}`;
      }

      history.push({
        pathname: externalPath,
        state: project,
      });
    }
  }, [project, history]);

  function getNewProjectInfo(): {
    project_type: string;
    erp_client_code: string;
  } {
    if (search) {
      const params = new URLSearchParams(search);
      const project_type = params.get('type');
      const erp_client_code = params.get('client');

      if (project_type && erp_client_code) {
        if (
          project_type === 'DISPLAY' ||
          project_type === 'MATERIAL' ||
          project_type === 'PATROCINIO' ||
          project_type === 'ADVERSIDADE' ||
          project_type === 'PASTA' ||
          project_type === 'AMOSTRA' ||
          project_type === 'EVENTO'
        ) {
          return { project_type, erp_client_code };
        }
      }
    }

    return { project_type: '', erp_client_code: '' };
  }

  function getNewProjectData(
    project_type: string,
    erp_client_code: string,
  ): void {
    api.get(`/clients/code?code=${erp_client_code}`).then(response => {
      setNewProjectData({
        type: project_type,
        erp_client_code: response.data.code,
        erp_client_name: response.data.name,
        erp_client_company_name: response.data.company_name,
        erp_client_document: response.data.document,
        erp_client_address: response.data.address,
        erp_client_city: response.data.city,
        erp_client_state: response.data.state,
        erp_rep_code: response.data.rep_code,
        rep_as_client: false,
      });
    });
  }

  useEffect(() => {
    let newProjectPath = '';

    if (newProjectData && user) {
      if (!newProjectData.erp_client_code) {
        addToast({
          type: 'error',
          title: 'Operação inválida!',
          description: 'Cliente não localizado',
        });

        history.push('/');
      } else if (
        user.user_type.type === 'REP' &&
        user.erp_rep_code !== newProjectData.erp_rep_code
      ) {
        addToast({
          type: 'error',
          title: 'Operação inválida!',
          description: 'Usuário sem permissão',
        });

        history.push('/');
      } else {
        if (newProjectData.type === 'DISPLAY') {
          newProjectPath = '/projects/displays/procedure1/new';
        } else if (newProjectData.type === 'MATERIAL') {
          newProjectPath = '/projects/materials/procedure1/new';
        } else if (newProjectData.type === 'PATROCINIO') {
          newProjectPath = '/projects/sponsorships/procedure1/new';
        } else if (newProjectData.type === 'ADVERSIDADE') {
          newProjectPath = '/projects/adversities/procedure1/new';
        } else if (newProjectData.type === 'PASTA') {
          newProjectPath = '/projects/folders/procedure1/new';
        } else if (newProjectData.type === 'AMOSTRA') {
          newProjectPath = '/projects/samples/procedure1/new';
        } else if (newProjectData.type === 'EVENTO') {
          newProjectPath = '/projects/events/procedure1/new';
        }

        history.push({
          pathname: newProjectPath,
          state: newProjectData,
        });
      }
    }
  }, [newProjectData, user, addToast, history]);

  function validateUser(): boolean {
    if (user) {
      const token = localStorage.getItem('@GuararapesPDV:token');
      if (token) {
        const decoded = jwt_decode(token);
        const dateNow = new Date();

        const { exp } = decoded as ITokenPayload;

        if (exp * 1000 < dateNow.getTime()) {
          signOut();
        } else {
          return true;
        }

        return false;
      }
    }

    return false;
  }

  return (
    <ReactDOMRoute
      render={({ location }) => {
        isUserLoggedIn = validateUser();
        isPasswordRequest = checkIsPasswordRequest();
        isPasswordReset = checkIsPasswordReset();
        isExternalAccess = checkIsExternalAccess();
        isNewProject = checkIsNewProject();

        if (isPasswordRequest) {
          return checkComponent();
        }
        if (isPasswordReset) {
          getPasswordResetInfo();
          return (
            <Redirect
              to={{
                pathname: '/resetpassword/reset',
                state: { token: passwordResetToken },
              }}
            />
          );
        }
        if (isExternalAccess) {
          getExternalInfo();
          if (projectId !== '') {
            if (isUserLoggedIn) {
              getProject();
            } else {
              return (
                <Redirect
                  to={{
                    pathname: '/externalsignin',
                    state: { type: 'projectprocedure', project_id: projectId },
                  }}
                />
              );
            }
          } else if (isUserLoggedIn) {
            return (
              <Redirect
                to={{
                  pathname: '/dashboard',
                  state: { from: location },
                }}
              />
            );
          } else {
            return (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: location },
                }}
              />
            );
          }
        } else if (isNewProject) {
          const { project_type, erp_client_code } = getNewProjectInfo();

          if (isUserLoggedIn && project_type !== '' && erp_client_code !== '') {
            getNewProjectData(project_type, erp_client_code);
          } else if (
            !isUserLoggedIn &&
            project_type !== '' &&
            erp_client_code !== ''
          ) {
            return (
              <Redirect
                to={{
                  pathname: '/externalsignin',
                  state: {
                    type: 'newproject',
                    project_type,
                    erp_client_code,
                  },
                }}
              />
            );
          } else if (
            isUserLoggedIn &&
            project_type === '' &&
            erp_client_code === ''
          ) {
            addToast({
              type: 'error',
              title: 'Operação inválida!',
              description: 'Dados inválidos para abertura de solicitação',
            });

            return (
              <Redirect
                to={{
                  pathname: '/dashboard',
                  state: { from: location },
                }}
              />
            );
          } else {
            return (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: location },
                }}
              />
            );
          }
        } else if (isUserLoggedIn) {
          if (checkIsValidUser()) {
            if (path === '/') {
              return (
                <Redirect
                  to={{
                    pathname: '/dashboard',
                    state: { from: location },
                  }}
                />
              );
            }

            return checkComponent();
          }

          return (
            <Redirect
              to={{
                pathname: '/dashboard',
                state: { from: location },
              }}
            />
          );
        } else if (!isPrivate) {
          return checkComponent();
        } else {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          );
        }

        return <></>;
      }}
      {...rest}
    />
  );
};

export default Route;
