import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FcCheckmark, FcCancel } from 'react-icons/fc';
import { FaCogs, FaShapes } from 'react-icons/fa';
import { PropagateLoader } from 'react-spinners';

import BreadCrumb from '~/components/BreadCrumb';
import DefaultInput from '~/components/Inputs/Default';
import EditButton from '~/components/Buttons/Edit';
import DeleteButton from '~/components/Buttons/Delete';
import Pagination from '~/components/Pagination';
import NewButton from '~/components/Buttons/New';
import ConfirmDeleteDialog from '~/components/Dialogs/ConfirmDelete';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  FilterContent,
  Table,
  Thead,
  Tbody,
  Th,
  TbodyTr,
  Td,
  ButtonsContent,
} from './styles';

interface IStoreRelationshipCategory {
  id: string;
  name: string;
  active: boolean;
}

const AMOUNT_PER_PAGE = 5;

const List: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState('');
  const [lastRefresh, setLastRefresh] = useState(new Date());
  const [storeRelationshipCategories, setStoreRelationshipCategories] =
    useState<IStoreRelationshipCategory[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [
    storeRelationshipCategoryIdDelete,
    setStoreRelationshipCategoryIdDelete,
  ] = useState('');
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [titleDelete, setTitleDelete] = useState('');
  const [subTitleDelete, setSubTitleDelete] = useState('');

  useEffect(() => {
    if (
      user.user_type.type !== 'ADM' &&
      user.user_type.type !== 'MK1' &&
      user.user_type.type !== 'MK2' &&
      user.user_type.type !== 'MAX'
    ) {
      history.push('/configurations/main');
    }
  }, [history, user]);

  useEffect(() => {
    const loadStoreRelationshipCategories = async () => {
      await api
        .get(
          `/configurations/storerelationshipcategories?page=${page}&filter=${filter}&amount=${AMOUNT_PER_PAGE}`,
        )
        .then(response => {
          setStoreRelationshipCategories(response.data[0]);
          setCount(response.data[1]);
          setLoading(false);
        });
    };

    loadStoreRelationshipCategories();
  }, [page, filter, lastRefresh]);

  const onDelete = useCallback(
    async (id: string) => {
      try {
        await api
          .delete(`/configurations/storerelationshipcategories/${id}`)
          .then(() => {
            setPage(0);
            setLastRefresh(new Date());

            addToast({
              type: 'success',
              title: 'Categoria de registro de loja excluída!',
              description:
                'Categoria de registro de loja excluída com sucesso.',
            });
          });
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
      setStoreRelationshipCategoryIdDelete('');
      setTitleDelete('');
      setSubTitleDelete('');
    },
    [addToast],
  );

  function handleTable(): React.ReactNode {
    if (storeRelationshipCategories) {
      if (storeRelationshipCategories.length > 0) {
        return (
          <Table>
            <Thead>
              <tr>
                <Th width="60%" textAlign="left">
                  Nome
                </Th>
                <Th width="20%" textAlign="center">
                  Situação
                </Th>
                <Th width="20%" textAlign="center" />
              </tr>
            </Thead>
            <Tbody>
              {loading && (
                <tr>
                  <Td colSpan={4} padding="1rem" textAlign="center">
                    <PropagateLoader color="#276c8d" />
                  </Td>
                </tr>
              )}
              {storeRelationshipCategories?.map(storeRelationshipCategory => (
                <TbodyTr key={storeRelationshipCategory.id}>
                  <Td width="60%" textAlign="left" heading="NOME">
                    {storeRelationshipCategory.name}
                  </Td>
                  <Td width="20%" textAlign="center" heading="SITUAÇÃO">
                    {storeRelationshipCategory.active ? (
                      <FcCheckmark size={22} />
                    ) : (
                      <FcCancel size={22} />
                    )}
                  </Td>
                  <Td
                    width="20%"
                    textAlign="center"
                    display="flex"
                    heading={' '}
                  >
                    <Link
                      to={{
                        pathname: `/configurations/storerelationshipcategories/edit/${storeRelationshipCategory.id}`,
                        state: storeRelationshipCategory,
                      }}
                    >
                      <EditButton />
                    </Link>

                    <DeleteButton
                      marginLeft="0.5rem"
                      onClick={() => {
                        setStoreRelationshipCategoryIdDelete(
                          storeRelationshipCategory.id,
                        );
                        setIsOpenDelete(true);
                        setTitleDelete(
                          `Deseja realmente excluir a categoria de registro ` +
                            `de loja ${storeRelationshipCategory.name}?`,
                        );
                        setSubTitleDelete(
                          'Esta operação não poderá ser desfeita!',
                        );
                      }}
                    />
                  </Td>
                </TbodyTr>
              ))}
            </Tbody>
          </Table>
        );
      }
    }

    return (
      <Table>
        <Thead>
          <tr>
            <Th width="100%" textAlign="center" />
          </tr>
        </Thead>
        <Tbody>
          <TbodyTr>
            <Td width="100%" textAlign="center">
              Nenhuma informação disponível
            </Td>
          </TbodyTr>
        </Tbody>
      </Table>
    );
  }

  return (
    <Container style={{ minHeight: 600 }}>
      <BreadCrumb
        main="Configurações"
        path="/configurations/main"
        firstChild="Categorias de Registros de Lojas"
        mainIcon={FaCogs}
        firstChildIcon={FaShapes}
        mainColored
      />

      <FilterContent>
        <DefaultInput
          name="search"
          labelText="Pesquisar"
          type="text"
          onChange={event => {
            setFilter(event.target.value);
            setPage(0);
          }}
        />
      </FilterContent>

      {handleTable()}

      <ButtonsContent>
        <Pagination
          page={page}
          count={count}
          amountPerPage={AMOUNT_PER_PAGE}
          setPage={setPage}
        />

        <Link to="/configurations/storerelationshipcategories/new">
          <NewButton />
        </Link>
      </ButtonsContent>

      <ConfirmDeleteDialog
        isOpen={isOpenDelete}
        title={titleDelete}
        subTitle={subTitleDelete}
        setIsOpen={setIsOpenDelete}
        onClose={() => {
          onDelete(storeRelationshipCategoryIdDelete);
        }}
      />
    </Container>
  );
};

export default List;
