import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.large};
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    zindex: 999;
    background-color: #eee;
    padding: 10px 20px 40px;
    border-radius: 8px;
    flex-direction: column;
  `}
`;

export const Icon = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    font-size: ${theme.fontSizes.xLarge};
    color: ${theme.colors.report};
    margin-bottom: 1rem;
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.xLarge};
    text-align: center;
    color: ${theme.colors.darkGrey};
  `}
`;

export const SubTitle = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.meddium};
    text-align: center;
    margin-top: 1rem;
  `}
`;

export const Options = styled.div`
  margin-top: 1rem;
`;

export const Info = styled.div`
  height: 15rem;
  padding-top: 2rem;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: center;
  margin-top: 1rem;
  cursor: pointer;
`;

export const Error = styled.div`
  ${({ theme }) => css`
    margin-top: 1rem;
    color: ${theme.colors.error};
    font-size: ${theme.fontSizes.meddium};
    text-align: center;
  `}
`;
