import React, { InputHTMLAttributes, useState, useCallback } from 'react';

import ErrorTooltip from '~/components/Tooltips/Error';

import { Label, Container, InputText } from './styles';

interface IWholeNumberProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  labelFor?: string;
  labelText: string;
  error?: string;
  defaultValue?: number;
  disabledField?: boolean;
  onSet?: (e: any) => void;
}

const WholeNumber = React.forwardRef<HTMLInputElement, IWholeNumberProps>(
  (
    {
      name,
      labelFor,
      labelText,
      error,
      defaultValue,
      disabledField,
      onSet,
    }: IWholeNumberProps,
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const [value, setValue] = useState(
      defaultValue !== undefined ? defaultValue : 0,
    );

    const handleInputFocus = useCallback(() => {
      setIsFocused(!isFocused);
    }, [isFocused]);

    const handleInputBlur = useCallback(() => {
      setIsFocused(false);
    }, []);

    const handleChange = (e: any): void => {
      let newValue = 0;

      e.target.value = e.target.value.replaceAll('.', '');

      if (e.target.value.length > 6) {
        e.target.value = e.target.value.substring(0, 6);
      }

      if (!Number.isNaN(Number(parseFloat(e.target.value)))) {
        newValue = parseFloat(e.target.value);
      }

      setValue(newValue);
      if (onSet) {
        onSet(e);
      }
    };

    return (
      <>
        <Label isFocused={isFocused}>{labelText}</Label>

        <Container id={labelFor} isFocused={isFocused} isErrored={!!error}>
          <InputText
            name={name}
            type="text"
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            defaultValue={defaultValue}
            value={value}
            ref={ref}
            intlConfig={{ locale: 'pt-BR' }}
            allowDecimals={false}
            allowNegativeValue={false}
            decimalsLimit={0}
            maxLength={6}
            onChange={e => {
              handleChange(e);
            }}
            disabled={disabledField}
          />

          {error && <ErrorTooltip title={error} />}
        </Container>
      </>
    );
  },
);

export default WholeNumber;
