import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight, FaBoxes } from 'react-icons/fa';

import BreadCrumb from '~/components/BreadCrumb';

import {
  AnimatedContainer,
  Items,
  ItemCardWrapper,
  ItemCard,
  ItemIcon,
  ItemName,
  GoIcon,
} from './styles';
import { ITEMS_NAVIGATION_ANIMATION } from './animations';

import { ITEMS_NAVIGATION } from '~/constants';

const MainContent: React.FC = () => {
  return (
    <AnimatedContainer variants={ITEMS_NAVIGATION_ANIMATION}>
      <BreadCrumb main="Itens" path="/items/main" mainIcon={FaBoxes} />
      <Items>
        {Object.entries(ITEMS_NAVIGATION).map(([key, value]) => (
          <ItemCardWrapper key={`wrapper-${key}`}>
            <Link to={value.link}>
              <ItemCard
                key={`card-${key}`}
                backgroundColor={value.backgroundColor}
              >
                <ItemIcon>{value.icon}</ItemIcon>

                <ItemName>{key}</ItemName>

                <GoIcon>
                  <FaArrowRight size={20} />
                </GoIcon>
              </ItemCard>
            </Link>
          </ItemCardWrapper>
        ))}
      </Items>
    </AnimatedContainer>
  );
};

export default MainContent;
