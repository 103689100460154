import React from 'react';

import {
  FaTruck,
  FaDonate,
  FaMoneyCheckAlt,
  FaGift,
  FaShapes,
  FaLaptopHouse,
} from 'react-icons/fa';

export default {
  'Operadores Logísticos': {
    icon: <FaTruck size={30} />,
    link: '/configurations/thirdpartylogisticsproviders',
    backgroundColor: '#747474',
  },
  'Tipos de Abatimentos Fiscais': {
    icon: <FaDonate size={30} />,
    link: '/configurations/taxdeductiontypes',
    backgroundColor: '#747474',
  },
  'Tipos de Custos de Eventos': {
    icon: <FaMoneyCheckAlt size={30} />,
    link: '/configurations/eventcosttypes',
    backgroundColor: '#747474',
  },
  'Tipos de Brindes': {
    icon: <FaGift size={30} />,
    link: '/configurations/gifttypes',
    backgroundColor: '#747474',
  },
  'Categorias de Registros de Lojas': {
    icon: <FaShapes size={30} />,
    link: '/configurations/storerelationshipcategories',
    backgroundColor: '#747474',
  },
  'Tipos de Tópico de Registros de Lojas': {
    icon: <FaLaptopHouse size={30} />,
    link: '/configurations/storerelationshiptopictypes',
    backgroundColor: '#747474',
  },
};
