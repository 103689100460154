import React, { ButtonHTMLAttributes } from 'react';
import { FaEllipsisH } from 'react-icons/fa';

import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const CenterButton: React.FC<ButtonProps> = ({ onClick }) => {
  return (
    <Container onClick={onClick}>
      <FaEllipsisH size={16} />
    </Container>
  );
};

export default CenterButton;
