import React, { useState, useEffect, useCallback } from 'react';
import { PropagateLoader } from 'react-spinners';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import * as mime from 'mime';

import { FaAngleDown, FaLink, FaTrashAlt } from 'react-icons/fa';

import AudioIcon from '~/components/Icons/Files/Audio';
import PdfIcon from '~/components/Icons/Files/Pdf';
import PresentationIcon from '~/components/Icons/Files/Presentation';
import SpreadsheetIcon from '~/components/Icons/Files/Spreadsheet';
import TextIcon from '~/components/Icons/Files/Text';
import VideoIcon from '~/components/Icons/Files/Video';
import WordIcon from '~/components/Icons/Files/Word';
import Paginator from '~/components/Paginator';
import PrevButton from '~/components/Paginator/PrevButton';
import NextButton from '~/components/Paginator/NextButton';
import CenterButton from '~/components/Paginator/CenterButton';
import ConfirmDeleteDialog from '~/components/Dialogs/ConfirmDelete';

import DefaultInput from '~/components/Inputs/Default';

import { useToast } from '~/hooks/toast';
import { useAuth } from '~/hooks/auth';

import api from '~/services/api';

import {
  Container,
  Label,
  Wrapper,
  Table,
  Th,
  Td,
  FileWrapper,
  FileImageWrapper,
  FileImage,
  UrlIcon,
  DeleteBtn,
  ButtonsContent,
} from './styles';

interface ICustomProjectFilesProps {
  project_id: string;
  project_procedure_id: string;
}

interface IProjectCustomProjectFile {
  id: string;
  name: string;
  size: number;
  key: string;
  url: string;
  project_id: string;
  user_id: string;
  project_procedure_id: string;
  project_custom_proj_file_received_id: string;
  project: {
    code: string;
    name: string;
  };
  user: {
    id: string;
    name: string;
    type: string;
  };
  created_at: string;
}

const CustomProjectFiles: React.FC<ICustomProjectFilesProps> = ({
  project_id,
  project_procedure_id,
}) => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const [page, setPage] = useState(0);
  const [pageFilter, setPageFilter] = useState(0);
  const [filter, setFilter] = useState('');
  const [filterHelper, setFilterHelper] = useState('');
  const [refresh, setRefresh] = useState(true);
  const [projectCustomProjectFiles, setProjectCustomProjectFiles] =
    useState<IProjectCustomProjectFile[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [
    projectCustomProjectFileIdDelete,
    setProjectCustomProjectFileIdDelete,
  ] = useState('');
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [titleDelete, setTitleDelete] = useState('');
  const [subTitleDelete, setSubTitleDelete] = useState('');

  useEffect(() => {
    if (refresh && project_id) {
      setRefresh(false);
      let pageSearch = 0;
      let foundData = false;

      if (filter !== filterHelper) {
        setPage(0);
        setPageFilter(0);
      }

      setFilterHelper(filter);

      if (filter !== '') {
        pageSearch = pageFilter;
      } else {
        pageSearch = page;
      }

      api
        .get(
          `/projectcustomprojectfiles/project?project_id=${project_id}&page=${pageSearch}&filter=${filter}`,
        )
        .then(firstResponse => {
          if (firstResponse.data.length > 0) {
            foundData = true;
            setProjectCustomProjectFiles(firstResponse.data);
            setLoading(false);
          }

          if (!foundData) {
            if (pageSearch - 1 >= 0) {
              pageSearch -= 1;
              if (filter !== '') {
                setPageFilter(pageFilter - 1);
              } else {
                setPage(page - 1);
              }
            }

            api
              .get(
                `/projectcustomprojectfiles/project?project_id=${project_id}&page=${pageSearch}&filter=${filter}`,
              )
              .then(secondResponse => {
                setProjectCustomProjectFiles(secondResponse.data);
                setLoading(false);

                api
                  .get(
                    `/projectcustomprojectfiles/project?project_id=${project_id}&page=${
                      pageSearch + 1
                    }
                      &filter=${filter}`,
                  )
                  .then(thirdResponse => {
                    setNextDisabled(false);
                    if (thirdResponse.data.length === 0) {
                      setNextDisabled(true);
                    }
                  });
              });
          } else {
            api
              .get(
                `/projectcustomprojectfiles/project?project_id=${project_id}&page=${
                  pageSearch + 1
                }
                  &filter=${filter}`,
              )
              .then(fourthResponse => {
                setNextDisabled(false);
                if (fourthResponse.data.length === 0) {
                  setNextDisabled(true);
                }
              });
          }
        });
    }
  }, [page, pageFilter, filter, filterHelper, refresh, project_id]);

  function handlePage(type: string): void {
    if (type === 'backward') {
      if (filter !== '') {
        setPageFilter(pageFilter - 1);
      } else {
        setPage(page - 1);
      }
    } else if (type === 'forward') {
      if (filter !== '') {
        setPageFilter(pageFilter + 1);
      } else {
        setPage(page + 1);
      }
    } else if (filter !== '') {
      setPageFilter(0);
    } else {
      setPage(0);
    }
    setRefresh(true);
  }

  function handlePrevButtonDisabled(): boolean {
    let validationPage = 0;

    if (filter !== '') {
      validationPage = pageFilter;
    } else {
      validationPage = page;
    }

    if (validationPage === 0) {
      return true;
    }

    return false;
  }

  function handleFilterChange(value: string): void {
    setFilter(value);
    setRefresh(true);
  }

  function handleProjectCustomProjectFileImageIcon(
    projectCustomProjectFileUrl: string,
  ): React.ReactNode {
    const projectCustomProjectFileMimeType = mime.getType(
      projectCustomProjectFileUrl,
    );

    if (
      projectCustomProjectFileMimeType === 'audio/mp3' ||
      projectCustomProjectFileMimeType === 'audio/mpeg'
    ) {
      return <AudioIcon iconSize={50} />;
    }

    if (projectCustomProjectFileMimeType === 'application/pdf') {
      return <PdfIcon iconSize={50} />;
    }

    if (
      projectCustomProjectFileMimeType ===
        'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
      projectCustomProjectFileMimeType ===
        'application/vnd.oasis.opendocument.presentation'
    ) {
      return <PresentationIcon iconSize={50} />;
    }

    if (
      projectCustomProjectFileMimeType ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      projectCustomProjectFileMimeType ===
        'application/vnd.oasis.opendocument.spreadsheet'
    ) {
      return <SpreadsheetIcon iconSize={50} />;
    }

    if (projectCustomProjectFileMimeType === 'text/plain') {
      return <TextIcon iconSize={50} />;
    }

    if (
      projectCustomProjectFileMimeType === 'image/gif' ||
      projectCustomProjectFileMimeType === 'video/quicktime' ||
      projectCustomProjectFileMimeType === 'video/mp4'
    ) {
      return <VideoIcon iconSize={40} />;
    }

    if (
      projectCustomProjectFileMimeType ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      projectCustomProjectFileMimeType ===
        'application/vnd.oasis.opendocument.text'
    ) {
      return <WordIcon iconSize={50} />;
    }

    return (
      <FileImageWrapper>
        <FileImage src={projectCustomProjectFileUrl} />
      </FileImageWrapper>
    );
  }

  function handleTable(): React.ReactNode {
    if (projectCustomProjectFiles) {
      if (projectCustomProjectFiles.length > 0) {
        return (
          <Table>
            <thead>
              <tr>
                <Th width="30%" textAlign="left" />
                <Th width="20%" textAlign="left">
                  Data
                </Th>
                <Th width="40%" textAlign="left">
                  Nome
                </Th>
                <Th width="10%" textAlign="center" />
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <Td colSpan={4} padding="1rem" textAlign="center">
                    <PropagateLoader color="#276c8d" />
                  </Td>
                </tr>
              )}
              {projectCustomProjectFiles?.map(projectCustomProjectFile => (
                <tr key={projectCustomProjectFile.id}>
                  <Td width="15%" textAlign="left" heading={' '}>
                    <FileWrapper>
                      {handleProjectCustomProjectFileImageIcon(
                        projectCustomProjectFile.url,
                      )}
                    </FileWrapper>
                  </Td>
                  <Td width="20%" textAlign="left" heading="DATA">
                    {new Date(
                      projectCustomProjectFile.created_at,
                    ).toLocaleDateString()}
                  </Td>
                  <Td width="60%" textAlign="left" heading="NOME">
                    {projectCustomProjectFile.name.substring(0, 100)}
                  </Td>
                  <Td
                    width="10%"
                    textAlign="center"
                    display="flex"
                    heading={' '}
                  >
                    <UrlIcon>
                      <a
                        href={projectCustomProjectFile.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaLink size={22} />
                      </a>
                    </UrlIcon>

                    <DeleteBtn
                      disabled={user.user_type.type !== 'ADM'}
                      isHidden={user.user_type.type !== 'ADM'}
                      onClick={() => {
                        setProjectCustomProjectFileIdDelete(
                          projectCustomProjectFile.id,
                        );
                        setIsOpenDelete(true);
                        setTitleDelete(
                          `Deseja realmente excluir o arquivo ${projectCustomProjectFile.name}?`,
                        );
                        setSubTitleDelete(
                          'Esta operação não poderá ser desfeita!',
                        );
                      }}
                    >
                      <FaTrashAlt size={22} />
                    </DeleteBtn>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        );
      }
    }

    return (
      <Table>
        <thead>
          <tr>
            <Th width="100%" textAlign="center" />
          </tr>
        </thead>
        <tbody>
          <tr>
            <Td width="100%" textAlign="center">
              Nenhuma informação disponível
            </Td>
          </tr>
        </tbody>
      </Table>
    );
  }

  const onDelete = useCallback(
    async (id: string) => {
      try {
        await api
          .delete(`/projectcustomprojectfiles/${id}`, {
            data: {
              user_id: user.id,
              project_procedure_id,
            },
          })
          .then(() => {
            setRefresh(true);
            addToast({
              type: 'success',
              title: 'Arquivo excluído!',
              description: 'Arquivo excluído com sucesso.',
            });
          });
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
      setProjectCustomProjectFileIdDelete('');
      setTitleDelete('');
      setSubTitleDelete('');
    },
    [addToast, user.id, project_procedure_id],
  );

  if (!project_id) {
    return null;
  }

  return (
    <Container>
      <Accordion>
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Label>Anexos de Projeto Personalizado</Label>
        </AccordionSummary>

        <AccordionDetails>
          <Wrapper>
            <div>
              <DefaultInput
                name="search"
                labelText="Pesquisar"
                type="text"
                onChange={event => handleFilterChange(event.target.value)}
              />
            </div>

            {handleTable()}

            <ButtonsContent>
              <Paginator>
                <PrevButton
                  disabled={handlePrevButtonDisabled()}
                  onClick={() => handlePage('backward')}
                />
                <CenterButton onClick={() => handlePage('reset')} />
                <NextButton
                  disabled={nextDisabled}
                  onClick={() => handlePage('forward')}
                />
              </Paginator>
            </ButtonsContent>
          </Wrapper>
        </AccordionDetails>
      </Accordion>

      <ConfirmDeleteDialog
        isOpen={isOpenDelete}
        title={titleDelete}
        subTitle={subTitleDelete}
        setIsOpen={setIsOpenDelete}
        onClose={() => {
          onDelete(projectCustomProjectFileIdDelete);
        }}
      />
    </Container>
  );
};

export default CustomProjectFiles;
