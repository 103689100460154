import React from 'react';

import InfoInput from '~/components/Inputs/Info';

import { Container, Content } from './styles';

interface ISentAmountProps {
  sent_amount?: number;
}

const SentAmount: React.FC<ISentAmountProps> = ({ sent_amount }) => {
  return (
    <Container>
      <Content>
        <InfoInput
          name="sent_amount"
          labelFor="sent_amount"
          labelText="Qtd. Enviada"
          type="text"
          defaultValue={
            sent_amount
              ? new Intl.NumberFormat('pt-BR').format(sent_amount)
              : new Intl.NumberFormat('pt-BR').format(0)
          }
          disabled
        />
      </Content>
    </Container>
  );
};

export default SentAmount;
