import React from 'react';
import { FaHome, FaProjectDiagram, FaBoxes, FaUser } from 'react-icons/fa';

export default {
  Início: {
    icon: <FaHome color="#c9aa71" />,
    link: '/dashboard',
  },
  Solicitações: {
    icon: <FaProjectDiagram color="#c9aa71" />,
    link: '/projects/main',
  },
  Itens: {
    icon: <FaBoxes color="#c9aa71" />,
    link: '/items/main',
  },
  Usuários: {
    icon: <FaUser color="#c9aa71" />,
    link: '/users',
  },
} as const;
