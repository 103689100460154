import React from 'react';
import ReactApexChart from 'react-apexcharts';

import { useTheme } from 'styled-components';

import { Container } from './styles';

interface IDistributedColumnsGeneral2Props {
  category: string;
  amount: number;
  color?: string;
}

const DistributedColumnsGeneral2: React.FC<IDistributedColumnsGeneral2Props> =
  ({ category, amount, color }: IDistributedColumnsGeneral2Props) => {
    const { colors } = useTheme();

    function getColor(): string {
      if (category === 'Displays') {
        return colors.chartDisplay;
      }
      if (category === 'Materiais') {
        return colors.chartMaterial;
      }
      if (category === 'Pagamentos') {
        return colors.chartSponsorship;
      }
      if (category === 'Pagamentos - Campanha de Rebate') {
        return colors.chartSponsorship;
      }
      if (category === 'Problemas') {
        return colors.chartAdversity;
      }
      if (category === 'Pastas') {
        return colors.chartFolder;
      }
      if (category === 'Amostras') {
        return colors.chartSample;
      }
      if (category === 'Eventos') {
        return colors.chartEvent;
      }
      if (color && color !== '') {
        return color;
      }

      return '#fff';
    }

    const optionColors = [getColor()];

    const series = [
      {
        name: 'Quantidade',
        data: [amount],
      },
    ];

    const options = {
      chart: {
        offsetX: 0,
        offsetY: 0,
        redrawOnWindowResize: true,
        toolbar: {
          show: false,
        },
      },
      colors: optionColors,
      plotOptions: {
        bar: {
          columnWidth: '50%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [category],
        labels: {
          style: {
            colors: optionColors,
            fontSize: '12px',
          },
        },
      },
    };

    return (
      <Container>
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </Container>
    );
  };

export default DistributedColumnsGeneral2;
