import React, { useState, useEffect } from 'react';

import { useAuth } from '~/hooks/auth';

import api from '~/services/api';

import { Container } from './styles';

const AccountProjects: React.FC = () => {
  const { user } = useAuth();
  const [pendingProjects, setPendingProjects] = useState(0);

  useEffect(() => {
    api.get(`/projects/usertypependingprojects`).then(response => {
      setPendingProjects(response.data.length);
    });
  }, [user]);

  return <Container>{pendingProjects}</Container>;
};

export default AccountProjects;
