import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { FaAngleDown } from 'react-icons/fa';

import InfoInput from '~/components/Inputs/Info';
import TextArea from '~/components/TextArea';

import { MainContainer, Label, Container, Content, Wrapper } from './styles';

interface IMainProps {
  code: string;
  note: string;
}

const Main: React.FC<IMainProps> = ({ code, note }) => {
  return (
    <MainContainer>
      <Accordion>
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Label>Informações Gerais</Label>
        </AccordionSummary>

        <AccordionDetails>
          <Container>
            <Content maxWidth="680px">
              <Wrapper>
                <InfoInput
                  name="code"
                  labelFor="code"
                  labelText="Código"
                  type="text"
                  defaultValue={code}
                  disabled
                />
              </Wrapper>
            </Content>

            <Content maxWidth="680px">
              <Wrapper>
                <TextArea
                  name="note"
                  labelFor="note"
                  labelText="Observação"
                  defaultValue={note}
                  maxLength={1000}
                  disabled
                />
              </Wrapper>
            </Content>
          </Container>
        </AccordionDetails>
      </Accordion>
    </MainContainer>
  );
};

export default Main;
