import React, { useCallback, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { FaCogs, FaTruck } from 'react-icons/fa';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import BreadCrumb from '~/components/BreadCrumb';
import DefaultInput from '~/components/Inputs/Default';
import Toggle from '~/components/Toggle';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Content,
  OneFourthWrapper,
  HalfWrapper,
  ButtonContainer,
} from './styles';

interface IFormThirdPartyLogisticsProvider {
  name: string;
  email: string;
  active: boolean;
  send_quotation: boolean;
}

interface ILocation {
  id: string;
  name: string;
  email: string;
  active: boolean;
  send_quotation: boolean;
}

const schema = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  email: Yup.string().required('Campo obrigatório').email('E-mail inválido'),
});

const New: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { handleSubmit, register, control, errors } =
    useForm<IFormThirdPartyLogisticsProvider>({
      resolver: yupResolver(schema),
      defaultValues: {
        active: state?.active,
        send_quotation: state?.send_quotation,
      },
    });

  useEffect(() => {
    if (user.user_type.type !== 'ADM' && user.user_type.type !== 'IMK') {
      history.push('/configurations/main');
    }
  }, [history, user]);

  const submitForm = useCallback(
    async (data: IFormThirdPartyLogisticsProvider) => {
      try {
        setIsSaving(true);

        if (state === undefined) {
          await api.post('/configurations/thirdpartylogisticsproviders', data);

          addToast({
            type: 'success',
            title: 'Cadastro realizado!',
            description: 'Operador logístico cadastrado com sucesso.',
          });
        } else {
          await api.put(`/configurations/thirdpartylogisticsproviders`, {
            id: state.id,
            ...data,
          });

          addToast({
            type: 'success',
            title: 'Cadastro atualizado!',
            description: 'Operador logístico atualizado com sucesso.',
          });
        }

        history.push('/configurations/thirdpartylogisticsproviders');
      } catch (error: any) {
        setIsSaving(false);

        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [addToast, history, state],
  );

  const onExit = useCallback(() => {
    history.push('/configurations/thirdpartylogisticsproviders');
  }, [history]);

  return (
    <Container>
      <BreadCrumb
        main="Configurações"
        path="/configurations/main"
        firstChildPath="/configurations/thirdpartylogisticsproviders"
        firstChild="Operadores Logísticos"
        secondChild={
          state === undefined
            ? 'Novo Operador Logístico'
            : 'Edição de Operador Logístico'
        }
        mainIcon={FaCogs}
        firstChildIcon={FaTruck}
        secondChildIcon={FaTruck}
        mainColored
        firstChildColored
      />

      <form onSubmit={handleSubmit(submitForm)}>
        <Content style={{ maxWidth: 700 }}>
          <HalfWrapper>
            <DefaultInput
              name="name"
              labelFor="name"
              labelText="Nome"
              type="text"
              defaultValue={state?.name}
              error={errors?.name?.message}
              ref={register}
              maxLength={60}
            />
          </HalfWrapper>

          <OneFourthWrapper>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                />
              )}
              name="active"
              defaultValue
              control={control}
            />
          </OneFourthWrapper>
        </Content>

        <Content style={{ maxWidth: 700 }}>
          <HalfWrapper>
            <DefaultInput
              name="email"
              labelFor="email"
              labelText="E-mail"
              type="email"
              defaultValue={state?.email}
              error={errors?.email?.message}
              ref={register}
              maxLength={60}
            />
          </HalfWrapper>

          <OneFourthWrapper>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                  label="Envia Cotação"
                />
              )}
              name="send_quotation"
              defaultValue
              control={control}
            />
          </OneFourthWrapper>
        </Content>

        <ButtonContainer>
          <SaveButton
            type="button"
            processing={isSaving}
            disabled={isSaving}
            onClick={() => {
              setIsOpenConfirmProcedure(true);
            }}
          />

          <ExitButton
            style={{ marginLeft: 10 }}
            type="button"
            disabled={isSaving}
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={() => handleSubmit(submitForm)()}
        title="Confirma gravação do operador logístico?"
        subTitle=""
        opConfirm="Sim"
        type=""
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />
    </Container>
  );
};

export default New;
