import React from 'react';

import {
  FaBox,
  FaDollyFlatbed,
  FaMoneyBillWave,
  FaListAlt,
  FaFolder,
  FaBoxOpen,
  FaCalendarAlt,
} from 'react-icons/fa';

export default {
  'Mostruários de PDV solicitados': {
    icon: <FaBox />,
    link: '/projects/displays',
    type: 'DISPLAY',
  },
  'Materiais solicitados': {
    icon: <FaDollyFlatbed />,
    link: '/projects/materials',
    type: 'MATERIAL',
  },
  'Pagamentos solicitados': {
    icon: <FaMoneyBillWave />,
    link: '/projects/sponsorships',
    type: 'PATROCINIO',
  },
  'Problemas reportados': {
    icon: <FaListAlt />,
    link: '/projects/adversities',
    type: 'ADVERSIDADE',
  },
  'Pastas solicitadas': {
    icon: <FaFolder />,
    link: '/projects/folders',
    type: 'PASTA',
  },
  'Amostras solicitadas': {
    icon: <FaBoxOpen />,
    link: '/projects/samples',
    type: 'AMOSTRA',
  },
  'Eventos solicitados': {
    icon: <FaCalendarAlt />,
    link: '/projects/events',
    type: 'EVENTO',
  },
} as const;
