import React, { useState, useEffect, useRef } from 'react';

import Select from '~/components/Select';
import SimplePieType from '~/components/StatisticsCharts/SimplePieType';

import { useAuth } from '~/hooks/auth';

import api from '~/services/api';

import {
  Container,
  FilterContent,
  FilterWrapper,
  ChartWrapper,
} from './styles';

interface IRepresentative {
  code: string;
  name: string;
}

interface IClient {
  code: string;
  company_name: string;
}

interface IProject {
  type: string;
  status: string;
}

const Statistic2: React.FC = () => {
  const { user } = useAuth();
  const [representatives, setRepresentatives] = useState<IRepresentative[]>();
  const [clients, setClients] = useState<IClient[]>();
  const [projects, setProjects] = useState<IProject[]>();
  const stateValue = useRef('ALL');
  const stateLabel = useRef('Todos');
  const previousStateValue = useRef('');
  const representativeValue = useRef('ALL');
  const representativeLabel = useRef('Todos');
  const previousRepresentativeValue = useRef('');
  const clientValue = useRef('ALL');
  const clientLabel = useRef('Todos');
  const previousClientValue = useRef('');
  const [refreshStates, setRefreshStates] = useState(true);
  const [refreshRepresentatives, setRefreshRepresentatives] = useState(false);
  const [refreshClients, setRefreshClients] = useState(false);
  const [refreshProjects, setRefreshProjects] = useState(false);
  const [displayPendingProjects, setDisplayPendingProjects] = useState(0);
  const [displayFinishedProjects, setDisplayFinishedProjects] = useState(0);
  const [displayCanceledProjects, setDisplayCanceledProjects] = useState(0);
  const [materialPendingProjects, setMaterialPendingProjects] = useState(0);
  const [materialFinishedProjects, setMaterialFinishedProjects] = useState(0);
  const [materialCanceledProjects, setMaterialCanceledProjects] = useState(0);
  const [sponsorshipPendingProjects, setSponsorshipPendingProjects] =
    useState(0);
  const [sponsorshipFinishedProjects, setSponsorshipFinishedProjects] =
    useState(0);
  const [sponsorshipCanceledProjects, setSponsorshipCanceledProjects] =
    useState(0);
  const [adversityPendingProjects, setAdversityPendingProjects] = useState(0);
  const [adversityFinishedProjects, setAdversityFinishedProjects] = useState(0);
  const [adversityCanceledProjects, setAdversityCanceledProjects] = useState(0);
  const [folderPendingProjects, setFolderPendingProjects] = useState(0);
  const [folderFinishedProjects, setFolderFinishedProjects] = useState(0);
  const [folderCanceledProjects, setFolderCanceledProjects] = useState(0);
  const [samplePendingProjects, setSamplePendingProjects] = useState(0);
  const [sampleFinishedProjects, setSampleFinishedProjects] = useState(0);
  const [sampleCanceledProjects, setSampleCanceledProjects] = useState(0);
  const [eventPendingProjects, setEventPendingProjects] = useState(0);
  const [eventFinishedProjects, setEventFinishedProjects] = useState(0);
  const [eventCanceledProjects, setEventCanceledProjects] = useState(0);

  useEffect(() => {
    if (refreshStates) {
      if (stateValue.current !== previousStateValue.current) {
        previousStateValue.current = stateValue.current;
        representativeValue.current = 'ALL';
        representativeLabel.current = 'Todos';
        clientValue.current = 'ALL';
        clientLabel.current = 'Todos';

        setRefreshStates(false);
        setRefreshRepresentatives(true);
      }
    }
  }, [refreshStates]);

  useEffect(() => {
    if (refreshRepresentatives) {
      if (representativeValue.current !== previousRepresentativeValue.current) {
        previousRepresentativeValue.current = representativeValue.current;
        clientValue.current = 'ALL';
        clientLabel.current = 'Todos';
      }
      if (stateValue.current && stateValue.current !== '') {
        api
          .get(`/representatives/state?state=${stateValue.current}`)
          .then(response => {
            setRepresentatives([
              {
                code: 'ALL',
                name: '',
              },
              ...response.data,
            ]);
            setRefreshRepresentatives(false);
            setRefreshClients(true);
          });
      }
    }
  }, [refreshRepresentatives]);

  useEffect(() => {
    if (refreshClients) {
      if (clientValue.current !== previousClientValue.current) {
        previousClientValue.current = clientValue.current;
      }
      if (
        stateValue.current &&
        stateValue.current !== '' &&
        representativeValue.current &&
        representativeValue.current !== ''
      ) {
        if (user.user_type.type === 'REP') {
          representativeValue.current = user.erp_rep_code;
        }
        api
          .get(
            `/clients/staterepresentative?` +
              `state=${stateValue.current}&` +
              `erp_rep_code=${representativeValue.current}`,
          )
          .then(response => {
            setClients([
              {
                code: 'ALL',
                company_name: '',
              },
              ...response.data,
            ]);
            setRefreshClients(false);
            setRefreshProjects(true);
          });
      }
    }
  }, [refreshClients, user]);

  useEffect(() => {
    if (refreshProjects) {
      if (
        stateValue.current &&
        stateValue.current !== '' &&
        representativeValue.current &&
        representativeValue.current !== '' &&
        clientValue.current &&
        clientValue.current !== ''
      ) {
        api
          .get(
            `/projects/projectstatistics?` +
              `state=${stateValue.current}&` +
              `erp_rep_code=${representativeValue.current}&` +
              `erp_client_code=${clientValue.current}&` +
              `status=Todos`,
          )
          .then(response => {
            setProjects(response.data);
            setRefreshProjects(false);
          });
      }
    }
  }, [refreshProjects]);

  useEffect(() => {
    let display_pending_project_length = 0;
    let display_finished_project_length = 0;
    let display_canceled_project_length = 0;
    let material_pending_project_length = 0;
    let material_finished_project_length = 0;
    let material_canceled_project_length = 0;
    let sponsorship_pending_project_length = 0;
    let sponsorship_finished_project_length = 0;
    let sponsorship_canceled_project_length = 0;
    let adversity_pending_project_length = 0;
    let adversity_finished_project_length = 0;
    let adversity_canceled_project_length = 0;
    let folder_pending_project_length = 0;
    let folder_finished_project_length = 0;
    let folder_canceled_project_length = 0;
    let sample_pending_project_length = 0;
    let sample_finished_project_length = 0;
    let sample_canceled_project_length = 0;
    let event_pending_project_length = 0;
    let event_finished_project_length = 0;
    let event_canceled_project_length = 0;

    if (projects) {
      if (projects.length > 0) {
        display_pending_project_length = projects.filter(
          project =>
            project.status.toUpperCase() === 'PENDENTE' &&
            project.type === 'DISPLAY',
        ).length;

        display_finished_project_length = projects.filter(
          project =>
            project.status.toUpperCase() === 'FINALIZADO' &&
            project.type === 'DISPLAY',
        ).length;

        display_canceled_project_length = projects.filter(
          project =>
            project.status.toUpperCase() === 'CANCELADO' &&
            project.type === 'DISPLAY',
        ).length;

        material_pending_project_length = projects.filter(
          project =>
            project.status.toUpperCase() === 'PENDENTE' &&
            project.type === 'MATERIAL',
        ).length;

        material_finished_project_length = projects.filter(
          project =>
            project.status.toUpperCase() === 'FINALIZADO' &&
            project.type === 'MATERIAL',
        ).length;

        material_canceled_project_length = projects.filter(
          project =>
            project.status.toUpperCase() === 'CANCELADO' &&
            project.type === 'MATERIAL',
        ).length;

        sponsorship_pending_project_length = projects.filter(
          project =>
            project.status.toUpperCase() === 'PENDENTE' &&
            project.type === 'PATROCINIO',
        ).length;

        sponsorship_finished_project_length = projects.filter(
          project =>
            project.status.toUpperCase() === 'FINALIZADO' &&
            project.type === 'PATROCINIO',
        ).length;

        sponsorship_canceled_project_length = projects.filter(
          project =>
            project.status.toUpperCase() === 'CANCELADO' &&
            project.type === 'PATROCINIO',
        ).length;

        adversity_pending_project_length = projects.filter(
          project =>
            project.status.toUpperCase() === 'PENDENTE' &&
            project.type === 'ADVERSIDADE',
        ).length;

        adversity_finished_project_length = projects.filter(
          project =>
            project.status.toUpperCase() === 'FINALIZADO' &&
            project.type === 'ADVERSIDADE',
        ).length;

        adversity_canceled_project_length = projects.filter(
          project =>
            project.status.toUpperCase() === 'CANCELADO' &&
            project.type === 'ADVERSIDADE',
        ).length;

        folder_pending_project_length = projects.filter(
          project =>
            project.status.toUpperCase() === 'PENDENTE' &&
            project.type === 'PASTA',
        ).length;

        folder_finished_project_length = projects.filter(
          project =>
            project.status.toUpperCase() === 'FINALIZADO' &&
            project.type === 'PASTA',
        ).length;

        folder_canceled_project_length = projects.filter(
          project =>
            project.status.toUpperCase() === 'CANCELADO' &&
            project.type === 'PASTA',
        ).length;

        sample_pending_project_length = projects.filter(
          project =>
            project.status.toUpperCase() === 'PENDENTE' &&
            project.type === 'AMOSTRA',
        ).length;

        sample_finished_project_length = projects.filter(
          project =>
            project.status.toUpperCase() === 'FINALIZADO' &&
            project.type === 'AMOSTRA',
        ).length;

        sample_canceled_project_length = projects.filter(
          project =>
            project.status.toUpperCase() === 'CANCELADO' &&
            project.type === 'AMOSTRA',
        ).length;

        event_pending_project_length = projects.filter(
          project =>
            project.status.toUpperCase() === 'PENDENTE' &&
            project.type === 'EVENTO',
        ).length;

        event_finished_project_length = projects.filter(
          project =>
            project.status.toUpperCase() === 'FINALIZADO' &&
            project.type === 'EVENTO',
        ).length;

        event_canceled_project_length = projects.filter(
          project =>
            project.status.toUpperCase() === 'CANCELADO' &&
            project.type === 'EVENTO',
        ).length;
      }

      setDisplayPendingProjects(display_pending_project_length);
      setDisplayFinishedProjects(display_finished_project_length);
      setDisplayCanceledProjects(display_canceled_project_length);
      setMaterialPendingProjects(material_pending_project_length);
      setMaterialFinishedProjects(material_finished_project_length);
      setMaterialCanceledProjects(material_canceled_project_length);
      setSponsorshipPendingProjects(sponsorship_pending_project_length);
      setSponsorshipFinishedProjects(sponsorship_finished_project_length);
      setSponsorshipCanceledProjects(sponsorship_canceled_project_length);
      setAdversityPendingProjects(adversity_pending_project_length);
      setAdversityFinishedProjects(adversity_finished_project_length);
      setAdversityCanceledProjects(adversity_canceled_project_length);
      setFolderPendingProjects(folder_pending_project_length);
      setFolderFinishedProjects(folder_finished_project_length);
      setFolderCanceledProjects(folder_canceled_project_length);
      setSamplePendingProjects(sample_pending_project_length);
      setSampleFinishedProjects(sample_finished_project_length);
      setSampleCanceledProjects(sample_canceled_project_length);
      setEventPendingProjects(event_pending_project_length);
      setEventFinishedProjects(event_finished_project_length);
      setEventCanceledProjects(event_canceled_project_length);
    }
  }, [projects]);

  const stateOptions: { value: string; label: string }[] = [
    { value: 'ALL', label: 'Todos' },
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapá' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceará' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espírito Santo' },
    { value: 'GO', label: 'Goiás' },
    { value: 'MA', label: 'Maranhão' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Pará' },
    { value: 'PB', label: 'Paraíba' },
    { value: 'PR', label: 'Paraná' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piauí' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondônia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'São Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' },
  ];

  const representativeOptions = representatives?.map(representative => ({
    value: representative.code,
    label:
      representative.code === 'ALL'
        ? 'Todos'
        : `(${representative.code}) - ${representative.name}`,
  }));

  const clientOptions = clients?.map(client => ({
    value: client.code,
    label:
      client.code === 'ALL'
        ? 'Todos'
        : `(${client.code}) - ${client.company_name}`,
  }));

  function handleStateChange(
    stateValueReceived: string,
    stateLabelReceived: string,
  ): void {
    stateValue.current = stateValueReceived;
    stateLabel.current = stateLabelReceived;
    setRefreshStates(true);
  }

  function handleRepresentativeChange(
    representativeValueReceived: string,
    representativeLabelReceived: string,
  ): void {
    representativeValue.current = representativeValueReceived;
    representativeLabel.current = representativeLabelReceived;
    setRefreshRepresentatives(true);
  }

  function handleClientChange(
    clientValueReceived: string,
    clientLabelReceived: string,
  ): void {
    clientValue.current = clientValueReceived;
    clientLabel.current = clientLabelReceived;
    setRefreshClients(true);
  }

  function handleFilter(): React.ReactNode {
    if (user.user_type.type !== 'REP') {
      return (
        <FilterContent>
          <FilterWrapper>
            <Select
              label="Estado"
              options={stateOptions}
              value={{
                value: stateValue.current,
                label: stateLabel.current,
              }}
              darkBorder
              onSet={(e: any) => {
                handleStateChange(e.value, e.label);
              }}
            />
          </FilterWrapper>

          <FilterWrapper>
            <Select
              label="Representante"
              options={representativeOptions}
              value={{
                value: representativeValue.current,
                label: representativeLabel.current,
              }}
              darkBorder
              onSet={(e: any) => {
                handleRepresentativeChange(e.value, e.label);
              }}
            />
          </FilterWrapper>

          <FilterWrapper>
            <Select
              label="Cliente"
              options={clientOptions}
              value={{
                value: clientValue.current,
                label: clientLabel.current,
              }}
              darkBorder
              onSet={(e: any) => {
                handleClientChange(e.value, e.label);
              }}
            />
          </FilterWrapper>
        </FilterContent>
      );
    }

    return <></>;
  }

  return (
    <Container>
      {handleFilter()}
      <ChartWrapper>
        <SimplePieType
          label="Displays"
          pendingAmount={displayPendingProjects}
          finishedAmount={displayFinishedProjects}
          canceledAmount={displayCanceledProjects}
        />

        <SimplePieType
          label="Materiais"
          pendingAmount={materialPendingProjects}
          finishedAmount={materialFinishedProjects}
          canceledAmount={materialCanceledProjects}
        />

        <SimplePieType
          label="Pagamentos"
          pendingAmount={sponsorshipPendingProjects}
          finishedAmount={sponsorshipFinishedProjects}
          canceledAmount={sponsorshipCanceledProjects}
        />

        <SimplePieType
          label="Problemas"
          pendingAmount={adversityPendingProjects}
          finishedAmount={adversityFinishedProjects}
          canceledAmount={adversityCanceledProjects}
        />

        <SimplePieType
          label="Pastas"
          pendingAmount={folderPendingProjects}
          finishedAmount={folderFinishedProjects}
          canceledAmount={folderCanceledProjects}
        />

        <SimplePieType
          label="Amostras"
          pendingAmount={samplePendingProjects}
          finishedAmount={sampleFinishedProjects}
          canceledAmount={sampleCanceledProjects}
        />

        <SimplePieType
          label="Eventos"
          pendingAmount={eventPendingProjects}
          finishedAmount={eventFinishedProjects}
          canceledAmount={eventCanceledProjects}
        />
      </ChartWrapper>
    </Container>
  );
};

export default Statistic2;
