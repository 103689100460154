import React from 'react';
import { useLocation } from 'react-router-dom';

import SignIn from '~/pages/SignIn';
import Request from './Request';
import Reset from './Reset';

const ResetPassword: React.FC = () => {
  const { pathname } = useLocation();

  function getComponent(): React.ReactNode {
    if (pathname === '/resetpassword/request') {
      return <Request />;
    }
    if (pathname === '/resetpassword/reset') {
      return <Reset />;
    }

    return <SignIn />;
  }

  return <>{getComponent()}</>;
};

export default ResetPassword;
