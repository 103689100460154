import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { motion } from 'framer-motion';

export const AnimatedContainer = styled(motion.div)`
  width: 100%;
  min-height: 12rem;
  height: auto;
  display: flex;
  flex-shrink: 0;
`;

export const Navigation = styled.nav`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  grid-gap: 2.4rem 1.4rem;
`;

export const AnimatedCard = styled(motion.button)`
  ${({ theme }) => css`
    min-height: 9rem;
    width: 20rem;
    height: 10rem;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    background: ${theme.colors.background};
    border-radius: ${theme.radius.small};
    padding: 1.2rem;
    box-shadow: ${theme.shadows.default};
  `}
`;

export const AnimatedCardIcon = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.darkGrayishOrange};
    max-width: 7rem;
    max-height: 7rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${theme.radius.small};
    margin-top: -5rem;
    box-shadow: ${theme.shadows.default};

    svg {
      color: ${theme.colors.label};
      stroke: ${theme.colors.primary};
      stroke-width: 1.5;
      max-height: 2.5rem;
      height: 100%;
      width: auto;
    }
  `}
`;

export const AnimatedCardWrapper = styled.div`
  width: 100%;
`;

export const AnimatedCardAmount = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.darkGrey};
    font-size: ${theme.fontSizes.xLarge};
    margin-left: 1rem;
  `}
`;

export const AnimatedCardInfo = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.labelCard};
    font-size: ${theme.fontSizes.small};
    margin-left: 1rem;
    border-bottom: 0.15rem solid
      ${transparentize(0.9, theme.colors.darkModerateOrange)};
  `}
`;
