import styled, { css } from 'styled-components';

interface IContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored?: boolean;
}

export const Label = styled.label<IContainerProps>`
  ${({ theme, isFocused }) => css`
    font-size: ${theme.fontSizes.label};
    color: ${theme.colors.labelInput};

    ${isFocused &&
    css`
      color: ${theme.colors.primary};
    `}
  `}
`;

export const Container = styled.div<IContainerProps>`
  ${({ theme, isFocused, isFilled, isErrored }) => css`
    width: 100%;
    display: flex;
    background: ${theme.colors.lightGrey};
    border-radius: ${theme.radius.small};
    padding: 10px;
    border: 1px solid ${theme.colors.inputBorder};
    align-items: center;

    ${isFocused &&
    css`
      border: 1px solid ${theme.colors.primary};
      color: ${theme.colors.primary};
    `}

    ${isFilled &&
    css`
      color: ${theme.colors.primary};
    `}

    ${isErrored &&
    css`
      border: 1px solid ${theme.colors.error};
    `}
  `}
`;

export const InputText = styled.input`
  ${({ theme }) => css`
    flex: 1;
    border: 0;
    background: transparent;
    font-size: ${theme.fontSizes.input};
  `}
`;

export const PasswordButton = styled.button`
  ${({ theme }) => css`
    background-color: transparent;
    color: ${theme.colors.inputIcon};
  `}
`;
