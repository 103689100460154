import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { shade } from 'polished';

import { Container as ButtonContainer } from '~/components/Button/styles';

interface IButtonProps {
  backgroundColor: string;
}

export const AnimatedContainer = styled(motion.section)`
  ${({ theme }) => css`
    background: ${theme.colors.backgroundContainer};
    border-radius: ${theme.radius.default};
    box-shadow: ${theme.shadows.flat};
    padding: 2.4rem;
    min-height: 30rem;
    width: 100%;
    margin: 2.4rem 0;
  `}
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const TaskButtonWrapper = styled.div`
  width: 32rem;
  padding: 0 2.5rem 0 2.5rem;
  margin-bottom: 2rem;
`;

export const TaskButton = styled(ButtonContainer)<IButtonProps>`
  ${({ theme, backgroundColor }) => css`
    padding: 1.4rem 1.6rem;
    justify-content: space-between;
    width: 100%;
    height: 7rem;
    border-radius: ${theme.radius.default};

    :hover {
      background: ${shade(0.2, theme.colors.optionsHover)};
      transition: 0.2s;
    }

    ${backgroundColor &&
    css`
      background: ${backgroundColor};
    `}
  `}
`;

export const ProjectIcon = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.iconProjects};
  `}
`;

export const ProjectType = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.large};
    margin-left: 0.8rem;
  `}
`;

export const GoIcon = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    margin-left: 0.8rem;
  `}
`;
