import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { FaStore } from 'react-icons/fa';

import BreadCrumb from '~/components/BreadCrumb';
import StoreRelationshipInfo from '~/components/StoreRelationshipInfo';
import ExitButton from '~/components/Buttons/Exit';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import { Container, ButtonContainer } from './styles';

interface IStoreRelationship {
  id: string;
  code: string;
  erp_client_code: string;
  erp_rep_code: string;
  trade_name: string;
  client_contact_name: string;
  client_contact_phone: string;
  client_contact_email: string;
  note: string;
  store_relationship_category_id: string;
  created_at: string;
}

interface ILocation {
  code: string;
  name: string;
  company_name: string;
  document: string;
  address: string;
  city: string;
  state: string;
  rep_code: string;
  sales_channel_code: string;
}

const View: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [storeRelationship, setStoreRelationship] =
    useState<IStoreRelationship>();

  async function getStoreRelationship(client_code: string): Promise<void> {
    await api
      .get(`/storerelationships/client?erp_client_code=${client_code}`)
      .then(response => {
        setStoreRelationship(response.data);
      });
  }

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Registro de loja não localizado',
      });

      history.push('/storerelationships');
    } else if (
      user.user_type.type === 'REP' &&
      state.rep_code !== user.erp_rep_code
    ) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Usuário não permitido',
      });

      history.push('/storerelationships');
    } else {
      getStoreRelationship(state.code);
    }
  }, [addToast, history, state, user]);

  return (
    <Container>
      <BreadCrumb
        main="Lojas"
        path="/storerelationships"
        firstChildPath="/storerelationships"
        firstChild="Visualização Registro"
        mainIcon={FaStore}
        firstChildIcon={FaStore}
        mainColored
      />

      <StoreRelationshipInfo
        erp_rep_code={state?.rep_code ? state.rep_code : ''}
        erp_client_code={state?.code ? state.code : ''}
        store_relationship_id={storeRelationship?.id}
      />

      <ButtonContainer>
        <Link
          to={{
            pathname: '/storerelationships',
          }}
        >
          <ExitButton type="button" />
        </Link>
      </ButtonContainer>
    </Container>
  );
};

export default View;
