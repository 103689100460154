import React, { InputHTMLAttributes, useState, useCallback } from 'react';

import ErrorTooltip from '~/components/Tooltips/Error';

import { Container, Label, InputText } from './styles';

interface IPercentageProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  labelFor?: string;
  labelText: string;
  error?: string;
  defaultValue?: number;
}

const Percentage = React.forwardRef<HTMLInputElement, IPercentageProps>(
  (
    { name, labelFor, labelText, error, defaultValue }: IPercentageProps,
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleInputFocus = useCallback(() => {
      setIsFocused(!isFocused);
    }, [isFocused]);

    const handleInputBlur = useCallback(() => {
      setIsFocused(false);
    }, []);

    return (
      <>
        <Label isFocused={isFocused}>{labelText}</Label>

        <Container id={labelFor} isFocused={isFocused} isErrored={!!error}>
          <InputText
            name={name}
            type="text"
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            defaultValue={defaultValue}
            ref={ref}
            intlConfig={{ locale: 'pt-BR' }}
            allowNegativeValue={false}
            decimalsLimit={2}
            prefix="%"
            maxLength={6}
          />

          {error && <ErrorTooltip title={error} />}
        </Container>
      </>
    );
  },
);

export default Percentage;
