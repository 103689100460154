import React, { ButtonHTMLAttributes } from 'react';

import { FaTrashAlt } from 'react-icons/fa';

import { Container } from './styles';

export interface IDeletePreviousProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  iconSize?: number;
  marginTop?: string;
  marginLeft?: string;
  isDisabled?: boolean;
}

const DeletePrevious: React.FC<IDeletePreviousProps> = ({
  iconSize,
  ...rest
}) => {
  return (
    <Container {...rest}>
      <FaTrashAlt size={iconSize !== undefined ? iconSize : 20} />
    </Container>
  );
};

export default DeletePrevious;
