import React from 'react';

import InfoInput from '~/components/Inputs/Info';

import { Container, Content } from './styles';

interface IShippingTypeProps {
  shipping_type?: string;
}

const ShippingType: React.FC<IShippingTypeProps> = ({ shipping_type }) => {
  return (
    <Container>
      <Content>
        <InfoInput
          name="shipping_type"
          labelFor="shipping_type"
          labelText="Frete"
          type="text"
          defaultValue={shipping_type}
          disabled
        />
      </Content>
    </Container>
  );
};

export default ShippingType;
