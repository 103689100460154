import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { FaAngleDown } from 'react-icons/fa';

import InfoInput from '~/components/Inputs/Info';
import { MainContainer, Label, Container, Content, Wrapper } from './styles';

interface IDiscountPricesProps {
  gross_discount_price?: number;
  total_sponsorship_sheets_price?: number;
  total_tax_deductions_price?: number;
  net_discount_price?: number;
  canceled_discount_price?: number;
  granted_discount_price?: number;
  default_expanded?: boolean;
}

const DiscountPrices: React.FC<IDiscountPricesProps> = ({
  gross_discount_price,
  total_sponsorship_sheets_price,
  total_tax_deductions_price,
  net_discount_price,
  canceled_discount_price,
  granted_discount_price,
  default_expanded,
}) => {
  function getInitialDiscountPrice(): number {
    if (gross_discount_price && total_sponsorship_sheets_price) {
      return gross_discount_price - total_sponsorship_sheets_price;
    }

    if (gross_discount_price) {
      return gross_discount_price;
    }

    return 0;
  }

  function getPendingDiscountPrice(): number {
    const netDiscountPrice =
      net_discount_price !== undefined ? net_discount_price : 0;
    const grantedDiscountPrice =
      granted_discount_price !== undefined ? granted_discount_price : 0;
    const canceledDiscountPrice =
      canceled_discount_price !== undefined ? canceled_discount_price : 0;

    return netDiscountPrice - grantedDiscountPrice - canceledDiscountPrice;
  }

  return (
    <MainContainer>
      <Accordion defaultExpanded={default_expanded}>
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Label>Valores</Label>
        </AccordionSummary>

        <AccordionDetails>
          <Container>
            <Content>
              <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
                <InfoInput
                  name="initial_discount_price"
                  labelFor="initial_discount_price"
                  labelText="Valor Desconto Inicial"
                  type="text"
                  defaultValue={new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(getInitialDiscountPrice())}
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '50%' }}>
                <InfoInput
                  name="total_sponsorship_sheets_price"
                  labelFor="total_sponsorship_sheets_price"
                  labelText="Valor Total Chapas"
                  type="text"
                  defaultValue={
                    total_sponsorship_sheets_price
                      ? new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(total_sponsorship_sheets_price)
                      : new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(0)
                  }
                  disabled
                />
              </Wrapper>
            </Content>

            <Content>
              <Wrapper style={{ width: '33%', marginRight: '1rem' }}>
                <InfoInput
                  name="discount_price"
                  labelFor="discount_price"
                  labelText="Valor Desconto Bruto"
                  type="text"
                  defaultValue={
                    gross_discount_price
                      ? new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(gross_discount_price)
                      : new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(0)
                  }
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '33%', marginRight: '1rem' }}>
                <InfoInput
                  name="total_tax_deductions_price"
                  labelFor="total_tax_deductions_price"
                  labelText="Total Abatimentos Fiscais"
                  type="text"
                  defaultValue={
                    total_tax_deductions_price
                      ? new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(total_tax_deductions_price)
                      : new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(0)
                  }
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '33%' }}>
                <InfoInput
                  name="net_discount_price"
                  labelFor="net_discount_price"
                  labelText="Valor Desconto Líquido"
                  type="text"
                  defaultValue={
                    net_discount_price
                      ? new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(net_discount_price)
                      : new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(0)
                  }
                  disabled
                />
              </Wrapper>
            </Content>

            <Content>
              <Wrapper style={{ width: '33%', marginRight: '1rem' }}>
                <InfoInput
                  name="canceled_discount_price"
                  labelFor="canceled_discount_price"
                  labelText="Valor Desconto Cancelado"
                  type="text"
                  defaultValue={
                    canceled_discount_price
                      ? new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(canceled_discount_price)
                      : new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(0)
                  }
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '33%', marginRight: '1rem' }}>
                <InfoInput
                  name="granted_discount_price"
                  labelFor="granted_discount_price"
                  labelText="Valor Desconto Concedido"
                  type="text"
                  defaultValue={
                    granted_discount_price
                      ? new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(granted_discount_price)
                      : new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(0)
                  }
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '33%' }}>
                <InfoInput
                  name="pending_discount_price"
                  labelFor="pending_discount_price"
                  labelText="Valor Desconto Pendente"
                  type="text"
                  defaultValue={new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(getPendingDiscountPrice())}
                  disabled
                />
              </Wrapper>
            </Content>
          </Container>
        </AccordionDetails>
      </Accordion>
    </MainContainer>
  );
};

export default DiscountPrices;
