import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FcCheckmark, FcCancel } from 'react-icons/fc';
import { FaCogs, FaDonate } from 'react-icons/fa';
import { PropagateLoader } from 'react-spinners';

import BreadCrumb from '~/components/BreadCrumb';
import DefaultInput from '~/components/Inputs/Default';
import EditButton from '~/components/Buttons/Edit';
import DeleteButton from '~/components/Buttons/Delete';
import Pagination from '~/components/Pagination';
import NewButton from '~/components/Buttons/New';
import ConfirmDeleteDialog from '~/components/Dialogs/ConfirmDelete';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  FilterContent,
  Table,
  Thead,
  Tbody,
  Th,
  TbodyTr,
  Td,
  PercentageContent,
  ButtonsContent,
} from './styles';

interface ITaxDeductionType {
  id: string;
  name: string;
  active: boolean;
  percentage: number;
}

const AMOUNT_PER_PAGE = 5;

const List: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState('');
  const [lastRefresh, setLastRefresh] = useState(new Date());
  const [taxDeductionTypes, setTaxDeductionTypes] =
    useState<ITaxDeductionType[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [taxDeductionTypeIdDelete, setTaxDeductionTypeIdDelete] = useState('');
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [titleDelete, setTitleDelete] = useState('');
  const [subTitleDelete, setSubTitleDelete] = useState('');

  useEffect(() => {
    if (
      user.user_type.type !== 'ADM' &&
      user.user_type.type !== 'MK1' &&
      user.user_type.type !== 'MK2'
    ) {
      history.push('/configurations/main');
    }
  }, [history, user]);

  useEffect(() => {
    const loadTaxDeductionTypes = async () => {
      await api
        .get(
          `/configurations/taxdeductiontypes?page=${page}&filter=${filter}&amount=${AMOUNT_PER_PAGE}`,
        )
        .then(response => {
          setTaxDeductionTypes(response.data[0]);
          setCount(response.data[1]);
          setLoading(false);
        });
    };

    loadTaxDeductionTypes();
  }, [page, filter, lastRefresh]);

  const onDelete = useCallback(
    async (id: string) => {
      try {
        await api.delete(`/configurations/taxdeductiontypes/${id}`).then(() => {
          setPage(0);
          setLastRefresh(new Date());

          addToast({
            type: 'success',
            title: 'Tipo de abatimento fiscal excluído!',
            description: 'Tipo de abatimento fiscal excluído com sucesso.',
          });
        });
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
      setTaxDeductionTypeIdDelete('');
      setTitleDelete('');
      setSubTitleDelete('');
    },
    [addToast],
  );

  function handleTable(): React.ReactNode {
    if (taxDeductionTypes) {
      if (taxDeductionTypes.length > 0) {
        return (
          <Table>
            <Thead>
              <tr>
                <Th width="35%" textAlign="left">
                  Nome
                </Th>
                <Th width="35%" textAlign="center">
                  Percentual
                </Th>
                <Th width="20%" textAlign="center">
                  Situação
                </Th>
                <Th width="10%" textAlign="center" />
              </tr>
            </Thead>
            <Tbody>
              {loading && (
                <tr>
                  <Td colSpan={4} padding="1rem" textAlign="center">
                    <PropagateLoader color="#276c8d" />
                  </Td>
                </tr>
              )}
              {taxDeductionTypes?.map(taxDeductionType => (
                <TbodyTr key={taxDeductionType.id}>
                  <Td width="35%" textAlign="left" heading="NOME">
                    {taxDeductionType.name}
                  </Td>
                  <Td width="35%" textAlign="right" heading="PERCENTUAL">
                    <PercentageContent>
                      {`${new Intl.NumberFormat('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(taxDeductionType.percentage)} %`}
                    </PercentageContent>
                  </Td>
                  <Td width="20%" textAlign="center" heading="SITUAÇÃO">
                    {taxDeductionType.active ? (
                      <FcCheckmark size={22} />
                    ) : (
                      <FcCancel size={22} />
                    )}
                  </Td>
                  <Td
                    width="10%"
                    textAlign="center"
                    display="flex"
                    heading={' '}
                  >
                    <Link
                      to={{
                        pathname: `/configurations/taxdeductiontypes/edit/${taxDeductionType.id}`,
                        state: taxDeductionType,
                      }}
                    >
                      <EditButton />
                    </Link>

                    <DeleteButton
                      marginLeft="0.5rem"
                      onClick={() => {
                        setTaxDeductionTypeIdDelete(taxDeductionType.id);
                        setIsOpenDelete(true);
                        setTitleDelete(
                          `Deseja realmente excluir o tipo de abatimento fiscal ${taxDeductionType.name}?`,
                        );
                        setSubTitleDelete(
                          'Esta operação não poderá ser desfeita!',
                        );
                      }}
                    />
                  </Td>
                </TbodyTr>
              ))}
            </Tbody>
          </Table>
        );
      }
    }

    return (
      <Table>
        <Thead>
          <tr>
            <Th width="100%" textAlign="center" />
          </tr>
        </Thead>
        <Tbody>
          <TbodyTr>
            <Td width="100%" textAlign="center">
              Nenhuma informação disponível
            </Td>
          </TbodyTr>
        </Tbody>
      </Table>
    );
  }

  return (
    <Container style={{ minHeight: 600 }}>
      <BreadCrumb
        main="Configurações"
        path="/configurations/main"
        firstChild="Tipos de Abatimentos Fiscais"
        mainIcon={FaCogs}
        firstChildIcon={FaDonate}
        mainColored
      />

      <FilterContent>
        <DefaultInput
          name="search"
          labelText="Pesquisar"
          type="text"
          onChange={event => {
            setFilter(event.target.value);
            setPage(0);
          }}
        />
      </FilterContent>

      {handleTable()}

      <ButtonsContent>
        <Pagination
          page={page}
          count={count}
          amountPerPage={AMOUNT_PER_PAGE}
          setPage={setPage}
        />

        <Link to="/configurations/taxdeductiontypes/new">
          <NewButton />
        </Link>
      </ButtonsContent>

      <ConfirmDeleteDialog
        isOpen={isOpenDelete}
        title={titleDelete}
        subTitle={subTitleDelete}
        setIsOpen={setIsOpenDelete}
        onClose={() => {
          onDelete(taxDeductionTypeIdDelete);
        }}
      />
    </Container>
  );
};

export default List;
