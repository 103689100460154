import React, { useState } from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import Statistic1 from './Statistic1';
import Statistic2 from './Statistic2';
import Statistic3 from './Statistic3';
import Statistic4 from './Statistic4';
import Statistic5 from './Statistic5';
import Statistic6 from './Statistic6';

import { useAuth } from '~/hooks/auth';

import { Container, TabTitle, TabFirst, TabSecond, TabThird } from './styles';

const Statistics: React.FC = () => {
  const { user } = useAuth();
  const [tabIndex, setTabIndex] = useState(0);

  function handleStatistic1TabList(): React.ReactNode {
    return (
      <Tab>
        <TabTitle>Geral</TabTitle>
      </Tab>
    );
  }

  function handleStatistic1TabPanel(): React.ReactNode {
    return (
      <TabPanel>
        <TabFirst>
          <Statistic1 />
        </TabFirst>
      </TabPanel>
    );
  }

  function handleStatistic2TabList(): React.ReactNode {
    return (
      <Tab>
        <TabTitle>Status</TabTitle>
      </Tab>
    );
  }

  function handleStatistic2TabPanel(): React.ReactNode {
    return (
      <TabPanel>
        <TabSecond>
          <Statistic2 />
        </TabSecond>
      </TabPanel>
    );
  }

  function handleStatistic3TabList(): React.ReactNode {
    if (
      user.user_type.type === 'ADM' ||
      user.user_type.type === 'GMK' ||
      user.user_type.type === 'MK1' ||
      user.user_type.type === 'MK2' ||
      user.user_type.type === 'DGR' ||
      user.user_type.type === 'DCM'
    ) {
      return (
        <Tab>
          <TabTitle>Valores</TabTitle>
        </Tab>
      );
    }

    return <></>;
  }

  function handleStatistic3TabPanel(): React.ReactNode {
    if (
      user.user_type.type === 'ADM' ||
      user.user_type.type === 'GMK' ||
      user.user_type.type === 'MK1' ||
      user.user_type.type === 'MK2' ||
      user.user_type.type === 'DGR' ||
      user.user_type.type === 'DCM'
    ) {
      return (
        <TabPanel>
          <TabThird>
            <Statistic3 />
          </TabThird>
        </TabPanel>
      );
    }

    return <></>;
  }

  function handleStatistic4TabList(): React.ReactNode {
    if (
      user.user_type.type === 'ADM' ||
      user.user_type.type === 'GMK' ||
      user.user_type.type === 'MK1' ||
      user.user_type.type === 'MK2' ||
      user.user_type.type === 'DGR' ||
      user.user_type.type === 'DCM'
    ) {
      return (
        <Tab>
          <TabTitle>Geral Rebate</TabTitle>
        </Tab>
      );
    }

    return <></>;
  }

  function handleStatistic4TabPanel(): React.ReactNode {
    if (
      user.user_type.type === 'ADM' ||
      user.user_type.type === 'GMK' ||
      user.user_type.type === 'MK1' ||
      user.user_type.type === 'MK2' ||
      user.user_type.type === 'DGR' ||
      user.user_type.type === 'DCM'
    ) {
      return (
        <TabPanel>
          <TabThird>
            <Statistic4 />
          </TabThird>
        </TabPanel>
      );
    }

    return <></>;
  }

  function handleStatistic5TabList(): React.ReactNode {
    if (
      user.user_type.type === 'ADM' ||
      user.user_type.type === 'GMK' ||
      user.user_type.type === 'MK1' ||
      user.user_type.type === 'MK2' ||
      user.user_type.type === 'DGR' ||
      user.user_type.type === 'DCM'
    ) {
      return (
        <Tab>
          <TabTitle>Status Rebate</TabTitle>
        </Tab>
      );
    }

    return <></>;
  }

  function handleStatistic5TabPanel(): React.ReactNode {
    if (
      user.user_type.type === 'ADM' ||
      user.user_type.type === 'GMK' ||
      user.user_type.type === 'MK1' ||
      user.user_type.type === 'MK2' ||
      user.user_type.type === 'DGR' ||
      user.user_type.type === 'DCM'
    ) {
      return (
        <TabPanel>
          <TabThird>
            <Statistic5 />
          </TabThird>
        </TabPanel>
      );
    }

    return <></>;
  }

  function handleStatistic6TabList(): React.ReactNode {
    if (
      user.user_type.type === 'ADM' ||
      user.user_type.type === 'GMK' ||
      user.user_type.type === 'MK1' ||
      user.user_type.type === 'MK2' ||
      user.user_type.type === 'DGR' ||
      user.user_type.type === 'DCM'
    ) {
      return (
        <Tab>
          <TabTitle>Valores Rebate</TabTitle>
        </Tab>
      );
    }

    return <></>;
  }

  function handleStatistic6TabPanel(): React.ReactNode {
    if (
      user.user_type.type === 'ADM' ||
      user.user_type.type === 'GMK' ||
      user.user_type.type === 'MK1' ||
      user.user_type.type === 'MK2' ||
      user.user_type.type === 'DGR' ||
      user.user_type.type === 'DCM'
    ) {
      return (
        <TabPanel>
          <TabThird>
            <Statistic6 />
          </TabThird>
        </TabPanel>
      );
    }

    return <></>;
  }

  return (
    <Container>
      <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <TabList>
          {handleStatistic1TabList()}
          {handleStatistic2TabList()}
          {handleStatistic3TabList()}
          {handleStatistic4TabList()}
          {handleStatistic5TabList()}
          {handleStatistic6TabList()}
        </TabList>
        {handleStatistic1TabPanel()}
        {handleStatistic2TabPanel()}
        {handleStatistic3TabPanel()}
        {handleStatistic4TabPanel()}
        {handleStatistic5TabPanel()}
        {handleStatistic6TabPanel()}
      </Tabs>
    </Container>
  );
};

export default Statistics;
