import styled, { css } from 'styled-components';

interface IDefaultTooltipProps {
  background?: string;
  borderColor?: string;
}

export const Container = styled.div<IDefaultTooltipProps>`
  ${({ theme, background, borderColor }) => css`
    position: relative;

    span {
      width: 160px;
      background: ${background || theme.colors.secondary};
      padding: 8px 0 8px 12px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      bottom: calc(100% + 12px);
      left: 50%;
      transform: translateX(-50%);
      transition: opacity 0.4s;

      &::before {
        content: '';
        border-style: solid;
        border-color: ${borderColor || theme.colors.secondary} transparent;
        border-width: 6px 6px 0 6px;
        top: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &:hover span {
      opacity: 1;
      visibility: visible;
    }
  `}
`;
