import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.lightGrey};
    font-size: ${theme.fontSizes.xxxLarge};
    color: ${theme.colors.darkGrey};
    width: 100%;
    height: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${theme.radius.small};
  `}
`;
