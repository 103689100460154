import React from 'react';
import { Switch, useLocation } from 'react-router-dom';

import Route from './Route';

import SignIn from '~/pages/SignIn';
import ResetPassword from '~/pages/ResetPassword';
import Dashboard from '~/pages/Dashboard';
import Users from '~/pages/Users';
import ProjectsMain from '~/pages/Projects';
import ProjectsDisplays from '~/pages/Projects/Displays';
import ProjectsMaterials from '~/pages/Projects/Materials';
import ProjectsSponsorships from '~/pages/Projects/Sponsorships';
import ProjectsAdversities from '~/pages/Projects/Adversities';
import ProjectsFolders from '~/pages/Projects/Folders';
import ProjectsSamples from '~/pages/Projects/Samples';
import ProjectsEvents from '~/pages/Projects/Events';
import ProjectsAll from '~/pages/Projects/All';
import ItemsMain from '~/pages/Items';
import ItemsDisplays from '~/pages/Items/Displays';
import ItemsMaterials from '~/pages/Items/Materials';
import ItemsSponsorships from '~/pages/Items/Sponsorships';
import ItemsAdversities from '~/pages/Items/Adversities';
import ItemsFolders from '~/pages/Items/Folders';
import ItemsSamples from '~/pages/Items/Samples';
import ItemsEvents from '~/pages/Items/Events';
import StoreRelationships from '~/pages/StoreRelationships';
import ConfigurationsMain from '~/pages/Configurations';
import ConfigurationsThirdPartyLogisticsProviders from '~/pages/Configurations/ThirdPartyLogisticsProviders';
import ConfigurationsTaxDeductionTypes from '~/pages/Configurations/TaxDeductionTypes';
import ConfigurationsEventCostTypes from '~/pages/Configurations/EventCostTypes';
import ConfigurationsGiftTypes from '~/pages/Configurations/GiftTypes';
import ConfigurationsStoreRelationshipCategories from '~/pages/Configurations/StoreRelationshipCategories';
import ConfigurationsStoreRelationshipTopicTypes from '~/pages/Configurations/StoreRelationshipTopicTypes';
import ManagementMain from '~/pages/Management';
import ManagementProjectEditing from '~/pages/Management/ProjectEditing';
import ManagementProjectDeletion from '~/pages/Management/ProjectDeletion';
import ManagementLocalClients from '~/pages/Management/LocalClients';
import ManagementMobileAccesses from '~/pages/Management/MobileAccesses';
import ManagementGeneralSettings from '~/pages/Management/GeneralSettings';

const Routes: React.FC = () => {
  const location = useLocation();
  const { pathname } = location;
  const { search } = location;

  return (
    <Switch location={location} key={pathname}>
      <Route path="/" exact component={SignIn} />
      <Route path="/externalsignin" exact component={SignIn} />
      <Route path="/newproject" search={search} />
      <Route path="/resetpassword" component={ResetPassword} />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/users" component={Users} isPrivate />
      <Route path="/projects/main" component={ProjectsMain} isPrivate />
      <Route path="/projects/displays" component={ProjectsDisplays} isPrivate />
      <Route
        path="/projects/materials"
        component={ProjectsMaterials}
        isPrivate
      />
      <Route
        path="/projects/sponsorships"
        component={ProjectsSponsorships}
        isPrivate
      />
      <Route
        path="/projects/adversities"
        component={ProjectsAdversities}
        isPrivate
      />
      <Route path="/projects/folders" component={ProjectsFolders} isPrivate />
      <Route path="/projects/samples" component={ProjectsSamples} isPrivate />
      <Route path="/projects/events" component={ProjectsEvents} isPrivate />
      <Route path="/projects/all" component={ProjectsAll} isPrivate />
      <Route path="/items/main" component={ItemsMain} isPrivate />
      <Route path="/items/displays" component={ItemsDisplays} isPrivate />
      <Route path="/items/materials" component={ItemsMaterials} isPrivate />
      <Route
        path="/items/sponsorships"
        component={ItemsSponsorships}
        isPrivate
      />
      <Route path="/items/adversities" component={ItemsAdversities} isPrivate />
      <Route path="/items/folders" component={ItemsFolders} isPrivate />
      <Route path="/items/samples" component={ItemsSamples} isPrivate />
      <Route path="/items/events" component={ItemsEvents} isPrivate />
      <Route
        path="/storerelationships"
        component={StoreRelationships}
        isPrivate
      />
      <Route
        path="/configurations/main"
        component={ConfigurationsMain}
        isPrivate
      />
      <Route
        path="/configurations/thirdpartylogisticsproviders"
        component={ConfigurationsThirdPartyLogisticsProviders}
        isPrivate
      />
      <Route
        path="/configurations/taxdeductiontypes"
        component={ConfigurationsTaxDeductionTypes}
        isPrivate
      />
      <Route
        path="/configurations/eventcosttypes"
        component={ConfigurationsEventCostTypes}
        isPrivate
      />
      <Route
        path="/configurations/gifttypes"
        component={ConfigurationsGiftTypes}
        isPrivate
      />
      <Route
        path="/configurations/storerelationshipcategories"
        component={ConfigurationsStoreRelationshipCategories}
        isPrivate
      />
      <Route
        path="/configurations/storerelationshiptopictypes"
        component={ConfigurationsStoreRelationshipTopicTypes}
        isPrivate
      />
      <Route path="/management/main" component={ManagementMain} isPrivate />
      <Route
        path="/management/projectediting"
        component={ManagementProjectEditing}
        isPrivate
      />
      <Route
        path="/management/projectdeletion"
        component={ManagementProjectDeletion}
        isPrivate
      />
      <Route
        path="/management/localclients"
        component={ManagementLocalClients}
        isPrivate
      />
      <Route
        path="/management/mobileaccesses"
        component={ManagementMobileAccesses}
        isPrivate
      />
      <Route
        path="/management/generalsettings"
        component={ManagementGeneralSettings}
        isPrivate
      />
      <Route path={pathname} />
    </Switch>
  );
};

export default Routes;
