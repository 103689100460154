import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

interface IPathIconProps {
  isColored?: boolean;
}

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.2rem 1.6rem 1rem;
    width: 100%;
    border-radius: ${theme.radius.default};
    background: ${theme.colors.lightGrey};
    margin-bottom: 30px;

    @media (max-width: 940px) {
      flex-direction: column;
    }
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.grey};
    font-size: ${theme.fontSizes.tiny};
    text-align: left;
    margin-right: 1rem;
    text-decoration: none;
    display: inline-flex;
    justify-content: flex-start;
  `}
`;

export const WrapperCrumbs = styled(Wrapper)`
  &:hover {
    transform: scale(1.2, 1.2);
  }
`;

export const LinkPath = styled(Link)`
  display: flex;
`;

export const PathIcon = styled.div<IPathIconProps>`
  ${({ theme, isColored }) => css`
    color: ${theme.colors.darkGrey};

    ${isColored &&
    css`
      color: ${theme.colors.secondary};
    `}
  `}
`;

export const PathName = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.darkGrey};
    font-size: ${theme.fontSizes.default};
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5rem;
  `}
`;

export const AngleIcon = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.darkGrey};
    margin-right: 1rem;
  `}
`;
