import styled, { css } from 'styled-components';

import { IEditProps } from '.';

export const Container = styled.button<IEditProps>`
  ${({ theme }) => css`
    background-color: ${theme.colors.edit};
    color: #fff;
    padding: 8px;
    border-radius: 50%;
    box-shadow: 1px 1px 1px ${theme.colors.lightGrey};

    &:hover {
      transform: scale(1.2, 1.2);
    }
  `}
`;
