import styled, { css } from 'styled-components';
import { shade } from 'polished';

import { IDeleteAltProps } from '.';

export const Container = styled.button<IDeleteAltProps>`
  ${({ theme }) => css`
    width: 18rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.2rem 6rem;
    background-color: ${theme.colors.danger};
    color: #fff;
    border-radius: ${theme.radius.default};
    font-size: ${theme.fontSizes.button};
    font-weight: 400;

    :hover {
      background: ${shade(0.2, theme.colors.danger)};
      transition: 0.2s;
    }
  `}
`;
