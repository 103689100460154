import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { FaAngleDown } from 'react-icons/fa';

import InfoInput from '~/components/Inputs/Info';
import TextArea from '~/components/TextArea';

import { MainContainer, Label, Container, Content, Wrapper } from './styles';

interface IMainProps {
  code?: string;
  item?: string;
  note?: string;
  rebate_campaign?: boolean;
  sponsorship_art_approval?: boolean;
  sponsorship_completion_files?: boolean;
  custom_project?: boolean;
  note_custom_project?: string;
  shipping_type?: string;
  first_request?: string;
  default_expanded?: boolean;
}

const Main: React.FC<IMainProps> = ({
  code,
  item,
  note,
  rebate_campaign,
  sponsorship_art_approval,
  sponsorship_completion_files,
  custom_project,
  note_custom_project,
  shipping_type,
  first_request,
  default_expanded,
}) => {
  function handleRebateCampaign(): React.ReactNode {
    if (rebate_campaign !== undefined) {
      return (
        <Wrapper style={{ width: '33%', marginRight: '1rem' }}>
          <InfoInput
            name="rebate_campaign"
            labelFor="rebate_campaign"
            labelText="Campanha de Rebate"
            type="text"
            defaultValue={rebate_campaign ? 'Sim' : 'Não'}
            disabled
          />
        </Wrapper>
      );
    }

    return <></>;
  }

  function handleSponsorshipArtApproval(): React.ReactNode {
    if (sponsorship_art_approval !== undefined) {
      return (
        <Wrapper style={{ width: '33%', marginRight: '1rem' }}>
          <InfoInput
            name="sponsorship_art_approval"
            labelFor="sponsorship_art_approval"
            labelText="Exige Aprovação de Arte"
            type="text"
            defaultValue={sponsorship_art_approval ? 'Sim' : 'Não'}
            disabled
          />
        </Wrapper>
      );
    }

    return <></>;
  }

  function handleSponsorshipCompletionFiles(): React.ReactNode {
    if (sponsorship_completion_files !== undefined) {
      return (
        <Wrapper style={{ width: '33%' }}>
          <InfoInput
            name="sponsorship_completion_files"
            labelFor="sponsorship_completion_files"
            labelText="Exige Anexos de Conclusão"
            type="text"
            defaultValue={sponsorship_completion_files ? 'Sim' : 'Não'}
            disabled
          />
        </Wrapper>
      );
    }

    return <></>;
  }

  function handleFirstExtraFields(): React.ReactNode {
    if (
      rebate_campaign !== undefined ||
      sponsorship_art_approval !== undefined ||
      sponsorship_completion_files !== undefined
    ) {
      return (
        <Content>
          {handleRebateCampaign()}
          {handleSponsorshipArtApproval()}
          {handleSponsorshipCompletionFiles()}
        </Content>
      );
    }

    return <></>;
  }

  function handleCustomProject(): React.ReactNode {
    return (
      <Content>
        <Wrapper style={{ width: '100%' }}>
          <InfoInput
            name="custom_project"
            labelFor="custom_project"
            labelText="Projeto Personalizado"
            type="text"
            defaultValue={custom_project ? 'Sim' : 'Não'}
            disabled
          />
        </Wrapper>
      </Content>
    );
  }

  function handleNoteCustomProject(): React.ReactNode {
    if (custom_project && note_custom_project !== undefined) {
      return (
        <Content>
          <Wrapper style={{ width: '100%' }}>
            <TextArea
              name="note_custom_project"
              labelFor="note_custom_project"
              labelText="Observação Projeto Personalizado"
              defaultValue={note_custom_project}
              maxLength={1000}
              disabled
            />
          </Wrapper>
        </Content>
      );
    }

    return <></>;
  }

  function handleSecondExtraFields(): React.ReactNode {
    if (custom_project !== undefined) {
      return (
        <>
          {handleCustomProject()}
          {handleNoteCustomProject()}
        </>
      );
    }

    return <></>;
  }

  function handleShippingType(): React.ReactNode {
    if (shipping_type !== undefined) {
      return (
        <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
          <InfoInput
            name="shipping_type"
            labelFor="shipping_type"
            labelText="Frete"
            type="text"
            defaultValue={shipping_type}
            disabled
          />
        </Wrapper>
      );
    }

    return <></>;
  }

  function handleFirstRequest(): React.ReactNode {
    if (first_request !== undefined) {
      return (
        <Wrapper style={{ width: '50%' }}>
          <InfoInput
            name="first_request"
            labelFor="first_request"
            labelText="1º Pedido"
            type="text"
            defaultValue={first_request}
            disabled
          />
        </Wrapper>
      );
    }

    return <></>;
  }

  function handleThirdExtraFields(): React.ReactNode {
    if (shipping_type !== undefined || first_request !== undefined) {
      return (
        <Content>
          {handleShippingType()}
          {handleFirstRequest()}
        </Content>
      );
    }

    return <></>;
  }

  return (
    <MainContainer>
      <Accordion defaultExpanded={default_expanded}>
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Label>Informações Gerais</Label>
        </AccordionSummary>

        <AccordionDetails>
          <Container>
            <Content>
              <Wrapper style={{ width: '33%', marginRight: '1rem' }}>
                <InfoInput
                  name="code"
                  labelFor="code"
                  labelText="Código"
                  type="text"
                  defaultValue={code}
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '67%' }}>
                <InfoInput
                  name="item"
                  labelFor="item"
                  labelText="Item"
                  type="text"
                  defaultValue={item}
                  disabled
                />
              </Wrapper>
            </Content>

            {handleFirstExtraFields()}

            {handleSecondExtraFields()}

            {handleThirdExtraFields()}

            <Content>
              <Wrapper style={{ width: '100%' }}>
                <TextArea
                  name="note"
                  labelFor="note"
                  labelText="Observação Geral"
                  defaultValue={note}
                  maxLength={1000}
                  disabled
                />
              </Wrapper>
            </Content>
          </Container>
        </AccordionDetails>
      </Accordion>
    </MainContainer>
  );
};

export default Main;
