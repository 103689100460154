import styled, { css } from 'styled-components';

interface IContainerProps {
  isFocused: boolean;
  isErrored?: boolean;
}

interface ITextAreaProps {
  customHeight?: string;
}

export const Label = styled.label<IContainerProps>`
  ${({ theme, isFocused }) => css`
    font-size: ${theme.fontSizes.small};
    font-weight: ${theme.weight.veryStrong};

    ${isFocused &&
    css`
      color: ${theme.colors.primary};
    `}
  `}
`;

export const Container = styled.div<IContainerProps>`
  ${({ theme, isFocused, isErrored }) => css`
    width: 100%;
    display: flex;
    background: ${theme.colors.lightGrey};
    border-radius: ${theme.radius.small};
    padding: 10px;
    border: 1px solid ${theme.colors.inputBorder};
    align-items: center;

    ${isFocused &&
    css`
      border: 1px solid ${theme.colors.primary};
      color: ${theme.colors.primary};
    `}

    ${isErrored &&
    css`
      border: 1px solid ${theme.colors.error};
    `}
  `}
`;

export const Text = styled.textarea<ITextAreaProps>`
  ${({ customHeight }) => css`
    width: 100%;
    border: none;
    background-color: transparent;
    min-height: 10rem;
    max-height: 10rem;
    resize: none;
    font-size: 1.4rem;

    ${customHeight &&
    css`
      min-height: ${customHeight};
      max-height: ${customHeight};
    `}
  `}
`;
