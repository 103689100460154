import React, { ButtonHTMLAttributes } from 'react';

import { FaWrench } from 'react-icons/fa';

import { Container } from './styles';

export interface IFixProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  marginLeft?: string;
}

const Fix: React.FC<IFixProps> = ({ ...rest }) => {
  return (
    <Container {...rest}>
      <FaWrench size={16} />
    </Container>
  );
};

export default Fix;
