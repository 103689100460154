import React, { ButtonHTMLAttributes } from 'react';

import { FaEdit } from 'react-icons/fa';

import { Container } from './styles';

export type IEditProps = ButtonHTMLAttributes<HTMLButtonElement>;

const Edit: React.FC<IEditProps> = ({ ...rest }) => {
  return (
    <Container {...rest}>
      <FaEdit size={16} />
    </Container>
  );
};

export default Edit;
