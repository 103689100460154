import React from 'react';

import Sponsorship09 from './Types/Sponsorships/09';

interface ISpecificProps {
  project_id: string;
  type: string;
}

const Specific: React.FC<ISpecificProps> = ({ project_id, type }) => {
  function handleType(): React.ReactNode | null {
    if (type === 'sponsorship_09') {
      return <Sponsorship09 project_id={project_id} />;
    }

    return null;
  }

  return <>{handleType()}</>;
};

export default Specific;
