import React, { createContext, useState, useCallback, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import filesize from 'filesize';

import api from '~/services/api';

export interface IPost {
  file_temp_id: string;
  name: string;
  size: number;
  key: string;
  url: string;
}

export interface IFile {
  id: string;
  name: string;
  readableSize: string;
  type: string;
  uploaded?: boolean;
  preview: string;
  file: File | null;
  progress?: number;
  error?: boolean;
  url: string;
}

interface IFileContextData {
  uploadedFiles: IFile[];
  deleteFile(id: string): void;
  handleUpload(
    file: any,
    type: string,
    projectTempId: string,
    projectType: string,
    sequence: number,
    userId: string,
  ): void;
}

const FileContext = createContext<IFileContextData>({} as IFileContextData);

const FileProvider: React.FC = ({ children }) => {
  const [uploadedFiles, setUploadedFiles] = useState<IFile[]>([]);

  const updateFile = useCallback((id, data) => {
    setUploadedFiles(state =>
      state.map(file => (file.id === id ? { ...file, ...data } : file)),
    );
  }, []);

  const processUpload = useCallback(
    (
      uploadedFile: IFile,
      type: string,
      projectTempId: string,
      projectType: string,
      sequence: number,
      userId: string,
    ) => {
      const data = new FormData();
      if (uploadedFile.file) {
        data.append('type', type);
        data.append('project_temp_id', projectTempId);
        data.append('project_type', projectType);
        data.append('sequence', String(sequence));
        data.append('file_temp_id', uploadedFile.id);
        data.append('user_id', userId);
        data.append('file', uploadedFile.file, uploadedFile.name);
      }

      api
        .post('/projectfilesreceived', data, {
          onUploadProgress: progressEvent => {
            const progress: number = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );

            updateFile(uploadedFile.id, { progress });
          },
        })
        .then(response => {
          updateFile(uploadedFile.id, {
            uploaded: true,
            id: response.data.id,
            url: response.data.url,
          });
        })
        .catch(err => {
          console.log(err);
          updateFile(uploadedFile.id, {
            error: true,
          });
        });
    },
    [updateFile],
  );

  const handleUpload = useCallback(
    (
      files: File[],
      type: string,
      projectTempId: string,
      projectType: string,
      sequence: number,
      userId: string,
    ) => {
      const newUploadedFiles: IFile[] = files.map((file: File) => ({
        file,
        id: uuidv4(),
        name: file.name,
        readableSize: filesize(file.size),
        type: file.type,
        preview: URL.createObjectURL(file),
        progress: 0,
        uploaded: false,
        error: false,
        url: '',
      }));

      setUploadedFiles(state => state.concat(newUploadedFiles));
      newUploadedFiles.forEach(upFile =>
        processUpload(
          upFile,
          type,
          projectTempId,
          projectType,
          sequence,
          userId,
        ),
      );
    },
    [processUpload],
  );

  const deleteFile = useCallback((id: string) => {
    api.delete(`projectfilesreceived/${id}`);
    setUploadedFiles(state => state.filter(file => file.id !== id));
  }, []);

  return (
    <FileContext.Provider value={{ uploadedFiles, deleteFile, handleUpload }}>
      {children}
    </FileContext.Provider>
  );
};

function useFiles(): IFileContextData {
  const context = useContext(FileContext);

  if (!context) {
    throw new Error('useFiles deve ser usado dentro de um FileProvider');
  }

  return context;
}

export { FileProvider, useFiles };
