import React, { useState, useEffect } from 'react';

import api from '~/services/api';

import TextArea from '~/components/TextArea';

import { Container, Content } from './styles';

interface IValidationProps {
  sequence: number;
  type: string;
  project_id: string;
}

interface IValidationApproved {
  sequence: number;
  description: string;
  status: string;
  visible: boolean;
  note: string;
  reason: string;
  project_id: string;
  user_id: string;
  user: {
    name: string;
  };
  project_procedure_id: string;
}

interface IValidationCanceled {
  sequence: number;
  description: string;
  status: string;
  visible: boolean;
  note: string;
  reason: string;
  project_id: string;
  user_id: string;
  user: {
    name: string;
  };
  project_procedure_id: string;
}

const Validation: React.FC<IValidationProps> = ({
  sequence,
  type,
  project_id,
}) => {
  const [validationApproved, setValidationApproved] =
    useState<IValidationApproved>();
  const [validationCanceled, setValidationCanceled] =
    useState<IValidationCanceled>();
  const [validation, setValidation] = useState('');

  useEffect(() => {
    if (validationApproved) {
      if (validationApproved.status) {
        if (validationApproved.status === 'Aprovado') {
          setValidation(`Aprovado por ${validationApproved.user.name}`);
        }
      }
    }
  }, [validationApproved]);

  useEffect(() => {
    if (validationCanceled) {
      if (validationCanceled.status) {
        if (validationCanceled.status === 'Cancelado') {
          setValidation(
            `Rejeitado por ${validationCanceled.user.name} - ` +
              `Motivo: ${validationCanceled.reason}`,
          );
        }
      }
    }
  }, [validationCanceled]);

  useEffect(() => {
    api
      .get(
        `/projectrecords/statusprojectprocedure?` +
          `status=Aprovado&type=${type}&sequence=${sequence}&project_id=${project_id}`,
      )
      .then(response => {
        setValidationApproved(response.data);
      });

    api
      .get(
        `/projectrecords/statusprojectprocedure?` +
          `status=Cancelado&type=${type}&sequence=${sequence}&project_id=${project_id}`,
      )
      .then(response => {
        setValidationCanceled(response.data);
      });
  }, [type, sequence, project_id]);

  return (
    <Container>
      <Content>
        <TextArea
          name="validation"
          labelFor="validation"
          labelText="Validação"
          value={validation}
          maxLength={1000}
          disabled
        />
      </Content>
    </Container>
  );
};

export default Validation;
