import React from 'react';

import { IconBaseProps } from 'react-icons';
import { FaHome, FaAngleDoubleRight } from 'react-icons/fa';

import {
  Container,
  Wrapper,
  WrapperCrumbs,
  LinkPath,
  PathIcon,
  PathName,
  AngleIcon,
} from './styles';

interface IBreadCrumbProps {
  main: string;
  path: string;
  firstChild?: string;
  firstChildPath?: string;
  firstChildState?: any;
  secondChild?: string;
  secondChildPath?: string;
  secondChildState?: any;
  thirdChild?: string;
  thirdChildPath?: string;
  thirdChildState?: any;
  fourthChild?: string;
  mainIcon?: React.ComponentType<IconBaseProps>;
  firstChildIcon?: React.ComponentType<IconBaseProps>;
  secondChildIcon?: React.ComponentType<IconBaseProps>;
  thirdChildIcon?: React.ComponentType<IconBaseProps>;
  fourthChildIcon?: React.ComponentType<IconBaseProps>;
  mainColored?: boolean;
  firstChildColored?: boolean;
  secondChildColored?: boolean;
  thirdChildColored?: boolean;
  fourthChildColored?: boolean;
}

const BreadCrumb: React.FC<IBreadCrumbProps> = ({
  main,
  path,
  firstChild,
  firstChildPath,
  firstChildState,
  secondChild,
  secondChildPath,
  secondChildState,
  thirdChild,
  thirdChildPath,
  thirdChildState,
  fourthChild,
  mainIcon: MainIcon,
  firstChildIcon: FirstChildIcon,
  secondChildIcon: SecondChildIcon,
  thirdChildIcon: ThirdChildIcon,
  fourthChildIcon: FourthChildIcon,
  mainColored,
  firstChildColored,
  secondChildColored,
  thirdChildColored,
  fourthChildColored,
}) => {
  function getDashboard(): JSX.Element {
    return (
      <WrapperCrumbs>
        <LinkPath to="/dashboard">
          <PathIcon isColored>
            <FaHome size={24} />
          </PathIcon>
          <PathName>Dashboard</PathName>
        </LinkPath>
      </WrapperCrumbs>
    );
  }

  function getMain(): JSX.Element {
    if (firstChild) {
      return (
        <WrapperCrumbs>
          <LinkPath to={path}>
            {MainIcon && (
              <PathIcon isColored={mainColored}>
                <MainIcon size={24} />
              </PathIcon>
            )}
            <PathName>{main}</PathName>
          </LinkPath>
        </WrapperCrumbs>
      );
    }

    return (
      <Wrapper>
        {MainIcon && (
          <PathIcon isColored={mainColored}>
            <MainIcon size={24} />
          </PathIcon>
        )}
        <PathName>{main}</PathName>
      </Wrapper>
    );
  }

  function getFirstChild(): JSX.Element {
    if (secondChild) {
      return (
        <WrapperCrumbs>
          <LinkPath
            to={{
              pathname: firstChildPath !== undefined ? firstChildPath : '#',
              state: firstChildState,
            }}
          >
            {FirstChildIcon && (
              <PathIcon isColored={firstChildColored}>
                <FirstChildIcon size={24} />
              </PathIcon>
            )}
            <PathName>{firstChild}</PathName>
          </LinkPath>
        </WrapperCrumbs>
      );
    }

    return (
      <Wrapper>
        {FirstChildIcon && (
          <PathIcon isColored={firstChildColored}>
            <FirstChildIcon size={24} />
          </PathIcon>
        )}
        <PathName>{firstChild}</PathName>
      </Wrapper>
    );
  }

  function getSecondChild(): JSX.Element {
    if (thirdChild) {
      return (
        <WrapperCrumbs>
          <LinkPath
            to={{
              pathname: secondChildPath !== undefined ? secondChildPath : '#',
              state: secondChildState,
            }}
          >
            {SecondChildIcon && (
              <PathIcon isColored={secondChildColored}>
                <SecondChildIcon size={24} />
              </PathIcon>
            )}
            <PathName>{secondChild}</PathName>
          </LinkPath>
        </WrapperCrumbs>
      );
    }

    return (
      <Wrapper>
        {SecondChildIcon && (
          <PathIcon isColored={secondChildColored}>
            <SecondChildIcon size={24} />
          </PathIcon>
        )}
        <PathName>{secondChild}</PathName>
      </Wrapper>
    );
  }

  function getThirdChild(): JSX.Element {
    if (fourthChild) {
      return (
        <WrapperCrumbs>
          <LinkPath
            to={{
              pathname: thirdChildPath !== undefined ? thirdChildPath : '#',
              state: thirdChildState,
            }}
          >
            {ThirdChildIcon && (
              <PathIcon isColored={thirdChildColored}>
                <ThirdChildIcon size={24} />
              </PathIcon>
            )}
            <PathName>{thirdChild}</PathName>
          </LinkPath>
        </WrapperCrumbs>
      );
    }

    return (
      <Wrapper>
        {ThirdChildIcon && (
          <PathIcon isColored={thirdChildColored}>
            <ThirdChildIcon size={24} />
          </PathIcon>
        )}
        <PathName>{thirdChild}</PathName>
      </Wrapper>
    );
  }

  function getFourthChild(): JSX.Element {
    return (
      <Wrapper>
        {FourthChildIcon && (
          <PathIcon isColored={fourthChildColored}>
            <FourthChildIcon size={24} />
          </PathIcon>
        )}
        <PathName>{fourthChild}</PathName>
      </Wrapper>
    );
  }

  return (
    <Container>
      {getDashboard()}

      <AngleIcon>
        <FaAngleDoubleRight size={18} />
      </AngleIcon>

      {getMain()}

      {firstChild ? (
        <AngleIcon>
          <FaAngleDoubleRight size={18} />
        </AngleIcon>
      ) : (
        ''
      )}

      {getFirstChild()}

      {secondChild ? (
        <AngleIcon>
          <FaAngleDoubleRight size={18} />
        </AngleIcon>
      ) : (
        ''
      )}

      {getSecondChild()}

      {thirdChild ? (
        <AngleIcon>
          <FaAngleDoubleRight size={18} />
        </AngleIcon>
      ) : (
        ''
      )}

      {getThirdChild()}

      {fourthChild ? (
        <AngleIcon>
          <FaAngleDoubleRight size={18} />
        </AngleIcon>
      ) : (
        ''
      )}

      {getFourthChild()}
    </Container>
  );
};

export default BreadCrumb;
