import React, { useState, useCallback, Dispatch, SetStateAction } from 'react';
import { FaFileExcel } from 'react-icons/fa';
import { Dialog } from '@material-ui/core';

import { useAuth } from '~/hooks/auth';

import api from '~/services/api';

import DateInput from '~/components/Inputs/Date';
import Select from '~/components/Select';
import DialogOptionButton from '~/components/Buttons/DialogOption';

import {
  Container,
  Icon,
  Title,
  SubTitle,
  Options,
  Info,
  ButtonContainer,
  Error,
} from './styles';

interface IReportProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  projectType: string;
  ids?: string[];
}

const Report: React.FC<IReportProps> = ({
  isOpen,
  setIsOpen,
  projectType,
  ids,
}: IReportProps) => {
  const { user } = useAuth();
  const [type, setType] = useState('none');
  const [initialEventDate, setInitialEventDate] = useState<Date>();
  const [endEventDate, setEndEventDate] = useState<Date>();
  const [error, setError] = useState('');
  const options: { value: string; label: string }[] =
    projectType === 'EVENTO'
      ? [
          { value: 'all', label: 'Todos' },
          { value: 'page', label: 'Em Página' },
          { value: 'event_dates', label: 'Datas de Eventos' },
        ]
      : [
          { value: 'all', label: 'Todos' },
          { value: 'page', label: 'Em Página' },
        ];

  const handleClose = useCallback(async () => {
    setType('none');
    setInitialEventDate(undefined);
    setEndEventDate(undefined);
    setError('');
    setIsOpen(false);
  }, [setIsOpen]);

  function delay(ms: number): unknown {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const handleDownload = useCallback(async (filename: string) => {
    const link = document.createElement('a');
    link.href = `${process.env.REACT_APP_API_URL}/reports/${filename}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  const handleGenerate = useCallback(async () => {
    setError('');
    if (type === 'none') {
      setError('Necessário selecionar opção');
    } else if (
      type === 'event_dates' &&
      (initialEventDate === undefined || endEventDate === undefined)
    ) {
      setError('Necessário informar datas');
    } else if (
      type === 'event_dates' &&
      initialEventDate &&
      endEventDate &&
      initialEventDate > endEventDate
    ) {
      setError('Data final deve ser maior que data inicial');
    } else {
      let filenameReceived = '';

      await api
        .post('/projects/report', {
          type,
          project_type: projectType,
          ids,
          initial_event_date: initialEventDate,
          end_event_date: endEventDate,
          user_type: user.user_type.type,
          erp_rep_code: user.erp_rep_code,
        })
        .then(response => {
          filenameReceived = response.data.filename;
        });

      if (filenameReceived !== '') {
        await handleDownload(filenameReceived);

        handleClose();

        await delay(2000);

        api.delete(`projects/report/${filenameReceived}`);
      } else {
        setError('Não foi possível gerar o relatório');
      }
    }
  }, [
    type,
    initialEventDate,
    endEventDate,
    projectType,
    ids,
    user,
    handleDownload,
    handleClose,
  ]);

  function handleInfo(): React.ReactNode | null {
    if (type === 'event_dates') {
      return (
        <div style={{ display: 'flex' }}>
          <div style={{ width: '100%' }}>
            <DateInput
              name="initial_event_date"
              labelFor="initial_event_date"
              labelText="Data de Evento Inicial"
              type="date"
              min="2021-01-01"
              onSet={e => {
                setInitialEventDate(e);
                setError('');
              }}
            />
          </div>

          <div style={{ width: '100%', marginLeft: '1rem' }}>
            <DateInput
              name="end_event_date"
              labelFor="end_event_date"
              labelText="Data de Evento Final"
              type="date"
              min="2021-01-01"
              onSet={e => {
                setEndEventDate(e);
                setError('');
              }}
            />
          </div>
        </div>
      );
    }

    return null;
  }

  return (
    <Dialog open={isOpen}>
      <Container>
        <Icon>
          <FaFileExcel size={60} />
        </Icon>

        <Title>Geração de Relatório</Title>

        <SubTitle>Selecione a opção desejada</SubTitle>

        <Options>
          <Select
            label="Opções"
            options={options}
            onSet={(e: any) => {
              setType(e.value);
              setError('');
            }}
          />
        </Options>

        <Info>{handleInfo()}</Info>

        <ButtonContainer>
          <DialogOptionButton
            label="Gerar"
            optionType="report"
            onClick={() => handleGenerate()}
          />

          <DialogOptionButton
            label="Fechar"
            marginLeft="1.5rem"
            onClick={() => handleClose()}
          />
        </ButtonContainer>

        <Error>{error}</Error>
      </Container>
    </Dialog>
  );
};

export default Report;
