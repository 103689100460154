import styled, { css } from 'styled-components';

export const Container = styled.section`
  ${({ theme }) => css`
    width: 100%;
    background: ${theme.colors.background};
    border-radius: ${theme.radius.default};
    box-shadow: ${theme.shadows.flat};
    padding: 2.4rem;
    margin: 2.4rem 0;
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    font-size: 2rem;
    font-weight: 500;
    color: ${theme.colors.labelInput};
  `}
`;

export const Content = styled.div`
  max-width: 680px;
  display: flex;
  justify-content: flex-start;
`;

export const ContentAddRow = styled(Content)`
  padding-top: 1rem;
  justify-content: flex-end;
  margin-bottom: 5rem;
`;

export const Table = styled.table`
  ${({ theme }) => css`
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0 4px;
    background-color: ${theme.colors.lightGrey};
    box-shadow: 1px 1px 6px #ececec;
    border-radius: ${theme.radius.small};
  `}
`;

export const Separator = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 1rem 0.5rem 1rem 1rem;
    background-color: ${theme.colors.backgroundContainer};
    border-top-right-radius: ${theme.radius.small};
    border-top-left-radius: ${theme.radius.small};
  `}
`;

export const ItemContent = styled.div`
  display: flex;

  @media (max-width: 1010px) {
    flex-direction: column;
  }
`;

export const ItemWrapper = styled.div`
  width: 50%;
  padding: 0 0.5rem 0 0.5rem;
  margin-top: 1rem;

  @media (max-width: 1010px) {
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 2rem;
`;
