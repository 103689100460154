import React, { InputHTMLAttributes, useState, useCallback } from 'react';
import { IconBaseProps } from 'react-icons';
import { FaExclamation, FaEye, FaEyeSlash } from 'react-icons/fa';

import { Container, Label, Error } from './styles';

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  labelFor?: string;
  labelText: string;
  prefix?: string;
  sufix?: string;
  isPassword?: boolean;
  icon?: React.ComponentType<IconBaseProps>;
  error?: string;
  validateInput?: boolean;
  valueInput?: string;
  isHidden?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      name,
      labelText,
      labelFor,
      icon: Icon,
      error,
      prefix,
      sufix,
      isPassword,
      validateInput,
      valueInput,
      isHidden,
      ...rest
    }: IInputProps,
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);
    const [clickPassword, setClickPassword] = useState<boolean>(false);

    const handlePassword = useCallback(() => {
      setClickPassword(!clickPassword);
    }, [clickPassword]);

    const handleInputFocus = useCallback(() => {
      setIsFocused(!isFocused);
    }, [isFocused]);

    const handleInputBlur = useCallback(
      (inputValidate, inputValue) => () => {
        setIsFocused(false);

        if (inputValidate) {
          setIsFilled(inputValue !== '');
        }
      },
      [],
    );

    if (isHidden) {
      return null;
    }

    return (
      <>
        <Label isFocused={isFocused} isFilled={isFilled}>
          {labelText}
        </Label>

        <Container
          id={labelFor}
          isFocused={isFocused}
          isFilled={isFilled}
          isErrored={!!error}
        >
          {Icon && <Icon size={18} />}
          <span>{prefix}&nbsp;&nbsp;</span>
          <input
            name={name}
            type={clickPassword ? 'type' : 'password'}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur(validateInput, valueInput)}
            ref={ref}
            {...rest}
          />
          <span>{sufix}&nbsp;&nbsp;</span>

          {isPassword && (
            <button type="button" onClick={handlePassword}>
              {clickPassword ? (
                <FaEyeSlash size={18} color="#ecd2a6" />
              ) : (
                <FaEye size={18} />
              )}
            </button>
          )}

          {error && (
            <Error title={error}>
              <FaExclamation color="#f7734e" size={20} />
            </Error>
          )}
        </Container>
      </>
    );
  },
);

export default Input;
