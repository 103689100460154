import React from 'react';

import InfoInput from '~/components/Inputs/Info';

import { Container, Content } from './styles';

interface IMaterialDeliveryStatusProps {
  material_delivery_status?: string;
}

const MaterialDeliveryStatus: React.FC<IMaterialDeliveryStatusProps> = ({
  material_delivery_status,
}) => {
  return (
    <Container>
      <Content>
        <InfoInput
          name="material_delivery_status"
          labelFor="material_delivery_status"
          labelText="Status"
          type="text"
          defaultValue={
            material_delivery_status !== undefined
              ? material_delivery_status
              : ''
          }
          disabled
        />
      </Content>
    </Container>
  );
};

export default MaterialDeliveryStatus;
