import React, { useState, useEffect } from 'react';
import { PropagateLoader } from 'react-spinners';

import { FaFileAlt } from 'react-icons/fa';

import Pagination from '~/components/Pagination';
import ProjectEventCostDetailModal from '~/components/Modals/Projects/EventCostDetail';

import DefaultInput from '~/components/Inputs/Default';

import api from '~/services/api';

import {
  Container,
  Content,
  Table,
  Th,
  Td,
  DetailBtn,
  ButtonsContent,
} from './styles';

interface IEventCostsProps {
  project_id: string;
}

interface IProjectEventCost {
  id: string;
  sequence: number;
  price: number;
  note: string;
  project_id: string;
  project: {
    code: string;
    name: string;
  };
  event_cost_type: {
    name: string;
    code: string;
  };
  user_inclusion: {
    name: string;
  };
  created_at: string;
}

const AMOUNT_PER_PAGE = 5;

const EventCosts: React.FC<IEventCostsProps> = ({ project_id }) => {
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState('');
  const [projectEventCosts, setProjectEventCosts] =
    useState<IProjectEventCost[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [projectCodeDetail, setProjectCodeDetail] = useState('');
  const [userInclusionDetail, setUserInclusionDetail] = useState('');
  const [createdAtDetail, setCreatedAtDetail] = useState(new Date());
  const [eventCostTypeDetail, setEventCostTypeDetail] = useState('');
  const [priceDetail, setPriceDetail] = useState(0);
  const [noteDetail, setNoteDetail] = useState('');

  useEffect(() => {
    const loadProjectEventCosts = async () => {
      await api
        .get(
          `/projecteventcosts/project?` +
            `project_id=${project_id}&` +
            `page=${page}&` +
            `filter=${filter}&` +
            `amount=${AMOUNT_PER_PAGE}`,
        )
        .then(response => {
          setProjectEventCosts(response.data[0]);
          setCount(response.data[1]);
          setLoading(false);
        });
    };

    loadProjectEventCosts();
  }, [project_id, page, filter]);

  function handleTable(): React.ReactNode {
    if (projectEventCosts) {
      if (projectEventCosts.length > 0) {
        return (
          <Table>
            <thead>
              <tr>
                <Th width="20%" textAlign="left">
                  Sequência
                </Th>
                <Th width="20%" textAlign="left">
                  Cód. Tipo
                </Th>
                <Th width="30%" textAlign="left">
                  Desc. Tipo
                </Th>
                <Th width="20%" textAlign="right">
                  Valor
                </Th>
                <Th width="10%" textAlign="center" />
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <Td colSpan={4} padding="1rem" textAlign="center">
                    <PropagateLoader color="#276c8d" />
                  </Td>
                </tr>
              )}
              {projectEventCosts?.map(projectEventCost => (
                <tr key={projectEventCost.id}>
                  <Td width="20%" textAlign="right" heading="SEQUÊNCIA">
                    <span style={{ marginRight: 30 }}>
                      {projectEventCost.sequence.toString()}
                    </span>
                  </Td>
                  <Td width="20%" textAlign="left" heading="CÓD. TIPO">
                    {projectEventCost.event_cost_type.code}
                  </Td>
                  <Td width="30%" textAlign="left" heading="DESC. TIPO">
                    {projectEventCost.event_cost_type.name.substring(0, 50)}
                  </Td>
                  <Td width="20%" textAlign="right" heading="VALOR">
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(projectEventCost.price)}
                  </Td>
                  <Td
                    width="10%"
                    textAlign="center"
                    display="flex"
                    heading={' '}
                  >
                    <DetailBtn
                      onClick={() => {
                        setIsOpenDetail(true);
                        setProjectCodeDetail(projectEventCost.project.code);
                        setUserInclusionDetail(
                          projectEventCost.user_inclusion.name,
                        );
                        setCreatedAtDetail(
                          new Date(projectEventCost.created_at),
                        );
                        setEventCostTypeDetail(
                          `(${projectEventCost.event_cost_type.code}) - ` +
                            `${projectEventCost.event_cost_type.name}`,
                        );
                        setPriceDetail(projectEventCost.price);
                        setNoteDetail(projectEventCost.note);
                      }}
                    >
                      <FaFileAlt size={22} />
                    </DetailBtn>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        );
      }
    }

    return (
      <Table>
        <thead>
          <tr>
            <Th width="100%" textAlign="center" />
          </tr>
        </thead>
        <tbody>
          <tr>
            <Td width="100%" textAlign="center">
              Nenhuma informação disponível
            </Td>
          </tr>
        </tbody>
      </Table>
    );
  }

  function clearProjectEventCostDetail(): void {
    setProjectCodeDetail('');
    setUserInclusionDetail('');
    setCreatedAtDetail(new Date());
    setEventCostTypeDetail('');
    setPriceDetail(0);
    setNoteDetail('');
  }

  if (!project_id) {
    return null;
  }

  return (
    <Container>
      <Content>
        <div>
          <DefaultInput
            name="search"
            labelText="Pesquisar"
            type="text"
            onChange={event => {
              setFilter(event.target.value);
              setPage(0);
            }}
          />
        </div>

        {handleTable()}

        <ButtonsContent>
          <Pagination
            page={page}
            count={count}
            amountPerPage={AMOUNT_PER_PAGE}
            setPage={setPage}
          />
        </ButtonsContent>
      </Content>

      <ProjectEventCostDetailModal
        isOpen={isOpenDetail}
        project_code={projectCodeDetail}
        user_inclusion={userInclusionDetail}
        created_at={createdAtDetail}
        event_cost_type={eventCostTypeDetail}
        price={priceDetail}
        note={noteDetail}
        setIsOpen={setIsOpenDetail}
        onClose={() => {
          clearProjectEventCostDetail();
        }}
      />
    </Container>
  );
};

export default EventCosts;
