import React, { ButtonHTMLAttributes } from 'react';

import { FaMinus } from 'react-icons/fa';

import { Container } from './styles';

export type IDeleteRowProps = ButtonHTMLAttributes<HTMLButtonElement>;

const DeleteRow: React.FC<IDeleteRowProps> = ({ ...rest }) => {
  return (
    <Container {...rest}>
      <FaMinus size={16} />
    </Container>
  );
};

export default DeleteRow;
