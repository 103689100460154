import React, { useCallback, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FaTools, FaWrench } from 'react-icons/fa';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import BreadCrumb from '~/components/BreadCrumb';
import ProjectInfo from '~/components/ProjectInfo/Default';
import DefaultInput from '~/components/Inputs/Default';
import TextArea from '~/components/TextArea';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Content,
  ContentSplit,
  Wrapper,
  HalfWrapper,
  ButtonContainer,
} from './styles';

interface IFormCancel {
  status: {
    value: string;
  };
  reason: string;
  received_user_id: string;
}

interface ILocation {
  id: string;
  code: string;
  status: string;
}

const schema = Yup.object().shape({
  reason: Yup.string().required('Campo obrigatório'),
});

const Cancel: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [titleConfirmProcedure, setTitleConfirmProcedure] = useState('');
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { handleSubmit, register, setValue, errors } = useForm<IFormCancel>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Dados não localizados',
      });

      history.push('/management/main');
    } else if (user.user_type.type !== 'ADM' && !user.admin_functions) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Tipo de usuário não permitido',
      });

      history.push('/management/main');
    } else {
      register('status');
      register('received_user_id');

      setValue('status', { value: 'Cancelado' });
      setValue('received_user_id', user.id);
    }
  }, [state, addToast, history, user, register, setValue]);

  const submitForm = useCallback(
    async (data: IFormCancel) => {
      try {
        setIsSaving(true);

        if (state && state.id) {
          await api.put(`/projects/updatestatus/${state.id}`, data);

          addToast({
            type: 'success',
            title: 'Solicitação atualizada!',
            description: 'Solicitação atualizada com sucesso.',
          });
        }

        history.push('/management/main');
      } catch (error: any) {
        setIsSaving(false);

        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [state, addToast, history],
  );

  function onConfirmProcedure(): void {
    handleSubmit(submitForm)();
  }

  const onExit = useCallback(() => {
    history.push('/management/main');
  }, [history]);

  return (
    <Container>
      <BreadCrumb
        main="Administração"
        path="/management/main"
        firstChild="Alteração Manual de Solicitação"
        mainIcon={FaTools}
        firstChildIcon={FaWrench}
        mainColored
      />

      <ProjectInfo project_id={state.id} all />

      <form onSubmit={handleSubmit(submitForm)}>
        <Content maxWidth="600px">
          <HalfWrapper>
            <DefaultInput
              name="code"
              labelFor="code"
              labelText="Código Solicitação"
              type="text"
              value={state?.code}
              disabled
            />
          </HalfWrapper>
        </Content>

        <ContentSplit maxWidth="600px">
          <HalfWrapper>
            <DefaultInput
              name="old_status"
              labelFor="old_status"
              labelText="Status Antigo"
              type="text"
              value={state?.status}
              disabled
            />
          </HalfWrapper>

          <HalfWrapper>
            <DefaultInput
              name="new_status"
              labelFor="new_status"
              labelText="Status Novo"
              type="text"
              value="Cancelado"
              disabled
            />
          </HalfWrapper>
        </ContentSplit>

        <Content maxWidth="600px">
          <Wrapper>
            <TextArea
              name="reason"
              labelFor="reason"
              labelText="Motivo"
              error={errors?.reason?.message}
              ref={register}
              maxLength={1000}
            />
          </Wrapper>
        </Content>

        <ButtonContainer>
          <SaveButton
            type="button"
            processing={isSaving}
            disabled={isSaving}
            onClick={() => {
              setTitleConfirmProcedure(
                `Confirma alteração de status na solicitação ${state.code}?`,
              );
              setIsOpenConfirmProcedure(true);
            }}
          />

          <ExitButton
            style={{ marginLeft: 10 }}
            type="button"
            disabled={isSaving}
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={onConfirmProcedure}
        title={titleConfirmProcedure}
        subTitle=""
        opConfirm="Sim"
        type=""
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />
    </Container>
  );
};

export default Cancel;
