import React, { ButtonHTMLAttributes } from 'react';

import { FaLockOpen } from 'react-icons/fa';

import { Container } from './styles';

export interface IUnlockProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  iconSize?: number;
  marginTop?: string;
}

const Unlock: React.FC<IUnlockProps> = ({ iconSize, ...rest }) => {
  return (
    <Container {...rest}>
      <FaLockOpen size={iconSize !== undefined ? iconSize : 26} />
    </Container>
  );
};

export default Unlock;
