import React from 'react';

import InfoInput from '~/components/Inputs/Info';

import { Container, Content } from './styles';

interface IRebateCampaignProps {
  rebate_campaign: boolean;
}

const RebateCampaign: React.FC<IRebateCampaignProps> = ({
  rebate_campaign,
}) => {
  return (
    <Container>
      <Content>
        <InfoInput
          name="rebate_campaign"
          labelFor="rebate_campaign"
          labelText="Campanha de Rebate"
          type="text"
          defaultValue={rebate_campaign ? 'Sim' : 'Não'}
          disabled
        />
      </Content>
    </Container>
  );
};

export default RebateCampaign;
