import React, { InputHTMLAttributes, useState, useCallback } from 'react';

import ErrorTooltip from '~/components/Tooltips/Error';

import { Label, Container, InputText } from './styles';

interface IInfoProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  labelFor?: string;
  labelText: string;
  error?: string;
}

const Info = React.forwardRef<HTMLInputElement, IInfoProps>(
  ({ name, labelFor, labelText, error, ...rest }: IInfoProps, ref) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleInputFocus = useCallback(() => {
      setIsFocused(!isFocused);
    }, [isFocused]);

    const handleInputBlur = useCallback(() => {
      setIsFocused(false);
    }, []);

    return (
      <>
        <Label isFocused={isFocused}>{labelText}</Label>

        <Container id={labelFor} isFocused={isFocused} isErrored={!!error}>
          <InputText
            name={name}
            type="type"
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            ref={ref}
            {...rest}
          />

          {error && <ErrorTooltip title={error} />}
        </Container>
      </>
    );
  },
);

export default Info;
