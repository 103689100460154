import React, { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';

import Header from '~/components/Header';
import NavigationCards from '~/components/NavigationCards';

import SideBar from '~/components/SideBar';
import Footer from '~/components/Footer';

import { Container, AnimatedWrapper, NavigationContainer } from './styles';
import { DASHBOARD_ANIMATION } from './animations';

const DefaultLayout: React.FC = ({ children }: PropsWithChildren<unknown>) => {
  const { pathname } = useLocation();

  return (
    <>
      <Header />
      <Container>
        <AnimatedWrapper
          variants={DASHBOARD_ANIMATION}
          initial={pathname === '/dashboard' ? 'unMounted' : 'mounted'}
          animate="mounted"
          transition={{ duration: 0.5 }}
        >
          <SideBar />
          <NavigationContainer>
            <NavigationCards />
            {children}
          </NavigationContainer>
        </AnimatedWrapper>
      </Container>
      <Footer />
    </>
  );
};

export default DefaultLayout;
