import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.button`
  ${({ theme }) => css`
    width: 180px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
    background-color: ${theme.colors.dataLoad};
    color: #fff;
    border-radius: ${theme.radius.default};
    font-size: ${theme.fontSizes.button};
    font-weight: 400;

    :hover {
      background: ${shade(0.2, theme.colors.dataLoad)};
      transition: 0.2s;
    }
  `}
`;
