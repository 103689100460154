import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { shade } from 'polished';

import { Navigation, AnimatedCard } from '~/components/NavigationCards/styles';

interface ICardProps {
  backgroundColor: string;
}

export const AnimatedContainer = styled(motion.section)`
  ${({ theme }) => css`
    background: ${theme.colors.backgroundContainer};
    border-radius: ${theme.radius.small};
    box-shadow: ${theme.shadows.flat};
    padding: 2.4rem;
    min-height: 30rem;
    width: 100%;
    margin: 2.4rem 0;
  `}
`;

export const Management = styled(Navigation)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const ManagementCardWrapper = styled.div`
  width: 30rem;
  padding: 0 2.5rem 0 2.5rem;
`;

export const ManagementCard = styled(AnimatedCard)<ICardProps>`
  ${({ theme, backgroundColor }) => css`
    display: flex;
    flex-direction: column;
    box-shadow: none;
    font-size: ${theme.fontSizes.default};
    height: 12rem;
    width: 100%;
    justify-content: center;

    :hover {
      background: ${shade(0.2, theme.colors.optionsHover)};
      transition: 0.2s;
    }

    ${backgroundColor &&
    css`
      background: ${backgroundColor};
    `}
  `}
`;

export const ManagementIcon = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.iconManagement};
  `}
`;

export const ManagementName = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white};
  `}
`;

export const GoIcon = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white};
  `}
`;
