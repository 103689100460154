import React from 'react';
import { useLocation } from 'react-router-dom';

import DefaultLayout from '../../_layouts/default';

import List from './List';
import View from './View';
import Procedure1Representative from './Procedures/Procedure1/ChooseRepresentative';
import FolderProcedure1Client from './Procedures/Procedure1/ChooseClient';
import FolderProcedure1New from './Procedures/Procedure1/New';
import FolderProcedure2 from './Procedures/Procedure2';
import FolderBatchesNew from './Batches';

const Folders: React.FC = () => {
  const { pathname } = useLocation();

  function getComponent(): React.ReactNode {
    if (pathname === '/projects/folders') {
      return <List />;
    }
    if (pathname === '/projects/folders/view') {
      return <View />;
    }
    if (pathname === '/projects/folders/procedure1/representative') {
      return <Procedure1Representative />;
    }
    if (pathname === '/projects/folders/procedure1/client') {
      return <FolderProcedure1Client />;
    }
    if (pathname === '/projects/folders/procedure1/new') {
      return <FolderProcedure1New />;
    }
    if (pathname === '/projects/folders/procedure2') {
      return <FolderProcedure2 />;
    }
    if (pathname === '/projects/folders/folderbatches') {
      return <FolderBatchesNew />;
    }

    return <List />;
  }

  return <DefaultLayout>{getComponent()}</DefaultLayout>;
};

export default Folders;
