import styled, { css } from 'styled-components';

export const Container = styled.section`
  ${({ theme }) => css`
    background: ${theme.colors.background};
    border-radius: ${theme.radius.default};
    box-shadow: ${theme.shadows.flat};
    padding: 2.4rem;
    width: 100%;
    margin: 2.4rem 0;
  `}
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const OneFourthWrapper = styled.div`
  width: 25%;
  margin: 0 1rem 2rem 0;

  @media (max-width: 900px) {
    width: 100%;
    margin: 0 0 2rem 0;
  }
`;

export const HalfWrapper = styled.div`
  width: 50%;
  margin: 0 1rem 2rem 0;

  @media (max-width: 900px) {
    width: 100%;
    margin: 0 0 2rem 0;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;
