import React, { Dispatch, SetStateAction } from 'react';

import InfoInput from '~/components/Inputs/Info';
import TextArea from '~/components/TextArea';
import CloseButton from '~/components/Buttons/Close';

import {
  StyledModal,
  MainContainer,
  Title,
  Container,
  Content,
  ContentSplit,
  Wrapper,
  HalfWrapper,
  ButtonContainer,
} from './styles';

interface IRecordDetailProps {
  isOpen: boolean;
  onClose: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  description?: string;
  status?: string;
  note?: string;
  reason?: string;
  store_relationship_code?: string;
  user?: string;
}

const RecordDetail: React.FC<IRecordDetailProps> = ({
  isOpen,
  onClose,
  setIsOpen,
  description,
  status,
  note,
  reason,
  store_relationship_code,
  user,
}: IRecordDetailProps) => {
  const handleClose = (): void => {
    setIsOpen(false);
    onClose();
  };

  return (
    <StyledModal
      isOpen={isOpen}
      style={{
        overlay: {
          zIndex: 999,
          overflowY: 'auto',
          position: 'fixed',
          background: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <MainContainer>
        <Title>Detalhes Registro Histórico</Title>
        <Container>
          <ContentSplit maxWidth="680px">
            <HalfWrapper marginRight="1rem">
              <InfoInput
                name="store_relationship_code"
                labelFor="store_relationship_code"
                labelText="Cód. Registro"
                type="text"
                defaultValue={store_relationship_code}
                disabled
              />
            </HalfWrapper>

            <HalfWrapper>
              <InfoInput
                name="status"
                labelFor="status"
                labelText="Status"
                type="text"
                defaultValue={status}
                disabled
              />
            </HalfWrapper>
          </ContentSplit>

          <Content maxWidth="680px">
            <Wrapper>
              <InfoInput
                name="description"
                labelFor="description"
                labelText="Descrição"
                type="text"
                defaultValue={description}
                disabled
              />
            </Wrapper>
          </Content>

          {!!note && (
            <Content maxWidth="680px">
              <Wrapper>
                <TextArea
                  name="note"
                  labelFor="note"
                  labelText="Observação"
                  defaultValue={note}
                  maxLength={1000}
                  disabled
                />
              </Wrapper>
            </Content>
          )}

          {!!reason && (
            <Content maxWidth="680px">
              <Wrapper>
                <TextArea
                  name="reason"
                  labelFor="reason"
                  labelText="Motivo"
                  defaultValue={reason}
                  maxLength={1000}
                  disabled
                />
              </Wrapper>
            </Content>
          )}

          <Content maxWidth="680px">
            <Wrapper>
              <InfoInput
                name="user"
                labelFor="user"
                labelText="Usuário"
                type="text"
                defaultValue={user}
                disabled
              />
            </Wrapper>
          </Content>

          <ButtonContainer>
            <CloseButton onClick={() => handleClose()} />
          </ButtonContainer>
        </Container>
      </MainContainer>
    </StyledModal>
  );
};

export default RecordDetail;
