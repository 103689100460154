import React from 'react';

import InfoInput from '~/components/Inputs/Info';

import { Container, Content, Wrapper } from './styles';

interface IItemProps {
  code: string;
  name: string;
}

const Item: React.FC<IItemProps> = ({ code, name }) => {
  return (
    <Container>
      <Content>
        <Wrapper>
          <InfoInput
            name="code"
            labelFor="code"
            labelText="Código"
            type="text"
            defaultValue={code}
            disabled
          />
        </Wrapper>

        <Wrapper>
          <InfoInput
            name="name"
            labelFor="name"
            labelText="Nome"
            type="text"
            defaultValue={name}
            disabled
          />
        </Wrapper>
      </Content>
    </Container>
  );
};

export default Item;
