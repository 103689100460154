import React from 'react';
import { useLocation } from 'react-router-dom';

import DefaultLayout from '../_layouts/default';

import ChooseClient from './ChooseClient';
import View from './View';
import Procedure1 from './Procedures/Procedure1';
import ProcedureTopics from './Procedures/ProcedureTopics';
import ProcedureDisplays from './Procedures/ProcedureDisplays';

const StoreRelationships: React.FC = () => {
  const { pathname } = useLocation();

  function getComponent(): React.ReactNode {
    if (pathname === '/storerelationships') {
      return <ChooseClient />;
    }
    if (pathname === '/storerelationships/view') {
      return <View />;
    }
    if (pathname === '/storerelationships/procedure1') {
      return <Procedure1 />;
    }
    if (pathname === '/storerelationships/proceduretopics') {
      return <ProcedureTopics />;
    }
    if (pathname === '/storerelationships/proceduredisplays') {
      return <ProcedureDisplays />;
    }

    return <ChooseClient />;
  }

  return <DefaultLayout>{getComponent()}</DefaultLayout>;
};

export default StoreRelationships;
