import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-right: 1rem;
`;

export const PrevNextWrapper = styled.button`
  ${({ theme, disabled }) => css`
    background-color: #fff;
    color: ${theme.colors.grey};
    margin: 0 0.6rem;
    padding: 8px 8px;
    border-radius: 100%;
    box-shadow: 0 0 4px #bbbbbb;

    ${!disabled &&
    css`
      &:hover {
        transform: scale(1.2, 1.2);
      }
    `}

    ${disabled &&
    css`
      background-color: ${theme.colors.secondLightGrey};

      svg {
        color: ${theme.colors.grey};
      }

      cursor: not-allowed;
    `}
  `}
`;

export const CenterWrapper = styled.button`
  ${({ theme, disabled }) => css`
    background-color: #fff;
    color: ${theme.colors.grey};
    padding: 8px 16px;
    border-radius: 8%;
    box-shadow: 0 0 3px #bbbbbb;

    ${!disabled &&
    css`
      &:hover {
        transform: scale(1.2, 1.2);
      }
    `}

    ${disabled &&
    css`
      background-color: ${theme.colors.secondLightGrey};

      svg {
        color: ${theme.colors.grey};
      }

      cursor: not-allowed;
    `}
  `}
`;
