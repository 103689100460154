import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { PropagateLoader } from 'react-spinners';
import * as mime from 'mime';
import { FaStore, FaListUl, FaLink } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

import BreadCrumb from '~/components/BreadCrumb';
import StoreRelationshipInfo from '~/components/StoreRelationshipInfo';
import TableSelect from '~/components/TableSelect';
import TableTextArea from '~/components/TableTextArea';
import AudioIcon from '~/components/Icons/Files/Audio';
import PdfIcon from '~/components/Icons/Files/Pdf';
import PresentationIcon from '~/components/Icons/Files/Presentation';
import SpreadsheetIcon from '~/components/Icons/Files/Spreadsheet';
import TextIcon from '~/components/Icons/Files/Text';
import VideoIcon from '~/components/Icons/Files/Video';
import WordIcon from '~/components/Icons/Files/Word';
import LockButton from '~/components/Buttons/Lock';
import UnlockButton from '~/components/Buttons/Unlock';
import AddRowButton from '~/components/Buttons/AddRow';
import DeleteRowButton from '~/components/Buttons/DeleteRow';
import DeletePreviousButton from '~/components/Buttons/DeletePrevious';
import UploadStoreRelationshipTopicFiles from '~/components/UploadStoreRelationshipTopicFiles';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';
import ConfirmWarningDialog from '~/components/Dialogs/ConfirmWarning';
import DeleteModal from '~/components/Modals/Delete';
import NoticeDialog from '~/components/Dialogs/Notice';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Content,
  ContentAddRow,
  LabelContainer,
  Label,
  Table,
  ItemSequence,
  ItemLabel,
  ItemContent,
  ItemWrapper,
  ItemUploadWrapper,
  ItemPreviousFilesWrapper,
  FileMainContainer,
  FileImageWrapper,
  FileImage,
  FileInfoName,
  FileInfoFileSize,
  FileUrlIcon,
  ButtonContainer,
} from './styles';

interface IPreviousStoreRelationshipTopic {
  id: string;
  sequence: number;
  description: string;
  store_relationship_id: string;
  store_relationship_topic_type_id: string;
  user_inclusion_id: string;
  user_update_id: string;
  user_inclusion: {
    name: string;
  };
  user_update: {
    name: string;
  };
  created_at: string;
}

interface IPreviousStoreRelationshipTopicFile {
  id: string;
  name: string;
  size: number;
  key: string;
  url: string;
  store_relationship_topic_id: string;
  user_id: string;
  store_relationship_topic_file_received_id: string;
  store_relationship_topic: {
    id: string;
    sequence: number;
  };
  user: {
    id: string;
    name: string;
    type: string;
  };
  created_at: string;
  deleted: boolean;
}

interface IStoreRelationshipTopicFileDeleted {
  id: string;
}

interface IStoreRelationshipTopic {
  id: string;
  sequence: number;
  description: string;
  store_relationship_id: string;
  store_relationship_topic_type_id: string;
  user_inclusion_id: string;
  user_update_id: string;
  store_relationship_topic_temp_id: string;
  store_relationship_record_reason: string;
  previous: boolean;
  fields_enabled: boolean;
  deleted: boolean;
  created_at: Date;
  store_relationship_topic_files_deleted: IStoreRelationshipTopicFileDeleted[];
}

interface IFormProcedureTopics {
  user_inclusion_id: string;
  user_update_id: string;
  record_description: string;
  store_relationship_topics: IStoreRelationshipTopic[];
}

interface ILocation {
  id: string;
  code: string;
  erp_client_code: string;
  erp_rep_code: string;
  store_relationship_category_id: string;
}

const ProcedureTopics: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [storeRelationshipTopicTypes, setStoreRelationshipTopicTypes] =
    useState<
      {
        id: string;
        name: string;
      }[]
    >();
  const [client, setClient] = useState<{
    code: string;
    name: string;
    company_name: string;
    document: string;
    address: string;
    city: string;
    state: string;
    rep_code: string;
    rep_name: string;
    sales_channel_code: string;
  }>();
  const [previousStoreRelationshipTopics, setPreviousStoreRelationshipTopics] =
    useState<IPreviousStoreRelationshipTopic[]>();
  const [
    previousStoreRelationshipTopicFiles,
    setPreviousStoreRelationshipTopicFiles,
  ] = useState<IPreviousStoreRelationshipTopicFile[]>([]);
  const [
    previousStoreRelationshipTopicFilesReceived,
    setPreviousStoreRelationshipTopicFilesReceived,
  ] = useState<IPreviousStoreRelationshipTopicFile[]>();
  const [storeRelationshipTopics, setStoreRelationshipTopics] = useState<
    IStoreRelationshipTopic[]
  >([]);
  const [loadStoreRelationshipTopics, setloadStoreRelationshipTopics] =
    useState(false);
  const [storeRelationshipTopicsLoaded, setStoreRelationshipTopicsLoaded] =
    useState(false);
  const [loadingTopicTypes, setLoadingTopicTypes] = useState<boolean>(true);
  const [loadingTopics, setLoadingTopics] = useState<boolean>(true);
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [titleConfirmProcedure, setTitleConfirmProcedure] = useState('');
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [
    isOpenDeleteStoreRelationshipTopic,
    setIsOpenDeleteStoreRelationshipTopic,
  ] = useState(false);
  const [
    titleDeleteStoreRelationshipTopic,
    setTitleDeleteStoreRelationshipTopic,
  ] = useState('');
  const [
    subTitleDeleteStoreRelationshipTopic,
    setSubTitleDeleteStoreRelationshipTopic,
  ] = useState('');
  const [
    reasonDeleteStoreRelationshipTopic,
    setReasonDeleteStoreRelationshipTopic,
  ] = useState('');
  const [
    isOpenDeleteStoreRelationshipTopicFile,
    setIsOpenDeleteStoreRelationshipTopicFile,
  ] = useState(false);
  const [
    titleDeleteStoreRelationshipTopicFile,
    setTitleDeleteStoreRelationshipTopicFile,
  ] = useState('');
  const storeRelationshipTopicSequence = useRef(0);
  const [isOpenNotice, setIsOpenNotice] = useState(false);
  const [titleNotice, setTitleNotice] = useState('');
  const [subTitleNotice, setSubTitleNotice] = useState('');
  const [
    storeRelationshipTopicEnableSequence,
    setStoreRelationshipTopicEnableSequence,
  ] = useState('');
  const [
    confirmStoreRelationshipTopicEnabled,
    setConfirmStoreRelationshipTopicEnabled,
  ] = useState(false);
  const [
    storeRelationshipTopicDeleteSequence,
    setStoreRelationshipTopicDeleteSequence,
  ] = useState('');
  const [
    confirmStoreRelationshipTopicDelete,
    setConfirmStoreRelationshipTopicDelete,
  ] = useState(false);
  const [
    storeRelationshipTopicFileDeleteId,
    setStoreRelationshipTopicFileDeleteId,
  ] = useState('');
  const [
    confirmStoreRelationshipTopicFileDelete,
    setConfirmStoreRelationshipTopicFileDelete,
  ] = useState(false);

  const { handleSubmit, register, setValue } = useForm<IFormProcedureTopics>();

  useEffect(() => {
    if (previousStoreRelationshipTopics) {
      if (!storeRelationshipTopicsLoaded) {
        if (storeRelationshipTopicTypes) {
          setloadStoreRelationshipTopics(true);
        }
      }
    }
  }, [
    storeRelationshipTopicsLoaded,
    previousStoreRelationshipTopics,
    storeRelationshipTopicTypes,
    state,
  ]);

  async function getStoreRelationshipTopics(
    store_relationship_id: string,
  ): Promise<void> {
    await api
      .get(
        `/storerelationshiptopics/storerelationshipall` +
          `?store_relationship_id=${store_relationship_id}`,
      )
      .then(response => {
        setPreviousStoreRelationshipTopics(response.data);
      });
  }

  useEffect(() => {
    let emptyStoreRelationship = true;

    if (state && state.id) {
      getStoreRelationshipTopics(state.id);
      emptyStoreRelationship = false;
    }

    if (emptyStoreRelationship) {
      if (!storeRelationshipTopicsLoaded) {
        setloadStoreRelationshipTopics(true);
      }
    }
  }, [state, storeRelationshipTopicsLoaded]);

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Registro de loja não localizado',
      });

      history.push('/storerelationships');
    } else if (
      user.user_type.type !== 'REP' &&
      user.user_type.type !== 'ADM' &&
      user.user_type.type !== 'MK1' &&
      user.user_type.type !== 'MK2' &&
      user.user_type.type !== 'MAX' &&
      user.user_type.type !== 'GMK' &&
      user.user_type.type !== 'DGR' &&
      user.user_type.type !== 'DCM' &&
      user.user_type.type !== 'IMK' &&
      user.user_type.type !== 'GCM'
    ) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Tipo de usuário não permitido',
      });

      history.push('/storerelationships');
    } else {
      register('user_update_id');
      register('record_description');
      register('store_relationship_topics');

      setValue('user_update_id', user.id);

      api
        .get('/configurations/storerelationshiptopictypes/valid')
        .then(response => {
          setStoreRelationshipTopicTypes(response.data);
          setLoadingTopicTypes(false);
        });

      api.get(`/clients/code?code=${state.erp_client_code}`).then(response => {
        setClient(response.data);
      });
    }
  }, [addToast, history, state, user, register, setValue]);

  useEffect(() => {
    if (previousStoreRelationshipTopicFilesReceived) {
      if (previousStoreRelationshipTopicFilesReceived.length > 0) {
        previousStoreRelationshipTopicFilesReceived.map(
          prevStoreRelationshipTopicFileReceived => {
            let fileInserted = false;

            const previousStoreRelationshipTopicFilesValidation =
              previousStoreRelationshipTopicFiles.filter(
                prevStoreRelationshipTopicFile =>
                  prevStoreRelationshipTopicFile.id ===
                  prevStoreRelationshipTopicFileReceived.id,
              );

            if (previousStoreRelationshipTopicFilesValidation) {
              if (previousStoreRelationshipTopicFilesValidation.length > 0) {
                fileInserted = true;
              }
            }

            if (!fileInserted) {
              setPreviousStoreRelationshipTopicFiles(
                prevStoreRelationshipTopicFiles => [
                  ...prevStoreRelationshipTopicFiles,
                  prevStoreRelationshipTopicFileReceived,
                ],
              );
            }

            return prevStoreRelationshipTopicFileReceived;
          },
        );
      }
    }
  }, [
    previousStoreRelationshipTopicFilesReceived,
    previousStoreRelationshipTopicFiles,
  ]);

  async function getPreviousStoreRelationshipTopicFiles(
    previous_store_rel_topic_id: string,
  ): Promise<void> {
    if (previous_store_rel_topic_id && previous_store_rel_topic_id !== '') {
      await api
        .get(
          `/storerelationshiptopicfiles/storerelationshiptopicall?` +
            `store_relationship_topic_id=${previous_store_rel_topic_id}`,
        )
        .then(response => {
          setPreviousStoreRelationshipTopicFilesReceived(response.data);
        });
    }
  }

  useEffect(() => {
    if (loadStoreRelationshipTopics) {
      if (previousStoreRelationshipTopics) {
        if (previousStoreRelationshipTopics.length > 0) {
          Object.keys(previousStoreRelationshipTopics).forEach(key => {
            storeRelationshipTopicSequence.current =
              previousStoreRelationshipTopics[Number(key)].sequence;
            const previousStoreRelationshipTopic: IStoreRelationshipTopic = {
              id: previousStoreRelationshipTopics[Number(key)].id,
              sequence: previousStoreRelationshipTopics[Number(key)].sequence,
              description:
                previousStoreRelationshipTopics[Number(key)].description,
              store_relationship_id:
                previousStoreRelationshipTopics[Number(key)]
                  .store_relationship_id,
              store_relationship_topic_type_id:
                previousStoreRelationshipTopics[Number(key)]
                  .store_relationship_topic_type_id,
              user_inclusion_id:
                previousStoreRelationshipTopics[Number(key)].user_inclusion_id,
              user_update_id: user.id,
              store_relationship_topic_temp_id: uuidv4(),
              store_relationship_record_reason: '',
              previous: true,
              fields_enabled: false,
              deleted: false,
              created_at: new Date(
                previousStoreRelationshipTopics[Number(key)].created_at,
              ),
              store_relationship_topic_files_deleted: [],
            };

            setStoreRelationshipTopics(prevStoreRelationshipTopics => [
              ...prevStoreRelationshipTopics,
              previousStoreRelationshipTopic,
            ]);

            getPreviousStoreRelationshipTopicFiles(
              previousStoreRelationshipTopics[Number(key)].id,
            );
          });
        }
      }

      storeRelationshipTopicSequence.current += 1;
      const firstStoreRelationshipTopic: IStoreRelationshipTopic = {
        id: '',
        sequence: storeRelationshipTopicSequence.current,
        description: '',
        store_relationship_id: state?.id ? state.id : '',
        store_relationship_topic_type_id: '',
        user_inclusion_id: user.id,
        user_update_id: user.id,
        store_relationship_topic_temp_id: uuidv4(),
        store_relationship_record_reason: '',
        previous: false,
        fields_enabled: true,
        deleted: false,
        created_at: new Date(),
        store_relationship_topic_files_deleted: [],
      };

      setStoreRelationshipTopics(prevStoreRelationshipTopics => [
        ...prevStoreRelationshipTopics,
        firstStoreRelationshipTopic,
      ]);

      setloadStoreRelationshipTopics(false);
      setStoreRelationshipTopicsLoaded(true);
      setLoadingTopics(false);
    }
  }, [
    state,
    user,
    loadStoreRelationshipTopics,
    previousStoreRelationshipTopics,
  ]);

  const topicOptions = storeRelationshipTopicTypes?.map(
    storeRelationshipTopicType => ({
      value: storeRelationshipTopicType.id,
      label: storeRelationshipTopicType.name,
    }),
  );

  function onConfirmWarning(): void {
    setConfirmStoreRelationshipTopicEnabled(true);
  }

  function onDeleteStoreRelationshipTopic(): void {
    setConfirmStoreRelationshipTopicDelete(true);
  }

  function onDeleteStoreRelationshipTopicFile(): void {
    setConfirmStoreRelationshipTopicFileDelete(true);
  }

  const submitForm = useCallback(
    async (data: IFormProcedureTopics) => {
      try {
        if (state !== undefined && state.id) {
          await api.put(`/storerelationships/${state.id}`, data);

          addToast({
            type: 'success',
            title: 'Tópicos de registro de loja atualizados!',
            description: 'Tópicos de registro de loja atualizados com sucesso.',
          });

          history.push({
            pathname: '/storerelationships/procedure1',
            state: client,
          });
        } else {
          addToast({
            type: 'error',
            title: 'Registro inválido!',
            description: 'Registro de loja não localizado',
          });
        }
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [addToast, history, client, state],
  );

  async function onValidateConfirm(): Promise<void> {
    const rowsStoreRelationshipTopicEmptyType = storeRelationshipTopics.filter(
      storeRelTopic =>
        storeRelTopic.store_relationship_topic_type_id === undefined ||
        storeRelTopic.store_relationship_topic_type_id === '',
    );

    const rowsStoreRelationshipTopicEmptyDescription =
      storeRelationshipTopics.filter(
        storeRelTopic => storeRelTopic.description === '',
      );

    if (
      rowsStoreRelationshipTopicEmptyType &&
      rowsStoreRelationshipTopicEmptyType.length > 0
    ) {
      setTitleNotice('Existem tópicos sem tipo selecionado!');
      setSubTitleNotice(
        'Não é possível gravar registro com tópicos sem tipo selecionado!',
      );
      setIsOpenNotice(true);
    } else if (
      rowsStoreRelationshipTopicEmptyDescription &&
      rowsStoreRelationshipTopicEmptyDescription.length > 0
    ) {
      setTitleNotice('Existem tópicos sem descrição!');
      setSubTitleNotice(
        'Não é possível gravar registro com tópicos sem descrição!',
      );
      setIsOpenNotice(true);
    } else {
      setTitleConfirmProcedure(
        `Confirma gravação de tópicos do registro ${state.code}?`,
      );

      setIsOpenConfirmProcedure(true);
    }
  }

  function onConfirmProcedure(): void {
    setValue(
      'record_description',
      'Atualização de tópicos do registro de loja',
    );

    setValue('store_relationship_topics', storeRelationshipTopics);

    handleSubmit(submitForm)();
  }

  const onExit = useCallback(() => {
    storeRelationshipTopics.map(storeRelTopic => {
      api.delete(
        `storerelationshiptopicfilesreceived/` +
          `storerelationshiptopictempid/` +
          `${storeRelTopic.store_relationship_topic_temp_id}`,
      );

      return storeRelTopic;
    });

    history.push({
      pathname: '/storerelationships/procedure1',
      state: client,
    });
  }, [history, client, storeRelationshipTopics]);

  function handleAddRowTable(): void {
    storeRelationshipTopicSequence.current += 1;

    const newStoreRelationshipTopic: IStoreRelationshipTopic = {
      id: '',
      sequence: storeRelationshipTopicSequence.current,
      description: '',
      store_relationship_id: state?.id ? state.id : '',
      store_relationship_topic_type_id: '',
      user_inclusion_id: user.id,
      user_update_id: user.id,
      store_relationship_topic_temp_id: uuidv4(),
      store_relationship_record_reason: '',
      previous: false,
      fields_enabled: true,
      deleted: false,
      created_at: new Date(),
      store_relationship_topic_files_deleted: [],
    };

    setStoreRelationshipTopics(prevStoreRelationshipTopics => [
      ...prevStoreRelationshipTopics,
      newStoreRelationshipTopic,
    ]);
  }

  function handleDeleteRowTable(
    deletedRowStoreRelationshipTopic: IStoreRelationshipTopic,
  ): void {
    api.delete(
      `storerelationshiptopicfilesreceived/` +
        `storerelationshiptopictempid/` +
        `${deletedRowStoreRelationshipTopic.store_relationship_topic_temp_id}`,
    );

    setStoreRelationshipTopics(
      storeRelationshipTopics.filter(
        storeRelTopic =>
          storeRelTopic.sequence !== deletedRowStoreRelationshipTopic.sequence,
      ),
    );

    if (
      deletedRowStoreRelationshipTopic.sequence ===
      storeRelationshipTopicSequence.current
    ) {
      storeRelationshipTopicSequence.current -= 1;
    }
  }

  async function handleRowTypeChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    setStoreRelationshipTopics(
      storeRelationshipTopics.map(storeRelTopic => {
        if (storeRelTopic.sequence === sequence) {
          storeRelTopic.store_relationship_topic_type_id = fieldValue;
        }
        return storeRelTopic;
      }),
    );
  }

  async function handleRowDescriptionChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    setStoreRelationshipTopics(
      storeRelationshipTopics.map(storeRelTopic => {
        if (storeRelTopic.sequence === sequence) {
          storeRelTopic.description = fieldValue;
        }
        return storeRelTopic;
      }),
    );
  }

  function handleRowFieldsDisabled(sequence: number): void {
    setStoreRelationshipTopics(
      storeRelationshipTopics.map(storeRelTopic => {
        if (storeRelTopic.sequence === sequence) {
          storeRelTopic.fields_enabled = false;
        }

        return storeRelTopic;
      }),
    );
  }

  useEffect(() => {
    if (confirmStoreRelationshipTopicEnabled) {
      if (storeRelationshipTopicEnableSequence) {
        if (storeRelationshipTopicEnableSequence !== '') {
          setStoreRelationshipTopics(
            storeRelationshipTopics.map(storeRelTopic => {
              if (
                storeRelTopic.sequence ===
                Number(storeRelationshipTopicEnableSequence)
              ) {
                storeRelTopic.fields_enabled = true;
              }
              return storeRelTopic;
            }),
          );
          setStoreRelationshipTopicEnableSequence('');
          setConfirmStoreRelationshipTopicEnabled(false);
        }
      }
    }
  }, [
    confirmStoreRelationshipTopicEnabled,
    storeRelationshipTopicEnableSequence,
    storeRelationshipTopics,
  ]);

  useEffect(() => {
    if (confirmStoreRelationshipTopicDelete) {
      if (storeRelationshipTopicDeleteSequence) {
        if (storeRelationshipTopicDeleteSequence !== '') {
          setStoreRelationshipTopics(
            storeRelationshipTopics.map(storeRelTopic => {
              if (
                storeRelTopic.sequence ===
                Number(storeRelationshipTopicDeleteSequence)
              ) {
                storeRelTopic.deleted = true;
                storeRelTopic.store_relationship_record_reason =
                  reasonDeleteStoreRelationshipTopic;
              }

              return storeRelTopic;
            }),
          );

          setStoreRelationshipTopicDeleteSequence('');
          setConfirmStoreRelationshipTopicDelete(false);
          setReasonDeleteStoreRelationshipTopic('');
        }
      }
    }
  }, [
    confirmStoreRelationshipTopicDelete,
    storeRelationshipTopicDeleteSequence,
    storeRelationshipTopics,
    reasonDeleteStoreRelationshipTopic,
  ]);

  useEffect(() => {
    if (confirmStoreRelationshipTopicFileDelete) {
      if (storeRelationshipTopicFileDeleteId) {
        if (storeRelationshipTopicFileDeleteId !== '') {
          setPreviousStoreRelationshipTopicFiles(
            previousStoreRelationshipTopicFiles.map(
              previousStoreRelationshipTopicFile => {
                if (
                  previousStoreRelationshipTopicFile.id ===
                  storeRelationshipTopicFileDeleteId
                ) {
                  previousStoreRelationshipTopicFile.deleted = true;

                  setStoreRelationshipTopics(
                    storeRelationshipTopics.map(storeRelTopic => {
                      if (
                        storeRelTopic.id ===
                        previousStoreRelationshipTopicFile
                          .store_relationship_topic.id
                      ) {
                        storeRelTopic.store_relationship_topic_files_deleted.push(
                          {
                            id: storeRelationshipTopicFileDeleteId,
                          },
                        );
                      }

                      return storeRelTopic;
                    }),
                  );
                }

                return previousStoreRelationshipTopicFile;
              },
            ),
          );

          setStoreRelationshipTopicFileDeleteId('');
          setConfirmStoreRelationshipTopicFileDelete(false);
        }
      }
    }
  }, [
    confirmStoreRelationshipTopicFileDelete,
    storeRelationshipTopicFileDeleteId,
    previousStoreRelationshipTopicFiles,
    storeRelationshipTopics,
  ]);

  function getStoreRelationshipTopicTypeLabel(srtt_id: string): string {
    if (storeRelationshipTopicTypes) {
      if (storeRelationshipTopicTypes.length > 0) {
        const storeRelTopicType = storeRelationshipTopicTypes.find(
          stRelTopicType => stRelTopicType.id === srtt_id,
        );

        if (storeRelTopicType) {
          return storeRelTopicType.name;
        }
      }
    }

    return '';
  }

  function handleRowEnableFieldsButtons(
    rowStoreRelationshipTopic: IStoreRelationshipTopic,
  ): React.ReactNode {
    if (rowStoreRelationshipTopic.fields_enabled) {
      return (
        <UnlockButton
          iconSize={20}
          type="button"
          onClick={() => {
            handleRowFieldsDisabled(rowStoreRelationshipTopic.sequence);
          }}
        />
      );
    }

    return (
      <LockButton
        iconSize={20}
        type="button"
        onClick={() => {
          setStoreRelationshipTopicEnableSequence(
            rowStoreRelationshipTopic.sequence.toString(),
          );
          setIsOpenWarning(true);
        }}
      />
    );
  }

  function handleTableRowButtons(
    rowStoreRelationshipTopic: IStoreRelationshipTopic,
  ): React.ReactNode {
    if (rowStoreRelationshipTopic.previous) {
      return (
        <>
          {handleRowEnableFieldsButtons(rowStoreRelationshipTopic)}
          <DeletePreviousButton
            marginTop="0.8rem"
            marginLeft="1rem"
            type="button"
            onClick={() => {
              setStoreRelationshipTopicDeleteSequence(
                rowStoreRelationshipTopic.sequence.toString(),
              );
              setTitleDeleteStoreRelationshipTopic(
                `Deseja realmente excluir o tópico de ` +
                  `sequência ${rowStoreRelationshipTopic.sequence} ` +
                  `previamente registrado?`,
              );
              setSubTitleDeleteStoreRelationshipTopic(
                'A exclusão apenas será efetivada ao salvar o registro!',
              );
              setIsOpenDeleteStoreRelationshipTopic(true);
            }}
          />
        </>
      );
    }

    return (
      <DeleteRowButton
        type="button"
        onClick={() => {
          handleDeleteRowTable(rowStoreRelationshipTopic);
        }}
      />
    );
  }

  function handleFileImageIcon(fileUrl: string): React.ReactNode {
    const fileMimeType = mime.getType(fileUrl);

    if (fileMimeType === 'audio/mp3' || fileMimeType === 'audio/mpeg') {
      return <AudioIcon iconSize={50} />;
    }

    if (fileMimeType === 'application/pdf') {
      return <PdfIcon iconSize={50} />;
    }

    if (
      fileMimeType ===
        'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
      fileMimeType === 'application/vnd.oasis.opendocument.presentation'
    ) {
      return <PresentationIcon iconSize={50} />;
    }

    if (
      fileMimeType ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      fileMimeType === 'application/vnd.oasis.opendocument.spreadsheet'
    ) {
      return <SpreadsheetIcon iconSize={50} />;
    }

    if (fileMimeType === 'text/plain') {
      return <TextIcon iconSize={50} />;
    }

    if (
      fileMimeType === 'image/gif' ||
      fileMimeType === 'video/quicktime' ||
      fileMimeType === 'video/mp4'
    ) {
      return <VideoIcon iconSize={40} />;
    }

    if (
      fileMimeType ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      fileMimeType === 'application/vnd.oasis.opendocument.text'
    ) {
      return <WordIcon iconSize={50} />;
    }

    return (
      <FileImageWrapper>
        <FileImage src={fileUrl} />
      </FileImageWrapper>
    );
  }

  function handleRowPreviousFile(
    receivedPreviousStoreRelationshipTopicFile: IPreviousStoreRelationshipTopicFile,
    previous: boolean,
    fields_enabled: boolean,
  ): React.ReactNode {
    if (!receivedPreviousStoreRelationshipTopicFile.deleted) {
      return (
        <div style={{ display: 'flex', marginBottom: '20px' }}>
          <div style={{ width: '20%' }}>
            {handleFileImageIcon(
              receivedPreviousStoreRelationshipTopicFile.url,
            )}
          </div>

          <div
            style={{
              width: '60%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <FileInfoName>
              {receivedPreviousStoreRelationshipTopicFile.name}
            </FileInfoName>
            <FileInfoFileSize>
              {receivedPreviousStoreRelationshipTopicFile.size}
            </FileInfoFileSize>
          </div>

          <div
            style={{
              width: '20%',
              display: 'flex',
              justifyContent: 'right',
              alignItems: 'center',
            }}
          >
            {receivedPreviousStoreRelationshipTopicFile.url && (
              <FileUrlIcon>
                <a
                  href={receivedPreviousStoreRelationshipTopicFile.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLink size={20} />
                </a>
              </FileUrlIcon>
            )}

            {!!receivedPreviousStoreRelationshipTopicFile.url && (
              <DeletePreviousButton
                iconSize={24}
                type="button"
                disabled={previous && !fields_enabled}
                isDisabled={!fields_enabled}
                onClick={() => {
                  setStoreRelationshipTopicFileDeleteId(
                    receivedPreviousStoreRelationshipTopicFile.id,
                  );
                  setTitleDeleteStoreRelationshipTopicFile(
                    `Deseja realmente excluir o arquivo ` +
                      `${receivedPreviousStoreRelationshipTopicFile.name} ` +
                      `previamente registrado no tópico de sequência ` +
                      `${receivedPreviousStoreRelationshipTopicFile.store_relationship_topic.sequence}?`,
                  );
                  setIsOpenDeleteStoreRelationshipTopicFile(true);
                }}
              />
            )}
          </div>
        </div>
      );
    }

    return <></>;
  }

  function handleRowPreviousFiles(
    receivedStoreRelationshipTopic: IStoreRelationshipTopic,
  ): React.ReactNode {
    if (receivedStoreRelationshipTopic) {
      if (receivedStoreRelationshipTopic.id) {
        if (receivedStoreRelationshipTopic.id !== '') {
          if (previousStoreRelationshipTopicFiles) {
            if (previousStoreRelationshipTopicFiles.length > 0) {
              const rowStoreRelationshipTopicFiles =
                previousStoreRelationshipTopicFiles.filter(
                  prevStoreRelationshipTopicFile =>
                    prevStoreRelationshipTopicFile.store_relationship_topic_id ===
                    receivedStoreRelationshipTopic.id,
                );

              if (rowStoreRelationshipTopicFiles) {
                if (rowStoreRelationshipTopicFiles.length > 0) {
                  return (
                    <ItemUploadWrapper
                      disabled={
                        receivedStoreRelationshipTopic.previous &&
                        !receivedStoreRelationshipTopic.fields_enabled
                      }
                    >
                      <ItemLabel>ANEXOS</ItemLabel>

                      <ItemPreviousFilesWrapper>
                        <FileMainContainer>
                          {rowStoreRelationshipTopicFiles.map(
                            rowStoreRelationshipTopicFile =>
                              handleRowPreviousFile(
                                rowStoreRelationshipTopicFile,
                                receivedStoreRelationshipTopic.previous,
                                receivedStoreRelationshipTopic.fields_enabled,
                              ),
                          )}
                        </FileMainContainer>
                      </ItemPreviousFilesWrapper>
                    </ItemUploadWrapper>
                  );
                }
              }
            }
          }
        }
      }
    }

    return <></>;
  }

  function handleTableRow(
    rowStoreRelationshipTopic: IStoreRelationshipTopic,
  ): React.ReactNode {
    if (!rowStoreRelationshipTopic.deleted) {
      return (
        <tr key={rowStoreRelationshipTopic.sequence}>
          <ItemSequence>
            <ItemLabel>
              {`${'Sequência'.toUpperCase()}: ${
                rowStoreRelationshipTopic.sequence
              }`}
            </ItemLabel>
          </ItemSequence>

          <ItemContent>
            <ItemWrapper>
              <TableSelect
                label="Tipo"
                upperCaseLabel
                disabled={
                  rowStoreRelationshipTopic.previous &&
                  !rowStoreRelationshipTopic.fields_enabled
                }
                options={topicOptions}
                loading={loadingTopicTypes}
                defaultValue={{
                  value:
                    rowStoreRelationshipTopic.store_relationship_topic_type_id,
                  label: getStoreRelationshipTopicTypeLabel(
                    rowStoreRelationshipTopic.store_relationship_topic_type_id,
                  ),
                }}
                onSet={(e: any) => {
                  handleRowTypeChange(
                    rowStoreRelationshipTopic.sequence,
                    e.value,
                  );
                }}
              />
            </ItemWrapper>
          </ItemContent>

          <ItemContent>
            <ItemWrapper>
              <TableTextArea
                labelText="Descrição"
                upperCaseLabel
                defaultValue={rowStoreRelationshipTopic.description}
                maxLength={2000}
                disabled={
                  rowStoreRelationshipTopic.previous &&
                  !rowStoreRelationshipTopic.fields_enabled
                }
                onChange={e => {
                  handleRowDescriptionChange(
                    rowStoreRelationshipTopic.sequence,
                    e.target.value,
                  );
                }}
              />
            </ItemWrapper>
          </ItemContent>

          {handleRowPreviousFiles(rowStoreRelationshipTopic)}

          <ItemUploadWrapper
            disabled={
              rowStoreRelationshipTopic.previous &&
              !rowStoreRelationshipTopic.fields_enabled
            }
          >
            <UploadStoreRelationshipTopicFiles
              type="topico_rel_loja"
              store_relationship_topic_temp_id={
                rowStoreRelationshipTopic.store_relationship_topic_temp_id
              }
              user_id={user.id}
              isDisabled={
                rowStoreRelationshipTopic.previous &&
                !rowStoreRelationshipTopic.fields_enabled
              }
            />
          </ItemUploadWrapper>

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '10px 5px 10px 10px',
            }}
          >
            {handleTableRowButtons(rowStoreRelationshipTopic)}
          </div>
        </tr>
      );
    }

    return <></>;
  }

  function handleTable(): React.ReactNode {
    if (storeRelationshipTopics) {
      if (storeRelationshipTopics.length > 0) {
        return (
          <Table>
            {loadingTopics && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <PropagateLoader color="#276c8d" />
              </div>
            )}
            {storeRelationshipTopics?.map(storeRelationshipTopic =>
              handleTableRow(storeRelationshipTopic),
            )}
          </Table>
        );
      }
    }

    return <></>;
  }

  return (
    <Container>
      <BreadCrumb
        main="Lojas"
        path="/storerelationships"
        firstChildPath="/storerelationships/procedure1"
        firstChildState={client}
        firstChild="Atualização Registro"
        secondChild="Atualização Tópicos"
        mainIcon={FaStore}
        firstChildIcon={FaStore}
        secondChildIcon={FaListUl}
        mainColored
        firstChildColored
      />

      <StoreRelationshipInfo
        erp_rep_code={state?.erp_rep_code ? state.erp_rep_code : ''}
        erp_client_code={state?.erp_client_code ? state.erp_client_code : ''}
        store_relationship_id={state?.id}
      />

      <form onSubmit={handleSubmit(submitForm)}>
        <LabelContainer>
          <Label>Tópicos</Label>
        </LabelContainer>

        <Content maxWidth="680px">{handleTable()}</Content>

        <ContentAddRow maxWidth="680px">
          <AddRowButton type="button" onClick={() => handleAddRowTable()} />
        </ContentAddRow>

        <ButtonContainer>
          <SaveButton
            type="button"
            onClick={() => {
              onValidateConfirm();
            }}
          />

          <ExitButton
            style={{ marginLeft: 10 }}
            type="button"
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={onConfirmProcedure}
        title={titleConfirmProcedure}
        subTitle=""
        opConfirm="Sim"
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />

      <ConfirmWarningDialog
        isOpen={isOpenDeleteStoreRelationshipTopicFile}
        setIsOpen={setIsOpenDeleteStoreRelationshipTopicFile}
        onConfirm={onDeleteStoreRelationshipTopicFile}
        title={titleDeleteStoreRelationshipTopicFile}
        subTitle="A exclusão apenas será efetivada ao salvar o registro de loja!"
        opConfirm="Sim, excluir"
      />

      <ConfirmWarningDialog
        isOpen={isOpenWarning}
        setIsOpen={setIsOpenWarning}
        onConfirm={onConfirmWarning}
        title="Deseja realmente alterar o tópico previamente registrado?"
        opConfirm="Sim, alterar"
      />

      <DeleteModal
        isOpen={isOpenDeleteStoreRelationshipTopic}
        onDelete={onDeleteStoreRelationshipTopic}
        title={titleDeleteStoreRelationshipTopic}
        subTitle={subTitleDeleteStoreRelationshipTopic}
        setIsOpen={setIsOpenDeleteStoreRelationshipTopic}
        setReason={setReasonDeleteStoreRelationshipTopic}
      />

      <NoticeDialog
        isOpen={isOpenNotice}
        type="error"
        title={titleNotice}
        subTitle={subTitleNotice}
        setIsOpen={setIsOpenNotice}
      />
    </Container>
  );
};

export default ProcedureTopics;
