import React, { InputHTMLAttributes, useState, useCallback } from 'react';

import ErrorTooltip from '~/components/Tooltips/Error';

import WarningTooltip from '~/components/Tooltips/Warning';

import { Label, Container, InputText } from './styles';

interface IDefaultProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  labelFor?: string;
  labelText?: string;
  upperCaseLabel?: boolean;
  error?: string;
  warning?: string;
}

const Default = React.forwardRef<HTMLInputElement, IDefaultProps>(
  (
    {
      name,
      labelFor,
      labelText,
      upperCaseLabel,
      error,
      warning,
      ...rest
    }: IDefaultProps,
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleInputFocus = useCallback(() => {
      setIsFocused(true);
    }, []);

    const handleInputBlur = useCallback(() => {
      setIsFocused(false);
    }, []);

    return (
      <>
        {labelText && (
          <Label isFocused={isFocused}>
            {upperCaseLabel ? labelText.toUpperCase() : labelText}
          </Label>
        )}

        <Container id={labelFor} isFocused={isFocused} isErrored={!!error}>
          <InputText
            name={name}
            type="type"
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            ref={ref}
            {...rest}
          />

          {error && <ErrorTooltip title={error} />}

          {warning && <WarningTooltip title={warning} />}
        </Container>
      </>
    );
  },
);

export default Default;
