import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FaProjectDiagram, FaCalendarAlt, FaWrench } from 'react-icons/fa';

import BreadCrumb from '~/components/BreadCrumb';
import ProjectInfo from '~/components/ProjectInfo/Default';
import Select from '~/components/Select';
import SelectButton from '~/components/Buttons/Select';
import NoticeDialog from '~/components/Dialogs/Notice';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import {
  Container,
  Content,
  TwoFifthsWrapper,
  ThreeFifthsWrapper,
} from './styles';

interface ILocation {
  id: string;
  type: string;
  code: string;
}

const ChooseProcess: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [type, setType] = useState('none');
  const [isOpenNotice, setIsOpenNotice] = useState(false);
  const [titleNotice, setTitleNotice] = useState('');
  const [subTitleNotice, setSubTitleNotice] = useState('');

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto não localizado',
      });

      history.push('/projects/events');
    } else if (
      user.user_type.type !== 'ADM' &&
      user.user_type.type !== 'MK1' &&
      user.user_type.type !== 'MK2' &&
      user.user_type.type !== 'MAX'
    ) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Tipo de usuário não permitido',
      });

      history.push('/projects/events');
    }
  }, [state, user, addToast, history]);

  const typeOptions: { value: string; label: string }[] = [
    { value: 'gifts', label: 'Brindes' },
    { value: 'cancel', label: 'Cancelamento' },
    { value: 'client_contact', label: 'Contato de Cliente' },
    { value: 'event_costs', label: 'Custos' },
    { value: 'event_date', label: 'Data de Evento' },
  ];

  function onConfirmProcedure(): void {
    if (type === 'gifts') {
      history.push({
        pathname: '/projects/events/editing/gifts',
        state,
      });
    }

    if (type === 'cancel') {
      history.push({
        pathname: '/projects/events/editing/cancel',
        state,
      });
    }

    if (type === 'client_contact') {
      history.push({
        pathname: '/projects/events/editing/clientcontact',
        state,
      });
    }

    if (type === 'event_costs') {
      history.push({
        pathname: '/projects/events/editing/eventcosts',
        state,
      });
    }

    if (type === 'event_date') {
      history.push({
        pathname: '/projects/events/editing/eventdate',
        state,
      });
    }
  }

  async function onValidateConfirmProcedure(): Promise<void> {
    if (type === 'none') {
      setTitleNotice('Tipo não selecionado!');
      setSubTitleNotice('Necessário selecionar tipo válido!');
      setIsOpenNotice(true);
    } else {
      onConfirmProcedure();
    }
  }

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/events"
        firstChild="Eventos"
        secondChild="Alteração Manual de Solicitação"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaCalendarAlt}
        secondChildIcon={FaWrench}
        mainColored
        firstChildColored
      />

      <ProjectInfo project_id={state.id} all />

      <Content maxWidth="700px">
        <ThreeFifthsWrapper>
          <Select
            label="Tipo"
            options={typeOptions}
            onSet={(e: any) => {
              setType(e.value);
            }}
          />
        </ThreeFifthsWrapper>

        <TwoFifthsWrapper>
          <SelectButton
            marginTop="2.8rem"
            marginLeft="2rem"
            onClick={() => onValidateConfirmProcedure()}
          />
        </TwoFifthsWrapper>
      </Content>

      <NoticeDialog
        isOpen={isOpenNotice}
        type="error"
        title={titleNotice}
        subTitle={subTitleNotice}
        setIsOpen={setIsOpenNotice}
      />
    </Container>
  );
};

export default ChooseProcess;
